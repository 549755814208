<!-- MoveKmsPop.vue -->
<script setup lang="ts">
import {
  onMounted,
  ref,
  computed,
  watch,
  nextTick,
  defineProps,
  PropType
} from "vue";
import { useQuasar } from "quasar";
import {
  getKmsFolderGroupList,
  getKmsFolderList,
  moveKmsKnow
} from "../../../api/backend";
import { useStore } from "../../../stores/store";
import FolderTreeMoveItem from "./FolderTreeMoveItem.vue";
import CloseIcon from "@fluentui/svg-icons/icons/dismiss_24_regular.svg";

interface SavedFolder {
  folder_name: string;
  kms_folder_key: number;
}

interface Props {
  open: boolean;
  authorizedItems?: SavedFolder[];
  onSave: (items: SavedFolder[]) => void;
  onClose: () => void;
  selectedMoveKnowKey: number;
  toggleKnowMoveMode: () => void;
}

interface FolderGroup {
  kms_folder_group_key: number;
  channel_key: number;
  cre_date: string | null;
  folder_group_system_code: string | null;
  cre_user_key: number;
  folder_group_name: string;
  folder_group_type: string;
  delete_yn: number;
}

interface SubFolder {
  doc_key: number;
  channel_key: number;
  folder_name: string;
  confirm_yn: number;
  kms_folder_key: number;
  parent_folder_key: number;
  children?: SubFolder[];
  kms_folder_group_key?: number;
  delete_yn?: number;
}

const props = defineProps<Props>();
const emits = defineEmits(["update:open", "save"]);

const $q = useQuasar();
const selectedFolder = ref<string>("");
const tempAuthorizedItems = ref<SavedFolder[]>([]);
const unauthorizedItems = ref<FolderGroup[]>([]);
const folderGroups = ref<FolderGroup[]>([]);
const folderList = ref<SubFolder[]>([]);
const selectedTopFolder = ref<FolderGroup | null>(null);
const selectedFolderId = ref<number | null>(null);
const expandedFolders = ref(new Set<number>());

// 폴더 선택 핸들러 추가
const selectTargetFolder = ({ folderKey, selected }) => {
  if (selected) {
    selectedFolderKey.value = folderKey;

    // 선택된 폴더 이름 저장
    const selectedFolder = store.folders.find(
      (folder) => folder.kms_folder_key === folderKey
    );
    if (selectedFolder) {
      selectedFolderName.value = selectedFolder.folder_name;
    }
  } else {
    selectedFolderKey.value = null;
    selectedFolderName.value = "";
  }
};

const fetchFolderGroups = async () => {
  const channelKey = store.currentChannel?.channel_key ?? 0;

  try {
    const response = await getKmsFolderGroupList(channelKey);
    folderGroups.value = Array.isArray(response)
      ? response.filter((group) => group.delete_yn === 0)
      : [response].filter((group) => group.delete_yn === 0);
    console.log("🚀 가져온 폴더 그룹:", folderGroups.value);
  } catch (error) {
    console.error("📢 폴더 그룹 가져오기 실패:", error);
  }
};

const fetchFolderList = async () => {
  const channelKey = store.currentChannel?.channel_key ?? 0;
  const filteredFolderTrees = store.filteredFolderTrees;
  const rootFolders = store.filteredFolderTrees.filter(
    (item) => !store.selectedFolders.has(item.kms_folder_key)
  );
  console.log(
    "!!!!!!!!!!!!!!!!!!!!!!!!!!! 현재 폴더 리스트",
    filteredFolderTrees
  );

  try {
    const response = await getKmsFolderList(channelKey);
    folderGroups.value = Array.isArray(response)
      ? response.filter((group) => group.delete_yn === 0)
      : [response].filter((group) => group.delete_yn === 0);
    console.log("🚀 가져온 폴더 그룹:", response);
  } catch (error) {
    console.error("📢 폴더 그룹 가져오기 실패:", error);
  }
};

// 필터링된 폴더 트리 목록
const localFilteredFolderTrees = computed(() => {
  // 폴더 그룹 선택 시 해당 그룹의 폴더만 표시
  if (!targetFolderGroupKey.value) return [];

  const rootFolders = store.folders.filter(
    (folder) =>
      folder.kms_folder_group_key === targetFolderGroupKey.value &&
      folder.parent_folder_key === 0 &&
      folder.delete_yn !== 1
  );

  console.log("필터링된 폴더 트리:", rootFolders);
  return rootFolders;
});

const fetchSubFolders = async (folderGroupKey: number) => {
  try {
    const response = await getKmsFolderList(folderGroupKey);
    console.log("가져온 폴더키 기준 폴더들", response);
    // 응답 데이터 필터링 및 정렬
    const filteredFolders = Array.isArray(response)
      ? response.filter((folder) => {
          const selectedGroup = folderGroups.value.find(
            (group) => group.kms_folder_group_key === folderGroupKey
          );
          return (
            selectedGroup &&
            folder.channel_key === selectedGroup.channel_key &&
            folder.delete_yn === 0 &&
            folder.show_yn !== null
          );
        })
      : [];

    folderList.value = filteredFolders;

    selectedTopFolder.value =
      folderGroups.value.find(
        (group) => group.kms_folder_group_key === folderGroupKey
      ) || null;

    return response;
  } catch (error) {
    console.error("하위 폴더 불러오기 실패:", error);
    folderList.value = [];
    selectedTopFolder.value = null;
  }
};

const buildFolderHierarchy = (folders: SubFolder[]): SubFolder[] => {
  const folderMap = new Map<number, SubFolder>();
  const rootFolders: SubFolder[] = [];

  // 먼저 모든 폴더를 맵에 저장
  folders.forEach((folder) => {
    folderMap.set(folder.kms_folder_key, { ...folder, children: [] });
  });

  // 계층 구조 구성
  folders.forEach((folder) => {
    const currentFolder = folderMap.get(folder.kms_folder_key);
    if (currentFolder) {
      if (folder.parent_folder_key === 0) {
        rootFolders.push(currentFolder);
      } else {
        const parentFolder = folderMap.get(folder.parent_folder_key);
        if (parentFolder) {
          if (!parentFolder.children) {
            parentFolder.children = [];
          }
          parentFolder.children.push(currentFolder);
        }
      }
    }
  });

  return rootFolders;
};

const toggleFolder = (folderId: number) => {
  if (expandedFolders.value.has(folderId)) {
    expandedFolders.value.delete(folderId);
  } else {
    expandedFolders.value.add(folderId);
  }
};

const isExpanded = (folderId: number) => {
  return expandedFolders.value.has(folderId);
};

const hasChildren = (folderId: number) => {
  return folderList.value.some(
    (folder) => folder.parent_folder_key === folderId
  );
};

const handleFolderClick = async (folderId: number) => {
  // 폴더 선택 상태 업데이트
  if (selectedFolderId.value === folderId) {
    selectedFolderId.value = null;
  } else {
    selectedFolderId.value = folderId;
    // 하위 폴더 데이터 가져오기
    const result = await fetchSubFolders(folderId);
    console.log("result", result);
  }

  // 폴더 확장/축소 상태 토글
  toggleFolder(folderId);
};

const handleFolderChange = async (folderName: string) => {
  selectedFolder.value = folderName;
  const selectedGroup = folderGroups.value.find(
    (group) => group.folder_group_name === folderName
  );

  if (selectedGroup) {
    // 폴더 그룹이 선택되면 바로 하위 폴더 구조를 가져옴
    const result = await fetchSubFolders(selectedGroup.kms_folder_group_key);
    console.log("result", result);

    // 루트 폴더만 필터링 (parent_folder_key가 0인 폴더만 표시)
    const rootFolders = Array.isArray(result)
      ? result.filter(
          (folder) => folder.parent_folder_key === 0 && folder.delete_yn !== 1
        )
      : [];

    folderList.value = rootFolders;
  } else {
    // 선택이 해제되면 하위 폴더 구조를 비움
    folderList.value = [];
  }

  updateUnauthorizedItems();
};
const selectedFolderKey = ref(0);

const selectedFolderName = ref("");

const updateUnauthorizedItems = () => {
  unauthorizedItems.value = [...folderGroups.value].filter(
    (folder) =>
      !tempAuthorizedItems.value.some(
        (authorized) =>
          authorized.kms_folder_key === folder.kms_folder_group_key
      )
  );
};
const store = useStore();

const removeFromAuthorized = (item: SavedFolder) => {
  tempAuthorizedItems.value = tempAuthorizedItems.value.filter(
    (i) => i.kms_folder_key !== item.kms_folder_key
  );
  updateUnauthorizedItems();
};

const handleClose = () => {
  emits("update:open", false);
  if (props.onClose) {
    props.onClose();
  }
};

const filteredUnauthorizedItems = computed(() => {
  if (!selectedFolder.value) return [];

  return unauthorizedItems.value.filter((folder) => {
    const isSelected = folder.folder_group_name === selectedFolder.value;
    const isNotAuthorized = !tempAuthorizedItems.value.some(
      (auth) => auth.kms_folder_key === folder.kms_folder_group_key
    );
    return isSelected && isNotAuthorized;
  });
});

const initializeData = async () => {
  await fetchFolderGroups();
  tempAuthorizedItems.value = Array.isArray(props.authorizedItems)
    ? [...props.authorizedItems]
    : [];

  // 폴더 그룹이 있으면 자동으로 첫 번째 폴더 그룹 선택
  if (folderGroups.value.length > 0) {
    selectedFolder.value = folderGroups.value[0].folder_group_name;
    targetFolderGroupKey.value = folderGroups.value[0].kms_folder_group_key;
    // 선택된 폴더 그룹의 폴더 목록 가져오기
    await handleFolderChange(selectedFolder.value);

    // 폴더가 있으면 모든 폴더를 자동으로 펼치기
    if (folderList.value.length > 0) {
      folderList.value.forEach((folder) => {
        popupExpandedFolders.value.add(folder.kms_folder_key);
      });
    }
  } else {
    selectedFolder.value = "";
  }
  updateUnauthorizedItems();

  popupExpandedFolders.value = new Set();
};

watch(
  () => props.open,
  (newValue) => {
    if (newValue) {
      initializeData();
    }
  }
);

watch(
  () => props.authorizedItems,
  (newValue) => {
    if (props.open) {
      tempAuthorizedItems.value = [...newValue];
      updateUnauthorizedItems();
    }
  },
  { deep: true }
);

const localFilteredFolderTrees2 = computed(() => {
  // selectedFolders에 포함되지 않은 루트 폴더만 필터링
  const rootFolders = store.filteredFolderTrees
    .filter((item) => !store.selectedFolders.has(item.kms_folder_key))
    .map((folder) => ({
      ...folder,
      children: folder.children ? folder.children : [] // ✅ children이 없으면 빈 배열 유지
    }));

  console.log("📁 localFilteredFolderTrees (최종 필터링 결과):", rootFolders);
  return rootFolders;
});

const selectedLocalFolder = ref<null | {
  kms_folder_group_key: string;
  folder_group_name: string;
}>(null);

onMounted(fetchFolderGroups);
onMounted(fetchFolderList);

// 지식 이동 처리
const moveKmsKnowBtn = async () => {
  if (!selectedFolderKey.value) {
    $q.notify({
      message: "이동할 폴더를 선택해주세요.",
      color: "negative"
    });
    return;
  }

  const response = await moveKmsKnow(
    props.selectedMoveKnowKey,
    selectedFolderKey.value
  );

  if (!response) {
    // 실패 시 다시 로드
    await store.loadKnows(
      store.currentChannel?.channel_key,
      store.selectedFolderKey,
      1
    );
  }
  $q.notify({
    message: "지식 이동이 완료되었습니다.",
    color: "positive"
  });

  // 지식 목록 다시 로드
  if (store.selectedFolderKey) {
    await store.loadKnows(
      Number(store.currentChannel?.channel_key),
      store.selectedFolderKey,
      1
    );
  }
  props.toggleKnowMoveMode();
  handleClose();
};

const formattedPath = computed(() => {
  return selectedFolderName.value ? `${selectedFolderName.value}` : "";
});

// 팝업 내에서만 사용할 로컬 상태 추가
const popupExpandedFolders = ref(new Set());

// 폴더 토글 메서드 추가
const togglePopupFolder = (folderKey) => {
  if (popupExpandedFolders.value.has(folderKey)) {
    popupExpandedFolders.value.delete(folderKey);
  } else {
    popupExpandedFolders.value.add(folderKey);
  }
};

const targetFolderGroupKey = ref(null);
</script>

<template>
  <div v-if="open" class="popup-overlay">
    <div class="popup-content">
      <div class="popup-header">
        <div class="header-content">
          <p>지식 이동</p>
        </div>
        <div @click="handleClose" class="close-button">
          <CloseIcon />
        </div>
      </div>

      <div class="popup-body">
        <!-- 경로 정보 섹션 -->
        <div class="path-info-section">
          <div class="path-info-row">
            <div class="path-label">기존 위치:</div>
            <div class="path-value">{{ store.currentPath }}</div>
          </div>

          <div class="path-info-row">
            <div class="path-label">이동 위치:</div>
            <div class="path-value highlight">
              <span v-if="selectedFolderName">{{ formattedPath }}</span>
              <span v-else class="empty-path">선택해주세요</span>
            </div>
          </div>
        </div>

        <!-- 폴더 선택 섹션 -->
        <div class="folder-selection-section">
          <h3>이동할 폴더 선택</h3>

          <div class="folder-select-container">
            <select
              v-model="selectedFolder"
              @change="handleFolderChange($event.target.value)"
              class="folder-select"
            >
              <option value="">-- 폴더 그룹 선택 --</option>
              <option
                v-for="group in folderGroups"
                :key="group.kms_folder_group_key"
                :value="group.folder_group_name"
              >
                {{ group.folder_group_name }}
              </option>
            </select>

            <!-- 선택된 폴더의 하위 구조 표시 -->
            <div
              v-if="selectedFolder && folderList.length > 0"
              class="sub-folders-container"
            >
              <FolderTreeMoveItem
                v-for="subFolder in folderList"
                :key="subFolder.kms_folder_key"
                :folder="subFolder"
                :level="0"
                :is-selectable="true"
                :is-selected="targetFolderKey === subFolder.kms_folder_key"
                :expanded-folders="popupExpandedFolders"
                @toggle-folder="togglePopupFolder"
                @target-folder-select="selectTargetFolder"
              />
            </div>

            <div
              v-else-if="selectedFolder && folderList.length === 0"
              class="empty-folder-message"
            >
              선택한 폴더 그룹에 폴더가 없습니다
            </div>
          </div>
        </div>
      </div>
      <div class="button-group">
        <button class="save-button" @click="moveKmsKnowBtn">저장</button>
        <button class="cancel-button" @click="handleClose">닫기</button>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  height: 760px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.popup-header {
  /* padding: 16px; */
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 14px;
}

.popup-header p {
  margin: 0;
  font-size: 20px !important;
  font-weight: bold;
}

.close-button {
  cursor: pointer;
  color: #666;
}

.close-button:hover {
  color: #333;
}

.popup-body {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
  /* max-height: calc(90vh - 60px); */
}

/* 경로 정보 섹션 */
.path-info-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
  border-radius: 8px;
  background-color: #f9f9f9;
  padding: 12px;
  border: 1px solid #eee;
}

.path-info-row {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 0;
}

.path-label {
  font-weight: 600;
  font-size: 14px;
  color: #555;
  width: 80px;
  flex-shrink: 0;
}

.path-value {
  flex: 1;
  font-size: 14px;
  color: #333;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.path-value.highlight {
  border-color: #51c2b8;
  background-color: #f0faf9;
}

.empty-path {
  color: #999;
  font-style: italic;
}

/* 폴더 선택 섹션 */
.folder-selection-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.folder-selection-section h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.folder-select-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 16px;
  /* background-color: #fafafa; */
}

.folder-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  height: auto;
}

.folder-select:focus {
  outline: none;
  border-color: #51c2b8;
  box-shadow: 0 0 0 2px rgba(81, 194, 184, 0.1);
}

.sub-folders-container {
  /* margin-top: 12px; */
  /* height: 500px; */
  flex: 1;
  overflow-y: auto;
  border: 1px solid #eee;
  border-radius: 4px;
  background-color: white;
}

.empty-folder-message {
  text-align: center;
  padding: 20px;
  color: #999;
  font-style: italic;
}

/* 버튼 그룹 */
.button-group {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 16px;
}

.save-button {
  padding: 10px 24px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  background-color: #51c2b8;
  color: white;
  font-weight: 600;
  transition: background-color 0.2s;
}

.save-button:hover {
  background-color: #18a498;
}

.save-button:disabled {
  background-color: #b5dbd7;
  cursor: not-allowed;
}

.cancel-button {
  padding: 10px 24px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  background-color: #f5f5f5;
  color: #333;
  font-weight: 600;
  transition: background-color 0.2s;
}

.cancel-button:hover {
  background-color: #ddd;
}

/* 모바일 반응형 */
@media (max-width: 768px) {
  .popup-content {
    width: 95%;
    max-height: 95vh;
  }

  .popup-header h2 {
    font-size: 18px;
  }

  .popup-body {
    padding: 16px;
    gap: 16px;
  }

  .sub-folders-container {
    max-height: 200px;
  }

  .button-group {
    flex-direction: column;
  }

  .save-button,
  .cancel-button {
    width: 100%;
    padding: 12px;
  }
}

@media (max-width: 480px) {
  .popup-header {
    padding: 12px;
  }

  .popup-body {
    padding: 12px;
    gap: 12px;
  }

  .folder-select-container {
    padding: 12px;
  }

  .pathTitle {
    font-size: 14px;
  }

  .pathBody {
    padding: 8px;
    font-size: 13px;
  }
}

/* 스크롤바 스타일링 */
.sub-folders-container::-webkit-scrollbar {
  width: 8px;
}

.sub-folders-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.sub-folders-container::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.sub-folders-container::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1;
}
</style>
