<script setup lang="ts">
import ChevronLeft from "@fluentui/svg-icons/icons/chevron_double_left_16_regular.svg";
import ChevronRight from "@fluentui/svg-icons/icons/chevron_double_right_16_regular.svg";
import { SplitterGroup, SplitterPanel, SplitterResizeHandle } from "radix-vue";
import { computed, watch } from "vue";
import QuestionBox from "../../components/main/QuestionBox.vue";
import { useStore } from "../../stores/store";
import { breakpoints } from "../../styles/responsive";
import { useAnimatedSplitter } from "./animatedSplitter";
import ChannelManagement from "../../components/root/channelManagement/ChannelManagement.vue";
import { ref } from "vue";
import { useTokenStore } from "../../stores/token";

const store = useStore();
const tokenStore = useTokenStore();

const isGreaterMobile = breakpoints.greater("tablet");
const splitDefaultSize = computed(() => (isGreaterMobile.value ? 50 : 0));
const splitMinSize = computed(() => (isGreaterMobile.value ? 20 : 30));

const openChannelManagement = () => {
  const width = 800;
  const height = 600;
  const left = (window.screen.width - width) / 2;
  const top = (window.screen.height - height) / 2;

  const newWindow = window.open(
    "/channel-management",
    "_blank",
    `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`
  );

  if (newWindow) {
    newWindow.focus();

    // localStorage 데이터를 객체로 변환하여 전송
    setTimeout(() => {
      newWindow.postMessage(
        {
          type: "LOCAL_STORAGE_DATA",
          data: {
            anytalk_user_info: localStorage.getItem("anytalk_user_info"),
            anytalk_jwt_token: localStorage.getItem("anytalk_jwt_token"),
            anytalk_api_base_url: localStorage.getItem("anytalk_api_base_url"),
            last_channel_key: localStorage.getItem("last_channel_key")
          }
        },
        window.location.origin // 보안 설정: 같은 origin에서만 데이터 전송
      );
    }, 1000);
  } else {
    alert("팝업 차단이 활성화되어 있습니다!");
  }
};

const [
  knowPanelRef,
  {
    togglePanel: toggleKnowPanel,
    isPanelCollapsed: isKnowPannelCollapsed,
    isPanelAnimatedCollapsed: isKnowPanelAnimatedCollapsed
  }
] = useAnimatedSplitter(splitMinSize);

let firstTimeRef = true;
watch(knowPanelRef, (knowRef) => {
  if (firstTimeRef) {
    knowRef?.$nextTick(() => {
      knowRef?.collapse();
    });
    firstTimeRef = false;
  }
});

// 경로가 변경될 때마다 handleRouteChange를 호출합니다.
watch(
  () => store.currentChannel?.channel_key,
  () => {
    console.log("===변경===================");
    knowPanelRef.value?.collapse();
    knowPanelRef.value?.resize(0);
  }
);

// 관리자 여부 확인
const isAdmin = computed(() => {
  const currentChannel = store.currentChannel;
  const myInfo = store.myInfo;

  if (!currentChannel || !myInfo) {
    return false; // 기본값으로 false를 반환하거나, 적절한 값을 반환
  }
  const nsuYn = ref(false);
  if (store.myInfo?.user_key == 27 && currentChannel.channel_key == 250) {
    return (nsuYn.value = true);
  }
  return (
    currentChannel.auth_type === "A" ||
    myInfo.observer_yn == 1 ||
    nsuYn.value == true
  );
});
</script>

<template>
  <SplitterGroup id="main" class="contentbox" as="main" direction="horizontal">
    <SplitterPanel
      :defaultSize="splitDefaultSize"
      :minSize="2"
      class="content content__maxWidth searchbox flexAlign"
      :class="{
        content__animated: isKnowPanelAnimatedCollapsed,
        content__collapsed_question:
          !isGreaterMobile && isAdmin && !isKnowPannelCollapsed
      }"
    >
      <!-- <AnytalkAnswer /> -->
      <!-- <JsTest /> -->
      <QuestionBox
        :isGreaterMobile="isGreaterMobile"
        :isKnowPannelCollapsed="isKnowPannelCollapsed"
      />
    </SplitterPanel>
    <!-- ======================================== 팝업 전용 -->
    <!-- <template v-if="isAdmin && tokenStore.isLogin"> -->
    <template v-if="false">
      <SplitterResizeHandle class="splitter" :disabled="!isGreaterMobile">
        <button class="toggleButton" @click.stop="toggleKnowPanel">
          <!-- 새 브라우저 탭에서 띄움 -->
          <!-- <button class="toggleButton" @click.stop="openChannelManagement"> -->
          <template v-if="isKnowPannelCollapsed">
            <ChevronLeft class="toggleIcon" />
          </template>
          <template v-else>
            <ChevronRight class="toggleIcon" />
          </template>
          관리
        </button>
      </SplitterResizeHandle>
      <SplitterPanel
        ref="knowPanelRef"
        :minSize="0"
        collapsible
        :collapsedSize="0"
        class="content searchbox2 flexAlign"
        :class="{
          content__animated: isKnowPanelAnimatedCollapsed,
          content__collapsed: isKnowPannelCollapsed
        }"
      >
        <!-- <KnowledgeBox /> -->
        <ChannelManagement />
      </SplitterPanel>
    </template>
  </SplitterGroup>
</template>
<style>
.content__maxWidth > div {
  max-width: 1024px;
}
</style>

<style lang="scss" scoped>
@use "../../styles/responsive";
@use "../../styles/commons";

$splitBorder: 1px solid commons.$mainBorderColor;

.contentbox {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  border-top: $splitBorder;
  background-color: #fff;
}

.content {
  flex: 1;
  display: flex;
  min-height: 100%;
  padding: 10px 20px;
  overflow-x: hidden;

  @include responsive.tablet {
    /* padding: 10px 30px; */
    padding: 0px 0px;
  }

  &__animated {
    transition: ease-in 0.35s;
    transition-property: flex, padding;
  }

  &__collapsed {
    padding: 0;
    max-height: 100vh;
  }

  &__collapsed_question {
    /* padding: 0 16px; */
    padding: 0px;
    max-height: 100vh;
    /* visibility: hidden; */
    &.content__animated {
      /* visibility: visible; */
    }
  }
}

.splitter {
  /* position: relative; */
  /* border-left: $splitBorder; */
}

.toggleButton {
  position: absolute;
  transform: translateX(-100%);
  left: 0;
  top: calc(10vh - var(--header-logo-height));
  padding: 10px 5px;
  background-color: #f0f0f0; /* 기존 색상으로 유지 */
  color: #333; /* 기존 텍스트 색상으로 유지 */
  border: 1px solid #ccc;
  border-top-left-radius: 8px; /* 좌측 위 모서리 둥글게 */
  border-bottom-left-radius: 8px; /* 좌측 아래 모서리 둥글게 */
  border-top-right-radius: 0; /* 우측 위 모서리 평평하게 */
  border-bottom-right-radius: 0; /* 우측 아래 모서리 평평하게 */
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    border-color 0.3s;

  &:hover {
    background-color: #e0e0e0; /* 기존 색상으로 유지 */
    border-color: #bbb;
  }

  &:focus {
    outline: none;
  }

  .toggleIcon {
    width: 16px;
    height: 16px;
  }
}

.searchbox {
  padding-right: 0px;
  position: relative;
  flex-direction: column;
  text-align: left;
  gap: 10px;
  background-color: commons.$mainBackground;
}

.searchbox2 {
  /* margin-top: 100px; */
  position: relative;
  flex-direction: column;
  text-align: left;
  gap: 10px;
  background-color: #fff;
}

@media (max-width: 420px) {
  /* .content {
    padding: 0px 10px 70px 0px !important;
  } */
}
</style>
