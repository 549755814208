import { ref, computed } from "vue";
import type { SplitterPanel } from "radix-vue";
import type { VueRef } from "../../types/ref";

export function useAnimatedSplitter(minSize?: number | VueRef<number>) {
  const panelRef = ref<InstanceType<typeof SplitterPanel>>();
  const isPanelCollapsed = computed(() => panelRef.value?.isCollapsed ?? false);
  const isPanelAnimatedCollapsed = ref(false);

  const togglePanel = () => {
    isPanelAnimatedCollapsed.value = true;
    isPanelCollapsed.value
      ? panelRef.value?.expand()
      : panelRef.value?.collapse();

    if (minSize) {
      const size = panelRef.value?.getSize();

      if (size) {
        if (typeof minSize === "number") {
          if (size < minSize) {
            panelRef.value?.resize(minSize);
          }
        } else {
          if (size < minSize.value) {
            panelRef.value?.resize(minSize.value);
          }
        }
      }
    }

    setTimeout(() => {
      isPanelAnimatedCollapsed.value = false;
    }, 500);
  };

  return [
    panelRef,
    {
      togglePanel,
      isPanelCollapsed,
      isPanelAnimatedCollapsed
    }
  ] as const;
}
