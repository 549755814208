<script setup>
import { ref, onMounted, onUnmounted } from "vue";

const socket = ref(null);
const message = ref("");
const messages = ref([]);
const buffer = ref(""); // 미완성 메시지 조립용 버퍼

const connectWebSocket = async () => {
  try {
    // const webSocketUrl = "wss://192.168.0.10:8000/ws/client1"; // URL 확인wq

    socket.value = new WebSocket(webSocketUrl);

    socket.value.onopen = () => {
      console.log("WebSocket connection opened.");
    };

    socket.value.onmessage = (event) => {
      handleIncomingMessage(event.data);
    };

    socket.value.onclose = () => {
      console.log("WebSocket closed. Reconnecting...");
      setTimeout(connectWebSocket, 1000);
    };

    socket.value.onerror = (error) => {
      console.error("WebSocket Error: ", error);
    };
  } catch (error) {
    console.error("Failed to connect to WebSocket:", error);
  }
};

const handleIncomingMessage = (data) => {
  buffer.value += data; // 데이터를 조립

  // 패킷 끝 확인 (예를 들어, '\0' 문자열로 끝나는지 확인)
  if (buffer.value.endsWith("\0")) {
    const completeMessage = buffer.value.slice(0, -1); // '\0' 제거
    messages.value.push(completeMessage);
    buffer.value = ""; // 버퍼 초기화
  }
};

const sendMessage = () => {
  if (
    message.value &&
    socket.value &&
    socket.value.readyState === WebSocket.OPEN
  ) {
    // 메시지 전송 시 종료 문자열 '\0' 추가
    socket.value.send(message.value + "\0");
    message.value = "";
  } else if (socket.value.readyState === WebSocket.CONNECTING) {
    console.log("WebSocket is still connecting. Please wait.");
  } else {
    console.log("WebSocket is not open.");
  }
};

onMounted(() => {
  connectWebSocket();
});

onUnmounted(() => {
  if (socket.value) {
    socket.value.close();
  }
});
</script>

<template>
  <div id="app">
    <h1>WebSocket Chat</h1>
    <textarea v-model="message" placeholder="Type your message"></textarea>
    <button @click="sendMessage">Send</button>
    <div v-for="msg in messages" :key="msg">{{ msg }}</div>
  </div>
</template>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  margin-top: 60px;
}
textarea {
  width: 300px;
  height: 100px;
}
</style>
