import { defineStore } from "pinia";
import { ref } from "vue";

export const usePopupStore = defineStore("popupStore", {
  state: () => ({
    // confirmAskPopYn: ref(false),
    // confirmAskPopBody: ref(""),
    confirmAskPopYn: false, // ref 제거
    confirmAskPopBody: "", // ref 제거
    // confirmAskOK: (() => {}) as (...args: unknown[]) => void,
    confirmAskOK: null as (() => Promise<void>) | null, // Promise를 반환하는 함수 타입으로 변경
    // confirmAskNO: (() => {}) as (...args: unknown[]) => void,
    confirmAskNO: null as (() => void) | null,
    // confirmCloseParentPop: (() => {}) as (...args: unknown[]) => void,
    // confirmCloseParentPop: null as (() => void) | null,
    confirmCloseParentPop: null as (() => void) | null | undefined,
    // confirmAskButtons: ref([
    //   { name: "확인", value: "confirm" },
    //   { name: "취소", value: "cancel" }
    // ]),
    // confirmPopYn: ref(false)
    confirmAskButtons: [
      // ref 제거
      { name: "확인", value: "confirm" },
      { name: "취소", value: "cancel" }
    ],
    confirmPopYn: false // ref 제거
    // confirmPopBody: ref("저장되었습니다.")
    // openConfirmPopYn: ref(true)
  }),
  actions: {
    openConfirmAskPopLayer() {
      console.log("컨펌애스크팝 열림");
      this.confirmAskPopYn = true;
    },
    closeConfirmAskPopLayer() {
      this.confirmAskPopYn = false;
      this.confirmAskOK = null;
      this.confirmAskNO = null;
    },
    async confirm() {
      if (this.confirmAskOK) await this.confirmAskOK();
      this.closeConfirmAskPopLayer();
    },
    cancel() {
      console.log("confirm 액션 실행");
      if (this.confirmAskNO) {
        console.log("confirmAskOK 존재함, 실행");
        this.confirmAskNO();
      }
      this.closeConfirmAskPopLayer();
    }
    // openConfirmPopLayer() {
    //   if (this.openConfirmPopYn == false) {
    //     this.openConfirmPopYn = true;
    //     return;
    //   }
    //   this.confirmPopYn = true;
    //   setTimeout(() => {
    //     this.closeConfirmPopLayer();
    //   }, 2000);
    // },
    // closeConfirmPopLayer() {
    //   this.confirmPopYn = false;
    // }
  }
});
