<script setup lang="ts">
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogTitle
} from "radix-vue";
import { ref } from "vue";
import CloseIcon from "@fluentui/svg-icons/icons/dismiss_16_filled.svg";
import { useStore } from "../../stores/store";
import { deleteChannel, getChannelList } from "../../api/backend";
import { Channel } from "../../api/types";
import { useRouter } from "vue-router";
import { useQuasar } from "quasar";

const store = useStore();
const router = useRouter();

interface Props {
  channel: Channel;
}
const props = defineProps<Props>();
const channel = ref(props.channel);

const $q = useQuasar();
import { inject } from "vue";
import { sidebarKey } from "../common/sidebar/SidebarRoot.vue";
const sidebarContext = inject(sidebarKey);

const onChannelUpdate = async () => {
  try {
    // if (channel.value.name.length > 0) {
    if (channel.value) {
      await deleteChannel(channel.value.channel_key);
      await store.updateAllChannelList();
      await store.updateMyChannelInfo();
      await getChannelList({ channel_key: 85 });
    }
  } catch (err) {
    console.log("err", err);
  } finally {
    $q.notify({
      message: "채널이 삭제되었습니다.",
      color: "positive",
      timeout: 3000
    });

    const chanIndex = store.allChannelList.findIndex(
      (item) => item.channel_key === 85
    );
    if (chanIndex) {
      store.currentChannel = store.allChannelList[chanIndex];
      router.push("/talk");
    }
  }
};
</script>

<template>
  <DialogPortal>
    <DialogOverlay class="DialogOverlay" />
    <DialogContent class="DialogContent" :disableOutsidePointerEvents="false">
      <DialogTitle class="DialogTitle"> 채널 삭제 </DialogTitle>
      <DialogDescription class="DialogDescription">
        삭제 하시겠습니까?
      </DialogDescription>
      <div
        :style="{
          display: 'flex',
          marginTop: 25,
          justifyContent: 'flex-end'
        }"
      >
        <DialogClose as-child>
          <button class="Button" @click="onChannelUpdate">삭제</button>
        </DialogClose>
        <DialogClose class="Button"> 취소 </DialogClose>
      </div>
      <DialogClose class="IconButton" aria-label="Close">
        <CloseIcon />
      </DialogClose>
    </DialogContent>
  </DialogPortal>
</template>

<style scoped lang="scss">
@use "../../styles/dialog";
</style>
