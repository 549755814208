<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useContentsStore } from "../../../stores/content";
import { useStore } from "../../../stores/store";

const contentStore = useContentsStore();
const store = useStore();

const props = defineProps({
  pTabs: Array,
  pSecondTabs: Array,
  pNowSecondTab: Number,
  pOptions: Array,
  pAddBtn: Boolean,
  pNowTab: Number,
  pThirdTab: Number,
  checkNowTab: Number,
  showAuthButtons: Boolean, //수빈 지식 권한 관리 추가
  showMemberAuthButtons: Boolean // 수빈 멤버 권한 관리 추가
});

const router = useRouter();

// const openKmsKnowPop = () => {
//   router.push("/talk/kmspage");
// };
const openKmsKnowPop = () => {
  router.push({
    name: "kmspage",
    params: { channelkey: store.currentChannel?.channel_key ?? null }
  });
};

const navigateToRepoFile = () => {
  router.push("/talk/kmsrepofile");
};

const navigateToRepoLink = () => {
  router.push("/talk/kmsrepolink");
};

const navigateToRepoImage = () => {
  router.push("/talk/kmsrepoimage");
};

const emits = defineEmits([
  "updateTab",
  "updateFilter",
  "openAddKnowPop",
  "updateSecondTab",
  "openAddFileKnowPop",
  "addAuth",
  "deleteAuth"
]);
// const nowTab = ref(props.pThirdTab);
const changsubTab = (index: number) => {
  emits("updateTab", index);
};
watch(
  () => [props.pTabs, props.pNowTab, props.pThirdTab],
  (value) => console.log("test입니다", value)
);

const changeSecondTab = (index: number) => {
  emits("updateSecondTab", index);
};

// 수빈 지식 권한 관리 추가,삭제 핸들러
const handleAdd = () => {
  console.log("SubTabsCompo: handleAdd called");
  emits("addAuth");
};

const handleDelete = () => {
  console.log("SubTabsCompo: handleDelete called");
  emits("deleteAuth");
};

const searchText = ref("");

const doSearch = () => {
  contentStore.getSearchManageText(searchText.value);
  searchText.value = "";
};

const setFilter = ref("default");
const changeFilter = (option: string) => {
  setFilter.value = option;
  emits("updateFilter", setFilter.value);
};

const openAddKnowPop = () => {
  emits("openAddKnowPop");
};

const showFileSelectBox = ref(false);
const selectedFileType = ref("");

const toggleDropdown = () => {
  showFileSelectBox.value = !showFileSelectBox.value;
};

// const selectFileType = (fileType: string) => {
//   showFileSelectBox.value = false;
//   console.log("Selected File Type:", fileType);
//   emits("openAddFileKnowPop");
//   emits("addFileKnowledge", selectedFileType.value); // 선택된 파일 형식
// };

// const openFileAddKnowPop = () => {
//   emits("openAddFileKnowPop");
// };

const selectFileType = (fileType: string) => {
  c;
  showFileSelectBox.value = false; // 드롭다운 닫기
  emits("openAddFileKnowPop", fileType); // 선택된 파일 형식 emit
};
</script>

<template>
  <div class="ActWrap">
    <template v-if="props.pTabs">
      <div class="subTabWrap flexAlign">
        <div class="subTab flexAlign" style="width: 100%">
          <div
            @click="changsubTab(index)"
            :class="{ selected: props.checkNowTab == index }"
            class="eachTab cursorP fontMd"
            v-for="(tab, index) in props.pTabs"
            :key="index"
            style="font-size: 16px"
          >
            {{ tab }}
          </div>
        </div>
        <!-- <div class="addBtn" v-if="props.pAddBtn">
          <button
            class="cursorP addBtnWrap"
            type="button"
            @click="navigateToRepoImage"
          >
            + kms 이미지 관리
          </button>
        </div>        
        <div class="addBtn" v-if="props.pAddBtn">
          <button
            class="cursorP addBtnWrap"
            type="button"
            @click="navigateToRepoLink"
          >
            + kms 링크 관리
          </button>
        </div>
        <div class="addBtn" v-if="props.pAddBtn">
          <button
            class="cursorP addBtnWrap"
            type="button"
            @click="navigateToRepoFile"
          >
            + kms 파일 관리
          </button>
        </div> -->
        <div class="addBtn" v-if="props.pAddBtn">
          <button
            v-if="store.currentChannel?.kms_yn === 1"
            class="cursorP addBtnWrap"
            type="button"
            @click="openKmsKnowPop"
          >
            + kms 지식 관리
          </button>
        </div>
        <div class="addBtn" v-if="props.pAddBtn">
          <button
            class="cursorP addBtnWrap"
            type="button"
            @click="openAddKnowPop"
          >
            + 텍스트 지식
          </button>
        </div>

        <!-- 수빈 : 권한 관리일 때만 버튼 표시 -->
        <template v-if="props.showAuthButtons">
          <div class="btnGroup">
            <button
              class="actionBtn addBtn cursorP"
              type="button"
              @click="handleAdd"
            >
              + 권한 추가
            </button>
            <!-- <button
              class="actionBtn deleteBtn cursorP"
              type="button"
              @click="handleDelete"
            >
              삭제
            </button> -->
          </div>
        </template>

        <!-- 수빈 : 권한 관리일 때만 버튼 표시 -->
        <template v-if="props.showMemberAuthButtons">
          <div class="btnGroup">
            <button
              class="actionBtn addBtn cursorP"
              type="button"
              @click="handleAdd"
            >
              추가
            </button>
            <button
              class="actionBtn deleteBtn cursorP"
              type="button"
              @click="handleDelete"
            >
              탈퇴
            </button>
          </div>
        </template>

        <!-- <div class="addBtn" v-if="props.pAddBtn">
          <button
            class="cursorP addBtnWrap"
            type="button"
            @click="openFileAddKnowPop"
          >
            + 파일 지식
          </button>
        </div> -->
        <div class="dropdown" v-if="props.pAddBtn">
          <button class="dropdown-button" @click="toggleDropdown">
            + 파일 지식
          </button>
          <ul v-if="showFileSelectBox" class="dropdown-content">
            <li @click="selectFileType('pdf')">PDF 파일</li>
            <li @click="selectFileType('excel')">엑셀 파일</li>
          </ul>
        </div>
      </div>
      <!-- 파일 선택 모달 -->
    </template>
    <template v-if="props.pSecondTabs">
      <div class="secondTabWrap flexAlign">
        <div class="secondTab flexAlign" style="width: 1000%">
          <div
            @click="changeSecondTab(index)"
            :class="{ selected: props.pNowSecondTab == index }"
            class="eachSecondTab cursorP"
            v-for="(tab, index) in props.pSecondTabs"
            :key="index"
          >
            {{ tab }}
          </div>
        </div>
      </div>
    </template>
    <!-- <template v-if="pOptions && pNowTab !== 2">
      <div class="searchArea flexAlign">
        <div class="options flexAlign">
          <select
            class="cursorP"
            v-model="setFilter"
            id="memberSelect"
            @change="changeFilter($event.target.value)"
          >
            <option
              v-for="(opt, index) in pOptions"
              :key="index"
              :value="opt.value"
            >
              {{ opt.name }}
            </option>
          </select>
          <select id="memberArray" class="cursorP">
            <option value="a">등록순</option>
            <option value="b">오래된순</option>
            <option value="b">이름순</option>
          </select>
        </div>
        <div class="search">
          <input
            type="text"
            id="searchByText"
            v-model="searchText"
            placeholder="검색어를 입력하세요."
          />
          <button type="button" @click="doSearch" class="searchBtn btn cursorP">
            검색
          </button>
        </div>
      </div>
    </template> -->
  </div>
</template>

<style lang="scss" scoped>
.ActWrap {
  width: 100%;
  margin-bottom: 20px;

  & .addBtn {
    white-space: nowrap;
    margin-left: 10px;
    /* margin-right: 15px; */
  }

  & .subTabWrap {
    width: 100%;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid gray;

    & .subTab {
      width: 80%;
      /* display: inline-block; */
      justify-content: start;
      font-weight: bold !important;

      & .eachTab {
        /* width: 25%; */
        max-width: 298px;
        text-align: center;
        padding: 14px 10px 10px;

        &.selected {
          border-bottom: 2px solid gray;
          font-weight: bold !important;
        }
      }
    }
  }

  & .secondTabWrap {
    width: 100%;
    justify-content: space-between;
    background-color: #fff;

    & .secondTab {
      width: 80%;
      /* display: inline-block; */
      justify-content: start;
      gap: 15px;
      padding: 15px 10px 0;

      & .eachSecondTab {
        /* width: 15%; */
        max-width: 298px;
        text-align: center;
        padding: 4px 9px;
        border: 2px solid lightgray;
        background-color: rgb(230, 230, 230);
        border-radius: 10px;

        &.selected {
          border: 2px solid gray;
          background-color: #fff;

          font-weight: bold;
        }
      }
    }
  }

  & .searchArea {
    width: 100%;
    justify-content: space-between;
    padding: 15px;
    background-color: #fff;

    & .options {
      justify-content: start;
      gap: 5px;

      & select {
        padding: 5px 10px;
        height: 30px;
      }
    }
  }
  & input {
    height: 30px;
    margin-right: 5px;
  }
  & button {
    height: 30px;
  }
}

// 모바일 반응형 CSS
@media (max-width: 768px) {
  .ActWrap {
    & .subTabWrap {
      & .subTab {
        width: 100%;
        & .eachTab {
          padding: 10px 5px;
          font-size: 14px;
        }
      }
    }

    & .secondTabWrap {
      & .secondTab {
        width: 100%;
        & .eachSecondTab {
          width: auto;
          padding: 5px 5px;
          font-size: 12px;
        }
      }
    }

    & .searchArea {
      flex-direction: column;
      & .options,
      & .search {
        width: 100%;
        & select,
        & input,
        & button {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
}
.addBtnWrap:hover {
  background-color: #c3c3c3dd;
}
.addBtnWrap {
  margin-left: 10px;
}

.file-select-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid gray;
  padding: 20px;
  z-index: 1000;
}

.modal-content button {
  margin: 5px;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-left: 15px;
}

.dropdown-content {
  position: absolute;
  top: 100%; /* 버튼 바로 아래에 위치 */
  left: 0;
  background-color: #f9f9f9;
  max-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5);
  z-index: 1;
  padding: 0;
  list-style-type: none;
  border-radius: 5px;
}

.dropdown-content li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content li:hover {
  background-color: #595959;
  color: white;
}

// 수빈 지식 권한 관리 css 추가
.btnGroup {
  display: flex;
  gap: 10px;
  margin-right: 10px;
}

.actionBtn {
  height: 35px !important;
  padding: 0 20px;

  &.addBtn {
    padding: 0 20px;
    background-color: #4a4a4a;
    height: 35px;
    color: white;
    font-weight: 600;
  }

  &.deleteBtn {
    background-color: rgb(230, 230, 230);
    color: #000000;
    height: 35px;
    font-weight: 600;
  }
}
</style>
