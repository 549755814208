<!-- ManageCheckListCompo.vue -->

<script setup lang="ts">
import { DialogRoot } from "radix-vue";
import { computed, onMounted, ref } from "vue";
import { useContentsStore } from "../../../stores/content";
import { useStore } from "../../../stores/store";
/* 컴포넌트 */
import CheckAnswers from "./CheckAnswerCompo.vue";
import CheckAsks from "./CheckAsksCompo.vue";
import CheckKnowsCopmo from "./CheckKnowsCompo.vue";
import DeletedAsks from "./DeletedAsksCompo.vue";
import AnswerQuestionManagePop from "./popups/AnswerQuestionManagePop.vue";
import KnowDetailModiPop from "./popups/KnowApproveDetailModiPop.vue";
import SubTabsCompo from "./SubTabsCompo.vue";
import type { KnowOrg, MsgCheck } from "../../../api/types";

// const contentStore = useContentsStore();
const store = useStore();

interface Props {
  checkNowTab: number;
  thirdTabs: number;
  updateCheckTab: (value: number) => void;
}
const props = defineProps<Props>();
// const nowTab = ref(props.checkNowTab);

//지식 검토
// const wrapTabs = ref(["질문 검토 목록", "지식 검토 목록"]);
const wrapTabs = computed(() => [
  `검토 질문 (${store.activeQuestionList.length}) `,
  `검토 지식 (${store.activeKnowCheckList.length}) `
]);
const filterOptions = ref([
  { name: "전체", value: "default" },
  { name: "출처", value: "from" },
  { name: "작성자", value: "creator" }
]);
const nowFilter = ref("default");
const changeFilterOption = (value) => {
  nowFilter.value = value;
};

const questionSecondTab = ref(0);
const knowSecondTab = ref(0);

const secondWrapTabs = computed(() => {
  if (props.checkNowTab === 0) {
    // return ["요청질문", "답변완료", "거절됨"];
    return ["진행중", "완료", "거절"];
  }
  // return ["요청지식", "승인완료", "거절됨"];
  return ["진행중", "완료", "거절"];
});

// 질문 검토 목록
// 지식 검토 목록
// const nowSecondTab = computed(() => {
//   if (props.checkNowTab === 0) {
//     console.log("질문 검토목록 불러오기!!!!");
//     store.updateChannelQuestionList(store.currentChannel?.channel_key);
//     return questionSecondTab.value;
//   }
//   store.updateKnowCheck(store.currentChannel?.channel_key);
//   console.log("지식 검토목록 불러오기!!!!");
//   return knowSecondTab.value;
// });

const nowSecondTab = computed(() => {
  updateList();
  return props.checkNowTab === 0
    ? questionSecondTab.value
    : knowSecondTab.value;
});

const updateList = async () => {
  if (props.checkNowTab === 0) {
    console.log("질문 검토목록 불러오기!");
    await store.updateChannelQuestionList(store.currentChannel?.channel_key);
  } else {
    if (store.currentChannel?.channel_key) {
      await store.updateKnowExpertCheck(
        store.currentChannel.channel_key,
        knowSecondTab.value
      );
    }
  }
};

const changeSecondTab = async (index: number) => {
  if (props.checkNowTab === 0) {
    questionSecondTab.value = index;
  } else {
    knowSecondTab.value = index;
    if (store.currentChannel?.channel_key) {
      await store.updateKnowExpertCheck(
        store.currentChannel.channel_key,
        index
      );
    }
  }
  console.log("nowSecondTab.value", nowSecondTab.value);
};

const secondKnowTabItems = computed(() => {
  if (nowSecondTab.value === 0) {
    return store.activeKnowCheckList;
  } else if (nowSecondTab.value === 1) {
    return store.answeredKnowCheckList;
  } else {
    return store.rejectedKnowCheckList;
  }
});

const answerQuePopYn = ref(false);
const selectedQue = ref<MsgCheck>();
const openAnswerQuePop = (item: MsgCheck) => {
  answerQuePopYn.value = true;
  selectedQue.value = item;
  // 전체 페이지 리로드
};
const closeAnswerQuePop = () => {
  answerQuePopYn.value = false;
};

const checkKnowsPopYn = ref(false);
const knowItem = ref<KnowOrg>();
const knowItemIndex = ref(0);
const openCheckKnowsPop = (item: KnowOrg, index: number) => {
  knowItem.value = item;
  knowItemIndex.value = index;
  checkKnowsPopYn.value = true;
  // checkKnowsYn.value = true
};
const closeCheckKnowsPop = () => {
  checkKnowsPopYn.value = false;
};

const userRole = computed(() => store.userRole);
</script>

<template>
  <div class="checkWrap">
    <SubTabsCompo
      :checkNowTab="props.checkNowTab"
      :pThirdTab="props.thirdTabs"
      :pNowTab="props.checkNowTab"
      :pSecondTabs="secondWrapTabs"
      :pNowSecondTab="nowSecondTab"
      :pOptions="filterOptions"
      :pTabs="wrapTabs"
      @update-tab="props.updateCheckTab"
      @update-filter="changeFilterOption"
      @update-second-tab="changeSecondTab"
    />

    <div class="checkItems">
      <template v-if="props.checkNowTab == 0">
        <template v-if="nowSecondTab == 0">
          <DialogRoot v-model:open="answerQuePopYn">
            <AnswerQuestionManagePop
              :pSelectedQue="selectedQue"
              :pClosePop="closeAnswerQuePop"
            ></AnswerQuestionManagePop>
            <CheckAsks
              :pAsks="store.activeQuestionList"
              @open-answer-que-pop="openAnswerQuePop"
            />
          </DialogRoot>
        </template>
        <!-- <DialogRoot v-model:open="answerQuePopYn">
          <AnswerQuestionManagePop
            :pSelectedQue="selectedQue"
            :pClosePop="closeAnswerQuePop"
          ></AnswerQuestionManagePop>
        </DialogRoot> -->
        <template v-if="nowSecondTab == 1">
          <CheckAnswers :pAnswers="store.repliedQuestionList" />
        </template>
        <template v-if="nowSecondTab == 2">
          <DeletedAsks :pAsks="store.rejectedQuestionList" />
        </template>
      </template>
      <template v-if="props.checkNowTab == 1">
        <DialogRoot v-model:open="checkKnowsPopYn">
          <KnowDetailModiPop
            :pCheckKnowsPopYn="checkKnowsPopYn"
            :pSelectedKnow="knowItem"
            :pSelectedKnowIndex="knowItemIndex"
            :pCheckKnowsYn="true"
            :pClosePop="closeCheckKnowsPop"
            :pDetailKnowPopYn="false"
          ></KnowDetailModiPop>
          <CheckKnowsCopmo
            :pKnows="secondKnowTabItems"
            @open-know-detail-pop="openCheckKnowsPop"
          />
        </DialogRoot>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.checkItems {
  padding: 0 5px;
}
</style>
