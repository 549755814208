<script setup lang="ts">
import AddIcon from "@fluentui/svg-icons/icons/add_24_regular.svg";
import CloseIcon from "@fluentui/svg-icons/icons/dismiss_24_regular.svg";
import HomeIcon from "@fluentui/svg-icons/icons/home_24_regular.svg";
import SearchIcon from "@fluentui/svg-icons/icons/search_24_regular.svg";
import { storeToRefs } from "pinia";
import {
  DialogRoot,
  DialogTrigger,
  SwitchRoot,
  SwitchThumb,
  TabsContent,
  TabsIndicator,
  TabsList,
  TabsRoot,
  TabsTrigger
} from "radix-vue";
import { computed, inject, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useContentsStore } from "../../stores/content";
import { useStore } from "../../stores/store";
import { useTokenStore } from "../../stores/token";
import { sidebarKey } from "../common/sidebar/SidebarRoot.vue";

/* 컴포넌트 */
import ChannelDetailPop from "../../popups/ChannelDetailPop.vue";
import SidebarContent from "../common/sidebar/SidebarContent.vue";
import SidebarPortal from "../common/sidebar/SidebarPortal.vue";
import SidebarTrigger from "../common/sidebar/SidebarTrigger.vue";
import AllChannelList from "./sidebarChannelList/AllChannelList.vue";
import SearchAreaCompo from "./sidebarChannelList/ChannelListSearchAreaCompo.vue";
import MyChatRoomList from "./sidebarChannelList/MyChattRoomList.vue";
import ChannelAddDialog from "../../popups/ChannelAddDialog.vue";
import MyMsgBookList from "./sidebarChannelList/MyMsgBookList.vue";

const route = useRoute();

const router = useRouter();
const context = inject(sidebarKey);

const contentStore = useContentsStore();

if (!contentStore.myMsgSearchText) {
  contentStore.myMsgSearchText = "";
}

interface Props {
  disableOutsidePointerEvents?: boolean;
}
const props = defineProps<Props>();

const store = useStore();
const tokenStore = useTokenStore();

const switchState = ref(false);

const showMyChannelList = () => {
  switchState.value = !switchState.value;
  console.log(switchState.value);
};

const { allChannelList } = storeToRefs(store);

const getInnerContentHeight = computed(() => {
  return allChannelList.value.length * 63;
});

const setZeroSearchText = () => {
  contentStore.searchText = "";
  contentStore.myMsgSearchText = "";
};

const channelDetailPopOpen = ref<boolean>(false);
const channelAddDialogOpen = ref<boolean>(false);

const openDetailPop = () => {
  channelDetailPopOpen.value = true;
};

const closeDetailPop = () => {
  channelDetailPopOpen.value = false;
};

// 기존 함수 수정
const openGlobalChannelDialog = () => {
  // 사이드바 닫기
  context?.toggleEnabled();

  store.showChannelAddDialog = true;
};

const openChannelAddDialog = () => {
  channelAddDialogOpen.value = true;
};

const closeChannelAddDialog = () => {
  channelAddDialogOpen.value = false;
};

const moveToManagePage = (channelKey: number) => {
  router.push(`/manage/${channelKey}`);
};
const selectedChannelInfo = ref({});
const setSelectedChannel = (value: object | object[]) => {
  selectedChannelInfo.value = value;
  changePageOrPop();
};

const changePageOrPop = () => {
  if (
    selectedChannelInfo.value.auth_type &&
    selectedChannelInfo.value.auth_type == "A"
  ) {
    moveToManagePage(selectedChannelInfo.value.channel_key);
    context?.toggleEnabled();
    store.currentChannel = selectedChannelInfo.value;
  } else {
    openDetailPop();
  }
};

// watch(
//   () => selectedChannelInfo.value,
//   () => {
//     changePageOrPop();
//   }
// );
watch(
  // 채널 상세 팝업에서 그냥 팝업을 껐으면 기존에 선택된 채널로 다시 교체
  () => channelDetailPopOpen.value,
  (newVal) => {
    if (newVal == false) {
      store.selectedChannel = store.currentChannel;
    }
  }
);

const goToHomepage = () => {
  setZeroSearchText();
  router.push("/");
  sidebarContext?.toggleEnabled();
};

const isNsuUser = computed(() => {
  return (
    (route.path.includes("/talk/channel?chKey=250") ||
      store.currentChannel?.channel_key == "250") &&
    store.myInfo?.nick == "nsu"
  );
});

const sidebarContext = inject(sidebarKey);

const addChannel = () => {
  sidebarContext?.toggleEnabled();
};

const showSearchArea = ref(false);
const toggleSearchArea = () => {
  showSearchArea.value = !showSearchArea.value;
};
</script>
<template>
  <SidebarPortal>
    <div class="DialogOverlay" v-if="context.enabled.value"></div>
    <SidebarContent
      as="aside"
      class="channelSidebar"
      :disable-outside-pointer-events="
        props.disableOutsidePointerEvents ||
        channelDetailPopOpen ||
        channelAddDialogOpen
      "
    >
      <SidebarTrigger
        style="margin-top: -15px !important"
        class="sidebarTrigger"
      >
        <!-- <CloseIcon class="cursorP" /> -->
      </SidebarTrigger>
      <template v-if="tokenStore.isLogin">
        <TabsRoot
          v-model="store.channelTabIndex"
          :default-value="store.TAB_LIST.MY_CHANNEL"
          class="TabsRoot"
        >
          <TabsList class="TabsList" @click="setZeroSearchText">
            <!-- <TabsTrigger
              :value="store.TAB_LIST.MY_CHANNEL"
              class="TabsTrigger flexJustify flexAlign"
            >
              구독 채널
            </TabsTrigger>
            <TabsTrigger
              :value="store.TAB_LIST.ALL_CHANNEL"
              class="TabsTrigger"
            >
              전체 채널
              <DialogTrigger @click="addChannel" class="channelPlus"
                ><AddIcon
              /></DialogTrigger>
            </TabsTrigger> -->
            <!-- <CloseIcon class="cursorP" @click="context?.toggleEnabled()" /> -->
            <TabsTrigger
              :value="store.TAB_LIST.MY_CHANNEL"
              class="TabsTrigger flexJustify flexAlign"
            >
              <CloseIcon class="cursorP" @click="context?.toggleEnabled()" />

              <!-- 마이페이지 -->
              설정
              <div class="homeBtn">
                <button @click="goToHomepage"><HomeIcon /></button>
              </div>
            </TabsTrigger>
            <!-- <TabsIndicator class="TabsIndicator">
              <div class="TabsIndicator_Item" />
            </TabsIndicator> -->
          </TabsList>

          <!-- 사이드바 -->
          <TabsContent
            :value="store.TAB_LIST.MY_CHANNEL"
            class="tabInnerContents"
          >
            <div class="sidebarSections">
              <!-- 구독 채널 리스트 -->
              <div class="sidebarSection">
                <div class="subscriptionHeader">
                  <!-- <h2>구독채널</h2> -->
                  <p class="titleFont">구독채널</p>
                  <div class="channelActions">
                    <div class="searchInputWrapper">
                      <input
                        type="text"
                        v-model="contentStore.searchText"
                        placeholder="검색"
                        class="searchInput"
                      />
                      <SearchIcon class="searchIcon" />
                    </div>
                    <!-- 기존 채널 생성 팝업 -->
                    <!-- <DialogTrigger @click="addChannel" class="iconButton">
                      <AddIcon />
                    </DialogTrigger> -->
                    <!-- <DialogRoot v-model:open="channelAddDialogOpen">
                      <DialogTrigger @click="addChannel" class="iconButton">
                        <AddIcon />
                      </DialogTrigger>
                      <ChannelAddDialog />
                    </DialogRoot> -->
                    <button @click="openGlobalChannelDialog" class="iconButton">
                      <AddIcon />
                    </button>
                  </div>
                </div>
                <div class="chatRoomList">
                  <MyChatRoomList />
                </div>
              </div>
              <!-- ======================= -->
              <!-- 메세지 보관함 -->
              <div class="sidebarSection">
                <div class="subscriptionHeader">
                  <p class="titleFont">보관함</p>
                  <div class="channelActions">
                    <div class="searchInputWrapper">
                      <input
                        type="text"
                        v-model="contentStore.myMsgSearchText"
                        placeholder="검색"
                        class="searchInput"
                      />
                      <SearchIcon class="searchIcon" />
                    </div>
                  </div>
                </div>
                <div class="myMsgBook">
                  <MyMsgBookList />
                  <!-- 준비중인 기능입니다. -->
                </div>
              </div>
            </div>
          </TabsContent>

          <DialogRoot v-model:open="channelDetailPopOpen">
            <ChannelDetailPop
              :pSelectedChannelInfo="selectedChannelInfo"
              :pCloseDetailPop="closeDetailPop"
              :isPopYn="true"
            ></ChannelDetailPop>
          </DialogRoot>

          <!-- 전체채널 -->
          <!-- <TabsContent
            :value="store.TAB_LIST.ALL_CHANNEL"
            class="tabInnerContents"
          >
            <div class="homeBtn">
              <button @click="goToHomepage">홈페이지 바로가기</button>
            </div>
            <SearchAreaCompo></SearchAreaCompo>
            <div
              v-if="store.TAB_LIST.ALL_CHANNEL"
              class="showMyChannel flexAlign"
            >
            </div>
            <div>
              <AllChannelList
                :pSwitchState="switchState"
                :pOpenDetailPop="openDetailPop"
                @update-channel-info="setSelectedChannel"
              />

              <DialogRoot v-model:open="channelDetailPopOpen">
                <ChannelDetailPop
                  :pSelectedChannelInfo="selectedChannelInfo"
                  :pCloseDetailPop="closeDetailPop"
                  :isPopYn="true"
                ></ChannelDetailPop>
              </DialogRoot>
            </div>
          </TabsContent> -->
          <!-- <div v-if="store.channelTabIndex == 1" class="gotoBtn flexJustify">
            <router-link
              to="/all"
              class="goAllChannelList"
              @click="sidebarContext?.toggleEnabled()"
            >
              전체 채널 목록 둘러보기
            </router-link>
          </div> -->
          <!-- <TabsContent :value="store.TAB_LIST.MY_CHANNEL">
            <MyChannelList />
          </TabsContent>
          <TabsContent :value="store.TAB_LIST.ALL_CHANNEL">
            <AllChannelList />
          </TabsContent> -->
        </TabsRoot>
      </template>
      <AllChannelList v-else />
    </SidebarContent>
  </SidebarPortal>
</template>
<style scoped lang="scss">
@use "../../styles/commons";
@use "../../styles/responsive";
@use "./header";

button {
  box-shadow: none;
}

.tabInnerContents {
  // overflow-y: scroll;
  height: calc(100vh - 97px);
}

.sidebarSections {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebarSection {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #eee;
}

.sidebarSection:last-child {
  border-bottom: none;
}

.chatRoomList,
.myMsgBook {
  flex: 1;
  overflow-y: auto;
  min-height: 0;
}

.gotoBtn {
  padding-top: 10px;
}
.goAllChannelList {
  width: 80%;
  height: 50px;
  text-align: center;
  cursor: pointer;
}

.showMyChannel {
  width: 100%;
  justify-content: end;
  padding: 0 40px 0 30px;
  gap: 10px;

  & #airplane-mode {
    width: 30px;
    height: 25px;
    background-color: var(--black-a9);
    border-radius: 9999px;
    position: relative;
    box-shadow: 0 2px 10px var(--black-a7);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}
.channelSidebar {
  border-right: 1px solid commons.$mainBorderColor;
  overflow: scroll;
}

.channelSidebar::-webkit-scrollbar {
  display: none;
}

.subheader__sidebar {
  margin-top: var(--header-logo-half-height);
}

.sidebarTrigger {
  z-index: 1;
  margin-left: -15px;
}

@media (min-width: 480px) {
  /* .sidebarTrigger {
    display: none;
  } */
}

.channelPlus {
  margin-inline-start: 15px;
  cursor: pointer;
  font-weight: inherit;
}

.TabsRoot {
  display: flex;
  flex-direction: column;
}

.TabsList {
  flex-shrink: 0;
  display: flex;
  border-bottom: 1px solid commons.$mainBorderColor;
  // margin-bottom: 20px;
  /* padding: 20px; */
  padding: 8px;
  position: relative;
  /* overflow: scroll; */
}

.TabsIndicator {
  --radix-tabs-indicator-size: 50%;

  position: absolute;
  left: 0;
  height: 2px;
  bottom: 0;
  width: var(--radix-tabs-indicator-size);
  transform: translateX(var(--radix-tabs-indicator-position));
  border-radius: 9999px; /* rounded-full equivalent */
  transition-property: width, transform;
  transition-duration: 300ms;
}
.TabsIndicator_Item {
  width: 100%;
  height: 100%;
  box-shadow:
    inset 0 -1px 0 0 currentColor,
    0 1px 0 0 currentColor;
}

$postionCorrection: 3px;
.TabsTrigger {
  justify-content: space-between;
  background-color: transparent;
  padding: 0 20px;
  /* height: var(--header-height); */

  flex: 1;
  font-size: 1.3rem !important;
  user-select: none;
  border: none;
  transform: translateY($postionCorrection);

  &:first-child {
    /* margin-left: 80px; */
    border-top-left-radius: 6px;
    padding-inline: 10px;
  }
  &:last-child {
    border-top-right-radius: 6px;
  }
  &:hover {
    font-weight: 700;
  }
  &[data-state="active"] {
    font-weight: 700;
    // box-shadow:
    //   inset 0 -1px 0 0 currentColor,
    //   0 1px 0 0 currentColor;
  }
  &:focus {
    position: relative;
  }
}
.homeBtn {
  // width: 50%;
  // padding: 0px 30px 10px 30px;
  // padding: 0px 0px 10px 0px;
}
.homeBtn button {
  width: 100%;
  // background-color: #545454;
  background-color: white;
  // color: white;
  font-weight: bold;
}

.homeBtn button:hover {
  width: 100%;
  background-color: #cecece;
  color: white;
  font-weight: bold;
}

// .cursorP:hover {
//   // width: 100%;
//   background-color: #cecece;
//   color: white;
//   font-weight: bold;
// }

.subscriptionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 20px;
  // margin-bottom: 10px;
  // border-bottom: 1px solid #666;
  box-shadow: 0 4px 6px -6px rgba(0, 0, 0, 0.3);
  position: relative;
}

.titleFont {
  font-size: 1.3rem;
  font-weight: 700;
}

.searchInputWrapper {
  position: relative;
  flex-grow: 1;
  margin: 0 15px;
  max-width: 200px;
}

.searchIcon {
  position: absolute;
  // left: 8px;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  pointer-events: none;
}

.searchInput {
  width: 100%;
  // padding: 6px 10px 6px 30px;
  padding: 6px 35px 6px 25px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 0.9rem;
  outline: none;
  transition: border-color 0.2s;
}

.searchInput:focus {
  border-color: #aaa;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

.channelActions {
  display: flex;
  gap: 10px;
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  // border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconButton:hover {
  background-color: #f0f0f0;
}

.chatRoomList {
}

// 모바일 대응
@media (max-width: 480px) {
  .channelActions {
    display: flex;
    // gap: 5px;
    max-width: 230px;
  }

  .searchInputWrapper {
    margin: 0 5px;
  }
}
</style>
