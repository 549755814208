<!-- QuestionBox -->
<script setup lang="ts">
//=========================== import영역
import { parse } from "best-effort-json-parser";
import { useQuasar } from "quasar";
import { DialogOverlay, DialogRoot,DialogTrigger, RadioGroupItem,RadioGroupRoot,SwitchRoot,SwitchThumb,} from "radix-vue";
//최초 진입시 로그인
// import { computed, onMounted, reactive, ref, watch } from "vue";
import {  loginIdPw, type  Credentials, getMyInfo} from "../../api/backend";
// import { Msg, Token } from "../../api/types";
import { computed, nextTick, onMounted, onUpdated, reactive, ref, watch, onBeforeUnmount, toRaw } from "vue";
import { createMsg, requestKmsAnswerStream, type ReqResponseStream } from "../../api/backend";

import { DayMsg, Msg, SetMsg, Token} from "../../api/types";

import { useStore } from "../../stores/store";
import { useTokenStore } from "../../stores/token";
import { elapsedTime } from "../../utils/date";
import {createDateString,createFakeKey,useTransition} from "../../utils/hooks";
import { LANGUAGE } from "../../api/backend";
import SearchBox from "./SearchBox.vue";
import MarkdownRenderer from "../common/MarkdownRenderer.vue";
import { useSpeechRecognition, useTTS, MicStatus } from "./sound";
import type { SpeechRecognition } from "../../types/SpeechRecognition";
import PrivateChannelDialog from "../root/PrivateChannelDialog.vue";
// qr , 링크 복사
import QRCode from 'qrcode';
import router from "../../routers";
import ReferKnowPop from "../root/channelManagement/popups/ReferKnowPop.vue";

import buttonHomepage from "../../assets/nsu_homepage.png"

//=========================== fluentui icon 
import SwapIcon from "@fluentui/svg-icons/icons/arrow_swap_16_regular.svg";
import docCopy from "@fluentui/svg-icons/icons/document_copy_20_regular.svg";
import heartIcon from "@fluentui/svg-icons/icons/heart_20_regular.svg";
import shareIcon from "@fluentui/svg-icons/icons/share_20_regular.svg";
import retryIcon from "@fluentui/svg-icons/icons/arrow_sync_16_regular.svg";
import deleteIcon from "@fluentui/svg-icons/icons/delete_16_regular.svg";
import SpeakYesIcon1 from "@fluentui/svg-icons/icons/speaker_2_20_regular.svg";
import SpeakYesIcon from "@fluentui/svg-icons/icons/speaker_1_20_regular.svg";
import HourGlassIcon from "@fluentui/svg-icons/icons/hourglass_20_regular.svg";
import StopRecoIcon from "@fluentui/svg-icons/icons/record_stop_20_regular.svg";
import SettingIcon from "@fluentui/svg-icons/icons/settings_20_regular.svg";
import QrCodeIcon from "@fluentui/svg-icons/icons/qr_code_24_regular.svg";
import LinkCopyIcon from "@fluentui/svg-icons/icons/link_multiple_24_regular.svg";
import Homepage from "@fluentui/svg-icons/icons/home_24_regular.svg";
import DocentIcon from "@fluentui/svg-icons/icons/person_20_regular.svg";
import AttachIcon from "@fluentui/svg-icons/icons/attach_20_regular.svg";
import LinkIcon from "@fluentui/svg-icons/icons/link_multiple_20_regular.svg";
import LikeIcon from "@fluentui/svg-icons/icons/thumb_like_20_regular.svg";
import Document from "@fluentui/svg-icons/icons/document_20_regular.svg";
import QuestionIcon from "@fluentui/svg-icons/icons/question_circle_20_filled.svg";
import { watchEffect } from "vue";
import { useRoute } from "vue-router";
import CoTKnowPop from "../root/channelManagement/popups/CoTKnowPop.vue";
import ChannelAddDialog from "../../popups/ChannelAddDialog.vue";

//=========================== store관리
const store = useStore();
const tokenStore = useTokenStore();
const $q = useQuasar();




//=========================== interface type관리 
interface MessageResponse {
  // link: [
  //   {
  //     channelKey: number;
  //     cre_date: string;
  //     cre_user_key: number;
  //     delete_yn: number;
  //     link_body: string;
  //     link_key: number;
  //     link_url: string;
  //     link_title: string;
  //   }
  // ];
  menu : [
    {
      channelKey: number;
      cre_date: string;
      cre_user_key: number;
      delete_yn: number;
      link_body: string;
      link_key: number;
      
      link_url: string;
      // link_title: string;
      link_name: string;
      repo_link_key: number;
    }
  ],

  call_info : [
    {
      cmd : string;
      sub_string: string;
    }
  ]

  attach_file_string : [
    {
      file_key: number;
      file_name: string;
      file_enc_path: string;
    }
  ]

  img_file_string : [
    {
      file_key: number; 
      file_name: string;
      file_enc_path: string;
    }
  ]

  body_img_path_string: string[],

  must_text_string : string[],
  
  knowledge: [
    {
      know: string;
      metadata: {
        id: string;
        score: number;
        cre_date: string;
      };
    }
  ];
  body: string;
  keyword_string: string[];
  quest_string: string[];
  msg_key?: number;
  res_msg_key: number;
  msg_item_str: string
}

export interface msg {
  msg_key: number;
  room_key: number;
  parent_key: number;
  body: string;
  cre_user_key: number;
  cre_date: string;
  delete_yn: number;
}

export interface imageFile {
  img_key: number;
  img_url: string;
}
//===========================
const emits = defineEmits(['hideMainBox', 'scroll-change']);

//=========================== 반응형 state 변수 관리
const sttLoading = ref(false);
const referKnowPopYn = ref(false);
const CoTKnowPopYn = ref(false);
const questionInput = ref<string>("");
const currentMsgKey = ref<number>(0);

// 채팅 리스트들 순서대로 할지 역순으로 할지 동적으로 설정하는 곳
// const reverseOrder = ref(true); // 기본적으로 오래된 날짜가 위로
const reverseOrder = ref(false); // 기본적으로 오래된 날짜가 위로

const isAnsweredYn = ref(false)

const resResult = ref("");
// const lastResponseMessageKey = ref<number>();
// const lastResponseMessageKey = ref<number | undefined>();
const lastResponseMessageKey = ref<number[]>([]);
const makeAnswerDone = ref(false);
const makingAnswer = ref(false);
const ttsOption = ref<number>(0);
const selectedLanguage = ref(LANGUAGE.KOREAN.code);
const knowMode = ref<"accuracy" | "count">("count");
const knowScope = ref<ReqResponseStream["know_scope"]>("independent");
const multiQuery = ref(false);
const likeIndex = ref<number[]>([]);
//---------------- 채팅방 채팅 설정 비밀번호 검사 로직
const pTitleValue = ref("비밀번호");
const password = ref("");
const mockupPw = ref("4217");
// const collectPwYn = ref(false);
const collectPwYnMap = ref<{ [key: number]: boolean }>({}); // 채널별 인증 상태
// 파싱 결과를 저장할 Map 추가
const parsedMsgItemCache = ref(new Map());

const privateChatDialog = ref(false);
const channelKey = ref(store.currentChannel?.channel_key || 0);
const chattingOption = ref(false)
const kindOf = ref("채팅");
// 팝업 표시 상태
const isPopupVisible = ref(false);
const qrCodeUrl = ref<string | undefined>(undefined);
const mainElement = ref<HTMLElement | null>(null); // HTMLElement 타입 지정
// 파일 목록 표시 상태 관리
const isFileListVisible = ref(false);
// //최초 진입시 로그인 
const credentials = reactive<Credentials>({
  username: "",
  password: ""
});


const status = ref<boolean>();

const knowScoreComputed = computed(() =>
  knowMode.value === "accuracy" ? KNOW_SCORE.high : 1
);

// mainBox의 움직임에 따라 스크롤 동적 이동
const isMainBoxVisible = ref(false);

onMounted(async() => {
  // scrollToBottomSmoothly(false);
  window.addEventListener('toggle-mainbox', ((event: CustomEvent) => {
    isMainBoxVisible.value = event.detail.isVisible;
  }) as EventListener);

  // await checkAndSendInitialQuestion()
  // await checkAndSendInitialQuestion()
});

onBeforeUnmount(() => {
  window.removeEventListener('toggle-mainbox', (() => {}) as EventListener);
});

// store.newMessageMap을 감시하여 변경될 때마다 실행
watch(
  () => store.newMessageMap.value, // 감시할 대상
  async (newValue, oldValue) => {
    console.log("store.newMessageMap 변경됨:", newValue);
    // 여기에 업데이트 반영하는 로직 추가 가능
    await checkAndSendInitialQuestion()
  },
  { deep: true } // 깊은 감지 활성화 (내부 객체 변경도 감지)
);

// 자동 질문 전송 함수
async function checkAndSendInitialQuestion() {
  // 로그인 상태이고 메시지가 없는 경우에만 자동 질문
  if (
    tokenStore.isLogin &&
    store.currentChannel?.channel_key
    // !hasExistingMessages(store.currentChannel.channel_key)
    // isFirstChannelVisit.value &&
    // (!store.arrangedMessageList || store.arrangedMessageList.length === 0)
    // (!store.newMessageMap || Object.keys(store.newMessageMap).length === 0)
    // (!store.newMessageMap)
  ) {
    console.log("채팅 내역이 없음: 자동 질문 전송");
    questionInput.value =
      // "여긴 어떤 채널이고, 무엇을 질문하고 답변받을 수 있는지 자세하게 알려줘";
      "이 채널을 소개해줘!";
    await onSearch(true);
  }
}

// 채널의 메시지 이력 확인 함수 추가
// function hasExistingMessages(channelKey: number): boolean {
//   // 모든 룸의 메시지를 확인
//   return Object.values(store.newMessageMap).some(roomMessages => 
//     roomMessages.some(dayMsg => 
//       dayMsg.s_msg_list.some(setMsg => 
//         setMsg.req?.channel_key === channelKey || 
//         setMsg.res?.channel_key === channelKey
//       )
//     )
//   );
// }

// function hasExistingMessages(channelKey: number): boolean {
//   // 디버그 로그 추가
//   console.log("✅ 메시지 존재 여부 체크: 채널키", channelKey);
  
//   // 모든 룸의 메시지를 확인
//   for (const [roomKey, roomMessages] of Object.entries(store.newMessageMap)) {
//     console.log(`✅ 룸 ${roomKey} 메시지 검사 중, 총 ${roomMessages.length} 개 날짜 그룹`);
    
//     // 해당 채널의 메시지가 있는지 확인
//     const hasMessages = roomMessages.some(dayMsg => {
//       console.log(`✅ 날짜 ${dayMsg.day} 검사 중, ${dayMsg.s_msg_list?.length || 0} 개 메시지 세트`);
      
//       return dayMsg.s_msg_list?.some(setMsg => {
//         const reqChannelKey = setMsg.req?.channel_key;
//         const resChannelKey = setMsg.res?.channel_key;
        
//         console.log(`✅ 메시지 세트 검사: req.channel_key=${reqChannelKey}, res.channel_key=${resChannelKey}`);
        
//         return reqChannelKey === channelKey || resChannelKey === channelKey;
//       }) || false;
//     });
    
//     if (hasMessages) {
//       console.log(`✅ 채널 ${channelKey}의 메시지 발견!`);
//       return true;
//     }
//   }
  
//   console.log(`✅ 채널 ${channelKey}의 메시지 없음`);
//   return false;
// }

// 동적 클래스 설정
// const inputContainerClass = computed(() => {
//   if (!tokenStore.isLogin || getGroupByDateChat.value.length > 0) {
//     return "center"; // 가운데 (bottom: 50%)
//   } else if (!answerYn){
//     return "under";
//   }
//   return ""; // 아래 (bottom: 55px)
// });
const inputContainerClass = computed(() => {
  // if (!tokenStore.isLogin || getGroupByDateChat.value.length > 0 && answerYn.value === false) {
  if (!tokenStore.isLogin  && answerYn.value === false) {
    return "center"; // 로그인 안 되었거나 getGroupByDateChat이 있으면 가운데 (bottom: 30%)
  }
  return ""; // 기본적으로 아래 (bottom: 55px)
});





//=========================== 유틸리티(헬퍼) Functions

const searched = computed(() => {
  console.log("knowledge data:", recvMsgParsed.value?.knowledge);
  if (recvMsgParsed.value?.knowledge) {
    return recvMsgParsed.value.knowledge;
  }
  return [];
});

const checkChatPw = (channel_key: number) => {
  console.log("채널 키는?", channel_key);
  if (password.value == mockupPw.value) {
    chattingOption.value = true;
    $q.notify({
      message: "채팅 설정 활성화",
      color: "positive",
      timeout: 3000
    });
    // collectPwYn.value = true;
    collectPwYnMap.value[channelKey.value] = true; // 채널의 인증 상태 업데이트
    password.value = "";
    closePrivateChatDialog();
  } else if (password.value == "") {
    $q.notify({
      message: "비밀번호를 입력해주세요.",
      color: "negative",
      timeout: 3000
    });
  } else {
    $q.notify({
      message: "비밀번호가 틀렸습니다.",
      color: "negative",
      timeout: 3000
    });
  }
};

// 메시지별 링크 정보 저장
const messageLinksMap = ref(new Map());

const recvMsgPattern = /^.*?(?=\{)|```$/s;

//받은 메시지에 JSON 데이터를 안전하게 파싱하는 유틸리티 함수
function recvMsgJson<T extends object>(content: string) {
if (!content.includes("{")) {
return {} as Partial<T>;
}
const jsonLikeText = content.replace(recvMsgPattern, "");
return parse(jsonLikeText) as Partial<T>;
}

// const recvMsgKey = computed(() => {
//   const parsedResult = recvMsgJson<MessageResponse>(resResult.value);
//   return parsedResult
// })

const recvMsgKey = computed(() => {
  if (recvMsgParsed.value?.res_msg_key) {
    return recvMsgParsed.value.res_msg_key;
  }
  return "";
});

//답변
const recvMsgParsed = computed(() => {
  const parsedResult = recvMsgJson<MessageResponse>(resResult.value);
  // console.log("====================parsedResult", parsedResult);
  return parsedResult;
});

//답변에 활용된 link정보
const linkUrls = computed(() => {
  if (recvMsgParsed.value?.menu) {
    return recvMsgParsed.value.menu;
  }
  return [];
});

const call_info = computed(() => {
  if (recvMsgParsed.value?.call_info) {
    return recvMsgParsed.value.call_info;
  }
  return [];
})

// 화면개발 목업데이터
// // 목업 이미지 데이터
// const testImg = ref({
//   img_file_key : 1,
//   img_file_name: "이미지 이름",
//   img_file_enc_path : "https://picsum.photos/200/300"
// })

// // 목업 링크 데이터
// const testLink = ref({
//   link_key: 1,
//   link_name: "남서울대학교 홈페이지",
//   link_url: "https://www.nsu.ac.kr",
// });

// // 목업 파일 데이터
// const testFiles = ref([
//   {
//     attach_file_key: 1,
//     attach_file_name: "남서울대학교 안내.pdf",
//     attach_file_enc_path: "/files/guide.pdf",
//   },
//   {
//     attach_file_key: 2,
//     attach_file_name: "입학처 연락처.pdf",
//     attach_file_enc_path: "/files/contact.pdf",
//   }
// ]);

// // 목업 키워드 데이터
// const testKeywords = ref([
//   "인공지능",
//   "빅데이터",
//   "남서울대",
//   "IT학과"
// ]);

// // 목업 필수표시 데이터
// const testMustText = ref({
//   must_key: 1,
//   must_text: "이 내용은 관련 규정에 따라 필수적으로 안내되는 사항입니다."
// });

// 이미지 목업 데이터 computed 수정
// const imageList = computed(() => {
//   if (recvMsgParsed.value?.knowledge) {
//     // 단일 이미지만 반환
//     return [testImg.value.img_file_enc_path];
//   }

//   return [];
// });

// 링크 목업 데이터 computed 수정
// const linkList = computed(() => {
//   if (recvMsgParsed.value?.knowledge) {
//     // 단일 링크만 반환
//     return [{
//       link_name: testLink.value.link_name,
//       link_url: testLink.value.link_url,
//       // link_body: testLink.value.link_body
//     }];
//   }
//   return [];
// });

// 키워드 목업 데이터 computed 수정
// const keywords = computed(() => {
//   if (recvMsgParsed.value?.knowledge) {
//     // 키워드 배열 한 번만 반환
//     return testKeywords.value;
//   }
//   return [];
// });

// const imageList = computed(() => {
//   if (recvMsgParsed.value?.knowledge) {
//     // 단일 이미지만 반환
//     return [testImg.value.img_file_enc_path];
//   }
//   return [];
// });

// // 링크 목업 데이터 computed 수정
// const linkList = computed(() => {
//   if (recvMsgParsed.value?.knowledge) {
//     // 단일 링크만 반환
//     return [{
//       link_name: testLink.value.link_name,
//       link_url: testLink.value.link_url,
//       // link_body: testLink.value.link_body
//     }];
//   }
//   return [];
// });

// // 키워드 목업 데이터 computed 수정
// const keywords = computed(() => {
//   if (recvMsgParsed.value?.knowledge) {
//     // 키워드 배열 한 번만 반환
//     return testKeywords.value;
//   }
//   return [];
// });

// 필수표시 목업 데이터를 위한 computed
// const mustText = computed(() => {
//   const knowMap = recvMsgParsed.value?.knowledge?.map((item) => 1);
//   if (knowMap) {
//     const filteredMust = knowMap.filter((value) => {
//       return value === testMustText.value.must_key;
//     });
//     return filteredMust.map(() => testMustText.value.must_text);
//   }
//   return [];
// });

// // 파일 목업 데이터를 위한 computed
// const fileList = computed(() => {
//   const knowMap = recvMsgParsed.value?.knowledge?.map((item) => 1);
//   if (knowMap) {
//     const filteredFiles = knowMap.filter((value) => {
//       return value === testFiles.value[0].attach_file_key;
//     });
//     return filteredFiles.map(() => testFiles.value);
//   }
//   return [];
// });

// 필수표시 목업 데이터를 위한 computed
// const mustText = computed(() => {
//   const knowMap = recvMsgParsed.value?.knowledge?.map((item) => 1);
//   if (knowMap) {
//     const filteredMust = knowMap.filter((value) => {
//       return value === testMustText.value.must_key;
//     });
//     return filtered;
//   }
//   return [];
// });

// 파일 목업 데이터를 위한 computed
// const fileList = computed(() => {
//   const knowMap = recvMsgParsed.value?.knowledge?.map((item) => 1);
//   if (knowMap) {
//     const filteredFiles = knowMap.filter((value) => {
//       return value === testFiles.value[0].attach_file_key;
//     });
//     return filteredFiles.map(() => testFiles.value);
//   }
//   return [];
// });

// const imageList = computed(() => {
//   if (recvMsgParsed.value?.img_file_string) {
//     return recvMsgParsed.value.img_file_string.map(img => img.file_enc_path);
//   }
//   // body_img_path_string이 있는 경우도 처리
//   if (recvMsgParsed.value?.body_img_path_string) {
//     return recvMsgParsed.value.body_img_path_string;
//   }
//   return [];
// });
// ======================================== 남서울대 bodyImg
const imageList = computed(() => {
  // const baseUrl = 'https://anytalk.com:28081/uploads/';
  const baseUrl = 'https://anytalk.com/uploads/';
  let images = [];
  
  // img_file_string 처리
  if (recvMsgParsed.value?.img_file_string) {
    images = [...images, ...recvMsgParsed.value.img_file_string.map(img => img.file_enc_path)];
  }
  
  // body_img_path_string 처리
  if (recvMsgParsed.value?.body_img_path_string) {
    images = [...images, ...recvMsgParsed.value.body_img_path_string.map(path => {
      // URL이 이미 https://로 시작하는지 확인
      return path.startsWith('https://') ? path : `${baseUrl}${path}`;
    })];
  }
  
  return images;
});

// ======================================== 링크
const linkList = computed(() => {
  if (recvMsgParsed.value?.menu) {
    return recvMsgParsed.value.menu.map(item => ({
      link_name: item.link_name,
      link_url: item.link_url
    }));
  }
  return [];
});

// ======================================== 키워드
const keywords = computed(() => {
  if (recvMsgParsed.value?.keyword_string) {
    return recvMsgParsed.value.keyword_string;
  }
  return [];
});

// ======================================== 추천 질문
const questLists = computed(() => {
  if (recvMsgParsed.value?.quest_string) {
    return recvMsgParsed.value.quest_string;
  }
  return [];
});

// ======================================== 전달받은 메시지 키
const receivedMsgKey = computed(() => recvMsgParsed.value?.res_msg_key);

// ======================================== 필수표시
const mustText = computed(() => {
  if (recvMsgParsed.value?.must_text_string) {
    return recvMsgParsed.value.must_text_string;
  }
  return [];
});

// ======================================== 첨부파일
// 첨부파일 없을 땐 화면에 표시 x
const fileList = computed(() => {
  if (recvMsgParsed.value?.attach_file_string && recvMsgParsed.value.attach_file_string.length > 0) {
    return [[...recvMsgParsed.value.attach_file_string.map(file => ({
      attach_file_key: file.file_key,
      attach_file_name: file.file_name,
      attach_file_enc_path: file.file_enc_path
    }))]]
  }
  return [];
});

// ======================================== 원본보기
// 특수기호, 특수 문자, 특수 기호 설정
const responseMsgText = computed(() => {
  if (recvMsgParsed.value?.body) {
    answerYn.value = true;
    // console.log("받은 메시지값의 body", recvMsgParsed.value.body);
    // console.log("받은 메시지값 전체", recvMsgParsed.value);
    const cleanBody = recvMsgParsed.value.body
  .replace(/[^\p{L}\p{N}\s.,|\\\-:;"'(){}~/%@#$^&*\[\]!?]/gu, "") // ✅ 허용할 문자 추가
  .replace(/^###\s*/gm, "");  // ✅ ### 제목 제거



    return " " + cleanBody;
  }
  return "";
});

const answerYn = ref(false);



// ======================================== 답변 시간순 배치
// const getGroupByDateChat = computed(() => {
//   const groupedChats = groupChatsByDate(store.arrangedMessageList);
//   const newMessageList = store.newMessageMap
//   console.log("✅✅✅✅✅✅✅✅✅============ 새롭게 가져온 message 값", newMessageList);
//   console.log("Grouped Chats (Before Sorting):", groupedChats);

//   nextTick(() => {
//     scrollToBottom();
//   });

//   return Object.fromEntries(
//     Object.entries(groupedChats).sort(([dateA], [dateB]) => new Date(dateB).getTime() - new Date(dateA).getTime())
//   );
// });

const getGroupByDateChat = computed(() => {
  const roomKey = store.currentRoom?.room_key ?? 0;
  console.log("======================== 메시지 목록", store.newMessageMap[roomKey])
  return store.newMessageMap[roomKey] || [];
});

// ========================================  STT, TTS 
const buttonClass = computed(() => {
  return {
    mikeBtn: ttsOption.value === 0,
    mikeRecording: ttsOption.value === 1,
    mikeRecordingFix: ttsOption.value === 2,
    sttLoading: sttLoading.value === true
  };
});
// ======================================== 자주하는 질문
const faqTexts = computed(() => {
  // store.currentChannel이 undefined일 때 빈 배열을 반환
  if(store.currentChannel?.faq_texts) {
    return (store.currentChannel?.faq_texts || "")
    .split("#;#")
    .filter(text => text.trim() !== "");
  } else{
    return false
  }
});

const faqContainer = ref(null); // FAQ 목록 컨테이너 참조

// FAQ 목록이 넘치는지 감지하여 버튼 표시 여부 결정
const showLeftButton = ref(false);
const showRightButton = ref(false);

const checkScrollButtons = () => {
  if (faqContainer.value) {
    showLeftButton.value = faqContainer.value.scrollLeft > 0;
    showRightButton.value =
      faqContainer.value.scrollLeft + faqContainer.value.clientWidth <
      faqContainer.value.scrollWidth;
  }
};

// 좌우 이동 함수 (부드러운 애니메이션 적용)
const scrollLeft = () => {
  if (faqContainer.value) {
    faqContainer.value.scrollBy({ left: -300, behavior: "smooth" });
    setTimeout(checkScrollButtons, 300);
  }
};
const scrollRight = () => {
  if (faqContainer.value) {
    faqContainer.value.scrollBy({ left: 300, behavior: "smooth" });
    setTimeout(checkScrollButtons, 300);
  }
};

// 요소가 로딩되었을 때, 그리고 faqTexts 변경 시 버튼 표시 여부 업데이트
onMounted(() => {
  if(!tokenStore.isLogin) {
    // scrollToTopBtn()
  }
  checkScrollButtons();
});
watchEffect(() => {
  checkScrollButtons();
});



//=========================== utils / Hooks
const { micStatus, initRecognition, startRecognition, stopRecognition } = useSpeechRecognition();
const { initTTS, runTTS, stopTTS } = useTTS();
const recognition: SpeechRecognition | null = null;
const [questionLoading, questionTransition] = useTransition();

//=========================== Props관리
const props = defineProps<{
  isGreaterMobile: boolean;
  isKnowPannelCollapsed: boolean;
}>();

// ======================================== 최초 진입시 스크롤
onMounted(() => {
  if(isNsuPage.value || !tokenStore.isLogin){
    scrollToBottom(true);
    setTimeout(scrollToBottom, 500);
  }
});

// ======================================== 최초 진입시 스크롤
onMounted(() => {
  window.addEventListener("message", (event) => {
  console.log("수신된 메시지:", event);
  // stopAxiosWatch(),
  // stopLoadingWatch()
  
  // event.origin 검증
  if (event.origin !== "https://anytalk.com") {
    console.warn("허용되지 않은 origin:", event.origin);
    return;
  }

  // 데이터 확인
  const { type, data } = event.data;
  if (type === "USER_INFO" && data) {
    console.log("받은 사용자 정보:", data);
  }
});
  generateQRCode(`https://anytalk.com/talk/channel?chKey=${store.currentChannel?.channel_key}`);
});

// 채널별 첫 방문 상태를 관리하는 상태 추가
const channelFirstVisitStatus = ref<{ [key: number]: boolean }>({});

watch(() => tokenStore.isLogin, (newVal) => {
  if (!newVal) {
    scrollToTopBtn()
  } else{
    // checkAndSendInitialQuestion()
  }
});

// ======================================== 채널 변경시 질문창 초기화
watch(
  () => store.currentChannel?.channel_key,
  async (newChannel) => {
    if (!newChannel) return;

    console.log("store.currentChannel?.channel_key", store.currentChannel?.channel_key);
    // 해당 채널의 메시지 이력 확인
    let roomKey = store.currentRoom?.room_key;
    console.log("roomKey1", roomKey);

    // if (roomKey === undefined) {
      console.warn("currentRoom이 아직 설정되지 않았습니다");
      
      // 1초 후에 다시 시도
      setTimeout(() => {
        roomKey = store.currentRoom?.room_key;
        console.log("roomKey2", roomKey);
        if (roomKey !== undefined) {
          console.log("roomKey3", roomKey);
          checkMessagesAndInitialize(newChannel, roomKey);
          // debugger;
        } else {
          console.error("여전히 roomKey를 가져올 수 없습니다. 채널:", newChannel);
        }
      }, 1000);
      return;
    // };

    // checkMessagesAndInitialize(newChannel, roomKey);
}, {immediate: true}
);

async function checkMessagesAndInitialize(newChannel, roomKey) {

  const channelMessages = store.newMessageMap[roomKey] || [];

  console.log("channelMessages",channelMessages);
  //  debugger;
   // 처음 방문하는 채널이고 메시지가 없는 경우에만 첫 방문으로 간주
   const isFirstVisit = 
      // channelFirstVisitStatus.value[newChannel.channel_key] === undefined && 
      // channelMessages.length === 0;
    toRaw(channelMessages).length === 0;

    console.log("isFirstVisit", isFirstVisit);
    console.log("toRaw(channelMessages).length", toRaw(channelMessages).length);

    // 첫 방문 상태 업데이트
    if (isFirstVisit) {
      channelFirstVisitStatus.value[newChannel] = true;
    }
    
    scrollToBottomSmoothly(false);
    if(tokenStore.isLogin) {
    // scrollToTopBtn()
  
    if (!store.currentChannel) {
      console.warn("store.currentChannel이 undefined입니다.");
      return;
    }
    // questionInput.value = "";
    // resResult.value = "";
    // lastResponseMessageKey.value = undefined;
    // lastResponseMessageKey.value = [];

    // 첫 방문이고 로그인 상태라면 자동 질문 전송
    if (
      tokenStore.isLogin && 
      isFirstVisit
    ) {
      // setTimeout(() => {
      //   checkAndSendInitialQuestion();
      // }, 500);

      questionInput.value =
      // "여긴 어떤 채널이고, 무엇을 질문하고 답변받을 수 있는지 자세하게 알려줘";
      "이 채널을 소개해줘!";
      await onSearch(true);
      // debugger;
    }
  } 
}
// ======================================== 메시지를 받으면 링크를 표기

const isLoading = ref(false);

// ✅ 스크롤을 최적화된 방식으로 호출
const scrollToBottomSmoothly = (instant = false) => {
  nextTick(() => {
    scrollToBottom(instant);
  });
};

// 채널 변경 시 첫 방문 상태 확인
// watch(
//   () => store.currentChannel,
//   async (newChannel) => {
//     if (!newChannel?.channel_key) return;

//     // 채널에 메시지 이력이 있는지 확인
//     const hasMessages = hasExistingMessages(newChannel.channel_key);

//     // 첫 방문 상태 업데이트
//     if (!hasMessages && tokenStore.isLogin) {
//       setTimeout(() => {
//         checkAndSendInitialQuestion();
//       }, 500);
//     }
//   },
//   { immediate: true }
// );

// ✅ 데이터 로딩 시 처리
watch(() => store.axios_ing_yn, (newVal) => {
  if (newVal) {
    console.log("🚀 데이터 요청 시작, 로딩 중...");

    isLoading.value = true;

    // 로딩 유지 시간 조정 (데이터가 랜더링되도록)
    setTimeout(() => {
      isLoading.value = false;
      store.axios_ing_yn = false; // 중복 실행 방지
      scrollToBottomSmoothly(false); // 부드러운 스크롤 이동
    }, 800);
  }
});

// ✅ 채널 변경 시 로딩 & 스크롤 처리
watch(() => store.currentChannel, () => {
  console.log("🔄 채널 변경됨, 화면 초기화...");

  isLoading.value = true;
  
  // 300ms 후 로딩 해제 및 스크롤 처리
  setTimeout(() => {
    isLoading.value = false;
    scrollToBottomSmoothly(true); // 즉시 하단으로 이동
  }, 600);
});

// ✅ 로딩 종료 감지 및 스크롤 처리
watch(() => isLoading.value, (newVal) => {
  if (!newVal || !tokenStore.isLogin) {
    scrollToBottomSmoothly(true);
  }
});

const KNOW_SCORE = {
  high: 0.5,
  middle: 0.7
};

// // 최초진입시 로그인
const loginWithToken = (token?: Token) => {
  console.log(token);
  if (token !== undefined) {
    status.value = true;
    tokenStore.setToken(token.access_token);
    $q.notify({ message: "로그인에 성공했습니다.", timeout: 3000});
  } else {
    status.value = false;
  }
};

//=========================== 일반 Functions

//=========================== 녹음 시작
const startSpeechRecognition = async () => {
  await initRecognition(selectedLanguage.value);
  await initTTS();
  startRecognition({
    onResult(result) {
      questionInput.value = result;
      onSearch();
    },
    async onError(e) {
      console.error("음성 인식 오류:", e.error);
      ttsOption.value = 0;
    }
  });
};

// tts 실행 중 중단 버튼 
const stopTtsFunc = () => {
  console.log("TTS 중단");
  stopTTS();
  // 음성 인식이 진행 중이면 중단
  if (recognition) {
    // stopRecording()
    stopRecognition();
  }
  ttsOption.value = 0; // TTS 옵션을 초기 상태로 변경
};

//=========================== 자주하는 질문
const usualQuestion = (question : any) => {
  questionInput.value = question;
  onSearch();
};

const smoothScroll = (targetPosition: number, duration = 800) => {
  const spanWrapContainer = document.querySelector('.spanWrap');
  if (!spanWrapContainer) return;

  const startPosition = spanWrapContainer.scrollTop;
  const distance = (targetPosition - startPosition) ;
  const startTime = performance.now();

  const animateScroll = (currentTime: number) => {
    const elapsedTime = currentTime - startTime;
    const progress = Math.min(elapsedTime / duration, 2); // 0에서 1 사이 값
    const easing = easeOutCubic(progress); // 부드러운 감속 효과

    spanWrapContainer.scrollTop = startPosition + distance * easing;

    if (progress < 1) {
      requestAnimationFrame(animateScroll);
    }
  };

  requestAnimationFrame(animateScroll);
};

// ✅ 부드러운 감속 효과
const easeOutCubic = (t: number) => 1 - Math.pow(1 - t, 3);

// ✅ 최하단으로 스크롤
const scrollToBottomBtn = () => {
  nextTick(() => {
    const spanWrapContainer = document.querySelector('.spanWrap');
    if (spanWrapContainer) {
      smoothScroll(spanWrapContainer.scrollHeight);
    }
  });
};

// ✅ 최상단으로 스크롤
const scrollToTopBtn = () => {
  nextTick(() => {
    const spanWrapContainer = document.querySelector('.spanWrap');
    if (spanWrapContainer) {
      smoothScroll(0);
    }
  });
};


const scrollToBottom = (instant = false) => {
  nextTick(() => {
    const spanWrapContainer = document.querySelector('.spanWrap');
    if (spanWrapContainer) {
      spanWrapContainer.style.scrollBehavior = instant ? 'auto' : 'smooth';
      spanWrapContainer.scrollTop = spanWrapContainer.scrollHeight;
    }
  });
};

// //=========================== 올라긱 버튼
// const scrollToTop = (instant = false) => {
//   nextTick(() => {
//     const spanWrapContainer = document.querySelector('.spanWrap');
//     if (spanWrapContainer) {
//       spanWrapContainer.style.scrollBehavior = instant ? 'auto' : 'smooth';
//       spanWrapContainer.scrollTop = 0; // 맨 위로 이동
//     }
//   });
// };

//=========================== 포커스 설정
const handleFocus = () => {
  console.log("포커스==================");
  focusMainElement();
};

//=========================== 검색 조건 검사
const isValidQuestion = () => {
  if (questionInput.value == "" || questionInput.value == undefined) {
    return false;
  }
  return true;
};

// //=========================== 질문 메시지 생성
// const createQuestionMessage = (): Msg => {
//   return {
//     msg_key: createFakeKey(),
//     room_key: store.currentRoom?.room_key ?? 0,
//     parent_key: 0,
//     body: questionInput.value ?? "",
//     cre_user_key: store.myInfo?.user_key ?? 0,
//     cre_date: createDateString(),
//     delete_yn: 0
//   };
// };

// //=========================== 응답 메시지 생성
// const createResponseMessage = (): Msg => {
//   return {
//     msg_key: createFakeKey(),
//     room_key: store.currentRoom?.room_key ?? 0,
//     parent_key: 0,
//     body: "",
//     cre_user_key: 0,
//     cre_date: createDateString(),
//     delete_yn: 0,
//     msg_item_str: "{}" // ✅ 기본적으로 빈 JSON 문자열을 포함
//   };
// };

//=========================== 질문 메시지 생성
const createQuestionMessage = (): Msg => {
  return {
    msg_key: createFakeKey(),
    room_key: store.currentRoom?.room_key ?? 0,
    parent_key: 0,
    body: questionInput.value ?? "",
    cre_user_key: store.myInfo?.user_key ?? 0,
    cre_date: createDateString(),
    delete_yn: 0,
    channel_key: store.currentChannel?.channel_key ?? 0, // ✅ 추가
    msg_item_str: "", // ✅ 추가
    unknown_yn: 0 // ✅ 추가
  };
};

//=========================== 응답 메시지 생성
// const createResponseMessage = (): Msg => {
//   return {
//     msg_key: createFakeKey(),
//     room_key: store.currentRoom?.room_key ?? 0,
//     parent_key: 0,
//     body: "",
//     cre_user_key: 0,
//     cre_date: createDateString(),
//     delete_yn: 0,
//     channel_key: store.currentChannel?.channel_key ?? 0, // ✅ 추가
//     msg_item_str: "{}", // ✅ 추가
//     unknown_yn: 0 // ✅ 추가
//   };
// };
const createResponseMessage = (parentKey: number): Msg => {
  return {
    msg_key: createFakeKey(),
    room_key: store.currentRoom?.room_key ?? 0,
    parent_key: parentKey, // ✅ 올바르게 부모 연결
    body: "",
    cre_user_key: 0,
    cre_date: createDateString(),
    delete_yn: 0,
    channel_key: store.currentChannel?.channel_key ?? 0, // ✅ 추가
    msg_item_str: "", // ✅ 추가
    unknown_yn: 0 // ✅ 추가
  };
};


//=========================== 서버 요청 데이터 구성
const buildRequestBody = (): Partial<ReqResponseStream> => {
  return {
    multi_query: multiQuery.value,
    question: questionInput.value ?? "",
    channel_key: store.currentChannel?.channel_key ?? 0,
    know_scope: knowScope.value,
  };
};

//=========================== TTS 처리
const handleTTS = async () => {
  if (ttsOption.value > 0) {
    ttsOption.value = 3;
    console.log("recognition1", recognition);
    if (responseMsgText.value) {
      console.log("response msg:", responseMsgText.value);
      runTTS(responseMsgText.value, () => {
        if (ttsOption.value === 2) {
          stopRecognition();
          startSpeechRecognition();
          console.log("startRecognition");
        } else {
          ttsOption.value = 0;
        }
        console.log("TTS 끝");
      });
    }
  }
};

const streamingStatus = ref<{ [key: number]: boolean }>({});



//=========================== 서버 응답 처리 (스트리밍)

const processServerResponse = async (reader: ReadableStreamDefaultReader, responseMessage: Msg, questionMessage: Msg) => {
  if (reader !== undefined) {
    resResult.value = "";
    let firstValueSkipped = false;
    questionInput.value = "";

    // ✅ 기존 msg_key 저장
    let oldMsgKey = responseMessage.msg_key;

    // ✅ 기존 msg_key에 대해 false 설정
    streamingStatus.value[oldMsgKey] = false;
    console.log("✅ 기존 msg_key 숨김:", oldMsgKey, streamingStatus.value);

    let userHasScrolled = false;
    const spanWrapContainer = document.querySelector('.spanWrap');

    const checkIfUserScrolledUp = () => {
      if (spanWrapContainer) {
        const maxScrollPosition = spanWrapContainer.scrollHeight - spanWrapContainer.clientHeight;
        userHasScrolled = spanWrapContainer.scrollTop < maxScrollPosition - 50;
      }
    };

    if (spanWrapContainer) {
      spanWrapContainer.addEventListener('scroll', checkIfUserScrolledUp);
    }

    try {
      while (true) {
        const { value, done } = await reader.read();
        makingAnswer.value = true;

        if (done) {
          getAnswersForQuestion(responseMessage.parent_key, responseMessage.msg_key);
          console.log("✅ 스트리밍 완료!!", value);

          // setTimeout(() => {
            makeAnswerDone.value = true;
            makingAnswer.value = false;
          // }, 500);

          await handleTTS();

          if (store.currentChannel) {
            console.log("📢 음성인식 중");
            await store.updateChannelQuestionList(store.currentChannel.channel_key);
          }

          // ✅ 새로운 msg_key 저장
          const newMsgKey = responseMessage.msg_key;
          streamingStatus.value[newMsgKey] = true;

          // ✅ 기존 msg_key(-2)가 남아 있다면 삭제
          if (oldMsgKey !== newMsgKey) {
            delete streamingStatus.value[oldMsgKey];
          }

          console.log("✅ 새로운 msg_key 활성화:", newMsgKey, streamingStatus.value);

          if (!userHasScrolled) {
            scrollToBottom(false);
          }
          break;
        }

        if (value) {
          resResult.value += value;
          if (!firstValueSkipped) {
            firstValueSkipped = true;
            continue;
          }

          store.setMessageBody(responseMessage.msg_key, responseMsgText.value);

          const parsedMsg = recvMsgJson<MessageResponse>(resResult.value);
          if (parsedMsg) {
            const parsedObj = JSON.parse(JSON.stringify(parsedMsg));
            const resMsgKey = parsedObj.res_msg_key;
            const resMsgItemStr = parsedObj.msg_item_str;
            
            console.log("✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅ lastResponseMessageKey:", resMsgItemStr);
            // lastResponseMessageKey.value = resMsgKey;
            if (!lastResponseMessageKey.value.includes(resMsgKey)) {
                lastResponseMessageKey.value.push(resMsgKey);
              }

              // console.log("✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅ lastResponseMessageKey:", lastResponseMessageKey.value);

            // ✅ msg_key 업데이트 (이전 값과 다르면)
            if (responseMessage.msg_key !== resMsgKey) {
              console.log(`🔄 메시지 키 업데이트: ${responseMessage.msg_key} → ${resMsgKey}`);
              // store.updateMessageKey(responseMessage.msg_key, resMsgKey);
               // ✅ 기존 메시지 키들 (`-2` 같은 값 포함) → 새로운 msg_key로 변경
              store.updateAllMessageKeys([responseMessage.msg_key, questionMessage.msg_key], resMsgKey);

              responseMessage.msg_key = resMsgKey;
              // ✅ 새로운 msg_key에 대해 false 설정
              streamingStatus.value[resMsgKey] = false;

              // ✅ 기존 msg_key(-2)를 삭제
              if (oldMsgKey !== resMsgKey) {
                delete streamingStatus.value[oldMsgKey];
              }

              oldMsgKey = resMsgKey; // 새로운 msg_key를 oldMsgKey로 갱신
              // responseMessage.msg_item_str = recvMsgJson<MessageResponse>(resResult.value);
                const parsedMsg = recvMsgJson<MessageResponse>(resResult.value);
                // JSON 객체를 문자열로 변환
                let jsonString = JSON.stringify(parsedMsg);
                // `{` 제거 (맨 앞의 `{` 삭제)
                jsonString = jsonString.replace(/^\{/, "");
                // "body" 키워드가 나오기 직전의 `]`까지 포함하여 추출
                const bodyIndex = jsonString.lastIndexOf(',"body"');
                if (bodyIndex !== -1) {
                    jsonString = jsonString.substring(0, bodyIndex) + "}";
                }
                // 결과를 `msg_item_str`에 저장
                responseMessage.msg_item_str = jsonString;
              console.log("responseMessage", responseMessage)
              console.log("responseMessage.msg_item_str", responseMessage.msg_item_str)
            }

            questionMessage.msg_key = resMsgKey - 1;
          }

          checkIfUserScrolledUp();
          if (!userHasScrolled) {
            nextTick(() => scrollToBottom(false));
          }
        }
      }
    } finally {
      if (spanWrapContainer) {
        spanWrapContainer.removeEventListener('scroll', checkIfUserScrolledUp);
      }
    }
  }
};

//=========================== 검색 기능 실행
const onSearch = async (isFirstVisit: boolean = false) => {
  // scrollToBottom(true);
  scrollToBottomSmoothly(false);
  emits('hideMainBox');
  handleFocus();
  if (!isValidQuestion()) {
    return;
  }
  makeAnswerDone.value = false;
  try {
    stopRecognition();
    await questionTransition(async () => {
      if (store.currentChannel?.channel_key !== undefined) {
        
         // 인수가 이벤트 객체인 경우 기본값으로 설정
        // if (isFirstVisit instanceof Event) {
        //   isFirstVisit = false;
        // }
        
        // console.log("첫방문 이야?", isFirstVisit);

        const questionMessage = createQuestionMessage();
        // const responseMessage = createResponseMessage();
        const responseMessage = createResponseMessage(questionMessage.msg_key);
        const roomKey = store.currentRoom?.room_key ?? 0;
        // responseMessage.msg_key = recvMsgJson<MessageResponse>(result.value)
          // debugger;
        store.appendMessage(questionMessage, roomKey);
        store.appendMessage(responseMessage, roomKey);
        // lastResponseMessageKey.value = responseMessage.msg_key;

        const defaultBody = buildRequestBody();

        if (store.currentChannel.kms_yn === 1) {
          console.log("======== kms_yn여부", store.currentChannel.kms_yn);
          console.log("현재 채널 키 ", store.currentChannel.channel_key);
          console.log("첫방문 이야?",isFirstVisit);
          const reader = await requestKmsAnswerStream(
            tokenStore.isLogin
              ? {
                  ...defaultBody,
                  user_key: store.myInfo?.user_key,
                  room_key: store.currentRoom?.room_key,
                  // ...(isFirstVisit && 
                  // store.currentChannel?.channel_key && 
                  // // 해당 채널의 첫 방문 상태 확인
                  // channelFirstVisitStatus.value[store.currentChannel.channel_key] === true 
                  // ? { first_yn: 1 } 
                  // : {})   
                  ...(isFirstVisit ? { first_yn: 1 } : {})              
                }
              : defaultBody
          );
                
          await processServerResponse(reader, responseMessage, questionMessage);
          
          // 첫 방문 후 상태 업데이트
          if (store.currentChannel?.channel_key) {
            channelFirstVisitStatus.value[store.currentChannel.channel_key] = false;
          }

        } else if (store.currentChannel.kms_yn === 0 || store.currentChannel.kms_yn === null) {
          console.log("======== kms_yn여부", store.currentChannel.kms_yn);
          const reader = await createMsg(
            tokenStore.isLogin
              ? { ...defaultBody, user_key: store.myInfo?.user_key, room_key: store.currentRoom?.room_key }
              : defaultBody
          );
          
          await processServerResponse(reader, responseMessage, questionMessage);
          responseMessage.msg_key = recvMsgJson<MessageResponse>(resResult.value)
        }
      }
    });
  } catch (err) {
    console.log("err", err);
  } finally {
    sttLoading.value = false;
  }
};

const isStreamingComplete = ref(false); // 스트리밍 완료 여부





// //=========================== 검색기능

//=============================================== 답변에 대한 사용자 질문
const getAnswersForQuestion = (parentKey: number, msgKey: number) => {
  const result = store.arrangedMessageList.filter(
    (msg) => msg.msg_key === parentKey
  );
  // 일치하는 메시지가 없고, msgKey가 음수일 때
  if (result.length === 0) {
    const len = store.arrangedMessageList.length;
    if (msgKey < 0 && len > 1) {
      const targetMsg = store.arrangedMessageList.find(
        (msg) => msg.msg_key === msgKey + 1
      );
      if (targetMsg) {
        return targetMsg.body;
      }
    } else if (parentKey === 0 && len > 1) {
      const targetMsg = store.arrangedMessageList.find(
        (msg) => msg.msg_key === msgKey - 1
      );
      if (targetMsg) {
        return targetMsg.body;
      }
    }
    return null;
  }

  return result[0]?.body;
};

//===============================================
function knowScoreFormat(score: number) {
  const fixed = score.toFixed(2);
  if (score <= KNOW_SCORE.high) {
    return `높음: ${fixed}`;
  }
  if (score <= KNOW_SCORE.middle) {
    return `보통: ${fixed}`;
  }
  return `낮음: ${fixed}`;
}

//===============================================
const copyToClipboard = async (msg: msg) => {
  try {
    await navigator.clipboard.writeText(msg.body);
    $q.notify({
      message: "답변 내용이 클립보드에 복사되었습니다.",
      color: "positive",
      timeout: 3000
    });
  } catch (err) {
    console.error("Failed to copy: ", err);
    // copyMessage.value = 'Failed to copy!';
  }
};

const askAgain = (msg: msg) => {
  const result = getAnswersForQuestion(msg.parent_key, msg.msg_key);
  // const askAgain = (msg: msg) => {
  // const result = msg;
  // questionInput.value = result ?? "";
  questionInput.value = msg.body ?? "";
  if (questionInput.value !== "" && questionInput.value !== undefined) {
    $q.notify({
      message: "해당 질문을 재전송하였습니다.",
      color: "positive",
      timeout: 3000
    });
    onSearch();
  } else {
    $q.notify({
      message: "빈 질문은 재전송할 수 없습니다.",
      color: "negative",
      timeout: 3000
    });
  }
};
// 스코어, 좋아요 
// const addLike = (msg: Msg) => {
//   const index = likeIndex.value.indexOf(msg.msg_key);
//   if (index !== -1) {
//     likeIndex.value.splice(index, 1);
//     // $q.notify({
//     //   message: "좋아요가 취소되었습니다."
//     // });
//   } else {
//     likeIndex.value.push(msg.msg_key);
//     // $q.notify({
//     //   message: "좋아요가 저장되었습니다."
//     // });
//   }
//   // console.log("좋아요 상태:", likeIndex.value);
//   // console.log("좋아요 + 1", msg);
// };
// 스코어, 좋아요 팝업 관리
const isScorePopMap = ref<Record<number, boolean>>({}); // msg_key별 팝업 상태 관리

const addLike = (msg: Msg) => {
  if (!isScorePopMap.value[msg.msg_key]) {
    isScorePopMap.value[msg.msg_key] = true;
  } else {
    isScorePopMap.value[msg.msg_key] = false;
  }

  const index = likeIndex.value.indexOf(msg.msg_key);
  if (index !== -1) {
    likeIndex.value.splice(index, 1);
  } else {
    likeIndex.value.push(msg.msg_key);
  }
};




function groupChatsByDate(chats: Msg[]) {
  

  console.log("✅ 정렬된 메시지 리스트:", chats);

  // ✅ 날짜별 그룹화
  // const groupedChats = sortedChats.reduce((groups, chat) => {
  const groupedChats = chats.reduce((groups, chat) => {
    const date = new Date(chat.cre_date);
    if (isNaN(date.getTime())) return groups;

    const dateString = date.toISOString().split("T")[0];

    if (!groups[dateString]) {
      groups[dateString] = [];
    }

    groups[dateString].push(chat); // ✅ 최신 메시지를 아래로 추가
    // groups[dateString].unshift(chat); // ✅ 최신 메시지를 아래로 추가

    return groups;
  }, {} as Record<string, Msg[]>);

  console.log("✅ 날짜별 그룹화 결과:", groupedChats);

  // ✅ 날짜 그룹을 오래된 순으로 정렬
  return Object.fromEntries(
    Object.entries(groupedChats).sort(([dateA], [dateB]) => 
      new Date(dateA).getTime() - new Date(dateB).getTime()
    )
  );
}


// 사용자의 현재 스크롤 위치를 저장할 변수
const userScrollPosition = ref(0);

// const deleteMsg = async (msg_key: number, room_key: number) => {
//   try {
//     console.log("메세지 삭제")
//     // popStore.openConfirmAskPopLayer();
//     // popStore.confirmAskPopBody = "메시지를 삭제하시겠습니까?";
//     // popStore.confirmCloseParentPop = props.pClosePop;

//     const spanWrapContainer = document.querySelector('.spanWrap');
//     if (spanWrapContainer) {
//       console.log("진행중");
//       userScrollPosition.value = spanWrapContainer.scrollTop;
//     }
    
//     // 삭제될 메세지 찾기
//     const messageToDelete = store.arrangedMessageList.find(msg => msg.msg_key === msg_key);

//     console.log("msg 삭제");
//     $q.notify({
//       // message: "메시지가 삭제되었습니다.",
//       message: `"${messageToDelete?.body.substring(0, 20)}${messageToDelete?.body.length > 20 ? '...' : ''}" 메시지가 삭제되었습니다.`,
//       color: "negative",
//       timeout: 3000
//     });

//     // 메세지 삭제 전 스크롤 이벤트 감시자
//     const preventScrollReset = () => {
//       if (spanWrapContainer) {
//         spanWrapContainer.scrollTop = userScrollPosition.value;
//       }
//     }

//     // 메세지 목록이 변경되더라도 스크롤 위치를 재설정하지 않도록 함
//     const tempWatch = watch(() => store.arrangedMessageList, () => {
//       nextTick(() => {
//         preventScrollReset();
//       });
//     }, {immediate: true});

//     await store.deleteMessage(msg_key, room_key);
//     //  store.deleteMessage(msg_key, room_key);
//     // router.go(0);
//     // await store.updateMessageList(store.currentRoom?.room_key ?? 0)

//     // 스크롤 복원을 위한 타임아웃 설정
//     setTimeout(() => {
//       if (spanWrapContainer) {
//         spanWrapContainer.scrollTop = userScrollPosition.value;
//       }
      
//       // 일정 시간 후 이벤트 리스너 제거
//       window.removeEventListener('scroll', preventScrollReset);
//       tempWatch(); // 감시자 해제
//     });

//   } catch (error) {
//     console.error("메시지 삭제 중 오류 발생:", error);
//   }
// };

const deleteMsg = async (msg_key: number, room_key: number) => {
  // debugger;
  try {
    console.log("메세지 삭제");

    const spanWrapContainer = document.querySelector('.spanWrap');
    if (spanWrapContainer) {
      console.log("진행중");
      userScrollPosition.value = spanWrapContainer.scrollTop;
    }

    // ✅ newMessageMap에서 삭제할 메시지 찾기
    let messageToDelete: Msg | undefined;
    let targetDayMsg: DayMsg | undefined;
    let targetSetMsg: SetMsg | undefined;

    if (store.newMessageMap[room_key]) {
      for (const dayMsg of store.newMessageMap[room_key]) {
        for (const setMsg of dayMsg.s_msg_list || []) {
          if (setMsg.req?.msg_key === msg_key) {
            messageToDelete = setMsg.req;
            targetDayMsg = dayMsg;
            targetSetMsg = setMsg;
            break;
          } else if (setMsg.res?.msg_key === msg_key) {
            messageToDelete = setMsg.res;
            targetDayMsg = dayMsg;
            targetSetMsg = setMsg;
            break;
          }
        }
        if (messageToDelete) break;
      }
    }

    if (!messageToDelete) {
      console.warn("삭제할 메시지를 찾을 수 없습니다.");
      return;
    }

    // ✅ 메시지 삭제 전 사용자에게 알림
    $q.notify({
      message: `"${messageToDelete.body.substring(0, 20)}${messageToDelete.body.length > 20 ? '...' : ''}" 메시지가 삭제되었습니다.`,
      color: "positive",
      timeout: 3000
    });
    console.log("before delete",store.newMessageMap[room_key])
    // ✅ newMessageMap에서 해당 메시지 제거
    if (targetDayMsg && targetSetMsg) {
      targetDayMsg.s_msg_list = targetDayMsg.s_msg_list?.filter(s => s !== targetSetMsg);

      // ✅ 만약 해당 날짜에 메시지가 더 이상 없다면 해당 날짜 삭제
      if (targetDayMsg.s_msg_list.length === 0) {
        store.newMessageMap[room_key] = store.newMessageMap[room_key].filter(d => d !== targetDayMsg);
      }
    }
    // ✅ 실제 서버에서 메시지 삭제 요청
    await store.deleteMessage(msg_key, room_key);
    console.log("after delete",store.newMessageMap[room_key])

    // ✅ 스크롤 유지 로직
    const preventScrollReset = () => {
      if (spanWrapContainer) {
        spanWrapContainer.scrollTop = userScrollPosition.value;
      }
    };

    const tempWatch = watch(() => store.newMessageMap, () => {
      nextTick(() => {
        preventScrollReset();
      });
    }, { immediate: true });

    // ✅ 스크롤 복원 및 이벤트 해제
    setTimeout(() => {
      if (spanWrapContainer) {
        spanWrapContainer.scrollTop = userScrollPosition.value;
      }
      window.removeEventListener('scroll', preventScrollReset);
      tempWatch(); // 감시자 해제
    });

  } catch (error) {
    console.error("메시지 삭제 중 오류 발생:", error);
  }
};


// const handleSttClick = () => {
//   if (ttsOption.value === 0) {
//     console.log("옵션 값", ttsOption.value);
//     ttsOption.value = 1;

//     startSpeechRecognition();
//   } else if (ttsOption.value === 1) {
//     console.log("옵션 값", ttsOption.value);
//     ttsOption.value = 2;
//   } else {
//     console.log("옵션 값", ttsOption.value);
//     ttsOption.value = 0;

//     stopRecognition();
//   }
// };

//고정 음성 제거
// const handleSttClick = () => {
//   if (ttsOption.value === 0) {
//     console.log("옵션 값", ttsOption.value);
//     ttsOption.value = 1;

//     startSpeechRecognition();
//   } else {
//     console.log("옵션 값", ttsOption.value);
//     ttsOption.value = 0;

//     stopRecognition();
//   }
// };

// 상태 추가
const showLanguageSelect = ref(false);

// handleSttClick 수정
const handleSttClick = async () => {
  if (ttsOption.value === 0 && !showLanguageSelect.value) {
    // 첫 클릭: 언어 선택 UI만 표시
    showLanguageSelect.value = true;
  } else if (showLanguageSelect.value) {
    // 언어 선택 후 두 번째 클릭: STT 시작
    if (!selectedLanguage.value) {
      $q.notify({
        message: "언어를 선택해주세요.",
        color: "negative",
        timeout: 3000
      });
      return;
    }
    showLanguageSelect.value = false;
    ttsOption.value = 1;
    await initRecognition(selectedLanguage.value);
    await initTTS();
    startRecognition({
      onResult(result) {
        questionInput.value = result;
        onSearch();
      },
      async onError(e) {
        console.error("음성 인식 오류:", e.error);
        ttsOption.value = 0;
      }
    });
  } else {
    // STT 중지
    console.log("옵션 값", ttsOption.value);
    ttsOption.value = 0;
    stopRecognition();
  }
};

const closePrivateChatDialog = () => {
  privateChatDialog.value = false;
};
const openPrivateChatDialog = () => {
  privateChatDialog.value = true;
};

// QR 코드 팝업 열기
const openQRCodePopup = async () => {
  await generateQRCode(`https://anytalk.com/talk/channel?chKey=${store.currentChannel?.channel_key}`);
  isPopupVisible.value = true;
};

// QR 코드 팝업 닫기
const closeQRCodePopup = () => {
  isPopupVisible.value = false;
};

// QR 코드 생성
const generateQRCode = async (text: string) => {
  try {
    const url = await QRCode.toDataURL(text);
    qrCodeUrl.value = url;
  } catch (err) {
    console.error('QR 코드 생성 오류:', err);
  }
};

const route = useRoute();

// 남서울대 페이지 여부 확인
const isNsuPage = computed(() => {
  // return (
  //   route.path.includes("/talk/channel?chKey=250") ||
  //   store.currentChannel?.channel_key == "250"
  // ); // 남서울대 라우트 경로에 맞게 수정
  return (
    // store.currentChannel?.channel_key == 250
    store.currentChannel?.channel_key == 515
  ); // 남서울대 라우트 경로에 맞게 수정
});


// QR 코드 다운로드
const downloadQRCode = () => {
  if (qrCodeUrl.value) {
    const link = document.createElement('a');
    link.href = qrCodeUrl.value;
    link.download = 'qrcode.png';
    closeQRCodePopup()
    link.click();
    $q.notify({
      message: "다운로드가 완료되었습니다.",
      color: "positive",
      timeout: 3000
    });
    
  } else {
    $q.notify({
      message: "QR 코드를 다운로드하지 못했습니다.",
      color: "negative",
      timeout: 3000
    });
  }
};

// 이미지 확대 팝업 표시 상태
const isImagePopupVisible = ref(false);
const selectedImageUrl = ref<string | null>(null);

// const BASE_UPLOAD_URL = "https://anytalk.com:28081/uploads/";
const BASE_UPLOAD_URL = "https://anytalk.com/uploads/";

// const getImageUrl = (path: string) => {
//   if (!path) return ""; // 빈 값 방지
//   return (path.startsWith("https://") || path.startsWith("http://")) 
//     ? path.replace(/\\/g, "/") // ✅ 백슬래시(`\`)를 슬래시(`/`)로 변환
//     : `${BASE_UPLOAD_URL}${path.replace(/\\/g, "/")}`; // ✅ 상대 경로에도 적용
// };

const getImageUrl = (path: string) => {
  if (!path) return ""; // 빈 값 방지
  
  // URL 인코딩 처리를 위한 부분을 분리
  const encodePath = (p: string) => {
    // 마지막 경로 부분(파일명)만 추출
    const parts = p.split(/[\\/]/);
    const filename = parts.pop();
    const directory = parts.join('/');
    
    // 파일명만 인코딩
    const encodedFilename = encodeURIComponent(filename);
    
    return directory ? `${directory}/${encodedFilename}` : encodedFilename;
  };

  if (path.startsWith("https://") || path.startsWith("http://")) {
    const normalizedPath = path.replace(/\\/g, "/");
    return encodePath(normalizedPath);
  } else {
    const normalizedPath = path.replace(/\\/g, "/");
    return `${BASE_UPLOAD_URL}${encodePath(normalizedPath)}`;
  }
};


// const openImagePopup = (imageUrl: string) => {
//   // console.log("selected image url:", getImageUrl(imageUrl)); // ✅ 변환된 URL 출력 확인
//   console.log("selected image url:", getImageUrl(imageUrl.file_enc_path)); // ✅ 변환된 URL 출력 확인
//   selectedImageUrl.value = getImageUrl(imageUrl.file_enc_path); // ✅ 변환된 URL 저장
//   isImagePopupVisible.value = true;
// };


const closeImagePopup = () => {
  isImagePopupVisible.value = false;
  selectedImageUrl.value = null;
};

const openImagePopup = (imageUrl: any) => {  // 타입을 명확히 하기 위해 인터페이스 정의가 필요할 수 있습니다
  // const processedUrl = getImageUrl(imageUrl.file_enc_path);
  const urlToProcess = imageUrl.file_enc_path || imageUrl;
  const processedUrl = getImageUrl(imageUrl);
  console.log("Processing URL:", processedUrl);  // 디버깅용 로그
  selectedImageUrl.value = urlToProcess;
  isImagePopupVisible.value = true;
};

const downloadImage = () => {
  if (selectedImageUrl.value) {
    // 이미지 URL에서 원본 파일명을 추출
    const parts = selectedImageUrl.value.split('/');
    const encodedFileName = parts[parts.length - 1];
    // URL 디코딩하여 원본 파일명 복원
    const originalFileName = decodeURIComponent(encodedFileName);
    
    const link = document.createElement('a');
    
    fetch(selectedImageUrl.value)
      .then(response => response.blob())
      .then(blob => {
        const blobUrl = URL.createObjectURL(blob);
        link.href = blobUrl;
        link.download = originalFileName;  // 디코딩된 원본 파일명 사용
        
        document.body.appendChild(link);
        link.click();
        
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
        
        closeImagePopup();
        
        $q.notify({
          message: "이미지 다운로드가 완료되었습니다.",
          color: "positive",
          timeout: 3000
        });
      })
      .catch(err => {
        console.error('이미지 다운로드 중 오류 발생:', err);
        $q.notify({
          message: "이미지를 다운로드하지 못했습니다.",
          color: "negative",
          timeout: 3000
        });
      });
  } else {
    $q.notify({
      message: "다운로드할 이미지가 없습니다.",
      color: "negative",
      timeout: 3000
    });
  }
};




// ✅ isImagePopupVisible이 변경될 때 확인
watch(isImagePopupVisible, (newVal) => {
  console.log("팝업 상태 변경됨:", newVal);
});

watch(selectedImageUrl, (newVal) => {
  console.log("선택된 이미지 URL:", newVal);
});

const copyLink = () => {
  const url = `https://anytalk.com/talk/channel?chKey=${store.currentChannel?.channel_key}&loginYn=1`;
  navigator.clipboard.writeText(url)
    .then(() => 
    $q.notify({
      message: "링크가 클립보드에 복사되었습니다.",
      color: "positive",
      timeout: 3000
    })
    )
    .catch(err => console.error('링크 복사 오류:', err));
};

const goToHomepage = () => {
  router.push('/nsu.ac.kr')
}
const goToAiDocent = () => {
  router.push('/aidocent')
}
const goToAiOrcha = () => {
  router.push('/aiOrch')
}

const focusMainElement = () => {
  if (mainElement.value) {
    mainElement.value.focus();
  }
};

// const closeReferKnowBtn = () => {
//   referKnowPopYn.value = false;
// }

const closeReferKnowBtn = () => {
  referKnowPopYn.value = false;
  currentMsgKey.value = 0;
};

const closeCoTKnowBtn = () => {
  CoTKnowPopYn.value = false;
  currentMsgKey.value = 0;
};

// const referKnow = () => {
//   referKnowPopYn.value = true;
//   console.log("참고지식 팝업 열기")
// }

const referKnow = (msgKey: number) => {
  console.log("전달된 msg_key:", msgKey);
  // currentMsgKey.value = msgKey;
  // const res_msg_key = recvMsgParsed.value?.res_msg_key;  
  currentMsgKey.value =  msgKey;
  // currentMsgKey.value = res_msg_key || msgKey;
  referKnowPopYn.value = true;
  console.log("Tracking 팝업 열기", currentMsgKey.value);
  // debugger;
};

const CoTKnow = (msgKey: number) => {
  console.log("전달된 msg_key:", msgKey);
  // currentMsgKey.value = msgKey;
  // const res_msg_key = recvMsgParsed.value?.res_msg_key;  
  currentMsgKey.value =  msgKey;
  // currentMsgKey.value = res_msg_key || msgKey;
  CoTKnowPopYn.value = true;
  console.log("CoT 팝업 열기", currentMsgKey.value);
  // debugger;
};

// const BASE_FILE_URL = "https://anytalk.com:28081/uploads/";
const BASE_FILE_URL = "https://anytalk.com/uploads/";

// const downloadFile = (file) => {
//   if (!file?.file_enc_path) {
//     $q.notify({
//       message: "파일 경로가 존재하지 않습니다.",
//       color: "negative",
//       timeout: 3000
//     });
//     return;
//   }

//   // 파일 다운로드 URL 생성
//   const fileUrl = file.file_enc_path.startsWith("https://")
//     ? file.file_enc_path
//     : `${BASE_FILE_URL}${file.file_enc_path}`;

//   console.log("📂 파일 다운로드 URL:", fileUrl);

//   // 브라우저에서 파일 다운로드 처리
//   const link = document.createElement("a");
//   link.href = fileUrl;
//   link.download = file.file_name; // 다운로드 파일 이름 설정
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);

//   $q.notify({
//     message: `${file.file_name} 다운로드가 시작되었습니다.`,
//     color: "positive",
//     timeout: 3000
//   });
// };
const downloadFile = (file) => {
  if (!file?.file_enc_path) {
    $q.notify({
      message: "파일 경로가 존재하지 않습니다.",
      color: "negative",
      timeout: 3000
    });
    return;
  }

  // 파일 다운로드 URL 생성
  const fileUrl = file.file_enc_path.startsWith("https://")
    ? file.file_enc_path
    : `${BASE_FILE_URL}${file.file_enc_path}`;

  console.log("📂 파일 다운로드 URL:", fileUrl);

  // 새로운 창을 통해 파일 다운로드 처리
  const newWindow = window.open(fileUrl, "_blank");
  if (newWindow) {
    newWindow.focus();
    $q.notify({
      message: `${file.file_name} 다운로드가 시작되었습니다.`,
      color: "positive",
      timeout: 3000
    });
  } else {
    $q.notify({
      message: "팝업 차단이 활성화되어 있습니다.",
      color: "negative",
      timeout: 3000
    });
  }
};



// // 파일 다운로드 함수 추가
// const downloadFile = (file) => {
//   // 실제 구현에서는 file_enc_path를 사용하여 다운로드
//   $q.notify({
//     message: `${file.file_name} 다운로드가 시작되었습니다.`,
//     color: "positive",
//     timeout: 3000
//   });
// };

// 파일 목록 토글 함수
const toggleFileList = () => {
  isFileListVisible.value = !isFileListVisible.value;
};


const isValidJSON = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch {
    return false;
  }
};

// // msg_item_str 파싱 함수 추가

const parseMsgItemStr = (msgItemStr: string) => {
  try {
    if (!msgItemStr) return null;

    // ✅ 이미 파싱된 값이 있다면 캐시된 결과 반환
    const cacheKey = msgItemStr;
    if (parsedMsgItemCache.value.has(cacheKey)) {
      return parsedMsgItemCache.value.get(cacheKey);
    }

    // ✅ 불완전한 JSON도 처리하기 위해 문자열 정리 후 파싱
    const cleanStr = msgItemStr.trim();
    const parsedData = parse(`{${cleanStr}}`);

    // ✅ 중복 제거 로직 추가
    const uniqueData = removeDuplicates(parsedData);

    // ✅ 캐시에 저장
    parsedMsgItemCache.value.set(cacheKey, uniqueData);
    console.error("✅✅✅✅✅✅✅✅:", uniqueData);
    return uniqueData;
  } catch (error) {
    console.error("메시지 파싱 에러:", error);
    return null;
  }
};

// const parsedMessageItems = computed(() => {
//   const parsedData: Record<number, MessageResponse> = {};

//   // ✅ `newMessageMap`에서 `msg_item_str`을 파싱하여 저장
//   Object.values(store.newMessageMap).forEach((roomMessages) => {
//     roomMessages.forEach((dayMsg) => {
//       dayMsg.s_msg_list?.forEach((setMsg) => {
//         const msg = setMsg.res ?? setMsg.req; // 응답 메시지가 있으면 res, 없으면 req 사용
//         if (msg && msg.msg_item_str) {
//           try {
//             parsedData[msg.msg_key] = JSON.parse(`{${msg.msg_item_str}}`);
//           } catch (error) {
//             console.error(`❌ msg_item_str JSON 파싱 오류 (msg_key: ${msg.msg_key}):`, error);
//           }
//         }
//       });
//     });
//   });

//   return parsedData;
// });



// ✅ 중복 제거 함수 (객체/배열 형태에 따라 다르게 처리)
const removeDuplicates = (data: any) => {
  if (Array.isArray(data)) {
    // 배열이면 중복된 객체 제거
    return [...new Map(data.map(item => [JSON.stringify(item), item])).values()];
  } else if (typeof data === "object" && data !== null) {
    // 객체이면 중복된 키 제거
    return Object.fromEntries(Object.entries(data).filter(([key, value], index, self) => {
      return self.findIndex(([k, v]) => k === key && JSON.stringify(v) === JSON.stringify(value)) === index;
    }));
  }
  return data; // 기본값 반환
};



const handleSttStart = (data: { language: string; isStarting: boolean; newTtsOption: number }) => {
  selectedLanguage.value = data.language;
  if (data.isStarting) {
    ttsOption.value = data.newTtsOption;  // ttsOption 값 업데이트
    startSpeechRecognition();
  }
};

const handleLanguageChange = (language: string) => {
  selectedLanguage.value = language;
};


// ===============================================================

const isExpanded = ref(false);
const showMoreButton = ref(false);
const questsBox = ref(null);
const questsBoxMap = ref<Record<number, boolean>>({}); // msg_key별 추천 질문 펼침 상태 관리


// const toggleExpand = () => {
//   isExpanded.value = !isExpanded.value;
// };

const toggleExpand = (msg: Msg) => {
  console.log("클릭한 메시지 정보", msg)
  if (!questsBoxMap.value[msg.msg_key]) {
    questsBoxMap.value[msg.msg_key] = true;
  } else {
    questsBoxMap.value[msg.msg_key] = false;
  }
};

onMounted(() => {
  nextTick(() => {
    if (questsBoxMap.value) {
      
      const maxHeight = parseInt(getComputedStyle(questsBoxMap.value).getPropertyValue('--max-height'), 10);
      if (questsBoxMap.value.scrollHeight > maxHeight) {
        showMoreButton.value = true;
      }
    }
  });
});

const computedHeight = computed(() => {
  const baseHeight = "calc(100vh - 150px)";
  const faqAdjustment = store.currentChannel?.faq_texts?.length > 0 ? "calc(100vh - 202px)" : baseHeight;
  return faqAdjustment;
});
</script>

<template>
  <span 
  class="spanWrap" 
  :class="{
    'mainbox-hidden': !isMainBoxVisible, 
    'mainbox-visible': isMainBoxVisible
  }" 
  :style="{ height: computedHeight }"
>
  <!-- <span class="spanWrap" :class="{'mainbox-hidden' : !isMainBoxVisible, 'mainbox-visible': isMainBoxVisible}"> -->
    <!-- 답변 영역 -->
    <!-- <div v-if="answerYn" class="noLoginAnswerWrap"> -->
    <!-- <div  v-if="!tokenStore.isLogin" class="noLoginAnswerWrap">
      
        <div
          class="outputContainer outputContainer__reply"
        >
        <p v-if="getGroupByDateChat" class="noLoginAsk">
                    <h2>안녕하세요 무엇을 도와드릴까요?</h2>
          </p>
          <li class="msgWrap answerChat">
            <div class="msgBox">
              <div>
                <div class="message fontMd">
                  
                  <p v-if="questionInput" class="queInAnswer">
                    {{ questionInput }}
                  </p>
                  <MarkdownRenderer class="answer" :source="response" />
                </div>
              </div>
            </div>
          </li>
        </div>
      </div> -->
    <!-- <button @click="sessionTest">테스트</button> -->
    
    <!-- <div class="wrap" :class="{ 'fixed-header': store.currentChannel?.channel_key === 250 }">
      <div v-if="store.currentChannel?.channel_key === 250" class="subheader subheader__search" > -->
    <div class="wrap" :class="{ 'fixed-header': store.currentChannel?.channel_key === 515 }">
      <div v-if="store.currentChannel?.channel_key === 515" class="subheader subheader__search" >
    <!-- ================== 로그인 후 상위 채널명 ================== -->
          <!-- <template v-if="store.currentChannel?.channel_key && store.currentChannel?.channel_key !== 250"> -->
          <template v-if="store.currentChannel?.channel_key && store.currentChannel?.channel_key !== 515">
            {{
              `${store.currentChannel?.name ?? ""}${
                store.currentRoom?.access_kind === "U"
                  ? ""
                  : " - " + (store.currentRoom?.name ?? "개설된 방이 없습니다")
              }`
            }}
          </template>
          <!-- 채널 키가 250일 때 로고 이미지 표시 -->
          <!-- <template v-if="store.currentChannel?.channel_key === 250">
            <div class="channel-logo">
              <img src="/src/assets/logo_namseoul.png" alt="채널 250 전용 이미지" />
            </div>
          </template>
          <template v-else-if="tokenStore.isLogin">
            {{
              `${store.currentChannel?.name ?? ""}${
                store.currentRoom?.access_kind === "U"
                  ? ""
                  : " - " + (store.currentRoom?.name ?? "개설된 방이 없습니다")
              }`
            }}
          </template> -->
    <!-- ================== 로그인 전 상위 채널명 ================== -->
          <!-- <template v-else> 애니톡</template> -->
          <template v-else-if="!store.currentChannel?.channel_key">
            애니톡
          </template>
    <!-- ================== 룸 전환 버튼 ================== -->
          <!-- <button
            v-show="!questionLoading && store.roomList.length >= 2"
            class="roomToggle flexJustify"
            @click="store.toggleSelectedRoom"
          > -->
          <button
            class="roomToggle flexJustify"
            @click="store.toggleSelectedRoom"
          >
            <SwapIcon />
          </button>
    <!-- ================== 질문 로딩박스 ================== -->
          <span v-show="questionLoading" class="loader"></span>
        </div>
      </div>

    <!-- ================== 질문 입력 창 ================== -->
    <!-- <div tabindex="-1"  ref="mainElement"></div> -->
    <!-- ================== 채팅 설정 버튼 ================== -->
    <div v-if="false" class="searchBoxOption">
      <div  style="display: flex">
        <!-- 채팅 설정 hidden -->
        <!-- <DialogRoot v-if="store.currentChannel?.auth_type === 'A' && store.currentChannel?.channel_key !== 250"> -->
        <!-- <DialogRoot v-if="store.currentChannel?.auth_type === 'A' && store.currentChannel?.channel_key !== 250"> -->
        <DialogRoot v-if="store.currentChannel?.auth_type === 'A' && store.currentChannel?.channel_key !== 515">
          <DialogTrigger @click.stop @click="openPrivateChatDialog"
            ><SettingIcon /> 채팅 설정
            <PrivateChannelDialog
              v-bind:open="privateChatDialog"
              :pClosePop="closePrivateChatDialog"
              :channel="store.currentChannel"
              :checkChatPw="checkChatPw"
              v-model:password="password"
              :kindOf="kindOf"
              :pTitle="pTitleValue"
              :channelKey="store.currentChannel?.channel_Key"
            />
          </DialogTrigger>
        </DialogRoot>
        <!-- <DialogRoot 
          v-if="
            store.currentChannel?.channel_key !== 250 && 
            (store.currentChannel?.auth_type === 'A' || store.myInfo?.observer_yn == 1)
          "
        >
          <DialogTrigger @click.stop @click="openPrivateChatDialog">
            <SettingIcon /> 채팅 설정
            <PrivateChannelDialog
              v-bind:open="privateChatDialog"
              :pClosePop="closePrivateChatDialog"
              :channel="store.currentChannel"
              :checkChatPw="checkChatPw"
              v-model:password="password"
              :kindOf="kindOf"
              :pTitle="pTitleValue"
              :channelKey="store.currentChannel?.channel_Key"
            />
          </DialogTrigger>
        </DialogRoot> -->
        <!-- <DialogRoot v-if="(store.currentChannel?.auth_type === 'A' ||store.myInfo?.observer_yn == 1 && store.currentChannel?.channel_key !== 250) || store.myInfo?.observer_yn == 1 ">
          <DialogTrigger @click.stop @click="openPrivateChatDialog"
            ><SettingIcon /> 채팅 설정
            <PrivateChannelDialog
              v-bind:open="privateChatDialog"
              :pClosePop="closePrivateChatDialog"
              :channel="store.currentChannel"
              :checkChatPw="checkChatPw"
              v-model:password="password"
              :kindOf="kindOf"
              :pTitle="pTitleValue"
              :channelKey="store.currentChannel?.channel_Key"
            />
          </DialogTrigger>
        </DialogRoot> -->
    <!-- ================== 음성입력 ================== -->
        <!-- <select
          v-if="store.currentChannel?.channel_key !== 250"
          v-model="selectedLanguage"
          aria-label="언어 선택"
          class="searchBoxOption__lang-select"
        > -->
        <select
          v-if="store.currentChannel?.channel_key !== 515"
          v-model="selectedLanguage"
          aria-label="언어 선택"
          class="searchBoxOption__lang-select"
        >
          <option value="" disabled>언어를 선택하세요</option>
          <option v-for="lang in LANGUAGE" :key="lang.code" :value="lang.code">
            {{ lang.name }}
          </option>
        </select>
      </div>
      <div></div>
      <div v-if="sttLoading && props.isGreaterMobile == true">
        <div>잠시만 기다려주세요</div>
      </div>
    <!-- ================== 음성인식 버튼 ================== -->
      <!-- <div v-else-if="!sttLoading" class="searchBoxOption__stt">
        <select
          v-if="ttsOption === 1"
          v-model="selectedLanguage"
          aria-label="언어 선택"
          class="stt-language-select"
        >
          <option value="" disabled>언어를 선택하세요</option>
          <option v-for="lang in LANGUAGE" :key="lang.code" :value="lang.code">
            {{ lang.name }}
          </option>
        </select>

        <button
          v-if="ttsOption === 3"
          type="button"
          @click="stopTtsFunc"
          class="stopTts"
        >
          <template v-if="ttsOption === 3">
            <StopRecoIcon class="speakImg" /> <span>출력 중단</span>
          </template>
        </button>
        <button v-else type="button" @click="handleSttClick" :class="buttonClass">
          <template v-if="ttsOption === 0" class="btnTemplate">
            <span> <p>음성</p>
              <p>시작 하기</p></span>
          </template>
          <template v-else-if="ttsOption === 1">
            <SpeakYesIcon
              v-if="micStatus === MicStatus.Active"
              class="speakImg"
            />
            <HourGlassIcon v-else /> <span>인식 중</span>
          </template>
          <template v-else>
            <SpeakYesIcon1
              v-if="micStatus === MicStatus.Active"
              class="speakImg"
            />
            <HourGlassIcon v-else /> <span>연속 인식</span>
          </template>
        </button>
      </div> -->

    </div>
    
    <!-- ================== 채팅 설정 참고지식 란 ================== -->
    <!-- <div
      class="outputContainer"
      v-if="
      store.myInfo?.observer_yn == 1 ||
        store.currentChannel?.auth_type === 'A' &&
        collectPwYnMap[store.currentChannel?.channel_Key]
        || chattingOption === true
      "
    > -->
    <!-- <div
    class="outputContainer"
    v-if="(store.currentChannel?.auth_type === 'A' && collectPwYnMap[store.currentChannel?.channel_key]) || 
          (store.myInfo?.observer_yn === 1 && store.currentChannel?.channel_key !== 250) ||
          chattingOption === true"
    > -->
    <div
    class="outputContainer"
    v-if="(store.currentChannel?.auth_type === 'A' && collectPwYnMap[store.currentChannel?.channel_key]) || 
          (store.myInfo?.observer_yn === 1 && store.currentChannel?.channel_key !== 515) ||
          chattingOption === true"
    >
      <details>
        <summary class="outputLabel knowToggleRoot">
          <span class="knowToggleContainer">
            참고지식
            {{ searched?.length > 0 ? `(${searched?.length}개)` : "(0개)" }}
            <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
            <label
              class="SwitchLabel"
              for="multi-query-mode"
              title="느리지만 더 정확해요"
            >
              정확하게
              <SwitchRoot
                class="SwitchRoot"
                id="muliti-query-mode"
                v-model:checked="multiQuery"
              >
                <SwitchThumb class="SwitchThumb" />
              </SwitchRoot>
            </label>
            <RadioGroupRoot
              v-if="false"
              v-model="knowMode"
              default-value="count"
              orientation="horizontal"
            >
              <RadioGroupItem
                class="knowToggleItem"
                value="accuracy"
                aria-label="정확하게"
              >
                정확도
              </RadioGroupItem>
              <RadioGroupItem
                class="knowToggleItem"
                value="count"
                aria-label="적정 수로"
              >
                적정 수
              </RadioGroupItem>
            </RadioGroupRoot>
            <RadioGroupRoot
              v-model="knowScope"
              default-value="independent"
              orientation="horizontal"
            >
              <RadioGroupItem
                class="knowToggleItem"
                value="independent"
                aria-label="독립 채널"
              >
                독립 채널
              </RadioGroupItem>
              <RadioGroupItem
                class="knowToggleItem"
                value="socialOpen"
                aria-label="소셜 개방"
              >
                소셜 개방
              </RadioGroupItem>
              <RadioGroupItem
                v-if="false"
                class="knowToggleItem"
                value="relatedChannel"
                aria-label="관계 채널"
              >
                관계 채널
              </RadioGroupItem>
            </RadioGroupRoot>
          </span>
        </summary>
        <ol class="referList">
          <li class="referListItem" v-for="(item, index) in searched" :key="index">
            <br />
          {{
              `${item.know} ${
                knowMode === "count"
                  ? `[${knowScoreFormat(item.metadata.score)}]`
                  : ""
              }`
            }} 
          </li>
          <br />
        </ol>
      </details>
    </div>
    <!-- ================== 로그인 후 ================== -->
    <!-- <button
            class="roomToggle flexJustify"
            @click="store.toggleSelectedRoom"
          >
            <SwapIcon />
          </button> -->
    <div  v-if="tokenStore.isLogin"  class="chat-messages" ref="chatContainer">
      <div class="messageListContainer" >
        <div v-show="isLoading" class="skeleton-container">
          <template v-for="index in 3" :key="index">
            <div class="skeleton skeleton-question"></div>
            <div class="skeleton skeleton-answer"></div>
            <div style="margin-bottom: 30px"></div>
          </template>
        </div>
        <!-- <ul v-show="!isLoading || answerYn" class="chatListWrap"> -->
          <ul
  v-show="!isLoading || answerYn"
  class="chatListWrap"
  :style="{ 'flex-direction': reverseOrder ? 'column-reverse' : 'column' }"
>
          <template
            v-for="(messages, index) in getGroupByDateChat"
            :key="index"
          >
          <!-- <template v-for="(message, index) in messages" :key="index"> -->
            <!-- 날짜 표시하는 dashLine (원래는 아래에 있었음) -->
            <div class="dateLabel">
              <div class="dashedLine"></div>
              <span>
                <!-- {{ date }} -->
                {{ messages.day }}
              </span>
            </div>

          <template v-for="(message, index) in messages.s_msg_list" :key="index">
        <!-- ================== 사용자 질문 ================== -->
              <li
                v-if="message.req?.cre_user_key !== 0 && message.req?.parent_key === 0"
                class="msgWrap myChat"
              >
              <div v-if="message.req?.cre_user_key !== 4" class="message fontMd">
                <MarkdownRenderer :source="message.req?.body ?? ''" />
                
                  <div class="date">
                    <!-- <span style="margin-right: 10px" @click="copyToClipboard(message)">
                  <docCopy />
                </span> -->
                {{ elapsedTime(message.req?.cre_date) }}</div>
                </div>
              </li>
        <!-- ================== 전문가 답변 ================== -->
              <li
                v-if="message.res?.cre_user_key > 0 && message.res?.parent_key > 0"
                class="msgWrap answerChat"
              >
              <!-- <li
                v-if="message.ai_res_yn === 0"
                class="msgWrap answerChat"
              > -->
                <div class="msgBox">
                  <div>
                    <p class="channelName">전문가 답변이 도착하였습니다</p>
                    <div class="message fontMd">
                      <div>
                        <p class="expertAnswerQuestion">
                          <!-- {{
                            getAnswersForQuestion(
                              message.parent_key,
                              message.msg_key
                            )
                          }} -->
                          {{  message.req?.body }}
                        </p>
                      </div>
                      <p class="expertAnswer">
                        <MarkdownRenderer :source="message.res?.body ?? ''" />
                        <!-- <MarkdownRenderer :source="message.res?.body" /> -->
                      </p>
                      <p>
                        <div>
                          ------------
                        </div>
                      </p>
                      <div class="subBtnWrap">
                        <span @click="copyToClipboard(message.res)"><docCopy /></span>
                        <span
                          @click="addLike(message.res)"
                          class="fontBg"
                          :class="{
                            selected: likeIndex.includes(message?.res?.msg_key)
                          }"
                          style="color: #bababa"
                        >
                        <heartIcon />
                        </span>
                        <span><shareIcon style="color:#bababa"/></span>
                        <span
                          @click="
                            deleteMsg(
                              message.res?.parent_key,
                              store.currentRoom?.room_key
                            )
                          "
                        >
                        <deleteIcon/>
                        </span>
                      </div>
                      <div class="date">{{ elapsedTime(message.res?.cre_date) }}</div>
                    </div>
                  </div>
                </div>
              </li>
      <!-- ================== 애니톡 답변 ================== -->
              <!-- <li v-if="message.res?.cre_user_key === 0" class="msgWrap answerChat"> -->
              <li v-if="message.ai_res_yn === 1" class="msgWrap answerChat">
                <div class="msgBox">
                  <div>
                    <!-- <p v-if="isNsuPage" class="channelName">
                      남서울대학교 AI챗봇
                    </p> -->
                    <p class="channelName">
                      <!-- {{ store.currentChannel?.name ?? "" }} -->
                      <br>
                    </p>
                    <div class="message fontMd">
                      <p class="queInAnswer">
                        <!-- {{
                          getAnswersForQuestion(message.res?.parent_key, message.res?.msg_key)
                        }} -->
                        {{
                          message.req?.body
                        }}
                      </p>
                     <MarkdownRenderer
                        class="answer"
                        :source="message.res?.body ? message.res?.body : '입력 중...'"
                      />
                      <!-- <MarkdownRenderer
                        class="answer"
                        :source="message.body"
                      /> -->

                    <!-- <div v-if="isAnsweredYn" class="subInfoWrap" > -->
                    <!-- <div class="subInfoWrap" > -->
                      <!-- msg_item_str 데이터 표시 -->
                    <transition name="fade-in">
                      <!-- <div class="subInfoWrap" v-if="streamingStatus[message.res?.msg_key] !== false"> -->
                      <div class="subInfoWrap" v-if="message.res?.msg_key && streamingStatus[message.res.msg_key] !== false">
                      <!-- <div v-if="isStreamingComplete && message?.msg_item_str && parseMsgItemStr(message?.msg_item_str)"> -->

                      <!-- msg_item_str 데이터 표시 -->
                      <div v-if="message?.res?.msg_item_str && parseMsgItemStr(message?.res?.msg_item_str)">
                        <!-- <div v-if="parseMsgItemStr(message?.msg_item_str)?.must_text_string?.length > 0" class="must-text">
                          <div v-for="(text, index) in parseMsgItemStr(message?.msg_item_str)?.must_text_string" :key="index">
                            * {{ text }}
                          </div>
                        </div> -->
                        
                        <!-- msgList 이미지 목록 -->
                        <div
                            class="subInfoTitle"
                            v-if="
                              parseMsgItemStr(message?.res?.msg_item_str) &&
                              (Array.isArray(parseMsgItemStr(message?.res?.msg_item_str)?.img_file_string) &&
                                parseMsgItemStr(message?.res?.msg_item_str)?.img_file_string.length > 0) ||
                              (Array.isArray(parseMsgItemStr(message?.res?.msg_item_str)?.body_img_path_string) &&
                                parseMsgItemStr(message?.res?.msg_item_str)?.body_img_path_string.length > 0)
                            "
                          >
                          <!-- 첨부 이미지 -->
                        </div>
                        <!-- =========================== img 히스토리 관리 -->
                        <div v-if="
                              parseMsgItemStr(message?.res?.msg_item_str) &&
                              (Array.isArray(parseMsgItemStr(message?.res?.msg_item_str)?.img_file_string) &&
                                parseMsgItemStr(message?.res?.msg_item_str)?.img_file_string.length > 0) && !isNsuPage
                            " class="imgList history" >
                          <div class="imgItem" v-for="(item, index) in parseMsgItemStr(message?.res?.msg_item_str)?.img_file_string" :key="index">
                            <!-- <img 
                              :src="item?.file_enc_path"
                              @click="openImagePopup(item?.file_enc_path)"
                              class="clickable-image"
                            /> -->
                            <!-- <img 
                            :src="getImageUrl(item?.file_enc_path)"
                            @click="openImagePopup(getImageUrl(item?.file_enc_path))"
                            class="clickable-image"
                          /> -->
                          <img 
                            :src="getImageUrl(item?.file_enc_path)"
                            @click="openImagePopup(item.file_enc_path)"
                            class="clickable-image"
                          />
                          </div>
                        </div>
                        
                        <!-- <div class="imgList" v-if="parseMsgItemStr(message?.msg_item_str)?.body_img_path_string?.length > 0"> -->
                        <div class="imgList" v-if="parseMsgItemStr(message?.res?.msg_item_str) && (Array.isArray(parseMsgItemStr(message?.res?.msg_item_str)?.body_img_path_string) &&
                                parseMsgItemStr(message?.res?.msg_item_str)?.body_img_path_string.length > 0) && !isNsuPage">
                          <div class="imgItem" v-for="(imgPath, index) in parseMsgItemStr(message?.res?.msg_item_str)?.body_img_path_string" :key="index">

                            <!-- <img 
                              :src="imgPath.startsWith('https://') ? imgPath : `https://anytalk.com:28081/uploads/${imgPath}`"
                              @click="openImagePopup(imgPath.startsWith('https://') ? imgPath : `https://anytalk.com:28081/uploads/${imgPath}`)"
                              class="clickable-image"
                            /> -->
                            <img 
                              :src="imgPath.startsWith('https://') ? imgPath : `https://anytalk.com/uploads/${imgPath}`"
                              @click="openImagePopup(imgPath.startsWith('https://') ? imgPath : `https://anytalk.com/uploads/${imgPath}`)"
                              class="clickable-image"
                            />
                            </div>
                          </div>
                          <p class="dashedLine"></p>
                          <!-- 첨부 파일 -->
                          <!-- msgList 첨부파일 목록 -->
                          <div class="subInfoTitle" v-if="parseMsgItemStr(message.res?.msg_item_str)?.attach_file_string?.length > 0 " >
                          </div>
                          <div v-if="parseMsgItemStr(message.res?.msg_item_str)?.attach_file_string?.length && !isNsuPage" class="file-section">
                            <p class="dashedLine"></p>
                            <div class="file-container">
                              <button class="file-toggle-btn" @click="toggleFileList">
                                <Document />
                                {{ parseMsgItemStr(message?.res?.msg_item_str)?.attach_file_string[0]?.file_name }}
                                ({{ parseMsgItemStr(message?.res?.msg_item_str)?.attach_file_string?.length }})
                              </button>
                              
                              <div v-if="isFileListVisible" class="file-list">
                                <div 
                                  v-for="file in parseMsgItemStr(message?.res?.msg_item_str)?.attach_file_string" 
                                  :key="file.file_key" 
                                  class="file-item"
                                  @click="downloadFile(file)"
                                >
                                  <Document />
                                  <span class="file-name">{{ file?.file_name }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p class="dashedLine"></p>

                          <!-- =========================== 링크 히스토리 관리 -->
                          <!-- msgList 링크 목록 -->
                          <div class="subInfoTitle history" v-if="parseMsgItemStr(message?.res?.msg_item_str)?.menu?.length > 0">
                            <!-- 링크 -->
                          </div>
                          <div v-if="parseMsgItemStr(message?.res?.msg_item_str)?.menu?.length && !isNsuPage" class="eachlinkLinkWrap history">
                            <!-- <p class="dashedLine"></p> -->
                            <a
                              v-for="(link, index) in parseMsgItemStr(message?.res?.msg_item_str)?.menu"
                              :key="index"
                              :href="link.link_url"
                              target="_blank"
                              class="cursorP eachlinkLink"
                            >
                             <LinkIcon />
                              {{ link?.link_name }}
                            </a>
                          </div>
                      </div>

                        <!-- 필수표시 목록 -->
                      <!-- <div v-if="mustText.length > 0 && message.msg_key === lastResponseMessageKey && !isNsuPage" class="must-text">
                        <div v-for="(text, index) in mustText" :key="index">
                          {{ text }}
                        </div>
                      </div> -->
                      <!-- <div v-if="parseMsgItemStr(message?.res?.msg_item_str)?.must_text?.length && mustText.length > 0 && message.res?.msg_key === lastResponseMessageKey && !isNsuPage" class="must-text"> -->
                      <div v-if="parseMsgItemStr(message?.res?.msg_item_str)?.must_text?.length && mustText.length > 0 && lastResponseMessageKey.includes(message.res?.msg_key) && !isNsuPage" class="must-text">
                        <div v-for="(text, index) in mustText" :key="index">
                          {{ text }}
                        </div>
                      </div>
                      
                      <!-- createMsg 이미지 목록 img 스트리밍 create 목록 -->
                      <!-- <div class="subInfoTitle create" v-if="imageList?.length > 0 && imageList[0]?.length > 0 && message.res?.msg_key === lastResponseMessageKey"> -->
                      <div class="subInfoTitle create" v-if="imageList?.length > 0 && imageList[0]?.length > 0 && lastResponseMessageKey.includes(message.res?.msg_key)">
                        <!-- 첨부 이미지 -->
                      </div>
                      <!-- <div class="imgList" v-if="imageList?.length > 0 && imageList[0]?.length > 0 && message.res?.msg_key === lastResponseMessageKey  && isNsupage"> -->
                      <div class="imgList" v-if="imageList?.length > 0 && imageList[0]?.length > 0 &&lastResponseMessageKey.includes(message.res?.msg_key) && isNsupage">
                        <div  class="imgItem" v-for="(item,index) in imageList" :key="index">
                          <img 
                          :src="getImageUrl(item)"
                          @click="openImagePopup(item)"
                          class="clickable-image"
                          />
                        </div>
                      
                      </div>
                      <!-- ================== 이미지 확대 팝업 ================== --> 
                      <!-- <div 
                      v-if="isImagePopupVisible && message.msg_key === lastResponseMessageKey" 
                      class="image-popup-overlay" 
                      > -->
                      <!-- <div v-show="isImagePopupVisible && message.msg_key === lastResponseMessageKey" class="image-popup-overlay" @click="closeImagePopup"> -->
                      <div v-show="isImagePopupVisible" class="image-popup-overlay" @click="closeImagePopup">

                        <div class="image-popup-content" @click.stop>
                          <!-- <img :src="selectedImageUrl" alt="확대된 이미지" /> -->
                          <img :src="getImageUrl(selectedImageUrl)" alt="확대된 이미지" />

                          <button class="qrBtn ok" @click="downloadImage">이미지 다운로드</button>
                          <button class="qrBtn cancel" @click="closeImagePopup">닫기</button>
                        </div>
                      </div>

                      <!-- <div
                            v-if="getCurrentMessageLinks(message.msg_key).length > 0"
                            class="eachlinkLinkWrap"
                          >
                        <p class="dashedLine"></p>
                        <a
                          v-for="linkUrl in getCurrentMessageLinks(message.msg_key)"
                                  :key="linkUrl.link_key"
                                  :href="linkUrl.link_url"
                                  target="_blank"
                                  class="cursorP eachlinkLink"
                                >
                                  {{ linkUrl.link_title }}
                        </a>
                      </div>
                      <DialogRoot v-if="referKnowPopYn"  v-model:open="referKnowPopYn">
                          <ReferKnowPop :referKnow="searched[0]" @closeReferKnowBtn="closeReferKnowBtn"/>
                      </DialogRoot> -->

                      <!-- <DialogRoot v-if="referKnowPopYn" v-model:open="referKnowPopYn">
                        <ReferKnowPop 
                          :msgKey="currentMsgKey"
                          @closeReferKnowBtn="closeReferKnowBtn"
                        />
                      </DialogRoot> -->

                      <!-- 키워드와 파일 섹션을 감싸는 컨테이너 -->
                      <!-- <div v-if="message.res?.msg_key === lastResponseMessageKey" class="metadata-section"> -->
                        <div v-if="lastResponseMessageKey.includes(message.res?.msg_key)" class="metadata-section">

                      <!-- 키워드 섹션 -->
                      <!-- 키워드 필요없다 하셔서 일단 히든 : 대표님 -->
                        <!-- <div v-if="keywords?.length > 0" class="keywords-section">
                          <p class="dashedLine"></p>
                          <div class="keywords-wrap">
                            <span 
                              v-for="(keyword, index) in keywords" 
                              :key="index"
                              class="keyword-tag"
                            >
                              # {{ keyword }}
                            </span>
                          </div>
                        </div> -->

                        <!-- 첨부파일 섹션 -->
                        <!-- <div v-if="fileList.length > 0" class="file-section">
                          <p class="dashedLine"></p>
                          <div class="file-wrap">
                            <div
                              v-for="(file, index) in fileList"
                              :key="index"
                              class="file-item"
                            >
                              <AttachIcon />
                              <span class="file-name">{{ file.file_name }}</span>
                            </div>
                          </div>
                        </div> -->

                        <!-- createMsg 첨부파일 섹션 -->
                        <!-- <div class="subInfoTitle" v-if="fileList?.length > 0 && fileList[0]?.length > 0 && message.msg_key === lastResponseMessageKey">
                          첨부 파일
                        </div>
                        <div v-if="fileList?.length > 0 && fileList[0]?.length > 0 && message.msg_key === lastResponseMessageKey" class="file-section">
                          <p class="dashedLine"></p>
                          <div class="file-container">
                            <button class="file-toggle-btn" @click="toggleFileList">
                              <AttachIcon />
                              {{ fileList[0][0]?.attach_file_name }} ({{ fileList[0]?.length }})
                            </button>
                            
                            <div v-if="isFileListVisible" class="file-list">
                              <div 
                                v-for="file in fileList[0]" 
                                :key="file.file_key" 
                                class="file-item"
                                @click="downloadFile(file)"
                              >
                                <AttachIcon />
                                <span class="file-name">{{ file.attach_file_name }}</span>
                              </div>
                            </div>
                          </div>
                        </div> -->
                      </div>
                      <!-- =========================== msg create 직후 관리 -->
                      <!-- createMsg 링크 섹션 -->
                      <!-- <div class="subInfoTitle" v-if="linkList?.length > 0 && message.msg_key === lastResponseMessageKey"> -->
                      <!-- <div class="subInfoTitle" v-if="linkList?.length > 0 ">
                      </div> -->
                      <!-- <div 
                        v-if="linkList?.length > 0 && message.msg_key === lastResponseMessageKey"
                        class="eachlinkLinkWrap"> -->
                      <!-- =========================== 링크 create 직후 관리 -->
                        <!-- <div 
                        v-if="linkList?.length > 0 && message.res?.msg_key === lastResponseMessageKey && !isNsuPage"
                        class="eachlinkLinkWrap create"> -->
                        <!-- <div 
                        v-if="linkList?.length > 0 && lastResponseMessageKey.includes(message.res?.msg_key) && !isNsuPage"
                        class="eachlinkLinkWrap create"> -->
                        <div 
                        v-if="false"
                        class="eachlinkLinkWrap create">
                        <!-- <p class="dashedLine"></p> -->
                        <a
                          v-for="(link, index) in linkList"
                          :key="index"
                          :href="link.link_url"
                          target="_blank"
                          class="cursorP eachlinkLink"
                        >
                         <LinkIcon />
                          {{ link.link_name }}
                        </a>
                      </div>

                      <!-- 원본 지식 섹션 -->
                      <div class="subBtnWrap">
                        <div 
                          v-if="( message.res?.cre_user_key === 0 && store.currentChannel?.auth_type === 'A' || store.currentChannel?.auth_type === 'E') || (message.res?.cre_user_key === 0 && store.myInfo?.observer_yn === '1')" 
                          class="referKnowBtn" 
                          @click="referKnow(message.res?.msg_key)"
                        >
                        지식 원본
                        </div>
                        <div 
                        v-if="( message.res?.cre_user_key === 0 && store.currentChannel?.auth_type === 'A' || store.currentChannel?.auth_type === 'E') || (message.res?.cre_user_key === 0 && store.myInfo?.observer_yn === '1')" 
                          class="referKnowBtn" 
                          @click="CoTKnow(message.res?.msg_key)"
                        >
                          지식 추적
                        </div>
                        <div>
                        <span @click="copyToClipboard(message.res)"><docCopy /></span>
                        <span
                          @click="addLike(message.res)"
                          class="fontBg"
                          :class="{
                            selected: likeIndex.includes(message?.res?.msg_key)
                          }"
                        >
                        <!-- <heartIcon/> -->
                        <LikeIcon/>
                        </span>
                        <!-- <div v-if="isScorePopMap[message.msg_key]" class="image-popup-overlay">
                          <p>메시지 평가</p>
                          <div class="image-popup-content">
                            점수 팝업 {{ message.msg_key }}
                          </div>
                        </div> -->
                        <!-- <span><shareIcon style="color: #bababa" /></span> -->
                        <span @click="askAgain(message.req)">
                          <retryIcon/>
                        </span>
                        <span
                          @click="
                            deleteMsg(
                              message.res?.msg_key -1,
                              store.currentRoom?.room_key
                            )
                          "
                        >
                        <deleteIcon/>
                        </span>
                      </div>
                      
                    </div>
                    
                    <!-- 추천 질문 섹션 -->
                    <!-- ====================== 추천질문 히스토리 관리 -->
                       <!-- <div v-if="questLists?.length > 0 && message.msg_key === lastResponseMessageKey" class="quests-section"> -->
                        <div v-if="parseMsgItemStr(message?.res?.msg_item_str)?.quest_string?.length && store.currentChannel?.channel_key !== 397" class="quests-section history">
                          <!-- <p class="questListUnderLine"></p> -->
                          <div class="quests-wrap">
                            <div class="questTitleWrap">
                            <p>추천 질문</p>
                            <button class="more-btn" @click="toggleExpand(message.res)">
                              {{ questsBoxMap[message.res?.msg_key] ? '접기' : '펼치기' }}
                            </button>
                          </div>
                          <div 
                            class="questsBox" 
                            :class="{ 'expanded': questsBoxMap[message.res?.msg_key] }"
                            ref="questsBox"
                          >
                            <!-- <span 
                              v-for="(quest, index) in questLists" 
                              :key="index"
                              class="quests-tag"
                              @click="usualQuestion(quest)"
                            > -->
                            <!-- <button v-if="showMoreButton" class="more-btn" @click="toggleExpand"> -->
                            <!-- {{parseMsgItemStr(message?.res?.msg_item_str)?.quest_string.length}} -->
                              <span 
                              v-for="(quest, index) in  parseMsgItemStr(message?.res?.msg_item_str)?.quest_string" 
                              :key="index"
                              class="quests-tag"
                              @click="usualQuestion(quest)"
                            >
                              <QuestionIcon class="QuestionIcon" /> {{ quest }}
                            </span>
                          </div>
                          </div>
                        </div>
                        <!-- ====================== 추천질문 create 관리 -->
                       <!-- <div v-if="questLists?.length > 0 && message.res?.msg_key === lastResponseMessageKey && store.currentChannel?.channel_key != 397" class="quests-section"> -->
                        <!-- <div v-if=" questLists?.length > 0 && lastResponseMessageKey.includes(message.res?.msg_key) && store.currentChannel?.channel_key != 397" class="quests-section"> -->
                        <div v-if="false" class="quests-section">

                       <!-- <div v-if="questLists?.length > 0 && message.msg_key === lastResponseMessageKey " class="quests-section"> -->
                          <!-- <p class="questListUnderLine"></p> -->
                          <div class="quests-wrap">
                            <div class="questTitleWrap">
                            <p>추천 질문</p>
                            <button class="more-btn" @click="toggleExpand(message.res)">
                              <!-- {{ isExpanded ? '접기' : '더보기' }} -->
                              {{ questsBoxMap[message.res?.msg_key] ? '접기' : '더보기' }}
                            </button>
                          </div>
                          <!-- <div class="questsBox" :class="{ 'expanded': isExpanded }" ref="questsBox"> -->
                            <div 
                            class="questsBox" 
                            :class="{ 'expanded': questsBoxMap[message.res?.msg_key] }"
                            ref="questsBox"
                          >
                            <!-- <span
                              v-for="(quest, index) in questLists" 
                              :key="index"
                              class="quests-tag"
                              @click="usualQuestion(quest)"
                            > -->
                            {{message?.res?.msg_item_str}}
                            <span 
                              v-for="(quest, index) in  parseMsgItemStr(message?.res?.msg_item_str)?.quest_string" 
                              :key="index"
                              class="quests-tag"
                              @click="usualQuestion(quest)"
                            >{{ index }}
                              <QuestionIcon class="QuestionIcon" /> {{ quest }}
                            </span>
                          </div>
                          </div>
                        </div>
                      </div>
                    </transition>
                      <div class="date">{{ elapsedTime(message.res?.cre_date) }}</div>
                    </div>
                  </div>
                </div>
              </li>
              <!-- <div
                v-if="message.cre_user_key !== 0"
                :class="{ middleDash: index !== messages.length - 1 }"
              ></div> -->
            </template>
          </template>
        </ul>
      </div>
    </div>
    <!-- <div class="topBtn" v-if="store.currentChannel?.channel_key !== 250 && tokenStore.isLogin"> -->
    <!-- ================== 위치 이동 버튼 ================== -->
      <!-- <div  v-if="tokenStore.isLogin">
        <div class="topBtn">
          <button class="goButton" @click="scrollToTopBtn">
            &#8593;
          </button>
        </div>
        <div class="bottomBtn" v-if="tokenStore.isLogin">
          <button class="goButton" @click="scrollToBottomBtn">
            &#8595;
          </button>
        </div>
      </div>   -->
    <!-- ================== 지식 원본 팝업 ================== -->
      <DialogRoot v-if="referKnowPopYn" v-model:open="referKnowPopYn">
        <!-- <DialogOverlay v-if="referKnowPopYn" class="DialogOverlay" /> -->
        <ReferKnowPop 
        :msgKey="currentMsgKey"
        @closeReferKnowBtn="closeReferKnowBtn"
        />
      </DialogRoot>
    <!-- ================== 지식 추적 팝업 ================== -->
      <DialogRoot v-if="CoTKnowPopYn" v-model:open="CoTKnowPopYn">
        <CoTKnowPop
        :msgKey="currentMsgKey"
        @closeCoTKnowBtn="closeCoTKnowBtn"
        />
      </DialogRoot>
    <!-- ================== 전체 채널 보기 팝업 ================== -->
    <DialogRoot v-if="store.showChannelAddDialog" v-model:open="store.showChannelAddDialog">
      <ChannelAddDialog @close="store.closeChannelAddDialog" />
    </DialogRoot>
    <!-- ================== 질문 박스 ================== -->
    <div class="input-container" :class="inputContainerClass">
      <div class="search-voice-container">
        <SearchBox
          v-model="questionInput"
          input-label="질문"
          send-label="전송"
          placeholder="AI챗봇에게 궁금한 것을 물어보세요."
          :send-event="onSearch"
          :channelKey="store.currentChannel?.channel_key"
          :handleSttClick="handleSttClick"
          :ttsOption="ttsOption"
          :micStatus="micStatus"
          :MicStatus="MicStatus"
          :stopTtsFunc="stopTtsFunc"
          @sttStart="handleSttStart"
          @languageChange="handleLanguageChange"
        />
      <div class="inputFaq-container">
        <!-- <button v-if="showLeftButton" class="scroll-btn left" @click="scrollLeft"> 좌측 </button>
        <button class="scroll-btn left" @click="scrollLeft"> 좌측 </button> -->
        <div class="inputFaq-wrapper">
          <div v-if="faqTexts" class="inputFaq">
              <button
              v-for="(question, index) in faqTexts"
                :key="index"
                class="input-block-button"
                @click="usualQuestion(question)"
              >
                  {{ question }}
                </button>
            </div>
          </div>
      </div>
      
      <!-- <button v-if="showRightButton" 
          class="scroll-btn right"
          @click="scrollRight"> 우측 </button>
      <button class="scroll-btn right" @click="scrollRight"> 우측 </button> -->
    </div>

    <!-- ================== 음성인식 선택 박스 ================== -->
      <!-- <div class="stt-container"> -->
        <!-- <div class="voice-logo-container" v-if="store.currentChannel?.channel_key !== 250"> -->
        <!-- <div class="voice-logo-container" v-if="false">
          <div class="searchBoxOption__stt">
            <select
              v-if="showLanguageSelect"
              v-model="selectedLanguage"
              aria-label="언어 선택"
              class="stt-language-select"
            >
              <option value="" disabled>언어를 선택하세요</option>
              <option v-for="lang in LANGUAGE" :key="lang.code" :value="lang.code">
                {{ lang.name }}
              </option>
            </select>

            <button
              v-if="ttsOption === 3"
              type="button"
              @click="stopTtsFunc"
              class="stopTts"
            >
              <template v-if="ttsOption === 3">
                <StopRecoIcon class="speakImg" /> <span>출력 중단</span>
              </template>
            </button> -->

            <!-- <button v-else type="button" @click="handleSttClick" :class="buttonClass"> -->
            <!-- <button v-if="false" type="button" @click="handleSttClick" :class="buttonClass">
              <template v-if="ttsOption === 0 && !showLanguageSelect">
                <span>
                  <p>음성</p>
                  <p>시작 하기</p>
                </span>
              </template>
              <template v-else-if="showLanguageSelect">
                <span>
                  <p>음성인식</p>
                  <p>시작</p>
                </span>
              </template>
              <template v-else-if="ttsOption === 1">
                <SpeakYesIcon v-if="micStatus === MicStatus.Active" class="speakImg" />
                <HourGlassIcon v-else /> <span>인식 중</span>
              </template>
              <template v-else>
                <SpeakYesIcon1 v-if="micStatus === MicStatus.Active" class="speakImg" />
                <HourGlassIcon v-else /> <span>연속 인식</span>
              </template>
            </button>
          </div> -->
          <!-- 🔹 스크롤이 되어도 우측 하단에 고정된 로고 : 일단 히든 -->
          <!-- <div class="bottomLogo__wrap">
            <img src="/src/assets/logo.png" alt="애니톡 로고" class="bottom__logo" />
          </div> -->
        <!-- </div> -->
    </div>
  </span>
</template>

<style scoped lang="scss">
@use "../../styles/spinner";
@use "../root/header";
@use "./outputBox";

$que-color: rgb(237, 237, 237);
$answer-color: #fff;
$link-color: rgb(240, 240, 248);

.app { /* 또는 최상위 컨테이너 클래스 */
  height: 100vh;
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
}

div {
  max-width: 1024px;
}

.noLoginAnswer{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #999;
  margin-bottom: 10px;
  /* min-height: 100%; */
}

// .topBtn {
//   width: 50px;
//   height: 50px;
//   z-index: 1001;
//   // position: fixed;
//   // width: 1024px;
//   text-align: left;
//   right: 10%;
//   // left: 50%;
//   // transform: translateX(-50%);
//   margin: 0 auto;
//   bottom: 230px;
  

//   & .button {
//     padding: 15px;
//     border: 1px solid gray;
//     background-color: #fff;
//     line-height: 40px;
//     border-radius: 50%;
//     text-align: center;
//     font-size: 25px;
//   }
// }

.roomToggle {
  display: inline-flex;
  vertical-align: baseline;
  margin-left: 5px;
}

.searchBoxOption {
padding: 5px 50px;
display: flex;
margin: 0 auto;

  justify-content: space-between;
  width: 100%;
  &__stt {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    place-content: flex-end;
  }
  &__lang-select {
    height: 80%;
    width: 40%;
    border-radius: 5px;
    margin-right: 3px;
    box-shadow: 0 2px 2px 1px rgba(184, 184, 184, 0.3);
  }
}

.SwitchLabel {
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  gap: 5px;
  font-weight: 400;
}
:deep(.SwitchRoot) {
  width: 42px;
  height: 25px;
  border-radius: 9999px;
  position: relative;
  box-shadow: none;
  -webkit-tap-highlight-color: rgb(84, 84, 84);
  padding: 0;
  margin: 0;
  &:focus {
    box-shadow: 0 0 0 2px rgb(84, 84, 84);
  }
  &[data-state="checked"] {
    background-color: rgb(84, 84, 84);
  }
}

:deep(.SwitchThumb) {
  display: block;
  width: 21px;
  height: 21px;
  background-color: white;
  border-radius: 9999px;
  box-shadow: none;
  transition: transform 100ms;
  transform: translateX(2px);
  will-change: transform;
  &[data-state="checked"] {
    transform: translateX(28px);
  }
}

:deep(.DialogRoot),
:deep(.DialogContent),
:deep([role="dialog"]),
:deep(.ReferCommonPopLayer),
:deep(.popLayer) {
  outline: none !important;
  box-shadow: none !important;
}

.chat-messages{
  margin : 0 auto;
}

.messageListContainer {
  width: 90%;
  margin: 0 auto;
  /* padding: 0px 0px 90px 0px; */
  padding: 0px 0px 0px 0px;
  /* width: 100%; */
  /* padding: 0px 50px; */
  /* padding: 0px 5%; */
  /* padding: 0px 5% 210px; */
  /* margin: 0px */
  // padding-bottom: 290px !important;
  // padding-bottom: 40px !important;
}

.loader {
  $loaderSize: 18px;
  @include spinner.loader($loaderSize);
  margin-inline-start: 5px;
  bottom: calc($loaderSize / 2);
}

.eachlinkLinkWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
  transition: all 0.4s;
  font-size: 12px !important;
  font-weight: 600;
  padding: 0px 20px 10px 15px;

  & .dashedLine {
    width: 100%;
    border-top: 1px dashed gray;
    padding-bottom: 10px;
  }
}
.eachlinkLink {
  display: block;
  color: #fff;
  text-decoration: none;
  background-color: $link-color;
  padding: 0px 7px;
  border-radius: 5px;
  color: #000;
  font-weight: normal;

  &.first-child {
    margin-left: 0;
  }
}

.knowToggleRoot {
  display: list-item;
  padding: 5px 50px;
}
.knowToggleContainer {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  font-size: 15px;
  font-weight: 400;
}
.knowToggleItem {
  border: 1px solid #8f8f9d;
  margin-right: 5px;
  font-weight: 400;
  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &[data-state="checked"] {
    background-color: lighten(#8f8f9d, 25%);
  }
}

.timeLabel {
  line-height: 10px;
}

.referList {
  padding-inline-start: 16px;
  padding: 5px 50px
}

.referListItem{
  border-bottom: 0.5px solid gray;
  padding: 5px;
}

.chatListWrap {
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column-reverse;
  /* flex-direction: column; */
  padding: 10px 10px 20px 10px !important;
  gap: 33px;
  /* margin-bottom: 60px; */
  /* padding: 0; */
  /* height: calc(100% - 100px); */
  /* overflow-y: scroll; */
  

  & .dateLabel {
    width: 100%;
    position: relative;
    text-align: center;
    margin: 50px 0;
    transform: rotate(180deg);

    & .dashedLine {
      width: 100%;
      border-bottom: 1px dashed gray;
    }

    & span {
      padding: 4px 0;
      border: 1px solid gray;
      border-radius: 5px;
      background-color: #fff;
      width: 30%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }

  & .middleDash {
    padding: 10px 0;
    width: 95%;
    margin: 0 auto;
    border-bottom: 1px dashed gray;
  }

  .msgWrap {
    list-style-type: none;
    /* padding: 0 20px; */
  }

  .message {
    display: inline-block;
    width: auto;
    padding: 10px;
    line-height: 24px;
    box-shadow: 0 3px 4px 2px rgba(148, 148, 148, 0.3);
    /* border: 1px solid gray; */
    border-radius: 10px;
    position: relative;
    text-align: left;
    min-width: 40px;

    & .queInAnswer {
      background-color: rgb(84 84 84);
      color: #fff;
      margin: 0;
      border-radius: 5px;
      padding: 10px 15px;
      line-height: 25px;
      font-weight: bold;
    }

    & .expertAnswerQuestion {
      padding: 10px 15px;
      /* border-bottom: 0.5px solid gray ; */
      background-color: #e2e2e2;
      border-radius: 5px;
    }

    & .expertAnswer {
      /* background-color: rgb(84 84 84); */
      background-color: white;
      /* border: 3px solid rgb(84 84 84); */
      color: #000;
      border-radius: 5px;
      line-height: 25px;
      font-weight: bold;
      margin: 25px 0 !important;
      font-size: 17px;
      padding: 0 15px;
    }

    & .answer {
      margin: 25px 0 !important;
      font-size: 17px;
      padding: 0 15px;
    }

    & .subBtnWrap {
      display: flex;
      width: 100%;
      text-align: right;
      margin-top: 25px;
      padding: 0 14px;
      background-color: #fff;
      padding-bottom: 5px;

      .referKnowBtn{
        cursor: pointer;
        background-color: #f1f1f1;
        min-width: 50px ;
        max-width: 70px;
        text-align: center;
        border-radius: 5px;
        font-size: 12px;
        height: 80%;
        margin-left: 5px;
        
        :hover {
        transition: 0.5s;
        transform: scale(1.13); /* 클릭 효과 */
      }
      }

      & span {
        :hover {
        transition: 0.5s;
        transform: scale(1.13); /* 클릭 효과 */
      }
        /* background-color: #f8f8fb; */
        padding: 3px 5px;
        margin-left: 5px;
        font-size: 14px;
        cursor: pointer;

        &.selected {
          font-weight: bold;
          color: red;
        }
      }
    }
  }

  .date {
    font-size: 13px;
    color: gray;

    position: absolute;
    bottom: -25px;
  }

  .myChat {
    text-align: right;

    .message {
      background-color: $que-color;
      max-width: 80%;
      color: #222;
      padding: 15px 25px;
      box-shadow: none;

      &::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-left: 10px solid $que-color; /* 삼각형의 색상과 방향 설정 */
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        position: absolute;
        top: 50%;
        right: -10px;
        transform: translateY(-50%);
        /* filter: drop-shadow(1px 0px 0px rgba(0, 0, 0.5)); */
      }
    }

    .date {
      text-align: left;
      left: 5px;
    }
  }

  .answerChat {
    text-align: left;
    margin-bottom: 30px;

    .msgBox {
      /* margin: 0 auto; */
      margin: 0 0 0 10px;
      width: 85%;
      display: flex;
      justify-content: start;
      gap: 13px;

      & div {
        width: 100%;
      }
    }

    .profileImg {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-top: 18px;
    }

    .channelName {
      font-size: 15px;
      margin-block: 0px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .message {
      background-color: $answer-color;
      color: #222;
      width: 100%;

      &::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-right: 10px solid #fff; /* 삼각형의 색상과 방향 설정 */
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        position: absolute;
        top: 32px;
        left: -10px;
        transform: translateY(-50%);
        filter: drop-shadow(-1px 2px 1px rgb(0, 0, 0, 0.2));
      }
    }

    .date {
      width: 100%;
      text-align: right;
      right: 5px;
    }
  }
}

.searchBoxOption__stt {
  /* height: 145px; */
  position: fixed;
  /* position: absolute; */
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  /* top: 6.5%;  */
  bottom: 10%;
  right: 5%;
  /* right: 1%;  */
  display: flex;
  align-items: center; /* 수직 정렬 */
  gap: 10px; /* 요소 간 간격 */
  z-index: 1000; /* 다른 요소들 위에 위치 */
  /* background-color: rgb(255, 255, 255); */
  /* background-color: #8080ff; */
  border-radius: 100px;
  /* padding: 0px 4px; */
  box-shadow: 0 2px 2px 3px rgba(167, 167, 167, 0.3);
  place-content: center;
}

.searchBoxOption__lang-select {
  width: auto;
  height: 100%;
  /* border: 0px solid white; */
  /* box-shadow: 0 0px 0px 0px white; */
  margin-left: 10px;

}

.mikeBtn {
  /* background-color: #efefef; */
  font-weight: bold;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  /* background-color: #8080ff; */
  background-color: #3eb697;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3px;
}

.mikeBtn span {
  font-size: 15px;
}

.mikeRecording {
  animation: changeColor 0.5s infinite alternate;
  transition: all 0.5s;
  /* background-color: #3eb697; */
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  font-size: 25px;
  font-weight: bold;
  /* background-color: #f8f8f8; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3px;
}

.mikeRecording span {
  font-size: 15px;
}
.mikeRecordingFix {
  animation: changeColor2 1s infinite alternate;
  transition: all 1s;
  /* background-color: #ababff; */
  background-color: #3eb697;
  /* background-color: white; */
  /* background-color: #8080ff; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3px;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  font-size: 25px;
  /* color: white; */
  font-weight: bold;
}
.mikeRecordingFix span {
  font-size: 15px;
}
/* .speakImg {
  width: 40px;
  height: 40px;
} */

.stopTts {
  background-color: #e63a46;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3px;
}
.stopTts span {
  font-size: 15px;
  font-weight: bold;
}

.sttLoading {
  position: fixed;
  top: 0;
  left: 0;
  /* width: 100%;
  height: 100%; */
  width: 80%;
  height: 50px;

  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  z-index: 1000;
}

/* 🔹 로고를 음성인식 버튼 아래에 배치 */
.bottomLogo__wrap {
  display: flex;
  justify-content: center;
  width: 66px; /* 원하는 크기로 조절 */
  height: auto;
  margin-top: 50px !important; /* 음성인식 버튼과의 간격 */
  position: fixed;
  bottom: 5%;
  right: 10% !important;
}

.bottom__logo {
  width: 70% !important; /* 원하는 크기로 조절 */
  right: 10% !important;
  opacity: 0.6;
  transition: opacity 0.3s ease-in-out;
}

/* 🔹 마우스를 올리면 더 선명하게 보이게 */
.bottom__logo:hover {
  opacity: 1;
}

@keyframes changeColor {
  0% {
    background-color: none;
    /* border: 1px solid gray; */
    /* font-weight: bold; */
  }
  100% {
    /* background-color: #c7c7ff; */
    background-color: #3eb697;
    /* border: 1px solid gray; */
    /* color: #fff; */
    /* font-weight: bold; */
  }
}

@keyframes changeColor2 {
  0% {
    background-color: none;
    /* border: 1px solid gray; */
    /* font-weight: bold; */
  }
  100% {
    /* background-color: #8080ff; */
    /* background-color: #3eb697; */
    background-color: white;
    /* border: 1px solid gray; */
    /* color: #fff; */
    font-weight: bold;
  }
}

.subheader {
  padding: 10px 20px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.subheader__search {
    font-size: 1.1em;
    font-weight: 500;
    display: none
  }
}

.detailWrap{
  margin: 0 auto;
  // margin-top: 70px !important;
  padding-top: 20px;  // margin-top 대신 padding 사용
  position: relative;
  // margin-top: calc(var(--header-height) + 20px); /* 헤더 높이 + 추가 여백 */
  // height: calc(100vh - var(--header-height));
  width: 100%;
  padding: 5px 53px;
  // margin: 10px 0px;
  // margin: 0 auto;
  /* margin: 10px 30px; */
  .subheader {
    margin-bottom: 20px;
  }
}

.descFaqWrap {
  /* max-width: 50%; */
  /* min-width: 700px; */
  margin: 0 auto;
  width: 100%;
  height: auto;
  background-color: white;
  box-shadow: 0 2px 2px 1px rgba(228, 228, 228, 0.3);
  padding: 20px 40px;
  border-radius: 20px;
}

.descWrap {
  width: 100%;
  /* text-align: center; */
  text-align: left;
  display: flex;
  /* justify-content: center; */
  justify-content: left;
  align-items: center; /* 수직 중앙 정렬 */
  font-size: 18px; /* 폰트 크기 조정 */
  /* background-color: #ffffff;
  border: 1px solid #dcdcdc;  */
  /* border-radius: 12px;  */
  padding: 15px; 
  min-height: 60px; /* 최소 높이 조정 */
  margin-bottom: 5px;
  box-shadow: none; /* 그림자 제거 */
  transition: background-color 0.3s; /* 배경색 변화 효과 */
}

.pWrap{
  border-top: 0.5px solid #c0c0c0;
  padding-bottom: 10px;
}

/* 전체 컨테이너 스타일 */
.faq-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  /* padding: 10px; */
  /* background-color: #ffffff; */
  /* max-width: 600px; */
  /* margin: 0 auto; */
  gap: 5px;
}

.faq-buttons{
  justify-content: left;
}

/* 제목 스타일 */
.faq-title {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.block-button {
  margin: 5px 5px;
  font-size: 12px !important;
  font-weight: 500 !important;
  padding: 3px !important;
  /* background-color: #c4e9e0; */
  background-color: #dff4ef;
  :hover {
    background-color: #3eb697;
  }
}

/* 그리드 레이아웃: 정갈하게 배열되도록 조정 */
.faq-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 15px;
  width: 100%;
}

/* 개별 버튼 스타일 */
.clean-button {
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  padding: 8px;
  font-size: 0.9rem;
  text-align: center;
  margin-left: 5px;
  cursor: pointer;
  transition:
    background-color 0.2s ease,
    border-color 0.2s ease;
  white-space: normal;
}

/* 버튼 hover 스타일 */
.clean-button:hover {
  background-color: #e0e0e0;
  border-color: #c0c0c0;
}

/* 긴 텍스트 처리 */
.clean-button {
  display: block;
  word-wrap: break-word;
}

.popup-overlay {
  // position: absolute;
  position: fixed !important;  // absolute -> fixed로 변경
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  // background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  // overflow: hidden;
}

/* 팝업 스타일 */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 40px 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 350px;
  border-radius: 20px;
}

.popup-content {
  border-radius: 20px;
  text-align: center;
  display: grid;
  border-radius: 50px
}

.popup-content img {
  width: 100%
}

.qrBtn {
  margin-top: 10px;
  padding: 10px;
  /* background-color: #007bff; */
  /* color: white; */
  border: none;
  cursor: pointer;
  border-radius: 4px;

}

.qrBtn :hover {
  background-color: #0056b3;
}

.qrLinkWrap{
  display: flex;
  gap: 5px;
  justify-content: center;
  margin: 10px 0px 0px 0px;
}

.codeLinkBtn {
  background-color: white;
  box-shadow: 0 0 0 white;

  .button-image {
    width: 50px;  // 이미지 크기 조절
    height: auto;
    border-radius: 5px;  // 이미지 모서리 둥글게
  }
}

.channelImage {
  display: flex;
  flex-direction: column;
}

.channel-banner {
  width: 100%;
  height: 160px; /* 60px 높이만 표시 */
  overflow: hidden; /* 초과 부분 숨김 */
  background-image: url("/src/assets/bn_img_namseoul.jpg"); /* 배경 이미지 적용 */
  background-size: cover; /* 비율 유지하며 전체 채우기 */
  background-position: center center; /* 중앙 정렬 */
  background-repeat: no-repeat; /* 반복 금지 */
  display: flex;
  align-items: center; /* 수직 중앙 정렬 */
  justify-content: center; /* 가로 중앙 정렬 */
  color: white; /* 글씨 색상 */
  text-align: center; /* 텍스트 가운데 정렬 */
}

.channel-banner-content {
  background: rgba(0, 0, 0, 0.2); /* 텍스트 가독성을 위한 반투명 배경 */
  padding: 5px 15px;
  border-radius: 5px;
}
.channel-wrap{
  display: flex;
  flex-direction: column;
}
.channel-title{
  font-size: 1.5em;
}
.channel-subtitle{
  font-size: 1.2em;
}

@media (max-width: 480px) {
  .skeleton-container {
    padding: 20px 0px !important;
}

.topBtn{
  bottom : 180px !important;
}

.bottomBtn{
bottom :  140px !important;
}

.goButton{
  min-width: 25px !important;
  min-height: 25px !important;
}

.skeleton-answer {
height:150px !important;
}

.skeleton-question{
  width: 50% !important;
  height: 70px !important
}

.chatListWrap{
  /* padding-bottom: 80px !important; */
  /* padding-bottom: 0px !important; */
  padding-bottom: 50px !important;
  width: 100%;
}
  
.referKnowBtn{
  max-width: 52px !important;
}

  .msgBox {
      width: 100% !important;
      margin: 0px 0px 0px 0px ;

  }

  
  .knowToggleContainer {
  gap: 0px;
}
  
  .referList {
  padding: 5px 
}
  
  .knowToggleRoot {
  padding: 5px;
}
  .block-button {
  max-width: 100%;
  overflow: hidden;        /* 넘치는 부분을 숨김 */
  white-space: nowrap;     /* 텍스트가 한 줄로 나오도록 설정 */
  text-overflow: ellipsis; /* 초과된 부분에 ... 표시 */
}
  .detailWrap{
    padding: 0px;
    // margin: 10px 0px;
}

  .descFaqWrap {
  padding: 5px 15px;
  margin: 0 !important;
  width: 100%;
  min-width: 10px; 
}

.faq-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  /* padding: 10px; */
  /* background-color: #ffffff; */
  /* max-width: 600px; */
  /* margin: 0 auto; */
  gap: 5px;
}

  .descWrap div{
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 표시할 최대 줄 수 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

  .searchBoxOption {
    padding: 3px 0px
}

  .messageListContainer {
    padding: 0px;
    margin: 0 auto;
    /* margin: 0px; */
}

  .searchBoxOption__stt {
    bottom: 40px;
    right: 20px;
}

  .spanWrap {
    // margin: 0px 20px;
    /* overflow: scroll; */
    transition: height 0.3s ease-in-out;
    padding: 0px !important;
    width: 100%;

}

  .wrap{
  width: 100vw;
  padding: 0px 20px;
  margin: 0 auto
}

  .channel-banner {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100% !important;
      height: 120px !important; 
      overflow: hidden !important; /* 초과 부분 숨김 */
      background-size: cover !important;
      background-position: center !important;
}

  .channel-banner-content {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80% !important;
    height: 100% !important;
    padding: 20px 10px;
    text-align: center;
    margin: auto;
  }

  .channel-title {
    font-size: 1.1em; /* 제목 크기 조절 오류생기는 지점 */
}

  .channel-subtitle {
    font-size: 0.8em; /* 부제목 크기 조절 */
}

.bottom__logo {
  width: 80%; /* 원하는 크기로 조절 */
}

  .bottomLogo__wrap{
    bottom: 1%; /* 🔹 모바일에서는 조금 더 위쪽으로 */
    right: 6%; /* 🔹 오른쪽 여백 추가 */
    z-index: 1000; 
  }
}

.spanWrap{
  scroll-behavior: smooth; /* 부드러운 스크롤 */
  // position: relative; /* inputBox 하단으로 보내기 */
  width:100%;
  /* margin-top: 80px !important; */
  // display: flex;
  /* padding: 0px 15px; */
  padding: 0px !important;
  min-width: 100%;
  height: 100vh; /* 전체 뷰포트 높이 사용 */
  flex-direction: column;
  // overflow: hidden;
  // min-height: 100vh;  // height -> min-height로 변경
  /* overflow-x: scroll !important;   // hidden -> auto로 변경해서 스크롤 허용 */
  overflow-y: auto !important;   // hidden -> auto로 변경해서 스크롤 허용
  height: var(--content-height, calc(100vh - 160px));
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: thin;     /* Firefox */
  
  &::-webkit-scrollbar {     /* Chrome, Safari, Opera */
    display: none;
  }
  
  &.mainbox-hidden {
    /* height: calc(100vh - 170px) !important; // 메인박스 사라졌을 때 스크롤을 위한 height */
  }

  &.mainbox-visible {
    /* height: calc(100vh - 170px) !important; // 메인박스 생겼을 때 스크롤을 위한 height */
  }
}

.outputLabel_content{
  // width: 100%
}
.wrap{
  // width: 100%;
  // margin: 0 auto
  // position: fixed;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: white;

  &.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
}

.imgList {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  padding: 5px 20px;
  /* justify-content: flex-start; */
  align-items: center;
  overflow: auto;
  width: 100% !important;
  /* height: 100px */
  height: 80px
}

.imgItem {
  width: 25% !important;
  height: 100%;
  /* border: 0.1px solid rgb(117, 117, 117);  */
  /* border-radius: 8px !important; */
  background-color: #fbfbfb;
  cursor: pointer;
}

.clickable-image {
  /* width: 100%; */
  /* min-width: 70px; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.2s;
  border: 0.1px solid rgb(117, 117, 117); 
  border-radius: 8px !important;
  
  &:hover {
    transform: scale(1.05);
  }
}

.imgSection {
  flex: 0 1 auto;
}

.imgSection img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.image-popup-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 40px 20px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
  z-index: 1001;
  width: 90%;
  max-width: 600px;
  border-radius: 20px;
}

.image-popup-content {
  border-radius: 20px;
  text-align: center;
  display: grid;
}

.image-popup-content img {
  width: 100%;
  max-height: 70vh;
  object-fit: contain;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 14px;
}

.close-btn:hover {
  background-color: #f0f0f0;
}

.edit-icon {
  font-size: 14px;
  color: #555;
  margin-left: 8px;
  cursor: pointer;
  display: inline-block;
}

.edit-icon:hover {
  color: #007bff;
}

.edit-input {
  width: calc(100% - 20px);
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
}

.save-btn {
  margin-top: 5px;
  background-color: #3eb697;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.save-btn:hover {
  background-color: #35a587;
}

.keywords-section {
  padding: 0px 20px 0 15px;
  margin-top: 10px;
  
  .keywords-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  .keyword-tag {
    display: inline-block;
    padding: 3px 10px 3px 10px;
    background-color: #f1f1f1;
    border-radius: 13px;
    font-size: 14px;
    color: #000;
    
    &:hover {
      background-color: #e5e5e5;
    }
  }
}

.file-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;  // 우측 정렬을 위한 속성
  gap: 5px;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  font-size: 14px;
  padding: 0px 20px 0 15px;

  // .file-wrap {
  //   width: auto;  // 100%에서 auto로 변경
  //   min-width: 100px;  // 최소 너비 설정
  //   max-width: 180px;  // 최대 너비 설정
  // }

  .file-container {
    position: relative;
    width: 100%;
  }

  .file-toggle-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 3px 8px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    text-align: left;
    transition: background-color 0.2s;
    overflow: hidden; // 추가
    white-space: nowrap; // 추가
    text-overflow: ellipsis; // 추가
    font-size: 12px !important; 

    &:hover {
      background-color: #e5e5e5;
    }
  }

  .file-toggle-btn button {
    font-size: 12px !important; 
  }

  .file-list {
    font-size: 15px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    margin-top: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }

  .file-item {
    font-size: 12px;
    margin: 0px !important;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
    margin-bottom: 5px;
    transition: all 0.2s;
    cursor: pointer;
    overflow: hidden; // 추가

    &:hover {
      background-color: #e5e5e5;
      transform: translateX(-2px);  // 호버 효과 추가
    }

    .file-name {
      font-size: 12px !important;
      color: #000;
      margin-left: 10px; // 아이콘과의 간격
      margin-right: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 180px;  // 파일명의 최대 길이 제한
      max-width: calc(100% - 40px);
    }
  }
}

.must-text {
  font-size: 12px;
  margin: 1rem 0;
  padding: 0.5rem;
  // border-radius: 10px;
  border-radius: 5px;
  // background-color: #eefffb;
  background-color: #e4f7f3;
  border-left: 7px solid #6aceb5;
  padding: 8px;
  width: 97% !important;
  margin: 15px 10px 15px 10px;

  /* max-height: 6.6em; */
  /* overflow: auto; */
  white-space: normal
}

.stt-language-select {
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  width: 140px;
  height: 40px;
  background-color: white;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  padding: 0 10px;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: slideDown 0.3s ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.input-container {
  /* position: sticky; */
  //==================================== 팝업전용 
  position: fixed;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  gap: 10px;
  width: auto; 
  max-width: 100vw;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
  /* bottom: 85px; */
  bottom: 0%;
  left: 0;
  right: 0;
  padding: 10px 20px 20px 20px !important;
  background: #f8f8f8;
  border-top: 1px solid #eee;
}

/* 조건을 만족할 때 적용할 클래스 */
.input-container.center {
  position: absolute;
  border-top : none;
  box-shadow: none;
  /* bottom: 35%; */
  bottom: 0%;
  opacity: 1;
  visibility: visible;
}

/* 추가: 완전히 사라지지 않도록 하기 위해 최소 높이 설정 */
.input-container.center::after {
  content: "";
  display: block;
  height: 20px; /* 최소 공간 확보 */
}

/* 완전히 안 보이게 만들려면 */
.input-container.center.complete-hide {
  opacity: 0;
  visibility: hidden;
}

.search-voice-container {
  max-width: 1024px;
  margin: 0 auto;
  position: relative;
  display: flex;  // Flexbox 추가
  flex-direction: column-reverse;
  gap: 5px;
  justify-content: center; // 가운데 정렬
  align-items: center; // 세로 가운데 정렬
  min-width: 100%;
}

/* .inputFaq{
  max-width: 830px;
  margin: 0 auto; 
  
} */

/* .input-block-button {
  margin: 5px 5px;
  color: #595959;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 3px !important;
  border: 0.2px solid #efefef;
  background-color: white;
  :hover {
    background-color: #ffffff;
  }
} */

.inputFaq-container {
  display: flex;
  align-items: center;
  position: relative;
  /* max-width: 830px; */
  margin: 0 auto;
  width: 100%;
}

.inputFaq-wrapper {
  overflow-x: auto; /* ✅ 스크롤 가능하게 설정 */
  overflow-y: hidden;
  scroll-behavior: smooth; /* ✅ 부드러운 스크롤 적용 */
  flex: 1;
  display: block;
  white-space: nowrap;
  width: 100%;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* ✅ Webkit(Chrome, Safari)에서 스크롤바 숨기기 */
.inputFaq-wrapper::-webkit-scrollbar {
  display: none;
}

.inputFaq {
  display: flex;
  white-space: nowrap; /* 버튼이 줄 바꿈되지 않도록 설정 */
  gap: 5px;
  padding: 5px;
  max-width: 100%;
}

.input-block-button {
  flex-shrink: 0;
  margin: 0;
  padding: 3px 10px !important;
  color: #3d3d3d;
  width: auto;
  border-radius: 15px;
  font-size: 12px !important;
  font-weight: 500 !important;
  border: 0.2px solid #efefef;
  background-color: #ffffff;
  transition: background-color 0.3s;

  &:hover {
    background-color: #cccccc;
  }
}

/* ✅ 좌우 버튼 스타일 */
.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.3);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
}

.scroll-button.left {
  left: -40px;
}

.scroll-button.right {
  right: -40px;
}


/* 버튼이 없을 때 (즉, faqTexts가 넘치지 않을 때) */
.scroll-btn:disabled {
  display: none;
}


.searchInputBox {
  width: 100%;
  // margin-right: 100px;
  /* max-width: 830px; // 최대 너비 설정 */
  max-width: 920px;
  margin: 0 auto; // 가운데 정렬
}

// .voice-logo-container {
//   position: absolute;
//   right: 30px;
//   top: 80%;
//   transform: translateY(-50%);
// }

.voice-logo-container {
  position: fixed; // absolute에서 fixed로 변경
  right: max(30px, calc((100% - 1024px) / 2 + 30px)); // 동적 위치 계산
  bottom: 100px; // 위치 조정
}

.mikeBtn {
  white-space: nowrap;
  padding: 8px 12px;
}

.bottomLogo__wrap {
  // top: 50%;
  margin-top: 80px !important;
}

/* .chat-messages {
  flex: 1;
  margin: 0 auto;
  overflow-y: auto;
  padding-bottom: 120px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth !important;
} */

// 모바일 대응
@media (max-width: 480px) {
  .questTitleWrap p{
    font-size: 13px !important;
    }
  
    .questTitleWrap button{
    font-size: 13px !important;
    }

  .quests-tag{
    font-size: 12px !important;
    }
  
  .fontMd{
    font-size: 14px !important;
  }
  .answer{
    font-size: 14px !important;
  }

  .eachlinkLinkWrap{
    margin-top: 3px !important
  }

  .subBtnWrap{
    margin-top: 10px !important
  }

  .imgItem{
    width: 50% !important;
  }
  
  .chat-messages {
    padding: 0px;
  }
  
  .input-container {
    position: fixed;
    bottom: 0;
    /* padding: 10px 15px 15px 10px !important; */
    padding: 5px !important;
  }

  .chatListWrap {
    /* padding-bottom: 0px; */
    padding-bottom: 95px !important;
  }

  .chat-messages {
    height: calc(100vh - 160px);
    padding-bottom: 100px;
  }

  .message{
    max-width: 100% !important
  }

  .messageListContainer {
    // padding: 0 20px;
    padding: 0px;
    /* padding-bottom: 80px; */
    padding-bottom: 0px;
  }

  .input-container {
    padding: 10px;
  }
}

.topBtn {
  position: fixed;
  // z-index: 1001;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // right: 6.7%;
  // bottom: 160px;
  // transition: all 0.3s ease;

  /* position: absolute; */
  width: 15px;
  height: 15px;
  /* z-index: 1001; */
  text-align: center;
  align-items: center;
  display: flex;
  // left: 50%;
  right: 10%;
  // transform: translateX(-50%);
  // margin: 0 auto;
  bottom: 250px;
  :hover{
    background-color: #ffffff;
  }
}
.bottomBtn {
  position: fixed;
  width: 15px;
  height: 15px;
  /* z-index: 1001; */
  text-align: center;
  align-items: center;
  display: flex;
  // left: 50%;
  right: 10%;
  // transform: translateX(-50%);
  // margin: 0 auto;
  bottom: 180px;
  :hover{
    background-color: #ffffff;
  }
}

.goButton {
  width: 40px !important;
  height: 40px !important;
  /* min-width: 40px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  color: #878787;
  border: 0.5px solid #a1a1a1;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  
  &:hover {
    transform: translateY(-3px);
    background-color: #7c7c7c;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 480px) {
    width: 20px !important;
    height: 20px !important;
    font-size: 18px;
  }
}

// .topBtn {
//   position: fixed;
//   width: 10px;
//   height: 50px;
//   z-index: 1001;
//   text-align: center;
//   align-items: center;
//   display: flex;
//   // left: 50%;
//   right: 25%;
//   // transform: translateX(-50%);
//   // margin: 0 auto;
//   bottom: 120px;
// }

// .button {
//   margin-right: 10px;
//   width: 100% !important;
//   height: 100% !important;
//   // padding: 15px;
//   font-weight: bold;
//   border: 1px solid gray;
//   background-color: #fff;
//   line-height: 40px;
//   border-radius: 50%;
//   text-align: center;
//   font-size: 25px;
//   cursor: pointer;
// }

// .channel-banner {
//     display: flex;
//     width: 100%;
//     height: 100px; /* 모바일 화면에서 높이 조절 */
//     overflow: hidden; /* 초과 부분 숨김 */
//     background-image: url("/src/assets/bn_img_namseoul.jpg"); /* 배경 이미지 적용 */
//     background-size: cover; /* 비율 유지하며 전체 채우기 */
//     background-position: center center; /* 중앙 정렬 */
//     background-repeat: no-repeat; /* 반복 금지 */
//     display: flex;
//     align-items: center; /* 수직 중앙 정렬 */
//     justify-content: center; /* 가로 중앙 정렬 */
//     color: white; /* 글씨 색상 */
//     text-align: center; /* 텍스트 가운데 정렬 */
//     justify-content: center;
//     align-items: center;
//   }
//   .channel-banner-content {
//     // background: rgba(0, 0, 0, 0.3); /* 텍스트 가독성을 위한 반투명 배경 */
//     padding: 5px 10px;
//     border-radius: 5px;
//     max-width: 90%; /* 모바일에서 너무 길어지지 않도록 제한 */
//     text-align: center;
//   }
//   .channel-title {
//     font-size: 1.2em; /* 제목 크기 조절 */
//   }
//   .channel-subtitle {
//     font-size: 0.9em; /* 부제목 크기 조절 */
//   }
/* 스켈레톤 스타일 */
/* .skeleton-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
}

.skeleton {
  background-color: #e0e0e0;
  border-radius: 8px;
  animation: shimmer 1.5s infinite linear;
}

.skeleton-answer {
  width: 88%;
  height: 200px;
  align-self: flex-start;
}

.skeleton-question{
  width: 30%;
  height: 100px;
  align-self: flex-end
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
} */
.skeleton-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
}

.skeleton {
  background-color: #e0e0e0;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

/* Skeleton이 우측으로 흐르는 파도타기 효과 */
.skeleton::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(90deg, rgba(224, 224, 224, 0.6) 25%, rgba(240, 240, 240, 1) 50%, rgba(224, 224, 224, 0.6) 75%);
  animation: shimmer-wave 1.5s infinite linear;
}

/* 깜빡이는 효과 */
@keyframes shimmer-wave {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
}

/* Skeleton 요소가 깜빡이는 효과 추가 */
.skeleton-answer,
.skeleton-question {
  animation: blink 1.5s infinite ease-in-out;
}

.skeleton-answer {
  width: 88%;
  height: 200px;
  align-self: flex-start;
}

.skeleton-question {
  width: 30%;
  height: 100px;
  align-self: flex-end;
}

.subInfoTitle {
  margin-left: 15px;
  margin-top: 15px;
  font-weight: bold;
}

------------------

/* 콘텐츠 전환 효과 */
.content-container {
  padding: 20px;
  text-align: center;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.borderBottom{
  /* width: 50%; */
  width: 95% !important;
  margin: 0 auto;
  padding: 5px 0px;
  border-bottom: 0.2px dashed rgb(196, 196, 196)
}

.DialogOverlay {
  width: 100vw !important;
  height: 100vh !important;
  position: fixed !important;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  overflow-y: hidden;
  // z-index:3;
}

.fade-in-enter-active, .fade-in-leave-active {
  transition: opacity 0.5s ease-in-out, transform 0.3s ease-out;
}

.fade-in-enter-from, .fade-in-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.noLoginAnswerWrap{
  margin: 0 auto;
  display: flex;
  width: 80%;
  padding: 10px;
  line-height: 24px;
  /* min-height: 100%; */
  box-shadow: 0 3px 4px 2px rgba(148, 148, 148, 0.3);
  background-color: white;
  border-radius: 10px;
  padding: 30px ;
  position: relative;
  text-align: left;
  min-width: 40px;
  margin-top : 30px
}

  .noLoginFaq{
    margin-top: 30px;
    justify-content: left;
  }

  .noLoginAsk{
  width: 100%;
  margin: 0 auto;
  padding-top: 30px;
  text-align: center;
  }

  .questListUnderLine {
    width: 100%;
    border-top: 1px dotted gray;
    padding-bottom: 10px;
    margin-top: 10px
    
  }
  
  .quests-section {
  display: flex;
  flex-direction: column;
  padding: 0px 20px 0 15px;
  margin-top: 10px;

  .quests-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #f1f1f1;
    border-radius: 10px;
    padding: 10px 6px;
    overflow: hidden;
    position: relative;
    transition: max-height 0.3s ease-in-out;

    /* 기본적으로 흐려짐 효과 적용 */
    // &:not(.expanded) {
    //   -webkit-mask-image: linear-gradient(to bottom, 
    //     rgba(0, 0, 0, 1) 85%,  
    //     rgba(0, 0, 0, 0.5) 95%, 
    //     rgba(0, 0, 0, 0) 100%
    //   );
    //   mask-image: linear-gradient(to bottom, 
    //     rgba(0, 0, 0, 1) 85%,  
    //     rgba(0, 0, 0, 0.5) 95%, 
    //     rgba(0, 0, 0, 0) 100%
    //   );
    // }
  }

  /* expanded 상태에서는 흐려짐 효과 제거 */
  .quests-wrap.expanded {
    -webkit-mask-image: none !important;
    mask-image: none !important;
  }

  .questTitleWrap {
    display: flex;
    justify-content: space-between;
  }

  .quests-wrap p {
    font-weight: bold;
    font-size: 14px;
    padding-left: 8px;
  }

  .quests-tag {
    display: inline-block;
    padding: 3px 8px;
    background-color: white;
    border-radius: 13px;
    border: 0.5px solid #7c7c7c;
    font-weight: 500;
    font-size: 15px ;
    color: #000;
    cursor: pointer;
    /* font-size: 14px; */
    margin: 2px 4px;

    &:hover {
      background-color: #e5e5e5;
    }
  }
}

.quests-tag {
  margin-bottom: 4px !important;
}
/* 기본 상태 */
.questsBox {
  /* --max-height: 72px;  */
  --max-height: 115px;
  max-height: var(--max-height);
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

/* expanded 상태 */
.questsBox.expanded {
  max-height: 1000px;
  transition: max-height 0.5s ease-in-out;
}


.more-btn {
  background: none;
  box-shadow: none !important;
  border: none;
  color: #434343;
  cursor: pointer;
  padding: 0px 10px 0px 0px !important;
  font-size: 14px;
  display: block;
  /* margin: 5px auto; */
  /* text-decoration: underline; */
}


.QuestionIcon{
  color:#919191; 
  margin-bottom:2px;
}

</style>