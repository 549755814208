<!-- FolderWithAddButton.vue -->

<script setup lang="ts">
import { ref, watch, nextTick, computed } from "vue";
import { useStore } from "../../../stores/store";
import { KmsFolder } from "../../../api/types";

const kmsStore = useStore();

const props = defineProps({
  folder: {
    type: Object,
    required: true
  },
  level: {
    type: Number,
    default: 0
  },
  folderMode: {
    type: String,
    default: "none"
  }
});

// 폴더 상태
const showFolderAddPopup = ref(false);
const folderName = ref("");
const isSubFolder = ref(false);
const selectedParentFolder = ref(null);
const isDragging = ref(false);
const isDropTarget = ref(false);
const folderInputRef = ref(null);

// emit 정의
const emit = defineEmits(["folder-action", "add-folder"]);

watch(showFolderAddPopup, (newValue) => {
  if (newValue) {
    nextTick(() => {
      if (folderInputRef.value) {
        folderInputRef.value.focus();
      }
    });
  }
});

// [나머지 모든 메서드들은 FolderTreeItem과 동일하게 복사]
const handleKeyPress = (event) => {
  if (event.key === "Enter") {
    event.preventDefault();
    event.stopPropagation();
    if (!showFolderAddPopup.value) return;
    addFolder();
  }
};

const hasSubFolders = computed(() => {
  return kmsStore.sortedFolders.some(
    (f) =>
      f.parent_folder_key === props.folder.kms_folder_key && f.delete_yn !== 1
  );
});

const handleFolderClick = async (folderKey: number) => {
  if (folderKey === kmsStore.selectedFolderKey) {
    kmsStore.toggleFolder(folderKey);
    return;
  }

  kmsStore.toggleFolder(folderKey);
  await kmsStore.selectFolder(folderKey);
};

const openFolderAddPopup = (parentFolderKey = null) => {
  if (!kmsStore.selectedFolderGroupKey) {
    alert("폴더 그룹을 선택해주세요.");
    return;
  }

  if (parentFolderKey === 0) {
    isSubFolder.value = false;
    selectedParentFolder.value = 0; // 최상위 폴더로 설정
  }
  // 개별 폴더에서 호출된 경우
  else {
    isSubFolder.value = true;
    selectedParentFolder.value = parentFolderKey;
  }

  showFolderAddPopup.value = true;
};

const closeFolderAddPopup = () => {
  showFolderAddPopup.value = false;
  folderName.value = "";
  isSubFolder.value = false;
  selectedParentFolder.value = null;
};

const addFolder = async () => {
  if (!folderName.value.trim() || !kmsStore.selectedFolderGroupKey) {
    alert("폴더명을 입력해주세요.");
    return;
  }
  const newFolder: KmsFolder = {
    kms_folder_key: 155,
    kms_folder_group_key: kmsStore.selectedFolderGroupKey,
    parent_folder_key: isSubFolder.value ? selectedParentFolder.value ?? 0 : 0,
    folder_name: folderName.value.trim()
  };

  try {
    await kmsStore.addNewFolder(newFolder);
    // await createKmsFolder(newFolder);
    closeFolderAddPopup();

    // 자식 폴더인 경우 부모 폴더를 확장 상태로 유지
    if (isSubFolder.value && selectedParentFolder.value) {
      kmsStore.expandedFolders.add(selectedParentFolder.value);
    }

    // alert(`${isSubFolder.value ? "하위 " : ""}폴더가 추가되었습니다.`);
  } catch (error) {
    console.error("폴더 추가 실패:", error);
    alert("폴더 추가에 실패했습니다.");
  }
};

// 폴더 depth 계산하여 화면상 들여쓰기
const calculateFolderDepth = (folder) => {
  let depth = 0;
  let currentFolder = folder;
  while (currentFolder.parent_folder_key !== 0) {
    depth++;
    currentFolder = kmsStore.folders.find(
      (f) => f.kms_folder_key === currentFolder.parent_folder_key
    );
    if (!currentFolder) break;
  }
  return depth;
};

const handleDragStart = (event: DragEvent) => {
  if (!event.dataTransfer || !kmsStore.isEditMode) return;
  event.dataTransfer.setData(
    "text/plain",
    props.folder.kms_folder_key.toString()
  );
  // event.dataTransfer.effectAllowed = "move";
  isDragging.value = true;
  if (event.target instanceof HTMLElement) {
    event.target.style.opacity = "0.4";
  }
};

const handleDrop = async (kms_folder_key: number) => {
  console.log("handleDrop");
  console.log("kms_folder_key", kms_folder_key);

  try {
    const targetFolderKey = props.folder.kms_folder_key;
    console.log("targetFolderKey", targetFolderKey);

    // 자기 자신에게 드롭 방지
    if (kms_folder_key === targetFolderKey) {
      return;
    }

    // 순환 참조 방지
    if (checkCircularReference(targetFolderKey, kms_folder_key)) {
      alert("폴더를 자신의 하위 폴더로 이동할 수 없습니다.");
      return;
    }

    const dragFolder = await kmsStore.selectFolder(targetFolderKey);
    console.log("dragFolder", dragFolder);
    const moveFolder = await kmsStore.moveFolder(dragFolder, targetFolderKey);
    console.log("moveFolder", moveFolder);
  } catch (error) {
    console.error("폴더 이동 실패:", error);
    alert("폴더 이동에 실패했습니다.");
  } finally {
    isDropTarget.value = false;
    isDragging.value = false;
  }
};

const checkCircularReference = (
  targetKey: number,
  draggedKey: number
): boolean => {
  let currentFolder = kmsStore.folders.find(
    (f) => f.kms_folder_key === targetKey
  );
  while (currentFolder && currentFolder.parent_folder_key !== 0) {
    if (currentFolder.parent_folder_key === draggedKey) {
      return true;
    }
    currentFolder = kmsStore.folders.find(
      (f) => f.kms_folder_key === currentFolder?.parent_folder_key
    );
  }
  return false;
};

const handleDragOver = (event: DragEvent) => {
  event.preventDefault();
  if (kmsStore.isEditMode) {
    isDropTarget.value = true;
  }

  if (event.currentTarget instanceof HTMLElement) {
    event.currentTarget.style.backgroundColor = "#e3f2fd";
  }
};

const handleDragLeave = (event: DragEvent) => {
  isDropTarget.value = false;

  if (event.currentTarget instanceof HTMLElement) {
    event.currentTarget.style.backgroundColor = "";
  }
};

const handleDragEnd = (event: DragEvent) => {
  isDragging.value = false;
  isDropTarget.value = false;

  if (event.target instanceof HTMLElement) {
    event.target.style.opacity = "1";
  }
};
</script>

<template>
  <div
    class="folder-item"
    :class="{
      dragging: isDragging,
      'drop-target': isDropTarget
    }"
    :draggable="kmsStore.isEditMode"
    @dragstart="handleDragStart"
    @dragend="handleDragEnd"
    @dragover.prevent="handleDragOver"
    @dragleave="handleDragLeave"
    @drop.prevent="handleDrop"
  >
    <div
      class="folder-header"
      :class="{
        selected: folder.kms_folder_key === kmsStore.selectedFolderKey
      }"
      @click="handleFolderClick(folder.kms_folder_key)"
    >
      <div
        class="folder-title"
        :style="{
          paddingLeft: `${calculateFolderDepth(props.folder) * 10}px`,
          borderLeft:
            calculateFolderDepth(props.folder) > 0
              ? '2px solid #e0e0e0'
              : 'none'
        }"
      >
        <span class="folder-icon">
          {{
            kmsStore.sortedFolders.some(
              (f) =>
                f.parent_folder_key === folder.kms_folder_key &&
                f.delete_yn !== 1
            )
              ? kmsStore.expandedFolders.has(folder.kms_folder_key)
                ? "⌵"
                : ">"
              : ">"
          }}
        </span>
        <span class="folder-name" :title="folder.folder_name">
          {{ folder.folder_name }}
        </span>
        <!-- 추가된 + 버튼 -->
        <span class="add-icon" @click.stop="$emit('add-folder', folder)"
          >+</span
        >
      </div>
    </div>

    <!-- 하위 폴더 -->
    <div
      v-if="kmsStore.expandedFolders.has(folder.kms_folder_key)"
      class="subfolder-container"
    >
      <folder-with-add-button
        v-for="childFolder in folder.children || []"
        :key="childFolder.kms_folder_key"
        :folder="childFolder"
        :folder-mode="folderMode"
        @folder-action="$emit('folder-action', $event)"
        @add-folder="$emit('add-folder', $event)"
      />
    </div>
    <!-- <div
      v-if="kmsStore.expandedFolders.has(folder.kms_folder_key)"
      class="subfolder-container"
    >
      <folder-with-add-button
        v-for="childFolder in kmsStore.sortedFolders.filter(
          (f) =>
            f.parent_folder_key === folder.kms_folder_key && f.delete_yn !== 1
        )"
        :key="childFolder.kms_folder_key"
        :folder="childFolder"
        :folder-mode="folderMode"
        @folder-action="$emit('folder-action', $event)"
        @add-folder="$emit('add-folder', $event)"
      />
    </div> -->

    <!-- 팝업 -->
    <div v-if="showFolderAddPopup" class="folderAddpopup">
      <div class="popup-content">
        <div class="popup-header">
          <h5>{{ isSubFolder ? "하위 폴더 추가" : "폴더 추가" }}</h5>
          <button @click="closeFolderAddPopup">X</button>
        </div>
        <div class="popup-body">
          <input
            v-model="folderName"
            :placeholder="isSubFolder ? '하위 폴더명 입력' : '폴더명 입력'"
            ref="folderInputRef"
            @keyup="handleKeyPress"
          />
        </div>
        <div class="popup-footer">
          <button type="button" class="edit-Btn" @click.stop="addFolder">
            추가
          </button>
          <button type="button" class="edit-Btn" @click="closeFolderAddPopup">
            취소
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
ul {
  list-style: none;
  padding-left: 0;
}

li {
  padding: 5px 10px;
}

.folder-container {
  flex: 1;
  overflow-y: auto;
  margin: 8px 0 10px 0;
  height: calc(100% - 80px); /* 상단 헤더와 하단 푸터 높이를 제외한 높이 */
  position: relative; /* 상대 위치 설정 */
}

.edit-Btn {
  font-weight: bold !important;
}

.edit-button,
.editBtn {
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
}

.section-footer .edit-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 8px 8px;
  /* background-color: #545454; */
}

.folder-header {
  padding: 6px !important;
  cursor: pointer;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dedede;
  border-left: 4px solid transparent;
  font-size: 14px !important;
}

.folder-header.selected {
  border-left: 4px solid #616364 !important; /* 선택된 상태의 왼쪽 바 색상 */
  background-color: #f8f9fa !important;
}

.folder-header.active {
  background-color: #dfdfdf;
  color: black;
}

.folder-title {
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
  min-height: 24px; /* 높이 추가 */
}

.folder-title button {
  padding: 2px 8px;
  font-size: 14px;
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.folder-title button {
  opacity: 0;
  transition: opacity 0.2s;
}

.folder-header:hover .folder-title button {
  opacity: 1;
}

.folder-icon {
  width: 16px; /* 20px에서 16px로 수정 */
  text-align: center;
  color: #666;
  font-size: 12px; /* 아이콘 크기 조정 */
}

.folder-name {
  flex: 1;
  margin-right: auto;
  /* cursor: pointer; */
}

.folder-header:hover {
  background-color: #e9ecef;
}

.folder-actions {
  display: flex;
  gap: 8px;
  margin-left: auto;
  /* justify-content: flex-end; */
  /* min-width: 60px; */
}

.subfolder-container {
  /* padding-left: 24px; */
  width: 100%;
  background-color: #fff;
  padding-left: 0;
}

/* 들여쓰기 레벨별 패딩 설정 */
/* .subfolder-container .folder-title {
  padding-left: 10px;
}

.subfolder-container .subfolder-container .folder-title {
  padding-left: 20px;
}

.subfolder-container .subfolder-container .subfolder-container .folder-title {
  padding-left: 30px;
} */

.subfolder-item {
  /* position: relative; */
  padding: 10px;
  cursor: pointer;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dedede;
  font-size: 1.2em;
}

.subfolder-item::before {
  content: "ㄴ";
  position: absolute; /* 위치 절대 지정 */
  left: 10px; /* 텍스트와의 간격 */
  color: #999; /* 텍스트 색상 */
  font-size: 1em; /* 크기 조정 */
}

.subfolder-item.active {
  background-color: #e9ecef;
  color: black;
}

.editBtn {
  opacity: 0;
  transition: opacity 0.2s;
}
.editBtn:hover {
  opacity: 1;
}

.editpopup-btn {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.editbtnWrap {
  display: flex;
  gap: 10px;
}

.subfolder-item:last-child {
  border-bottom: none;
}

.subfolder-item:hover {
  background-color: #f8f9fa;
}

.subfolder-item:hover .folder-actions {
  opacity: 1;
}

/* 팝업 스타일 */
.folderAddpopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.popup-content {
  display: flex;
  flex-direction: column;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-header h5 {
  margin: 0;
  font-size: 16px;
}

.popup-header .close-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.popup-body label {
  margin-bottom: 8px;
  font-size: 14px;
}

.popup-body input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.popup-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.popup-actions button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup-actions button:first-child {
  background-color: #007bff;
  color: white;
}

.popup-actions button:first-child:hover {
  background-color: #0056b3;
}

.popup-actions button:last-child {
  background-color: #f5f5f5;
  color: #333;
}

.popup-actions button:last-child:hover {
  background-color: #e0e0e0;
}

.folder-item {
  width: 100%;
}

.folder-item:hover {
  background-color: #f0f0f0;
}

.folder-item .folder-name {
  flex: 1;
}

.folder-item .folder-actions {
  display: flex;
  gap: 10px;
  padding-left: 10px;
  justify-content: space-between;
  /* opacity: 0; */
  transition: opacity 0.2s;
}

/* 폴더 드래그 */
.folder-item[draggable="true"] {
  cursor: move;
  transition: all 0.2s ease;
}

.folder-item[draggable="true"]:hover {
  opacity: 0.7;
}

.folder-item[draggable="true"]:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.drag-handle {
  cursor: move;
  color: #666;
  margin-right: 8px;
  user-select: none;
}

.drag-handle:active {
  cursor: grabbing;
}

/* 드래그 중인 대상의 스타일 */
.folder-item.dragging {
  opacity: 0.5;
  background-color: #f5f5f5;
}

/* 드롭 가능한 영역의 스타일 */
.folder-item.drop-target {
  border: 2px dashed #007bff;
  background-color: #f8f8f8;
}

.folder-header,
.subfolder-item {
  padding: 10px;
  cursor: pointer;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dedede;
  font-size: 1.2em;
}

.folder-header:hover,
.subfolder-item:hover {
  background-color: #e9ecef;
}

.folder-title {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.folder-item .edit-button .file-button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #545454;
  font-weight: 600;
  color: white;
}

.folder-item .edit-button:hover {
  background-color: #545454;
  color: white;
  /* opacity: 0; */
}

.border-button {
  padding: 2px 15px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  background-color: #545454;
  font-weight: 600;
  color: white;
}

.border-button {
  border: 1px solid #ccc;
  background-color: white;
  color: #545454;
}

.select-box {
  width: 100%;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #ddd;
  background-color: #e9ecef;
  color: #505050;
  margin-bottom: 10px;
  font-size: 1.2em;
  font-weight: bold;
  color: black;
}

.sub-folder {
  padding-left: 20px; /* 하위 폴더 들여쓰기 */
  font-size: 14px;
  color: #555;
}

.folder-name {
  flex: 1;
  margin-right: auto;
  cursor: pointer; /* 클릭 가능한 폴더 이름 표시 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 100px);
}

.folder-tree {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.add-icon {
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #ddd;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  margin-left: 8px;

  &:hover {
    background-color: #f8f8f8;
  }
}
</style>
