<script setup lang="ts">
import { inject } from "vue";
import { Primitive, type PrimitiveProps } from "radix-vue";
import { sidebarKey } from "./SidebarRoot.vue";
const props = withDefaults(defineProps<PrimitiveProps>(), {
  as: "button"
});
const context = inject(sidebarKey);
</script>
<template>
  <Primitive v-bind="props" @click="context?.toggleEnabled">
    <slot
      v-if="context?.enabled.value === true && $slots.disable"
      name="disable"
    ></slot>
    <slot
      v-else-if="context?.enabled.value === false && $slots.enable"
      name="enable"
    ></slot>
    <slot name="default"></slot>
  </Primitive>
</template>
