<!-- ChannelDetailCompo -->
<script setup lang="ts">
import AddCircle from "@fluentui/svg-icons/icons/add_circle_24_regular.svg";
import AdminIcon from "@fluentui/svg-icons/icons/crown_20_filled.svg";
import LockIcon from "@fluentui/svg-icons/icons/lock_closed_key_20_filled.svg";
import SettingsIcon from "@fluentui/svg-icons/icons/settings_20_regular.svg";
import AIIcon from "@fluentui/svg-icons/icons/bot_20_filled.svg";
import KnowledgeIcon from "@fluentui/svg-icons/icons/book_20_filled.svg";
import QuestionIcon from "@fluentui/svg-icons/icons/question_circle_20_regular.svg";
import DotIcon from "@fluentui/svg-icons/icons/circle_small_20_filled.svg";
import LinkIcon from "@fluentui/svg-icons/icons/link_20_regular.svg";

import { useQuasar } from "quasar";
import { DialogRoot } from "radix-vue";
import { onMounted, ref, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  createFileList,
  joinChannel,
  unjoinChannel
} from "../.././../api/backend";
import { usePopupStore } from "../../../stores/popups";
import { useStore } from "../../../stores/store.ts";
import { elapsedYear } from "../../../utils/date";
import { updateChannel } from "../.././../api/backend";
import { QToggle } from "quasar";
import { Channel } from "../../../api/types";
import channeLimg from "../../../assets/profile.png";

const store = useStore();
const route = useRoute();
const router = useRouter();
const popStore = usePopupStore();
const $q = useQuasar();

// 섹션 레퍼런스 객체들
const aiSectionRef = ref(null);
const responseSectionRef = ref(null);
const knowledgeSectionRef = ref(null);
const questionSectionRef = ref(null);
const uiSectionRef = ref(null);

// 활성화된 탭
const activeTab = ref("ai");

// 각 설정 활성화 상태 관리
const afunEnabled = ref(true);
const afunLevel = ref("premium"); // normal, premium
const multiLangEnabled = ref(true);
const voiceChatEnabled = ref(true);
const avatarEnabled = ref(false);
const faqEnabled = ref(true);

// KMS 설정 활성화 상태 관리
const kmsEnabled = ref(true);
const dynamicKnowledgeEnabled = ref(true);
const aggregateKnowledgeEnabled = ref(true);
const myKnowledgeEnabled = ref(true);
const invalidationEnabled = ref(true);
const folderGroupSearchEnabled = ref(true);
const folderNameSearchEnabled = ref(true);

// 질문 설정 활성화 상태 관리
const multiHyBridSearchEnabled = ref(true);
const titleSearchEnabled = ref(true);
const summarySearchEnabled = ref(true);
const keywordSearchEnabled = ref(true);
const multiTurnEnabled = ref(true);
const switchGptEnabled = ref(false);
const dateConvertEnabled = ref(true);

// 답변 설정 활성화 상태 관리
const responseScopeLevel = ref("strict");
const responseLengthLevel = ref("medium");
const viewKnowledgeEnabled = ref(false);
const viewDevCoTEnabled = ref(true);
const uiTableEnabled = ref(true);
const uiCalendarEnabled = ref(false);
const uiMapEnabled = ref(false);
const recommandQuestionEnabled = ref(false);
const bodyImagesEnabled = ref(true);
const linksEnabled = ref(true);
const imageFiles = ref(true);
const attachFiles = ref(true);
const toneAndStyle = ref(true);
const reponseToneLevel = ref("formal");

const tone = ref("");
const originalTone = ref("");
const bypassPhrases = ref([""]);
const isActiveListener = ref(false);
const channelSecureYn = ref(0);
const channelSecureNum = ref("");

// 민감/모르는 질문 답변 관리
const sensitiveQuestionResponse = ref(
  "죄송합니다. 이 질문은 민감한 주제를 포함하고 있어 답변을 제공하기 어렵습니다. 다른 질문으로 도움을 드릴 수 있을까요?"
);
const unknownQuestionResponse = ref(
  "죄송합니다. 현재 제가 가진 지식으로는 해당 질문에 답변하기 어렵습니다. 다른 방식으로 질문해 주시거나 다른 주제에 대해 문의해 주세요."
);

// 원본 응답 백업 (취소 시 복원용)
const originalSensitiveResponse = ref("");
const originalUnknownResponse = ref("");

// 민감한 질문 응답 저장
const saveSensitiveResponse = () => {
  try {
    // 여기에 실제 저장 로직 (API 호출 등) 추가
    $q.notify({
      message: "민감 질문 응답이 저장되었습니다.",
      color: "positive",
      timeout: 2000
    });
    originalSensitiveResponse.value = sensitiveQuestionResponse.value;
  } catch (error) {
    console.error("민감 질문 응답 저장 실패:", error);
    $q.notify({
      message: "저장에 실패했습니다.",
      color: "negative",
      timeout: 2000
    });
  }
};

// 모르는 질문 응답 저장
const saveUnknownResponse = () => {
  try {
    // 여기에 실제 저장 로직 (API 호출 등) 추가
    $q.notify({
      message: "모르는 질문 응답이 저장되었습니다.",
      color: "positive",
      timeout: 2000
    });
    originalUnknownResponse.value = unknownQuestionResponse.value;
  } catch (error) {
    console.error("모르는 질문 응답 저장 실패:", error);
    $q.notify({
      message: "저장에 실패했습니다.",
      color: "negative",
      timeout: 2000
    });
  }
};

const props = defineProps({
  pIsPopYn: Boolean
});

// 채널 정보
const currentChannel = ref<Channel>({
  channel_key: Number(store.currentChannel?.channel_key) || 0,
  parent_key: Number(store.currentChannel?.parent_key) || 0,
  cre_user_key: Number(store.currentChannel?.cre_user_key) || 0,
  delete_yn: Number(store.currentChannel?.delete_yn) || 0,
  name: store.currentChannel?.name || "",
  cre_date: store.currentChannel?.cre_date || "",
  lc_uuid: store.currentChannel?.channel_key || "",
  secure_str4: store.currentChannel?.secure_str4 || undefined,
  secure_yn: Number(store.currentChannel?.secure_yn) || 0,
  description: store.currentChannel?.description || "",
  faq_texts: store.currentChannel?.faq_texts || ""
});

// 보안 설정 토글 계산된 속성
const secureToggle = computed({
  get: () => currentChannel.value.secure_yn === 1, // 1이면 true
  set: (value) => {
    currentChannel.value.secure_yn = value ? 1 : 0; // true이면 1, false이면 0으로 설정
  }
});

// 카테고리 목록
const categories = ref([
  { name: "기업", value: "기업" },
  { name: "식당", value: "식당" },
  { name: "카페", value: "카페" },
  { name: "공공장소", value: "공공장소" },
  { name: "종교", value: "종교" }
]);

const newDetailDesc = ref("");
const selectedCategory = ref("");
const newName = ref("");

// 채널 업데이트 함수
const onChannelUpdate = async () => {
  if (channelName.value === "" || channelSecureNum.value.length < 4) {
    $q.notify({
      message: "입력란을 채워주세요.",
      color: "negative",
      timeout: 3000
    });
    return;
  }

  if (currentChannel.value.channel_key > 0) {
    // AI 설정을 문자열로 변환
    const aiSettings = JSON.stringify({
      afun: afunEnabled.value,
      afunLevel: afunLevel.value,
      multiLang: multiLangEnabled.value,
      voiceChat: voiceChatEnabled.value,
      avatar: avatarEnabled.value,
      faq: faqEnabled.value // FAQ 활성화 상태 저장
    });

    // KMS 설정을 문자열로 변환
    const kmsSettings = JSON.stringify({
      kms: kmsEnabled.value,
      dynamic: dynamicKnowledgeEnabled.value,
      aggregate: aggregateKnowledgeEnabled.value,
      myKnowledge: myKnowledgeEnabled.value,
      invalidation: invalidationEnabled.value
    });

    // faqEnabled가 false면 FAQ 텍스트 비우기
    const updatedFaqTexts = faqEnabled.value
      ? faqTexts.value.filter((text) => text.trim() !== "")
      : [];

    const updatedChannel = {
      ...currentChannel.value,
      name: channelName.value,
      description: channelDesc.value,
      secure_str4: channelSecureNum.value,
      faq_texts: updatedFaqTexts.join("#;#"), // `#;#`로 이어붙여 저장
      ai_settings: aiSettings,
      kms_settings: kmsSettings,
      logo_img_filekey: bannerImage.value,
      links: JSON.stringify(validLinks.value)
    };

    try {
      await updateChannel(updatedChannel);
      changeToDetail();
      $q.notify({
        message: "채널 수정이 완료되었습니다.",
        color: "positive",
        timeout: 3000
      });
    } catch (error) {
      $q.notify({
        message: "채널 수정에 실패했습니다.",
        color: "negative",
        timeout: 3000
      });
      console.error("Update failed:", error);
    }
  } else {
    $q.notify({
      message: "현재 채널 정보를 불러올 수 없습니다.",
      color: "negative",
      timeout: 3000
    });
  }
};

// 구독 및 구독취소
const getChannelIndex = () => {
  const currentChannelKey = currentChannel?.value?.channel_key;
  const chanIndex = store.allChannelList.findIndex(
    (item) => item.channel_key === currentChannelKey
  );
  return chanIndex;
};

const followThisChannelParam = (channel: Channel) => {
  const params = new Object();
  params.user_key = store.myInfo?.user_key;
  params.channel_key = channel.channel_key;
  params.auth_type = "S";
  params.grant_user_key = 0;
  return params;
};

const followThisChannel = async () => {
  const currentChannelKey = currentChannel?.value?.channel_key;
  const chanIndex = store.allChannelList.findIndex(
    (item) => item.channel_key === currentChannelKey
  );

  await joinChannel(followThisChannelParam(currentChannel?.value));
  $q.notify("구독이 완료되었습니다.");
  store.updateChannelAuth(getChannelIndex());
};

const unFollowThisChannelParam = (channel: object) => {
  const param = new Object();
  param.user_key = store.myInfo?.user_key;
  param.channel_key = channel.channel_key;
  return param;
};

const unFollowThisChannel = async () => {
  await unjoinChannel(unFollowThisChannelParam(currentChannel?.value));
  $q.notify("구독이 취소되었습니다.");
  store.deleteFollowChannelAuth(getChannelIndex());
};

// 채널 정보 변경
const modiYn = ref(false);

// "편집" 버튼 클릭 시 동작 수정
const changeToModi = () => {
  if (!modiYn.value) {
    modiYn.value = true; // 편집 모드 활성화
    newName.value = currentChannel.value?.name;

    // 저장된 설정을 로드 (실제로는 서버에서 불러오는 로직이 필요)
    try {
      if (currentChannel.value.ai_settings) {
        const aiSettings = JSON.parse(currentChannel.value.ai_settings);
        afunEnabled.value = aiSettings.afun;
        afunLevel.value = aiSettings.afunLevel;
        multiLangEnabled.value = aiSettings.multiLang;
        voiceChatEnabled.value = aiSettings.voiceChat;
        avatarEnabled.value = aiSettings.avatar;
        faqEnabled.value = aiSettings.faq;
      }

      if (currentChannel.value.kms_settings) {
        const kmsSettings = JSON.parse(currentChannel.value.kms_settings);
        kmsEnabled.value = kmsSettings.kms;
        dynamicKnowledgeEnabled.value = kmsSettings.dynamic;
        aggregateKnowledgeEnabled.value = kmsSettings.aggregate;
        myKnowledgeEnabled.value = kmsSettings.myKnowledge;
        invalidationEnabled.value = kmsSettings.invalidation;
      }
    } catch (e) {
      console.error("설정 로드 중 오류:", e);
    }
  } else {
    onChannelUpdate(); // 저장
  }
};

const changeToDetail = () => {
  modiYn.value = false;
};

const changeChannelCategory = (event: any) => {
  selectedCategory.value = event.target.value;
};

const goToChatting = () => {
  router.push("/talk");
};

// const imgUrl = ref("src/assets/profile.png");
// imgUrl을 ref로 선언하여 반응형으로 사용
const imgUrl = ref<string>(channeLimg);

const fileInput = ref(null);
const triggerFileInput = () => {
  fileInput.value.click();
};

const onFileChange = (event: any) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      imgUrl.value = e.target.result;
    };
    reader.readAsDataURL(file);
  }
};

// 배너 이미지 관련 상태
const bannerImage = ref(null);
const bannerFileInput = ref(null);

// 배너 파일 입력창 열기
const openBannerFileInput = () => {
  bannerFileInput.value.click();
};

const bannerPreviewUrl = ref(null);

// 배너 이미지 변경 처리
const handleBannerFileChange = async (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      // 이미지 원본 그대로 저장
      bannerPreviewUrl.value = e.target.result;
    };
    reader.readAsDataURL(file);

    try {
      // FormData 생성
      const formData = new FormData();
      formData.append("file_list", file);
      formData.append(
        "channel_key",
        currentChannel.value.channel_key.toString()
      );

      // createFileList API 호출
      const uploadResult = await createFileList(formData);

      // 반환된 파일 키를 bannerImage의 키로 저장
      if (uploadResult && uploadResult.length > 0 && uploadResult[0].file_key) {
        const fileKey = uploadResult[0].file_key;
        bannerImage.value = fileKey;

        const updatedChannel = {
          ...currentChannel.value,
          logo_img_filekey: fileKey
        };

        // await updateChannel(updatedChannel);

        bannerImage.value = fileKey;

        $q.notify({
          message: "배너 이미지가 성공적으로 업로드되었습니다.",
          color: "positive"
          // timeout: 2000
        });
      } else {
        throw new Error("업로드 결과에 파일 키가 없습니다.");
      }
    } catch (error) {
      console.error("배너 이미지 업로드 실패:", error);
      $q.notify({
        message: "배너 이미지 업로드에 실패했습니다.",
        color: "negative"
        // timeout: 2000
      });
    }
  }
};

// 배너 이미지 삭제
const deleteBanner = () => {
  bannerImage.value = null;
  bannerPreviewUrl.value = null;
  // 파일 입력 초기화
  if (bannerFileInput.value) {
    bannerFileInput.value.value = "";
  }
};

// 링크 관리 상태
const channelLinks = ref([{ title: "", url: "" }]);

// 링크 추가
const addLink = () => {
  channelLinks.value.push({ title: "", url: "" });
};

const validLinks = computed(() => {
  // Filter out any links that have empty title or url
  return channelLinks.value.filter(
    (link) => link.title.trim() !== "" || link.url.trim() !== ""
  );
});

// 링크 삭제
const removeLink = (index: number) => {
  if (index >= 0 && index < channelLinks.value.length) {
    channelLinks.value.splice(index, 1);
  }
};

// 탭 클릭 시 해당 섹션으로 스크롤하는 함수
const changeTab = (tab: string) => {
  activeTab.value = tab;

  // 해당 섹션으로 스크롤
  let targetRef = null;
  switch (tab) {
    case "ai":
      targetRef = aiSectionRef;
      break;
    case "knowledge":
      targetRef = knowledgeSectionRef;
      break;
    case "question":
      targetRef = questionSectionRef;
      break;
    case "response":
      targetRef = responseSectionRef;
      break;
    case "ui":
      targetRef = uiSectionRef;
      break;
  }

  if (targetRef && targetRef.value) {
    targetRef.value.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  // FAQ 탭으로 이동하고 FAQ가 비활성화 상태라면 자동으로 활성화
  if (tab === "faq" && !faqEnabled.value && modiYn.value) {
    faqEnabled.value = true;
  }
};

watch(
  () => store.selectedChannel,
  () => {
    modiYn.value = false;
    currentChannel.value = store.currentChannel;
    selectedCategory.value =
      currentChannel?.value.category === undefined
        ? "기업"
        : currentChannel?.value.category;
  }
);

// store.currentChannel 값이 변경될 때, currentChannel을 즉시 반영
watch(
  () => store.currentChannel,
  (newVal) => {
    if (newVal) {
      currentChannel.value = { ...newVal };
      channelSecureNum.value = newVal.secure_str4 || "";
      selectedCategory.value = newVal.category || "기업";
      bannerImage.value = newVal.image || null;

      // 링크 데이터 로드
      try {
        if (newVal.links) {
          channelLinks.value = JSON.parse(newVal.links);
        } else {
          channelLinks.value = []; // 링크가 없으면 빈 배열로 초기화
        }
      } catch (e) {
        console.error("링크 정보 로드 중 오류:", e);
        channelLinks.value = [];
      }
    }
  },
  { immediate: true } // 처음 마운트될 때도 한 번 실행
);

onMounted(() => {
  currentChannel.value = { ...store.currentChannel };
  setTimeout(() => {
    if (route.path === "/talk") {
      currentChannel.value = store.currentChannel;
    } else if (route.path.includes("manage")) {
      currentChannel.value = store.currentChannel;
    }
  }, 500);

  // 초기 설정 값 설정 (실제로는 서버에서 불러와야 함)
  afunEnabled.value = true;
  afunLevel.value = "premium";
  multiLangEnabled.value = true;
  voiceChatEnabled.value = true;
  avatarEnabled.value = false;
  faqEnabled.value = true;

  kmsEnabled.value = true;
  dynamicKnowledgeEnabled.value = true;
  aggregateKnowledgeEnabled.value = true;
  myKnowledgeEnabled.value = true;
  invalidationEnabled.value = true;
});

onMounted(() => {
  // Make sure the current channel and faq_texts exist
  if (store.currentChannel && store.currentChannel.faq_texts) {
    // Load FAQ texts from the current channel
    faqTexts.value = store.currentChannel.faq_texts
      .split("#;#")
      .filter((text) => text.trim() !== "");

    console.log("자주하는 질문 리스트", store.currentChannel.faq_texts);
    console.log("FAQ texts loaded:", faqTexts.value);
  } else {
    // Initialize with empty array if no data
    faqTexts.value = [];
    console.log("No FAQ texts found, initialized with empty array");
  }
});

const handleInput = (event: any) => {
  channelSecureNum.value = event.target.value;
};

// FAQ 관련 코드
const faqTexts = ref<string[]>([]);

// watch(
//   () => store.currentChannel?.faq_texts,
//   (newValue) => {
//     if (newValue !== undefined) {
//       faqTexts.value = newValue
//         .split("#;#")
//         .filter((text) => text.trim() !== "");
//       console.log("watch - faqTexts updated:", faqTexts.value);
//     }
//   },
//   { immediate: true }
// );
watch(
  () => store.currentChannel?.faq_texts,
  (newValue) => {
    if (typeof newValue === "string") {
      // 문자열인지 확인
      faqTexts.value = newValue
        .split("#;#")
        .filter((text) => text.trim() !== "");
      console.log("watch - faqTexts updated:", faqTexts.value);
    } else {
      faqTexts.value = []; // null 또는 undefined라면 빈 배열 할당
    }
  },
  { immediate: true }
);

// 질문 추가
const addQuestion = () => {
  faqTexts.value.push(""); // 새로운 빈 질문 추가
};

// 개별 질문 저장 함수
const saveQuestion = async (index) => {
  // 저장할 질문이 비어있는지 확인
  if (!faqTexts.value[index] || faqTexts.value[index].trim() === "") {
    $q.notify({
      message: "질문을 입력해주세요.",
      color: "negative",
      timeout: 2000
    });
    return;
  }

  try {
    // 기존 FAQ 텍스트 배열을 복사
    const updatedFaqTexts = [...faqTexts.value];

    // FAQ 텍스트 중 빈 값 제거 (필요한 경우)
    const filteredFaqTexts = updatedFaqTexts.filter(
      (text) => text.trim() !== ""
    );

    // 채널 정보에 업데이트된 FAQ 텍스트 문자열 설정
    currentChannel.value.faq_texts = filteredFaqTexts.join("#;#");

    // API 호출로 업데이트
    await updateChannel({
      ...currentChannel.value,
      faq_texts: filteredFaqTexts.join("#;#")
    });

    // 성공 메시지 표시
    $q.notify({
      message: index + 1 + "번 질문이 저장되었습니다.",
      color: "positive",
      timeout: 2000
    });
  } catch (error) {
    console.error("질문 저장 실패:", error);
    $q.notify({
      message: "질문 저장에 실패했습니다.",
      color: "negative",
      timeout: 2000
    });
  }
};

// 질문 삭제
const removePhrase = (index: number) => {
  if (index >= 0 && index < faqTexts.value.length) {
    faqTexts.value.splice(index, 1); // 해당 인덱스 질문 삭제
  }
};

const toggleSecureColor = computed(() => {
  return channelSecureYn.value ? "#03ceae" : "#03ceae";
});

const toggleColor = computed(() => {
  return isActiveListener.value ? "#03ceae" : "#03ceae";
});

const channelDesc = computed({
  get() {
    return (newDetailDesc.value = store.currentChannel?.description || "");
  },
  set(value) {
    if (store.currentChannel) {
      newDetailDesc.value = store.currentChannel.description;
    }
  }
});

const channelName = computed({
  get() {
    return (newName.value = store.currentChannel?.name || "");
  },
  set(value) {
    if (store.currentChannel) {
      newName.value = store.currentChannel.name = value;
    }
  }
});
</script>

<template>
  <div class="anytalk-admin-container">
    <div>
      <!-- 프로필 섹션 -->
      <div class="profile-section">
        <div class="profile-image">
          <img :src="imgUrl" alt="채널이미지" />
          <span class="add-img-btn" v-if="modiYn">
            <AddCircle @click="triggerFileInput" />
          </span>
          <input
            type="file"
            ref="fileInput"
            @change="onFileChange"
            style="display: none"
          />
        </div>

        <div class="profile-info">
          <div class="profile-title">
            <div class="title-content">
              <p v-if="!modiYn" class="profile-name">
                {{ currentChannel.name }}
              </p>
              <input
                v-else
                type="text"
                v-model="currentChannel.name"
                class="profile-name-input"
              />
              <AdminIcon
                v-if="
                  store.currentChannel &&
                  store.currentChannel.auth_type &&
                  store.currentChannel.auth_type.includes('A')
                "
              />
              <span class="auth-tag">{{
                store.currentChannel?.auth_type?.includes("A")
                  ? "관리자"
                  : "멤버"
              }}</span>
            </div>
          </div>

          <div class="profile-details">
            <div class="category-info">
              <span v-if="!modiYn">{{ selectedCategory }}</span>
              <select @change="changeChannelCategory($event)" v-else>
                <option
                  v-for="cate in categories"
                  :key="cate.value"
                  :value="cate.value"
                >
                  {{ cate.name }}
                </option>
              </select>
              <span class="date-info"
                >채널 생성 {{ elapsedYear(currentChannel.cre_date) }}</span
              >
            </div>
          </div>
        </div>
        <!-- 편집/저장 버튼 -->
        <div class="action-buttons">
          <button class="edit-button" @click="changeToModi">
            {{ modiYn ? "저장" : "편집" }}
          </button>
          <button v-if="modiYn" class="cancel-button" @click="changeToDetail">
            취소
          </button>
        </div>
      </div>

      <!-- 배너 이미지 섹션 -->
      <div class="banner-section"></div>
      <!-- <div @click="openBannerFileInput" class="banner-container"> -->
      <div
        @click="modiYn ? openBannerFileInput() : undefined"
        class="banner-container"
        :class="{ editable: modiYn }"
      >
        <img
          v-if="bannerPreviewUrl"
          :src="bannerPreviewUrl"
          alt="배너 이미지 미리보기"
          class="banner-image"
        />
        <img
          v-else-if="bannerImage"
          :src="bannerImage"
          alt="배너 이미지"
          class="banner-image"
        />
        <div v-else class="empty-banner-message">
          {{
            modiYn
              ? "클릭하여 배너 이미지 추가"
              : "등록된 배너 이미지가 없습니다."
          }}
        </div>

        <!-- 이미지가 있고 편집 모드일 때만 변경/삭제 버튼 표시 -->
        <div
          v-if="(bannerImage || bannerPreviewUrl) && modiYn"
          class="banner-controls"
        >
          <button class="banner-btn add" @click="openBannerFileInput">
            변경
          </button>
          <button class="banner-btn delete" @click="deleteBanner">삭제</button>
        </div>

        <input
          type="file"
          ref="bannerFileInput"
          @change="handleBannerFileChange"
          accept="image/*"
          style="display: none"
        />
      </div>

      <!-- 링크 관리 섹션 -->
      <div class="links-section">
        <div class="links-header">
          <h3 class="links-title"><LinkIcon /> 링크 관리</h3>
          <button v-if="modiYn" @click="addLink" class="add-button">
            + 링크 추가
          </button>
        </div>

        <div class="links-list">
          <div
            v-for="(link, index) in channelLinks"
            :key="index"
            class="link-item"
          >
            <div class="link-inputs">
              <input
                type="text"
                v-model="link.title"
                placeholder="링크 제목"
                class="link-input title-input"
                :readonly="!modiYn"
              />
              <input
                type="text"
                v-model="link.url"
                placeholder="URL (예: https://example.com)"
                class="link-input url-input"
                :readonly="!modiYn"
              />
            </div>
            <button
              v-if="modiYn"
              @click="removeLink(index)"
              class="delete-button"
            >
              삭제
            </button>
          </div>
          <div v-if="channelLinks.length === 0" class="empty-links-message">
            등록된 링크가 없습니다.
          </div>
        </div>
      </div>

      <!-- 채널 설명 -->
      <div class="channel-description">
        <p v-if="!modiYn">{{ currentChannel.description }}</p>
        <textarea v-else v-model="currentChannel.description"></textarea>
      </div>
    </div>
    <!-- 관리자 탭 메뉴 (이제 고정 네비게이션으로 사용) -->
    <div v-if="store.myInfo?.observer_yn == 1" class="admin-tabs sticky-tabs">
      <button
        class="admin-tab"
        :class="{ active: activeTab === 'ai' }"
        @click="changeTab('ai')"
      >
        <AIIcon /> AI 설정
      </button>
      <!-- <button
        class="admin-tab"
        :class="{ active: activeTab === 'response' }"
        @click="changeTab('response')"
      >
        응답 설정
      </button> -->
      <button
        class="admin-tab"
        :class="{ active: activeTab === 'knowledge' }"
        @click="changeTab('knowledge')"
      >
        <KnowledgeIcon /> 지식 관리
      </button>
      <button
        class="admin-tab"
        :class="{ active: activeTab === 'question' }"
        @click="changeTab('question')"
      >
        <QuestionIcon /> 질문 관리
      </button>
      <button
        class="admin-tab"
        :class="{ active: activeTab === 'response' }"
        @click="changeTab('response')"
      >
        <QuestionIcon /> 답변 관리
      </button>
      <button
        class="admin-tab"
        :class="{ active: activeTab === 'ui' }"
        @click="changeTab('ui')"
      >
        <SettingsIcon /> UI 설정
      </button>
    </div>

    <!-- 모든 설정 섹션이 세로로 배치 -->
    <div v-if="store.myInfo?.observer_yn == 1" class="all-settings-container">
      <!-- AI 설정 패널 -->
      <div
        v-if="activeTab === 'ai'"
        ref="aiSectionRef"
        id="ai-section"
        class="setting-panel"
      >
        <div class="panel-header">
          <h3 class="panel-title"><AIIcon /> AI 기능 설정</h3>
        </div>

        <div class="setting-items">
          <!-- AFUN 설정 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label">
                <DotIcon class="setting-icon" /> AI 자동완성
              </label>
              <span class="setting-description"
                >AFUN(AI-First, User-Next), 사용자 질문 자동완성
              </span>
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="afunEnabled"
                :label="afunEnabled ? '활성화' : '비활성화'"
                color="primary"
              />
            </div>
          </div>

          <!-- AFUN 품질 레벨 (들여쓰기로 계층 표시) -->
          <div class="setting-item sub-setting" v-if="afunEnabled">
            <div class="setting-info">
              <label class="setting-label">AFUN 품질 레벨</label>
              <span class="setting-description"
                >Premium은 유료 기능입니다.</span
              >
            </div>
            <div class="setting-control">
              <select v-model="afunLevel" class="setting-select">
                <option value="normal">보통 (Normal)</option>
                <option value="premium">고품질 (Premium)</option>
              </select>
            </div>
          </div>

          <!-- 다국어 지원 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label">
                <DotIcon class="setting-icon" /> 다국어 지원
              </label>
              <span class="setting-description"
                >다양한 언어로 대화가 가능합니다. (Multi-lang chat)</span
              >
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="multiLangEnabled"
                :label="multiLangEnabled ? '활성화' : '비활성화'"
                color="primary"
              />
            </div>
          </div>

          <!-- 음성 대화 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label">
                <DotIcon class="setting-icon" /> 음성 대화
              </label>
              <span class="setting-description"
                >음성으로 대화를 나눌 수 있습니다. (Voice chat)</span
              >
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="voiceChatEnabled"
                :label="voiceChatEnabled ? '활성화' : '비활성화'"
                color="primary"
              />
            </div>
          </div>

          <!-- 아바타 지원 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label">
                <DotIcon class="setting-icon" /> 아바타 지원
              </label>
              <span class="setting-description"
                >AI 캐릭터 아바타를 사용합니다. (Avatar chat)</span
              >
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="avatarEnabled"
                :label="avatarEnabled ? '활성화' : '비활성화'"
                color="primary"
              />
            </div>
          </div>

          <!-- AFUN(FAQ) 지원 -->
          <div class="setting-item" style="border-bottom: none">
            <div class="setting-info">
              <label class="setting-label">
                <DotIcon class="setting-icon" /> 자주묻는질문
              </label>
              <span class="setting-description"
                >AI 자동완성으로 자주 묻는 질문을 제공합니다.</span
              >
            </div>
            <div class="setting-control">
              <!-- <q-toggle
                v-model="avatarEnabled"
                :label="avatarEnabled ? '활성화' : '비활성화'"
                color="primary"
                :disable="!modiYn"
              /> -->
              <button
                @click="addQuestion"
                class="add-button"
                :disabled="!faqEnabled"
              >
                + 질문 추가
              </button>
            </div>
          </div>
          <div class="faq-items">
            <div
              v-for="(question, index) in faqTexts"
              :key="index"
              class="faq-item"
            >
              <span class="faq-number">{{ index + 1 }}.</span>
              <input
                type="text"
                v-model="faqTexts[index]"
                class="faq-input"
                :placeholder="'자주 묻는 질문 ' + (index + 1)"
              />
              <button @click="saveQuestion(index)" class="save-button">
                저장
              </button>
              <button @click="removePhrase(index)" class="delete-button">
                삭제
              </button>
            </div>
            <div
              v-if="faqTexts.length === 0 && faqEnabled && modiYn"
              class="empty-faq-message"
            >
              질문을 추가해 주세요.
            </div>
            <div
              v-if="faqTexts.length === 0 && (!faqEnabled || !modiYn)"
              class="empty-faq-message"
            >
              등록된 자주 묻는 질문이 없습니다.
            </div>
            <div v-if="!faqEnabled" class="empty-faq-message warning-message">
              FAQ 기능이 비활성화되어 있습니다. 활성화하려면 상단의 토글을
              켜주세요.
            </div>
          </div>
        </div>
      </div>

      <!-- 지식 관리 설정 패널 -->
      <div
        v-if="activeTab === 'knowledge'"
        ref="knowledgeSectionRef"
        id="knowledge-section"
        class="setting-panel"
      >
        <div class="panel-header">
          <h3 class="panel-title"><KnowledgeIcon /> 지식 관리 설정</h3>
        </div>

        <div class="setting-items">
          <!-- KMS 사용 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label">
                <DotIcon class="setting-icon" /> KMS 사용
              </label>
              <span class="setting-description"
                >지식 관리 시스템을 사용합니다.</span
              >
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="kmsEnabled"
                :label="kmsEnabled ? '활성화' : '비활성화'"
                color="primary"
              />
            </div>
          </div>

          <!-- 동적지식 지원 -->
          <div class="setting-item sub-setting" v-if="kmsEnabled">
            <div class="setting-info">
              <label class="setting-label">동적지식 지원</label>
              <span class="setting-description"
                >1순위 동적지식 지원 기능을 사용합니다.
                <!-- <span class="fixed-tooltip">시스템 필수 기능</span> -->
              </span>
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="dynamicKnowledgeEnabled"
                :label="dynamicKnowledgeEnabled ? '활성화' : '비활성화'"
                color="grey-6"
                disable
              />
            </div>
          </div>

          <!-- 집계지식 지원 -->
          <div class="setting-item sub-setting" v-if="kmsEnabled">
            <div class="setting-info">
              <label class="setting-label">집계지식 지원</label>
              <span class="setting-description"
                >1순위 집계지식 지원 기능을 사용합니다.
                <!-- <span class="fixed-tooltip">시스템 필수 기능</span> -->
              </span>
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="aggregateKnowledgeEnabled"
                :label="aggregateKnowledgeEnabled ? '활성화' : '비활성화'"
                color="grey-6"
                disable
              />
            </div>
          </div>

          <!-- 나의지식 지원 -->
          <div class="setting-item sub-setting" v-if="kmsEnabled">
            <div class="setting-info">
              <label class="setting-label">나의지식 지원</label>
              <span class="setting-description"
                >1순위 나의지식 지원 기능을 사용합니다.</span
              >
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="myKnowledgeEnabled"
                :label="myKnowledgeEnabled ? '활성화' : '비활성화'"
                color="primary"
              />
            </div>
          </div>

          <!-- 지식 무효화 지원 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label"
                ><DotIcon class="setting-icon" />지식 무효화</label
              >
              <span class="setting-description"
                >유효하지 않은 지식을 관리합니다.</span
              >
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="invalidationEnabled"
                :label="invalidationEnabled ? '활성화' : '비활성화'"
                color="primary"
              />
            </div>
          </div>

          <!-- 폴더 그룹 선행 검색 지원 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label"
                ><DotIcon class="setting-icon" />폴더그룹 선행 검색</label
              >
              <span class="setting-description">
                <!-- <span class="fixed-tooltip">시스템 필수 기능</span> -->
              </span>
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="folderGroupSearchEnabled"
                :label="folderGroupSearchEnabled ? '활성화' : '비활성화'"
                color="grey-6"
                disable
              />
            </div>
          </div>

          <!-- 폴더명 선행 검색 지원 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label"
                ><DotIcon class="setting-icon" />폴더명 선행 검색</label
              >
              <span class="setting-description">
                <!-- <span class="fixed-tooltip">시스템 필수 기능</span> -->
              </span>
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="folderNameSearchEnabled"
                :label="folderNameSearchEnabled ? '활성화' : '비활성화'"
                color="grey-6"
                disable
              />
            </div>
          </div>
        </div>
      </div>

      <!-- FAQ 관리 패널 -->
      <!-- <div ref="faqSectionRef" id="faq-section" class="setting-panel">
        <div class="panel-header">
          <h3 class="panel-title"><QuestionIcon /> 자주 묻는 질문 관리</h3>
          <div class="faq-toggle-container">
            <span class="toggle-label">FAQ 기능:</span>
            <q-toggle
              v-model="faqEnabled"
              :label="faqEnabled ? '활성화' : '비활성화'"
              color="primary"
              :disable="!modiYn"
            />
          </div>
        </div>

        <div class="faq-section">
          <div class="faq-header">
            <button
              @click="addQuestion"
              class="add-button"
              :disabled="!modiYn || !faqEnabled"
            >
              + 질문 추가
            </button>
          </div>

          <div class="faq-items">
            <div
              v-for="(question, index) in faqTexts"
              :key="index"
              class="faq-item"
            >
              <span class="faq-number">{{ index + 1 }}.</span>
              <input
                type="text"
                v-model="faqTexts[index]"
                :readonly="!modiYn || !faqEnabled"
                class="faq-input"
                :placeholder="'자주 묻는 질문 ' + (index + 1)"
              />
              <button
                @click="removePhrase(index)"
                class="delete-button"
                :disabled="!modiYn || !faqEnabled"
              >
                삭제
              </button>
            </div>
            <div
              v-if="faqTexts.length === 0 && faqEnabled && modiYn"
              class="empty-faq-message"
            >
              질문을 추가해 주세요.
            </div>
            <div
              v-if="faqTexts.length === 0 && (!faqEnabled || !modiYn)"
              class="empty-faq-message"
            >
              등록된 자주 묻는 질문이 없습니다.
            </div>
            <div v-if="!faqEnabled" class="empty-faq-message warning-message">
              FAQ 기능이 비활성화되어 있습니다. 활성화하려면 상단의 토글을
              켜주세요.
            </div>
          </div>
        </div>
      </div> -->

      <!-- UI 설정 패널 -->
      <!-- <div ref="uiSectionRef" id="ui-section" class="setting-panel">
        <div class="panel-header">
          <h3 class="panel-title"><SettingsIcon /> UI 설정</h3>
        </div>

        <div class="setting-items">
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label">UI 설정 샘플</label>
              <span class="setting-description">UI 설정에 대한 설명</span>
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="isActiveListener"
                :label="isActiveListener ? '활성화' : '비활성화'"
                color="primary"
                :disable="!modiYn"
              />
            </div>
          </div>
        </div>
      </div> -->

      <!-- 질문 설정 패널 -->
      <div
        v-if="activeTab === 'question'"
        ref="questionRef"
        id="knowledge-section"
        class="setting-panel"
      >
        <div class="panel-header">
          <h3 class="panel-title"><QuestionIcon /> 질문 관리 설정</h3>
        </div>

        <div class="setting-items">
          <!-- 멀티 하이브리드 검색 지원 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label">
                <DotIcon class="setting-icon" /> 멀티 하이브리드 검색
              </label>
              <span class="setting-description"
                >통합 검색입니다.
                <!-- <span class="fixed-tooltip"> -->
              </span>
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="multiHyBridSearchEnabled"
                :label="multiHyBridSearchEnabled ? '활성화' : '비활성화'"
                color="grey-6"
                disable
              />
            </div>
          </div>

          <!-- 타이틀 검색 지원 -->
          <div class="setting-item sub-setting" v-if="kmsEnabled">
            <div class="setting-info">
              <label class="setting-label">타이틀 검색</label>
              <span class="setting-description">
                <!-- <span class="fixed-tooltip">시스템 필수 기능</span> -->
              </span>
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="titleSearchEnabled"
                :label="titleSearchEnabled ? '활성화' : '비활성화'"
                color="grey-6"
                disable
              />
            </div>
          </div>

          <!-- 요약 검색 지원 -->
          <div class="setting-item sub-setting" v-if="kmsEnabled">
            <div class="setting-info">
              <label class="setting-label">요약 검색</label>
              <span class="setting-description">
                <!-- <span class="fixed-tooltip">시스템 필수 기능</span> -->
              </span>
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="summarySearchEnabled"
                :label="summarySearchEnabled ? '활성화' : '비활성화'"
                color="grey-6"
                disable
              />
            </div>
          </div>

          <!-- 키워드 검색 지원 -->
          <div class="setting-item sub-setting" v-if="kmsEnabled">
            <div class="setting-info">
              <label class="setting-label">키워드 검색</label>
              <span class="setting-description">
                <!-- <span class="fixed-tooltip">시스템 필수 기능</span> -->
              </span>
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="keywordSearchEnabled"
                :label="keywordSearchEnabled ? '활성화' : '비활성화'"
                color="grey-6"
                disable
              />
            </div>
          </div>

          <!-- 멀티턴 대화 지원 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label"
                ><DotIcon class="setting-icon" />멀티턴 대화</label
              >
              <span class="setting-description"
                >이전 대화의 맥락을 유지합니다.</span
              >
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="multiTurnEnabled"
                :label="multiTurnEnabled ? '활성화' : '비활성화'"
                color="primary"
              />
            </div>
          </div>

          <!-- GPT모드 지원 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label"
                ><DotIcon class="setting-icon" />GPT모드</label
              >
              <span class="setting-description"
                >대화 중 AI 모델을 전환합니다.</span
              >
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="switchGptEnabled"
                :label="switchGptEnabled ? '활성화' : '비활성화'"
                color="primary"
              />
            </div>
          </div>

          <!-- 시점 정보 지원 -->

          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label"
                ><DotIcon class="setting-icon" />시점 기반 정보 생성</label
              >
              <span class="setting-description"
                >상대적 시간을 정확한 날짜로 변환합니다</span
              >
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="dateConvertEnabled"
                :label="dateConvertEnabled ? '활성화' : '비활성화'"
                color="primary"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- 답변 설정 패널 -->
      <div
        v-if="activeTab === 'response'"
        ref="responseRef"
        id="knowledge-section"
        class="setting-panel"
      >
        <div class="panel-header">
          <h3 class="panel-title"><KnowledgeIcon /> 답변 관리 설정</h3>
        </div>

        <div class="setting-items">
          <!-- 답변 정확도 설정 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label">
                <DotIcon class="setting-icon" /> 대화의 정확도
              </label>
              <span class="setting-description"
                >AI 답변의 정확도를 설정할 수 있습니다.</span
              >
            </div>
            <div class="setting-control">
              <select v-model="responseScopeLevel" class="setting-select">
                <option value="strict">정확 (Strict)</option>
                <option value="partial">일부 추론 (Partial)</option>
                <option value="free">자유롭게 (Free)</option>
              </select>
            </div>
          </div>

          <!-- 대화량 설정 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label"
                ><DotIcon class="setting-icon" />대화량</label
              >
              <span class="setting-description"
                >챗봇과의 대화량을 설정할 수 있습니다.</span
              >
            </div>
            <div class="setting-control">
              <select v-model="responseLengthLevel" class="setting-select">
                <option value="small">적음 (Small)</option>
                <option value="medium">보통 (Medium)</option>
                <option value="large">많음 (Large)</option>
              </select>
            </div>
          </div>

          <!-- 원본지식 보기 설정 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label"
                ><DotIcon class="setting-icon" />원본지식 보기</label
              >
              <span class="setting-description"
                >원본 지식 소스를 표시합니다.</span
              >
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="viewKnowledgeEnabled"
                :label="viewKnowledgeEnabled ? '활성화' : '비활성화'"
                color="primary"
              />
            </div>
          </div>

          <!-- 지식 이해 흐름 설정 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label"
                ><DotIcon class="setting-icon" />지식 이해 흐름 보기</label
              >
              <span class="setting-description"
                >AI 사고 과정을 확인할 수 있습니다.</span
              >
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="viewDevCoTEnabled"
                :label="viewDevCoTEnabled ? '활성화' : '비활성화'"
                color="primary"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- UI 설정 패널 -->
      <div
        v-if="activeTab === 'ui'"
        ref="uiSectionRef"
        id="knowledge-section"
        class="setting-panel"
      >
        <div class="panel-header">
          <h3 class="panel-title"><SettingsIcon /> UI 설정</h3>
        </div>

        <div class="setting-items">
          <!-- 테이블 지원 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label">
                <DotIcon class="setting-icon" />테이블 지원
              </label>
              <span class="setting-description"
                >표를 시각화하여 답변합니다.
                <!-- <span class="fixed-tooltip">시스템 필수 기능</span> -->
              </span>
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="uiTableEnabled"
                :label="viewDevCoTEnabled ? '활성화' : '비활성화'"
                color="grey-6"
                disable
              />
            </div>
          </div>

          <!-- 캘린더 지원 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label"
                ><DotIcon class="setting-icon" />캘린더 지원</label
              >
              <span class="setting-description"
                >캘린더를 시각화하여 답변합니다.</span
              >
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="uiCalendarEnabled"
                :label="uiCalendarEnabled ? '활성화' : '비활성화'"
                color="primary"
              />
            </div>
          </div>

          <!-- 지도 지원 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label"
                ><DotIcon class="setting-icon" />지도 지원</label
              >
              <span class="setting-description"
                >지도를 시각화하여 답변합니다.</span
              >
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="uiMapEnabled"
                :label="uiMapEnabled ? '활성화' : '비활성화'"
                color="primary"
              />
            </div>
          </div>

          <!-- 지식별 추천 질문 지원 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label"
                ><DotIcon class="setting-icon" />지식별 질문 추천</label
              >
              <span class="setting-description"
                >질문을 추천받을 수 있습니다.</span
              >
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="recommandQuestionEnabled"
                :label="recommandQuestionEnabled ? '활성화' : '비활성화'"
                color="primary"
              />
            </div>
          </div>

          <!-- body images 지원 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label"
                ><DotIcon class="setting-icon" />body images</label
              >
              <span class="setting-description">body images</span>
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="bodyImagesEnabled"
                :label="bodyImagesEnabled ? '활성화' : '비활성화'"
                color="primary"
              />
            </div>
          </div>

          <!-- 링크 지원 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label"
                ><DotIcon class="setting-icon" />링크 지원</label
              >
              <span class="setting-description"
                >지식에 포함되어있는 링크와 함께 답변합니다.</span
              >
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="linksEnabled"
                :label="linksEnabled ? '활성화' : '비활성화'"
                color="primary"
              />
            </div>
          </div>

          <!-- 이미지 파일 지원 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label"
                ><DotIcon class="setting-icon" />이미지 파일 지원</label
              >
              <span class="setting-description"
                >지식에 포함되어있는 이미지와 함께 답변합니다.</span
              >
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="imageFiles"
                :label="imageFiles ? '활성화' : '비활성화'"
                color="primary"
              />
            </div>
          </div>

          <!-- 첨부 파일 지원 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label"
                ><DotIcon class="setting-icon" />첨부 파일 지원</label
              >
              <span class="setting-description"
                >지식에 포함되어있는 첨부 파일과 함께 답변합니다.</span
              >
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="attachFiles"
                :label="attachFiles ? '활성화' : '비활성화'"
                color="primary"
              />
            </div>
          </div>

          <!-- AI 답변 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label">
                <DotIcon class="setting-icon" />AI 답변 톤&스타일
              </label>
              <span class="setting-description"
                >AI 답변시 톤과 스타일을 정합니다.</span
              >
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="toneAndStyle"
                :label="toneAndStyle ? '활성화' : '비활성화'"
                color="primary"
              />
            </div>
          </div>

          <!-- 민감 질문 답변 -->
          <div class="setting-item sub-setting" v-if="kmsEnabled">
            <div class="setting-info">
              <label class="setting-label">민감 질문 답변</label>
              <span class="setting-description"
                >민감한 질문에 대한 AI 응답을 설정합니다.</span
              >
            </div>
            <div class="setting-control" style="width: 100%">
              <div class="textarea-with-button">
                <textarea
                  v-model="sensitiveQuestionResponse"
                  class="response-textarea"
                  placeholder="민감한 질문에 대한 답변을 입력하세요."
                ></textarea>
                <button
                  class="save-response-button"
                  @click="saveSensitiveResponse"
                >
                  저장
                </button>
              </div>
            </div>
          </div>

          <!-- 모르는 질문 답변 -->
          <div class="setting-item sub-setting" v-if="kmsEnabled">
            <div class="setting-info">
              <label class="setting-label">모르는 질문 답변</label>
              <span class="setting-description"
                >AI가 답변할 수 없는 질문에 대한 응답을 설정합니다.</span
              >
            </div>
            <div class="setting-control" style="width: 100%">
              <div class="textarea-with-button">
                <textarea
                  v-model="unknownQuestionResponse"
                  class="response-textarea"
                  placeholder="알 수 없는 질문에 대한 답변을 입력하세요."
                ></textarea>
                <button
                  class="save-response-button"
                  @click="saveUnknownResponse"
                >
                  저장
                </button>
              </div>
            </div>
          </div>

          <!-- response tone 설정 -->
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label">
                <DotIcon class="setting-icon" />말투 설정
              </label>
              <span class="setting-description"
                >답변의 말투를 설정할 수 있습니다.</span
              >
            </div>
            <div class="setting-control">
              <select v-model="reponseToneLevel" class="setting-select">
                <option value="formal">사무적으로</option>
                <option value="friendly">친절하게</option>
                <option value="veryfriendly">매우 친절하게</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <!-- 채널 비밀번호 설정 -->
      <div class="setting-panel">
        <div class="panel-header">
          <h3 class="panel-title"><LockIcon /> 채널 비밀번호 설정</h3>
        </div>

        <div class="setting-items">
          <div class="setting-item">
            <div class="setting-info">
              <label class="setting-label">
                <LockIcon class="setting-icon" /> 채널 보안
              </label>
              <span class="setting-description">
                채널에 비밀번호를 설정합니다.
              </span>
            </div>
            <div class="setting-control">
              <q-toggle
                v-model="secureToggle"
                :label="secureToggle ? '활성화' : '비활성화'"
                color="primary"
              />
            </div>
          </div>

          <!-- 비밀번호 입력 필드 (서브 설정 스타일로) -->
          <div class="setting-item sub-setting" v-if="secureToggle">
            <div class="setting-info">
              <label class="setting-label">비밀번호</label>
              <span class="setting-description">
                4자리 숫자로 설정하세요.
              </span>
            </div>
            <div class="setting-control">
              <input
                type="text"
                v-model="channelSecureNum"
                placeholder="비밀번호 4자리"
                class="setting-input"
                maxlength="4"
                @input="handleInput"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 편집/저장 버튼 -->
      <!-- <div class="action-buttons">
        <button class="edit-button" @click="changeToModi">
          {{ modiYn ? "저장" : "편집" }}
        </button>
        <button v-if="modiYn" class="cancel-button" @click="changeToDetail">
          취소
        </button>
      </div> -->
      <!-- <div class="password-section">
        <div class="password-header">
          <LockIcon /> <span class="password-title">채널 비밀번호 설정</span>
        </div>

        <div class="password-controls" v-if="modiYn">
          <q-toggle
            v-model="secureToggle"
            :label="secureToggle ? '활성화' : '비활성화'"
            :color="toggleSecureColor"
            class="secure-toggle"
            :disable="!modiYn"
          />

          <input
            v-if="currentChannel.secure_yn"
            type="text"
            id="toneInput"
            v-model="channelSecureNum"
            :placeholder="currentChannel.secure_str4"
            class="secureInput"
            maxlength="4"
            @input="handleInput"
          />
        </div>
      </div> -->
    </div>
  </div>
</template>

<style scoped>
.anytalk-admin-container {
  /* justify-self: flex-start; */
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  margin-top: 20px !important;
  /* margin: 0 auto; */
  padding: 20px;
  border: 1px solid #c7c7c7;
  background-color: white;
  border-radius: 10px;
  font-family: "Pretendard", "Malgun Gothic", sans-serif;
  /* overflow-y: auto; */
  height: 100%; /* 변경: max-height → height */
  position: relative;
  box-shadow: 0 3px 4px 2px rgba(148, 148, 148, 0.3);
}

/* 탭 메뉴 스타일 */
.sticky-tabs {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
}

.tab-container {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.tab {
  padding: 12px 20px;
  background-color: #ddd;
  border: none;
  font-size: 14px;
  cursor: pointer;
}

.tab.active {
  background-color: #575757;
  color: white;
}

/* 프로필 섹션 스타일 */
.profile-section {
  display: flex;
  align-items: flex-start;
  /* margin-bottom: 30px; */
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.profile-image {
  width: 70px;
  height: 70px;
  position: relative;
  margin-right: 20px;
  margin-bottom: 15px;
}

.profile-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.add-img-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 24px;
  height: 24px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}

.profile-info {
  flex: 1;
  min-width: 200px;
}

.profile-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.title-content {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.profile-name {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.profile-name-input {
  font-size: 18px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
  max-width: 100%;
}

.auth-tag {
  background-color: #f2f2f2;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  color: #666;
}

.profile-details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.category-info {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.date-info {
  font-size: 14px;
  color: #666;
  margin-left: 10px;
}

/* 배너 섹션 스타일 */
.banner-section {
  /* margin-bottom: 10px; */
  background-color: white;
  border-radius: 8px;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 5px 20px 20px 20px; */
}

.banner-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.banner-title {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.banner-container {
  position: relative;
  width: 100%;
  max-width: 650px;
  height: 140px;
  margin-bottom: 30px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e6e6e6;
}

/* 편집 모드일 때 커서와 시각적 효과 추가 */
.banner-container.editable {
  cursor: pointer;
  transition: background-color 0.3s;
}

.banner-container.editable:hover {
  background-color: #f5f5f5;
}

.banner-container.editable:hover .empty-banner-message {
  color: #333;
}

/* 클릭 방지를 위한 이벤트 전파 중지 처리 */
.banner-btn {
  z-index: 10;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}

.empty-banner-message {
  text-align: center;
  color: #888;
  font-style: italic;
}

.banner-controls {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.banner-btn {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.banner-btn.add {
  background-color: #4a4a4a;
}

.banner-btn.delete {
  background-color: #777;
}

/* 추가 버튼 (이미 있는 스타일과 동일) */
.add-button {
  font-size: 14px;
  background-color: #4a4a4a;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  color: white;
  transition: background-color 0.3s;
}

/* 링크 관리 섹션 스타일 */
.links-section {
  margin-bottom: 30px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 5px 20px 10px 20px;
}

.links-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.links-title {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.links-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.link-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.link-inputs {
  flex: 1;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.link-input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.title-input {
  width: 30%;
  min-width: 150px;
}

.url-input {
  flex: 1;
  min-width: 200px;
}

.empty-links-message {
  text-align: center;
  padding: 20px;
  color: #888;
  font-style: italic;
}

@media (max-width: 768px) {
  .link-inputs {
    flex-direction: column;
    width: 100%;
  }

  .title-input,
  .url-input {
    width: 100%;
  }
}
/* 채널 설명 스타일 */
.channel-description {
  margin-bottom: 30px;
  line-height: 1.6;
}

.channel-description textarea {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  line-height: 1.6;
}

/* 관리자 탭 스타일 */
.admin-tabs {
  display: flex;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: relative;
  top: 0;
  left: 0;
  border: 0.3px solid #c7c7c7;
  z-index: 10;
  width: 100%;
  justify-content: space-between;
}

.admin-tab {
  flex: 1;
  padding: 8px 5px;
  border: none;
  background-color: transparent;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #222;
  border-radius: 8px;
  text-align: center;
}

.admin-tab.active {
  color: #fff;
  font-weight: 600;
  background-color: rgb(84, 84, 84);
}

.all-settings-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

/* 설정 패널 스타일 */
.setting-panel {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: visible;
  scroll-margin-top: 70px; /* 스크롤 시 상단 여백 */
}

.panel-header {
  padding: 10px 20px;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: #f7f7f7;
  border-radius: 8px 8px 0 0;
}

.panel-title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #333;
}

.setting-items {
  padding: 0 20px;
}

.setting-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px 8px 20px; /* Reduced vertical padding */
  flex-wrap: wrap;
}

.sub-setting {
  padding-left: 48px;
  background-color: #f9f9f9;
}

.setting-info {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  margin-right: 10px;
  margin-bottom: 10px;
}

.setting-label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  white-space: normal;
}

.setting-icon {
  /* color: #4a6cf7; */
  color: #666;
  flex-shrink: 0;
}

.setting-description {
  font-size: 12px;
  color: #666;
  /* max-width: 220px; */
}

.setting-control {
  min-width: 120px;
}

.setting-select {
  width: 120px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: white;
  font-size: 14px;
}

/* FAQ 섹션 스타일 */
.faq-section {
  margin-bottom: 15px;
}

.faq-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 20px;
  flex-wrap: wrap;
  gap: 10px;
}

.section-title {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.add-button {
  font-size: 14px !important;
  margin-left: 10px;
  background-color: #4a4a4a;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  color: white;
  transition: background-color 0.3s;
}

/* .add-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
} */

.faq-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 20px 20px;
}

.faq-item {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.faq-number {
  color: #666;
  font-size: 14px;
  width: 20px;
  flex-shrink: 0;
}

.faq-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-width: 150px;
}

.faq-input[readonly] {
  background-color: #f9f9f9;
}

.delete-button {
  background-color: #f2f2f2;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  margin-top: 5px;
}

.delete-button:hover {
  background-color: #e0e0e0;
}

.delete-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* 비밀번호 섹션 스타일 */
.password-section {
  margin-bottom: 30px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.password-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.password-title {
  font-size: 14px;
  font-weight: 600;
}

.password-controls {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.secure-input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 150px;
  max-width: 100%;
}

/* 액션 버튼 스타일 */
.action-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.edit-button {
  background-color: #4a4a4a;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cancel-button {
  background-color: transparent;
  border: 1px solid #ddd;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #f2f2f2;
}

/* FAQ 버튼 스타일 */
.faq-button {
  background-color: #03ceae;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s;
}

.faq-button:hover {
  background-color: #02b99b;
}

.faq-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.faq-toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.toggle-label {
  font-size: 14px;
  color: #666;
}

.fixed-enabled-toggle {
  opacity: 0.7;
  pointer-events: none;
}

.fixed-enabled-toggle .q-toggle__inner {
  cursor: not-allowed !important;
}

.fixed-enabled-label {
  color: #888;
  font-style: italic;
  font-size: 12px;
}

.fixed-tooltip {
  /* background-color: #f8f8f8; */
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 8px;
  margin-left: 5px;
  font-size: 11px;
  color: #666;
}

.empty-faq-message {
  text-align: center;
  padding: 20px;
  color: #888;
  font-style: italic;
}

.warning-message {
  color: #ff6b6b;
  font-weight: 500;
}

.response-textarea {
  width: 80%;
  min-height: 80px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  resize: vertical;
  font-size: 14px;
  margin-top: 5px;
  font-family: inherit;
}

.readonly-textarea {
  background-color: #f9f9f9;
  cursor: not-allowed;
}

.textarea-with-button {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.save-response-button {
  position: absolute;
  margin-top: 25px;
  margin-right: 30px;
  background-color: #4a4a4a;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 12px;
  font-size: 12px;
  cursor: pointer;
  align-self: flex-end;
}

/* 모바일 반응형 스타일 */
@media (max-width: 768px) {
  .anytalk-admin-container {
    padding: 15px;
    max-width: 100%;
    height: auto;
    min-height: 100vh;
  }

  .profile-section {
    flex-direction: column;
  }

  .profile-image {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .title-content {
    margin-bottom: 10px;
  }

  .profile-details {
    flex-direction: column;
    gap: 10px;
  }

  .admin-tabs {
    overflow-x: auto;
  }

  .admin-tab {
    padding: 8px 5px;
    font-size: 14px;
    white-space: nowrap;
  }

  .setting-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .setting-info {
    margin-bottom: 10px;
    width: 100%;
  }

  .setting-control {
    width: 100%;
  }

  .faq-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .faq-input {
    width: 100%;
  }

  .delete-button {
    align-self: flex-end;
  }

  .password-controls {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .action-buttons {
    flex-direction: column;
    width: 100%;
  }

  .edit-button,
  .cancel-button {
    width: 100%;
  }

  .panel-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .faq-toggle-container {
    width: 100%;
    justify-content: space-between;
  }
}

/* 작은 모바일 화면 */
@media (max-width: 480px) {
  .anytalk-admin-container {
    padding: 10px;
    height: auto;
    overflow-y: visible;
    min-height: 100vh;
  }

  /* 특정 뷰포트 크기에 맞춘 조정 */
  @media (width: 390px) and (height: 844px) {
    .anytalk-admin-container {
      overflow-y: auto;
      max-height: 844px;
    }
  }

  body,
  html {
    height: 100%;
    overflow: auto;
  }

  .admin-tab {
    font-size: 12px;
    padding: 8px 3px;
  }

  .panel-title {
    font-size: 14px;
  }

  .setting-label {
    font-size: 14px;
  }

  .setting-description {
    font-size: 12px;
  }

  .faq-section .section-title,
  .password-section .password-title {
    font-size: 14px;
  }

  .faq-number {
    font-size: 12px;
  }

  .add-button,
  .delete-button {
    font-size: 11px;
    padding: 6px 10px;
  }

  .faq-button {
    padding: 6px 12px;
    font-size: 12px;
  }
}
</style>
