// export function elapsedTime(date: number | string) {
//   const start = new Date(date);
//   const end = new Date();

//   const seconds = Math.floor((end.getTime() - start.getTime()) / 1000);
//   if (seconds < 60) return "방금 전";

//   const minutes = seconds / 60;
//   if (minutes < 60) return `${Math.floor(minutes)}분 전`;

//   const hours = minutes / 60;
//   if (hours < 24) return `${Math.floor(hours)}시간 전`;

//   const days = hours / 24;
//   if (days < 7) return `${Math.floor(days)}일 전`;

//   return `${start.toLocaleDateString()}`;
// }

export function elapsedTime(date?: number | string) {
  // console.log("Input date:", date); // 디버그용 로그 추가
  const start = new Date(date === undefined ? createDate() : date);
  const end = new Date();
  // console.log("Parsed start date:", start); // 디버그용 로그 추가
  // console.log("Current end date:", end); // 디버그용 로그 추가

  const seconds = Math.floor((end.getTime() - start.getTime()) / 1000);
  if (seconds < 60) return "방금 전";

  const minutes = seconds / 60;
  if (minutes < 60) return `${Math.floor(minutes)}분 전`;

  const hours = minutes / 60;
  if (hours < 24) return `${Math.floor(hours)}시간 전`;

  // 24시간 이상 지난 경우 월/일 시:분 형식으로 반환
  return `${start.getMonth() + 1}/${start.getDate()} ${start.getHours()}:${start.getMinutes().toString().padStart(2, "0")}`;
}

export function elapsedYear(date: number | string) {
  const start = new Date(date);

  return `${start.getFullYear()}/${(start.getMonth() + 1).toString().padStart(2, "0")}/${start.getDate().toString().padStart(2, "0")}`;
}

export function createDate() {
  return new Date().toISOString();
}

export function elapsedDateAndTime(date?: number | string) {
  // console.log("Input date:", date); // 디버그용 로그 추가
  const start = new Date(date === undefined ? createDate() : date);
  const end = new Date();
  // console.log("Parsed start date:", start); // 디버그용 로그 추가
  // console.log("Current end date:", end); // 디버그용 로그 추가

  const seconds = Math.floor((end.getTime() - start.getTime()) / 1000);
  if (seconds < 60) return "방금 전";

  const minutes = seconds / 60;
  if (minutes < 60) return `${Math.floor(minutes)}분 전`;

  const hours = minutes / 60;
  if (hours < 24) return `${Math.floor(hours)}시간 전`;

  // 24시간 이상 지난 경우 월/일 시:분 형식으로 반환
  return `${start.getMonth() + 1}월 ${start.getDate()}일 ${start.getHours()}:${start.getMinutes().toString().padStart(2, "0")}`;
}
