// atKmsMockData.ts

import { ref } from "vue";

// 인터페이스 정의
export interface KmsFolderGroup {
  kms_folder_group_key: number;
  folder_group_name: string;
  channel_key: number;
  cre_date: string;
  cre_user_key: number;
  delete_yn: number;
}

export interface KmsFolder {
  kms_folder_key: number;
  kms_folder_group_key: number;
  parent_folder_key: number | null;
  folder_name: string;
  cre_date: string;
  cre_user_key: number;
  upd_date: string;
  upd_user_key: number;
  delete_yn: number;
  children: number[];
}

export interface KmsDoc {
  kms_doc_key: number;
  kms_folder_key: number;
  doc_name: string;
  doc_type: string;
  file_key: number;
  cre_date: string;
  cre_user_key: number;
  upd_date: string;
  upd_user_key: number;
  delete_yn: number;
}

export interface KmsKnow {
  kms_know_key: number;
  kms_doc_key: number;
  channel_key: number;
  title: string;
  summary: string;
  body: string;
  must_text: string;
  keyword_list: string[];
  img_filekey_list: number[];
  attach_filekey_list: number[];
  linkkey_list: number[];
  cre_date: string;
  cre_user_key: number;
  confirm_yn: number;
  delete_yn: number;
}

// 사용자 인터페이스 정의
export interface User {
  user_key: number;
  user_id: string;
  user_name: string;
  user_type: string; // ADMIN(관리자), STAFF(교직원), STUDENT(학생)
  department: string;
  position: string | null;
  email: string;
  phone: string;
  cre_date: string;
  delete_yn: number;
}

// 폴더 그룹 데이터
export const kmsFolderGroups = ref<KmsFolderGroup[]>([
  {
    kms_folder_group_key: 1,
    folder_group_name: "학사 규정",
    channel_key: 1001,
    cre_date: "2024-02-01 09:00:00",
    cre_user_key: 1001,
    delete_yn: 0
  },
  {
    kms_folder_group_key: 2,
    folder_group_name: "학교 생활",
    channel_key: 1002,
    cre_date: "2024-02-01 09:00:00",
    cre_user_key: 1001,
    delete_yn: 0
  }
]);

// 폴더 데이터
export const kmsFolderTrees = ref<KmsFolder[]>([
  {
    kms_folder_key: 101,
    kms_folder_group_key: 1,
    parent_folder_key: null, // 부모 폴더
    folder_name: "장학금 관리",
    cre_date: "2024-02-01 09:00:00",
    cre_user_key: 1001,
    upd_date: "2024-02-01 09:00:00",
    upd_user_key: 1001,
    delete_yn: 0,
    children: [1011, 1012] // 자식 폴더들의 key
  },
  {
    kms_folder_key: 1011,
    kms_folder_group_key: 1,
    parent_folder_key: 101, // 자식 폴더
    folder_name: "교내 장학금",
    cre_date: "2024-02-01 09:00:00",
    cre_user_key: 1001,
    upd_date: "2024-02-01 09:00:00",
    upd_user_key: 1001,
    delete_yn: 0,
    children: [] // 자식 폴더는 children이 null
  },
  {
    kms_folder_key: 1012,
    kms_folder_group_key: 1,
    parent_folder_key: 101, // 자식 폴더
    folder_name: "국가 장학금",
    cre_date: "2024-02-01 09:00:00",
    cre_user_key: 1001,
    upd_date: "2024-02-01 09:00:00",
    upd_user_key: 1001,
    delete_yn: 0,
    children: [] // 자식 폴더는 children이 null
  },
  {
    kms_folder_key: 102,
    kms_folder_group_key: 1,
    parent_folder_key: null, // 부모 폴더
    folder_name: "학점 관리",
    cre_date: "2024-02-01 09:00:00",
    cre_user_key: 1001,
    upd_date: "2024-02-01 09:00:00",
    upd_user_key: 1001,
    delete_yn: 0,
    children: [] // 하위 폴더 없음
  },

  // 학교 생활(2) 그룹의 폴더들
  {
    kms_folder_key: 201,
    kms_folder_group_key: 2,
    parent_folder_key: null, // 부모 폴더
    folder_name: "기숙사",
    cre_date: "2024-02-01 09:00:00",
    cre_user_key: 1001,
    upd_date: "2024-02-01 09:00:00",
    upd_user_key: 1001,
    delete_yn: 0,
    children: [2011] // 자식 폴더의 key
  },
  {
    kms_folder_key: 2011,
    kms_folder_group_key: 2,
    parent_folder_key: 201, // 자식 폴더
    folder_name: "입사 신청",
    cre_date: "2024-02-01 09:00:00",
    cre_user_key: 1001,
    upd_date: "2024-02-01 09:00:00",
    upd_user_key: 1001,
    delete_yn: 0,
    children: [] // 자식 폴더는 children이 null
  }
]);

// 파일 데이터
export const kmsDocs = ref<KmsDoc[]>([
  {
    kms_doc_key: 10111,
    kms_folder_key: 1011,
    doc_name: "성적 장학금 지급 규정",
    doc_type: "PDF",
    file_key: 1,
    cre_date: "2024-02-01 09:00:00",
    cre_user_key: 1001,
    upd_date: "2024-02-01 09:00:00",
    upd_user_key: 1001,
    delete_yn: 0
  },
  {
    kms_doc_key: 10112,
    kms_folder_key: 1011,
    doc_name: "근로 장학금 신청 안내",
    doc_type: "DOCX",
    file_key: 2,
    cre_date: "2024-02-01 09:00:00",
    cre_user_key: 1001,
    upd_date: "2024-02-01 09:00:00",
    upd_user_key: 1001,
    delete_yn: 0
  },
  {
    kms_doc_key: 10121,
    kms_folder_key: 1012,
    doc_name: "국가장학금 신청 가이드",
    doc_type: "PDF",
    file_key: 2,
    cre_date: "2024-02-01 09:00:00",
    cre_user_key: 1001,
    upd_date: "2024-02-01 09:00:00",
    upd_user_key: 1001,
    delete_yn: 0
  },
  {
    kms_doc_key: 10122,
    kms_folder_key: 1012,
    doc_name: "국가장학금 FAQ",
    doc_type: "PDF",
    file_key: 4,
    cre_date: "2024-02-01 09:00:00",
    cre_user_key: 1001,
    upd_date: "2024-02-01 09:00:00",
    upd_user_key: 1001,
    delete_yn: 0
  },
  {
    kms_doc_key: 20111,
    kms_folder_key: 2011,
    doc_name: "기숙사 입사 신청서",
    doc_type: "DOCX",
    file_key: 3,
    cre_date: "2024-02-01 09:00:00",
    cre_user_key: 1001,
    upd_date: "2024-02-01 09:00:00",
    upd_user_key: 1001,
    delete_yn: 0
  }
]);

// 지식 데이터
export const kmsKnows = ref<KmsKnow[]>([
  {
    kms_know_key: 101111,
    kms_doc_key: 6007,
    channel_key: 225,
    title: "성적 장학금 신청 자격 및 지급 기준",
    summary: "성적 장학금 신청을 위한 자격 요건과 장학금 지급 기준 안내",
    body: "성적 장학금은 직전 학기 15학점 이상 이수하고 평점 3.5 이상인 학생에게 지급됩니다.",
    must_text: "신청 기간을 반드시 확인하세요.",
    keyword_list: ["장학금", "성적장학금", "학자금"],
    img_filekey_list: [1, 2],
    attach_filekey_list: [101],
    linkkey_list: [1001],
    cre_date: "2024-02-01 09:00:00",
    cre_user_key: 1001,
    confirm_yn: 1,
    delete_yn: 0
  },
  {
    kms_know_key: 101112,
    kms_doc_key: 6007,
    channel_key: 225,
    title: "성적 장학금 계속지원 조건 안내",
    summary: "성적 장학금 지원 자격 유지를 위한 조건과 지침",
    body: "성적 장학금 계속 지원을 위해서는 매 학기 12학점 이상 이수하고 평점 3.5 이상을 유지해야 하며, 채플 등 필수 교과목을 이수해야 합니다.",
    must_text: "필수 교과목 미이수 시 장학금 지원이 중단됩니다.",
    keyword_list: ["장학금", "성적장학금", "계속지원"],
    img_filekey_list: [7, 8],
    attach_filekey_list: [105],
    linkkey_list: [1006],
    cre_date: "2024-02-01 09:00:00",
    cre_user_key: 1001,
    confirm_yn: 1,
    delete_yn: 0
  },
  {
    kms_know_key: 101121,
    kms_doc_key: 6007,
    channel_key: 225,
    title: "근로 장학금 신청 방법 및 근무 지침",
    summary: "교내 근로 장학생 신청 절차와 근무 시 유의사항",
    body: "근로 장학생은 월 최대 25시간까지 근무 가능하며, 시급은 9,860원입니다.",
    must_text: "근무 시작 전 반드시 안전교육을 이수해야 합니다.",
    keyword_list: ["근로장학금", "교내근로", "장학금"],
    img_filekey_list: [3],
    attach_filekey_list: [102],
    linkkey_list: [1002],
    cre_date: "2024-02-01 09:00:00",
    cre_user_key: 1001,
    confirm_yn: 0,
    delete_yn: 0
  },
  {
    kms_know_key: 101122,
    kms_doc_key: 6007,
    channel_key: 225,
    title: "근로 장학금 학과별 근무지 정보",
    summary: "교내 근로 장학생 근무 가능 부서 및 업무 안내",
    body: "각 학과 사무실, 도서관, 학생지원센터 등에서 근무 가능하며, 전공 관련 업무를 우선 배정받을 수 있습니다.",
    must_text: "근무지 선택 시 전공 관련 우선순위가 적용됩니다.",
    keyword_list: ["근로장학금", "근무지", "학과사무실"],
    img_filekey_list: [9],
    attach_filekey_list: [106, 107],
    linkkey_list: [1007],
    cre_date: "2024-02-01 09:00:00",
    cre_user_key: 1001,
    confirm_yn: 0,
    delete_yn: 0
  },
  {
    kms_know_key: 101211,
    kms_doc_key: 6007,
    channel_key: 225,
    title: "국가장학금 1유형 신청 안내",
    summary: "소득연계형 국가장학금 신청 방법 및 지원 자격",
    body: "국가장학금 1유형은 소득 분위에 따라 등록금을 차등 지원하는 제도입니다.",
    must_text: "가구원 동의를 반드시 완료해야 합니다.",
    keyword_list: ["국가장학금", "1유형", "소득연계"],
    img_filekey_list: [4],
    attach_filekey_list: [103],
    linkkey_list: [1003],
    cre_date: "2024-02-01 09:00:00",
    cre_user_key: 1001,
    confirm_yn: 1,
    delete_yn: 0
  },
  {
    kms_know_key: 101212,
    kms_doc_key: 6007,
    channel_key: 225,
    title: "국가장학금 2유형 지원 기준",
    summary: "대학연계 지원형 국가장학금 신청 자격 및 선발 기준",
    body: "국가장학금 2유형은 대학 자체 기준에 따라 지원되며, 소득분위와 성적 요건을 고려하여 차등 지급됩니다.",
    must_text: "대학 자체 지원 기준을 반드시 확인하세요.",
    keyword_list: ["국가장학금", "2유형", "대학연계"],
    img_filekey_list: [10],
    attach_filekey_list: [108],
    linkkey_list: [1008],
    cre_date: "2024-02-01 09:00:00",
    cre_user_key: 1001,
    confirm_yn: 0,
    delete_yn: 0
  },
  {
    kms_know_key: 101221,
    kms_doc_key: 10122,
    channel_key: 1001,
    title: "자주 묻는 국가장학금 질문",
    summary: "국가장학금 신청 및 수혜 관련 주요 FAQ",
    body: "학자금 지원구간 산정 방법, 다자녀 국가장학금 기준 등 주요 문의사항을 정리했습니다.",
    must_text: "학자금 지원구간 통지서를 확인하세요.",
    keyword_list: ["국가장학금", "FAQ", "학자금지원구간"],
    img_filekey_list: [5, 6],
    attach_filekey_list: [104],
    linkkey_list: [1004, 1005],
    cre_date: "2024-02-01 09:00:00",
    cre_user_key: 1001,
    confirm_yn: 1,
    delete_yn: 0
  },
  {
    kms_know_key: 101222,
    kms_doc_key: 10122,
    channel_key: 1001,
    title: "국가장학금 신청 시 유의사항",
    summary: "장학금 신청 과정에서 자주 발생하는 오류 및 주의점",
    body: "서류 제출 기한, 가구원 정보 제출, 학자금 지원구간 산정 소요 기간 등 신청 과정에서 주의해야 할 사항들을 안내합니다.",
    must_text: "모든 서류는 신청 기간 내 제출 완료해야 합니다.",
    keyword_list: ["국가장학금", "신청오류", "주의사항"],
    img_filekey_list: [11, 12],
    attach_filekey_list: [109, 110],
    linkkey_list: [1009, 1010],
    cre_date: "2024-02-01 09:00:00",
    cre_user_key: 1001,
    confirm_yn: 1,
    delete_yn: 0
  },
  {
    kms_know_key: 201111,
    kms_doc_key: 20111,
    channel_key: 1002,
    title: "2024년도 1학기 기숙사 입사 신청 안내",
    summary: "기숙사 입사 신청 절차 및 선발 기준 안내",
    body: "2024년도 1학기 기숙사 입사 신청이 시작되었습니다. 선발은 거리 점수(50%)와 성적(50%)을 반영하여 진행됩니다.",
    must_text: "결핵검진 결과서를 반드시 제출해야 합니다.",
    keyword_list: ["기숙사", "입사신청", "생활관"],
    img_filekey_list: [3],
    attach_filekey_list: [201],
    linkkey_list: [2001],
    cre_date: "2024-02-01 09:00:00",
    cre_user_key: 1001,
    confirm_yn: 1,
    delete_yn: 0
  }
]);

// 사용자 데이터
export const users = ref<User[]>([
  {
    user_key: 1001,
    user_id: "admin.kim",
    user_name: "김관리",
    user_type: "ADMIN",
    department: "학생지원처",
    position: "과장",
    email: "admin.kim@university.ac.kr",
    phone: "02-1234-5671",
    cre_date: "2024-01-01 09:00:00",
    delete_yn: 0
  },
  {
    user_key: 1002,
    user_id: "staff.lee",
    user_name: "이교직",
    user_type: "STAFF",
    department: "장학팀",
    position: "대리",
    email: "staff.lee@university.ac.kr",
    phone: "02-1234-5672",
    cre_date: "2024-01-01 09:00:00",
    delete_yn: 0
  },
  {
    user_key: 1003,
    user_id: "staff.park",
    user_name: "박직원",
    user_type: "STAFF",
    department: "기숙사행정실",
    position: "주임",
    email: "staff.park@university.ac.kr",
    phone: "02-1234-5673",
    cre_date: "2024-01-01 09:00:00",
    delete_yn: 0
  },
  {
    user_key: 1004,
    user_id: "staff.jung",
    user_name: "정직원",
    user_type: "STAFF",
    department: "교무처",
    position: "계장",
    email: "staff.jung@university.ac.kr",
    phone: "02-1234-5674",
    cre_date: "2024-01-01 09:00:00",
    delete_yn: 0
  },
  {
    user_key: 1005,
    user_id: "staff.choi",
    user_name: "최담당",
    user_type: "STAFF",
    department: "입학관리과",
    position: "사원",
    email: "staff.choi@university.ac.kr",
    phone: "02-1234-5675",
    cre_date: "2024-01-01 09:00:00",
    delete_yn: 0
  }
]);
