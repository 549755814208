<template>
  <div>
    <div>JSP 테스트 페이지</div>
    <!-- <iframe
      src="/path-to-your-jsp/test.jsp"
      width="100%"
      height="600px"
    ></iframe> -->
  </div>
</template>

<script setup lang="ts"></script>
