<script setup lang="ts">
import { ref } from "vue";

interface CompareData {
  title: {
    original: string;
    generated: string;
  };
  summary: {
    original: string;
    generated: string;
  };
  keywords: {
    original: string[];
    generated: string[];
  };
  quests: {
    original: string[];
    generated: string[];
  };
}

const props = defineProps<{
  show: boolean;
  compareData: CompareData;
}>();

const emit = defineEmits(["close", "apply"]);

// 각 항목별 선택 상태 (true면 AI 생성값, false면 기존값)
const selectedItems = ref({
  title: false,
  summary: false,
  keywords: false,
  quests: false
});

// 선택 적용
const applySelection = () => {
  // const result = {
  //   title: selectedItems.value.title
  //     ? props.compareData.title.generated
  //     : props.compareData.title.original,
  //   summary: selectedItems.value.summary
  //     ? props.compareData.summary.generated
  //     : props.compareData.summary.original,
  //   keywords: selectedItems.value.keywords
  //     ? props.compareData.keywords.generated
  //     : props.compareData.keywords.original
  // };

  // 선택된 항목만 전달하여 부분 업데이트 가능하도록 함
  const result = {
    // 선택된 항목만 포함
    ...(selectedItems.value.title && { title: "generated" }),
    ...(selectedItems.value.summary && { summary: "generated" }),
    ...(selectedItems.value.keywords && { keywords: "generated" }),
    ...(selectedItems.value.quests && { quests: "generated" })
  };

  // 아무것도 선택되지 않았다면 모든 항목을 original로 설정
  if (Object.keys(result).length === 0) {
    result.title = "original";
    result.summary = "original";
    result.keywords = "original";
    result.quests = "original";
  }

  emit("apply", result);
  emit("close");
};
</script>

<template>
  <div v-if="show" class="modal-overlay" @click="$emit('close')"></div>
  <div v-if="show" class="compare-popup" @click.stop>
    <div class="popup-header">
      <span>AI 생성 결과 비교</span>
      <button class="close-button" @click="$emit('close')">×</button>
    </div>

    <!-- 전체 내용 타이틀 -->
    <div class="content-titles">
      <div class="title-item">
        <h5>기존 내용</h5>
      </div>
      <div class="title-item">
        <h5>AI 생성 내용</h5>
      </div>
    </div>

    <div class="compare-content">
      <!-- 제목 비교 -->
      <div class="compare-section">
        <div class="compare-items">
          <div class="compare-item-title">
            <h6>제목</h6>
          </div>
          <div class="compare-item-title">
            <h6>제목</h6>
          </div>
        </div>
        <div class="compare-items">
          <div
            class="compare-item"
            :class="{ selected: !selectedItems.title }"
            @click="selectedItems.title = false"
          >
            <!-- <h6>기존</h6> -->
            <p>{{ compareData.title.original }}</p>
          </div>
          <div
            class="compare-item"
            :class="{ selected: selectedItems.title }"
            @click="selectedItems.title = true"
          >
            <!-- <h6>AI 생성</h6> -->
            <p>{{ compareData.title.generated }}</p>
          </div>
        </div>
      </div>

      <!-- 요약 비교 -->
      <div class="compare-section">
        <div class="compare-items">
          <div class="compare-item-title">
            <h6>요약</h6>
          </div>
          <div class="compare-item-title">
            <h6>요약</h6>
          </div>
        </div>

        <div class="compare-items">
          <div
            class="compare-item"
            :class="{ selected: !selectedItems.summary }"
            @click="selectedItems.summary = false"
          >
            <!-- <h6>기존</h6> -->
            <p>{{ compareData.summary.original }}</p>
          </div>
          <div
            class="compare-item"
            :class="{ selected: selectedItems.summary }"
            @click="selectedItems.summary = true"
          >
            <!-- <h6>AI 생성</h6> -->
            <p>{{ compareData.summary.generated }}</p>
          </div>
        </div>
      </div>

      <!-- 키워드 비교 -->
      <div class="compare-section">
        <div class="compare-items">
          <div class="compare-item-title">
            <h6>키워드</h6>
          </div>
          <div class="compare-item-title">
            <h6>키워드</h6>
          </div>
        </div>
        <div class="compare-items">
          <div
            class="compare-item"
            :class="{ selected: !selectedItems.keywords }"
            @click="selectedItems.keywords = false"
          >
            <!-- <h6>기존</h6> -->
            <div class="keyword-chips">
              <span
                v-for="keyword in compareData.keywords.original"
                :key="keyword"
                class="keyword-chip"
              >
                {{ keyword }}
              </span>
            </div>
          </div>
          <div
            class="compare-item"
            :class="{ selected: selectedItems.keywords }"
            @click="selectedItems.keywords = true"
          >
            <!-- <h6>AI 생성</h6> -->
            <div class="keyword-chips">
              <span
                v-for="keyword in compareData.keywords.generated"
                :key="keyword"
                class="keyword-chip"
              >
                {{ keyword }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- 추천질문 비교 -->
      <div class="compare-section">
        <div class="compare-items">
          <div class="compare-item-title">
            <h6>추천 질문</h6>
          </div>
          <div class="compare-item-title">
            <h6>추천 질문</h6>
          </div>
        </div>
        <div class="compare-items">
          <div
            class="compare-item"
            :class="{ selected: !selectedItems.quests }"
            @click="selectedItems.quests = false"
          >
            <!-- <h6>기존</h6> -->
            <div class="keyword-chips">
              <span
                v-for="quest in compareData.quests.original"
                :key="quest"
                class="keyword-chip"
              >
                {{ quest }}
              </span>
            </div>
          </div>
          <div
            class="compare-item"
            :class="{ selected: selectedItems.quests }"
            @click="selectedItems.quests = true"
          >
            <!-- <h6>AI 생성</h6> -->
            <div class="keyword-chips">
              <span
                v-for="quest in compareData.quests.generated"
                :key="quest"
                class="keyword-chip"
              >
                {{ quest }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="popup-actions">
      <button class="apply-btn" @click="applySelection">선택 적용</button>
      <button class="cancel-btn" @click="$emit('close')">취소</button>
    </div>
  </div>
</template>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.compare-popup {
  padding: 20px;
  width: 55%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
  /* max-height: 95%; */
  /* overflow-y: auto; */
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.compare-section h6 {
  margin: 10px 0 10px 0 !important;
  /* text-align: center; */
  font-weight: 600 !important;
}

.compare-items {
  display: flex;
  gap: 20px;
}

.compare-item {
  flex: 1;
  padding: 15px;
  border: 2px solid #eee;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.compare-item-title {
  flex: 1;
  cursor: pointer;
  transition: all 0.3s ease;
}

.compare-item h6 {
  margin: 0 0 10px 0 !important;
}

.compare-item.selected {
  border-color: #51c2b8;
  background-color: #f8fffd;
}

.compare-item.selected h6 {
  margin: 0 0 10px 0 !important;
}

.keyword-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.keyword-chip {
  background-color: #f0f0f0;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
}

.popup-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.apply-btn {
  background-color: #51c2b8;
  color: white;
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-btn {
  background-color: #f0f0f0;
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup-header span {
  font-size: 20px;
  font-weight: 800;
}

.close-button {
  min-width: 40px;
  width: auto;
  text-align: center;
  background-color: rgb(236, 236, 236);
  box-shadow: 0 2px 2px 1px rgba(184, 184, 184, 0.3);
  font-size: 15px !important;
  font-weight: 600;
}

.content-titles {
  display: flex;
  gap: 20px;
  padding: 15px;
  background-color: #d8d8d8;
  border-radius: 8px;
}

.title-item {
  flex: 1;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.title-item h5 {
  margin: 0 0 0 0;
  color: #495057;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.title-item p {
  margin: 0;
  color: #212529;
  font-size: 14px;
  line-height: 1.5;
}

.section-title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 15px;
  padding-bottom: 8px;
  border-bottom: 2px solid #51c2b8;
}

.section-title h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  color: #333;
}

.section-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px; /* 밑줄 길이 */
  height: 2px;
  background-color: #51c2b8; /* 테마 컬러와 동일하게 */
}

.icon {
  font-size: 20px;
}

.compare-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.compare-content {
  margin-top: 10px;
  overflow-y: auto;
  max-height: 600px;
}
</style>
