<script setup lang="ts">
import router from "../../../routers";

const goToChannel = () => {
  router.push({ path: "/talk" }); // Replace with actual channel ID
};
</script>
<template>
  <header class="headerbox flexJustify">
    <div class="headerWrap">
      <p>애니톡 - 웹 자동화 수집/ 분석</p>
      <button @click="goToChannel">X</button>
    </div>
  </header>
</template>
<style lang="scss">
@use "../../../styles/responsive.scss";
@use "../header";

:root {
  $headerMargin: 2 * header.$headerMargin;
  $headerBoxMargin: 2 * header.$headerBoxMargin;
  $headerLineHeight: (header.$subTitleFontSize + header.$copyRightFontSize) *
    1.5;

  --header-logo-height: 32px;
  --header-logo-half-height: calc(var(--header-logo-height) / 2);
  --header-height: #{calc(var(--header-logo-height) + $headerMargin)};
  @include responsive.tablet {
    --header-logo-height: 52px;
    --header-height: #{calc(
        $headerLineHeight + #{$headerMargin + $headerBoxMargin + 1px}
      )};
  }
}
</style>

<style scoped lang="scss">
@use "../header";
.headerbox {
  min-height: 64px;
  width: 100%;
  flex-direction: row;
  margin-block: header.$headerBoxMargin;
  font-size: 20px;
  font-weight: 600;
  align-items: center;
}
.headerWrap {
  display: flex;
  flex-direction: row;
  margin-block: 10px;
  width: 100%;
  height: 100%;
  padding: 0px 30px 0px 20px;
  justify-content: space-between;
}
.headerWrap p {
  font-size: 20px;
  font-weight: 800;
}
</style>
