<script setup lang="ts">
import { elapsedTime } from "../../../utils/date";
import type { MsgCheck } from "../../../api/types";

const props = defineProps<{ pAnswers: MsgCheck[] }>();
</script>

<template>
  <div class="checkAnswersArea">
    <div
      class="eachAnswer backShadow fontMd"
      v-for="(each, index) in props.pAnswers"
      :key="index"
    >
      <p style="margin: 0">{{ each.question.body }}</p>
      <div class="line"></div>
      <div class="answer">
        <span class="body">{{ each.msg_2.body }}</span>
        <span class="info"
          >{{ each.msg_2_user.nick }} {{ elapsedTime(each.msg_2.cre_date) }}
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* .checkAnswersArea {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;

  & .eachAnswer {
    padding: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;

    & .line {
      width: 100%;
      border-bottom: 1px dashed gray;
    }

    & .answer {
      width: 100%;
      display: flex;
      align-items: start;
      justify-content: space-between;

      & .body {
        width: calc(100% - 200px);
      }

      & .info {
        width: 180px;
        white-space: nowrap;
        color: gray;
        text-align: right;
      }
    }
  }
}

@media (max-width: 768px) {
  .checkAnswersArea {
    gap: 10px;

    & .eachAnswer {
      padding: 15px;
      gap: 5px;

      & .answer {
        flex-direction: column;
        gap: 5px;

        & .body {
          width: 100%;
        }

        & .info {
          width: 100%;
          text-align: left;
        }
      }
    }
  }
} */
.checkAnswersArea {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;

  & .eachAnswer {
    padding: 20px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    & p {
      margin: 0;
      font-size: 1rem;
      line-height: 1.5;
      color: #333;
    }

    & .line {
      width: 100%;
      border-bottom: 1px dashed #ccc;
    }

    & .answer {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 5px;

      & .body {
        width: 100%;
        font-size: 0.9rem;
        line-height: 1.4;
        color: #555;
      }

      & .info {
        width: 100%;
        font-size: 0.8rem;
        color: #999;
        text-align: right;
      }
    }
  }
}

@media (max-width: 768px) {
  .checkAnswersArea {
    gap: 10px;

    & .eachAnswer {
      padding: 15px;
      gap: 10px;

      & .answer {
        gap: 5px;

        & .body,
        & .info {
          width: 100%;
        }

        & .info {
          text-align: left;
        }
      }
    }
  }
}
</style>
