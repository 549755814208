<!-- ======================================== 재영 수정 실제 데이터 호출 수빈 코드 아래에 있음!!-->
<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useStore } from "../../../stores/store";
import SubTabsCompo from "./SubTabsCompo.vue";
import AddKnowAuthPop from "./popups/AddKnowAuthPop.vue";
import DeleteConfirmPop from "./popups/DeleteConfirmPop.vue";
import AuthDetailPop from "./popups/AuthDetailPop.vue";
import { getAuthCardList } from "../../../api/backend";
import { elapsedYear } from "../../../utils/date";
import { useQuasar } from "quasar";

const store = useStore();

interface Props {
  checkNowTab: number;
  changeTab: () => void;
}

const props = defineProps<Props>();

// ✅ API 응답으로부터 가져온 권한 카드 타입 정의
interface AuthCardType {
  authcard_name: string; // 권한 카드 이름
  authcard_key: number; // 권한 카드 키
  channel_key: number; // 채널 키
  cre_date: string; // 생성 날짜
  folder_list?: any[]; // 폴더 목록 (있을 경우)
}

const authCards = ref<AuthCardType[]>([]); // ✅ API 응답을 저장할 상태
const selectedAuth = ref<number[]>([]); // ✅ 선택된 권한 카드 키
const showDeleteConfirm = ref(false);
const showAddAuthDialog = ref(false);
const showAuthDetailDialog = ref(false);
const selectedAuthData = ref<AuthCardType | null>(null);
const $q = useQuasar();

// 검색 관련 상태
const searchOptions = ref([
  { name: "이름", value: "name" },
  { name: "생성일", value: "date" }
]);
const selectedSearchOption = ref("name");
const searchQuery = ref("");

// ✅ API에서 권한 카드 목록 불러오기
const fetchAuthCards = async () => {
  const param = {
    subinfo_yn: 1,
    channel_key: store.currentChannel?.channel_key || 0
  };

  try {
    const authCardList = await getAuthCardList(param); // ✅ API 호출
    console.log("🚀 API 응답 데이터:", authCardList); // ✅ 응답 데이터 확인

    if (authCardList && authCardList.length > 0) {
      authCards.value = authCardList.map((item) => ({
        authcard_name: item.authcard?.authcard_name || "",
        authcard_key: item.authcard?.authcard_key || 0,
        channel_key: item.authcard?.channel_key || 0,
        cre_date: item.authcard?.cre_date || "",
        folder_list: item.folder_list || []
      }));
    } else {
      console.log("📢 가져온 권한 카드가 없습니다.");
      authCards.value = []; // ✅ 가져온 데이터가 없으면 빈 배열로 초기화
    }

    console.log("변환된 권한 카드 목록:", authCards.value);
  } catch (error) {
    console.error("카드 목록 가져오기 실패:", error);
    $q.notify({
      message: "권한 카드 목록을 불러오는데 실패했습니다.",
      color: "negative",
      timeout: 3000
    });
  }
};

onMounted(() => {
  fetchAuthCards();
});

// 검색 핸들러
const handleSearch = () => {
  searchQuery.value = searchQuery.value.trim();
  if (!searchQuery.value) {
    $q.notify({
      message: "검색어를 입력해주세요.",
      color: "negetive"
      // timeout: 2000
    });
    return;
  }
  const resultCount = filteredAuthCards.value.length;
  $q.notify({
    message: `검색결과: ${resultCount}건`,
    color: resultCount > 0 ? "positive" : "negative",
    timeout: 2000
  });
};

// 필터링된 권한 카드 목록
const filteredAuthCards = computed(() => {
  if (!searchQuery.value) {
    return authCards.value;
  }

  return authCards.value.filter((card) => {
    const searchTerm = searchQuery.value.toLowerCase();

    switch (selectedSearchOption.value) {
      case "name":
        return card.authcard_name.toLowerCase().includes(searchTerm);
      case "date":
        return elapsedYear(card.cre_date).includes(searchTerm);
      default:
        return true;
    }
  });
});

// 권한 카드 삭제 처리 (상세 팝업에서 호출됨)
const handleDeleteCard = async (cardKey: number) => {
  // 카드 삭제 후 목록 다시 불러오기
  await fetchAuthCards();
  // 팝업 닫기
  closeAuthDetailDialog();
};

// ✅ 선택된 권한 카드 토글
const toggleAuth = (authcardKey: number) => {
  if (!selectionMode.value) return;

  const index = selectedAuth.value.indexOf(authcardKey);
  if (index === -1) {
    selectedAuth.value.push(authcardKey);
  } else {
    selectedAuth.value.splice(index, 1);
  }
};

// ✅ 필터 및 팝업 관련 설정
const wrapTabs = computed(() => {
  return [`권한 카드 (${authCards.value.length})`];
});

const filterOptions = ref([
  { name: "전체", value: "default" },
  { name: "작성자", value: "creator" }
]);

const nowFilter = ref("default");

const openAddAuthDialog = () => {
  showAddAuthDialog.value = true;
};

const closeAddAuthDialog = () => {
  showAddAuthDialog.value = false;
  fetchAuthCards();
};

const openAuthDetailDialog = (authData: AuthCardType) => {
  const currentAuth = authCards.value.find(
    (auth) => auth.authcard_key === authData.authcard_key
  );
  if (currentAuth) {
    selectedAuthData.value = { ...currentAuth };
    showAuthDetailDialog.value = true;
  }
};

const closeAuthDetailDialog = () => {
  showAuthDetailDialog.value = false;
  selectedAuthData.value = null;
  fetchAuthCards();
};

const handleAddNewAuth = async (newAuth: any) => {
  console.log("권한 카드 추가 성공:", newAuth);
  // 카드 목록 다시 불러오기
  await fetchAuthCards();
  // 팝업 닫기
  closeAddAuthDialog();

  $q.notify({
    message: "권한 카드가 추가되었습니다.",
    color: "positive",
    timeout: 3000
  });
};

const handleAddAuth = () => {
  console.log("CheckKnowAuthCompo: handleAddAuth called");
  openAddAuthDialog();
};

const confirmDelete = () => {
  console.log("Deleting selected auth items:", selectedAuth.value);
  // 삭제 후 목록 갱신 (임시로 필터링)
  authCards.value = authCards.value.filter(
    (card) => !selectedAuth.value.includes(card.authcard_key)
  );

  selectedAuth.value = [];
  showDeleteConfirm.value = false;
  $q.notify({
    message: "권한 카드 삭제를 성공하였습니다.",
    color: "positive"
    // timeout: 3000
  });
};

const closeDeleteConfirm = () => {
  showDeleteConfirm.value = false;
};
</script>
<template>
  <div class="checkWrap">
    <!-- <SubTabsCompo
      :checkNowTab="props.checkNowTab"
      :pNowTab="props.checkNowTab"
      :pTabs="wrapTabs"
      :pOptions="filterOptions"
      :showAuthButtons="true"
      @addAuth="handleAddAuth"
      @deleteAuth="handleDeleteAuth"
      @update-filter="changeFilterOption"
    /> -->
    <div class="subTabWrap flexAlign">
      <div class="subTab flexAlign">
        <p class="total-count">권한 카드 ({{ filteredAuthCards.length }})</p>

        <!-- 검색 섹션 -->
        <div class="search-section flexAlign">
          <div class="info-section flexAlign">
            <button class="add-button cursorP" @click="handleAddAuth">
              권한 추가
            </button>
          </div>
          <select v-model="selectedSearchOption" class="search-select">
            <option
              v-for="option in searchOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.name }}
            </option>
          </select>
          <input
            type="text"
            v-model="searchQuery"
            placeholder="검색어를 입력하세요"
            class="search-input"
          />
          <button @click="handleSearch" class="search-button">검색</button>
        </div>
      </div>
    </div>

    <div class="checkItems">
      <div class="auth-container">
        <div
          v-for="card in filteredAuthCards"
          :key="card.authcard_key"
          class="auth-item"
          @click="openAuthDetailDialog(card)"
        >
          <!-- <label class="auth-label">
            <input
              type="checkbox"
              :value="card.authcard_key"
              :checked="selectedAuth.includes(card.authcard_key)"
              @change="toggleAuth(card.authcard_key)"
            />
            <div class="auth-content" @click="openAuthDetailDialog(card)">
              <span class="auth-text">{{ card.authcard_name }}</span>
              <span class="auth-date">{{ elapsedYear(card.cre_date) }}</span>
            </div>
          </label> -->
          <label class="auth-label">
            <div class="auth-content">
              <span class="auth-text">{{ card.authcard_name }}</span>
              <span class="auth-date">{{ elapsedYear(card.cre_date) }}</span>
            </div>
          </label>
        </div>

        <!-- 데이터가 없을 때 표시 -->
        <!-- <div v-if="authCards.length === 0" class="empty-auth-message">
          권한 카드가 없습니다. 위의 추가 버튼을 눌러 새 권한 카드를
          만들어보세요.
        </div> -->
      </div>
    </div>

    <DeleteConfirmPop
      :open="showDeleteConfirm"
      @update:open="showDeleteConfirm = $event"
      :pClosePop="closeDeleteConfirm"
      @confirm="confirmDelete"
    />

    <!-- 권한 카드 상세 팝업 -->
    <AuthDetailPop
      :open="showAuthDetailDialog"
      @update:open="showAuthDetailDialog = $event"
      :pClosePop="closeAuthDetailDialog"
      :authData="selectedAuthData"
      @delete="handleDeleteCard"
    />

    <!-- 권한 카드 추가 팝업 -->
    <AddKnowAuthPop
      :open="showAddAuthDialog"
      @update:open="showAddAuthDialog = $event"
      :pClosePop="closeAddAuthDialog"
      :authData="authCards"
      @save="handleAddNewAuth"
    />
  </div>
</template>

<style lang="scss" scoped>
.checkItems {
  padding: 0 5px;
}

.auth-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}

.auth-item {
  width: 100%;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  transition: all 0.2s ease;

  &:hover {
    border-color: #454545;
    background-color: #f8f8f8;
  }
}

.auth-label {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.auth-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px;
  cursor: pointer;
}

.auth-text {
  font-size: 16px;
  color: #333;
}

.selection-actions {
  display: flex;
  gap: 10px;
}

.cancel-selection-btn {
  background-color: transparent;
  border: none;
  color: #0078d4;
  cursor: pointer;
  font-weight: 500;
  padding: 5px 10px;

  &:hover {
    text-decoration: underline;
  }
}

.confirm-selection-btn {
  background-color: #0078d4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  padding: 5px 12px;

  &:hover {
    background-color: #0067b8;
  }
}

.subTabWrap {
  min-height: 50px;
  width: 100%;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid gray;
  padding: 0 6px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 80px;
    height: 3px;
    background-color: gray;
  }
}

.flexAlign {
  display: flex;
  align-items: center;
}

.subTab {
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: space-between;

  .tab-group {
    display: flex;
    gap: 20px;
    align-items: center;
  }
}

.total-count {
  margin: 0;
  font-size: 16px;
  font-weight: bold !important;
  padding: 14px 4px 10px;
  min-width: 100px;
}

.search-section {
  gap: 12px;
  flex: 1;
  justify-content: flex-end;

  .search-select {
    width: 100px;
    height: 35px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    background-color: white;
  }

  .search-input {
    height: 35px;
    padding: 0 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }

  .search-button {
    height: 35px;
    padding: 0 20px;
    background-color: #4a4a4a;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
}

.info-section {
  gap: 16px;
  white-space: nowrap;

  .add-button {
    height: 35px;
    padding: 0 16px;
    background: rgb(74, 74, 74);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
  }

  .delete-button {
    height: 35px;
    padding: 0 16px;
    background: #e0e0e0;
    color: #333;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;

    &.active-delete {
      background: #ff4c4c;
      color: white;
    }
  }

  .cancel-button {
    height: 35px;
    padding: 0 16px;
    background: #f0f0f0;
    color: #333;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
  }
}

/* 권한 카드 아이템 스타일 */
.auth-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}

.auth-item {
  width: 100%;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    border-color: #454545;
    background-color: #f8f8f8;
  }

  &.selected {
    background-color: #f0f7ff;
    border-color: #4a6cf7;
  }
}

.auth-label {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  width: 100%;
}

.auth-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px;
}

.auth-text {
  font-size: 16px;
  color: #333;
}

.auth-date {
  font-size: 14px;
  color: #666;
}

.empty-auth-message {
  text-align: center;
  padding: 40px 0;
  color: #666;
  font-style: italic;
}

/* 반응형 스타일 */
@media (max-width: 768px) {
  .subTab {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .search-section {
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;

    .search-input {
      flex: 1;
      min-width: 120px;
    }
  }

  .info-section {
    width: 100%;
    justify-content: space-between;
  }
}
</style>
