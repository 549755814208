<script setup lang="ts">
import { SplitterGroup, SplitterPanel } from "radix-vue";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "../../../stores/store.ts";
// import { useRoute } from "vue-router";

/* 컴포넌트 */
// import userCardCompo from "./userCardCompo.vue";
import MyPageQuestionList from "./MyPageQuestionList.vue";
import MyPageFavoriteList from "./MyPageFavoriteList.vue";
import MyPageReportList from "./MyPageReportList.vue";
import MyPageSubscribeList from "./MyPageSubscribeList.vue";

const store = useStore();
const router = useRouter();
const route = useRoute();
// watch(() => store.currentChannel);

const channelKey = Number(route.params.chankey);
// const store = useStore();
// const contentStore = useContentsStore();
const tabs = ["내 질문", "내 즐겨찾기", "내 신고내역", "내 구독"];
const nowTab = ref(0);
const selectedTab = (index: number) => {
  nowTab.value = index;
};
</script>

<template>
  <SplitterGroup id="main" class="contentbox" as="main" direction="horizontal">
    <SplitterPanel
      class="content searchbox flexAlign"
      style="height: calc(100vh - 85px); overflow-y: scroll"
    >
      <div class="myPageWrap flexJustify">
        <!-- <userCardCompo :pIsMine="true"></userCardCompo> -->
        <!-- <channelDetailRankCompo></channelDetailRankCompo> -->
      </div>

      <!-- <div class="myActListWrap backShadow">
        <div class="manageTabs flexAlign">
          <div
            class="fontBg eachTab cursorP"
            :class="{ selected: nowTab === index }"
            v-for="(tab, index) in tabs"
            :key="index"
            @click="selectedTab(index)"
          >
            {{ tab }}
          </div>
        </div>

        <div class="myActContentsWrap">
          <MyPageQuestionList v-if="nowTab === 0" />
          <MyPageFavoriteList v-if="nowTab === 1" />
          <MyPageReportList v-if="nowTab === 2" />
          <MyPageSubscribeList v-if="nowTab == 3" />
        </div>
      </div> -->
    </SplitterPanel>
  </SplitterGroup>
</template>
<style lang="scss" scoped>
@use "../../../styles/responsive";
@use "../../../styles/commons";

$splitBorder: 1px solid commons.$mainBorderColor;
.contentbox {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  /* border-top: $splitBorder; */
  background-color: commons.$mainBackground;
  overflow-y: scroll;
}
.content {
  flex: 1;
  display: flex;
  min-height: 100%;
  /* padding-block: 10px; */
  padding-inline: 20px;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE와 Edge에서 스크롤 바 숨기기 */
  scrollbar-width: none; /* Firefox에서 스크롤 바 숨기기 */
  margin: 0 auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @include responsive.tablet {
    /* padding-inline: 20rem; */
    max-width: 1024px;
  }

  & .myPageWrap {
    width: 100%;
    height: auto;
    /* min-height: 500px; */
    background-color: #fff;
    border: 1px solid gray;
    padding: 40px;
    margin-top: 10px;
    flex-direction: column;
    gap: 30px;

    & .btnWrap {
      width: 50%;
      margin: 0 auto;
      gap: 20px;
    }
    & .goToChatting {
      width: 70%;
      padding: 10px 30px;
    }

    & .goToModi {
      padding: 10px 30px;
    }
  }
}

.splitter {
  position: relative;
  border-left: $splitBorder;
}
.toggleButton {
  position: absolute;
  transform: translateX(-100%);
  left: 0px;
  top: calc(50vh - var(--header-logo-height));
  padding-inline: 5px;
  padding-block-start: 8px;
  padding-block-end: 10px;
  writing-mode: vertical-rl;
  font-weight: 700;

  $radius: 10px;
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
  border: 1px solid #8f8f9d;
}
.toggleIcon {
  transform: translateX(-0.5px);
}

.searchbox {
  position: relative;
  flex-direction: column;
  text-align: left;
  background-color: commons.$mainBackground;
}

.myActListWrap {
  width: 100%;
  margin-top: 30px;
  /* height: 100%; */

  & .manageTabs {
    width: 100%;
    justify-content: space-between;
    position: sticky;
    background-color: #fff;
    left: 0;
    top: 0;
    /* z-index: 2; */

    & .eachTab {
      width: 100%;
      padding: 20px 0 10px;
      text-align: center;
      border-bottom: 1px solid gray;
      background-color: #fff;
      color: #222;
      border-radius: 10px 10px 0 0;

      &.selected {
        font-weight: bold;
        /* border-bottom: 2px solid #fff; */
        color: #fff;
        background-color: rgb(84, 84, 84);
      }
    }
  }

  & .myActContentsWrap {
    width: 100%;
    overflow-y: hidden;
    /* height: 90%; */
    min-height: 500px;
    padding: 0px 0px 20px;
  }
}
</style>
