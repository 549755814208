<!-- CoTKnowPop.vue - AI Chain of Thought Component -->
<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { DialogClose } from "radix-vue";
import { usePopupStore } from "../../../../stores/popups";
import { useQuasar } from "quasar";
import ReferCommonPopLayer from "../../../../popups/ReferCommonPopLayer.vue";
import { getKmsKnowReason } from "../../../../api/backend";
import docCopy from "@fluentui/svg-icons/icons/document_copy_20_regular.svg";
import KnowList from "@fluentui/svg-icons/icons/document_bullet_list_20_regular.svg";
import ContentIcon from "@fluentui/svg-icons/icons/slide_text_20_regular.svg";
import SummaryIcon from "@fluentui/svg-icons/icons/document_checkmark_20_regular.svg";
import KeywordIcon from "@fluentui/svg-icons/icons/key_20_regular.svg";
import FolderTreeItem from "../../webCollection/FolderTreeItem.vue";
import ChevronDown from "@fluentui/svg-icons/icons/chevron_down_20_regular.svg";
import ChevronRight from "@fluentui/svg-icons/icons/chevron_right_20_regular.svg";
import DataBase from "@fluentui/svg-icons/icons/database_stack_16_regular.svg";
import Search from "@fluentui/svg-icons/icons/search_16_regular.svg";
import Folder from "@fluentui/svg-icons/icons/folder_16_regular.svg";
import LineSearch from "@fluentui/svg-icons/icons/line_horizontal_4_search_16_regular.svg";
import Check from "@fluentui/svg-icons/icons/checkmark_circle_16_regular.svg";
import FolderPerson from "@fluentui/svg-icons/icons/folder_person_20_regular.svg";
import FolderPeople from "@fluentui/svg-icons/icons/folder_people_20_regular.svg";

const popStore = usePopupStore();
const $q = useQuasar();

const props = defineProps<{
  msgKey: number;
}>();

const emit = defineEmits(["closeCoTKnowBtn"]);

const knowData = ref<any>(null);
const loading = ref(true);
const error = ref("");
const parsedData = ref<any>(null);
const selectedKnowledge = ref<any>(null);

// 토글 상태 관리
const showStep1 = ref(false);
const showStep2 = ref(false);
const showFinalKnowledge = ref(true); // 최종 선별 지식 섹션은 기본적으로 표시

// 섹션 토글 함수
const toggleStep1 = () => {
  showStep1.value = !showStep1.value;
};

const toggleStep2 = () => {
  showStep2.value = !showStep2.value;
};

const toggleFinalKnowledge = () => {
  showFinalKnowledge.value = !showFinalKnowledge.value;
};

// JSON 데이터 파싱
const parseJsonData = (jsonStr: string) => {
  try {
    return typeof jsonStr === "string" ? JSON.parse(jsonStr) : jsonStr;
  } catch (e) {
    console.error("JSON 파싱 오류:", e);
    return null;
  }
};

watch(
  () => props.msgKey,
  async (newKey) => {
    if (!newKey) {
      error.value = "msg_key가 유효하지 않습니다.";
      return;
    }

    try {
      loading.value = true;
      const response = await getKmsKnowReason(newKey);
      knowData.value = response;

      // JSON 데이터 파싱
      if (response?.reason_json) {
        parsedData.value = parseJsonData(response.reason_json);

        // 초기 선택된 지식 설정 (첫번째 지식)
        if (parsedData.value?.last_hubo_know_list?.length > 0) {
          selectedKnowledge.value = parsedData.value.last_hubo_know_list[0];
        }
      }
    } catch (err) {
      console.error("지식 데이터 로딩 실패:", err);
      error.value = "데이터를 불러오는데 실패했습니다.";
    } finally {
      loading.value = false;
    }
  },
  { immediate: true }
);

// 사용자 질문 (원본)
const userQuestion = computed(() => {
  return parsedData.value?.quest_str || "";
});

// LLM에 전달된 질문 (컨텍스트 포함)
const llmQuestion = computed(() => {
  return parsedData.value?.llm_quest_str || "";
});

// 선택된 지식 목록
const selectedKnowledgeList = computed(() => {
  return parsedData.value?.last_hubo_know_list || [];
});

// 검색 결과 계산
const elasticResults = computed(() => {
  return parsedData.value?.s1_ela_know_key_list || [];
});

const embeddingResults = computed(() => {
  return parsedData.value?.s1_emb_know_key_list || [];
});

const folderResults = computed(() => {
  return parsedData.value?.s2_authcard_folder_key_list || [];
});

const elasticResultsStep2 = computed(() => {
  return parsedData.value?.s2_ela_know_key_list || [];
});

const embeddingResultsStep2 = computed(() => {
  return parsedData.value?.s2_emb_know_key_list || [];
});

const closePop = () => {
  emit("closeCoTKnowBtn");
};

// const popTitle = "AI 지식 탐색 과정 (Chain of Thought)";
const popTitle = "지식 추적";

// 복사 기능
const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    $q.notify({
      message: "내용이 클립보드에 복사되었습니다.",
      color: "positive",
      timeout: 3000
    });
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
};

// 전체 데이터를 JSON 문자열로 변환하여 복사
const copyFullData = () => {
  if (!parsedData.value) return;

  const jsonStr = JSON.stringify(parsedData.value, null, 2);
  copyToClipboard(jsonStr);
};

// 지식 선택
const selectKnowledge = (knowledge) => {
  selectedKnowledge.value = knowledge;
};

// 텍스트 길이 제한 함수
const truncateText = (text, maxLength = 100) => {
  if (!text) return "";
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + "...";
};

// 부모 폴더 구조 (선택된 지식의 상위 폴더 구조)
const parentFolderStructure = computed(() => {
  if (!parsedData.value || !selectedKnowledge.value) return [];

  // 실제로는 API에서 받아온 폴더 정보를 처리해야 함
  // 현재는 s2_authcard_folder_key_list에서 최대 3개만 표시
  const folderKeys = parsedData.value.s2_authcard_folder_key_list || [];

  // 간단한 예시 폴더 구조 생성 (실제 구현에서는 API 데이터 활용)
  return folderKeys.slice(0, 3).map((key, index) => ({
    kms_folder_key: key,
    kms_folder_group_key: 1, // 임의의 그룹 키
    parent_folder_key: index > 0 ? folderKeys[index - 1] : 0, // 단순화된 계층 구조
    folder_name: `${key}`,
    channel_key: 1, // 임의의 채널 키
    delete_yn: 0
  }));
});

// 연관 폴더 구조 (관련 있는 다른 폴더들)
const relatedFolderStructure = computed(() => {
  if (!parsedData.value) return [];

  // 실제로는 API에서 받아온 폴더 정보를 처리해야 함
  // 현재는 s2_recursive_folder_key_list에서 최대 5개만 표시
  const folderKeys = parsedData.value.s2_recursive_folder_key_list || [];
  const authFolderKeys = parsedData.value.s2_authcard_folder_key_list || [];

  // 이미 부모 폴더 구조에 포함된 키는 제외
  const uniqueKeys = folderKeys
    .filter((key) => !authFolderKeys.includes(key))
    .slice(0, 5);

  // 간단한 예시 폴더 구조 생성
  return uniqueKeys.map((key) => ({
    kms_folder_key: key,
    kms_folder_group_key: 1, // 임의의 그룹 키
    parent_folder_key: 0, // 최상위 폴더로 가정
    folder_name: `${key}`,
    channel_key: 1, // 임의의 채널 키
    delete_yn: 0
  }));
});
</script>

<template>
  <ReferCommonPopLayer :pPopTitle="popTitle">
    <template #body>
      <div v-if="loading" class="loading-state">
        <div class="loading-spinner"></div>
        <p>데이터를 불러오는 중...</p>
      </div>

      <div v-else-if="error" class="error-state">
        {{ error }}
      </div>

      <div v-else-if="parsedData" class="cot-container">
        <!-- 질문 정보 영역 -->
        <div class="question-area">
          <div class="question-item">
            <strong class="question-label">사용자 질문:</strong>
            <span>{{ userQuestion }}</span>
          </div>
          <div class="question-item" v-if="llmQuestion !== userQuestion">
            <strong class="question-label">AI 인식 질문:</strong>
            <span>{{ llmQuestion }}</span>
          </div>
        </div>

        <!-- 메인 콘텐츠 (2컬럼 레이아웃) -->
        <div class="main-content">
          <!-- 왼쪽 패널 (지식 탐색 과정) -->
          <div class="left-panel">
            <!-- 최종 지식 후보 섹션 -->
            <div class="section-container">
              <!-- <h3 class="panel-title"><Check /> 최종 선별 지식</h3> -->
              <div class="section-header" @click="toggleFinalKnowledge">
                <h3 class="panel-title">
                  <span class="toggle-icon">
                    <ChevronRight v-if="!showFinalKnowledge" />
                    <ChevronDown v-else />
                  </span>
                  <Check /> 최종 선별 지식
                </h3>
              </div>

              <!-- <div class="panel-description">
                최종적으로 선별된 지식 후보입니다.<br />
                지식을 클릭하면 상세 내용을 확인할 수 있습니다.
              </div> -->

              <div v-show="showFinalKnowledge" class="section-content">
                <div class="knowledge-list">
                  <div
                    v-for="knowledge in selectedKnowledgeList"
                    :key="knowledge.know_key"
                    class="knowledge-card"
                    :class="{
                      active:
                        selectedKnowledge &&
                        selectedKnowledge.know_key === knowledge.know_key
                    }"
                    @click="selectKnowledge(knowledge)"
                  >
                    <div class="card-header">
                      <div class="title-area">
                        <!-- <div
                          class="priority-dot"
                          :class="getPriorityClass(knowledge.valid_yn)"
                        ></div> -->
                        <h4 class="card-title">
                          <KnowList /> {{ knowledge.title }}
                        </h4>
                      </div>
                      <span class="know-id">{{ knowledge.know_key }}</span>
                    </div>

                    <div class="card-body">
                      {{ truncateText(knowledge.body, 100) }}
                    </div>

                    <div v-if="knowledge.keyword_list" class="card-keywords">
                      <span
                        v-for="(keyword, idx) in knowledge.keyword_list"
                        :key="idx"
                        class="keyword-tag"
                      >
                        {{ keyword }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 1단계 검색 섹션 -->
            <div class="section-container search-section">
              <div class="section-header" @click="toggleStep1">
                <h3 class="panel-title">
                  <span class="toggle-icon">
                    <ChevronRight v-if="!showStep1" />
                    <ChevronDown v-else />
                  </span>
                  <LineSearch /> 1단계 : 검색
                </h3>
              </div>

              <div v-show="showStep1" class="section-content">
                <div class="result-boxes">
                  <div class="result-box">
                    <h4 class="result-title">
                      <div class="icon-margin"><Search /></div>
                      Elastic 검색 결과
                    </h4>
                    <div class="search-results">
                      <div
                        v-for="key in elasticResults"
                        :key="key"
                        class="result-item"
                        @click="
                          selectKnowledge(
                            selectedKnowledgeList.find(
                              (k) => k.know_key === key
                            )
                          )
                        "
                      >
                        <span class="result-icon">🔍</span>
                        <div class="result-info">
                          <div class="result-name">
                            {{
                              selectedKnowledgeList.find(
                                (k) => k.know_key === key
                              )?.title || `지식 ${key}`
                            }}
                            <div class="result-id">{{ key }}</div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="elasticResults.length === 0"
                        class="no-results"
                      >
                        검색 결과가 없습니다.
                      </div>
                    </div>
                  </div>

                  <div class="result-box">
                    <h4 class="result-title">
                      <div class="icon-margin"><DataBase /></div>
                      Embedding 검색 결과
                    </h4>
                    <div class="search-results">
                      <div
                        v-for="key in embeddingResults"
                        :key="key"
                        class="result-item"
                        @click="
                          selectKnowledge(
                            selectedKnowledgeList.find(
                              (k) => k.know_key === key
                            )
                          )
                        "
                      >
                        <span class="result-icon">🔍</span>
                        <div class="result-info">
                          <div class="result-name">
                            {{
                              selectedKnowledgeList.find(
                                (k) => k.know_key === key
                              )?.title || `지식 ${key}`
                            }}
                            <div class="result-id">{{ key }}</div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="embeddingResults.length === 0"
                        class="no-results"
                      >
                        검색 결과가 없습니다.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 폴더 구조 탐색 섹션 -->
            <div class="section-container folder-section">
              <div class="section-header" @click="toggleStep2">
                <h3 class="panel-title">
                  <span class="toggle-icon">
                    <ChevronRight v-if="!showStep2" />
                    <ChevronDown v-else />
                  </span>
                  <Folder /> 2단계 : 연관 폴더 탐색
                </h3>
              </div>

              <div v-show="showStep2" class="section-content">
                <div class="folder-areas">
                  <!-- 부모 폴더 섹션 -->
                  <div class="parent-folder-section">
                    <h4 class="result-title">
                      <div class="icon-margin"><FolderPerson /></div>
                      부모 폴더 구조
                    </h4>

                    <div class="folder-explorer">
                      <div
                        v-if="selectedKnowledge && parsedData"
                        class="parent-folders"
                      >
                        <!-- 부모 폴더 구조 표시 (FolderTreeItem 사용) -->
                        <template v-if="parentFolderStructure.length > 0">
                          <FolderTreeItem
                            v-for="folder in parentFolderStructure"
                            :key="folder.kms_folder_key"
                            :folder="folder"
                            :level="0"
                            :is-selectable="false"
                          />
                        </template>
                        <div v-else class="no-results">
                          부모 폴더 정보가 없습니다.
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- 연관 폴더 섹션 -->
                  <div class="related-folder-section">
                    <h4 class="result-title">
                      <div class="icon-margin"><FolderPeople /></div>
                      연관 폴더 구조
                    </h4>

                    <div class="folder-explorer">
                      <div v-if="parsedData" class="related-folders">
                        <!-- 연관 폴더 구조 표시 (FolderTreeItem 사용) -->
                        <template v-if="relatedFolderStructure.length > 0">
                          <FolderTreeItem
                            v-for="folder in relatedFolderStructure"
                            :key="folder.kms_folder_key"
                            :folder="folder"
                            :level="0"
                            :is-selectable="false"
                            :is-selected="
                              folderResults.includes(folder.kms_folder_key)
                            "
                          />
                        </template>
                        <div v-else class="no-results">
                          연관 폴더 정보가 없습니다.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 2단계 검색 결과 (1단계와 동일한 UI로 구성) -->
                <div class="result-boxes">
                  <!-- Elastic 검색 결과 -->
                  <div class="result-box">
                    <h4 class="result-title">
                      <div class="icon-margin"><Search /></div>
                      2단계 Elastic 검색 결과
                    </h4>
                    <div class="search-results">
                      <div
                        v-for="key in elasticResultsStep2"
                        :key="key"
                        class="result-item"
                        @click="
                          selectKnowledge(
                            selectedKnowledgeList.find(
                              (k) => k.know_key === key
                            )
                          )
                        "
                      >
                        <span class="result-icon">🔍</span>
                        <div class="result-info">
                          <div class="result-name">
                            {{
                              selectedKnowledgeList.find(
                                (k) => k.know_key === key
                              )?.title || `지식 ${key}`
                            }}
                            <div class="result-id">{{ key }}</div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="elasticResultsStep2.length === 0"
                        class="no-results"
                      >
                        검색 결과가 없습니다.
                      </div>
                    </div>
                  </div>

                  <!-- Embedding 검색 결과 -->
                  <div class="result-box">
                    <h4 class="result-title">
                      <div class="icon-margin"><DataBase /></div>
                      2단계 Embedding 검색 결과
                    </h4>
                    <div class="search-results">
                      <div
                        v-for="key in embeddingResultsStep2"
                        :key="key"
                        class="result-item"
                        @click="
                          selectKnowledge(
                            selectedKnowledgeList.find(
                              (k) => k.know_key === key
                            )
                          )
                        "
                      >
                        <span class="result-icon">🔍</span>
                        <div class="result-info">
                          <div class="result-name">
                            {{
                              selectedKnowledgeList.find(
                                (k) => k.know_key === key
                              )?.title || `지식 ${key}`
                            }}
                            <div class="result-id">{{ key }}</div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="embeddingResultsStep2.length === 0"
                        class="no-results"
                      >
                        검색 결과가 없습니다.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 오른쪽 패널 (지식 상세정보) -->
          <div class="right-panel">
            <div class="section-container">
              <h3 class="panel-title"><KnowList /> 지식 상세 정보</h3>

              <div v-if="selectedKnowledge" class="knowledge-detail">
                <div class="detail-section">
                  <div class="detail-header">
                    <h3 class="detail-title"><KnowList /> 제목</h3>
                    <!-- <div
                      class="priority-badge"
                      :class="getPriorityClass(selectedKnowledge.valid_yn)"
                    >
                      {{ getPriorityText(selectedKnowledge.valid_yn) }}
                    </div> -->
                  </div>
                  <div class="detail-content">
                    {{ selectedKnowledge.title }}
                  </div>
                </div>

                <div class="detail-section">
                  <h3 class="detail-title"><ContentIcon /> 내용</h3>
                  <div class="detail-content body-content">
                    <p
                      v-for="(line, idx) in selectedKnowledge.body.split('\n')"
                      :key="idx"
                    >
                      {{ line }}
                    </p>
                  </div>
                </div>

                <div
                  v-if="
                    selectedKnowledge.keyword_list &&
                    selectedKnowledge.keyword_list.length > 0
                  "
                  class="detail-section"
                >
                  <h3 class="detail-title">키워드</h3>
                  <div class="detail-keywords">
                    <span
                      v-for="(keyword, idx) in selectedKnowledge.keyword_list"
                      :key="idx"
                      class="detail-keyword-tag"
                    >
                      {{ keyword }}
                    </span>
                  </div>
                </div>

                <div class="knowledge-id-footer">
                  지식 ID: {{ selectedKnowledge.know_key }}
                </div>
              </div>
              <div v-else class="no-knowledge-selected">
                왼쪽에서 지식을 선택하여 상세 정보를 확인하세요.
              </div>
            </div>
          </div>
        </div>

        <!-- 복사 버튼 -->
        <!-- <div class="action-buttons">
          <div class="copyIcon" @click="copyFullData">
            <docCopy /> 전체 데이터 복사
          </div>
        </div> -->
      </div>

      <div v-else class="empty-state">지식 데이터가 없습니다.</div>
    </template>
    <template #actions>
      <DialogClose
        @click="closePop"
        class="IconButton fontSm cursorP cancel"
        aria-label="Close"
      >
        닫기
      </DialogClose>
    </template>
  </ReferCommonPopLayer>
</template>

<style scoped>
.loading-state {
  text-align: center;
  padding: 40px 20px;
  width: 100%;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3eb697;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 10px;
}

.error-state,
.empty-state {
  text-align: center;
  padding: 40px 20px;
  color: #666;
  width: 100%;
}

.cancel {
  background-color: #ccc;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cancel:hover {
  background-color: #b3b3b3;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* 전체 컨테이너 스타일 */
.commonPopBody {
  height: calc(100% - 67px) !important;
}

.cot-container {
  display: flex;
  flex-direction: column;
  max-height: 600px;
  height: 100%;
  width: 100%;
  font-family: Arial, sans-serif;
  border-radius: 8px;
  background-color: #fafafa;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

/* 질문 영역 스타일 */
.question-area {
  /* padding: 16px 20px; */
  padding: 10px 12px;
  background-color: #f0f7ff;
  border-bottom: 1px solid #e0e9f7;
  border-radius: 8px 8px 0 0;
}

.question-item {
  /* margin-bottom: 8px; */
  margin-bottom: 4px;
  line-height: 1.5;
}

.question-item:last-child {
  margin-bottom: 0;
}

.question-label {
  margin-right: 8px;
  color: #3b5998;
  font-weight: 600;
}

/* 메인 콘텐츠 레이아웃 */
.main-content {
  display: flex;
  flex: 1;
  overflow: hidden;
  height: 500px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05) inset;
}

/* 왼쪽 패널 (지식 탐색 과정) */
.left-panel {
  width: 50%;
  border-right: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  overflow-y: auto;
  background-color: #fff;
}

/* 오른쪽 패널 (지식 상세 정보) */
.right-panel {
  width: 50%;
  border-right: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  /* padding: 20px; */
  overflow-y: auto;
  background-color: #fff;
}

/* 섹션 컨테이너 */
.section-container {
  /* padding: 16px; */
  padding: 0;
  border-bottom: 1px solid #eee;
  background-color: #ffffff;
  margin: 0 10px 2px 10px;
  /* margin-bottom: 2px; */
  /* margin-right: 10px; */
}

.section-container:last-child {
  border-bottom: none;
}

.panel-title {
  margin: 5px 0 5px 0;
  /* margin: 0; */
  padding: 0px 16px;
  background-color: #f5f9ff;
  border-left: 5px solid #3eb697;
  font-size: 16px;
  font-weight: 700;
  color: #333;
  border-radius: 0 4px 4px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.toggle-icon {
  margin: 5px;
}

.final-knowledge-section {
  /* background-color: #f9fffe; */
}

.search-section {
  /* background-color: #fefeff; */
}

.folder-section {
  /* background-color: #fffcf7; */
}

.result-boxes {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* 결과 영역 공통 스타일 */
.result-box {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  /* padding: 16px; */
  padding: 4px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.parent-folder-section,
.related-folder-section {
  min-width: 200px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 7px 7px 7px 7px;
  /* margin-bottom: 16px; */
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.folder-areas {
  width: 100%;
  display: flex;
  justify-content: space-around;
  /* flex-direction: column; */
  /* gap: 5px; */
  margin-bottom: 16px;
}

.result-title {
  margin: 0 0 7px 0;
  font-size: 15px;
  font-weight: 600;
  color: #444;
  display: flex;
  align-items: center;
  /* padding-bottom: 8px; */
  border-bottom: 1px solid #f0f0f0;
}

.icon-margin {
  margin: 0 6px !important;
}

.additional-knowledge-title {
  margin-top: 20px;
  padding-top: 4px;
}

.search-results {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 10px;
}

.result-item {
  display: flex;
  align-items: center;
  /* padding: 10px 12px; */
  padding: 6px 6px;
  background-color: #f9f9f9;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid transparent;
}

.result-item:hover {
  background-color: #f0f4f8;
  border-color: #e0e9f7;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.result-icon {
  margin-right: 12px;
  font-size: 18px;
}

.result-info {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.result-name {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.result-id {
  font-size: 12px;
  color: #888;
  background-color: #f0f0f0;
  padding: 2px 6px;
  border-radius: 4px;
}

.no-results {
  padding: 16px;
  color: #888;
  text-align: center;
  font-style: italic;
  background-color: #f9f9f9;
  border-radius: 6px;
  border: 1px dashed #ddd;
}

/* 지식 카드 스타일 */
.knowledge-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.knowledge-card {
  padding: 6px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.knowledge-card.active {
  border: 1px solid #51c2b8;
  background-color: #eefffb;
  box-shadow: 0 2px 8px rgba(62, 182, 151, 0.15);
  transform: translateY(-2px);
}

.knowledge-card:hover:not(.active) {
  background-color: #f8f8f8;
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
}

.card-header {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 12px; */
  align-items: center;
  padding-bottom: 2px;
  border-bottom: 1px solid #f0f0f0;
}

.title-area {
  display: flex;
  align-items: center;
  gap: 8px;
}

.card-title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  display: flex;
  align-items: center;
  gap: 6px;
  line-height: 1.4;
}

.know-id {
  font-size: 12px;
  color: #666;
  background-color: #f0f0f0;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: 600;
}

.card-body {
  max-height: 42px;
  font-size: 14px;
  color: #555;
  /* margin-bottom: 12px; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* -webkit-line-clamp: 2; */
  -webkit-box-orient: vertical;
  line-height: 1.5;
  /* background-color: #fafafa; */
  /* padding: 5px 5px; */
  border-radius: 6px;
  /* border-left: 3px solid #e6e6e6; */
}

.card-keywords {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.keyword-tag {
  padding: 4px 10px;
  background-color: #f0f0f0;
  border-radius: 20px;
  font-size: 12px;
  color: #666;
  border: 1px solid #e0e0e0;
  font-weight: 500;
  transition: all 0.2s ease;
}

.keyword-tag:hover {
  background-color: #e8f5e9;
  color: #388e3c;
  border-color: #c8e6c9;
}

/* 지식 상세 정보 스타일 */
.knowledge-detail {
  /* margin: 0 10px 2px 10px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.detail-section {
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #f0f0f0;
}

.detail-title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  display: flex;
  align-items: center;
  gap: 6px;
}

.detail-content {
  font-size: 14px;
  color: #444;
  line-height: 1.6;
}

.body-content {
  line-height: 1.6;
  white-space: pre-wrap;
  max-height: 300px;
  overflow-y: auto;
  background-color: #fafafa;
  padding: 16px;
  border-radius: 6px;
  /* border-left: 3px solid #e6e6e6; */
}

.body-content p {
  margin: 0 0 12px 0;
}

.body-content p:last-child {
  margin-bottom: 0;
}

.detail-keywords {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 0;
}

.detail-keyword-tag {
  padding: 6px 12px;
  background-color: #f0f0f0;
  border-radius: 20px;
  font-size: 13px;
  color: #555;
  border: 1px solid #e0e0e0;
  font-weight: 500;
  transition: all 0.2s ease;
}

.detail-keyword-tag:hover {
  background-color: #e8f5e9;
  color: #388e3c;
  border-color: #c8e6c9;
  transform: translateY(-1px);
}

.knowledge-id-footer {
  text-align: right;
  font-size: 12px;
  color: #888;
  margin-top: 8px;
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
  border: 1px solid #eee;
}

.no-knowledge-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #888;
  font-style: italic;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 2px dashed #ddd;
  padding: 40px;
}

/* 하단 액션 버튼 */
.action-buttons {
  display: flex;
  gap: 10px;
  padding: 12px 16px;
  border-top: 1px solid #e0e0e0;
  background-color: #f8f9fa;
  border-radius: 0 0 8px 8px;
}

.copyIcon {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  transform-origin: center center;
  font-size: 14px;
  color: #555;
  padding: 8px 14px;
  background-color: #f0f0f0;
  border-radius: 6px;
  border: 1px solid #ddd;
  font-weight: 500;
  transition: all 0.2s ease;
}

.copyIcon:hover {
  transform: translateY(-1px);
  color: #333;
  background-color: #e9ecef;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-color: #ccc;
}

/* 폴더 탐색기 영역 */
.folder-explorer {
  background-color: #fbfbfb;
  border: 1px solid #eee;
  border-radius: 6px;
  /* padding: 8px; */
  max-height: 200px;
  overflow-y: auto;
}

.parent-folders,
.related-folders {
  padding: 4px;
}

/* 우선순위 관련 스타일 */
.priority-high {
  background-color: #ffebee;
  border: 2px solid #d32f2f;
}

.priority-medium {
  background-color: #e8f5e9;
  border: 2px solid #388e3c;
}

.priority-low {
  background-color: #e3f2fd;
  border: 2px solid #1976d2;
}

/* FolderTreeItem 호환성 스타일 */
:deep(.folder-header) {
  padding: 8px 10px !important;
  background-color: #f8f9fa !important;
  border-bottom: 1px solid #e0e0e0 !important;
  font-size: 14px !important;
}

:deep(.folder-title) {
  gap: 6px !important;
}

:deep(.folder-icon) {
  color: #555 !important;
}

:deep(.folder-name) {
  font-size: 14px !important;
  color: #444 !important;
}

:deep(.folder-header.selected) {
  border-left: 4px solid #616364 !important;
}

:deep(.folder-header:hover) {
  background-color: #e9ecef !important;
}

.section-header {
  cursor: pointer;
}

/* 기존 반응형 스타일 수정 및 개선 */
@media (max-width: 992px) {
  .main-content {
    flex-direction: column;
    height: auto;
    overflow-y: auto;
  }

  .left-panel,
  .right-panel {
    width: 100%;
    max-height: none;
    border-right: none;
    border-left: none;
  }

  .left-panel {
    border-bottom: 1px solid #9b9a9a;
  }

  .right-panel {
    margin-top: 10px;
  }

  .section-container {
    margin: 0 5px 2px 5px;
  }

  .panel-title {
    font-size: 14px;
    padding: 0px 10px;
  }

  .result-boxes {
    gap: 10px;
  }

  .result-box,
  .parent-folder-section,
  .related-folder-section {
    padding: 10px;
  }

  .result-title {
    font-size: 13px;
  }

  .folder-areas {
    flex-direction: column;
    gap: 10px;
  }

  .knowledge-card {
    padding: 8px;
  }

  .card-title {
    font-size: 14px;
  }

  .detail-section {
    padding: 12px;
  }

  .detail-title {
    font-size: 14px;
  }
}

/* 모바일 소형 화면 (768px 이하) 추가 스타일 */
@media (max-width: 768px) {
  .cot-container {
    max-height: 100%;
  }

  .question-area {
    padding: 10px;
  }

  .question-item {
    font-size: 14px;
  }

  .knowledge-list {
    gap: 8px;
  }

  .card-body {
    font-size: 13px;
    -webkit-line-clamp: 2; /* 모바일에서는 2줄만 표시 */
  }

  .detail-content {
    font-size: 13px;
  }

  .body-content {
    padding: 10px;
    max-height: 200px;
  }

  .keyword-tag,
  .detail-keyword-tag {
    padding: 3px 8px;
    font-size: 11px;
  }

  .result-item {
    padding: 8px;
  }

  .folder-explorer {
    max-height: 150px;
  }
}

/* 초소형 화면 (480px 이하) 스타일 */
@media (max-width: 480px) {
  .section-container {
    margin: 0 2px 2px 2px;
  }

  .panel-title {
    font-size: 13px;
    padding: 0px 8px;
  }

  .folder-areas {
    margin-bottom: 8px;
  }

  .result-item {
    padding: 6px;
  }

  .result-icon {
    margin-right: 8px;
    font-size: 16px;
  }

  .result-name {
    font-size: 12px;
  }

  .result-id {
    font-size: 10px;
    padding: 1px 4px;
  }

  .card-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .know-id {
    margin-top: 4px;
    font-size: 10px;
  }

  .card-keywords {
    gap: 4px;
  }

  /* 스크롤바 크기 축소 */
  .left-panel::-webkit-scrollbar,
  .right-panel::-webkit-scrollbar,
  .folder-explorer::-webkit-scrollbar,
  .body-content::-webkit-scrollbar {
    width: 4px;
  }
}

/* 팝업 크기 조정을 위한 추가 스타일 */
@media (max-width: 992px) {
  :deep(.commonPopLayer) {
    width: 95vw !important;
    max-width: 95vw !important;
    height: 90vh !important;
    max-height: 90vh !important;
  }

  :deep(.commonPopBody) {
    max-height: calc(90vh - 100px) !important;
    overflow-y: hidden !important;
  }
}

/* 스크롤바 스타일링 */
.left-panel::-webkit-scrollbar,
.right-panel::-webkit-scrollbar,
.folder-explorer::-webkit-scrollbar,
.body-content::-webkit-scrollbar {
  width: 8px;
}

.left-panel::-webkit-scrollbar-track,
.right-panel::-webkit-scrollbar-track,
.folder-explorer::-webkit-scrollbar-track,
.body-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.left-panel::-webkit-scrollbar-thumb,
.right-panel::-webkit-scrollbar-thumb,
.folder-explorer::-webkit-scrollbar-thumb,
.body-content::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.left-panel::-webkit-scrollbar-thumb:hover,
.right-panel::-webkit-scrollbar-thumb:hover,
.folder-explorer::-webkit-scrollbar-thumb:hover,
.body-content::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

/* 팝업 컨테이너 반응형 스타일 */
:deep(.commonPopLayer) {
  /* 기본 스타일은 유지하되 최대 너비와 높이 설정 */
  max-width: 90vw;
  max-height: 85vh;
  width: auto !important; /* !important로 기존 인라인 스타일 재정의 */
  height: auto !important;
  overflow: hidden;
}

:deep(.commonPopBody) {
  height: calc(85vh - 120px) !important; /* 헤더와 하단 버튼 영역 제외 */
  /* overflow-y: auto !important; */
}

:deep(.popupFooter) {
  padding: 10px !important;
}

/* 모바일 전용 스타일 */
@media (max-width: 768px) {
  :deep(.commonPopLayer) {
    max-width: 95vw;
    max-height: 90vh;
    width: 95vw !important;
    border-radius: 8px;
  }

  :deep(.commonPopHeader) {
    padding: 10px !important;
  }

  :deep(.popupFooter .cancel) {
    padding: 8px 12px;
    font-size: 14px;
  }
}

/* 초소형 화면 스타일 */
@media (max-width: 480px) {
  :deep(.commonPopLayer) {
    max-width: 98vw;
    width: 98vw !important;
    border-radius: 6px;
  }

  :deep(.commonPopHeader h1) {
    font-size: 16px !important;
  }

  :deep(.commonPopBody) {
    padding: 8px !important;
  }
}

/* 가로 모드(landscape) 스마트폰을 위한 조정 */
@media (max-height: 480px) and (orientation: landscape) {
  :deep(.commonPopLayer) {
    max-height: 96vh;
  }

  :deep(.commonPopBody) {
    height: calc(96vh - 100px) !important;
  }

  /* 가로 모드에서는 콘텐츠를 더 컴팩트하게 */
  .question-area {
    padding: 8px;
  }

  .panel-title {
    margin: 3px 0;
    line-height: 1.5rem !important;
  }

  .detail-section {
    padding: 8px;
  }
}

@media (max-width: 768px) {
  /* 팝업 자체 크기 조정 */
  :deep(.commonPopLayer) {
    width: 95vw !important;
    max-width: 95vw !important;
    height: auto !important;
    max-height: 90vh !important;
  }

  :deep(.commonPopBody) {
    height: auto !important;
    max-height: calc(90vh - 100px) !important;
    overflow-y: hidden !important;
  }

  /* 컨테이너 높이 제한 해제 */
  .cot-container {
    max-height: none;
    height: auto;
  }

  /* 콘텐츠 영역 위아래로 분리 */
  .main-content {
    flex-direction: column;
    height: auto;
  }

  /* 왼쪽/오른쪽 패널 전체 너비로 */
  .left-panel,
  .right-panel {
    width: 100%;
    border-right: none;
    border-left: none;
  }

  /* 왼쪽 패널에 최대 높이 제한 */
  .left-panel {
    max-height: 350px;
    overflow-y: auto;
    border-bottom: 2px solid #9b9a9a;
    margin-bottom: 10px;
  }

  /* 오른쪽 패널도 높이 제한 */
  .right-panel {
    max-height: 350px;
    overflow-y: auto;
  }

  /* 섹션 컨테이너 여백 감소 */
  .section-container {
    margin: 0 5px 2px 5px;
  }

  /* 패널 타이틀 간소화 */
  .panel-title {
    font-size: 14px;
    padding: 0px 10px;
  }

  /* 폴더 영역 세로 배치 */
  .folder-areas {
    flex-direction: column;
    gap: 8px;
    margin-bottom: 8px;
  }

  /* 폴더 영역 축소 */
  .parent-folder-section,
  .related-folder-section {
    padding: 8px;
  }

  /* 검색 결과 높이 제한 */
  .search-results {
    max-height: 150px;
    overflow-y: auto;
  }

  /* 결과 제목 축소 */
  .result-title {
    font-size: 13px;
    margin-bottom: 6px;
  }

  /* 카드 컴팩트하게 */
  .knowledge-card {
    padding: 5px;
  }

  .card-title {
    font-size: 14px;
  }

  .card-body {
    font-size: 13px;
    -webkit-line-clamp: 1;
    max-height: 20px;
  }

  /* 결과 아이템 간소화 */
  .result-item {
    padding: 8px;
  }

  .result-name {
    font-size: 13px;
  }

  /* 상세 정보 영역 */
  .detail-section {
    padding: 10px;
    margin-bottom: 8px;
  }

  .detail-title {
    font-size: 14px;
  }

  .body-content {
    padding: 10px;
    max-height: 200px;
  }

  /* 키워드 태그 축소 */
  .keyword-tag,
  .detail-keyword-tag {
    padding: 3px 8px;
    font-size: 11px;
  }
}

/* 작은 모바일 화면 (480px 이하) */
@media (max-width: 480px) {
  /* 패널 추가 축소 */
  .left-panel {
    max-height: 197px;
    margin-bottom: 2px;
    border-bottom: 1px solid #9b9a9a;
  }

  .right-panel {
    max-height: 197px;
    margin-top: 0px;
  }

  .panel-title {
    line-height: revert;
    padding: 2px 5px;
  }
  /* 질문 영역 더 간소화 */
  .question-area {
    padding: 8px 10px;
  }

  .question-item {
    font-size: 11px;
  }

  /* 카드 헤더 간소화 */
  .card-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .know-id {
    font-size: 10px;
    margin-top: 2px;
  }

  /* 결과 아이템 더 간소화 */
  .result-item {
    padding: 6px;
  }

  .result-icon {
    margin-right: 8px;
    font-size: 14px;
  }

  .result-name {
    font-size: 12px;
  }

  .result-id {
    font-size: 10px;
    padding: 1px 4px;
  }

  .result-box {
    padding: 3px;
  }
  .result-title {
    height: 30px;
  }

  /* 폴더 항목 작게 */
  :deep(.folder-header) {
    padding: 6px 8px !important;
    font-size: 13px !important;
  }

  :deep(.folder-name) {
    font-size: 12px !important;
  }

  /* 키워드 더 작게 */
  .card-keywords {
    gap: 4px;
  }

  .keyword-tag {
    padding: 2px 5px;
    font-size: 10px;
  }

  .detail-section {
    padding: 5px;
    margin-bottom: 0px;
  }

  .detail-header {
    height: 30px;
    margin-bottom: 8px;
  }

  .detail-title {
    height: 30px;
  }

  .parent-folder-section {
    margin-bottom: 0px;
  }

  .related-folder-section {
    margin-bottom: 0px;
  }
}

/* 매우 작은 화면 */
@media (max-width: 360px) {
  /* 패널 높이 더 축소 */
  .left-panel {
    max-height: 230px;
  }

  .right-panel {
    max-height: 230px;
  }

  /* 패널 제목 더 작게 */
  .panel-title {
    font-size: 11px;
    padding: 0px 8px;
  }

  /* 폴더 트리 높이 축소 */
  .folder-explorer {
    max-height: 100px;
  }
}

/* 스크롤바 스타일 간소화 */
.left-panel::-webkit-scrollbar,
.right-panel::-webkit-scrollbar,
.folder-explorer::-webkit-scrollbar,
.body-content::-webkit-scrollbar,
.search-results::-webkit-scrollbar {
  width: 4px;
}

.left-panel::-webkit-scrollbar-thumb,
.right-panel::-webkit-scrollbar-thumb,
.folder-explorer::-webkit-scrollbar-thumb,
.body-content::-webkit-scrollbar-thumb,
.search-results::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}
</style>
