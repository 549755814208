<!-- FolderTreeMoveItem.vue -->
<script setup lang="ts">
import { ref, watch, nextTick, computed, onMounted, PropType } from "vue";
import { useStore } from "../../../stores/store";
import { KmsFolder } from "../../../api/types";
import RightIcon from "@fluentui/svg-icons/icons/chevron_right_20_regular.svg";
import DownIcon from "@fluentui/svg-icons/icons/chevron_down_20_regular.svg";

const kmsStore = useStore();

const props = defineProps({
  folder: {
    type: Object,
    required: true
  },
  level: {
    type: Number,
    default: 0
  },
  isSelected: {
    type: Boolean,
    default: false
  },
  isSelectable: {
    type: Boolean,
    default: false
  },
  expandedFolders: {
    type: Object,
    default: () => new Set()
  }
});

// 하위 폴더 존재 여부 체크
const hasSubFolders = computed(() => {
  return kmsStore.folders.some(
    (f) =>
      f.parent_folder_key === props.folder.kms_folder_key && f.delete_yn !== 1
  );
});

// emit 정의 추가
const emit = defineEmits(["target-folder-select", "toggle-folder"]);

// 폴더 토글 메서드 추가
const toggleFolder = (event) => {
  event.stopPropagation(); // 이벤트 전파 방지
  const folderKey = props.folder.kms_folder_key;
  // 상위 컴포넌트에 토글 이벤트 발생
  emit("toggle-folder", folderKey);
};

const handleFolderClick = async () => {
  // 토글 이벤트 발생 (폴더 헤더 클릭으로 열고 닫기)
  const folderKey = props.folder.kms_folder_key;
  emit("toggle-folder", folderKey);

  // 폴더 이동 모드일 때 (이동 팝업)
  if (props.isSelectable) {
    emit("target-folder-select", {
      folderKey: props.folder.kms_folder_key,
      selected: !props.isSelected // 토글 동작으로 변경
    });
  }
};

// 하위 폴더 목록
const childFolders = computed(() => {
  const result = kmsStore.folders.filter(
    (f) =>
      f.parent_folder_key === props.folder.kms_folder_key && f.delete_yn !== 1
  );
  return result.sort((a, b) => a.kms_folder_key - b.kms_folder_key);
});

// 폴더 depth 계산하여 화면상 들여쓰기
const calculateFolderDepth = (folder) => {
  const depth = props.level; // 시작 레벨을 props.level로 설정
  return depth;
};
</script>

<template>
  <div class="folder-item">
    <!-- <div
      class="folder-header"
      :class="{ selected: isSelected }"
      :style="isSelected ? 'border-left: 4px solid #616364 !important;' : ''"
      @click="handleFolderClick"
    > -->
    <div
      class="folder-header"
      :class="{ selected: props.isSelected }"
      @click="handleFolderClick"
    >
      <div
        class="folder-title"
        :style="{
          paddingLeft: `${calculateFolderDepth(folder) * 20}px`,
          borderLeft: calculateFolderDepth(props.folder) > 0 ? 'none' : 'none'
        }"
      >
        <span class="folder-icon" @click.stop="toggleFolder($event)">
          <!-- {{
            hasSubFolders
              ? props.expandedFolders.has(folder.kms_folder_key)
                ? "⌵"
                : ">"
              : ">"
          }} -->
          <template
            v-if="
              kmsStore.sortedFolders.some(
                (f) =>
                  f.parent_folder_key === folder.kms_folder_key &&
                  f.delete_yn !== 1
              )
            "
          >
            <component
              :is="
                kmsStore.expandedFolders.has(folder.kms_folder_key)
                  ? DownIcon
                  : RightIcon
              "
            />
          </template>
          <template v-else>
            <!-- 하위 폴더가 없을 때는 아무것도 표시하지 않음 -->
          </template>
        </span>
        <!-- <span v-else class="folder-icon-placeholder"></span> -->

        <span class="folder-name" :title="folder.folder_name">
          {{ folder.folder_name }}
          <span class="folder-key">{{ folder.kms_folder_key }}</span>
        </span>
        <!-- <span v-if="isSelected" class="selection-indicator">✓</span> -->
      </div>
    </div>

    <!-- 하위 폴더 - 팝업의 확장 상태를 사용해 펼쳐졌을 때만 표시 -->
    <div
      v-if="hasSubFolders && props.expandedFolders.has(folder.kms_folder_key)"
      class="subfolder-container"
    >
      <folder-tree-move-item
        v-for="childFolder in childFolders"
        :key="childFolder.kms_folder_key"
        :folder="childFolder"
        :level="level + 1"
        :is-selectable="isSelectable"
        :is-selected="
          isSelected && childFolder.kms_folder_key === folder.kms_folder_key
        "
        :expanded-folders="expandedFolders"
        @toggle-folder="$emit('toggle-folder', $event)"
        @target-folder-select="$emit('target-folder-select', $event)"
      />
    </div>
  </div>
</template>

<style scoped>
.folder-item {
  width: 100%;
}

/* 부모 요소 확인 */
.folder-item {
  /* width: 100%; */
  /* overflow: visible; */
}
/* 더 강력한 선택자 사용 */
/* .folder-item .folder-header.selected {
  border-left: 4px solid #616364 !important;
  background-color: #e7feef !important;
} */

.folder-header {
  padding: 10px;
  cursor: pointer;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dedede;
  font-size: 1.2em;
  /* transition: all 0.2s ease; */
  border-left: 4px solid transparent !important;
  /* position: relative; */
  /* overflow: visible; */
  /* box-sizing: border-box; */
}
.folder-header.selected::before {
  /* border-left: 4px solid #616364 !important; */
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  /* background-color: #61fㅔ6364; */
  z-index: 1;
}

.folder-header.selected {
  border-left: 4px solid #616364 !important; /* 명시적으로 border-left 추가 */
  background-color: #e7feef;
  /* padding-left: 6px; */
}

/* border-left 대신 가상 요소 사용 */
/* .folder-header.selected::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #616364 !important;
  z-index: 1;
} */

.folder-header:hover {
  background-color: #e9ecef;
}

.folder-title {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.folder-icon {
  width: 20px;
  text-align: center;
  color: #666;
  cursor: pointer;
}

/* .folder-icon-placeholder {
  width: 20px;
} */

.folder-name {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 50px);
}

.folder-key {
  font-size: 0.65em;
  color: #888;
  font-weight: normal;
}

.subfolder-container {
  width: 100%;
  background-color: #fff;
}
</style>
