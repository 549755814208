<script setup lang="ts">
import { DialogRoot } from "radix-vue";
import { computed, onMounted, ref } from "vue";
import { RouterView, useRoute, useRouter } from "vue-router";
import SidebarRoot from "./components/common/sidebar/SidebarRoot.vue";
import ChannelSidebar from "./components/root/ChannelSidebar.vue";
import MainHeader from "./components/root/MainHeader.vue";
import CollectionHeader from "./components/root/webCollection/CollectionHeader.vue";
import AddChannelDialog from "./popups/ChannelCreatePop.vue";
import { usePopupStore } from "./stores/popups";
// import { getCssSelector } from "css-selector-generator";
// import { ElementPicker } from "./utils/elementPicker";

const popStore = usePopupStore();
const channelAddDialog = ref<boolean>(false);
const collectionYn = ref<boolean>(false);
const route = useRoute();
const router = useRouter();

const closeAddPop = () => {
  channelAddDialog.value = false;
};

// Update collectionYn based on route path
const updateCollectionYn = () => {
  collectionYn.value = route.path === "/talk/webpage";
};

onMounted(async () => {
  updateCollectionYn();

  // Inspector 테스트 코드
  // nextTick(() => {
  //   new ElementPicker({
  //     container: document.body,
  //     selectors: "*",
  //     background: "rgba(153, 235, 255, 0.5)",
  //     borderWidth: 5,
  //     transition: "all 150ms ease",
  //     ignoreElements: [document.body],
  //     actions: [
  //       {
  //         trigger: "mouseover",
  //         callback: (target) => {
  //           console.log("=====Hover Target====");
  //           console.log(target);
  //           console.log(getCssSelector(target));
  //         }
  //       },
  //       {
  //         trigger: "click",
  //         callback: (target) => {
  //           console.log("=====Click Target====");
  //           console.log(target);
  //           console.log(getCssSelector(target));
  //         }
  //       }
  //     ]
  //   });
  // });
});

router.beforeEach((to) => {
  collectionYn.value = to.path === "/talk/webpage";
  // homepageYn.value = to.path === "/";
});

// // KMS 페이지 여부 확인
// const isKmsPage = computed(() => {
//   return route.path.includes("/talk/webpage"); // KMS 라우트 경로에 맞게 수정
// });
// KMS 페이지 여부 확인
const isKmsPage = computed(() => {
  return route.path.includes("/talk/kmspage"); // KMS 라우트 경로에 맞게 수정
});

// KMS 페이지 여부 확인
const isDocentPage = computed(() => {
  return route.path.includes("/aidocent"); // KMS 라우트 경로에 맞게 수정
});

// KMS 페이지 여부 확인
const isAiOrchPage = computed(() => {
  return route.path.includes("/aiOrch"); // KMS 라우트 경로에 맞게 수정
});

// managing 페이지 여부 확인
const isManagePage = computed(() => {
  return route.path.includes("/channelManage"); // KMS 라우트 경로에 맞게 수정
});

// 홈페이지 페이지 여부 확인
const isMainPage = computed(() => {
  return route.path === "/"; // 메인 페이지(`/`) 여부 확인
});

const homepageYn = ref(false);
</script>

<template>
  <DialogRoot
    v-if="!collectionYn && !homepageYn"
    v-model:open="channelAddDialog"
  >
    <SidebarRoot>
      <MainHeader
        v-if="!isKmsPage && !isDocentPage && !isAiOrchPage && !isManagePage"
      />
      <ChannelSidebar :disableOutsidePointerEvents="channelAddDialog" />
    </SidebarRoot>
    <AddChannelDialog :pClosePop="closeAddPop" />
    <RouterView />
  </DialogRoot>
  <DialogRoot v-else v-model:open="channelAddDialog">
    <SidebarRoot>
      <CollectionHeader />
      <ChannelSidebar :disableOutsidePointerEvents="channelAddDialog" />
    </SidebarRoot>
    <AddChannelDialog :pClosePop="closeAddPop" />
    <RouterView />
  </DialogRoot>
  <DialogRoot v-model:open="popStore.confirmPopYn">
    <confirmPop />
  </DialogRoot>
</template>

<style>
html,
body,
#app {
  height: 100%;
  overflow: hidden;
}
#app {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  /* height: auto; */
  height: 100dvh;
  min-height: 100vh;
  /* max-width: 1024px; */
}

* {
  font-family:
    "Pretendard Variable",
    Pretendard,
    -apple-system,
    BlinkMacSystemFont,
    system-ui,
    Roboto,
    "Helvetica Neue",
    "Segoe UI",
    "Apple SD Gothic Neo",
    "Noto Sans KR",
    "Malgun Gothic",
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    sans-serif;
}

h1 {
  font-weight: 900;
}
</style>
