<script lang="ts">
import type { InjectionKey, Ref } from "vue";

export const sidebarKey: InjectionKey<SidebarContext> = Symbol();
export interface SidebarContext {
  enabled: Ref<boolean>;
  toggleEnabled: () => void;
}
</script>

<script setup lang="ts">
import { provide, ref, watch } from "vue";

const enabled = ref(false);
const toggleEnabled = () => {
  enabled.value = !enabled.value;
};

watch(enabled, (newValue) => {
  if (newValue) {
    // 스크롤 막기
    document.getElementById("app").style.height = "100vh";
  } else {
    // 스크롤 해제
    document.getElementById("app").style.height = "auto";
  }
});
provide(sidebarKey, { enabled, toggleEnabled });
</script>
<template>
  <slot></slot>
</template>
