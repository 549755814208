<script setup lang="ts">
import { useQuasar } from "quasar";
import { ref, watch } from "vue";

const props = defineProps<{
  show: boolean;
  knowBody: string;
}>();

const emit = defineEmits(["close", "save", "toggle"]);

const applyAndClose = () => {
  emit("save", localBody.value); // 부모로 수정된 값 전달
  $q.notify({
    message: "지식 원본을 수정하였습니다.",
    color: "positive"
  });
  emit("toggle");
  emit("close"); // 팝업 닫기
  editYn.value = false;
};

const editYn = ref(false);
const $q = useQuasar();

const editMode = () => {
  console.log("편집모드");
  editYn.value = !editYn.value; // ← 수정: !=이 아니라 = ! 로 토글해야 함
};

const localBody = ref("");

// props.show가 true가 될 때마다 props.knowBody → localBody에 복사
watch(
  () => props.show,
  (newVal) => {
    if (newVal) {
      localBody.value = props.knowBody;
    }
  },
  { immediate: true }
);
</script>

<template>
  <div v-if="props.show" class="modal-overlay" @click="$emit('close')"></div>
  <div v-if="props.show" class="compare-popup" @click.stop>
    <div class="popup-header">
      <span>지식 원본</span>
      <button class="close-button" @click="$emit('close')">×</button>
    </div>
    <div v-if="!editYn" class="compare-content">
      <div class="compare-items">
        <!-- 수정된 부분: props.knowBody → localBody -->
        <div class="compare-item">{{ localBody }}</div>
      </div>
    </div>

    <div v-else class="compare-content">
      <div class="compare-items">
        <textarea class="edit-textarea" v-model="localBody" />
      </div>
    </div>

    <div class="popup-actions">
      <!-- <button class="apply-btn" @click="editMode">
        {{ editYn ? "편집" : "수정" }}
      </button> -->
      <button class="apply-btn" @click="editYn ? applyAndClose() : editMode()">
        {{ editYn ? "적용" : "편집" }}
      </button>
      <button class="cancel-btn" @click="$emit('close')">취소</button>
    </div>
  </div>
</template>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.compare-popup {
  padding: 20px;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
  /* max-height: 95%; */
  /* overflow-y: auto; */
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.compare-section h6 {
  margin: 10px 0 10px 0 !important;
  /* text-align: center; */
  font-weight: 600 !important;
}

.compare-items {
  display: flex;
  gap: 20px;
  flex: 1;
  padding: 15px;
  /* border: 2px solid #eee; */
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-height: 20vh;
  max-height: 40vh;
}

.compare-items textarea {
  width: 100%;
  height: 100%;
  /* min-height: 20vh; */
  /* max-height: 50vh; */
  /* border: none; */
  /* resize: none; */
}

.compare-item-title {
  flex: 1;
  cursor: pointer;
  transition: all 0.3s ease;
}

.compare-item h6 {
  margin: 0 0 10px 0 !important;
}

.compare-item.selected {
  border-color: #51c2b8;
  background-color: #f8fffd;
}

.compare-item.selected h6 {
  margin: 0 0 10px 0 !important;
}

.keyword-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.keyword-chip {
  background-color: #f0f0f0;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
}

.popup-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.apply-btn {
  background-color: #51c2b8;
  color: white;
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-btn {
  background-color: #f0f0f0;
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup-header span {
  font-size: 20px;
  font-weight: 800;
}

.close-button {
  min-width: 40px;
  width: auto;
  text-align: center;
  background-color: rgb(236, 236, 236);
  box-shadow: 0 2px 2px 1px rgba(184, 184, 184, 0.3);
  font-size: 15px !important;
  font-weight: 600;
}

.content-titles {
  display: flex;
  gap: 20px;
  padding: 15px;
  background-color: #d8d8d8;
  border-radius: 8px;
}

.title-item {
  flex: 1;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.title-item h5 {
  margin: 0 0 0 0;
  color: #495057;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.title-item p {
  margin: 0;
  color: #212529;
  font-size: 14px;
  line-height: 1.5;
}

.section-title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 15px;
  padding-bottom: 8px;
  border-bottom: 2px solid #51c2b8;
}

.section-title h4 {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  color: #333;
}

.section-title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px; /* 밑줄 길이 */
  height: 2px;
  background-color: #51c2b8; /* 테마 컬러와 동일하게 */
}

.icon {
  font-size: 20px;
}

.compare-section {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.compare-content {
  margin-top: 10px;
  overflow-y: auto;
  /* max-height: 600px; */
}

.edit-textarea {
  width: 100%;
  height: 80px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  min-height: 50vh;
}
</style>
