<script setup lang="ts">
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle
} from "radix-vue";
import { ref, watch } from "vue";
import CloseIcon from "@fluentui/svg-icons/icons/dismiss_16_filled.svg";
import { Channel } from "../../api/types";
import { useQuasar } from "quasar";
// import { goToChannel } from "../../api/backend";
// import { Channel } from "diagnostics_channel";

const $q = useQuasar();

interface Props {
  pClosePop: Function;
  channel: Channel;
  followThisChannel: Function;
  kindOf: string;
  pTitle: string;
  checkChatPw?: Function;
  password?: string;
  index?: number;
  goToChatting?: Function;
  pOpen?: Function;
  channelKey?: number;
  goToWebPage?: Function;
  goToB2bPage?: Function;
}
const props = defineProps<Props>();

const emit = defineEmits(["update:password"]); // 부모에게 보낼 이벤트 정의
// props의 password 값이 변경될 경우 반영
const passwordValue = ref(props.password); // props로 받은 password 값을 반응형으로 처리

watch(
  () => props.password,
  (newValue) => {
    passwordValue.value = newValue;
    console.log("passwordValue.value", passwordValue.value);
  }
);

// 입력값이 변경될 때마다 부모에게 emit
const emitPassword = () => {
  emit("update:password", passwordValue.value);
};

// const channel = ref<Channel>(props.channel);
// const password = ref(props.password);
const index = ref(props.index);

// const password = props.password;
// const mokeUp = ref("4216");
// const mokeUp = ref(channel.secure_str4);
// const channelPw = ref(channel.pw);
const isOpen = ref(true); // 다이얼로그의 열림 상태를 관리할 ref

const clickBtn = (channel: Channel) => {
  if (props.kindOf == "채팅") {
    // props.checkChatPw(channel.channel_key);
    props.checkChatPw(props.channelKey);
    isOpen.value = false; // 팝업을 닫음
    console.log("더미 비밀번호 입니다.");
  } else if (props.kindOf == "구독") {
    gotoChattingRoom(channel);
  } else if (props.kindOf == "웹수집") {
    props.goToWebPage();
    props.pClosePop();
  } else if (props.kindOf == "B2b") {
    props.goToB2bPage();
    props.pClosePop();
  }
};

const gotoChattingRoom = (channel: Channel) => {
  if (passwordValue.value == "") {
    $q.notify({
      message: "비밀번호를 입력하세요.",
      color: "negative",
      timeout: 3000
    });
  } else {
    props.followThisChannel(channel, index.value);
  }
  // console.log("store.currentChannel;", store.currentChannel);
  // console.log("channel", channel);
  // followChannel();
  // props.followThisChannel(channel, index.value);
  // goToChannel(password.value);
  // if () {
  //   props.goToChatting(channel);
  //   $q.notify({ message: "채팅방으로 이동합니다.", color: "positive" });
  //   isOpen.value = false; // 다이얼로그 닫기
  //   sidebarContext?.toggleEnabled();
  // } else
  //   $q.notify({ message: "비밀번호가 올바르지 않습니다.", color: "negative" });
};
</script>

<template>
  <!-- <DialogRoot> -->
  <DialogOverlay class="dialog-overlay" />
  <DialogPortal>
    <DialogOverlay class="DialogOverlay" />
    <DialogContent class="DialogContent" :disableOutsidePointerEvents="false">
      <DialogTitle class="DialogTitle">
        {{ props.channel.name }}
        {{ props.pTitle }}</DialogTitle
      >
      <DialogDescription class="DialogDescription">
        채팅방 비밀번호를 입력해주세요.
      </DialogDescription>
      <fieldset class="Fieldset">
        <label class="Label" for="name"> 비밀번호 </label>
        <input
          type="password"
          id="name"
          class="Input"
          placeholder="채팅방 비밀번호"
          v-model="passwordValue"
          @input="emitPassword"
        />
      </fieldset>

      <div
        :style="{
          display: 'flex',
          marginTop: 25,
          justifyContent: 'flex-end'
        }"
      >
        <button :value="index" class="Button" @click="clickBtn(props.channel)">
          확인
        </button>
        <!-- <button>{{ channel.lc_uuid }}</button> -->
        <!-- <DialogClose @click="props.pClosePop()" as-child> -->
        <!-- <button class="Button" @click="onChannelUpdate">확인</button> -->
        <!-- </DialogClose> -->
        <DialogClose @click="props.pClosePop()" class="Button">
          취소
        </DialogClose>
      </div>
      <DialogClose
        @click="props.pClosePop()"
        class="IconButton"
        aria-label="Close"
      >
        <CloseIcon />
      </DialogClose>
    </DialogContent>
  </DialogPortal>
  <!-- </DialogRoot> -->
</template>

<style scoped lang="scss">
@use "../../styles/dialog";
</style>
<style scoped>
.dialog-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.2);
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 999;
}
</style>
