import { useAsyncState } from "@vueuse/core";

// React like
// https://react.dev/reference/react/useTransition
export function useTransition() {
  const { isLoading, execute } = useAsyncState(
    async (callback: () => Promise<void>) => {
      await callback();
    },
    undefined,
    {
      immediate: false
    }
  );
  const startTransition = (callback: () => Promise<void>) =>
    execute(0, callback);

  return [isLoading, startTransition] as const;
}

let fakeKey = 0;
export function createFakeKey() {
  return --fakeKey;
}

export function createDateString() {
  const now = new Date();
  return now.toISOString();
}
