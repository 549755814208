import { createRouter, createWebHistory } from "vue-router";
import channelManagement from "../components/root/channelManagement/ChannelManagement.vue";
import myPage from "../components/root/myPage/MyPage.vue";
import MainPage from "../pages/main/MainPage.vue";
import WebSocketPage from "../components/root/WebSocketPage.vue";
import SampleAnytalkAsk from "../components/root/SampleAnytalkAsk.vue";
import IframePage from "../components/root/iframePage.vue";
import NamSeoulUniv from "../components/root/NamSeoulUniv.vue";
import aiDocnet from "../components/root/Aidocent.vue";
import aiDocnet from "../components/root/Aidocent.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),

  routes: [
    {
      path: "/",
      name: "main",
      // component: MainPage
      component: () => import("../pages/homepage/HomePage.vue")
    },
    {
      path: "/talk",
      name: "talk",
      // component: () => import("../pages/saeum/SaeumPage.vue")
      component: MainPage
    },
    {
      path: "/talk/channel",
      name: "channel",
      component: () => import("../pages/channel/ChannelPage.vue")
    },
    // {
    //   path: "/talk/channel/:chKey",
    //   name: "channel",
    //   component: () => import("../pages/channel/ChannelPage.vue")
    // }

    {
      path: "/talk/manage/:chankey",
      name: "channelManage",
      component: channelManagement
    },
    {
      path: "/talk/my",
      name: "mypage",
      component: myPage
    },
    {
      path: "/talk/test",
      name: "test",
      component: () => import("../components/root/testPage.vue")
    },
    {
      path: "/talk/folder",
      name: "folder",
      component: () => import("../components/root/folder.vue")
    },
    // {
    //   path: "/attach",
    //   name: "attach",
    //   component: () => import("../components/root/folder.vue")
    // },
    {
      path: "/talk/webpage",
      name: "webpage",
      component: () =>
        import("../components/root/webCollection/WebCollectionMain.vue")
    },
    {
      path: "/talk/kmspage/:channelkey",
      name: "kmspage",
      component: () =>
        import("../components/root/webCollection/KmsPageMain.vue")
    },
    {
      path: "/talk/kmsrepofile",
      name: "KmsRepoFile",
      component: () =>
        import("../components/root/webCollection/KmsRepoFile.vue")
    },
    {
      path: "/talk/kmsrepolink",
      name: "KmsRepoLink",
      component: () =>
        import("../components/root/webCollection/KmsRepoLink.vue")
    },
    {
      path: "/talk/kmsrepoimage",
      name: "KmsRepoImage",
      component: () =>
        import("../components/root/webCollection/KmsRepoImage.vue")
    },
    {
      path: "/talk/websocket",
      name: "websocket",
      component: WebSocketPage
    },
    {
      path: "/talk/test-page",
      name: "SampleAnytalkAsk",
      component: SampleAnytalkAsk // JSP 파일을 iframe으로` 보여주는 컴포넌트
    },
    // {
    //   path: "/iframe",
    //   name: "iframePage",
    //   component: IframePage
    // },
    {
      path: "/nsu.ac.kr",
      name: "NamSeoulUniv",
      component: NamSeoulUniv // JSP 파일을 iframe으로` 보여주는 컴포넌트
    },
    {
      path: "/aidocent",
      name: "AIDocent",
      component: aiDocnet
    },
    // {
    //   path: "/talk/aiChatbot",
    //   name: "NamseoulUnivAiChatbot",
    //   redirect: () => {
    //     // 리다이렉트 URL 반환
    //     return "/talk/channel?chKey=244";
    //   }
    // },
    {
      path: "/nsuAiChat",
      name: "NamseoulUnivAiChatbot",
      redirect: () => {
        // 리다이렉트 URL 반환
        return "/talk/channel?chKey=250";
      }
    },
    {
      path: "/jungnangAiChat",
      name: "JungnangAiChat",
      redirect: () => {
        // 리다이렉트 URL 반환
        return "/talk/channel?chKey=353";
      }
    },
    {
      path: "/dosiAiChat",
      name: "dosiAiChat",
      redirect: () => {
        // 리다이렉트 URL 반환
        return "/talk/channel?chKey=326";
      }
    },
    {
      path: "/sarangAiChat",
      name: "sarangAiChat",
      redirect: () => {
        // 리다이렉트 URL 반환
        return "/talk/channel?chKey=331";
      }
    },
    {
      path: "/aiChatbot",
      // path: "/aiChatbot",
      name: "NamseoulUnuivAiChatbot",
      component: MainPage
    },
    {
      path: "/homepageTest",
      name: "homepageTest",
      component: () => import("../components/root/homepageTest.vue")
    },
    {
      path: "/aiOrch",
      name: "AiOrchastrator",
      component: () => import("../components/root/aiOrchastrator/AiOrchast.vue")
    },
    {
      path: "/channelManage",
      name: "ChannelManage",
      component: () =>
        import("../components/root/channelManagement/ChannelManagement.vue")
    }
    // {
    //   path: "/login",
    //   name: "login",
    //   // route level code-splitting
    //   // this generates a separate chunk (About.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import("../pages/login/LoginPage.vue")
    // }
  ]
});

export default router;
