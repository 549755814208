import { ref } from "vue";
import { getMsgTts } from "../../api/backend";
import type {
  SpeechRecognition,
  SpeechRecognitionErrorEvent
} from "../../types/SpeechRecognition";
import { arrayBuffer } from "stream/consumers";

export enum MicStatus {
  NeedInit = "need init",
  Inactive = "inactive",
  ActiveButNotReady = "active but not ready",
  Active = "active"
}

//현재 사용중인 STT
export function useSpeechRecognition() {
  const micStatus = ref<MicStatus>(MicStatus.NeedInit); // Use the enum for microphone status
  let recognition: SpeechRecognition | null = null;

  const initRecognition = async (
    lang: string,
    handler?: () => void | Promise<void>
  ) => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      recognition = new SpeechRecognition();

      if (!recognition) {
        throw new Error("SpeechRecognition is not supported in this browser.");
      }

      recognition.lang = lang;
      micStatus.value = MicStatus.Inactive;
    } else {
      throw new Error("SpeechRecognition is not supported in this browser.");
    }

    await handler?.();
  };

  const startRecognition = async (handlers?: {
    onResult?: (result: string) => void;
    onError?: (event: SpeechRecognitionErrorEvent) => void;
    onEnd?: () => void; // 종료 시 호출할 콜백 추가
    onSoundEnd?: () => void; // 소리 종료 시 호출할 콜백 추가
  }) => {
    if (recognition) {
      // https://developer.mozilla.org/en-US/docs/Web/API/SpeechRecognition
      // onsoundstart onspeechstart 이벤트는 늦음
      recognition.onaudiostart = () => {
        micStatus.value = MicStatus.Active;
        console.log("음성인식 준비 완료 ");
      };

      // 음성 인식이 종료될 때 호출되는 이벤트
      recognition.onend = () => {
        console.log("음성 인식 종료");
        micStatus.value = MicStatus.Inactive;
        handlers?.onEnd?.(); // 종료 콜백 호출
      };

      // 음성이 끝났을 때 호출되는 이벤트
      recognition.onsoundend = () => {
        console.log("소리 감지 종료 - 마이크 자동 종료");
        stopRecognition();
        handlers?.onSoundEnd?.(); // 소리 종료 콜백 호출
      };

      recognition.onresult = (e) => {
        const result = e.results[0][0].transcript;
        console.log("음성인식 완료:", result);

        stopRecognition();
        handlers?.onResult?.(result);
      };
      recognition.onnomatch = async (e) => {
        console.log("음성인식 불가", e);
        stopRecognition();
        await startRecognition(handlers);
      };

      recognition.onerror = (event) => {
        console.error("음성 인식 오류:", event.error);
        handlers?.onError?.(event);
      };

      // 음성 인식 시작
      recognition.start();
    }
  };

  const stopRecognition = () => {
    if (recognition) {
      recognition.stop();
      recognition = null;
      micStatus.value = MicStatus.Inactive;
    }
  };

  return {
    micStatus,
    initRecognition,
    startRecognition,
    stopRecognition
  };
}

//현재 사용중인 TTS
export function useTTS() {
  const audioContext = ref<AudioContext>();
  let currentAudioBufferSourceNode: AudioBufferSourceNode | null = null; // 현재 재생 중인 AudioBufferSourceNode를 참조하기 위한 변수

  const initTTS = async () => {
    if (audioContext.value === undefined) {
      audioContext.value = new AudioContext();
      await audioContext.value.resume();
    }
  };

  // const runTTS = async (text: string, handler?: () => void) => {
  //   if (audioContext.value) {
  //     const audioArrayBuffer = await getMsgTts(text);

  //     if (audioArrayBuffer) {
  //       const decodedAudioBuffer =
  //         await audioContext.value.decodeAudioData(audioArrayBuffer);

  //       currentAudioBufferSourceNode = audioContext.value.createBufferSource();
  //       currentAudioBufferSourceNode.buffer = decodedAudioBuffer;

  //       if (handler) {
  //         const runHandler = () => {
  //           currentAudioBufferSourceNode?.removeEventListener(
  //             "ended",
  //             runHandler
  //           );
  //           handler?.();
  //           currentAudioBufferSourceNode = null; // 핸들러가 실행된 후에는 노드를 초기화
  //         };
  //         currentAudioBufferSourceNode.addEventListener("ended", runHandler);
  //       }

  //       currentAudioBufferSourceNode.connect(audioContext.value.destination);
  //       currentAudioBufferSourceNode.start();
  //       return audioArrayBuffer;
  //     }
  //   }
  // };
  const runTTS = async (
    text: string,
    handler?: () => void
  ): Promise<number> => {
    if (audioContext.value) {
      const audioArrayBuffer = await getMsgTts(text);

      if (audioArrayBuffer) {
        const decodedAudioBuffer =
          await audioContext.value.decodeAudioData(audioArrayBuffer);

        currentAudioBufferSourceNode = audioContext.value.createBufferSource();
        currentAudioBufferSourceNode.buffer = decodedAudioBuffer;

        if (handler) {
          const runHandler = () => {
            currentAudioBufferSourceNode?.removeEventListener(
              "ended",
              runHandler
            );
            handler?.();
            currentAudioBufferSourceNode = null;
          };
          currentAudioBufferSourceNode.addEventListener("ended", runHandler);
        }

        currentAudioBufferSourceNode.connect(audioContext.value.destination);
        currentAudioBufferSourceNode.start();

        // TTS 길이 반환
        return decodedAudioBuffer.duration;
      }
    }
    return 0; // 실패 시 0 반환
  };

  const stopTTS = () => {
    console.log("TTS 중단");
    if (currentAudioBufferSourceNode) {
      currentAudioBufferSourceNode.stop(); // 현재 재생 중인 TTS를 중단
      currentAudioBufferSourceNode.disconnect(); // 연결 해제
      currentAudioBufferSourceNode = null; // 노드를 초기화
    }
    audioContext.value?.close(); // AudioContext 종료
    audioContext.value = undefined; // AudioContext 초기화
  };

  return {
    initTTS,
    runTTS,
    stopTTS
  };
}

//테스트용 STT/TTS로직
// export function useAudioRecorder() {
//   const transcript = ref("");
//   const micStatus = ref<MicStatus>(MicStatus.NeedInit); // Use the enum for microphone status
//   let mediaRecorder: SingletonMediaRecorder;

//   let cropedAudioBlob: Blob | null = null;

//   const initRecording = async () => {
//     mediaRecorder = await SingletonMediaRecorder.getInstance();
//     micStatus.value = MicStatus.Inactive;
//   };

//   const startRecording = () => {
//     transcript.value = "";
//     micStatus.value = MicStatus.ActiveButNotReady;

//     mediaRecorder.startRecording(() => {
//       micStatus.value = MicStatus.Active;
//     });
//   };

//   const startSpeachRecording = async () => {
//     const data = await mediaRecorder.getAudioData();
//     console.log(data);

//     const newData = await extractLastSecond(data);
//     cropedAudioBlob = newData;
//   };

//   const stopRecording = async (lang: string) => {
//     const audioBlob = await mediaRecorder.getAudioData();

//     const concatBlob =
//       cropedAudioBlob === null
//         ? audioBlob
//         : await concatenateAudioBlobs(cropedAudioBlob, audioBlob);

//     cropedAudioBlob = null;

//     mediaRecorder.stopRecording(() => {
//       micStatus.value = MicStatus.Inactive;
//     });
//     const result =
//       concatBlob === undefined
//         ? ""
//         : await convertSpeechToText(lang, [concatBlob]);

//     micStatus.value = MicStatus.Inactive;
//     transcript.value = result;
//     return result;
//   };

//   onUnmounted(() => {
//     mediaRecorder?.distroy();
//   });

//   return {
//     transcript,
//     micStatus,
//     initRecording,
//     startRecording,
//     startSpeachRecording,
//     stopRecording,
//     MicStatus
//   };
// }
