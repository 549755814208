<script setup lang="ts">
import { ref, watch } from "vue";

/* 컴포넌트 */
import CommonPopLayer from "./CommonPopLayer.vue";
import EachAlim from "./components/EachAlim.vue";

const alimList = ref([
  { id: 1, title: "새로운 질문이 도착했습니다.", cre_date: "2024-06-23" },
  { id: 2, title: "새로운 질문이 도착했습니다.", cre_date: "2024-06-23" },
  { id: 3, title: "새로운 질문이 도착했습니다.", cre_date: "2024-06-23" },
  { id: 4, title: "새로운 질문이 도착했습니다.", cre_date: "2024-06-23" }
]);

const tabs = ref(["전체", "답변수신", "멤버신청", "답변필요", "지식검토"]);

const popTitle = "알림 리스트";

const nowTab = ref(0);
const changeTab = (index: number) => {
  nowTab.value = index;
};

watch(
  () => nowTab.value,
  (newVal) => {
    if (newVal == 0) {
      alimList.value = alimList.value;
    } else if (newVal == 1) {
      alimList.value = alimList.value;
    } else if (newVal == 2) {
      alimList.value = alimList.value;
    } else if (newVal == 3) {
      alimList.value = alimList.value;
    } else if (newVal == 4) {
      alimList.value = alimList.value;
    }
    // alimList.value = tabData[newVal];
  }
);
</script>

<template>
  <CommonPopLayer :pPopTitle="popTitle">
    <template #body>
      <div class="alimWrap">
        <div class="alimTab flexAlign">
          <div
            @click="changeTab(index)"
            :class="{ selected: nowTab === index }"
            class="eachTab cursorP"
            v-for="(tab, index) in tabs"
            :key="tab"
          >
            {{ tab }}
          </div>
        </div>
        <ul class="alimContents flexAlign">
          <EachAlim :pAlimList="alimList" />
        </ul>
      </div>
    </template>
    <template #actions>
      <button type="button" style="display: none" />
    </template>
  </CommonPopLayer>
</template>

<style lang="scss" scoped>
.alimWrap {
  & .alimTab {
    justify-content: start;
    text-align: center;

    & .eachTab {
      width: 20%;
      padding: 15px 0 10px;
      border-bottom: 1px solid gray;

      &.selected {
        font-weight: bold;
        border-bottom: 2px solid gray;
      }
    }
  }
  & .alimContents {
    gap: 10px;
    flex-direction: column;
    padding-top: 10px;
  }
}
</style>
