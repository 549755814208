<!-- KmsPageMain.vue -->
<!-- kmsmpage -->
<script setup lang="ts">
import { ref, computed, onMounted, watch, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "../../../stores/store";
import { users } from "../../../mokUpData/atKmsMockData";
import {
  // 독 업데이트
  // deleteKmsDoc,
  // confirmKmsKnow,
  deleteKmsKnow,
  confirmKmsKnow2,
  aiRefineChannel,
  getKmsKnowList2,
  // 독 업데이트
  // updateKmsDoc,
  updateKmsFolder,
  updateKmsKnow,
  moveKmsFolder,
  requestPdfToKnow
} from "../../../api/backend";
import {
  PaginationEllipsis,
  PaginationFirst,
  PaginationLast,
  PaginationList,
  PaginationListItem,
  PaginationNext,
  PaginationPrev,
  PaginationRoot
} from "radix-vue";
import { Icon } from "@iconify/vue";
import { usePopupStore } from "../../../stores/popups";
// 아이콘
import CloseIcon from "@fluentui/svg-icons/icons/dismiss_24_regular.svg";
import ImageIcon from "@fluentui/svg-icons/icons/image_16_regular.svg";
import Link from "@fluentui/svg-icons/icons/link_multiple_16_regular.svg";
//import File from "@fluentui/svg-icons/icons/copy_16_regular.svg";
//import File from "@fluentui/svg-icons/icons/document_link_16_regular.svg";
import File from "@fluentui/svg-icons/icons/folder_link_16_regular.svg";
// 독 업데이트
// import TextEdit from "@fluentui/svg-icons/icons/slide_text_edit_20_regular.svg";
// import SettingIcon from "@fluentui/svg-icons/icons/settings_16_regular.svg";
// import Delete from "@fluentui/svg-icons/icons/delete_20_regular.svg";
// import Document from "@fluentui/svg-icons/icons/document_20_regular.svg";
import KnowList from "@fluentui/svg-icons/icons/document_bullet_list_20_regular.svg";
import KMSFolderIcon from "@fluentui/svg-icons/icons/folder_20_regular.svg";
// 독 업데이트
// import KMSDocIcon from "@fluentui/svg-icons/icons/document_data_16_regular.svg";
// import Search from "@fluentui/svg-icons/icons/search_20_regular.svg";

// import KmsViewPage from "./KmsViewPage.vue";
import CreateKmsDoc from "./CreateKmsDoc.vue"; // 지식 추가 컴포넌트 가져오기
import KmsKnowItemAdd from "./KmsKnowItemAdd.vue"; // 개별 지식 추가 컴포넌트 가져오기
import FolderTreeItem from "./FolderTreeItem.vue";
import SearchPopup from "./KmsSearchPop.vue";

import MoveKmsPop from "./MoveKmsPop.vue";

import { KnowItem } from "../../../mokUpData/atKmsData";
import { KmsKnow, RepoLink, ResKmsKnow } from "../../../api/types";
import { KmsFolder } from "../../../api/types";
import { useQuasar } from "quasar";
import { watchEffect } from "vue";
import { ReqKmsChannelKnow } from "../../../api/backend";
// 독 업데이트
// import { moveKmsDoc } from "../../../api/backend";
import { moveKmsKnow } from "../../../api/backend";
import KmsDocDetail from "./KmsDocDetail.vue";
import KmsViewPage from "./KmsViewPage.vue";

interface FolderData {
  kms_folder_key: number;
  kms_folder_group_key?: number | null; // Allow null here
  parent_folder_key: number | null;
  folder_name: string;
  channel_key: number;
}

// store 사용
const kmsStore = useStore();

const router = useRouter();
const popStore = usePopupStore();
const $q = useQuasar();

// 팝업 작동
// const isPopupOpen = ref(true);

// const goBack = () => {
//   console.log("goBack 실행됨");
//   isPopupOpen.value = false; // 팝업 닫기
// };

const goBack = () => {
  // kmsStore.loadFolders(null);
  router.push("/talk");
};

// const channelKey = ref(225); // 채널 키는 적절히 설정
// const channelKey = ref(kmsStore.currentChannel?.channel_key); // 채널 키는 적절히 설정

const route = useRoute();
// const channelKey = ref(
//   route.params.channelkey || null
// );

// ============================== 팝업 전용
const channelKey = ref(
  route.params.channelkey || kmsStore.currentChannel?.channel_key
);

const isGenerating = ref(false);

// 우선순위 관련 상태
const isPriorityMode = ref(false);
const selectedKnowsForPriority = ref(new Set());
const showPriorityPopup = ref(false);
const selectedPriority = ref(null);

// 폴더 추가 관련 상태만 로컬로 관리
const showFolderAddPopup = ref(false);
const showFolderEditPopup = ref(false);
const searchQuery = ref("");
const showPopup = ref(false);
const folderName = ref("");
const isSubFolder = ref(false); // 자식 폴더 여부를 저장할 상태 추가
const selectedParentFolder = ref(null); // 선택된 부모 폴더를 저장할 상태 추가
// 독 업데이트
// const docName = ref("");
// 독 업데이트
// const showDocEditPopup = ref(false);
const folderInputRef = ref(null);
const folderLocation = ref("root");
const selectedSearchOption = ref("elasticKnow");

// 검색 옵션
const searchOptions = ref([
  { name: "Elastic 지식", value: "elasticKnow" },
  { name: "KMS 지식", value: "kmsKnow" }
]);

const isItemSelected = (item: KmsKnow) => {
  return selectedItems.value.includes(item);
};

const isFolderSelected = computed(() => {
  return kmsStore.selectedFolderKey !== null;
});

// 폴더 변경 시 선택 상태
const isMultiSelect = ref(false);
// selectedFolders의 타입 정의를 수정
const selectedFolders = ref<Set<number>>(new Set());

// 이동 모드 상태 관리
const isMoveMode = ref(false);
const selectedFoldersForMove = ref(new Set());

// computed 속성 추가
const selectedFoldersCount = computed(() => selectedFolders.value?.size || 0);
const shouldShowMoveButton = computed(
  () => isMoveMode.value && selectedFoldersCount.value > 0
);

const getPriorityText = (validYn) => {
  switch (validYn) {
    case 9:
      return "상";
    case 1:
      return "중";
    case 0:
      return "하";
    default:
      return "-";
  }
};

// 이동 모드 토글 함수
const toggleMoveMode = () => {
  isMoveMode.value = !isMoveMode.value;
  isMultiSelect.value = isMoveMode.value;

  // 모드 전환 시 상태 초기화
  if (!isMoveMode.value) {
    selectedFolders.value = new Set();
    showMovePopup.value = false;
    targetFolderKey.value = null;
  }
};

// 우선순위 토글 함수
const togglePriorityMode = () => {
  isPriorityMode.value = !isPriorityMode.value;
  if (!isPriorityMode.value) {
    selectedKnowsForPriority.value.clear();
    showPriorityPopup.value = false;
  }
};

// 우선순위 팝업 열기
const openPriorityPopup = () => {
  if (selectedKnowsForPriority.value.size === 0) {
    $q.notify({
      message: "우선순위를 설정할 지식을 선택해주세요.",
      color: "negative"
    });
    return;
  }
  showPriorityPopup.value = true;
};

// 우선순위 팝업 닫기
const closePriorityPopup = () => {
  showPriorityPopup.value = false;
  selectedPriority.value = null;
  isPriorityMode.value = false;
  selectedKnowsForPriority.value.clear();
};

// 지식 선택 핸들러
const handleKnowSelect = ({
  knowKey,
  selected
}: {
  knowKey: number;
  selected: boolean;
}) => {
  if (selected) {
    selectedKnowsForPriority.value.add(knowKey);
  } else {
    selectedKnowsForPriority.value.delete(knowKey);
  }
};

// 우선순위 매핑 상수 추가
const PRIORITY_VALUES = {
  high: 9,
  medium: 1,
  low: 0
};

// 우선순위 저장 처리
const handlePrioritySave = async () => {
  if (!selectedPriority.value) {
    $q.notify({
      message: "우선순위를 선택해주세요.",
      color: "negative"
    });
    return;
  }

  // const updatedData = {
  //   kms_know_key: props.knowViewItem.know.kms_know_key,
  //   valid_yn: 1
  // };

  try {
    // const result = await updateKmsKnow(updatedData);
    for (const knowKey of selectedKnowsForPriority.value) {
      const updatedData = {
        kms_know_key: knowKey,
        valid_yn: PRIORITY_VALUES[selectedPriority.value],
        refine_yn: 1
      };

      // API 호출
      await updateKmsKnow(updatedData);
    }

    $q.notify({
      message: "우선순위가 설정되었습니다.",
      color: "positive"
    });
    closePriorityPopup();

    // 독 업데이트
    // 지식 목록 새로고침
    // if (kmsStore.selectedDocKey) {
    //   await kmsStore.loadKnows(
    //     Number(channelKey.value),
    //     kmsStore.selectedDocKey,
    //     1
    //   );
    // }
    if (kmsStore.selectedFolderKey) {
      await kmsStore.loadKnows(
        Number(channelKey.value),
        kmsStore.selectedFolderKey,
        1
      );
    }
  } catch (error) {
    console.error("우선순위 설정 실패:", error);
    $q.notify({
      message: "우선순위 설정 중 오류가 발생했습니다.",
      color: "negative"
    });
  }
};

const selectedFoldersArray = computed(() =>
  Array.from(selectedFolders.value || new Set())
);

// 폴더 이름을 가져오는 helper 함수
const getFolderName = (folderKey: number) => {
  return (
    kmsStore.folders.find((f) => f.kms_folder_key === folderKey)?.folder_name ||
    ""
  );
};

// 이동 팝업 상태 관리
const showMovePopup = ref(false);
const targetFolderGroupKey = ref(null);
const targetFolderKey = ref<number | null>(null);

// 이동 팝업 열기
const openMovePopup = () => {
  if (selectedFolders.value.size === 0) {
    $q.notify({
      message: "이동할 폴더를 선택해주세요.",
      color: "negative"
    });
    return;
  }
  showMovePopup.value = true;
};

// 이동 팝업 닫기
const closeMovePopup = () => {
  showMovePopup.value = false;
  targetFolderKey.value = null;
  targetFolderGroupKey.value = null;
  // isMoveMode.value = false; // 이동 모드도 함께 종료
  // isMultiSelect.value = false; // 다중 선택 모드도 종료
  // selectedFolders.value.clear(); // 선택된 폴더들 초기화
};

// 폴더 이동 처리
const handleFolderMove = async () => {
  try {
    // 선택된 각 폴더에 대해 이동 처리
    for (const folderKey of selectedFolders.value) {
      // 현재 폴더 찾기
      const folderToMove = kmsStore.folders.find(
        (folder) => folder.kms_folder_key === folderKey
      );

      if (!folderToMove) continue;

      // 이동할 데이터 준비
      // const updateData = {
      //   kms_folder_key: folderKey,
      //   // kms_folder_group_key: kmsStore.selectedFolderGroupKey, // 새 폴더 그룹 키
      //   kms_folder_group_key: targetFolderGroupKey.value, // 선택된 새 폴더 그룹 키
      //   parent_folder_key: targetFolderKey.value, // 부모 폴더 키
      //   folder_name: folderToMove.folder_name,
      //   channel_key: folderToMove.channel_key // 채널 키 유지
      // };

      // API 호출하여 폴더 업데이트
      // const updatedFolder = await updateKmsFolder(updateData);
      // const updatedFolder = await moveKmsFolder(
      //   folderKey,
      //   targetFolderKey.value
      // );

      // if (updatedFolder == null) {
      //   // UI 업데이트
      //   const folderIndex = kmsStore.folders.findIndex(
      //     (folder) => folder.kms_folder_key === folderKey
      //   );

      //   if (folderIndex !== -1) {
      //     kmsStore.folders[folderIndex] = {
      //       ...kmsStore.folders[folderIndex],
      //       kms_folder_group_key: updateData.kms_folder_group_key,
      //       parent_folder_key: updateData.parent_folder_key
      //     };
      //   }
      // } else {
      //   // 실패 시 에러 처리
      //   $q.notify({
      //     message: "폴더 이동 중 오류가 발생했습니다.",
      //     color: "negative"
      //   });
      //   throw new Error(`폴더 ${folderKey} 이동 실패`);
      // }
      const updatedFolder = await moveKmsFolder(
        folderKey,
        targetFolderKey.value
      );

      if (updatedFolder) {
        // UI 업데이트
        const folderIndex = kmsStore.folders.findIndex(
          (folder) => folder.kms_folder_key === folderKey
        );

        if (folderIndex !== -1) {
          kmsStore.folders[folderIndex] = {
            ...kmsStore.folders[folderIndex],
            kms_folder_group_key: updatedFolder.kms_folder_group_key,
            parent_folder_key: updatedFolder.parent_folder_key
          };
        } else {
          // 실패 시 에러 처리
          $q.notify({
            message: "폴더 이동 중 오류가 발생했습니다.",
            color: "negative"
          });
          throw new Error(`폴더 ${folderKey} 이동 실패`);
        }
      }
    }

    // 이동 완료 후 상태 초기화 및 팝업 닫기
    showMovePopup.value = false;
    targetFolderKey.value = null;
    targetFolderGroupKey.value = null;
    isMoveMode.value = false;
    isMultiSelect.value = false;
    selectedFolders.value.clear();

    $q.notify({
      message: "폴더 이동이 완료되었습니다.",
      color: "positive"
    });

    // 폴더 그룹 다시 로드
    if (kmsStore.currentChannel?.channel_key) {
      await kmsStore.loadFolderGroups(kmsStore.currentChannel.channel_key);
      // 현재 선택된 폴더 그룹의 폴더들도 다시 로드
      if (kmsStore.selectedFolderGroupKey) {
        await kmsStore.loadFolders(kmsStore.selectedFolderGroupKey);
      }
    }

    // 이동 완료 후 상태 초기화
    closeMovePopup();
    isMoveMode.value = false; // 이동 모드도 함께 종료
    isMultiSelect.value = false; // 다중 선택 모드도 종료
    selectedFolders.value.clear(); // 선택된 폴더들 초기화
    $q.notify({
      message: "폴더 이동이 완료되었습니다.",
      color: "positive"
    });
    console.log(updateKmsFolder);
    // 폴더 그룹 다시 로드
    // if (kmsStore.currentChannel?.channel_key) {
    //   await kmsStore.loadFolderGroups(kmsStore.currentChannel.channel_key);
    // }
  } catch (error) {
    console.error("폴더 이동 실패:", error);
    $q.notify({
      message: "폴더 이동 중 오류가 발생했습니다.",
      color: "negative"
    });
  }
};

// 대상 폴더 선택 핸들러
const selectTargetFolder = ({ folderKey, selected }) => {
  targetFolderKey.value = selected ? folderKey : null;
};

// isValidMove computed 속성 수정
const isValidMove = computed(() => {
  return (
    targetFolderGroupKey.value &&
    (targetFolderKey.value === "0" ||
      kmsStore.folders.some(
        (f) =>
          f.kms_folder_key === targetFolderKey.value &&
          !selectedFolders.value.has(f.kms_folder_key)
      ))
  );
});

// 폴더 선택 모드 토글
const toggleSelectionMode = () => {
  isMultiSelect.value = !isMultiSelect.value;
  // 모드 변경 시 초기화
  selectedFolders.value.clear();
};

// 폴더 선택 핸들러
const handleFolderSelect = ({
  folderKey,
  selected
}: {
  folderKey: number;
  selected: boolean;
}) => {
  if (!selectedFolders.value) {
    selectedFolders.value = new Set();
  }

  if (selected) {
    selectedFolders.value.add(folderKey);
  } else {
    selectedFolders.value.delete(folderKey);
  }
};

// 선택된 폴더들의 상세 정보
const selectedFoldersDetails = computed(() => {
  return selectedFolders.value
    .map((key) => kmsStore.folders.find((f) => f.kms_folder_key === key))
    .filter(Boolean);
});

const filteredFolders = computed(() => {
  if (!targetFolderGroupKey.value) return [];

  return kmsStore.folders.filter(
    (folder) =>
      folder.kms_folder_group_key === targetFolderGroupKey.value &&
      !selectedFolders.value.has(folder.kms_folder_key) // 이미 선택된 폴더 제외
  );
});

// 필요한 ref 선언
// const targetFolderGroupKey = ref("");
// const selectedFolders = ref(new Set());
// const targetFolderKey = ref("");

// filteredFolderTrees를 로컬에서 재구현
const localFilteredFolderTrees = computed(() => {
  const rootFolders = kmsStore.filteredFolderTrees.filter(
    (item) => !selectedFolders.value.has(item.kms_folder_key)
  );
  console.log("선택된 폴더 키", selectedFolders.value);
  console.log("현재 폴더 리스트", rootFolders);
  return rootFolders;
});

// 재귀적으로 폴더 트리 구조 생성
const buildFolderTree = (folder: any) => {
  const children = kmsStore.folders.filter(
    (child) =>
      child.kms_parent_folder_key === folder.kms_folder_key &&
      !selectedFolders.value.has(child.kms_folder_key)
  );

  return {
    ...folder,
    children: children.map((child) => buildFolderTree(child))
  };
};

// 폴더 그룹 변경 시 핸들러
const handleFolderGroupChange = () => {
  targetFolderKey.value = null; // 타겟 폴더 선택 초기화
};

// 팝업용 새로운 이벤트 핸들러 추가
const handleTargetFolderGroupChange = () => {
  targetFolderKey.value = null; // 타겟 폴더 선택 초기화

  // 선택된 폴더 그룹에 해당하는 폴더들만 필터링
  const filteredFolders = kmsStore.folders.filter(
    (folder) =>
      folder.kms_folder_group_key === targetFolderGroupKey.value &&
      !selectedFolders.value.has(folder.kms_folder_key)
  );

  // 필요한 경우 추가 로직 구현
  console.log("Filtered folders for move:", filteredFolders);
};

// 문서 이동 관련 상태 추가
// 독 업데이트
// const isDocMoveMode = ref(false);
// 독 업데이트
// const selectedDocsForMove = ref(new Set<number>());
// 독 업데이트
// const showDocMovePopup = ref(false);
// 독 업데이트
// const targetDocFolderKey = ref<number | null>(null);
// const targetKnowFolderKey = ref<number | null>(null);

// 독 업데이트
// 문서 이동 모드 토글 함수
// const toggleDocMoveMode = () => {
//   isDocMoveMode.value = !isDocMoveMode.value;
//   if (!isDocMoveMode.value) {
//     selectedDocsForMove.value.clear();
//     showDocMovePopup.value = false;
//     targetDocFolderKey.value = null;
//   }
// };

// 독 업데이트
// 문서 선택 핸들러
// const handleDocSelect = ({
//   docKey,
//   selected
// }: {
//   docKey: number;
//   selected: boolean;
// }) => {
//   if (!selectedDocsForMove.value) {
//     selectedDocsForMove.value = new Set();
//   }

//   if (selected) {
//     selectedDocsForMove.value.add(docKey);
//   } else {
//     selectedDocsForMove.value.delete(docKey);
//   }
// };

// 독 업데이트
// 문서 이동 팝업 열기
// const openDocMovePopup = () => {
//   if (selectedDocsForMove.value.size === 0) {
//     $q.notify({
//       message: "이동할 문서를 선택해주세요.",
//       color: "negative"
//     });
//     return;
//   }
//   showDocMovePopup.value = true;
// };

// 독 업데이트
// 문서 이동 팝업 닫기
// const closeDocMovePopup = () => {
//   showDocMovePopup.value = false;
//   targetDocFolderKey.value = null;
//   isDocMoveMode.value = false;
//   selectedDocsForMove.value.clear();
// };

// 문서 이동 처리
// 독 업데이트
// const handleDocMove = async () => {
//   if (!targetDocFolderKey.value) {
//     $q.notify({
//       message: "이동할 폴더를 선택해주세요.",
//       color: "negative"
//     });
//     return;
//   }

//   try {
//     // 선택된 각 문서에 대해 이동 처리
//     for (const docKey of selectedDocsForMove.value) {
//       // 현재 문서 찾기
//       const docToMove = kmsStore.filteredDocs.find(
//         (doc) => doc.kms_doc_key === docKey
//       );

//       if (!docToMove) continue;

//       const updateData = {
//         kms_doc_key: docKey,
//         kms_folder_key: targetDocFolderKey.value,
//         doc_name: docToMove.doc_name
//       };

//       // UI 즉시 업데이트
//       const docIndex = kmsStore.filteredDocs.findIndex(
//         (doc) => doc.kms_doc_key === docKey
//       );

//       if (docIndex !== -1) {
//         kmsStore.filteredDocs[docIndex] = {
//           ...kmsStore.filteredDocs[docIndex],
//           kms_folder_key: targetDocFolderKey.value
//         };
//       }

//       // API 호출 (백엔드에 updateKmsDoc 메서드 필요)
//       // const response = await updateKmsDoc(updateData);
//       const response = await moveKmsDoc(docKey, targetDocFolderKey.value);

//       if (!response) {
//         // 실패 시 다시 로드
//         await kmsStore.loadDocs(kmsStore.selectedFolderKey);
//       }
//     }

//     // 이동 완료 후 상태 초기화
//     closeDocMovePopup();
//     $q.notify({
//       message: "문서 이동이 완료되었습니다.",
//       color: "positive"
//     });

//     // 문서 목록 다시 로드
//     if (kmsStore.selectedFolderKey) {
//       await kmsStore.loadDocs(kmsStore.selectedFolderKey);
//     }
//   } catch (error) {
//     console.error("문서 이동 실패:", error);
//     $q.notify({
//       message: "문서 이동 중 오류가 발생했습니다.",
//       color: "negative"
//     });
//   }
// };

// ====================================================== 지식 이동

// 지식 이동 관련 상태 추가
const isKnowMoveMode = ref(false);
const selectedKnowForMove = ref(new Set<number>());
const showKnowMovePopup = ref(false);
const targetKnowFolderKey = ref<number | null>(null);

// 지식 이동 모드 토글 함수
const toggleKnowMoveMode = () => {
  isKnowMoveMode.value = !isKnowMoveMode.value;
  if (!isKnowMoveMode.value) {
    selectedKnowForMove.value.clear();
    showKnowMovePopup.value = false;
    targetKnowFolderKey.value = null;
  }
};
const selectedMoveKnowKey = ref(0);

// 지식 선택 핸들러
const handleKmsKnowSelect = ({
  knowKey,
  selected
}: {
  knowKey: number;
  selected: boolean;
}) => {
  if (
    selected &&
    selectedKnowForMove.value.size > 0 &&
    !selectedKnowForMove.value.has(knowKey)
  ) {
    selectedKnowForMove.value.clear();
  }

  selectedMoveKnowKey.value = knowKey;
  console.log("selectedMoveKnowKey", selectedMoveKnowKey.value);
  if (selected) {
    selectedKnowForMove.value.add(knowKey);
  } else {
    selectedKnowForMove.value.delete(knowKey);
  }
};

// 지식 이동 팝업 열기
const openKnowMovePopup = () => {
  if (selectedKnowForMove.value.size === 0) {
    $q.notify({
      message: "이동할 지식를 선택해주세요.",
      color: "negative"
    });
    return;
  }
  showKnowMovePopup.value = true;
};

// 지식 이동 팝업 닫기
const closeKnowMovePopup = () => {
  showKnowMovePopup.value = false;
  targetKnowFolderKey.value = null;
  // 독 업데이트
  // isDocMoveMode.value = false;
  selectedKnowForMove.value.clear();
};

// 지식 이동 처리
const handleKnowMove = async () => {
  // 독 업데이트
  // if (!targetDocFolderKey.value) {
  if (!targetFolderKey.value) {
    $q.notify({
      message: "이동할 폴더를 선택해주세요.",
      color: "negative"
    });
    return;
  }

  try {
    // 선택된 각 지식에 대해 이동 처리
    for (const knowKey of selectedKnowForMove.value) {
      // 현재 지식 찾기
      // 독 업데이트
      // const docToMove = kmsStore.selectedDocKnows.find(
      //   (know) => know.know.kms_know_key === knowKey
      // );
      const folderToMove = kmsStore.selectedFolderKnows.find(
        (know) => know.know.kms_know_key === knowKey
      );

      // if (!docToMove) continue;

      // 독 업데이트
      // 기존 데이터를 유지하면서 kms_doc_key만 변경
      // const updateData = {
      //   ...docToMove.know, // 기존 데이터 유지
      //   kms_doc_key: targetDocFolderKey.value // kms_doc_key만 변경
      // };

      // 독 업데이트
      // UI 즉시 업데이트
      // const docIndex = kmsStore.selectedDocKnows.findIndex(
      //   (doc) => doc.kms_know_key === knowKey
      // );
      const knowIndex = kmsStore.selectedFolderKnows.findIndex(
        (know) => know.kms_know_key === knowKey
      );

      // 독 업데이트
      // if (docIndex !== -1) {
      //   kmsStore.selectedDocKnows[docIndex] = {
      //     ...kmsStore.selectedDocKnows[docIndex],
      //     kms_doc_key: targetDocFolderKey.value
      //   };
      // }
      if (knowIndex !== -1) {
        kmsStore.selectedFolderKnows[knowIndex] = {
          ...kmsStore.selectedFolderKnows[knowIndex],
          kms_know_key: targetFolderKey.value
        };
      }

      // 독 업데이트
      // API 호출 (백엔드에 updateKmsKnow 메서드 필요)
      // const response = await updateKmsKnow(updateData);
      // const response = await moveKmsKnow(
      //   docToMove.know.kms_know_key,
      //   targetDocFolderKey.value
      // );
      const response = await moveKmsKnow(
        folderToMove.know.kms_know_key,
        targetFolderKey.value
      );

      if (!response) {
        // 실패 시 다시 로드
        // 독 업데이트
        // await kmsStore.loadDocs(kmsStore.selectedFolderKey);
        await kmsStore.loadKnows(
          Number(channelKey.value),
          kmsStore.selectedFolderKey,
          1
        );
      }
    }

    // 이동 완료 후 상태 초기화
    closeKnowMovePopup();
    $q.notify({
      message: "지식 이동이 완료되었습니다.",
      color: "positive"
    });
    isPriorityMode.value = false;
    isKnowMoveMode.value = false;

    // 지식 목록 다시 로드
    if (kmsStore.selectedFolderKey) {
      // 독 업데이트
      // await kmsStore.loadDocs(kmsStore.selectedFolderKey);
      await kmsStore.loadKnows(
        Number(channelKey.value),
        kmsStore.selectedFolderKey,
        1
      );
    }
  } catch (error) {
    console.error("지식 이동 실패:", error);
    $q.notify({
      message: "지식 이동 중 오류가 발생했습니다.",
      color: "negative"
    });
  }
};

// const handleKnowMove = async () => {
//   if (!targetDocFolderKey.value) {
//     $q.notify({
//       message: "이동할 지식을 선택해주세요.",
//       color: "negative"
//     });
//     return;
//   }

//   try {
//     // 선택된 각 지식에 대해 이동 처리
//     for (const knowKey of selectedKnowForMove.value) {
//       // 현재 지식 찾기
//       const docToMove = kmsStore.selectedDocKnows.find(
//         (know) => know.kms_know_key === knowKey
//       );

//       if (!docToMove) continue;

//       const updateData = {
//         kms_know_key: knowKey,
//         kms_doc_key: targetDocFolderKey.value,
//         doc_name: docToMove.doc_name
//       };

//       // UI 즉시 업데이트
//       const docIndex = kmsStore.selectedDocKnows.findIndex(
//         (doc) => doc.kms_doc_key === knowKey
//       );

//       if (docIndex !== -1) {
//         kmsStore.selectedDocKnows[docIndex] = {
//           ...kmsStore.selectedDocKnows[docIndex],
//           kms_folder_key: targetDocFolderKey.value
//         };
//       }

//       // API 호출 (백엔드에 updateKmsDoc 메서드 필요)
//       const response = await updateKmsKnow(updateData);

//       if (!response) {
//         // 실패 시 다시 로드
//         await kmsStore.loadDocs(kmsStore.selectedFolderKey);
//       }
//     }

//     // 이동 완료 후 상태 초기화
//     closeKnowMovePopup();
//     $q.notify({
//       message: "지식 이동이 완료되었습니다.",
//       color: "positive"
//     });

//     // 지식 목록 다시 로드
//     if (kmsStore.selectedFolderKey) {
//       await kmsStore.loadDocs(kmsStore.selectedFolderKey);
//     }
//   } catch (error) {
//     console.error("지식 이동 실패:", error);
//     $q.notify({
//       message: "지식 이동 중 오류가 발생했습니다.",
//       color: "negative"
//     });
//   }
// };
// ======================================================

// const isFolderGroupSelected = computed(() => {
//   return kmsStore.selectedFolderGroupKey !== null;
// });

// const isAllSelected = computed(() => {
//   return selectedItems.value.length === kmsStore.selectedFileKnows.length;
// });

// const isSelectedMode = computed(() => {
//   return isSelectionActive.value;
// });

// 독 업데이트
// const isSelectionActive = ref(false); // 선택 모드 상태 관리

// 독 업데이트
// const hasConnectedDocs = computed(() => {
//   console.log("선택돤 폴더", kmsStore.selectedFolderChildren);
//   const selectedFolder = kmsStore.selectedFolder;
//   return selectedFolder && kmsStore.filteredDocs.length > 0;
// });
const hasConnectedKnows = computed(() => {
  console.log("선택돤 폴더", kmsStore.selectedFolderChildren);
  const selectedFolder = kmsStore.selectedFolder;
  return selectedFolder && kmsStore.selectedFolderKnows.length > 0;
});

// 검토 승인된 지식 페이지 설정
const currentPage = ref(1);
const itemsPerPage = ref(10);

// 검토 필요한 지식 페이지 설정
const unconfirmedCurrentPage = ref(1);
const unconfirmedItemsPerPage = ref(10);

// 검토 승인된 지식 총 페이지 수 계산
const totalPages = computed(() => {
  return Math.ceil(sortedKnows.value.length / itemsPerPage.value);
});

// const hasFiles = computed(() => {
//   console.log("selectedFolderFiles:", kmsStore.selectedFolderFiles);
//   return (
//     kmsStore.selectedFolderFiles && kmsStore.selectedFolderFiles.length > 0
//   );
// });

// 검토 승인된 지식 페이지 변경 핸들러
const handlePageChange = (page) => {
  currentPage.value = page;
  console.log("현재 페이지", currentPage.value);
};

// 정렬 옵션 상태
const sortOption = ref("date"); // 'date' 또는 'name'

// // 페이지네이션된 지식 목록을 위한 computed 속성 수정
// const paginatedKnows = computed(() => {
//   const startIndex = (currentPage.value - 1) * itemsPerPage.value;
//   const endIndex = startIndex + itemsPerPage.value;
//   return sortedKnows.value.slice(startIndex, endIndex);
// });
// // 페이지네이션된 지식 목록을 위한 computed 속성 (ResKmsKnow[] 유지)
// const paginatedKnows = computed<ResKmsKnow[]>(() => {
//   const startIndex = (currentPage.value - 1) * itemsPerPage.value;
//   const endIndex = startIndex + itemsPerPage.value;
//   return sortedKnows.value.slice(startIndex, endIndex);
// });

const paginatedKnows = computed<ResKmsKnow[]>(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage.value;
  const endIndex = startIndex + itemsPerPage.value;
  return sortedKnows.value.slice(startIndex, endIndex);
});

// // 정렬된 지식 목록을 위한 computed 속성
// const sortedKnows = computed(() => {
//   let knows = [...kmsStore.selectedFileKnows];

//   if (searchQuery.value.trim()) {
//     knows = knows.filter((know) => {
//       const titleMatch = know.title
//         .toLowerCase()
//         .includes(searchQuery.value.toLowerCase());
//       const keywordMatch = know.keyword_list.some((keyword) =>
//         keyword.toLowerCase().includes(searchQuery.value.toLowerCase())
//       );

//       return titleMatch || keywordMatch;
//     });
//   }

//   if (sortOption.value === "date") {
//     // 시간순 정렬 (최신순)
//     return knows.sort((a, b) => new Date(b.cre_date) - new Date(a.cre_date));
//   } else {
//     // 가나다순 정렬
//     return knows.sort((a, b) => a.title.localeCompare(b.title, "ko"));
//   }
// });

// // 정렬된 지식 목록을 위한 computed 속성 (ResKmsKnow[] 형태 유지)
// const sortedKnows = computed<ResKmsKnow[]>(() => {
//   let knows = [...kmsStore.selectedFileKnows]; // ✅ 전체 객체 유지

//   if (searchQuery.value.trim()) {
//     knows = knows.filter((item) => {
//       const titleMatch = item.know.title
//         .toLowerCase()
//         .includes(searchQuery.value.toLowerCase());
//       const keywordMatch = item.know.keyword_list.some((keyword) =>
//         keyword.toLowerCase().includes(searchQuery.value.toLowerCase())
//       );

//       return titleMatch || keywordMatch;
//     });
//   }

//   if (sortOption.value === "date") {
//     // 시간순 정렬 (최신순)
//     return knows.sort(
//       (a, b) =>
//         new Date(b.know.cre_date).getTime() -
//         new Date(a.know.cre_date).getTime()
//     );
//   } else {
//     // 가나다순 정렬
//     return knows.sort((a, b) => a.know.title.localeCompare(b.know.title, "ko"));
//   }
// });

const sortedKnows = computed<ResKmsKnow[]>(() => {
  // const knows = [...kmsStore.selectedDocKnows]; // ✅ `ResKmsKnow[]` 형태 유지
  // 독 업데이트
  const knows = [...kmsStore.selectedFolderKnows]; // ✅ `ResKmsKnow[]` 형태 유지

  // if (searchQuery.value.trim()) {
  //   knows = knows.filter((item) => {
  //     const titleMatch = item.know.title
  //       .toLowerCase()
  //       .includes(searchQuery.value.toLowerCase());

  //     // ✅ item.know.keyword_list로 변경
  //     const keywordMatch = item.know.keyword_list.some((keyword) =>
  //       keyword.toLowerCase().includes(searchQuery.value.toLowerCase())
  //     );

  //     return titleMatch || keywordMatch;
  //   });
  // }

  if (sortOption.value === "date") {
    return knows.sort(
      (a, b) =>
        new Date(b.know.cre_date).getTime() -
        new Date(a.know.cre_date).getTime()
    );
  } else {
    return knows.sort((a, b) => a.know.title.localeCompare(b.know.title, "ko"));
  }
});

// select 변경 핸들러
const handleSortChange = (event) => {
  sortOption.value = event.target.value;
};

// const fetchKmsChannelKnowList = async () => {
//   const param: ReqKmsChannelKnow = {
//     channel_key_list: [kmsStore.currentChannel?.channel_key],
//     confirm_yn: 0
//   };

//   const result = await getKmsChannelKnowList(param);

//   console.log("지식 데이터:", result);
// };

// 검토 필요한 지식 정렬을 위한 computed 속성
const sortedUnconfirmedKnows = computed(() => {
  // const knows = [...confirmKnowlist.value];
  const knows = [...kmsStore.unconfirmedKnows];

  if (sortOption.value === "date") {
    return knows.sort(
      (a, b) =>
        new Date(b.know.cre_date).getTime() -
        new Date(a.know.cre_date).getTime()
    );
  } else {
    return knows.sort((a, b) => a.know.title.localeCompare(b.know.title, "ko"));
  }
});

// 검토 필요한 지식의 페이지네이션된 목록
const paginatedUnconfirmedKnows = computed(() => {
  const startIndex =
    (unconfirmedCurrentPage.value - 1) * unconfirmedItemsPerPage.value;
  const endIndex = startIndex + unconfirmedItemsPerPage.value;
  return sortedUnconfirmedKnows.value.slice(startIndex, endIndex);
});

// 검토 필요한 지식의 총 페이지 수
const unconfirmedTotalPages = computed(() => {
  return Math.ceil(
    sortedUnconfirmedKnows.value.length / unconfirmedItemsPerPage.value
  );
});

// 검토 필요한 지식의 페이지 변경 핸들러
const handleUnconfirmedPageChange = (page: number) => {
  unconfirmedCurrentPage.value = page;
};

onMounted(async () => {
  try {
    if (!channelKey.value) {
      console.error("유효한 채널 키가 없습니다.");
      return;
    }

    // 채널 키로 폴더 그룹 로딩
    // await kmsStore.loadFolderGroups(
    //   kmsStore.currentChannel?.channel_key || channelKey.value
    // );

    await kmsStore.loadFolderGroups(channelKey.value);

    // 폴더 그룹이 로드되면 첫 번째 폴더 그룹을 자동 선택하도록 설정
    if (kmsStore.folderGroups.length > 0) {
      kmsStore.selectedFolderGroupKey =
        kmsStore.folderGroups[0].kms_folder_group_key;
      // 첫 번째 폴더 그룹에 해당하는 폴더들 로드
      await kmsStore.loadFolders(kmsStore.selectedFolderGroupKey);
    }

    const confirmYn = 0;
    const limit = 7000;
    const result = await kmsStore.loadChannelKnows(
      Number(channelKey.value),
      confirmYn,
      limit
    );
    kmsStore.unconfirmedKnowCount = result.length;
    confirmKnowlist.value = result; // 배열 전체를 할당
  } catch (error) {
    console.error("초기 데이터 로딩 실패:", error);
  }
});

// const unconfirmedKnowCountData = ref(kmsStore.unconfirmedKnowCount);
const unconfirmedKnowCountData = computed(() => kmsStore.unconfirmedKnowCount);
const unconfirmedChannelKnowCountData = computed(
  () => kmsStore.unconfirmedChannelKnowCount
);

// ✅ `channelKey`가 변경될 때 자동으로 `getKmsChannelKnowList` 호출
// watch(
//   channelKey,
//   async (newChannelKey) => {
//     if (newChannelKey) {
//       await kmsStore.loadKmsKnows(newChannelKey);
//     }
//   },
//   { immediate: true }
// ); // `immediate: true`는 컴포넌트가 마운트될 때 최초 실행

// watch 추가
// watch(
//   () => route.params.channelkey,
//   async (newChannelKey) => {
//     if (newChannelKey) {
//       channelKey.value = newChannelKey;
//       try {
//         // console.log("========================!!", kmsStore.selectedFolder);
//         // console.log("========================watch 호출!!", channelKey.value);
//         // await kmsStore.loadFolderGroups(kmsStore.currentChannel?.channel_key);
//         await kmsStore.loadFolders(kmsStore.selectedFolderGroupKey);
//       } catch (error) {
//         console.error("데이터 로딩 실패:", error);
//       }
//     }
//   }
// );

watch(
  () => kmsStore.selectedFolderGroupKey,
  async (newValue) => {
    if (newValue) {
      // 폴더 그룹이 변경되면 모든 상태 초기화
      kmsStore.selectedFolderKey = null;
      // 독 업데이트
      // kmsStore.selectedDocKey = null;
      kmsStore.knows = [];

      // 새로운 폴더 그룹의 데이터 로드
      await kmsStore.loadFolders(newValue);

      // 첫 번째 폴더 그룹인지 확인
      const isFirstFolderGroup =
        kmsStore.folderGroups[0]?.kms_folder_group_key === newValue;

      if (isFirstFolderGroup) {
        // filteredFolderTrees에서 첫 번째 폴더 찾기
        const firstFolder = kmsStore.filteredFolderTrees[0];
        if (firstFolder) {
          // 첫 번째 폴더 선택
          kmsStore.selectedFolderKey = firstFolder.kms_folder_key;
          // 폴더 확장 상태 추가
          kmsStore.expandedFolders.add(firstFolder.kms_folder_key);

          // 해당 폴더의 문서 로드
          // 독 업데이트
          // await kmsStore.loadDocs(firstFolder.kms_folder_key);
          await kmsStore.loadKnows(
            Number(channelKey.value),
            firstFolder.kms_folder_key,
            1
          );
        }
      }
    }
  }
);
watch(
  () => kmsStore.selectedFolderKey,
  async (newValue) => {
    // 독 업데이트
    // 파일과 지식 목록 초기화
    // kmsStore.selectedDocKey = null;
    kmsStore.knows = [];

    // 새로운 폴더가 선택된 경우에만 문서 목록 로드
    if (newValue) {
      // 독 업데이트
      // await kmsStore.loadDocs(newValue);
      await kmsStore.loadKnows(Number(channelKey.value), newValue, 1);

      // 독 업데이트
      // 첫 번째 폴더인 경우 첫 번째 문서 자동 선택
      // if (kmsStore.filteredDocs.length > 0) {
      //   const firstDoc = kmsStore.filteredDocs[0];
      //   kmsStore.selectDoc(firstDoc.kms_doc_key);
      // }
      if (kmsStore.selectedFolderKnows.length > 0) {
        const firstKnow = kmsStore.selectedFolderKnows[0];
        kmsStore.selectKnow(firstKnow.kms_know_key);
      }
    }
  },
  { immediate: true }
);

// watch(
//   () => kmsStore.selectedFileKey,
//   async (newValue) => {
//     if (newValue) {
//       await kmsStore.loadKnows(newValue);
//     }
//   }
// );

// watch는 selectedFileKey의 변경만 감지
watch(
  // 독 업데이트
  // () => kmsStore.selectedDocKey,
  () => kmsStore.selectedFolderKey,
  async (newValue, oldValue) => {
    console.log("selectedFolderKey 변경:", { oldValue, newValue });
    handlePageChange(1); // 페이지를 1로 변경하는 함수 호출

    // 지식 목록 초기화
    kmsStore.knows = [];

    // 새로운 파일이 선택된 경우에만 지식 목록 로드
    if (newValue) {
      // 기본적으로 confirmYn = 1로 동작
      await kmsStore.loadKnows(Number(channelKey.value), newValue, 1);
    }
  },
  { immediate: true } // 컴포넌트 마운트 시에도 실행
);

watch(
  () => kmsStore.folders,
  (newValue) => {
    console.log("Folders changed:", newValue);
  },
  { deep: true }
);

watch(
  () => kmsStore.filteredFolderTrees,
  (newValue) => {
    console.log("FilteredFolderTrees changed:", newValue);
  },
  { deep: true }
);

watch(showFolderAddPopup, (newValue) => {
  if (newValue) {
    // 팝업이 열릴 때 다음 틱에서 input에 포커스
    nextTick(() => {
      if (folderInputRef.value) {
        folderInputRef.value.focus();
      }
    });
  }
});

watch(showFolderEditPopup, (newValue) => {
  if (newValue) {
    // 팝업이 열릴 때 다음 틱에서 input에 포커스
    nextTick(() => {
      if (folderInputRef.value) {
        folderInputRef.value.focus();
      }
    });
  }
});

watch(
  () => kmsStore.selectedFolderKey,
  async (newValue) => {
    // 폴더가 변경될 때 파일과 지식 초기화
    // 독 업데이트
    // kmsStore.selectedDocKey = null; // 선택된 파일 초기화
    kmsStore.knows = []; // knows 배열을 초기화

    // 새로운 폴더가 선택된 경우에만 문서 목록 로드
    if (newValue) {
      // 독 업데이트
      // await kmsStore.loadDocs(newValue);
      await kmsStore.loadKnows(Number(channelKey.value), newValue, 1);
    }
  }
);

// 독 업데이트
// 파일 선택 시에도 지식 목록 초기화 후 로드
// watch(
//   // 독 업데이트
//   // () => kmsStore.selectedDocKey,
//   () => kmsStore.selectedFolderKey,
//   async (newValue) => {
//     // 파일이 변경될 때 지식 목록 초기화
//     kmsStore.knows = [];

//     // 새로운 파일이 선택된 경우에만 지식 목록 로드
//     if (newValue) {
//       await kmsStore.loadKnows(Number(channelKey.value), newValue, 1);
//     }
//   }
// );

// const handleKeyPress = (event) => {
//   if (event.key === "Enter") {
//     event.preventDefault(); // 이벤트 전파 방지
//     event.stopPropagation(); // 이벤트 버블링 방지
//     if (!showFolderAddPopup.value) return; // 팝업이 열려있을 때만 실행
//     addFolder();
//   }
// };

// 폴더 추가 팝업 관리
const openFolderAddPopup = () => {
  // if (!kmsStore.selectedFolderGroupKey) {
  //   $q.notify({
  //     message: "폴더 그룹을 선택해주세요.",
  //     color: "negative"
  //   });
  //   return;
  // }

  const selectedFolder = kmsStore.selectedFolderKey;
  if (!selectedFolder) {
    // 선택된 폴더가 없으면 최상위 폴더로 추가
    isSubFolder.value = false;
    selectedParentFolder.value = 0;
  } else {
    // 선택된 폴더가 있으면 하위 폴더로 추가
    isSubFolder.value = true;
    selectedParentFolder.value = selectedFolder;
  }

  showFolderAddPopup.value = true;
};

const openFolderEditPopup = () => {
  console.log("수정 팝업 열기 시도");
  console.log("현재 선택된 폴더 키:", kmsStore.selectedFolderKey);

  if (!kmsStore.selectedFolderKey) {
    console.warn("폴더 키가 없습니다!");
    alert("폴더를 하나만 선택해주세요.");
    return;
  }

  // 선택된 폴더 키를 사용해 폴더 객체 찾기
  const selectedFolder = kmsStore.folders.find(
    (folder) => folder.kms_folder_key === kmsStore.selectedFolderKey
  );

  if (!selectedFolder) {
    alert("선택된 폴더를 찾을 수 없습니다.");
    return;
  }

  // 스토어의 selectedFolder를 업데이트
  kmsStore.$patch((state) => {
    state.selectedFolder = selectedFolder;
  });

  folderName.value = "";

  showFolderEditPopup.value = true;
  // folderName.value = selectedFolder.folder_name; // 선택된 폴더의 이름을 상태에 저장
  console.log("선택된 폴더:", selectedFolder);
  console.log("팝업 상태:", showFolderEditPopup.value);
};

const openFolderDeletePopup = async () => {
  if (!kmsStore.selectedFolderKey) {
    $q.notify({
      message: "삭제할 폴더를 선택해주세요.",
      color: "negative"
    });
    return;
  }
  showDeleteFolderPopup.value = true;
};

const confirmFolderDelete = async () => {
  if (!kmsStore.selectedFolderKey) {
    $q.notify({
      message: "삭제할 폴더를 선택해주세요.",
      color: "negative"
    });
    return;
  }
  console.log("selectedFolderKey", kmsStore.selectedFolderKey);
  try {
    await kmsStore.deleteFolders(kmsStore.selectedFolderKey);
    showDeleteFolderPopup.value = false;
    kmsStore.selectedFolderKey = null;
    $q.notify({
      message: "폴더가 삭제되었습니다.",
      color: "positive"
    });
  } catch (error) {
    console.error("폴더 삭제 실패:", error);
    $q.notify({
      message: "폴더 삭제 중 오류가 발생했습니다.",
      color: "negative"
    });
  }
};

const closeFolderDeletePopup = () => {
  showDeleteFolderPopup.value = false;
};

const closeFolderAddPopup = () => {
  showFolderAddPopup.value = false;
  folderName.value = "";
  isSubFolder.value = false;
  folderLocation.value = "root";
  selectedParentFolder.value = null;
  // kmsStore.selectedFolderKey = null;
};

const closeFolderEditPopup = () => {
  showFolderEditPopup.value = false;
  folderName.value = "";
  // isSubFolder.value = false;
  // selectedParentFolder.value = null;
};

// 독 업데이트
// const closeDocEditPopup = () => {
//   showDocEditPopup.value = false;
//   docName.value = "";
//   // isSubFolder.value = false;
//   // selectedParentFolder.value = null;
// };
export interface folderType {
  kms_folder_key: number;
  kms_folder_group_key: number;
  parent_folder_key: number;
  folder_name: string;
  channel_key: number;
}

const addFolder = async () => {
  // if (!channelKey.value) {
  //   $q.notify({
  //     message: "유효한 채널이 선택되지 않았습니다.",
  //     color: "negative"
  //   });
  //   return;
  // }

  console.log("addFolder 함수 실행 시작", {
    folderName: folderName.value,
    selectedFolderGroupKey: kmsStore.selectedFolderGroupKey
  });

  if (!folderName.value.trim()) {
    $q.notify({
      message: "폴더명을 입력해주세요.",
      color: "negative"
    });
    return;
  }

  // 폴더 추가 시 부모 폴더 검증
  if (folderLocation.value === "sub") {
    const parentFolder = kmsStore.folders.find(
      (f) =>
        f.kms_folder_key === kmsStore.selectedFolderKey && f.delete_yn !== 1
    );

    if (!parentFolder) {
      $q.notify({
        message: "선택된 폴더가 존재하지 않거나 삭제되었습니다.",
        color: "negative"
      });
      return;
    }
  }

  const maxFolderKey = Math.max(
    ...kmsStore.folders.map((f) => f.kms_folder_key),
    0
  );

  const newFolder: folderType = {
    kms_folder_key: maxFolderKey + 1,
    kms_folder_group_key: kmsStore.selectedFolderGroupKey || null || 0,
    // parent_folder_key: isSubFolder.value ? selectedParentFolder.value ?? 0 : 0,
    parent_folder_key:
      folderLocation.value === "sub" ? kmsStore.selectedFolderKey : 0,
    folder_name: folderName.value.trim(),
    channel_key: kmsStore.currentChannel?.channel_key
    // channel_key: Number(channelKey.value)
  };

  console.log("newFolder", newFolder);

  try {
    console.log("kmsStore.addNewFolder 호출 전", newFolder);
    await kmsStore.addNewFolder(newFolder);
    console.log("kmsStore.addNewFolder 호출 완료");
    closeFolderAddPopup();
    $q.notify({
      message: "폴더가 추가되었습니다.",
      color: "positive"
    });

    // 자식 폴더인 경우 부모 폴더를 확장 상태로 유지
    if (isSubFolder.value && selectedParentFolder.value) {
      kmsStore.expandedFolders.add(selectedParentFolder.value);
    }

    // alert(`${isSubFolder.value ? "하위 " : ""}폴더가 추가되었습니다.`);
  } catch (error) {
    console.error("폴더 추가 실패:", error);
    $q.notify({
      message: "폴더 추가에 실패했습니다.",
      color: "negative"
    });
  }
};

const editFolder = async () => {
  if (!folderName.value.trim()) {
    $q.notify({
      message: "변경할 폴더명을 입력해주세요.",
      color: "negative"
    });
    return;
  }

  const selectedFolder = kmsStore.folders.find(
    (folder) => folder.kms_folder_key === kmsStore.selectedFolderKey
  );

  if (!selectedFolder) {
    $q.notify({
      message: "선택된 폴더가 없습니다.",
      color: "negative"
    });
    return;
  }

  console.log("selected", selectedFolder);
  const updateFolder: folderType = {
    kms_folder_key: selectedFolder.kms_folder_key,
    kms_folder_group_key: selectedFolder.kms_folder_group_key,
    parent_folder_key: selectedFolder.parent_folder_key,
    folder_name: folderName.value.trim()
  };

  try {
    await kmsStore.editFolderName(updateFolder);
    $q.notify({
      message: "파일명이 성공적으로 수정되었습니다.",
      color: "positive"
    });
    closeFolderEditPopup();

    // 자식 폴더인 경우 부모 폴더를 확장 상태로 유지
    if (isSubFolder.value && selectedParentFolder.value) {
      kmsStore.expandedFolders.add(selectedParentFolder.value);
    }
  } catch (error) {
    console.log("폴더명 변경 실패:", error);
    $q.notify({
      message: "폴더명 변경 중 오류가 발생하였습니다.",
      color: "negative"
    });
  }
};

// const deleteFile = async (file_key: number) => {
//   try {
//     await deleteKmsDoc(file_key);
//     alert("파일이 삭제되었습니다.");
//     // await kmsStore.deletFile(file_key);
//     closeFileEditPopup();
//   } catch (error) {
//     console.log("파일 삭제", error);
//   }
// };

// 루트 영역 드래그 상태 관리
const isDraggingOverRoot = ref(false);

// 루트 영역 드래그 핸들러들
const handleRootDragOver = (event: DragEvent) => {
  event.preventDefault();
  // 폴더 아이템 위가 아닌 경우에만 활성화
  if (!(event.target as HTMLElement).closest(".folder-item")) {
    isDraggingOverRoot.value = true;
  }
};

const handleRootDragLeave = (event: DragEvent) => {
  // 컨테이너를 벗어났을 때만 비활성화
  if (!(event.relatedTarget as HTMLElement)?.closest(".folder-container")) {
    isDraggingOverRoot.value = false;
  }
};

const handleRootDrop = async (kms_folder_key: number, event: DragEvent) => {
  isDraggingOverRoot.value = false;

  const draggedFolderKey = kms_folder_key;
  if (!draggedFolderKey) return;

  try {
    // 현재 폴더 찾기
    const folderToMove = kmsStore.folders.find(
      (folder) => folder.kms_folder_key === draggedFolderKey
    );

    if (!folderToMove) return;

    // 이동할 데이터 준비
    const updateData: FolderData = {
      kms_folder_key: draggedFolderKey,
      kms_folder_group_key: kmsStore.selectedFolderGroupKey, // Can be null
      parent_folder_key: targetFolderKey.value, // Can be null
      folder_name: folderToMove.folder_name,
      channel_key: folderToMove.channel_key
    };

    // API 호출하여 폴더 업데이트
    const updatedFolder = await updateKmsFolder(updateData);

    if (updatedFolder) {
      // UI 업데이트
      const folderIndex = kmsStore.folders.findIndex(
        (folder) => folder.kms_folder_key === draggedFolderKey
      );

      if (folderIndex !== -1) {
        kmsStore.folders[folderIndex] = {
          ...kmsStore.folders[folderIndex],
          parent_folder_key: 0
        };
      }

      $q.notify({
        message: "폴더가 최상위 폴더로 이동되었습니다.",
        color: "positive"
      });
    }
  } catch (error) {
    console.error("폴더 이동 실패:", error);
    $q.notify({
      message: "폴더 이동에 실패했습니다.",
      color: "negative"
    });
  }
};

const getUserName = (userKey: number) => {
  const user = users.value.find((user) => user.user_key === userKey);
  return user ? user.user_name : "알 수 없음";
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(1, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${year}년 ${month}월 ${day}일 ${hours}시 ${minutes}분`;
};

// 상세페이지 팝업열기
const showViewPopup = ref(false); // 팝업 상태 관리
// const selectedItem = (null); // 선택된 아이템 데이터
const selectedItem = ref<ResKmsKnow | null>(null); // 선택된 아이템 데이터
// const selectedItem = ref<KmsKnow[]>([]); // 선택된 아이템 데이터

const openViewPopup = async (knowViewItem) => {
  console.log("openViewPopup 호출됨");
  console.log("팝업 오픈 선택된 item:", knowViewItem); // 로그 추가
  // selectedItem.value = knowViewItem; // 클릭된 항목 데이터를 저장

  // 상세 정보를 위한 API 호출
  if (knowViewItem?.know?.kms_know_key) {
    const detailData = await kmsStore.loadKnowDetail(
      Number(channelKey.value),
      knowViewItem.know.kms_know_key,
      1,
      1
    );
    // 해당 지식 찾기
    const detailedKnow = detailData.find(
      (item) => item.know.kms_know_key === knowViewItem.know.kms_know_key
    );

    if (detailedKnow) {
      selectedItem.value = detailedKnow;
    } else {
      selectedItem.value = knowViewItem;
      console.warn("상세 정보를 찾을 수 없음:", knowViewItem.know.kms_know_key);
    }
  } else {
    selectedItem.value = knowViewItem;
  }

  showViewPopup.value = true; // 팝업 열기
  console.log("팝업 열기 상태:", showViewPopup.value);
  console.log("selectedItem 값 확인:", selectedItem.value);
};

const closeViewPopup = () => {
  // console.log("선택된 항목:", item);
  console.log("closeViewPopup 호출됨"); // 디버깅용 로그
  showViewPopup.value = false; // 팝업 닫기
  selectedItem.value = null; // 선택된 데이터 초기화
  console.log("팝업 닫기 상태:", showViewPopup.value);
  console.log("팝업 닫기 후 selectedItem 값:", selectedItem.value);
};

// 팝업 상태 변경 감지
watch(showViewPopup, (newValue) => {
  console.log("팝업 상태 변경 감지:", newValue); // 상태 변경 확인
});

// watch(kmsStore.unconfirmedKnowCount, () => {
//   unconfirmedKnowCount.value =
// })

// 상세페이지 요약 수정
const handleUpdateSummary = (updatedSummary) => {
  console.log("handleUpdateSummary 호출됨:", updatedSummary);
  console.log("선택된 항목 데이터:", selectedItem.value);

  if (selectedItem.value) {
    console.log("Store에 전달되는 데이터:", {
      kms_know_key: selectedItem.value.know.kms_know_key,
      summary: updatedSummary
    });
    kmsStore.updateSummary(
      selectedItem.value.know.kms_know_key,
      updatedSummary
    );
    selectedItem.value.know.summary = updatedSummary; // 업데이트된 요약 반영
    console.log("Store와 selectedItem 업데이트 완료:", selectedItem.value);
  } else {
    console.warn("선택된 항목이 없습니다. 업데이트 불가능.");
  }
};

// 내용 업데이트 함수
const handleUpdateBody = (updatedBody) => {
  console.log("handleUpdateBody 호출됨:", updatedBody);
  console.log("선택된 항목 데이터:", selectedItem.value);

  if (selectedItem.value) {
    console.log("Store에 전달되는 데이터:", {
      kms_know_key: selectedItem.value.know.kms_know_key,
      body: updatedBody
    });
    kmsStore.updateBody(selectedItem.value.know.kms_know_key, updatedBody);
    selectedItem.value.know.body = updatedBody; // 업데이트된 내용 반영
    console.log("Store와 selectedItem 업데이트 완료:", selectedItem.value);
  } else {
    console.warn("선택된 항목이 없습니다. 업데이트 불가능.");
  }
};

// 필수 내용 업데이트
const handleUpdateMustText = (updatedMustText: string) => {
  console.log("handleUpdateMustText 호출됨:", updatedMustText);
  console.log("선택된 항목 데이터:", selectedItem.value);

  if (selectedItem.value) {
    // store와 selectedItem의 데이터 동기화
    selectedItem.value.know.must_text = updatedMustText;
    kmsStore.updateMustText(
      selectedItem.value.know.kms_know_key,
      updatedMustText
    );
    console.log("필수 내용 업데이트 완료:", selectedItem.value);
  } else {
    console.warn("선택된 항목이 없습니다. 필수 내용 업데이트 불가능.");
  }
};

// 키워드 리스트 업데이트
const handleUpdateKeywordList = (updatedKeywordList: string[]) => {
  console.log("handleUpdateKeywordList 호출됨:", updatedKeywordList);
  console.log("선택된 항목 데이터:", selectedItem.value);

  if (selectedItem.value) {
    // store와 selectedItem의 데이터 동기화
    selectedItem.value.know.keyword_list = updatedKeywordList;
    kmsStore.updateKeywordList(
      selectedItem.value.know.kms_know_key,
      updatedKeywordList
    );
    console.log("키워드 리스트 업데이트 완료:", selectedItem.value);
  } else {
    console.warn("선택된 항목이 없습니다. 키워드 리스트 업데이트 불가능.");
  }
};

// 필수표시  업데이트
const handleUpdateQuestList = (updatedQyestList: string[]) => {
  console.log("handleUpdateQuestList 호출됨:", updatedQyestList);
  console.log("선택된 항목 데이터:", selectedItem.value);

  if (selectedItem.value) {
    // store와 selectedItem의 데이터 동기화
    selectedItem.value.know.quest_list = updatedQyestList;
    kmsStore.updateQuestList(
      selectedItem.value.know.kms_know_key,
      updatedQyestList
    );
    console.log("키워드 리스트 업데이트 완료:", selectedItem.value);
  } else {
    console.warn("선택된 항목이 없습니다. 키워드 리스트 업데이트 불가능.");
  }
};

// 지식 삭제 핸들러
// const handleDeleteKnow = async (deletedKey) => {
//   console.log("삭제된 지식 키:", deletedKey);

//   await kmsStore.loadKnows(kmsStore.selectedFileKey, 1);
//   // 팝업 닫기
//   closeViewPopup();

//   console.log("삭제 후 업데이트된 지식 목록:", kmsStore.selectedFileKnows);
// };

// 추가 상태 관리
const showKnowAddPopup = ref(false);
const showKnowItemAddPopup = ref(false); // 개별 지식 추가 팝업

// 지식 추가 팝업 열기
const openKnowAddPopup = () => {
  if (kmsStore.selectedFolder) {
    console.log("문서 지식 추가 팝업 열림"); // 팝업 열림 로그 출력
    showKnowAddPopup.value = true;
  } else {
    $q.notify({
      message: "폴더를 선택하세요.",
      color: "negative",
      timeout: 2000
    });
  }
};

// 지식 추가 팝업 닫기
const closeKnowAddPopup = async () => {
  console.log("지식 추가 팝업 닫기");
  showKnowAddPopup.value = false;

  if (kmsStore.selectedFolderKey) {
    // 독 업데이트
    // await kmsStore.loadDocs(kmsStore.selectedFolderKey);
    await kmsStore.loadKnows(
      Number(channelKey.value),
      kmsStore.selectedFolderKey,
      1
    );
  }
};

// 독 업데이트
// 지식 추가 팝업 열기
const openKnowItemAddPopup = () => {
  // if (!kmsStore.selectedDocKey) {
  if (!kmsStore.selectedFolderKey) {
    $q.notify({
      message: "지식을 추가하려면 먼저 폴더를 선택해주세요.",
      color: "red"
    });
    return;
  }
  console.log("개별 지식 추가 팝업 열기");
  showKnowItemAddPopup.value = true;
};

// 지식 추가 팝업 닫기
const closeKnowItemAddPopup = () => {
  console.log("개별 지식 추가 팝업 닫기");
  showKnowItemAddPopup.value = false;
};

// //지식 목록
// const getKmsKnowConfirmY = async () => {
//   editConfirmYn.value = false;
//   const confirmYn = 1;
//   const file_key = kmsStore.selectedFileKey;
//   console.log("선택된 파일", kmsStore.selectedFileKey);

//   if (file_key) {
//     await kmsStore.loadKnows(file_key, Number(channelKey.value), confirmYn);
//   } else {
//     console.error("선택된 파일 키가 없습니다.");
//   }
// };

const editConfirmYn = ref(false);
// const confirmKnowlist = ref<ResKmsKnow>([]);
const confirmKnowlist = ref<ResKmsKnow[]>([]);

// //검토 지식
// const getKmsKnowConfirmN = async () => {
//   // console.log("============선택된 지식 목록", kmsStore.selectedFileKnows);
//   openConfirmKnowPopYn.value = true; // 팝업 열기
//   console.log("openConfirmKnowPopYn", openConfirmKnowPopYn.value);
//   editConfirmYn.value = true;
//   const confirmYn = 0;
//   const file_key = kmsStore.selectedFileKey;

//   try {
//     // 현재 채널 키를 사용하여 모든 검토 지식 로드
//     const result = await kmsStore.loadChannelKnows(
//       Number(channelKey.value),
//       confirmYn
//     );

//     confirmKnowlist.value.know = result;
//     kmsStore.unconfirmedKnowCount = result.length;
//     openConfirmKnowPopYn.value = true;

//     console.log("검토지식목록!!!@@@@@@@@@@@2", result);
//   } catch (error) {
//     console.error("검토 지식 로드 중 오류 발생:", error);
//     $q.notify({
//       message: "검토 지식 조회 중 오류가 발생했습니다.",
//       color: "negative"
//     });
//   }

//   // if (file_key) {
//   //   await kmsStore.loadKnows(file_key, Number(channelKey.value), confirmYn);
//   // } else {
//   //   console.error("선택된 파일 키가 없습니다.");
//   // }
// };

const getKmsKnowConfirmN = async () => {
  openConfirmKnowPopYn.value = true; // 팝업 열기
  console.log("openConfirmKnowPopYn", openConfirmKnowPopYn.value);
  editConfirmYn.value = true;
  const confirmYn = 0;
  const limit = 7000;

  try {
    // 현재 채널 키를 사용하여 모든 검토 지식 로드
    const result: ResKmsKnow[] = await kmsStore.loadChannelKnows(
      Number(channelKey.value),
      confirmYn,
      limit
    );

    confirmKnowlist.value = result; // 배열 전체를 할당
    unconfirmedCurrentPage.value = 1;

    // kmsStore.unconfirmedKnowCount = result.length;
    openConfirmKnowPopYn.value = true;

    console.log("검토지식목록!!!@@@@@@@@@@@2", result);
  } catch (error) {
    console.error("검토 지식 로드 중 오류 발생:", error);
    $q.notify({
      message: "검토 지식 조회 중 오류가 발생했습니다.",
      color: "negative"
    });
  }
};

// const getKmsKnowConfirmN = async () => {
//   openConfirmKnowPopYn.value = true;
//   editConfirmYn.value = true;
//   const confirmYn = 0;

//   try {
//     let result: ResKmsKnow[];

//     // 파일이 선택되어 있는 경우
//     if (kmsStore.selectedFileKey) {
//       // 선택된 파일의 검토 지식만 로드
//       result = await kmsStore.loadKnows(
//         Number(channelKey.value),
//         kmsStore.selectedFileKey,
//         confirmYn
//       );
//     } else {
//       // 파일이 선택되지 않은 경우 채널의 모든 검토 지식 로드
//       result = await kmsStore.loadChannelKnows(
//         Number(channelKey.value),
//         confirmYn
//       );
//     }

//     confirmKnowlist.value = result;

//     // 파일이 선택된 경우 해당 파일의 미확인 지식 수만 표시
//     if (kmsStore.selectedFileKey) {
//       kmsStore.unconfirmedKnowCount = result.length;
//     } else {
//       // 채널 전체의 미확인 지식 수 표시
//       const channelResult = await kmsStore.loadChannelKnows(
//         Number(channelKey.value),
//         confirmYn
//       );
//       kmsStore.unconfirmedKnowCount = channelResult.length;
//     }

//     openConfirmKnowPopYn.value = true;
//   } catch (error) {
//     console.error("검토 지식 로드 중 오류 발생:", error);
//     $q.notify({
//       message: "검토 지식 조회 중 오류가 발생했습니다.",
//       color: "negative"
//     });
//   }
// };

// // 검토가 필요한 지식의 수를 계산하는 computed 속성 추가
// const unconfirmedKnowsCount = computed(() => {
//   // 현재 선택된 파일의 지식들 중 confirm_yn이 0인 것들의 수를 계산
//   return kmsStore.knows.filter((know) => know.confirm_yn === 0).length;
// });

// const handleSelectChange = (event) => {
//   const selectedValue = event.target.value;

//   if (selectedValue === "knowConfirmY") {
//     getKmsKnowConfirmY();
//   } else if (selectedValue === "knowConfirmN") {
//     getKmsKnowConfirmN();
//   }
// };

interface ResKmsKnow {
  know: KmsKnow;
  link_list: RepoLink[];
}

const selectedItems = ref<KmsKnow[]>([]);

// // 전체 선택 체크박스의 상태를 computed로 관리
// const allSelected = computed({
//   get: () => selectedItems.value.length === confirmKnowlist.value.length, // 여기를 수정
//   // get: () => selectedItems.value.length === kmsStore.selectedFileKnows.length, //원본
//   // get: () => selectedItems.value.length === kmsKnows.value.length, //목업 더미 데이터
//   set: (value: boolean) => {
//     if (value) {
//       selectedItems.value = [...confirmKnowlist.value]; // 여기도 수정
//       // selectedItems.value = [...kmsStore.selectedFileKnows]; // 전체 선택
//       // selectedItems.value = [...kmsKnows.value]; // 목업 더미 데이터
//     } else {
//       selectedItems.value = []; // 전체 해제
//     }
//   }
// });
const allSelected = computed({
  get: () => selectedItems.value.length === confirmKnowlist.value.length,
  set: (value: boolean) => {
    if (value) {
      // selectedItems.value = [...confirmKnowlist.value.know];
      selectedItems.value = confirmKnowlist.value.map((item) => item.know); // ✅ ResKmsKnow -> KmsKnow 변환
    } else {
      selectedItems.value = []; // 전체 해제
    }
  }
});

const aiRefineBtn = async () => {
  const paramData = {
    channel_key: kmsStore.currentChannel?.channel_key,
    req_title_yn: "true", // 필요시 수정
    req_summary_yn: "true", // 필요시 수정
    req_keyword_yn: "true", // 필요시 수정
    req_embedding_yn: "true" // 필요시 수정
  };

  const result = await aiRefineChannel(paramData);
  console.log("=========== refine 결과", result);
  return result;
};

// 일괄 승인 함수
const batchApproveKnow = async () => {
  // popStore.openConfirmAskPopLayer();
  // popStore.confirmAskPopBody = `${selectedItems.value.length}개의 지식을 승인하시겠습니까?`;
  // popStore.confirmAskOK = async () => {

  // // 생성 시작 시 상태 변경
  // isGenerating.value = true;

  // if (selectedItems.value.length === 0) {
  //   $q.notify({
  //     message: "1개 이상의 지식을 선택해 주세요.",
  //     color: "negative"
  //   });
  //   return;
  // }
  if (selectedItems.value.length === 0) {
    $q.notify({
      message: "1개 이상의 지식을 선택해 주세요.",
      color: "negative"
    });
    return;
  } else if (selectedItems.value.length > 0) {
    isGenerating.value = true;
  }

  try {
    for (const item of selectedItems.value) {
      if (item.kms_know_key) {
        console.log("item", item.kms_know_key);
        // await confirmKmsKnow({
        //   kms_know_key: item.kms_know_key,
        //   confirm_yn: 1
        // });
        // console.log("Processing item:", item.kms_know_key);

        // Prepare the API request payload using the selected item's data
        const payload = {
          kms_know_key: item.kms_know_key,
          // 독 업데이트
          // kms_doc_key: item.kms_doc_key,
          kms_folder_key: item.kms_folder_key,
          channel_key: item.channel_key,
          // lc_know_id: item.lc_know_id || "",
          refine_yn: 1,
          confirm_yn: 1,
          title: item.title || "",
          summary: item.summary || "",
          body: item.body || "",
          must_text: item.must_text || "",
          delete_yn: 0,
          // embedding: item.embedding || "", // Add this line
          keyword_list: item.keyword_list || [],
          quest_list: item.quest_list || [],
          img_filekey_list: item.img_filekey_list || [],
          attach_filekey_list: item.attach_filekey_list || [],
          linkkey_list: item.linkkey_list || [],
          valid_yn: item.valid_yn || 1,
          mapping_key_str: item.mapping_key_str || ""
        };

        await confirmKmsKnow2(payload);
      }
    }
    $q.notify({
      message: `${selectedItems.value?.length}개의 지식이 승인되었습니다`,
      color: "positive"
    });
    openConfirmKnowPopYn.value = false;
    selectedItems.value = []; // 작업 완료 후 선택 항목 초기화

    // 독 업데이트
    // if (kmsStore.selectedDocKey) {
    //   await kmsStore.loadKnows(
    //     Number(channelKey.value),
    //     kmsStore.selectedDocKey,
    //     1
    //   );
    // }
    if (kmsStore.selectedFolderKey) {
      await kmsStore.loadKnows(
        Number(channelKey.value),
        kmsStore.selectedFolderKey,
        1
      );
    }
  } catch (error) {
    console.error("승이 처리 중 오류 발생:", error);
    $q.notify({
      message: "지식 승인 중 오류가 발생했습니다.",
      color: "negative"
    });
  } finally {
    // 생성 완료 시 상태 복원
    isGenerating.value = false;
  }
  // };
  // popStore.confirmPopBody = `${selectedItems.value.length}개의 지식이 승인되었습니다.`;
};

const batchRejectKnow = async () => {
  // popStore.openConfirmAskPopLayer();
  // popStore.confirmAskPopBody = `${selectedItems.value.length}개의 지식을 승인하시겠습니까?`;
  // popStore.confirmAskOK = async () => {
  if (selectedItems.value.length === 0) {
    $q.notify({
      message: "1개 이상의 지식을 선택해 주세요.",
      color: "negative"
    });
    return;
  }

  // try {
  //   for (const item of selectedItems.value) {
  //     if (item.kms_know_key) {
  //       await confirmKmsKnow({
  //         kms_know_key: item.kms_know_key,
  //         confirm_yn: 2
  //       });
  //     }
  //   }
  try {
    for (const item of selectedItems.value) {
      if (item.kms_know_key) {
        console.log("item", item.kms_know_key);
        // await confirmKmsKnow({
        //   kms_know_key: item.kms_know_key,
        //   confirm_yn: 1
        // });
        // console.log("Processing item:", item.kms_know_key);

        // Prepare the API request payload using the selected item's data
        const payload = {
          kms_know_key: item.kms_know_key,
          // 독 업데이트
          // kms_doc_key: item.kms_doc_key,
          kms_folder_key: item.kms_folder_key,
          channel_key: item.channel_key,
          lc_know_id: item.lc_know_id || "",
          refine_yn: 0,
          confirm_yn: 2,
          title: item.title || "",
          summary: item.summary || "",
          body: item.body || "",
          must_text: item.must_text || "",
          delete_yn: 0,
          embedding: item.embedding || "", // Add this line
          keyword_list: item.keyword_list || [],
          quest_list: item.quest_list || [],
          img_filekey_list: item.img_filekey_list || [],
          attach_filekey_list: item.attach_filekey_list || [],
          linkkey_list: item.linkkey_list || [],
          valid_yn: item.valid_yn || 1,
          mapping_key_str: item.mapping_key_str || ""
        };

        await confirmKmsKnow2(payload);
      }
    }
    $q.notify({
      message: `${selectedItems.value?.length}개의 지식이 반려되었습니다`,
      color: "positive"
    });
    openConfirmKnowPopYn.value = false;
    selectedItems.value = []; // 작업 완료 후 선택 항목 초기화
    // 독 업데이트
    // if (kmsStore.selectedDocKey) {
    //   await kmsStore.loadKnows(
    //     Number(channelKey.value),
    //     kmsStore.selectedDocKey,
    //     1
    //   );
    // }
    if (kmsStore.selectedFolderKey) {
      await kmsStore.loadKnows(
        Number(channelKey.value),
        kmsStore.selectedFolderKey,
        1
      );
    }
  } catch (error) {
    console.error("반려 처리 중 오류 발생:", error);
    $q.notify({
      message: "지식 반려 중 오류가 발생했습니다.",
      color: "negative"
    });
  }
};

// 개별 체크박스 클릭 시 호출
const toggleItemSelection = (item: KmsKnow) => {
  console.log("선택한 지식 정보", item.kms_know_key, item.title);
  const index = selectedItems.value.findIndex(
    (i) => i.kms_know_key === item.kms_know_key
  );

  if (index > -1) {
    // 이미 선택된 항목이면 배열에서 제거
    selectedItems.value.splice(index, 1);
  } else {
    // 선택되지 않은 항목이면 배열에 추가
    selectedItems.value.push(item);
  }
};

const openConfirmKnowPopYn = ref(false);

// 파일 형식에 따라 탭을 열고 팝업을 여는 함수
const closeConfirmKnowPop = async () => {
  openConfirmKnowPopYn.value = false;

  // 독 업데이트
  // if (kmsStore.selectedDocKey) {
  //   await kmsStore.loadKnows(
  //     Number(channelKey.value),
  //     kmsStore.selectedDocKey,
  //     1
  //   );
  // }
  if (kmsStore.selectedFolderKey) {
    await kmsStore.loadKnows(
      Number(channelKey.value),
      kmsStore.selectedFolderKey,
      1
    );
  }
};

// 독 업데이트
// 문서 선택되었는지 확인
// const isDocSelected = computed(() => selectedDoc.value !== null); // 수정/삭제 버튼 활성화 상태

// 독 업데이트
// const selectedDoc = ref(null); // 선택된 파일 상태 관리

// 독 업데이트
// 지식 선택되었는지 확인
// const isKnowSelected = computed(() => {
//   return (
//     kmsStore.selectedDocKey !== null && kmsStore.selectedDocKnows.length > 0
//   );
// });
const isKnowSelected = computed(() => {
  return (
    kmsStore.selectedFolderKey !== null &&
    kmsStore.selectedFolderKnows.length > 0
  );
});

// 독 업데이트
// 파일 선택 시 호출
// const handleFileClick = (doc) => {
//   selectedDoc.value = doc;
// };

// 독 업데이트
// 파일 지식 수정
// const handleEditDoc = () => {
//   if (selectedDoc.value) {
//     console.log("변경할 문서:", selectedDoc.value);
//     // 수정 로직 추가
//   }
// };

// 독 업데이트
// 파일 지식 삭제
// const handleDeleteDoc = () => {
//   console.log("선택된 문서:", selectedDoc.value);
//   if (selectedDoc.value) {
//     showDeleteDocPopup.value = true;
//     console.log("팝업 상태:", showDeleteDocPopup.value);
//   }
// };

// 지식 삭제 처리
const handleKnowDeleted = (deletedKnowKey) => {
  confirmKnowlist.value = confirmKnowlist.value.filter(
    (know) => know.know.kms_know_key !== deletedKnowKey
  );
};

// 독 업데이트
// 문서 목록 추가
// const showKnowDocAddPopup = ref(false); // KnowFile 추가 팝업 상태
// const newKnowDocName = ref(""); // 새로 추가할 KnowFile 이름
// const knowDocs = ref([]); // KnowFile 목록

// 독 업데이트
// 팝업 열기
// const openKnowDocAddPopup = () => {
//   if (!selectedDoc.value) {
//     alert("변경할 문서를 선택해주세요.");
//     return;
//   }

//   showKnowDocAddPopup.value = true;
//   newKnowDocName.value = ""; // 선택된 파일의 이름을 기본값으로 설정

//   nextTick(() => {
//     const inputElement = document.querySelector("#knowDocNameInput");
//     if (inputElement) {
//       (inputElement as HTMLElement).focus();
//     }
//   });
// };

watch(showKnowAddPopup, (newValue) => {
  console.log(newValue ? "팝업 열림" : "팝업 닫힘");
});

// 독 업데이트
// 팝업 닫기
// const closeKnowDocAddPopup = () => {
//   console.log("문서 지식 추가 팝업 닫힘"); // 팝업 닫힘 로그 출력
//   showKnowDocAddPopup.value = false; // 팝업 닫기
//   newKnowDocName.value = ""; // 입력값 초기화
// };

// KnowDoc 수정
// 독 업데이트
// const editDoc = async () => {
//   if (!newKnowDocName.value.trim()) {
//     $q.notify({
//       message: "문서명을 입력해주세요.",
//       color: "negative"
//     });
//     return;
//   }

//   try {
//     await kmsStore.updateDocName(
//       selectedDoc.value.kms_doc_key,
//       newKnowDocName.value.trim()
//     ); // 스토어에 추가
//     await kmsStore.loadDocs(kmsStore.selectedFolderKey); // 문서 목록 다시 로드
//     closeKnowDocAddPopup(); // 팝업 닫기

//     $q.notify({
//       message: "문서명이 성공적으로 수정되었습니다.",
//       color: "positive"
//     });
//   } catch (error) {
//     console.error("문서명 변경 실패:", error);
//     $q.notify({
//       message: "문서명 변경 중 오류가 발생했습니다.",
//       color: "negative"
//     });
//   }
// };

// 문서 목록 상태
// const files = ref([]);

// // 파일 추가 함수
// const addNewFile = async (newFile) => {
//   try {
//     files.value.push(newFile); // 새 파일을 목록에 추가
//     console.log("문서 추가 성공:", newFile);
//   } catch (error) {
//     console.error("문서 추가 실패:", error);
//   }
// };

const showDeleteFolderPopup = ref(false);
// 독 업데이트
// const showDeleteDocPopup = ref(false);
const showDeleteKnowPopup = ref(false);
// const selectedFile = ref(null); // 선택된 파일 정보

// // 파일 삭제 팝업 열기
// const openDeleteFilePopup = () => {
//   if (!isDocSelected.value) return;
//   showDeleteDocPopup.value = true;
// };

// 독 업데이트
// 파일 삭제 팝업 닫기
// const closeDeleteDocPopup = () => {
//   showDeleteDocPopup.value = false;
// };

// 파일 삭제 확인 동작
// 독 업데이트
// const confirmDelete = async () => {
//   if (selectedDoc.value) {
//     try {
//       console.log("삭제할 문서:", selectedDoc.value);

//       // 실제 삭제 로직 추가
//       await deleteKmsDoc(selectedDoc.value.kms_doc_key);
//       $q.notify({
//         message: "문서이 삭제되었습니다.",
//         color: "positive"
//       });

//       // 파일 삭제 후 상태 초기화
//       selectedDoc.value = null;
//       showDeleteDocPopup.value = false;

//       // const confirmYn = 1;
//       // const file_key = kmsStore.selectedFileKey;
//       // console.log("선택된 파일", kmsStore.selectedFileKey);

//       if (kmsStore.selectedFolderKey) {
//         await kmsStore.loadDocs(kmsStore.selectedFolderKey);
//       }
//     } catch (error) {
//       console.error("문서 삭제 실패:", error);
//       $q.notify({
//         message: "문서 삭제 중 오류가 발생했습니다.",
//         color: "negative"
//       });
//     }
//   }
// };

const openDeleteKnowPopup = () => {
  showDeleteKnowPopup.value = true;
};

const closeDeleteKnowPopup = () => {
  showDeleteKnowPopup.value = false;
};

const openSearchPopup = () => {
  // if (searchQuery.value.trim()) {
  // 검색어가 있을 때만 팝업 열기
  showPopup.value = true;
  // }
};

// 독 업데이트
// 검색 옵션 변경 함수 추가
// const handleSearchOptionChange = (event) => {
//   selectedSearchOption.value = event.target.value;
// };

const closeSearchPopup = () => {
  showPopup.value = false;
  // searchQuery.value = ""; // 검색어 초기화
};

// 독 업데이트
// const handleMoveToKnowledge = async (item) => {
//   console.log("item", item);
//   try {
//     // 1. 먼저 해당 폴더 그룹 선택
//     if (item.folder_group_name) {
//       const folderGroup = kmsStore.folderGroups.find(
//         (group) => group.folder_group_name === item.folder_group_name
//       );
//       if (folderGroup) {
//         kmsStore.selectedFolderGroupKey = folderGroup.kms_folder_group_key;
//         await kmsStore.loadFolders(folderGroup.kms_folder_group_key);
//       }
//     }

//     // 2. 폴더 선택
//     if (item.folder_name) {
//       const folder = kmsStore.folders.find(
//         (folder) => folder.folder_name === item.folder_name
//       );
//       if (folder) {
//         kmsStore.selectedFolderKey = folder.kms_folder_key;
//         // 독 업데이트
//         // await kmsStore.loadDocs(folder.kms_folder_key);
//         await kmsStore.loadKnows(
//           Number(channelKey.value),
//           folder.kms_folder_key,
//           1
//         );
//       }
//     }

//     // 독 업데이트
//     // 3. 파일 선택
//     // if (item.doc_name) {
//     //   const doc = kmsStore.filteredDocs.find(
//     //     (doc) => doc.doc_name === item.doc_name
//     //   );
//     //   if (doc) {
//     //     kmsStore.selectedDocKey = doc.kms_doc_key;
//     //     await kmsStore.loadKnows(Number(channelKey.value), doc.kms_doc_key, 1);

//     //     // 4. 약간의 지연 후 지식 상세 팝업 열기
//     //     setTimeout(() => {
//     //       const targetKnow = kmsStore.selectedDocKnows.find(
//     //         (know) => know.title === item.title
//     //       );
//     //       if (targetKnow) {
//     //         openViewPopup(targetKnow);
//     //       }
//     //     }, 300);
//     //   }
//     // }

//     // know_key로 직접 지식 조회
//     const params: ReqKmsChannelKnow = {
//       channel_key: channelKey.value,
//       file_key: item.know.kms_doc_key,
//       know_key_list_str: [item.know.kms_know_key], // know_key를 배열로 전달
//       confirm_yn: 1,
//       sub_info_yn: 1, // 상세 정보 포함,
//       // limit: 1000
//       limit: 5000
//     };

//     const result = await getKmsKnowList2(params);

//     if (result && result.length > 0) {
//       const targetKnow = result[0]; // 단일 지식 정보

//       // kmsStore의 상태 업데이트
//       kmsStore.selectedDocKey = targetKnow.know.kms_doc_key;
//       // 독 업데이트
//       // await kmsStore.loadKnows(
//       //   Number(channelKey.value),
//       //   targetKnow.know.kms_doc_key,
//       //   1
//       // );

//       // 지식 상세 팝업 열기
//       openViewPopup(targetKnow);
//     } else {
//       console.warn(`지식을 찾을 수 없습니다. (know_key: ${item.know_key})`);
//       $q.notify({
//         message: "지식을 찾을 수 없습니다.",
//         color: "negative"
//       });
//     }
//   } catch (error) {
//     console.error("지식 위치로 이동 중 오류 발생:", error);
//     $q.notify({
//       message: "지식 위치로 이동하는 중 오류가 발생했습니다.",
//       color: "negative"
//     });
//   }
// };

const deleteKmsKnowItem = async () => {
  try {
    if (!selectedItem.value) {
      console.error("선택된 지식이 없습니다.");
      return;
    }

    // console.log("sss", selectedItem.value.know.kms_know_key);
    await deleteKmsKnow(selectedItem.value.know.kms_know_key);
    $q.notify({
      message: "지식이 삭제되었습니다.",
      color: "positive"
    });

    showDeleteKnowPopup.value = false;
    showViewPopup.value = false;
    // 독 업데이트
    // if (kmsStore.selectedDocKey) {
    //   await kmsStore.loadKnows(
    //     Number(channelKey.value),
    //     kmsStore.selectedDocKey,
    //     1
    //   );
    // }
    if (kmsStore.selectedFolderKey) {
      await kmsStore.loadKnows(
        Number(channelKey.value),
        kmsStore.selectedFolderKey,
        1
      );
    }
  } catch (error) {
    console.error("삭제 중 오류 발생:", error);
    $q.notify({
      message: "삭제 중 문제가 발생했습니다. 다시 시도해주세요.",
      color: "negative"
    });
  }
};

// const toggleSelectedMode = () => {
//   if (isSelectionActive.value) {
//     isSelectionActive.value = false; // 선택 해제
//     selectedItems.value = []; // 선택 항목 초기화
//   } else {
//     isSelectionActive.value = true; // 선택 모드 활성화
//   }
// };

// const toggleSelectAll = () => {
//   if (allSelected.value) {
//     selectedItems.value = []; // 전체 해제
//   } else {
//     selectedItems.value = [...kmsStore.selectedFileKnows]; // 전체 선택
//   }
// };

const isChecked = (item) => selectedItems.value.includes(item);

// ==================================================
// PDF 지식 추가 진행
// ==================================================

// Add new state variables for PDF processing
const pdfProcessing = ref(false);
const pdfProcessProgress = ref(0);
const pdfProcessStatus = ref("");
const showProgressPopup = ref(false);
const isProcessComplete = ref(false);

// PDF 지식 추가 관련 상태 변수를 배열로 변경
const pdfProgressItems = ref<
  Array<{
    id: number;
    showProgressPopup: boolean;
    pdfProcessing: boolean;
    pdfProcessProgress: number;
    pdfProcessStatus: string;
    isProcessComplete: boolean;
    // 독 업데이트
    docName?: string; // 선택적: 어떤 문서에 대한 처리인지 표시
    fileName?: string; // PDF 파일 이름 추가
  }>
>([]);

let nextProgressId = 0;

// Handle PDF processing start
const handlePdfProcessingStart = async (pdfData) => {
  // Initialize processing state
  pdfProcessing.value = true;
  pdfProcessProgress.value = 0;
  pdfProcessStatus.value = "PDF 변환 준비 중...";
  showProgressPopup.value = true;
  isProcessComplete.value = false;

  // 새로운 진행 항목 생성
  const progressId = nextProgressId++;

  // PDF 파일명 추출
  const fileName = pdfData.file?.name || "파일명 없음";

  // 진행 항목 객체 생성
  const progressItem = {
    id: progressId,
    showProgressPopup: true,
    pdfProcessing: true,
    pdfProcessProgress: 0,
    pdfProcessStatus: "PDF 변환 준비 중...",
    isProcessComplete: false,
    // 독 업데이트
    docName: pdfData.docName, // 선택적: 문서 이름 저장
    fileName: fileName
  };

  // 진행 항목 배열에 추가
  pdfProgressItems.value.push(progressItem);

  try {
    // Create FormData
    const formData = new FormData();
    // formData.append("kms_doc_key", String(pdfData.docKey));
    formData.append("kms_folder_key", String(pdfData.folderKey));
    formData.append("page_range_str", pdfData.pageRangeStr);
    formData.append("pdf_file", pdfData.file);
    formData.append("page_chunk_yn", String(pdfData.pageChunkYn));

    // Update progress (10%)
    pdfProcessProgress.value = 10;
    pdfProcessStatus.value = "PDF 파일 업로드 중...";

    // 진행 상태 업데이트 (10%)
    updateProgressItem(progressId, {
      pdfProcessProgress: 10,
      pdfProcessStatus: "PDF 파일 업로드 중..."
    });

    // API call
    await requestPdfToKnow(formData);

    // Update progress (30%)
    pdfProcessProgress.value = 30;
    pdfProcessStatus.value = "PDF 파일 처리 중...";

    // Update progress (70%)
    pdfProcessProgress.value = 70;
    pdfProcessStatus.value = "지식 등록 중...";

    // 진행 상태 업데이트 (30%)
    updateProgressItem(progressId, {
      pdfProcessProgress: 30,
      pdfProcessStatus: "PDF 파일 처리 중..."
    });

    // 진행 상태 업데이트 (70%)
    updateProgressItem(progressId, {
      pdfProcessProgress: 70,
      pdfProcessStatus: "지식 등록 중..."
    });

    // Refresh knowledge list
    // 독 업데이트
    // if (kmsStore.selectedDocKey && Number(channelKey.value)) {
    //   await kmsStore.loadKnows(
    //     Number(channelKey.value),
    //     kmsStore.selectedDocKey,
    //     1
    //   );
    // }
    if (kmsStore.selectedFolderKey && Number(channelKey.value)) {
      await kmsStore.loadKnows(
        Number(channelKey.value),
        kmsStore.selectedFolderKey,
        1
      );
    }

    // Complete progress (100%)
    pdfProcessProgress.value = 100;
    pdfProcessStatus.value = "PDF 지식 추가 완료";
    isProcessComplete.value = true;

    // 진행 완료 (100%)
    updateProgressItem(progressId, {
      pdfProcessProgress: 100,
      pdfProcessStatus: "PDF 지식 추가 완료",
      isProcessComplete: true
    });

    $q.notify({
      message: "PDF 지식이 성공적으로 추가되었습니다.",
      color: "positive"
    });

    // Auto-close progress popup after 3 seconds
    // setTimeout(() => {
    //   closeProgressPopup();
    // }, 3000);
  } catch (error) {
    console.error("PDF 지식 추가 중 에러:", error);

    // Update to error status
    pdfProcessProgress.value = 0;
    pdfProcessStatus.value = "오류 발생";
    isProcessComplete.value = true;

    $q.notify({
      message: "PDF 지식 추가 중 오류가 발생했습니다.",
      color: "negative"
    });

    // Auto-close progress popup after error display
    // setTimeout(() => {
    //   closeProgressPopup();
    // }, 3000);
  }
};

// 진행 항목 업데이트 헬퍼 함수
const updateProgressItem = (id, updates) => {
  const index = pdfProgressItems.value.findIndex((item) => item.id === id);
  if (index !== -1) {
    pdfProgressItems.value[index] = {
      ...pdfProgressItems.value[index],
      ...updates
    };
  }
};

// // Function to close progress popup
// const closeProgressPopup = () => {
//   showProgressPopup.value = false;
//   pdfProcessing.value = false;
// };

// 특정 진행 팝업 닫기 함수
const closeProgressPopup = (id) => {
  const index = pdfProgressItems.value.findIndex((item) => item.id === id);
  if (index !== -1) {
    // 팝업을 배열에서 제거
    pdfProgressItems.value.splice(index, 1);
  }
};

// 독 업데이트
// 문서 상세보기 관련 상태
// const showDocDetailModal = ref(false);
// const selectedDocDetail = ref(null);

// 독 업데이트
// 문서 상세보기 열기
// const openDocDetailView = (doc) => {
//   selectedDocDetail.value = doc;
//   showDocDetailModal.value = true;
// };

// 독 업데이트
// 문서 상세보기 닫기
// const closeDocDetailView = () => {
//   showDocDetailModal.value = false;
//   selectedDocDetail.value = null;
// };

// 독 업데이트
// 문서 정보 업데이트 처리
// const handleDocUpdate = (updatedDoc) => {
//   // 업데이트된 문서 정보를 적용
//   const index = kmsStore.filteredDocs.findIndex(
//     (doc) => doc.kms_doc_key === updatedDoc.kms_doc_key
//   );

//   if (index !== -1) {
//     kmsStore.filteredDocs[index] = updatedDoc;
//   }

//   // 필요한 경우 문서 목록을 새로고침
//   if (kmsStore.selectedFolderKey) {
//     // 독 업데이트
//     // kmsStore.loadDocs(kmsStore.selectedFolderKey);
//     kmsStore.loadKnows(Number(channelKey.value), kmsStore.selectedFolderKey, 1);
//   }

//   $q.notify({
//     message: "문서 정보가 업데이트되었습니다.",
//     color: "positive"
//   });
// };

// 지식 카드 수정-저장 시 로딩 상태
const isPageLoading = ref(false);

// 저장 시작 이벤트 처리
const handleSaveStart = () => {
  isPageLoading.value = true;
};

// 저장 완료 이벤트 처리
const handleSaveComplete = () => {
  isPageLoading.value = false;
};
</script>

<template>
  <div class="container kms-page">
    <header class="headerbox flexJustify">
      <div class="headerWrap">
        <div class="titleWrap">
          <p class="kmsTitle">AI 지식 관리 시스템</p>
          <div class="right-elements">
            <button class="confirmBtn" @click="getKmsKnowConfirmN">
              <!-- <span v-if="kmsStore.unconfirmedKnowCount > 0" class="dot"></span
            >검토 지식
            {{
              kmsStore.unconfirmedKnowCount > 0
                ? `(${kmsStore.unconfirmedKnowCount})`
                : ""
            }} -->
              <!-- <span v-if="unconfirmedKnowCountData > 0" class="dot"></span>검토
              지식
              {{
                unconfirmedKnowCountData > 0
                  ? `(${unconfirmedKnowCountData})`
                  : ""
              }} -->
              <span
                v-if="unconfirmedChannelKnowCountData > 0"
                class="dot"
              ></span
              >검토 지식
              {{
                unconfirmedChannelKnowCountData > 0
                  ? `(${unconfirmedChannelKnowCountData})`
                  : ""
              }}
            </button>
            <div class="searchInput">
              <select v-model="selectedSearchOption" class="search-select">
                <option
                  v-for="option in searchOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.name }}
                </option>
              </select>
              <input
                type="text"
                v-model="searchQuery"
                placeholder="검색어 입력"
                @keyup.enter="openSearchPopup"
              />
              <button @click="openSearchPopup" class="search-button">
                검색
              </button>
              <!-- <Search class="search-icon" @click="openSearchPopup" /> -->
            </div>
          </div>
          <!-- <div class="searchInput">
            <input
              type="text"
              v-model="searchQuery"
              placeholder="검색어 입력"
            />
            <Search class="search-icon" />
          </div> -->
        </div>
        <!-- <button class="close-btn" @click="goBack">X</button> -->
      </div>
    </header>
    <!-- <div class="header">
      <p>AI 지식 관리 시스템</p>
      <button @click="goBack">X</button>
    </div> -->

    <div class="main-content">
      <!-- left-section -->
      <div class="left-section">
        <div class="knowSortWrap detailTitle detailTitle2">
          <div class="left-section-title">폴더</div>
          <div class="folder-control-buttons">
            <button
              class="control-button"
              @click="openFolderAddPopup"
              title="폴더 추가"
            >
              <span>+</span>
            </button>
            <button
              class="control-button"
              @click="openFolderDeletePopup"
              title="폴더 삭제"
              :disabled="!isFolderSelected"
            >
              <span>-</span>
            </button>
            <button
              class="control-button"
              @click="openFolderEditPopup"
              title="폴더 이름 변경"
              :disabled="!isFolderSelected"
            >
              <span>편집</span>
            </button>
            <button
              class="control-button"
              @click="toggleMoveMode"
              title="폴더 이동"
              :class="{ active: isMoveMode }"
            >
              <span>{{ isMoveMode ? "취소" : "이동" }}</span>
            </button>
          </div>
          <Transition name="fade">
            <button
              v-if="shouldShowMoveButton"
              class="floating-move-button"
              @click="openMovePopup"
            >
              이동할 폴더가 선택되었습니다. <br />
              <span class="move-button"
                >폴더 이동하기 ({{ selectedFoldersCount }}개 선택)</span
              >
            </button>
          </Transition>

          <!-- ====================================================== 폴더 이동 팝업 -->
          <div v-if="showMovePopup" class="modal-overlay">
            <div class="move-popup">
              <div class="popup-content">
                <div class="popup-header">
                  <p style="font-weight: bold; font-size: 20px">
                    이동할 폴더 위치 선택
                  </p>
                </div>
                <!-- <h3>이동할 폴더 위치 선택</h3> -->
                <!-- 현재 선택된 폴더들 표시 -->
                <!-- <div class="selected-folders">
                    <h4>선택된 폴더</h4>
                    <div
                      v-for="folderKey in selectedFoldersArray"
                      :key="folderKey"
                    >
                      {{ getFolderName(folderKey) }}
                    </div>
                  </div> -->

                <!-- 폴더 그룹 선택 -->
                <div class="target-selection">
                  <!-- <h4>이동할 위치 선택</h4> -->
                  <!-- <select
                    v-model="kmsStore.selectedFolderGroupKey"
                    class="group-select"
                  >
                    <option value="">폴더 그룹 선택</option>
                    <option
                      v-for="group in kmsStore.folderGroups"
                      :key="group.kms_folder_group_key"
                      :value="group.kms_folder_group_key"
                    >
                      <span
                        ><KMSFolderIcon /> {{ group.folder_group_name }}</span
                      >
                    </option>
                  </select> -->

                  <!-- 폴더 그룹 선택 추가 -->
                  <select
                    v-model="targetFolderGroupKey"
                    class="group-select"
                    @change="handleTargetFolderGroupChange"
                  >
                    <option value="">폴더 그룹 선택</option>
                    <option
                      v-for="group in kmsStore.folderGroups"
                      :key="group.kms_folder_group_key"
                      :value="group.kms_folder_group_key"
                    >
                      {{ group.folder_group_name }}
                    </option>
                  </select>

                  <!-- 폴더 목록 -->
                  <div class="font-size folder-list">
                    <div
                      class="folder-option root-option"
                      @click="targetFolderKey = '0'"
                      :class="{ selected: targetFolderKey === '0' }"
                    >
                      최상위 폴더로 이동
                    </div>
                    <!-- <div
                      v-for="folder in availableFolders"
                      :key="folder.kms_folder_key"
                      class="folder-option"
                      @click="targetFolderKey = folder.kms_folder_key"
                      :class="{
                        selected: targetFolderKey === folder.kms_folder_key
                      }"
                    >
                      {{ folder.folder_name }}
                    </div> -->
                    <div class="scroll">
                      <!-- <div
                        v-if="targetFolderGroupKey"
                        class="folder-tree-container"
                      > -->
                      <div class="folder-tree-container">
                        <!-- <folder-tree-item
                          v-for="folder in filteredFolders"
                          :key="folder.kms_folder_key"
                          :folder="folder"
                          :level="0"
                          :is-selectable="true"
                          :is-selected="
                            targetFolderKey === folder.kms_folder_key
                          "
                          @target-folder-select="selectTargetFolder"
                        /> -->
                        <!-- <folder-tree-item
                          v-for="folder in kmsStore.filteredFolderTrees"
                          :key="folder.kms_folder_key"
                          :folder="folder"
                          :level="0"
                          :is-selectable="true"
                          :is-selected="
                            targetFolderKey === folder.kms_folder_key
                          "
                          @target-folder-select="selectTargetFolder"
                        /> -->
                        <folder-tree-item
                          v-for="folder in localFilteredFolderTrees"
                          :key="folder.kms_folder_key"
                          :folder="folder"
                          :level="0"
                          :is-selectable="true"
                          :is-selected="
                            targetFolderKey === folder.kms_folder_key
                          "
                          @target-folder-select="selectTargetFolder"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="popup-footer">
                  <!-- <button
                    @click="handleFolderMove"
                    class="submit-btn"
                    :disabled="!isValidMove"
                  > -->
                  <button @click="handleFolderMove" class="submit-btn">
                    이동
                  </button>
                  <button @click="closeMovePopup" class="cancel-btn">
                    취소
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="folder-control-buttons"> -->
          <!-- <button
              class="control-button"
              @click="openFolderAddPopup"
              title="폴더 추가"
              :disabled="!isFolderGroupSelected"
            > -->
          <!-- <button
              class="control-button"
              @click="openFolderAddPopup"
              title="폴더 추가"
            >
              <span>추가</span>
            </button>
            <button
              class="control-button"
              @click="openFolderEditPopup"
              title="폴더 이름 변경"
              :disabled="!isFolderSelected"
            >
              <span>수정</span>
            </button>
            <button
              class="control-button"
              @click="openFolderDeletePopup"
              title="폴더 삭제"
              :disabled="!isFolderSelected"
            >
              <span>삭제</span>
            </button> -->
          <!-- </div> -->
        </div>

        <!-- 폴더 트리 영역을 감싸는 컨테이너 추가 -->
        <div
          class="folder-container"
          :class="{ 'drop-area-active': isDraggingOverRoot }"
          @dragover.prevent="handleRootDragOver"
          @dragleave.prevent="handleRootDragLeave"
          @drop="handleRootDrop"
        >
          <!-- 폴더 그룹 선택 -->
          <div class="bottom-line">
            <div class="select-wrapper">
              <div class="select-icon">
                <KMSFolderIcon />
              </div>
              <select
                v-model="kmsStore.selectedFolderGroupKey"
                class="select-box"
              >
                <option
                  v-for="group in kmsStore.folderGroups"
                  :key="group.kms_folder_group_key"
                  :value="group.kms_folder_group_key"
                >
                  <span
                    ><KMSFolderIcon /> {{ group.folder_group_name }}
                    <p class="folderGroupKey">
                      ({{ group.kms_folder_group_key }})
                    </p></span
                  >
                </option>
              </select>
            </div>
          </div>
          <div class="scroll">
            <folder-tree-item
              v-for="(folder, index) in kmsStore.filteredFolderTrees"
              :key="folder.kms_folder_key"
              :folder="folder"
              :level="0"
              :is-first-child="index === 0"
              :is-multi-select="isMultiSelect"
              :is-selected="selectedFolders.has(folder.kms_folder_key)"
              @folder-select="handleFolderSelect"
            />
          </div>
        </div>

        <!-- 휴지통 hidden 처리 -->
        <!-- <div class="section-footer knowSortWrap">
          <button class="edit-button"><Delete /> 휴지통1</button>
        </div> -->
      </div>

      <!-- center-section -->
      <!-- 문서 목록-->
      <!-- // 독 업데이트 -->
      <!-- <div class="center-section">
        <div class="knowSortWrap detailTitle detailTitle2">
          <div class="center-section-title">
            <span> 문서</span>
          </div>
          <div>
            <div class="editBtn-wrap">
              <button class="control-button" @click="openKnowAddPopup">
                +
              </button>
              <button
                class="control-button"
                @click="handleDeleteDoc"
                :disabled="!isDocSelected"
              >
                -
              </button>
              <button
                class="control-button"
                @click="openKnowDocAddPopup"
                :disabled="!selectedDoc"
              >
                편집
              </button>
              <button
                class="control-button"
                @click="toggleDocMoveMode"
                :class="{ active: isDocMoveMode }"
                :disabled="!isDocSelected"
              >
                {{ isDocMoveMode ? "취소" : "이동" }}
              </button>
              <Transition name="fade">
                <button
                  v-if="selectedDocsForMove.size > 0"
                  class="floating-move-button"
                  @click="openDocMovePopup"
                >
                  이동할 문서가 선택되었습니다. <br />
                  <span class="move-button"
                    >선택한 문서 이동하기 ({{ selectedDocsForMove.size }}개
                    선택)</span
                  >
                </button>
              </Transition>
            </div>
            <div v-if="showDocMovePopup" class="modal-overlay">
              <div class="move-popup">
                <div class="popup-content">
                  <div class="popup-header">
                    <p style="font-weight: bold; font-size: 20px">
                      이동할 문서 위치 선택
                    </p>
                  </div>
                  <div class="target-selection">
                    <select v-model="targetDocFolderKey" class="group-select">
                      <option value="">폴더 선택</option>
                      <option
                        v-for="folder in kmsStore.folders"
                        :key="folder.kms_folder_key"
                        :value="folder.kms_folder_key"
                      >
                        {{ folder.folder_name }}
                      </option>
                    </select>
                  </div>

                  <div class="popup-footer">
                    <button @click="handleDocMove" class="submit-btn">
                      이동
                    </button>
                    <button @click="closeDocMovePopup" class="cancel-btn">
                      취소
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="doc-list">
          <div class="bottom-line">
            <div class="docNameWrap">
              <div v-if="kmsStore.selectedFolderKey" class="ameWrap">
                <div>
                  <span
                    ><Document />
                    {{ kmsStore.selectedFolder?.folder_name }}</span
                  >
                  <span
                    class="docLength"
                    v-if="kmsStore.filteredDocs.length > 0"
                  >
                    ({{ kmsStore.filteredDocs.length }})
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="scroll">
            <div v-if="!kmsStore.selectedFolder" class="no-results">
              <p>폴더를 선택해주세요.</p>
            </div>
            <div
              v-else-if="kmsStore.filteredDocs.length === 0"
              class="no-results"
            >
              <p>등록된 문서가 없습니다.</p>
            </div>

            <div
              v-for="doc in kmsStore.filteredDocs"
              :key="doc.kms_doc_key"
              class="file-item"
              :class="{
                active: selectedDoc?.kms_doc_key === doc.kms_doc_key,
                'move-mode': isDocMoveMode
              }"
              @click="
                !isDocMoveMode && kmsStore.selectDoc(doc.kms_doc_key);
                !isDocMoveMode && handleFileClick(doc);
              "
            >
              <div v-if="isDocMoveMode" class="doc-move-checkbox">
                <input
                  type="checkbox"
                  :checked="selectedDocsForMove.has(doc.kms_doc_key)"
                  @change="
                    handleDocSelect({
                      docKey: doc.kms_doc_key,
                      selected: $event.target.checked
                    })
                  "
                />
              </div>
              <div class="doc-info">
                <strong class="kmsDocTextWrap">
                  <span class="kmsDocText"
                    ><KMSDocIcon />
                    {{ doc.doc_name.length > 0 ? doc.doc_name : "제목없음" }}
                    <span class="doc-key">{{ doc.kms_doc_key }}</span>
                  </span>
                  <span class="doc-count"> </span>
                </strong>
                <div class="doc-actions">
                  <button
                    class="detail-button"
                    @click.stop="openDocDetailView(doc)"
                    title="상세보기"
                  >
                    <SettingIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- =========================================================================================== -->
      <!-- 파일 지식 수정 팝업-->
      <!-- // 독 업데이트 -->
      <!-- <template v-if="showKnowDocAddPopup">
        <div class="modal-overlay"></div>
        <div v-if="showKnowDocAddPopup" class="docAddPopup">
          <div class="popup-content">
            <div class="popup-header">
              <p style="font-weight: bold; font-size: 20px">문서명 변경</p>
              <div @click="closeKnowDocAddPopup" class="cursorP">
                <CloseIcon />
              </div>
            </div>
            <div class="popup-body">
              <input
                id="knowDocNameInput"
                v-model="newKnowDocName"
                :placeholder="
                  kmsStore.selectedDoc.doc_name || '변경할 폴더명 입력'
                "
                @keyup.enter="editDoc"
                class="folder-name-input"
              />
            </div>
            <div class="popup-footer">
              <button type="button" class="submit-btn" @click="editDoc">
                수정
              </button>
              <button
                type="button"
                class="cancel-btn"
                @click="closeKnowDocAddPopup"
              >
                취소
              </button>
            </div>
          </div>
        </div>
      </template> -->

      <!-- // 독 업데이트 -->
      <!-- 문서 삭제 확인 팝업 -->
      <!-- <template v-if="showDeleteDocPopup">
        <div class="modal-overlay"></div>
        <div class="docAddPopup">
          <div class="popup-content">
            <div class="popup-header">
              <p style="font-weight: bold; font-size: 20px">문서 삭제</p>
              <div @click="closeDeleteDocPopup" class="cursorP">
                <CloseIcon />
              </div>
            </div>
            <div class="popup-body" style="margin-top: 10px">
              <p style="color: black">정말로 이 문서를 삭제하시겠습니까?</p>
            </div>
            <div class="popup-footer">
              <button type="button" class="submit-btn" @click="confirmDelete">
                확인
              </button>
              <button
                type="button"
                class="cancel-btn"
                @click="closeDeleteDocPopup"
              >
                취소
              </button>
            </div>
          </div>
        </div>
      </template> -->

      <!-- 지식 삭제 확인 팝업 -->
      <div v-if="showDeleteKnowPopup" class="docAddPopup">
        <div class="popup-content">
          <div class="popup-header">
            <p style="font-weight: bold; font-size: 20px">지식 삭제</p>
            <!-- <button @click="closeDeleteKnowPopup" class="close-button">
              X
            </button> -->
            <div @click="closeDeleteKnowPopup" class="cursorP">
              <CloseIcon />
            </div>
          </div>
          <div class="popup-body" style="margin-top: 10px">
            <!-- <div class="popup-body-box"> -->
            <!-- <p>
                <strong>{{ selectedFile?.doc_name }}</strong>
              </p> -->
            <p style="color: black">정말로 이 지식을 삭제하시겠습니까?</p>
            <!-- </div> -->
          </div>
          <div class="popup-footer">
            <button type="button" class="submit-btn" @click="deleteKmsKnowItem">
              확인
            </button>
            <button
              type="button"
              class="cancel-btn"
              @click="closeDeleteKnowPopup"
            >
              취소
            </button>
          </div>
        </div>
      </div>

      <!-- middle-section -->
      <div class="middle-section">
        <div class="knowListWrap knowSortWrap detailTitle detailTitle3">
          <!-- <span v-if="kmsStore.selectedFileKey == null"> 지식 목록 </span> -->
          <div class="middle-section-title">
            <span> 지식 </span>
            <!-- <button
              class="control-button"
              @click="getKmsKnowConfirmN"
              style="
                background-color: #dfdfdf !important;
                margin-left: 10px;
                font-weight: bold;
              "
            >
              검토 지식
              {{
                kmsStore.unconfirmedKnowCount > 0
                  ? `(${kmsStore.unconfirmedKnowCount})`
                  : ""
              }} -->
            <!-- {{
                kmsStore.loadKmsKnows.length > 0
                  ? `(${kmsStore.loadKmsKnows.length})`
                  : ""
              }} -->
            <!-- </button> -->
          </div>
          <!-- <select @change="handleSelectChange">
              <option value="knowConfirmY">지식 목록</option>
              <option value="knowConfirmN">검토 지식</option>
            </select> -->
          <!-- <button @click="getKmsKnowConfirmY">지식 목록</button> -->

          <div class="knowSortWrap2">
            <button
              class="control-button"
              @click="toggleKnowMoveMode"
              :class="{ active: isKnowMoveMode }"
              :disabled="!isKnowSelected"
            >
              {{ isKnowMoveMode ? "취소" : "이동" }}
            </button>
            <Transition name="fade">
              <button
                v-if="selectedKnowForMove.size > 0"
                class="floating-move-button"
                @click="openKnowMovePopup"
              >
                이동할 지식이 선택되었습니다. <br />
                <span class="move-button">선택한 지식 이동하기</span>
              </button>
            </Transition>

            <Transition name="fade">
              <button
                v-if="selectedKnowsForPriority.size > 0"
                class="floating-move-button"
                @click="openPriorityPopup"
              >
                선택한 지식 우선순위 분류하기 ({{
                  selectedKnowsForPriority.size
                }}개 선택)
              </button>
            </Transition>
            <!-- // 독 업데이트 -->
            <!-- <button
              class="control-button"
              @click="openKnowItemAddPopup"
              :disabled="!kmsStore.selectedDocKey"
              :title="
                kmsStore.selectedDocKey
                  ? '지식 추가하기'
                  : '지식을 추가하려면 먼저 문서를 선택하세요.'
              "
            > -->
            <button
              class="control-button"
              @click="openKnowItemAddPopup"
              :disabled="!kmsStore.selectedFolderKey"
              :title="
                kmsStore.selectedFolderKey
                  ? '지식 추가하기'
                  : '지식을 추가하려면 먼저 폴더를 선택하세요.'
              "
            >
              지식 추가
            </button>
            <div class="sort-select-wrapper">
              <select @change="handleSortChange" :value="sortOption">
                <option value="date">시간순</option>
                <option value="name">가나다순</option>
              </select>
            </div>
            <!-- <div class="current-path">{{ kmsStore.currentPath }}</div> -->
            <!-- 지식만 검색 우선 주석!!! -->
            <!-- <div class="searchInput">
              <input
                type="text"
                v-model="searchQuery"
                placeholder="검색어 입력"
              />
              <button>검색</button>
            </div> -->
          </div>
        </div>

        <div class="knowContListWrap">
          <div class="bottom-line">
            <div class="knowNavWrap">
              <div class="knowDocNamep-wrap">
                <div class="knowDocName">
                  <!-- // 독 업데이트 -->
                  <!-- <div v-if="kmsStore.selectedDocKey" class="Doc-gap">
                    <p>
                      <KnowList /> {{ kmsStore.selectedDoc?.doc_name }} ({{
                        kmsStore.selectedDocKnows.length
                      }})
                    </p>
                  </div> -->
                  <div v-if="kmsStore.selectedFolderKey" class="Doc-gap">
                    <p>
                      <KnowList />
                      {{ kmsStore.selectedFolder?.folder_name }} ({{
                        kmsStore.selectedFolderKnows.length
                      }})
                    </p>
                  </div>
                </div>
                <div class="knowNavWrapRight">
                  <!-- <button @click="openKnowAddPopup">지식추가</button> -->
                  <!-- 현재 경로 -->
                  <!-- <div class="current-path">{{ kmsStore.currentPath }}</div> -->
                  <!-- <select @change="handleSortChange" :value="sortOption">
                    <option value="date">시간순</option>
                    <option value="name">가나다순</option>
                  </select> -->
                </div>
              </div>
            </div>
          </div>

          <!-- PDF 지식 추가 진행 팝업 -->
          <div v-for="(item, index) in pdfProgressItems" :key="item.id">
            <div
              v-if="item.showProgressPopup"
              class="pdf-popup-container"
              :style="{
                bottom: `${20 + (pdfProgressItems.length - index - 1) * 110}px`,
                right: '20px',
                zIndex: 2000 + index
              }"
            >
              <div class="progress-popup">
                <div class="progress-header">
                  <span>
                    <!-- PDF 지식 추가 -->
                    <!-- 파일명 표시 부분 추가 -->
                    <div class="file-name" :title="item.fileName">
                      <span class="file-label"></span> {{ item.fileName }}
                    </div>
                    {{ item.isProcessComplete ? "완료" : "진행 중" }}
                    <span v-if="item.docName">({{ item.docName }})</span>
                  </span>
                  <button
                    @click="closeProgressPopup(item.id)"
                    class="close-progress-btn"
                  >
                    X
                  </button>
                </div>
                <div class="progress-body">
                  <div class="progress-status">{{ item.pdfProcessStatus }}</div>
                  <div class="progress-bar-container">
                    <div
                      class="progress-bar"
                      :style="{ width: `${item.pdfProcessProgress}%` }"
                      :class="{ complete: item.isProcessComplete }"
                    ></div>
                  </div>
                  <div class="progress-percentage">
                    {{ item.pdfProcessProgress }}%
                  </div>
                </div>
                <div v-if="item.isProcessComplete" class="progress-actions">
                  <button @click="closeProgressPopup(item.id)" class="ok-btn">
                    확인
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- CreateKmsFile 컴포넌트 -->
          <!-- // 독 업데이트 -->
          <!-- <CreateKmsDoc
            v-if="showKnowAddPopup"
            @close="closeKnowAddPopup"
            :selectedFolder="kmsStore.selectedFolderKey"
          /> -->
          <KmsKnowItemAdd
            v-if="showKnowItemAddPopup"
            :channelKey="channelKey"
            @close="closeKnowItemAddPopup"
            @pdfProcessingStart="handlePdfProcessingStart"
          />

          <!-- 검색 팝업 컴포넌트 -->
          <!-- // 독 업데이트 -->
          <!-- <SearchPopup
            :show="showPopup"
            :searchQuery="searchQuery"
            :searchOption="selectedSearchOption"
            @close="closeSearchPopup"
            @moveToKnowledge="handleMoveToKnowledge"
          /> -->
          <SearchPopup
            :show="showPopup"
            :searchQuery="searchQuery"
            :searchOption="selectedSearchOption"
            @close="closeSearchPopup"
          />

          <!-- 검토 지식 목록 리스트 -->
          <div v-if="openConfirmKnowPopYn">
            <div class="backgroundLayout" @click="closeConfirmKnowPop"></div>
            <div class="confimrKnowPopup">
              <div class="modal-header confirmPopHeaderWrap">
                <p style="font-weight: bold; font-size: 20px">지식 검토</p>
                <!-- <button class="close-btn" @click="closeDocKnowPop">X</button> -->
                <div @click="closeConfirmKnowPop" class="cursorP">
                  <CloseIcon />
                </div>
              </div>
              <div class="confirmBtnWrap">
                <div>
                  <input
                    class="allKnowCheckInput"
                    type="checkbox"
                    v-model="allSelected"
                  />
                  <label for="selectAll">전체 선택</label>
                  <!-- <button
                    @click="toggleSelectedMode"
                    :class="{ selected: isSelectedMode }"
                  >
                    {{ isSelectedMode ? "선택 해제" : "선택" }}
                  </button>
                  <button
                    @click="toggleSelectAll"
                    :class="{ selected: isAllSelected }"
                  >
                    {{ isAllSelected ? "전체 선택 해제" : "전체 선택" }}
                  </button> -->
                </div>
                <p>{{ selectedItems.length }}개의 지식이 선택되었습니다.</p>
              </div>

              <div class="knowledge-list confimrKnowList">
                <!-- <div
                  class="knowlistWrapStyle"
                  v-for="know in kmsStore.selectedFileKnows"
                  :class="{ selected: isItemSelected(know) }"
                  @click="
                    isSelectedMode
                      ? toggleItemSelection(know)
                      : openViewPopup(know)
                  "
                > -->
                <!-- <div
                  class="knowlistWrapStyle"
                  v-for="know in kmsStore.selectedFileKnows"
                > -->
                <!-- <div
                  class="knowlistWrapStyle"
                  v-for="know in [...confirmKnowlist].sort(
                    (a, b) => new Date(b.cre_date) - new Date(a.cre_date)
                  )"
                > -->
                <!-- <div
                  class="knowlistWrapStyle"
                  v-for="know in [...confirmKnowlist].sort(
                    (a, b) =>
                      new Date(b.know.cre_date).getTime() -
                      new Date(a.know.cre_date).getTime()
                  )"
                > -->
                <!-- 검토지식 리스트들 목록 -->
                <div
                  class="knowlistWrapStyle"
                  v-for="know in paginatedUnconfirmedKnows"
                  :key="know.know.kms_know_key"
                >
                  <input
                    class="knowCheckInput"
                    type="checkbox"
                    :checked="isChecked(know.know)"
                    @change="toggleItemSelection(know.know)"
                  />
                  <div
                    :key="know.know.kms_know_key"
                    class="knowledge-box knowCheckCont g bents"
                    @click="openViewPopup(know)"
                  >
                    <div class="knowNavWrap">
                      <div class="knowlist_title">
                        <strong>{{ know.know.title }}</strong>
                        <span class="know-key">{{
                          know.know.kms_know_key
                        }}</span>
                        <span class="know-key">{{ know?.vaild_yn }}</span>
                      </div>
                    </div>

                    <!-- 날짜 -->
                    <div class="date-info">
                      <p>{{ formatDate(know.know.cre_date) }}</p>
                    </div>
                    <!-- 요약 -->
                    <div class="summary">
                      <p>{{ know.know.summary }}</p>
                    </div>
                    <!-- 내용 -->
                    <!-- <div class="body">
                      <p>{{ know.know.body }}</p>
                    </div> -->
                    <div v-if="know.know.must_text" class="must-text">
                      <strong>필수 표시:</strong> {{ know.know.must_text }}
                    </div>
                    <div>
                      <div class="keywords">
                        <span
                          v-for="keyword in know.know.keyword_list"
                          :key="keyword"
                          class="keyword"
                        >
                          #{{ keyword }}
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="know.know.quest_list && know.know.quest_list.length"
                      class="quest-list"
                    >
                      <!-- <div class="keywords"> -->
                      <span
                        v-for="quest in know.know.quest_list"
                        :key="quest"
                        class="quest-item"
                      >
                        {{ quest }}
                      </span>
                      <!-- </div> -->
                    </div>
                    <div class="knowNavWrap">
                      <!-- 첨부파일 정보 리스트 -->
                      <div class="attachments">
                        <button
                          v-if="know.know.img_filekey_list?.length > 0"
                          class="border-button"
                        >
                          <ImageIcon /> {{ know.know.img_filekey_list?.length }}
                        </button>
                        <button
                          v-if="know.know.attach_filekey_list?.length > 0"
                          class="border-button"
                        >
                          <File /> {{ know.know.attach_filekey_list?.length }}
                        </button>
                        <button
                          v-if="know.know.linkkey_list?.length > 0"
                          class="border-button"
                        >
                          <Link /> {{ know.know.linkkey_list?.length }}
                        </button>
                      </div>
                      <!-- 첨부파일 정보 리스트-->
                      <!-- 파일 원본 -->
                      <div>
                        <!-- <button class="edit-button">{{ know.doc_type }}</button> -->
                      </div>
                      <!-- 파일 원본 -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- 페이지네이션 추가 -->
              <div class="section-footer knowSortWrap">
                <div
                  class="pagination"
                  style="transform: scale(0.8); transform-origin: center"
                >
                  <PaginationRoot
                    :key="unconfirmedCurrentPage"
                    :total="unconfirmedTotalPages * 10"
                    :sibling-count="1"
                    show-edges
                    :default-page="unconfirmedCurrentPage"
                    @update:page="handleUnconfirmedPageChange"
                  >
                    <PaginationList
                      v-slot="{ items }"
                      class="flex items-center gap-2 text-white"
                    >
                      <PaginationFirst
                        class="w-9 h-9 flex items-center justify-center disabled:opacity-50 focus-within:outline focus-within:outline-1 focus-within:outline-offset-1 rounded"
                      >
                        <Icon icon="radix-icons:double-arrow-left" />
                      </PaginationFirst>
                      <PaginationPrev
                        class="w-9 h-9 flex items-center justify-center mr-4 disabled:opacity-50 focus-within:outline focus-within:outline-1 focus-within:outline-offset-1 rounded"
                      >
                        <Icon icon="radix-icons:chevron-left" />
                      </PaginationPrev>
                      <template v-for="(page, index) in items">
                        <PaginationListItem
                          v-if="page.type === 'page'"
                          :key="index"
                          class="w-9 h-9 border rounded data-[selected]:bg-white data-[selected]:text-blackA11 hover:bg-white/10 transition focus-within:outline focus-within:outline-1 focus-within:outline-offset-1"
                          :value="page.value"
                        >
                          {{ page.value }}
                        </PaginationListItem>
                        <PaginationEllipsis
                          v-else
                          :key="page.type"
                          :index="index"
                          class="w-9 h-9 flex items-center justify-center"
                        >
                          &#8230;
                        </PaginationEllipsis>
                      </template>
                      <PaginationNext
                        class="w-9 h-9 flex items-center justify-center ml-4 disabled:opacity-50 focus-within:outline focus-within:outline-1 focus-within:outline-offset-1 rounded"
                      >
                        <Icon icon="radix-icons:chevron-right" />
                      </PaginationNext>
                      <PaginationLast
                        class="w-9 h-9 flex items-center justify-center disabled:opacity-50 focus-within:outline focus-within:outline-1 focus-within:outline-offset-1 rounded"
                      >
                        <Icon icon="radix-icons:double-arrow-right" />
                      </PaginationLast>
                    </PaginationList>
                  </PaginationRoot>
                </div>
              </div>

              <!-- <button @click="aiRefineBtn">aiChannelRefine 테스트</button> -->
              <!-- <div class="btnWrap"> -->
              <div class="form-actions">
                <button
                  type="submit"
                  class="submit-btn"
                  @click="batchApproveKnow"
                  :disabled="isGenerating"
                >
                  {{ isGenerating ? "생성 중..." : "승인" }}
                </button>
                <button
                  type="button"
                  class="cancel-btn"
                  @click="batchRejectKnow"
                >
                  반려
                </button>
                <button
                  type="button"
                  class="cancel-btn"
                  @click="closeConfirmKnowPop"
                >
                  닫기
                </button>
              </div>
              <!-- <button @click="closeFileKnowPop">취소</button> -->
              <!-- </div> -->
            </div>
            <div v-if="isGenerating" class="spinner-overlay">
              <div class="spinner"></div>
              <p>생성 중입니다...</p>
            </div>
          </div>

          <!-- 지식 목록 리스트 -->
          <div v-else class="scroll">
            <!-- 폴더/파일이 선택되지 않은 초기 상태 -->
            <div v-if="!kmsStore.selectedFolderKey" class="no-results">
              <!-- <p>폴더와 파일을 선택해주세요.</p> -->
              <!-- <p>폴더와 문서를 선택해주세요.</p> -->
              <!-- 독 업데이트 -->
              <p>폴더를 선택해주세요.</p>
            </div>

            <!-- 독 업데이트 -->
            <!-- <div v-else-if="!kmsStore.selectedDocKey" class="no-results">
              <p>문서를 선택해주세요.</p>
              <div class="selection-guide">
                <i class="arrow-left-icon"></i>
                <p>왼쪽에서 문서를 선택하면 지식을 추가할 수 있습니다.</p>
              </div>
            </div> -->

            <!-- 폴더/파일은 선택되었지만 검색 결과가 없는 경우 -->
            <div v-else-if="sortedKnows.length === 0" class="no-results">
              <p>지식이 없습니다.</p>
            </div>

            <!-- 독 업데이트 -->
            <!-- <div
              v-else-if="
                !kmsStore.selectedDocKnows ||
                kmsStore.selectedDocKnows.length === 0
              "
              class="no-results"
            > -->
            <div
              v-else-if="
                !kmsStore.selectedFolderKnows ||
                kmsStore.selectedFolderKnows.length === 0
              "
              class="no-results"
            >
              <p>등록된 지식이 없습니다.</p>
            </div>
            <!-- 지식목록 리스트들 목록 -->
            <div v-if="sortedKnows?.length" class="knowledge-list">
              <div
                v-for="know in paginatedKnows"
                :key="know.know.kms_know_key"
                class="knowledge-item-wrapper"
              >
                <div v-if="isKnowMoveMode" class="know-priority-checkbox">
                  <input
                    type="checkbox"
                    :checked="selectedKnowForMove.has(know.know.kms_know_key)"
                    :disabled="
                      selectedKnowForMove.size > 0 &&
                      !selectedKnowForMove.has(know.know.kms_know_key)
                    "
                    @change="
                      handleKmsKnowSelect({
                        knowKey: know.know.kms_know_key,
                        selected: $event.target.checked
                      })
                    "
                  />
                  <!-- <input type="checkbox" /> -->
                </div>

                <!-- 우선순위 모드일 때 체크박스 추가 -->
                <div v-if="isPriorityMode" class="know-priority-checkbox">
                  <input
                    type="checkbox"
                    :checked="
                      selectedKnowsForPriority.has(know.know.kms_know_key)
                    "
                    @change="
                      handleKnowSelect({
                        knowKey: know.know.kms_know_key,
                        selected: $event.target.checked
                      })
                    "
                  />
                </div>
                <div class="knowledge-box" @click="openViewPopup(know)">
                  <div class="knowNavWrap">
                    <div class="knowlist_title">
                      <strong>{{ know.know.title }}</strong>
                      <span class="know-key">{{ know.know.kms_know_key }}</span>
                      <span
                        class="priority-badge"
                        :class="'priority-' + know.know.valid_yn"
                      >
                        {{ getPriorityText(know.know.valid_yn) }}
                      </span>
                    </div>
                    <div class="writer-info">
                      <!-- 대표님 피드백 : 일단 주석 -->
                      <!-- <span>작성자: {{ getUserName(know.cre_user_key) }}</span> -->
                    </div>
                  </div>

                  <!-- 날짜 -->
                  <div class="date-info">
                    <p>{{ formatDate(know.know.cre_date) }}</p>
                  </div>

                  <!-- 요약 -->
                  <div class="summary">
                    <p>{{ know.know.summary }}</p>
                  </div>
                  <!-- 내용 -->
                  <!-- <div class="body">
                    <p>{{ know.know.body }}</p>
                  </div> -->
                  <div v-if="know.know.must_text" class="must-text">
                    <strong>필수 표시:</strong> {{ know.know.must_text }}
                  </div>
                  <!-- 키워드 -->
                  <div>
                    <div class="keywords">
                      <span
                        v-for="keyword in know.know.keyword_list"
                        :key="keyword"
                        class="keyword"
                      >
                        #{{ keyword }}
                      </span>
                    </div>
                  </div>
                  <!-- 추천 질문 -->
                  <div class="questListWrap">
                    <div
                      v-if="know.know.quest_list && know.know.quest_list.length"
                      class="quest-list"
                    >
                      <span
                        v-for="quest in know.know.quest_list"
                        :key="quest"
                        class="quest-item"
                      >
                        {{ quest }}
                      </span>
                    </div>
                    <!-- <span class="questNum"
                      >+ {{ know.know.quest_list.length }}
                    </span> -->
                  </div>
                  <!-- <div>
                    <div class="keywords">
                      <span
                        v-for="quest in know.know.quest_list"
                        :key="quest"
                        class="keyword"
                      >
                        Q.{{ quest }}
                      </span>
                    </div>
                  </div> -->
                  <div class="knowNavWrap">
                    <!-- 첨부파일 정보 리스트 -->
                    <div class="attachments">
                      <button
                        class="border-button"
                        v-if="know.know.img_filekey_list?.length"
                      >
                        <ImageIcon /> {{ know.know.img_filekey_list?.length }}
                      </button>
                      <button
                        class="border-button"
                        v-if="know.know.attach_filekey_list?.length"
                      >
                        <File /> {{ know.know.attach_filekey_list?.length }}
                      </button>
                      <button
                        class="border-button"
                        v-if="know.know.linkkey_list?.length"
                      >
                        <Link /> {{ know.know.linkkey_list?.length }}
                      </button>
                    </div>
                    <div>
                      <!-- <button class="file-button">PDF</button> -->
                    </div>
                  </div>
                </div>
              </div>

              <!-- 우선순위 설정 팝업 (기존 이동 팝업 스타일 활용) -->
              <div v-if="showPriorityPopup" class="modal-overlay">
                <div class="move-popup">
                  <div class="popup-content">
                    <div class="popup-header">
                      <p style="font-weight: bold; font-size: 20px">
                        지식 우선순위 설정
                      </p>
                    </div>
                    <!-- <h3>우선순위 설정</h3> -->

                    <div class="target-selection">
                      <div class="priority-options">
                        <button
                          class="priority-option"
                          :class="{ selected: selectedPriority === 'high' }"
                          @click="selectedPriority = 'high'"
                        >
                          상
                        </button>
                        <button
                          class="priority-option"
                          :class="{ selected: selectedPriority === 'medium' }"
                          @click="selectedPriority = 'medium'"
                        >
                          중
                        </button>
                        <button
                          class="priority-option"
                          :class="{ selected: selectedPriority === 'low' }"
                          @click="selectedPriority = 'low'"
                        >
                          하
                        </button>
                      </div>
                    </div>

                    <div class="popup-footer">
                      <button
                        @click="handlePrioritySave"
                        class="submit-btn"
                        :disabled="!selectedPriority"
                      >
                        저장
                      </button>
                      <button @click="closePriorityPopup" class="cancel-btn">
                        취소
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- ====================================================== 지식 이동 팝업 -->

              <!-- <MoveKmsPop
                :selectedFolders="selectedFolders"
                :selectedMoveKnowKey="selectedMoveKnowKey"
                :handleKnowMove="handleKnowMove"
                v-model:open="showKnowMovePopup"
              /> -->
              <MoveKmsPop
                :selectedFolders="selectedFolders"
                :selectedMoveKnowKey="selectedMoveKnowKey"
                :handleKnowMove="handleKnowMove"
                :toggleKnowMoveMode="toggleKnowMoveMode"
                v-model:open="showKnowMovePopup"
              />
              <!-- <div v-if="showKnowMovePopup" class="modal-overlay">
                <div class="move-popup">
                  <div class="popup-content">
                    <div class="popup-header">
                      <p style="font-weight: bold; font-size: 20px">
                        이동할 문서 위치 선택
                      </p>
                    </div>

                    <div class="target-selection">
                      <select v-model="targetDocFolderKey" class="group-select">
                        <option value="">문서 선택</option>
                        <option
                          v-for="doc in kmsStore.docs.filter(
                            (item) => item.delete_yn === 0
                          )"
                          :key="doc.kms_doc_key"
                          :value="doc.kms_doc_key"
                        >
                          {{ doc.doc_name }}
                        </option>
                      </select>
                    </div>

                    <div class="popup-footer">
                      <button @click="handleKnowMove" class="submit-btn">
                        저장
                      </button>
                      <button @click="closeKnowMovePopup" class="cancel-btn">
                        취소
                      </button>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>

          <!-- 팝업 -->
          <template v-if="showFolderAddPopup">
            <div class="modal-overlay"></div>
            <div class="folderAddpopup">
              <div class="popup-content">
                <div class="popup-header">
                  <p style="font-weight: bold; font-size: 20px">폴더 추가</p>
                  <!-- <button @click="closeFolderAddPopup">X</button> -->
                  <div @click="closeFolderAddPopup" class="cursorP">
                    <CloseIcon />
                  </div>
                </div>
                <div class="popup-body">
                  <!-- 폴더 위치 선택 라디오 버튼 -->
                  <div class="folder-location-selector">
                    <label :for="'rootFolder'" class="radio-option">
                      <div class="custom-radio">
                        <input
                          type="radio"
                          id="rootFolder"
                          v-model="folderLocation"
                          value="root"
                          name="folderLocation"
                        />
                        <span class="radio-checkmark"></span>
                        <span class="radio-label">최상위 폴더로 추가</span>
                      </div>
                    </label>

                    <label
                      :for="'subFolder'"
                      class="radio-option"
                      :class="{ disabled: !kmsStore.selectedFolderKey }"
                    >
                      <div class="custom-radio">
                        <input
                          type="radio"
                          id="subFolder"
                          v-model="folderLocation"
                          value="sub"
                          name="folderLocation"
                          :disabled="!kmsStore.selectedFolderKey"
                        />
                        <span class="radio-checkmark"></span>
                        <span class="radio-label">
                          <template v-if="kmsStore.selectedFolderKey">
                            '{{ kmsStore.selectedFolder?.folder_name }}' 하위
                            폴더로 추가
                          </template>
                          <template v-else>
                            하위 폴더로 추가 (폴더를 선택해주세요)
                          </template>
                        </span>
                      </div>
                    </label>
                  </div>
                  <!-- <input
                    v-model="folderName"
                    :placeholder="
                      isSubFolder ? '하위 폴더명 입력' : '폴더명 입력'
                    "
                    ref="folderInputRef"
                    @keyup.enter="handleKeyPress"
                    class="folder-name-input"
                  /> -->
                  <input
                    v-model="folderName"
                    :placeholder="
                      isSubFolder ? '하위 폴더명 입력' : '폴더명 입력'
                    "
                    ref="folderInputRef"
                    class="folder-name-input"
                  />
                </div>
                <div class="popup-footer">
                  <button
                    type="button"
                    class="submit-btn"
                    @click.stop="addFolder"
                  >
                    추가
                  </button>
                  <button
                    type="button"
                    class="cancel-btn"
                    @click="closeFolderAddPopup"
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>
          </template>

          <!-- 폴더명 변경 팝업 -->
          <template v-if="showFolderEditPopup">
            <div class="modal-overlay"></div>
            <div class="folderAddpopup">
              <div class="popup-content">
                <div class="popup-header">
                  <p style="font-weight: bold; font-size: 20px">폴더명 변경</p>
                  <!-- <button @click="closeFolderEditPopup">X</button> -->
                  <div @click="closeFolderEditPopup" class="cursorP">
                    <CloseIcon />
                  </div>
                </div>
                <div class="popup-body">
                  <input
                    v-model="folderName"
                    :placeholder="
                      kmsStore.selectedFolder?.folder_name ||
                      '변경할 폴더명 입력'
                    "
                    ref="folderInputRef"
                    @keyup.enter="editFolder"
                    class="folder-name-input"
                  />
                </div>

                <!-- // -->
                <div class="popup-footer">
                  <button type="button" class="submit-btn" @click="editFolder">
                    저장
                  </button>
                  <button
                    type="button"
                    class="cancel-btn"
                    @click="closeFolderEditPopup"
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>
          </template>

          <!-- 폴더 삭제 확인 팝업     -->
          <template v-if="showDeleteFolderPopup">
            <div class="modal-overlay"></div>
            <div class="docAddPopup">
              <div class="popup-content">
                <div class="popup-header">
                  <p style="font-weight: bold; font-size: 20px">폴더 삭제</p>
                  <!-- <button @click="closeFolderDeletePopup" class="close-button">
                    X
                  </button> -->
                  <div @click="closeFolderDeletePopup" class="cursorP">
                    <CloseIcon />
                  </div>
                </div>
                <div class="popup-body" style="margin-top: 10px">
                  <p style="color: black">
                    <!-- // 독 업데이트 -->
                    <!-- <template v-if="hasConnectedDocs"> -->
                    <!-- 독 업데이트 -->
                    <!-- <template v-if="hasConnectedKnows">
                      이 폴더에 연결된 문서가 존재합니다.<br />
                    </template> -->
                    <template v-if="hasConnectedKnows">
                      이 폴더에 연결된 지식이 존재합니다.<br />
                    </template>
                    <template v-else>
                      정말로 이 폴더를 삭제하시겠습니까?
                    </template>
                  </p>
                  <!-- // 독 업데이트 -->
                  <p
                    v-if="hasConnectedKnows"
                    style="color: #666; font-size: 0.9em; margin-top: 8px"
                  >
                    * 폴더 삭제 전 연결된 모든 지식을 삭제해주세요.
                  </p>
                </div>
                <div class="popup-footer">
                  <!-- // 독 업데이트 -->
                  <!-- <button
                    type="button"
                    class="submit-btn"
                    @click="confirmFolderDelete"
                    :disabled="hasConnectedDocs"
                  > -->
                  <button
                    type="button"
                    class="submit-btn"
                    @click="confirmFolderDelete"
                    :disabled="hasConnectedKnows"
                  >
                    확인
                  </button>
                  <button
                    type="button"
                    class="cancel-btn"
                    @click="closeFolderDeletePopup"
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>
          </template>

          <!-- 팝업 컴포넌트 
         props를 통해 데이터를 전달
         -->
          <KmsViewPage
            :knowViewItem="selectedItem"
            :folderAndGroupName="kmsStore.currentPath"
            :show="showViewPopup"
            @know-deleted="handleKnowDeleted"
            @close="closeViewPopup"
            @updateSummary="handleUpdateSummary"
            @updateBody="handleUpdateBody"
            @updateMustText="handleUpdateMustText"
            @updateKeywordList="handleUpdateKeywordList"
            @updateQuestList="handleUpdateQuestList"
            @openDeleteKnowPopup="openDeleteKnowPopup"
            @saveStart="handleSaveStart"
            @saveComplete="handleSaveComplete"
          />

          <!-- // 독 업데이트 -->
          <!-- <KmsDocDetail
            v-if="showDocDetailModal"
            :doc="selectedDocDetail"
            :show="showDocDetailModal"
            @close="closeDocDetailView"
            @update="handleDocUpdate"
          /> -->
        </div>

        <div class="section-footer knowSortWrap">
          <div
            class="pagination"
            style="transform: scale(0.8); transform-origin: center"
          >
            <PaginationRoot
              :key="currentPage"
              :total="totalPages * 10"
              :sibling-count="1"
              show-edges
              :default-page="currentPage"
              @update:page="handlePageChange"
            >
              <PaginationList
                v-slot="{ items }"
                class="flex items-center gap-2 text-white"
              >
                <PaginationFirst
                  class="w-9 h-9 flex items-center justify-center disabled:opacity-50 focus-within:outline focus-within:outline-1 focus-within:outline-offset-1 rounded"
                >
                  <Icon icon="radix-icons:double-arrow-left" />
                </PaginationFirst>
                <PaginationPrev
                  class="w-9 h-9 flex items-center justify-center mr-4 disabled:opacity-50 focus-within:outline focus-within:outline-1 focus-within:outline-offset-1 rounded"
                >
                  <Icon icon="radix-icons:chevron-left" />
                </PaginationPrev>
                <template v-for="(page, index) in items">
                  <PaginationListItem
                    v-if="page.type === 'page'"
                    :key="index"
                    class="w-9 h-9 border rounded data-[selected]:bg-white data-[selected]:text-blackA11 hover:bg-white/10 transition focus-within:outline focus-within:outline-1 focus-within:outline-offset-1"
                    :value="page.value"
                  >
                    {{ page.value }}
                  </PaginationListItem>
                  <PaginationEllipsis
                    v-else
                    :key="page.type"
                    :index="index"
                    class="w-9 h-9 flex items-center justify-center"
                  >
                    &#8230;
                  </PaginationEllipsis>
                </template>
                <PaginationNext
                  class="w-9 h-9 flex items-center justify-center ml-4 disabled:opacity-50 focus-within:outline focus-within:outline-1 focus-within:outline-offset-1 rounded"
                >
                  <Icon icon="radix-icons:chevron-right" />
                </PaginationNext>
                <PaginationLast
                  class="w-9 h-9 flex items-center justify-center disabled:opacity-50 focus-within:outline focus-within:outline-1 focus-within:outline-offset-1 rounded"
                >
                  <Icon icon="radix-icons:double-arrow-right" />
                </PaginationLast>
              </PaginationList>
            </PaginationRoot>
          </div>
          <!-- pagination -->
        </div>
      </div>
      <!-- right-section -->
      <div class="right-section">
        <!-- <KmsViewPage> </KmsViewPage> -->
      </div>
    </div>
  </div>
  <!-- KmsPageMain.vue 템플릿 맨 아래에 추가 -->
  <div v-if="isPageLoading" class="spinner-overlay">
    <div class="spinner"></div>
    <p>저장 중입니다...</p>
  </div>
</template>

<style scoped lang="scss">
@use "../../root/header";

/* 전체 컨테이너 */
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100vh; */
  font-family: Arial, sans-serif;
}

/* MainHeader를 가리기 위한 스타일 */
.kms-page {
  /* position: fixed; */
  // ============================== 팝업 전용
  max-height: 95vh;
  scrollbar-width: thin;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 100;
  height: 100%;
  // 가로 스크롤 추가
  overflow-x: auto; // 가로 스크롤바 표시
  width: 100%; // 전체 너비 사용
  max-width: 100vw; // 최대 너비를 뷰포트 너비로 설정
}

/* 헤더 스타일 */
.headerbox {
  min-height: 43px;
  width: 100%;
  min-width: 1200x;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 600;
  align-items: center;
  border-bottom: 1px solid gray;
  background-color: #fff;
  position: relative;
  padding: 0 6px;

  &::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 160px;
    height: 3px;
    background-color: gray;
  }
}

.headerWrap {
  display: flex;
  flex-direction: row;
  margin-block: 5px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 30px 0 20px; */
  // ============================== 팝업 전용
  padding: 0 0 0 6px;
  /* min-width: 1200px; */
}

.headerWrap p {
  font-size: 16px;
  font-weight: 800;
  margin: 0;
}

.titleWrap {
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
  padding-left: 2px;
  justify-content: space-between;
}

.titleWrap .fontMd {
  flex-shrink: 0;
}

.titleWrap .searchInput {
  margin-left: 5px;
  margin-right: px;
}

.titleWrap .confirmBtn {
  margin-right: 0;
  margin-left: 0;
}

.titleWrap p {
  margin-right: auto;
  @media (max-width: 1200px) {
    /* padding: 6px 10px; */
    min-width: 150px;
    /* font-size: 13px; */
  }

  @media (max-width: 992px) {
    /* padding: 6px 8px; */
    /* min-width: 76px; */
    /* font-size: 12px; */
  }
}

// 오른쪽 요소들을 그룹화
.titleWrap .right-elements {
  display: flex;
  align-items: center;
  gap: 10px; // 요소들 사이의 간격
}

ul {
  list-style: none;
  padding-left: 0;
}

li {
  padding: 5px 10px;
}

/* 메인 콘텐츠 영역 */
.main-content {
  display: flex;
  flex: 1;
  overflow-x: auto; // 가로 스크롤 추가
  /* min-width: 1200px; */
  /* padding: 0px 15px; */
  // ============================== 팝업 전용
  padding: 0px;
}

/* 왼쪽 섹션 */
.left-section {
  min-width: 300px;
  /* width: 25%; */
  width: 40%;
  border-right: 1px solid #ddd;
  display: flex; /* flex 컨테이너로 설정 */
  flex-direction: column; /* 세로 방향 정렬 */
  height: 100%; /* 전체 높이 사용 */
  position: relative; /* 상대 위치 설정 */
  overflow: hidden; /* 내부 스크롤을 위한 설정 */
  /* padding: 10px 20px 20px 20px; */
  // ============================== 팝업 전용
  padding: 10px 30px 20px 0px;
}

.left-section h2 {
  font-size: 18px;
  /* margin-bottom: 15px; */
}

.left-section ul {
  list-style-type: none;
  padding: 0;
}

.left-section li {
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
}

.left-section li:hover {
  background-color: #f0f0f0;
}

.left-section li.active {
  background-color: #e0e0e0;
  border-color: #ccc;
}

.folder-container {
  flex: 1;
  /* overflow-y: auto; */
  margin: 8px 0 10px 0;
  height: calc(100% - 80px); /* 상단 헤더와 하단 푸터 높이를 제외한 높이 */
  position: relative; /* 상대 위치 설정 */
  min-height: 100px;
}

.scroll {
  overflow-y: auto;
  height: 90%; /* 부모의 높이를 기준으로 */
  overflow: auto;
}

/* 드롭 영역 활성화 시 스타일 */
.folder-container.drop-area-active::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(33, 150, 243, 0.1);
  border: 2px dashed #2196f3;
  pointer-events: none;
  z-index: 1;
}

/* 드롭 가능 안내 메시지 */
.folder-container.drop-area-active::before {
  content: "여기로 드래그하여 최상위 폴더로 이동";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 8px 16px;
  background-color: white;
  border: 1px solid #2196f3;
  border-radius: 4px;
  color: #2196f3;
  font-size: 14px;
  white-space: nowrap;
  z-index: 2;
}

.folder-container.drop-target {
  background-color: #f8f9fa;
  border: 2px dashed #2196f3;
}

/* folder-tree가 비어있을 때의 스타일 */
.folder-tree:empty {
  min-height: 100px;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.folder-tree:empty::after {
  content: "폴더가 없습니다.";
  color: #666;
  font-size: 0.9em;
}

.section-header,
.section-footer {
  flex: 0 0 auto;
  padding: 15px 0;
}

.section-footer {
  // width: 60%;
  margin-top: auto; /* 하단에 고정 */
  border-top: 1px solid #ddd;
  padding: 5px 0;
}

.edit-button,
.editBtn {
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
}

.section-footer .edit-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 8px 8px;
  /* background-color: #545454; */
}

.section-footer .edit-button:hover {
  background-color: #b4b2b2;
}

.folder-header {
  height: 46px;
  padding: 10px;
  cursor: pointer;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dedede;
  font-size: 1.2em;
  /* border-left: 3px solid transparent !important; */
  transition: border-left-color 0.2s ease !important;
}

.folder-header.active {
  /* border-left: 3px solid #51c2b8 !important; 선택된 상태의 왼쪽 바 색상 */
  background-color: transparent !important; /* 배경색 제거 */
}

.folder-title {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.folder-title button {
  padding: 2px 8px;
  font-size: 14px;
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.folder-title button {
  opacity: 0;
  transition: opacity 0.2s;
}

.folder-header:hover .folder-title button {
  opacity: 1;
}

.folder-icon {
  width: 20px;
  text-align: center;
  color: #666;
}

.folder-name {
  flex: 1;
  margin-right: auto;
  /* cursor: pointer; */
}

.folder-header:hover {
  background-color: #f8f9fa;
  /* border-left-color: #51c2b8 !important; hover 시에도 왼쪽 바 표시 */
}

.folder-actions {
  display: flex;
  gap: 8px;
  margin-left: auto;
  justify-content: flex-end;
  min-width: 150px;
}

.subfolder-container {
  /* padding-left: 24px; */
  width: 100%;
  background-color: #fff;
  padding-left: 0;
}

.subfolder-item {
  /* position: relative; */
  padding: 10px;
  cursor: pointer;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dedede;
  font-size: 1.2em;
}

.subfolder-item::before {
  content: "ㄴ";
  position: absolute; /* 위치 절대 지정 */
  left: 10px; /* 텍스트와의 간격 */
  color: #999; /* 텍스트 색상 */
  font-size: 1em; /* 크기 조정 */
}

.subfolder-item.active {
  background-color: #e9ecef;
  color: black;
}

.editBtn {
  opacity: 0;
  transition: opacity 0.2s;
}
.editBtn:hover {
  opacity: 1;
}

.editpopup-btn {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.editbtnWrap {
  display: flex;
  gap: 10px;
}

.subfolder-item:last-child {
  border-bottom: none;
}

.subfolder-item:hover {
  background-color: #f8f9fa;
}

.subfolder-item:hover .folder-actions {
  opacity: 1;
}

.knowSortWrap {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  margin-bottom: 15px;
}

.knowSortWrap2 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .sort-select-wrapper {
    display: flex;
    align-items: center;
  }

  select {
    height: 25px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
    font-size: 12px;
    font-weight: normal; // 추가
  }

  select option {
    font-weight: normal; // 옵션 글자 굵기를 normal로
  }
}

/* .knowSortWrap button {
  height: 100%;
} */

.knowSortWrap h2 {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
}

.detailTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 10px;
  padding: 0px 0px 15px 0px;
  border-bottom: 1px solid #ddd;
}

// .confirmBtn .dot {
//   width: 8px !important;
//   height: 8px !important;
//   border-radius: 50%;
//   background-color: rgb(255, 94, 0) !important;
//   margin-right: 8px;
//   display: inline-block;
//   vertical-align: middle;
//   animation: blink 2s infinite;
// }

.middle-section-title {
  min-width: 50px;
}

.detailTitle3 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 10px;
  padding: 0px 0px 15px 0px;
  border-bottom: 1px solid #ddd;
}

.confirmBtn {
  height: 35px;
  padding: 0 16px;
  background: rgb(74, 74, 74);
  border: none;
  border-radius: 4px;
  color: white;

  &:hover {
    // background-color: #030303;
  }
}

.modal-header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  border-bottom: 1px solid #ddd;
}

// .items-center {
//   width: 100%;
// }

// .confirmBtn {
//   // background-color: #dfdfdf !important;
//   background-color: white !important;
//   color: rgb(72, 72, 72) !important;
//   margin-left: 10px;
//   font-weight: bold;
//   width: 8px !important;
//   height: 8px !important;
//   border-radius: 50%;
//   margin-right: 8px;
//   display: inline-block;
//   vertical-align: middle;
//   animation: blink 2s infinite;
//   border: 1px solid !important;
//   border-color: rgb(204, 204, 204) !important;
// }

@keyframes blink {
  0% {
    opacity: 1; /* 완전 불투명 */
  }
  50% {
    opacity: 0; /* 반투명 (깜빡임 효과) */
  }
  100% {
    opacity: 1; /* 다시 완전 불투명 */
  }
}

.folder-control-buttons,
.editBtn-wrap {
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
}
@media (max-width: 450px) {
  .knowledge-list {
    min-width: 100px !important;
  }

  .middle-section {
    min-width: 300px !important;
  }

  .kmsTitle {
    display: none;
  }

  .confirmBtn {
    height: 24px;
    padding: 0 7px;
    background: rgb(74, 74, 74);
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 0.7rem;
  }
  .search-select {
    width: 80px !important;
    height: 24px !important;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.7rem;
    background-color: white;
    align-self: anchor-center;
  }
  .right-elements {
    gap: 0px !important;
  }
  .searchInput {
    margin-left: 8px;
  }

  .searchInput input {
    width: 70px !important;
    padding: 0 2px;
    height: 24px !important;
    font-size: 0.7rem;
    align-self: center;
  }
  .search-button {
    width: 50px !important;
    padding: 0px !important;
    height: 24px !important;
    font-size: 0.7rem;
    align-self: center;
  }

  .headerbox {
    padding: 0px !important;
  }

  .headerWrap {
    padding: 0px !important;
  }
}

@media (max-width: 986px) {
  .headerbox,
  .headerWrap {
    /* width: 100vw; */
    /* min-width: 1200px; */
  }

  .control-button {
    /* width: 45px !important; */
    padding: 0px 5px !important;
    min-width: 30px !important;
  }
  .folder-control-buttons {
    gap: 0px;
  }
  .editBtn-wrap {
    gap: 0px;
  }
}

@media (max-width: 1263px) {
  .headerbox {
    max-width: 100vw;
  }

  .headerWrap {
    max-width: 100vw;
  }

  .main-content {
    /* overflow-x: hidden; */
    max-width: 100vw; /* 뷰포트 너비에 맞춤 */
    padding: 0;
  }
  .folder-control-buttons {
    gap: 5px;
  }
  .editBtn-wrap {
    gap: 5px;
  }
  .detailTitle2 {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .detailTitle3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .detailTitle3 span {
    width: auto;
  }

  .knowListWrap {
    align-items: center !important;
  }

  .knowSortWrap2 {
    display: flex;
    flex-direction: row;
    width: auto;
    justify-content: flex-end;
  }
  .current-path {
    padding: 0rem !important;
  }
}
.detailTitle button {
  font-weight: 600;
  background-color: #545454;
  color: white;
  padding: 0px 10px;
}

.detailTitle button:hover {
  background-color: #282727;
}

.left-section-title {
  min-width: 35px;
}

.center-section-title {
  min-width: 35px;
}

/* .trash-button {
  margin-top: 20px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
} */

// /* 중앙 섹션 */
// .center-section {
//   min-width: 300px;
//   width: 25%;
//   padding: 10px 20px 20px 20px;
//   border-right: 1px solid #ddd;
//   /* overflow-y: auto; */
// }

// .center-section h2 {
//   font-size: 18px;
//   /* margin-bottom: 15px; */
// }

// .center-section ul {
//   list-style-type: none;
//   padding: 0;
// }

// .center-section li {
//   margin-bottom: 10px;
//   cursor: pointer;
//   padding: 5px;
// }

.knowGroupItem:hover {
  background-color: #f0f0f0;
}

.knowGroupItem.active {
  background-color: #e9ecef;
  border-color: #ccc;
}

.itemByGroup {
  margin-left: 15px;
  color: #555;
}

.sub-menu {
  padding-left: 15px;
  color: #666;
  font-size: 14px;
}

.sub-menu li {
  margin-bottom: 5px;
}

/* 중앙 섹션 */
.middle-section {
  overflow-x: auto;
  /* width: 50%; */
  width: 60%;
  padding: 10px 0px 5px 30px;
  display: flex; /* 추가 */
  flex-direction: column; /* 추가 */
  height: 100%;
  min-width: 650px;
  /* padding-left: 20px; */
  /* padding-right: 20px; */
  /* padding-bottom: 20px; */
  /* padding-top: 7px; */
}

.middle-section .section-footer {
  margin-top: auto; /* 추가 */
  padding: 15px 0px 0px 0px;
  border-top: 1px solid #ddd;
}

.middle-section h2 {
  font-size: 18px;
  /* margin-bottom: 20px; */
}

.knowListWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchInput {
  position: relative;
  display: flex;
  gap: 10px;
  /* margin-bottom: 20px; */
  font-size: 0.7em;
  font-weight: 300;
  margin-left: 10px;

  .search-select {
    width: 120px;
    height: 35px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    background-color: white;
  }

  input {
    height: 35px;
    flex: 1;
    padding: 0 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }

  .search-button {
    height: 35px;
    padding: 0 20px;
    background-color: #4a4a4a;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
}

/* .input {
  height: 10px;
} */

// .searchInput input {
//   height: 30px;
//   flex: 1;
//   border: 1px solid #ccc;
//   border-radius: 15px;
// }

// .search-icon {
//   position: absolute;
//   right: 8px;
//   top: 50%;
//   transform: translateY(-50%);
//   width: 20px;
//   height: 20px;
//   cursor: pointer;
// }

// .searchInput button {
//   height: 30px;
//   border: none;
//   border-radius: 4px;
//   cursor: pointer;
// }

.knowledge-item {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: #fff;
  transition: background-color 0.3s ease; /* 배경색 전환 효과 */
}

.knowledge-item.selected-item {
  background-color: #e7feef; /* 선택된 항목의 배경색 */
  border: solid 3px #51c2b8;
}

.knowledge-item:hover {
  background-color: #e7feef; /* hover 상태일 때 배경색 */
}

.knowledge-item h3 {
  font-size: 16px;
  margin: 0 0 10px 0;
}

.knowledge-item h3 span {
  font-size: 14px;
  color: #666;
}

.knowledge-item p {
  margin: 0 0 10px 0;
  font-size: 14px;
  color: #333;
}

.knowledge-item .actions {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #555;
}

/* .pagination {
  width: 100%;
  display: flex;
  gap: 5px;
  text-align: center !important;
  justify-content: center;
  align-items: center;
  padding: 4px 0;
}

.pagination span {
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.pagination span:hover {
  background-color: #f0f0f0;
} */

/* 기존 pagination 클래스 삭제하고 새로운 스타일 추가 */
/* .section-footer {
  margin-top: auto;
  border-top: 1px solid #ddd;
  padding: 0;
  min-height: 50px;
  display: flex;
  align-items: center;
} */

.pagination {
  width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0;
  margin: auto;
}

/* Radix Pagination 컴포넌트 스타일 */
.pagination [role="navigation"] {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

/* 페이지 번호 버튼 공통 스타일 */
.pagination button {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #ddd;
  background: white;
  color: #333;
  cursor: pointer;
  transition: all 0.2s ease;
}

/* 페이지 번호 버튼 호버 효과 */
.pagination button:hover:not(:disabled) {
  background-color: #f0f0f0;
  border-color: #ccc;
}

/* 선택된 페이지 스타일 */
.pagination button[data-selected="true"] {
  background-color: #ddd;
  color: #545454;
  border-color: #ddd;
  font-weight: bold;
}

/* 이전/다음 버튼 스타일 */
.pagination button[aria-label*="Previous"],
.pagination button[aria-label*="Next"],
.pagination button[aria-label*="First"],
.pagination button[aria-label*="Last"] {
  background-color: white;
}

/* 비활성화된 버튼 스타일 */
.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* 줄임표(...) 스타일 */
.pagination [data-type="ellipsis"] {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
}

/* 포커스 스타일 */
.pagination button:focus-visible {
  outline: 2px solid #545454;
  outline-offset: 2px;
}

.writer-info {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  color: #666;
  text-align: right;
  gap: 10px;
}

.backgroundLayout {
  position: fixed; /* 뷰포트에 고정 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: black;
  opacity: 0.4;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1100;
}

/* 컨펌지식 팝업 스타일 */
.confimrKnowPopup {
  /* overflow: scroll; */
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  max-width: 600px;
  height: 95%;
  max-height: 900px;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 200;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 580px) {
    width: 85% !important;
  }
}
.confirmPopHeaderWrap {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  // border-bottom: 0.5px solid gray;
  padding: 0px 0px 10px 0px;
}
/* .confirmPopHeaderWrap p {
  font-size: 1.2rem;
  font-weight: 800;
} */

.confimrKnowList {
  min-height: 50%;
  height: 100%;
  overflow-y: auto;
}

.knowlistWrapStyle {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.knowCheckInput {
  width: 20px !important;
  height: 20px !important;
  border: 2px solid #ccc !important; /* 부드러운 테두리 */
  border-radius: 4px !important; /* 살짝 둥근 모서리 */
  background-color: #f9f9f9 !important; /* 밝은 배경 */
  transition: all 0.1s ease !important; /* 부드러운 전환 효과 */
  appearance: none !important; /* 기본 체크박스 제거 */
  cursor: pointer !important; /* 클릭 가능한 손 모양 커서 */
}

.knowCheckInput:checked {
  background-color: #4caf50 !important; /* 체크 시 녹색 배경 */
  border-color: #4caf50 !important; /* 체크 시 테두리 색 변경 */
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"%3E%3Cpath d="M20.285 6.738l-11.082 11.195c-.369.369-.963.369-1.332 0l-4.156-4.156a.941.941 0 0 1 0-1.332l1.332-1.332c.369-.369.963-.369 1.332 0l2.824 2.823 9.75-9.75c.369-.369.963-.369 1.332 0l1.332 1.332c.369.369.369.963 0 1.332z"/%3E%3C/svg%3E') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 60% !important; /* 체크 아이콘 크기 */
}

.knowCheckInput:hover {
  background-color: rgb(205, 240, 205) !important; /* 체크 시 녹색 배경 */

  color: black !important;
}
.allKnowCheckInput {
  width: 20px !important;
  height: 20px !important;
  border: 2px solid #ccc !important;
  border-radius: 4px !important;
  background-color: #f9f9f9 !important;
  transition: all 0.1s ease !important; /* 부드러운 전환 효과 */
  appearance: none !important;
  cursor: pointer !important;
}

.allKnowCheckInput:checked {
  background-color: #4caf50 !important;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"%3E%3Cpath d="M20.285 6.738l-11.082 11.195c-.369.369-.963.369-1.332 0l-4.156-4.156a.941.941 0 0 1 0-1.332l1.332-1.332c.369-.369.963-.369 1.332 0l2.824 2.823 9.75-9.75c.369-.369.963-.369 1.332 0l1.332 1.332c.369.369.369.963 0 1.332z"/%3E%3C/svg%3E') !important;
  border-color: #4caf50 !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 60% !important;
}

.allKnowCheckInput:hover {
  background-color: rgb(205, 240, 205) !important; /* 체크 시 녹색 배경 */
  color: black !important;
}

.knowCheckContents {
  width: 95%;
}

.btnWrap {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
}

.btnWrap div {
  display: flex;
  gap: 10px;
  font-weight: 700;
}

.btnWrap div :first-child {
  background-color: #51c2b8 !important;
  color: white;
}

/* 팝업 스타일 */
.folderAddpopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1200;
}

.popup-content {
  display: flex;
  flex-direction: column;
  background-color: white !important;
}

.popup-content input {
  outline: none;
  border-color: #51c2b8;
  box-shadow: 0 0 0 2px rgba(81, 194, 184, 0.1);
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 16px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.popup-header h5 {
  margin: 0;
  font-size: 16px;
}

.popup-header .close-button {
  /* background: none !important; */
  background-color: white !important;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

// .popup-body {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   max-height: 30px !important;

//   .setting-button {
//     width: 30px !important;
//     background: none !important;
//     border: none !important;
//     cursor: pointer;
//     color: #666;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     box-shadow: none !important;
//     padding: 0 !important;

//     &:hover {
//       color: #333;
//     }
//   }
// }

.popup-body label {
  margin-bottom: 8px;
  font-size: 14px;
}

.popup-body input {
  width: 100%;
  padding: 8px;
  margin: 0px !important;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.popup-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.popup-actions button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup-actions button:first-child {
  background-color: #007bff;
  color: white;
}

.popup-actions button:first-child:hover {
  background-color: #0056b3;
}

.popup-actions button:last-child {
  background-color: #f5f5f5;
  color: #333;
}

.popup-actions button:last-child:hover {
  background-color: #e0e0e0;
}

.popup-body-box {
  border-radius: 4px;
  padding: 8px;
  border: 1px solid #ccc;
  margin-top: 10px;
}

.folder-item {
  width: 100%;
}

.folder-item:hover {
  background-color: #f0f0f0;
}

.folder-item .folder-name {
  flex: 1;
}

.folder-item .folder-actions {
  display: flex;
  gap: 10px;
  padding-left: 10px;
  justify-content: space-between;
  /* opacity: 0; */
  transition: opacity 0.2s;
}

.folder-header,
.subfolder-item {
  padding: 10px;
  cursor: pointer;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dedede;
  font-size: 1.2em;
}

.folder-header:hover,
.subfolder-item:hover {
  background-color: #e9ecef;
}

.folder-title {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.folder-item .edit-button .doc-button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #545454;
  font-weight: 600;
  color: white;
}

.folder-item .edit-button:hover {
  background-color: #545454;
  color: white;
  /* opacity: 0; */
}

.border-button {
  padding: 2px 15px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  background-color: #545454;
  font-weight: 600;
  color: white;
}

.border-button {
  border: 1px solid #ccc;
  background-color: white;
  color: #545454;
}

.doc-button {
  background-color: #545454;
  color: white;
}

.knowContListWrap {
  flex: 1; /* 변경 */
  display: flex; /* 추가 */
  flex-direction: column; /* 추가 */
  margin: 8px 0 10px 0;
  min-height: 0; /* 추가 */
  overflow: hidden; /* 추가 */
}

.knowNavWrap {
  margin: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 7px 0; */
}

.knowDocName {
  /* width: 100%; */
  height: 40px;
  /* border-radius: 3px; */
  /* border: 1px solid #ddd; */
  /* margin-bottom: 14px; */
  font-size: 1.2em;
  display: flex;
  align-items: center;
  color: black;
  font-weight: 700;
  gap: 2px;
}

.knowNavWrapRight {
  display: flex;
  align-items: center;
  gap: 20px;
}
.select-wrapper {
  position: relative;
  width: 100%;
}

.select-icon {
  position: absolute;
  left: 10px;
  top: 40%;
  transform: translateY(-50%);
  pointer-events: none; /* 아이콘 위로 마우스 이벤트가 select로 전달되도록 */
}
.select-box {
  width: 100%;
  height: 42px;
  border-radius: 3px;
  border: 1px solid #ddd;
  background-color: #e9ecef;
  color: #505050;
  margin-bottom: 10px;
  font-size: 1.2em;
  font-weight: bold;
  color: black;
  padding-left: 35px;
}

.sub-folder {
  padding-left: 20px; /* 하위 폴더 들여쓰기 */
  font-size: 14px;
  color: #555;
}

.folder-name {
  flex: 1;
  margin-right: auto;
  cursor: pointer; /* 클릭 가능한 폴더 이름 표시 */
}

.folder-tree {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.current-path {
  /* padding: 0.5rem; */
  padding: 0px 3px !important;
  border-radius: 4px;
  font-size: 0.8rem;
  color: #ababab;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 최대 표시 줄 수 */
  -webkit-box-orient: vertical;
}

.knowledge-content {
  padding: 1rem;
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.must-text {
  margin: 1rem 0;
  padding: 0.5rem;
  background-color: #eefffb;
  // border-left: 4px solid #b2f4e3;
  border-left: 7px solid #6aceb5;
  border-radius: 5px;
  padding: 8px;
  margin: 15px 0;
}

.keywords {
  display: flex;
  gap: 0.2rem;
  margin: 5px 0;
  /* margin: 1rem 0; */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  max-height: 2.5em;
}

.keyword {
  // padding: 0.25rem 0.5rem;
  padding: 2px 8px;
  background: #e9ecef;
  border-radius: 8px;
  font-size: 1em;
}

/* Quest 키워드 구분 */
.quest-item {
  padding: 4px 8px;
  background: white; /* 다른 배경색 */
  border-radius: 13px;
  border: 0.5px solid #7c7c7c;
  font-size: 14px;
  color: #3a3a3a; /* 다른 텍스트 색상 */
  // border: 1px solid #b2ebf2; /* 테두리 추가 */
}

.quest-item::before {
  content: "?";
  display: inline-block;
  font-weight: bold;
  margin-right: 6px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background-color: #9b9b9b;
  color: white;
  border-radius: 50%;
  font-size: 0.8em;
}

/* Quest 리스트 영역 */
.quest-list {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin: 4px 0 2px 0;
  max-height: 2.5em;
  overflow: hidden;
}

.questListWrap {
  display: flex;
  gap: 7px;
  align-items: center;
}

.questNum {
  font-weight: 500;
  color: gray;
  font-size: 1rem;
}

.quest-label {
  font-size: 14px;
  color: #666;
  margin-right: 5px;
}

/* 첨부파일 영역 */
.attachments {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

/* 우선순위 뱃지 */
.priority-badge {
  font-size: 0.75em;
  padding: 2px 8px;
  border-radius: 12px;
  margin-left: 6px;
  font-weight: 600;
}

.kmsDocTextWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.kmsDocText {
  font-size: 1.2em;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* max-width: 80%; */
  max-width: 100%;
  /* display: inline-block; */
}

.doc-key {
  font-size: 0.75em;
  color: #888;
  font-weight: normal;
}

.doc-count {
  font-size: 0.9em;
  color: #666;
  margin-left: 8px;
}

.doc-info {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.doc-actions {
  display: flex;
  margin-left: 8px;
}

.detail-button {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #666;
  opacity: 0.5;
  transition: all 0.2s ease;
}

.file-item:hover .detail-button {
  opacity: 1;
}

.detail-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #333;
}

.doc-attachments {
  display: flex;
  gap: 8px;
  margin-left: 12px;
  white-space: nowrap;
}

.attachment-badge {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 6px;
  border-radius: 12px;
  background-color: #f5f5f5;
  font-size: 12px;
  color: #666;
}

/* 아이콘별 색상 차별화 (선택사항) */
.attachment-badge:has(File) {
  background-color: #e3f2fd;
  color: #1976d2;
}

.attachment-badge:has(ImageIcon) {
  background-color: #e8f5e9;
  color: #388e3c;
}

.attachment-badge:has(Link) {
  background-color: #fff3e0;
  color: #e65100;
}

.file-item {
  height: 46px;
  display: flex;
  /* margin-bottom: 10px; */
  padding: 10px;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  transition:
    background-color 0.1s ease,
    box-shadow 0.1s ease;
  border-bottom: 0.2px solid #eeeeee;
  border-top: 0.2px solid #eeeeee;
}

.file-item:hover {
  background-color: #f8f9fa; /* Hover 상태의 배경색 */
}

.file-item.active {
  background-color: #f8f9fa;
  border-left: 4px solid #616364;
  color: black;
}

.file-header.selected:hover {
  background-color: #f8f9fa; /* Hover 상태의 배경색 */
}

.file-item strong {
  display: flex;
  align-items: center;
}

.doc-list {
  /* height: 100%; */
  flex: 1;
  /* overflow-y: auto; */
  margin: 8px 0 10px 0;
  height: calc(100% - 140px);
  position: relative;
}

.docNameWrap {
  width: 100%;
  height: 42px;
  border-radius: 3px;
  border: 1px solid #ddd;
  margin: 18px 0 8px 0;
  padding-left: 10px;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  color: black;
  font-weight: 700;
  gap: 2px;
  background-color: #e9ecef;
}
.docLength {
  font-size: 0.9em;
}

.knowledge-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px; /* 박스 간 간격 */
  min-width: 350px;
  overflow-x: auto;
  scrollbar-width: thin; /* Firefox */
}

.knowledge-box {
  width: 100%;
  // height: 265px;
  // min-height: 265px;
  max-height: 400px;
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  // overflow-x: auto;
  // overflow-y: hidden;

  &:hover {
    cursor: pointer;
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
    transition:
      transform 0.5s ease,
      box-shadow 0.5s ease;
  }

  @media (max-width: 580px) {
    /* width: 70% !important; */
  }
}

/* .knowledge-box:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
} */

.knowNavWrap strong {
  font-size: 1.1em;
  color: #333;
}

.knowWrap {
  display: flex;
  justify-content: space-between;
}

.keywords {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-right: 50px;
}

.keyword {
  background-color: #f5f5f5;
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 14px;
  color: #666;
}
.knowlist_title {
  font-size: 1.1em;
}

.knowlist_title strong {
  margin-right: 6px;
}

.know-key {
  font-size: 0.75em;
  color: #888;
  font-weight: normal;
}

.summary {
  color: #353535;
  margin: 12px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  // -webkit-line-clamp: 1;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  max-height: 4.5em;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 5px;
}

// .summary p {
//   font-size: 1.1em;
// }

.summary p {
  font-size: 1em;
  // font-weight: 500;
  margin: 3px !important;
  // margin: 0;
  color: #333;
}

.body {
  color: #333;
  margin: 12px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  max-height: 4.5em;
}

.body p {
  line-height: 1.5;
  font-size: 1em;
}

.attachments {
  display: flex;
  gap: 1rem;
  color: #666;
}

.attachments span {
  display: flex;
  align-items: center;
  gap: 5px;
}

.radix-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 반투명 검은색 */
  z-index: 1000;
}

.radix-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px; /* 팝업 너비 */
  padding: 20px; /* 내부 여백 */
  background-color: white; /* 흰색 배경 */
  border-radius: 10px; /* 둥근 모서리 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 그림자 */
  z-index: 1010;
}

.bottom-line {
  /* border-bottom: 1px solid #cccccc; */
  margin-bottom: 6px;
  padding: 0px 0px 5px 0px;
}

.Doc-gap {
  display: flex;
  flex-direction: row;
}

.knowDocNamep-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #ddd;
  margin: -2px 0 2px 0;
  padding: 0 10px;
  background-color: #e9ecef;
}

.confirmBtnWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.confirmBtnWrap div {
  display: flex;
  gap: 10px;
}

.docAddPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 20px;
  background-color: white !important;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1200;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.docAddPopup {
  animation: fadeIn 0.2s ease-out forwards;
}

.popup-content {
  display: flex;
  flex-direction: column;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-body input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
}

.popup-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 12px;
}

.popup-footer button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.knowlistWrapStyle.selected {
  background-color: #e7feef;
  border: 2px solid #51c2b8;
}

.confirmBtnWrap button.selected {
  background-color: #51c2b8;
  color: white;
}

.confirmBtnWrap button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f0f0f0;
  color: #333;
}

.confirmBtnWrap button:hover {
  background-color: #e0e0e0;
}

// .control-button {
//   border: 1px solid !important;
//   border-color: rgb(204, 204, 204) !important;
//   background-color: white !important;
//   color: rgb(72, 72, 72) !important;
// }

.control-button {
  padding: 6px 6px;
  white-space: nowrap;
  min-width: 30px;
  font-size: 14px;
  border: 1px solid !important;
  border-color: rgb(204, 204, 204) !important;
  background-color: white !important;
  color: rgb(72, 72, 72) !important;

  /* @media (max-width: 1200px) {
    padding: 6px 10px;
    min-width: 50px;
    font-size: 13px;
  } */

  /* @media (max-width: 992px) {
    padding: 6px 8px;
    min-width: 45px;
    font-size: 12px;
  } */
}

.control-button:hover {
  border: 1px solid !important;
  border-color: rgb(204, 204, 204) !important;
  background-color: #ececec !important;
  color: rgb(24, 24, 24) !important;
}

.form-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
  // margin-top: 20px;
  // padding: 5px 0; /* 10px에서 5px로 감소 */
  // border-top: 1px solid #ddd;
  // margin-top: 5px;
}

.submit-btn {
  background-color: #51c2b8;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.submit-btn:hover {
  background-color: #18a498;
}

.cancel-btn {
  background-color: #f5f5f5;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.cancel-btn:hover {
  background-color: #ddd;
}

.folder-location-selector {
  margin-bottom: 15px;
}

.radio-option {
  display: block;
  align-items: center;
  gap: 8px;
  /* margin-bottom: 8px; */
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

/* .radio-option:hover:not(.disabled) {
  background-color: #f5f5f5;
} */

.radio-option.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.radio-option input[type="radio"] {
  margin: 0;
  cursor: pointer;
  width: 20px;
}

.radio-option label {
  cursor: pointer;
  font-size: 14px;
  margin: 0px;
}

.disabled label,
.disabled input {
  cursor: not-allowed;
}

.custom-radio {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.custom-radio:hover:not(.disabled) {
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.custom-radio input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-checkmark {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 12px;
  border: 2px solid #adb5bd;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.radio-checkmark::after {
  content: "";
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #51c2b8;
}

.custom-radio input[type="radio"]:checked ~ .radio-checkmark {
  border-color: #51c2b8;
}

.custom-radio input[type="radio"]:checked ~ .radio-checkmark::after {
  display: block;
}

.radio-label {
  font-size: 14px;
  color: #495057;
  user-select: none;
}

.radio-option.disabled .custom-radio {
  opacity: 0.6;
  cursor: not-allowed;
  background-color: #f1f3f5;
}

.radio-option.disabled .radio-label {
  color: #868e96;
}

.folder-name-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  font-size: 14px;
  transition: border-color 0.2s ease;
  margin-top: 8px;
}

.folder-name-input:focus {
  outline: none;
  border-color: #51c2b8;
  box-shadow: 0 0 0 2px rgba(81, 194, 184, 0.1);
}

.gap {
  gap: 5px;
}

.no-results {
  text-align: center;
  padding: 40px 0;
  color: #666;
  font-size: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 0px 0px 20px 0;
}
.selection-guide {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  // color: #51c2b8;
  font-size: 13px;
  animation: pulse 2s infinite;
}

.arrow-left-icon {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  // border-right: 12px solid #51c2b8;
  margin-right: 10px;
}

// @keyframes pulse {
//   0% {
//     opacity: 0.7;
//     transform: scale(0.95);
//   }
//   50% {
//     opacity: 1;
//     transform: scale(1);
//   }
//   100% {
//     opacity: 0.7;
//     transform: scale(0.95);
//   }
// }

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #51c2b8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-overlay p {
  color: white;
  margin-top: 15px;
  font-size: 18px;
}

.selection-controls {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px;
  background-color: #f8f9fa;
  border-radius: 4px;
  margin-bottom: 10px;
}

.mode-toggle {
  padding: 6px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
}

.selected-count {
  font-size: 0.9em;
  color: #666;
}
// .floating-move-button {
//   position: fixed;
//   bottom: 40px;
//   left: 50%;
//   transform: translateX(-50%);
//   background-color: #51c2b8;
//   color: white;
//   padding: 12px 24px;
//   border-radius: 25px;
//   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
//   cursor: pointer;
//   z-index: 1000; // z-index 값을 확실히 높게 설정
//   font-weight: bold;
//   border: none;
//   font-size: 16px;
//   min-width: 200px;
//   text-align: center;
//   transition: all 0.3s ease;

// .move-button {
//   border: 2px;
//   background-color: #666;
//   color: white !important;
// }

// .floating-move-button {
//   position: fixed;
//   bottom: 40px;
//   left: 50%;
//   transform: translateX(-50%);
//   // background-color: #fffafa !important;
//   background-color: white !important;
//   color: #666 !important;
//   padding: 10px 20px !important;
//   border-radius: 5px;
//   box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
//   cursor: pointer;
//   z-index: 1000;
//   font-weight: 600;
//   border: none;
//   font-size: 14px;
//   min-width: 180px;
//   text-align: center;
//   transition: all 0.2s ease;

//   &:hover {
//     background-color: #429e96;
//     transform: translateX(-50%) translateY(-2px);
//   }
// }

.action-floating-button {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #51c2b8 !important;
  color: white !important;
  padding: 12px 24px !important;
  border-radius: 50px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  font-weight: 600;
  border: none;
  font-size: 15px;
  min-width: 180px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.action-floating-button:hover {
  background-color: #3da89e !important;
  transform: translateX(-50%) translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
}

.action-floating-button:active {
  transform: translateX(-50%) translateY(0);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}

.action-button-text {
  font-weight: 700;
}

.action-button-icon {
  font-size: 18px;
  margin-left: 5px;
}

.floating-move-button {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white !important;
  color: #666 !important;
  padding: 20px !important;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  font-weight: 600;
  border: none;
  font-size: 14px;
  min-width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  cursor: default; // 커서를 기본값으로 변경

  .move-button {
    display: inline-block;
    padding: 8px 16px;
    background-color: #666;
    color: white !important;
    border-radius: 4px;
    font-size: 13px;
    margin-top: 4px;
    cursor: pointer; // 버튼에만 포인터 커서 적용
    transition: all 0.2s ease;

    &:hover {
      background-color: #555;
      transform: translateY(-2px);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    &:active {
      transform: translateY(0);
    }
  }
}

// .floating-move-button:hover {
//   background-color: #18a498;
//   box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
//   transform: translateX(-50%) translateY(-2px);
// }

// .floating-move-button:hover {
//   background-color: #e3e3e3 !important;
//   box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
//   transform: translateX(-50%) translateY(-1px);
// }

// .floating-move-button:active {
//   transform: translateX(-50%) translateY(0);
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
// }

/* 페이드 트랜지션 효과 */
.fade-enter-active,
.fade-leave-active {
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(-50%) translateY(20px);
}

.move-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  // min-height: 500px !important;
  transform: translate(-50%, -50%);
  min-width: 400px;
  /* min-height: 600px; */
  height: auto;
  /* width: 400px; */
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  z-index: 1001;

  .popup-content {
    padding: 24px;
    height: 100%;

    h3 {
      font-size: 18px;
      font-weight: 600;
      margin: 0 auto !important;
      color: #333;
    }

    .selected-folders {
      max-height: 200px;
      overflow-y: auto;
      margin-bottom: 20px;
      border: 1px solid #eee;
      border-radius: 6px;
      padding: 8px;

      div {
        padding: 8px 12px;
        margin: 4px 0;
        background-color: #f8f9fa;
        border-radius: 4px;
        font-size: 14px;
        color: #555;

        &:hover {
          background-color: #eef2f7;
        }
      }
    }

    .popup-footer {
      display: flex;
      justify-content: center;
      gap: 12px;
      margin-top: 24px;

      button {
        padding: 8px 24px;
        border-radius: 4px;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.2s ease;
      }

      .submit-btn {
        background-color: #51c2b8;
        color: white;
        border: none;

        &:hover {
          background-color: #429e96;
        }
      }

      .cancel-btn {
        background-color: #f5f5f5;
        border: none;
        color: #333;

        &:hover {
          background-color: #e5e5e5;
        }
      }
    }
  }
}

// 모달 오버레이 스타일도 추가/수정
// .modal-overlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
//   z-index: 1000;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

.target-selection {
  // margin-top: 20px;
  margin-top: 5px;

  .group-select {
    width: 100%;
    margin-bottom: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .group-select select {
    font-size: 12px !important;
    font-weight: normal;
  }

  .font-size {
    font-size: 12px !important;
    font-weight: 500 !important;
  }

  .folder-list {
    max-height: 200px;
    overflow-y: auto;
    // border: 1px solid #eee;
    border-radius: 4px;
  }

  .folder-option {
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: #f5f5f5;
    }

    &.selected {
      background-color: #e3f2fd;
    }

    &.root-option {
      border-bottom: 1px solid #eee;
      font-weight: 500;
      margin-bottom: 10px !important;
      font-size: 1.2em !important;
    }
  }
}

h4 {
  margin: 0 0 8px 0;
  font-size: 14px;
  color: #666;
}

.doc-move-checkbox {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.doc-move-checkbox input {
  width: 20px;
  height: 20px;
}

.know-priority-checkbox {
  display: flex;
  align-items: center;
  margin-right: 10px;

  input {
    width: 20px;
    height: 20px;
  }
}

.priority-options {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 20px 0;
}

.priority-option {
  width: 100%;
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f5f5f5;
  }

  &.selected {
    background-color: #e3f2fd;
    border-color: #51c2b8;
    color: #51c2b8;
  }
}

.control-button.active {
  background-color: #e9ecef !important;
  border-color: #dee2e6 !important;
}

.knowledge-item-wrapper {
  display: flex;
}

.priority-badge {
  font-size: 0.75em;
  padding: 2px 8px;
  border-radius: 12px;
  margin-left: 6px;
  font-weight: 600;
}

.priority-9 {
  background-color: #ffebee;
  color: #d32f2f;
}

.priority-1 {
  background-color: #e8f5e9;
  color: #388e3c;
}

.priority-0 {
  background-color: #e3f2fd;
  color: #1976d2;
}

.date-info {
  font-size: 0.85em !important;
  color: #777 !important;
  text-align: right !important;
  margin: 0px 0 3px 0 !important;
}

.progress-popup-overlay {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-items: flex-end;
  pointer-events: none;
}

.pdf-popup-container {
  position: fixed;
  display: flex;
  pointer-events: auto;
}

.progress-popup {
  width: 320px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  animation: slide-in 0.3s ease-out;
}

@keyframes slide-up {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.progress-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #eee;
}

.progress-header span {
  font-weight: 600;
  font-size: 14px;
  color: #333;
}

.close-progress-btn {
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: #666;
}

.progress-body {
  padding: 15px;
}

.progress-status {
  font-size: 13px;
  color: #555;
  margin-bottom: 10px;
}

.progress-bar-container {
  height: 8px;
  background-color: #eee;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 8px;
}

.progress-bar {
  height: 100%;
  background-color: #51c2b8;
  border-radius: 4px;
  transition: width 0.3s ease;
}

.progress-bar.complete {
  background-color: #4caf50;
}

.progress-percentage {
  text-align: right;
  font-size: 12px;
  color: #666;
}

.progress-actions {
  display: flex;
  justify-content: flex-end;
  padding: 10px 15px 15px;
}

.ok-btn {
  background-color: #51c2b8;
  color: white;
  border: none;
  padding: 6px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
}

.ok-btn:hover {
  background-color: #45a69f;
}

.know-priority-checkbox input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.know-priority-checkbox input:disabled + label {
  opacity: 0.5;
  cursor: not-allowed;
}

.folderGroupKey {
  font-size: 0.3rem !important;
}
</style>
