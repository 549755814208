<script setup lang="ts">
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogTitle
} from "radix-vue";
import CloseIcon from "@fluentui/svg-icons/icons/dismiss_24_regular.svg";
// import { sidebarKey } from "../components/common/sidebar/SidebarRoot.vue";

import { ref, inject } from "vue";

// const sidebarContext = inject(sidebarKey);

const props = defineProps({
  pPopTitle: {
    type: String,
    default: "애니톡"
  },
  pPopupInfos: {
    type: Object,
    require: false
  },
  pSetDefault: Function
});

const commonPopInfo = ref({
  title: props.pPopTitle,
  mainBtnColor: props.pPopupInfos?.mainColor
    ? props.pPopupInfos.mainColor
    : "blue",
  subBtnColor: props.pPopupInfos?.subColor ? props.pPopupInfos.subColor : "gray"
});

const setDefault = () => {
  if (props.pSetDefault) {
    props.pSetDefault();
  }
};
</script>

<template>
  <DialogPortal>
    <DialogOverlay class="DialogOverlay" />
    <DialogContent
      class="DialogContent loginOptionWrap"
      :disableOutsidePointerEvents="false"
    >
      <div
        class="DialogTitle commonPopHeader"
        style="font-size: 18px; font-weight: bold; padding: 0px 10px"
      >
        <span>{{ commonPopInfo.title }}</span>
        <DialogClose as-child @click="setDefault">
          <CloseIcon class="cursorP" />
        </DialogClose>
      </div>
      <div class="popBodyFooterWrap">
        <DialogDescription class="DialogDescription commonPopBody">
          <slot name="body"></slot>
        </DialogDescription>
      </div>
    </DialogContent>
  </DialogPortal>
</template>

<style lang="scss" scoped></style>
