<script setup lang="ts">
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle
} from "radix-vue";
import { ref } from "vue";
import CloseIcon from "@fluentui/svg-icons/icons/dismiss_16_filled.svg";
import { useStore } from "../../stores/store";
import { updateChannel } from "../../api/backend";
import { Channel } from "../../api/types";

const store = useStore();

interface Props {
  channel: Channel;
}
const props = defineProps<Props>();
const channel = ref(props.channel);

const onChannelUpdate = async () => {
  if (channel.value.name.length > 0) {
    await updateChannel(channel.value);
    await store.updateAllChannelList();
    await store.updateMyChannelInfo();
  }
};
</script>

<template>
  <DialogPortal>
    <DialogOverlay class="DialogOverlay" />
    <DialogContent class="DialogContent" :disableOutsidePointerEvents="false">
      <DialogTitle class="DialogTitle"> 채널 업데이트 </DialogTitle>
      <DialogDescription class="DialogDescription">
        업데이트할 정보들을 작성하십시오.
      </DialogDescription>
      <fieldset class="Fieldset">
        <label class="Label" for="name"> 이름 </label>
        <input
          id="name"
          class="Input"
          placeholder="채널 이름"
          v-model="channel.name"
        />
      </fieldset>
      <div
        :style="{
          display: 'flex',
          marginTop: 25,
          justifyContent: 'flex-end'
        }"
      >
        <DialogClose as-child>
          <button class="Button" @click="onChannelUpdate">저장</button>
        </DialogClose>
      </div>
      <DialogClose class="IconButton" aria-label="Close">
        <CloseIcon />
      </DialogClose>
    </DialogContent>
  </DialogPortal>
</template>

<style scoped lang="scss">
@use "../../styles/dialog";
</style>
