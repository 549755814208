<script setup lang="ts">
import {
  DialogRoot,
  DialogTrigger,
  RadioGroupItem,
  RadioGroupRoot
} from "radix-vue";
import { computed, inject, onMounted, ref, watch } from "vue";
// import { sidebarKey } from "../../common/sidebar/SidebarRoot.vue";
import AdminIcon from "@fluentui/svg-icons/icons/crown_20_filled.svg";
import { useQuasar } from "quasar";
import { useRoute, useRouter } from "vue-router";
import { joinChannel, unjoinChannel } from "../../../api/backend";
import { useContentsStore } from "../../../stores/content";
import { useStore } from "../../../stores/store";
// import LockIcon from "@fluentui/svg-icons/lockClosed16Filled.svg";
import LockIcon from "@fluentui/svg-icons/icons/lock_closed_key_20_filled.svg";
import SubscribeIcon from "@fluentui/svg-icons/icons/checkmark_circle_20_regular.svg";

/* 컴포넌트*/
import DeleteChannelDialog from "../DeleteChannelDialog.vue";
import UpdateChannelDialog from "../UpdateChannelDialog.vue";
import PrivateChannelDialog from "../PrivateChannelDialog.vue";

import { Channel, AuthUser } from "../../../api/types";

const password = ref("");
const route = useRoute();
const router = useRouter();
const $q = useQuasar();
const store = useStore();
// const sidebarContext = inject(sidebarKey);
const contentStore = useContentsStore();

const props = defineProps({
  pSwitchState: {
    type: Boolean,
    default: false
  },
  pOpenDetailPop: Function,
  pIsAllChannelList: Boolean
});
const channelDesc = ref("안녕하세요, 환영합니다.");

const showMyChannelList = computed<Channel[]>(() => {
  const filteredData = ref<Channel[]>([]);
  filteredData.value = store.allChannelList; // 초기화
  filteredData.value = store.allChannelList.filter(
    //   (item) =>
    //     item.auth_type == "S" ||
    //     item.auth_type == "A" ||
    //     item.auth_type == "E" ||
    //     item.channel_key == 85
    // ); // 초기화
    (item) =>
      ["S", "A", "E", "M", ""].some((type) =>
        item.auth_type.split(",").includes(type)
      ) || item.channel_key === 85
  );

  // 검색 (대소문자 구분 없이 검색)
  if (contentStore.searchText.trim() !== "") {
    filteredData.value = store.allChannelList.filter(
      (item) =>
        item.name
          .toLowerCase()
          .includes(contentStore.searchText.trim().toLowerCase()) // ✅ 대소문자 무시
    );
  }

  // 내 관리 채널 필터링
  if (props.pSwitchState === true) {
    filteredData.value = filteredData.value.filter(
      (item) => item.auth_type === "A"
    );
  }

  return filteredData.value;
});

watch(
  () => props.pSwitchState,
  () => {
    showMyChannelList.value;
  }
);

const followThisChannelParam = (channel: Channel) => {
  const auth_user: Omit<AuthUser, "auth_user_key" | "grant_date"> = {
    user_key: store.myInfo?.user_key,
    channel_key: channel.channel_key,
    auth_type: "S",
    grant_user_key: 0
  };

  const secure_str4 = password.value; // secure_str4 값

  // ReqChannelAnswerWithSecure 객체 반환
  return { auth_user, secure_str4 };
};

const followThisChannel = async (channel: Channel, index: number) => {
  console.log("channelll ftc", channel);
  // sidebarContext?.toggleEnabled();
  const params = followThisChannelParam(channel); // 필요한 파라미터 생성
  const response = await joinChannel(params); // 생성된 객체를 joinToTheChannel에 전달
  if (response) {
    store.updateChannelAuth(index);
    $q.notify({
      message: "구독이 완료되었습니다.",
      color: "positive"
    });
    console.log("response", response);
    goToChatting(channel);
    // sidebarContext?.toggleEnabled();
    password.value = ""; // 비밀번호 초기화
  } else {
    $q.notify({
      message: "비밀번호가 틀렸습니다.",
      color: "negative"
    });
  }
};

const unFollowThisChannelParam = (channel: object) => {
  privateChannelDialog.value = false;
  const param = new Object();

  param.user_key = store.myInfo?.user_key;
  param.channel_key = channel.channel_key;

  return param;
};
const getChannelIndex = (channel: Channel) => {
  const currentChannelKey = channel.channel_key;
  const chanIndex = store.allChannelList.findIndex(
    (item) => item.channel_key === channel.channel_key
  );
  console.log("chanIndex", chanIndex);
  return chanIndex;
};
const unFollowThisChannel = async (channel: Channel) => {
  await unjoinChannel(unFollowThisChannelParam(channel));
  store.toggleSelectedRoom();
  $q.notify({ message: "구독이 취소되었습니다.", color: "negative" });
  store.deleteFollowChannelAuth(getChannelIndex(channel));
};

import { sidebarKey } from "../../common/sidebar/SidebarRoot.vue";
const sidebarContext = inject(sidebarKey);
const goToChatting = (channel: object) => {
  console.log("채팅방 이동");
  console.log("password", password.value);
  store.currentChannel = [];
  store.currentChannel = channel;
  sidebarContext?.toggleEnabled();
  // privateChannelDialog.value = false;
  router.push("/talk");
};

// const goToChattingRoom = (channel: Channel) => {
//   console.log("channel입니다", channel.value);
//   if (
//     !channel.auth_type.includes("A") &&
//     !channel.auth_type.includes("S") &&
//     channel.secure_yn == true &&
//     store.myInfo?.observer_yn == 0
//   ) {
//     $q.notify({ message: "구독 후 이용해주세요.", color: "negative" });
//   } else if (store.myInfo?.observer_yn == 1) {
//     goToChatting(channel);
//   } else {
//     goToChatting(channel);
//   }
// };

const goToChattingRoom = async (channel: Channel, index: number) => {
  console.log("channel입니다", channel);

  // 개인방이 없는 경우 처리
  const user_key = store.myInfo?.user_key;
  if (
    !channel.auth_type === "" &&
    !store.hasPrivateRoom(channel.channel_key, user_key)
  ) {
    await followThisChannel(channel, index); // 구독 후 입장
    goToChatting(channel);
    console.log("개인방이 없는 경우 처리");

    return;
  }

  // ✅ 1. A(관리자)인 경우 자동 입장
  if (channel.auth_type.includes("A") || store.myInfo?.observer_yn === 1) {
    goToChatting(channel);
    console.log("A(관리자)인 경우 자동 입장");
    return;
  }

  // ✅ 2. observer(전체 권한)인 경우, S(구독자)가 아니라면 자동 구독 후 입장
  if (store.myInfo?.observer_yn === 1 && channel.auth_type === "") {
    // if (store.myInfo?.observer_yn === 1) {
    $q.notify({ message: "구독후 입장합니다.", color: "positive" });
    await followThisChannel(channel, index); // 구독 후 입장
    goToChatting(channel);
    console.log("store.myInfo?.observer_yn", store.myInfo?.observer_yn);
    console.log("옵저버일때 입장");
    return;
  }

  // ✅ 3. observer도 아니고, S(구독자)도 아닌 경우 → "구독 후 이용해주세요." 알림 표시
  // if (channel.auth_type === "" && store.myInfo?.observer_yn === 0) {
  if (channel.auth_type === "") {
    openPrivateDialog(channel);
    $q.notify({ message: "구독 후 이용해주세요.", color: "negative" });
    console.log("구독자가 아닐때");
    return;
  }
  console.log("일반 구독자 입장 ");
  goToChatting(channel);
};

const pTitleValue = ref("비공개 채팅");

const privateChannelDialog = ref(false);
const closePrivateDialog = () => {
  privateChannelDialog.value = false;
};
// const openPrivateDialog = () => {
const openPrivateDialog = (channel) => {
  store.selectedChannel = channel;
  sidebarContext?.toggleEnabled();
  console.log("팝업 오픈하기: ", channel);
  // 조건에 따라 privateChannelDialog를 true로 설정
  if (channel.channel_key) {
    // store.currentChannel = channel;
    // store.currentChannel = [];
    privateChannelDialog.value = true;
    // sidebarContext?.toggleEnabled();
    // store.currentChannel = undefined;
  }
};
const kindOf = ref("구독");

onMounted(() => {
  console.log("내 정보", store.myInfo);
  console.log("내 채널 정보", showMyChannelList.value);
  console.log("전체 채널 정보", store.allChannelList);
});
</script>
<template>
  <RadioGroupRoot
    v-model="store.selectedChannelBold"
    v-for="(channel, index) in showMyChannelList"
    :key="channel.channel_key"
    orientation="vertical"
  >
    <RadioGroupItem
      type="single"
      :value="`${index}`"
      class="channelItem"
      @click="goToChattingRoom(channel, index)"
      :style="
        (store.selectedChannel?.channel_key === channel?.channel_key
          ? { fontWeight: 'bold' }
          : '',
        pIsAllChannelList
          ? { padding: '10px 15px' }
          : { padding: '10px 40px 10px 30px' })
      "
    >
      <div class="listItemWrap flexAlign">
        <!-- <AvatarRoot class="AvatarRoot">
          <AvatarImage
            class="AvatarImage"
            src="/src/assets/profile.png"
            alt="Pedro Duarte"
          />
        </AvatarRoot> -->
        <div class="eachItemText">
          <div class="fontMd">
            {{ channel?.name }}
            <span v-if="channel.secure_yn"><LockIcon /></span>
            <!-- O type 관리  -->
            <!-- <AdminIcon
              v-if="
                channel &&
                channel.auth_type &&
                channel.auth_type.includes('A') &&
                channel.observer_yn == 1
              "
            /> -->
            <AdminIcon
              v-if="
                (channel &&
                  channel.auth_type &&
                  channel.auth_type.includes('A')) ||
                store.myInfo?.observer_yn == 1
              "
            />
          </div>
          <!-- <span :class="{ fontSm: pIsAllChannelList }" class="channelDesc">{{
            channelDesc
          }}</span> -->
        </div>
      </div>
      <!-- O type 관리  -->
      <!-- <div
        class="eachItemBtn"
        v-if="
          channel &&
          channel.auth_type &&
          channel.auth_type.includes('A') &&
          channel.auth_type.includes('O')
        "
      > -->
      <!-- O type 관리  -->
      <!-- <div
        class="eachItemBtn"
        v-if="channel && channel.auth_type && channel.auth_type.includes('A') &&
          channel.auth_type.includes('O')"
      > -->
      <!-- <div
        class="eachItemBtn"
        v-if="
          (channel && channel.auth_type && channel.auth_type.includes('A')) ||
          store.myInfo?.observer_yn == 1
        "
      >
        <template v-if="route.path !== '/all'">
          <div style="display: flex; gap: 3px">
            <DialogRoot>
              <DialogTrigger @click.stop>수정</DialogTrigger>
              <UpdateChannelDialog :channel="channel" />
            </DialogRoot>
            <DialogRoot>
              <DialogTrigger @click.stop>삭제</DialogTrigger>
              <DeleteChannelDialog :channel="channel" />
            </DialogRoot>
          </div>
        </template>
      </div> -->

      <!-- secure_yn = true인 채널 -->
      <!-- <div
        class="eachItemBtn"
        v-if="
          channel.secure_yn == true &&
          !channel.auth_type.includes('A') &&
          !channel.auth_type.includes('S') &&
          store.myInfo?.observer_yn == 0
        "
      > -->
      <div
        class="eachItemBtn"
        v-if="
          channel.secure_yn == true &&
          !channel.auth_type.includes('A') &&
          !channel.auth_type.includes('S') &&
          !channel.auth_type.includes('E') &&
          !channel.auth_type.includes('M')
        "
      >
        <template v-if="route.path !== '/all'">
          <DialogRoot>
            <!-- <DialogTrigger
              @click.stop
              @click="() => openPrivateDialog(channel)"
            > -->
            <DialogTrigger @click.stop="openPrivateDialog(channel)">
              구독 <SubscribeIcon />

              <PrivateChannelDialog
                v-bind:open="
                  privateChannelDialog &&
                  store.selectedChannel?.channel_key === channel.channel_key
                "
                :pClosePop="closePrivateDialog"
                :channel="channel"
                :index="index"
                :value="`${index}`"
                v-model:password="password"
                :goToChatting="goToChatting"
                :followThisChannel="followThisChannel"
                :kindOf="kindOf"
                :pTitle="pTitleValue"
                :pOpen="openPrivateDialog"
              />
            </DialogTrigger>
          </DialogRoot>
        </template>
      </div>

      <!-- secure_yn = fasle인 채널 -->
      <div
        class="eachItemBtn"
        v-if="
          channel.secure_yn == false &&
          !channel.auth_type.includes('A') &&
          !channel.auth_type.includes('S')
        "
      >
        <template v-if="route.path !== '/all'">
          <button type="button" @click.stop="followThisChannel(channel, index)">
            구독 <SubscribeIcon />
          </button>
        </template>
      </div>
      <div
        class="eachItemBtn"
        v-if="
          channel &&
          channel.auth_type &&
          channel.auth_type.includes('S') &&
          !channel.auth_type.includes('A')
        "
      >
        <!-- <template v-if="route.path !== '/all'">
          <button @click.stop="unFollowThisChannel(channel)" type="button">
            구독중
          </button>
        </template> -->
      </div>
    </RadioGroupItem>
  </RadioGroupRoot>
</template>

<style scoped lang="scss">
@use "../channelItem";

.listItemWrap {
  gap: 10px;
}

.eachItemBtn {
  display: flex;
  flex-direction: column;
}

.channelDesc {
  font-size: 1rem;
}
</style>
