<script setup lang="ts">
import { usePopupStore } from "../stores/popups";

/* 컴포넌트 */
import CommonPopLayer from "./CommonPopLayer.vue";

const popStore = usePopupStore();

const popTitle = "확인";
</script>

<template>
  <CommonPopLayer :pPopTitle="popTitle">
    <template #body>
      <div>{{ popStore.confirmPopBody }}</div>
    </template>
    <template #actions>
      <button @click="popStore.closeConfirmPopLayer" type="button">닫기</button>
    </template>
  </CommonPopLayer>
</template>

<style lang="scss" scoped></style>
