import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const {
  VITE_FB_API_KEY,
  VITE_FB_AUTHDOMAIN,
  VITE_FB_PROJECT_ID,
  VITE_FB_MESSAGING_SENDER_ID,
  VITE_FB_APP_ID,
  VITE_FB_STORAGE_BUCKET,
  VIAT_FB_MEASUREMENT_ID
  // VITE_FB_GOOGLE_LOGIN_CLIENT_ID,
  // VITE_FB_GOOGLE_LOGIN_SECRET_KEY
} = import.meta.env;
console.log(import.meta.env);

const firebaseConfig = {
  apiKey: VITE_FB_API_KEY,
  authDomain: VITE_FB_AUTHDOMAIN,
  databaseURL: VITE_FB_PROJECT_ID,
  projectId: VITE_FB_PROJECT_ID,
  storageBucket: VITE_FB_STORAGE_BUCKET,
  messagingSenderId: VITE_FB_MESSAGING_SENDER_ID,
  appId: VITE_FB_APP_ID,
  measurementId: VIAT_FB_MEASUREMENT_ID
};
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export async function requestPermission() {
  console.log("Requesting permission...");
  const permission = await Notification.requestPermission();

  if (permission === "granted") {
    console.log("Notification permission granted.");
    await requestForToken();
  } else {
    console.log("Unable to get permission to notify.");
  }
}

export async function requestForToken() {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BP7Pq4cTDYNkXf28QbXrlcsOBdjTiIJMTLAIYTtP89NouwjCV631XG0qBwCawO7pV668_zmG_rnSeYazbMeonak",
      serviceWorkerRegistration: await navigator.serviceWorker.getRegistration()
    });
    if (currentToken) {
      console.log("current token for client: ", currentToken);
      return currentToken;
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("알람!!!!!!", payload);
      resolve(payload);
    });
  });
