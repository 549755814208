if (typeof window !== "undefined") {
  const useragt = navigator.userAgent.toLowerCase();
  const target_url = window.location.href;

  // 이미 리다이렉션 파라미터가 있는지 확인
  if (target_url.indexOf("ext_redirect=1") === -1) {
    if (useragt.match(/kakaotalk/i)) {
      // 카카오톡 외부브라우저로 호출
      window.location.href =
        "kakaotalk://web/openExternal?url=" + encodeURIComponent(target_url);
    }
  }
}

import { createPinia } from "pinia";

import "sanitize.css/sanitize.css";

import { createApp } from "vue";
import "./components/root/styles.scss";
// import dayjs from "./plugins/dayjs";
import { Notify, Quasar } from "quasar";
import datePlugin from "./plugins/getChartLabel";

/* 컴포넌트 */
import "quasar/src/css/index.sass";
import App from "./App.vue";
import confirmAskPop from "./popups/ConfirmAskPop.vue";
import confirmPop from "./popups/ConfirmPop.vue";
import router from "./routers";

import { registerSW } from "virtual:pwa-register";

if ("serviceWorker" in navigator) {
  registerSW({ immediate: true });
}

const app = createApp(App);
app.use(datePlugin);
// app.use(dayjs);
app.use(createPinia());
app.use(router);
app.use(Quasar, {
  plugins: {
    Notify
  },
  config: {
    notify: {
      timeout: 500 // Default timeout for all notifications
    }
  }
});

app.component("confirmAskPop", confirmAskPop);
app.component("confirmPop", confirmPop);

app.mount("#app");
