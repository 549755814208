<script setup lang="ts">
import { ref, reactive, nextTick, onMounted } from "vue";
import { useQuasar } from "quasar";
import MainPage from "../../../src/pages/main/MainPage.vue";
import { useRouter } from "vue-router";
import exitIcon from "@fluentui/svg-icons/icons/arrow_exit_20_filled.svg";
import scaleIcon from "@fluentui/svg-icons/icons/scale_fill_20_filled.svg";
import peopleIcon from "@fluentui/svg-icons/icons/person_20_filled.svg";
import { useSpeechRecognition, useTTS } from "../main/sound";
// import QuestionBox from "../../components/main/QuestionBox.vue";

import { Credentials, LANGUAGE, loginIdPw } from "../../api/backend";
import ChromaKey from "./ChromaKey.vue";
// const answerText = ref < String > "";
const $q = useQuasar(); // Quasar 인스턴스
const answerText = ref("");
const isTTSPlaying = ref(false);
const selectedBackground = ref("사무실");

// 비디오 요소 참조
const video1 = ref(null);
const video2 = ref(null);
const video3 = ref(null);
const canvasRef = ref(null);

const CANVAS_WIDTH = 640;
const CANVAS_HEIGHT = 900;

const state = reactive({
  background: new Image()
});

const backgrounds = {
  사무실: "src/assets/office.jpg",
  전시장: "src/assets/exhibition.avif",
  미술관: "src/assets/museum.jpg",
  경복궁: "src/assets/gyeongbokgung.jpeg"
};

// isChatbotVisible 상태 관리 (true이면 chatWrap이 보임)
const isChatbotVisible = ref(false);
// isAvatarVisible 상태 관리 (true이면 chatWrap이 보임)
const isAvartarVisible = ref(false);
const router = useRouter();

// // Vue 컴포넌트 로드 시 실행
onMounted(async () => {
  await nextTick();

  const videoElements = [video1.value, video2.value, video3.value];

  if (!videoElements.every((el) => el)) {
    return;
  }

  // 비디오 초기화
  videoElements?.forEach((videoEl, index) => {
    initVideoElement(videoEl, index);
  });
  console.log("videoElements: ", videoElements);

  // 첫 번째 비디오 재생
  videoElements[0].style.display = "block";
  videoElements[0].play();
});

const initializeVideos = async () => {
  await nextTick();

  if (!video1.value || !video2.value || !video3.value) {
    console.error("Video elements not properly initialized.");
    return;
  }

  const videoElements = [video1.value, video2.value, video3.value];
  videoElements.forEach((videoEl, index) => initVideoElement(videoEl, index));

  state.background.src = backgrounds.사무실;
  state.background.onload = () => {
    console.log("Background image loaded.");
  };
  state.background.onerror = () => {
    console.error("Failed to load background image.");
  };

  playFixedVideo(videoElements);

  // 고정 비디오 캔버스에 렌더링
  renderToCanvas(video1.value);
};

const initVideoElement = (videoEl, index) => {
  videoEl.inx = index;

  videoEl.onplaying = () => {
    console.log(`Video ${videoEl.inx + 1} is playing`);
    renderToCanvas(videoEl); // 현재 재생 중인 비디오를 캔버스에 렌더링
  };

  videoEl.onended = () => {
    console.log(`Video ${videoEl.inx + 1} ended`);

    // TTS 상태 확인 후 동작 결정
    if (isTTSPlaying.value) {
      console.log("TTS is playing, moving to the next TTS video");
      playRandomTTSVideo(); // TTS 비디오 재생
    } else if (videoEl === video1.value) {
      console.log("Default video loop continues.");
      playNextVideo(videoEl, video1.value); // 기본 비디오는 루프
    } else {
      console.log("Returning to default video.");
      playNextVideo(videoEl, video1.value); // TTS 종료 후 기본 비디오로 복귀
    }
  };

  videoEl.onerror = () => {
    console.error(`Error occurred in video ${videoEl.inx + 1}`);
    renderToCanvas(video1.value); // 에러 발생 시 기본 비디오로 복귀
  };
};

// 캔버스에 비디오 렌더링
const renderToCanvas = (video) => {
  const canvas = canvasRef.value;
  if (!canvas || !video) {
    console.error("Canvas or video is not properly initialized.");
    return;
  }

  const ctx = canvas.getContext("2d", { willReadFrequently: true });
  if (!ctx) {
    console.error("Failed to get canvas context.");
    return;
  }

  canvas.width = CANVAS_WIDTH;
  canvas.height = CANVAS_HEIGHT;

  // 마지막 프레임 고정
  const renderFrame = () => {
    ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
    ctx.drawImage(video, 0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

    // Chroma Key 처리
    const frame = ctx.getImageData(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
    const data = frame.data;

    for (let i = 0; i < data.length; i += 4) {
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];

      if (g > 100 && r < 80 && b < 80) {
        data[i + 3] = 0; // 초록색 제거
      }
    }

    ctx.putImageData(frame, 0, 0);

    // 배경 이미지 그리기
    if (state.background.complete) {
      ctx.globalCompositeOperation = "destination-over";
      ctx.drawImage(state.background, 0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      ctx.globalCompositeOperation = "source-over";
    }

    if (!video.paused && !video.ended) {
      requestAnimationFrame(renderFrame);
    }
  };

  renderFrame();
};

// TTS 비디오 재생
const playRandomTTSVideo = () => {
  isTTSPlaying.value = true;
  const videoElements = [video2.value, video3.value];

  // 모든 비디오 정지
  videoElements.forEach((video) => {
    video.style.display = "none";
    video.pause();
  });

  // 랜덤으로 TTS 비디오 선택
  const randomIndex = Math.floor(Math.random() * videoElements.length);
  const randomVideo = videoElements[randomIndex];

  randomVideo.style.display = "block";
  renderToCanvas(randomVideo);

  randomVideo.play().catch((err) => {
    console.error("Failed to play TTS video:", err);
    isTTSPlaying.value = false;
  });
};

// 고정된 비디오 재생 함수
function playFixedVideo(videoElements) {
  // 모든 비디오 숨기기
  videoElements.forEach((video) => {
    video.style.display = "none";
    video.pause();
  });

  // 고정 비디오 (첫 번째 비디오) 재생
  const fixedVideo = videoElements[0];
  if (fixedVideo) {
    fixedVideo.style.display = "block";
    fixedVideo.loop = false; // 필요 시 `true`로 설정
    try {
      fixedVideo.play();
    } catch (error) {
      console.error("Error playing fixed video:", error);
    }
  }
}

// 배경 변경 핸들러
const changeBackground = (event) => {
  const selectedBackground = event.target.value;
  state.background.src = backgrounds[selectedBackground];
  console.log(`Selected background: ${selectedBackground}`);
};

// const applyChromaKey = (videos, canvas, ctx) => {
//   function renderFrame() {
//     if (!videos || !ctx || !canvas) {
//       console.error("Videos, canvas, or context is not properly initialized.");
//       return;
//     }

//     ctx.clearRect(0, 0, canvas.width, canvas.height);
//     const activeVideo = videos.find(
//       (video) => video && video.style.display === "block"
//     );
//     if (!activeVideo) {
//       console.error("No active video found.");
//       return;
//     }

//     ctx.drawImage(activeVideo, 0, 0, canvas.width, canvas.height);

//     const frame = ctx.getImageData(0, 0, canvas.width, canvas.height);
//     const data = frame.data;

//     for (let i = 0; i < data.length; i += 4) {
//       const r = data[i];
//       const g = data[i + 1];
//       const b = data[i + 2];

//       if (g > 100 && r < 80 && b < 80) {
//         data[i + 3] = 0;
//       }
//     }

//     ctx.putImageData(frame, 0, 0);

//     if (state.background.complete && !state.background.error) {
//       ctx.globalCompositeOperation = "destination-over";
//       ctx.drawImage(state.background, 0, 0, canvas.width, canvas.height);
//       ctx.globalCompositeOperation = "source-over";
//     }

//     if (!activeVideo.paused && !activeVideo.ended) {
//       requestAnimationFrame(renderFrame);
//     }
//   }

//   renderFrame();
// };

// 버튼 클릭 시 chatWrap의 표시 상태를 토글

const toggleChatbot = () => {
  isChatbotVisible.value = !isChatbotVisible.value;
};

// 아바타 팝업 토글
const toggleAvatar = async () => {
  isAvartarVisible.value = !isAvartarVisible.value;
  if (isAvartarVisible.value) {
    await initializeVideos();
  }
};

const goToPage = () => {
  router.push("/talk/channel?chKey=225");
};

const silentVideo = {
  src: "src/assets/silent.mp4",
  duration: 1
};

const { initTTS, runTTS, stopTTS } = useTTS();
const { micStatus, initRecognition, startRecognition, stopRecognition } =
  useSpeechRecognition();
const selectedLanguage = ref(LANGUAGE.KOREAN.code);

const initializeTTS = async () => {
  try {
    await initTTS();
    console.log("TTS 초기화 완료");
  } catch (error) {
    console.error("TTS 초기화 실패:", error);
  }
};

// 컴포넌트가 로드될 때 TTS 초기화
initializeTTS();

const startSpeechRecognition = async () => {
  // await initRecognition(selectedLanguage.value);
  await initTTS();
  startRecognition({
    onResult(result) {
      answerText.value = result;
    }
  });
};

// 메세지 전송 함수
const createAnswerBtn = async () => {
  const text = answerText.value;

  if (!text) {
    $q.notify({
      message: "텍스트를 입력해주세요.",
      color: "negative",
      timeout: 3000
    });
    return;
  }

  try {
    isTTSPlaying.value = true;
    const videoElements = [video1.value, video2.value, video3.value];

    // 모든 비디오 정지
    videoElements.forEach((video) => {
      video.pause();
      video.style.display = "none";
    });

    // TTS 실행 및 랜덤 비디오 재생
    const wavData = await runTTS(text, () => {
      console.log("TTS playback ended");
      isTTSPlaying.value = false; // TTS 종료
      playDefaultVideo();
    });
    playRandomTTSVideo();
    return wavData;
  } catch (error) {
    console.error("Error in createAnswerBtn:", error);
    isTTSPlaying.value = false; // 오류 발생 시 TTS 종료 처리
    playDefaultVideo();
  }
};

function playDefaultVideo() {
  if (isTTSPlaying.value) return;

  const videoElements = [video1.value, video2.value, video3.value];
  videoElements.forEach((video) => {
    video.style.display = "none";
    video.pause();
  });

  const defaultVideo = videoElements[0];
  defaultVideo.style.display = "block";
  defaultVideo.loop = true;

  try {
    defaultVideo.play();
    console.log("Default video is playing.");
  } catch (error) {
    console.error("Error playing default video:", error);
  }
}

import desktopImageSrc from "../../assets/namsu.png";
import mobileImageSrc from "../../assets/namsumo.png";
import { useStore } from "../../stores/store";
import { useTokenStore } from "../../stores/token";

const desktopImage = desktopImageSrc;
const mobileImage = mobileImageSrc;

const currentImage = ref(desktopImage);

// 화면 크기 변경 감지
const updateImage = () => {
  if (window.innerWidth <= 768) {
    currentImage.value = mobileImage; // 모바일 이미지로 변경
  } else {
    currentImage.value = desktopImage; // 데스크탑 이미지로 변경
  }
};

updateImage(); // 초기 설정
window.addEventListener("resize", updateImage); // 화면 크기 변경 감지

const playNextVideo = async (currentVideo, nextVideo) => {
  if (currentVideo) {
    renderToCanvas(currentVideo); // 마지막 프레임 고정
    currentVideo.pause();
  }

  if (nextVideo.readyState < 3) {
    await new Promise((resolve) =>
      nextVideo.addEventListener("canplay", resolve)
    );
  }

  nextVideo.play();
};

const store = useStore();
const tokenStore = useTokenStore();

// ✅ 사용자별 계정 정보 설정 (NamSeoulUniv 사용자 로그인 정보)
const USER_CREDENTIALS: Record<string, Credentials> = {
  // 일반인: { username: "nsu1", password: "2222" },
  일반인: { username: "guest", password: "2222" },
  학생1: { username: "19100070", password: "2222" }, //정지한
  학생2: { username: "재영", password: "1111" },
  학생3: { username: "최팀", password: "3333" },
  교직원: { username: "mkjang", password: "2222" } //장무경
};

let channelPopup: Window | null = null;

// ✅ 자동 로그인 후 팝업 실행 함수
// const openChannelManagement = async (userType: string) => {
//   const width = 360;
//   const height = 780;
//   const left = (window.screen.width - width) / 3.5;
//   const top = (window.screen.height - height) / 2;

//   try {
//     console.log(`🔹 ${userType} 자동 로그인 중...`);

//     const credentials = USER_CREDENTIALS[userType];

//     if (!credentials) {
//       console.error("❌ 해당 유저 유형에 대한 로그인 정보가 없음.");
//       return;
//     }

//     // ✅ 로그인 시도
//     const result = await loginIdPw(credentials);
//     if (result) {
//       // ✅ 로그인 성공 시 로컬 스토리지 저장
//       localStorage.setItem("anytalk_user_info", JSON.stringify(result.user));
//       localStorage.setItem("anytalk_jwt_token", result.access_token);
//       tokenStore.setToken(result.access_token);
//       console.log(`✅ ${userType} 로그인 성공!`);

//       // ✅ 기존 팝업이 열려 있으면 focus
//       if (channelPopup && !channelPopup.closed) {
//         channelPopup.focus();
//         return;
//       }

//       // ✅ 새로운 팝업 창 열기
//       channelPopup = window.open(
//         "/talk/aiChatbot",
//         "channelPopup",
//         `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`
//       );

//       if (channelPopup) {
//         channelPopup.focus();

//         setTimeout(() => {
//           channelPopup?.postMessage(
//             {
//               type: "LOCAL_STORAGE_DATA",
//               data: {
//                 anytalk_user_info: localStorage.getItem("anytalk_user_info"),
//                 anytalk_jwt_token: localStorage.getItem("anytalk_jwt_token"),
//                 anytalk_api_base_url: localStorage.getItem(
//                   "anytalk_api_base_url"
//                 ),
//                 last_channel_key: "250" // ✅ 250번 채널로 설정
//               }
//             },
//             window.location.origin
//           );
//         }, 1000);

//         // ✅ 팝업 로딩 후 크기 조정
//         channelPopup.onload = () => {
//           channelPopup?.resizeTo(width, height);
//         };

//         // ✅ 팝업이 닫히면 변수 초기화
//         channelPopup.onbeforeunload = () => {
//           channelPopup = null;
//         };
//       } else {
//         alert("❌ 팝업 차단이 활성화되어 있습니다!");
//       }
//     }
//   } catch (error) {
//     console.error(`❌ ${userType} 자동 로그인 실패:`, error);
//   }
// };

// const userData = {
//   systemToken: "1A244B951C27",
//   USER_NO: "201520415", // Comcenter
//   PORTAL_ID: "dlawodud15", // Comcenter
//   NAME: "임재영", // 전산원
//   EMAIL: "dlawodud@nsu.ac.kr", // comcenter@nsu.ac.kr
//   HP_NO: "01092921111", // 정보 없음
//   TEL_NO: "", // 정보 없음
//   HAKJEOK_NAME: "컴퓨터학과", // 전산정보원
//   HAKJEOK_CODE: "c18033", // c18033
//   HAGSAENG_HAGNYEON: "2", // 학년 (예: 0,1,2,3,4)
//   GYOJIGWEON_YN: "학부생", // 학부생, 대학원생, 교수, 직원, 조교
//   SOSOK_NAME: "컴퓨터학과", // 전산정보원
//   SOSOK_CODE: "c18033", // c18033
//   GYOJIGWEON_GUBUN: "학부생", // 학부생, 대학원생, 교수, 직원, 조교
//   GYOJIGWEON_GUBUN_2: "학부생" // 학부생, 대학원생, 교수, 직원, 조교
// };

const userData = {
  systemToken: "1A244B951C27",
  id: "201520415", // Comcenter
  empid: "dlawodud15", // Comcenter
  nameKor: "임재영", // 전산원
  email: "dlawodud@nsu.ac.kr", // comcenter@nsu.ac.kr
  hp_no: "01092921111", // 정보 없음
  tel_no: "", // 정보 없음
  sosog_nm: "컴퓨터학과", // 전산정보원
  sosog_cod: "c18033", // c18033
  hagnyeon: "2", // 학년 (예: 0,1,2,3,4)
  user_gubn: "학부생" // 학부생, 대학원생, 교수, 직원, 조교
};

const saveUserSession = (userData: any) => {
  sessionStorage.setItem("nsu_user_info", JSON.stringify(userData));
};

const openChannelManagement = async (userType: string) => {
  saveUserSession(userData);
  const width = 450;
  const height = 850;
  const left = (window.screen.width - width) / 3.5;
  const top = (window.screen.height - height) / 2;

  try {
    console.log(`🔹 ${userType} 자동 로그인 중...`);

    const credentials = USER_CREDENTIALS[userType];
    if (!credentials) {
      console.error("❌ 해당 유저 유형에 대한 로그인 정보가 없음.");
      return;
    }

    // ✅ 로그인 시도
    const result = await loginIdPw(credentials);
    if (result) {
      // ✅ 로그인 성공 시 로컬 스토리지 저장
      localStorage.setItem("anytalk_user_info", JSON.stringify(result.user));
      localStorage.setItem("anytalk_jwt_token", result.access_token);
      // localStorage.setItem("last_channel_key", "250"); // ✅ 250번 채널로 설정
      localStorage.setItem("last_channel_key", "517"); // ✅ 250번 채널로 설정
      tokenStore.setToken(result.access_token);
      console.log(`✅ ${userType} 로그인 성공!`);

      // ✅ 기존 팝업이 열려 있으면 focus
      if (channelPopup && !channelPopup.closed) {
        channelPopup.focus();
        return;
      }

      // ✅ 새로운 팝업 창 열기 (URL에 chKey=250 포함)
      channelPopup = window.open(
        // `/talk/aiChatbot?chKey=250`, // ✅ 250번 채널 자동 이동
        // `/talk/channel?chKey=250`, // ✅ 250번 채널 자동 이동
        `/talk/channel?chKey=517&loginYn=1`, // ✅ 250번 채널 자동 이동
        "channelPopup",
        `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`
      );

      if (channelPopup) {
        channelPopup.focus();

        setTimeout(() => {
          channelPopup?.postMessage(
            {
              type: "LOCAL_STORAGE_DATA",
              data: {
                anytalk_user_info: localStorage.getItem("anytalk_user_info"),
                anytalk_jwt_token: localStorage.getItem("anytalk_jwt_token"),
                nsu_user_info: localStorage.getItem("nsu_user_info"),
                anytalk_api_base_url: localStorage.getItem(
                  "anytalk_api_base_url"
                ),
                // last_channel_key: "250"
                last_channel_key: "517"
              }
            },
            window.location.origin
          );
        }, 1000);

        // ✅ 팝업 로딩 후 크기 조정
        channelPopup.onload = () => {
          channelPopup?.resizeTo(width, height);
        };

        // ✅ 팝업이 닫히면 변수 초기화
        channelPopup.onbeforeunload = () => {
          channelPopup = null;
        };
      } else {
        alert("❌ 팝업 차단이 활성화되어 있습니다!");
      }
    }
  } catch (error) {
    console.error(`❌ ${userType} 자동 로그인 실패:`, error);
  }
};

// const openChannelManagement = (userType: string) => {};
</script>

<template>
  <!-- 테스트 영상 팝업 -->
  <!-- <div class="testWrap">
    <ChromaKey />
  </div> -->

  <img :src="currentImage" alt="남서울대학교" class="fullImage" />
  <div class="wrap">
    <!-- <button class="chatbotBtn" @click="toggleChatbot"> -->
    <div class="chatBtnWrap">
      <button class="chatbotBtn" @click="openChannelManagement('일반인')">
        <!-- <img src="../../assets/anytalk.png" alt="" />
        <span>일반인</span> -->
        <img src="../../assets/nsu_chat_icon.png" alt="남서울대 챗봇 아이콘" />
      </button>
      <!-- <button class="chatbotBtn" @click="openChannelManagement('학생1')">
        <img src="../../assets/anytalk.png" alt="" />
        <span>지한</span>
      </button>
      <button class="chatbotBtn" @click="openChannelManagement('학생2')">
        <img src="../../assets/anytalk.png" alt="" />
        <span>재영</span>
      </button>
      <button class="chatbotBtn" @click="openChannelManagement('학생3')">
        <img src="../../assets/anytalk.png" alt="" />
        <span>최팀</span>
      </button>
      <button class="chatbotBtn" @click="openChannelManagement('교직원')">
        <img src="../../assets/anytalk.png" alt="" />
        <span>교직원</span>
      </button> -->
    </div>
    <!-- chatWrap은 isChatbotVisible 값에 따라 나타나거나 사라집니다 -->
    <div v-if="isChatbotVisible" class="chatWrap">
      <div class="btnWrap">
        <div>
          <button class="btn" @click="goToPage"><scaleIcon />전체보기</button>
          <!-- <button class="btn" @click="toggleAvatar">
            <scaleIcon />아바타 모드
          </button> -->
        </div>
        <button class="btn" @click="toggleChatbot"><exitIcon />닫기</button>
      </div>

      <!-- <MainPage class="mainpage" /> -->
      <iframe
        src="https://anytalk.com/talk/channel?chKey=225"
        frameborder="0"
      ></iframe>
    </div>

    <!-- 아바타 팝업 -->
    <div v-if="isAvartarVisible" class="avatarWrap">
      <div class="btnWrap">
        <button class="btn" @click="toggleAvatar"><exitIcon />닫기</button>
      </div>
      <div class="avatarContent">
        <div class="answerWrap">
          <div>{{ answerText }}</div>
        </div>
        <div class="videoWrap">
          <div id="videoContainer">
            <!-- <video
                ref="video1"
                id="video1"
                playsinline
                muted
                preload="auto"
                style="display: none"
              >
                <source src="../../assets/calmVideo10sc.mp4" type="video/mp4" />
              </video>
              <video
                ref="video2"
                id="video2"
                playsinline
                muted
                preload="auto"
                style="display: none"
              >
                <source src="../../assets/2chomal.mp4" type="video/mp4" />
              </video>
              <video
                ref="video3"
                id="video3"
                playsinline
                muted
                preload="auto"
                style="display: none"
              >
                <source src="../../assets/3초 말.mp4" type="video/mp4" />
              </video> -->
            <canvas
              ref="canvasRef"
              id="myCanvas"
              class="canvas"
              width="640"
              height="360"
            ></canvas>
          </div>
          <div class="background-selector">
            <label
              v-for="(src, name) in backgrounds"
              :key="name"
              :class="[
                'radio-button',
                { selected: selectedBackground === name }
              ]"
            >
              <input
                type="radio"
                name="background"
                :value="name"
                v-model="selectedBackground"
                @change="changeBackground"
              />
              {{ name }}
            </label>
          </div>

          <!-- 비디오 요소 -->
          <video
            ref="video1"
            src="../../assets/calmVideo10sc.mp4"
            class="hidden"
            muted
          ></video>
          <video
            ref="video2"
            src="../../assets/talkVideo4sc.mp4"
            class="hidden"
            muted
          ></video>
          <video
            ref="video3"
            src="../../assets/talkVideo4sc.mp4"
            class="hidden"
            muted
          ></video>
        </div>
        <div class="inputWrap">
          <input type="text" v-model="answerText" />
          <button @click="createAnswerBtn">전송</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.testWrap {
  position: fixed;
  width: 30%;
  height: 80%;
  top: 50px;
  left: 300px;
  border: 1px solid black;
  background-color: white;
  z-index: 10000;
}

.answerWrap {
  display: flex;
  width: 100%;
  min-height: 10%;
  padding: 10px;
  display: inline-block;
  line-height: 24px;
  box-shadow: 0 3px 4px 2px rgba(148, 148, 148, 0.3);
  /* border: 1px solid gray; */
  border-radius: 10px;
  position: relative;
  text-align: left;
  min-width: 40px;
  overflow-y: auto;
  height: 68px;
}

.wrap {
  position: relative;
  background-color: white;
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
}

.fullImage {
  position: absolute;
  /* width: 100vw; */
  height: 100vh;
  z-index: 1;
  object-fit: cover;
}

.chatbotBtn,
.avatarBtn {
  /* position: absolute; */
  /* bottom: 20%;
  right: 2%;
  z-index: 200; */
  border-radius: 100%;
  /* background-color: #3eb697; */
  display: flex;
  flex-direction: column;
  width: 120px;
  height: 120px;
  justify-content: center;
  place-items: center;
  font-size: 22px !important;
  font-weight: 700;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 1);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  background-color: white;
}

.chatbotBtn img {
  width: 80%;
}

.chatbotBtn {
  right: 5%;
}
.avatarBtn {
  right: 20%;
}

.chatbotBtn:hover,
.avatarBtn:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 2);
  background-color: #e1f3ef;

  /* font-size: 24px !important; */
}

.chatWrap,
.avatarWrap {
  /* padding: 15px 30px 30px 30px; */
  z-index: 300;
  width: 350px;
  height: 95%;
  background-color: white;
  position: absolute;
  bottom: 10%;
  right: 5%;
  border-radius: 15px;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
}

iframe {
  height: 100%;
  width: 100%;
  /* border: 0.5px solid gray; */
  border-radius: 15px;
  overflow: hidden;
  transform: translateX(5px);
}

iframe::-webkit-scrollbar {
  display: none; /* 스크롤바를 화면에서 숨김 */
}

/* .mainpage {
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 15px;
  overflow: scroll;
} */

.btnWrap {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 10px; */
}

.btn {
  background-color: white;
  border: none;
  box-shadow: none;
}

.avatarContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  justify-content: space-between;
  height: auto; /* 자동 높이 조정 */
  overflow: visible; /* 넘치는 내용이 잘리지 않도록 설정 */
}

.videoWrap {
  width: 100%;
  min-height: 400px; /* 높이 조정 */
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}

.inputWrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 10px;
}

.inputWrap input {
  width: 80%;
  border: 7px solid #4ec9a9;
  border-radius: 10px;
}

.videoWrap video {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  object-fit: cover; /* 비디오 내용 크기에 맞춤 */
}

#video1 {
  display: block;
}

#video2 {
  display: none;
}

canvas {
  max-width: 100%;
  border-radius: 10px;
  padding-top: 10px;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  position: absolute;
}

.videoContainer {
  position: relative;
  width: 640px;
  height: 360px;
  border: 1px solid #000;
  margin-bottom: 20px;
  border-radius: 10px;
}

.background-selector {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
  padding-top: 10px;
}

.radio-button {
  flex: 0 0 calc(50% - 10px);
  text-align: center;
  background-color: #f0f0f0;
  border: 2px solid #d3d3d3;
  border-radius: 10px;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.radio-button.selected {
  background-color: #4ec9a9;
  border-color: #4ec9a9;
  color: white;
}

.radio-button input {
  display: none;
}
.chatBtnWrap {
  display: flex;
  gap: 20px;
  position: absolute;
  bottom: 20%;
  right: 2%;
  z-index: 200;
  /* position: fixed;
  bottom: 20%;
  right: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px; */
}
</style>
