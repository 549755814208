<!-- KmsSearchPop.vue -->
<script setup lang="ts">
import { ref, watch } from "vue";

import KmsViewPage from "./KmsViewPage.vue";
import { useStore } from "../../../stores/store";
import ChatWarning from "@fluentui/svg-icons/icons/chat_warning_24_regular.svg";
import CloseIcon from "@fluentui/svg-icons/icons/dismiss_24_regular.svg";

import {
  getKmsKnowList2,
  ReqKmsChannelKnow,
  searchKmsKnowElastic
} from "../../../api/backend";
import KmsSearchViewPage from "./KmsSearchViewPage.vue";
import { KmsKnow } from "../../../api/types";

const props = defineProps({
  show: Boolean,
  searchQuery: String,
  searchOption: {
    type: String,
    default: "elasticKnow"
  }
});

// 독 업데이트
// const emit = defineEmits(["close", "moveToKnowledge"]);
const emit = defineEmits(["close"]);

const kmsStore = useStore();

const showViewPopup = ref(false);
const selectedItem = ref(null);
const isElasticSearch = ref(false); // Elastic 검색 여부 플래그

const isLoading = ref(false);

// interface SearchItem {
//   // channel_key: number;

//   folder_group_name: string;
//   folder_group_key: number;

//   folder_name: string;
//   folder_key: number;

//   doc_name: string;
//   file_key: number;

//   know_key: number;
//   title: string;
//   keyword_list: string[];
// }

interface SearchItem {
  // know: {
  //   kms_know_key: number;
  //   title: string;
  //   summary: string;
  //   keyword_list: string[];
  //   kms_doc_key: number;
  //   channel_key: number;
  // };
  know: {
    kms_know_key: number;
    title: string;
    summary: string;
    body: string;
    keyword_list: string[];
    quest_list: string[];
    // 독 업데이트
    // kms_doc_key: number;
    kms_folder_key: number;
    channel_key: number;
    folder_group_name?: string;
    folder_name?: string;
    doc_name?: string;
  };
  link_list: any[];
}

// Elastic 검색 결과 인터페이스
// interface ElasticSearchItem {
//   know_key: number;
//   emb_yn: number;
//   score: number;
//   doc_key: number;
//   folder_key: number;
//   body: string;
//   title?: string;
//   summary?: string;
//   keyword_list?: string[];
//   quest_list?: string[];
// }

// 독 업데이트
interface ElasticSearchItem {
  body: string;
  doc_key: number;
  emb_yn: number;
  folder_key: number;
  know: KmsKnow;
  know_key: number;
  primary_yn: number;
  score: number;
}

// 검색결과가 없을 경우
const searchResults = ref<ElasticSearchItem[]>([]);
const elasticResults = ref<ElasticSearchItem[]>([]);

const channelKey = ref(kmsStore.currentChannel?.channel_key);

// 상세 정보를 가져오는 함수 (Elastic 검색 결과에 필요)
const fetchKnowDetails = async (knowKey: number) => {
  try {
    const params: ReqKmsChannelKnow = {
      channel_key: channelKey.value,
      know_key_list_str: [knowKey],
      confirm_yn: 1,
      sub_info_yn: 1,
      // limit: 1000
      limit: 5000
    };

    const result = await getKmsKnowList2(params);
    return result.length > 0 ? result[0] : null;
  } catch (error) {
    console.error(`지식 상세 정보 가져오기 실패 (knowKey: ${knowKey}):`, error);
    return null;
  }
};
// 검색 결과를 가져오는 함수
const fetchSearchResults = async () => {
  try {
    if (!props.searchQuery?.trim()) return;

    isLoading.value = true; // 검색 시작 시 로딩 활성화

    // Elastic 검색 여부 설정
    isElasticSearch.value = props.searchOption === "elasticKnow";

    // elastic_yn 값을 설정 (elasticKnow -> 1, kmsKnow -> 0)
    const elasticYn = isElasticSearch.value ? 1 : 0;

    // Elastic 검색 API 호출
    const result = await searchKmsKnowElastic(
      channelKey.value,
      props.searchQuery,
      elasticYn // 추가된 파라미터
    );

    if (elasticYn) {
      elasticResults.value = result;
    } else {
      searchResults.value = result;
    }

    // elasticResults.value = [];
    // searchResults.value = [];

    // for (const item of result) {
    //   // 기본 정보만 있는 객체 생성
    //   const searchItem: SearchItem = {
    //     know: {
    //       kms_know_key: item.know_key,
    //       title: item.title || "제목 없음", // Elastic 결과에 title이 없을 수 있음
    //       summary: item.summary || "",
    //       body: item.body || "",
    //       keyword_list: item.keyword_list || [],
    //       quest_list: item.quest_list || [],
    //       kms_doc_key: item.doc_key,
    //       channel_key: channelKey.value
    //     },
    //     link_list: []
    //   };

    //   searchResults.value.push(searchItem);
    // }
  } catch (error) {
    console.error("검색 중 오류 발생:", error);
    searchResults.value = [];
    elasticResults.value = [];
  } finally {
    isLoading.value = false; // 검색 완료 후 로딩 해제
  }
};

// 팝업이 열릴 때도 검색 실행
watch(
  () => props.show,
  (newValue) => {
    if (newValue && props.searchQuery?.trim()) {
      fetchSearchResults();
    }
  }
);

// store의 channel_key 변경 감지
watch(
  () => kmsStore.currentChannel?.channel_key,
  (newChannelKey) => {
    if (newChannelKey) {
      channelKey.value = newChannelKey;
      // 새로운 channel_key로 검색 결과 다시 가져오기
      if (props.searchQuery?.trim()) {
        fetchSearchResults();
      }
    }
  }
);

// const handleItemClick = (item: SearchItem) => {
//   console.log("선택된 항목:", item);
//   // 실제 구현 시 여기에 항목 클릭 시 동작 추가
//   const knowItem = kmsStore.knows.find(
//     (know) => know.know.kms_know_key === item.know_key
//   );

//   if (knowItem) {
//     selectedItem.value = knowItem;
//     showViewPopup.value = true;
//   } else {
//     console.warn(
//       `지식 데이터를 찾을 수 없습니다. (know_key: ${item.know_key})`
//     );
//   }
// };

const handleItemClick = async (item: any) => {
  console.log("선택된 항목:", item);

  if (isElasticSearch.value) {
    // Elastic 검색 결과인 경우 상세 정보 가져오기
    const details = await fetchKnowDetails(item.know.kms_know_key);
    if (details) {
      selectedItem.value = details;
      showViewPopup.value = true;
    } else {
      console.warn(
        `지식 데이터를 찾을 수 없습니다. (know_key: ${item.know.kms_know_key})`
      );
    }
  } else {
    // 기존 KMS 검색 결과인 경우
    const knowItem = kmsStore.knows.find(
      (know) => know.know.kms_know_key === item.know.kms_know_key
    );

    if (knowItem) {
      selectedItem.value = knowItem;
    } else {
      selectedItem.value = item;
    }
    showViewPopup.value = true;
  }
};

const closeViewPopup = () => {
  showViewPopup.value = false;
  selectedItem.value = null;
};

const closePopup = () => {
  // 상세 정보 팝업도 함께 닫기
  showViewPopup.value = false;
  selectedItem.value = null;
  // 검색 팝업 닫기
  emit("close");
};

// const moveToKnowledge = (item) => {
//   emit("moveToKnowledge", item);
//   emit("close");
// };

// 독 업데이트
// const moveToKnowledge = async (item) => {
//   if (isElasticSearch.value) {
//     // Elastic 검색 결과인 경우 상세 정보 가져오기
//     const details = await fetchKnowDetails(item.know.kms_know_key);
//     if (details) {
//       emit("moveToKnowledge", details);
//     } else {
//       console.warn(
//         `이동할 지식 데이터를 찾을 수 없습니다. (know_key: ${item.know.kms_know_key})`
//       );
//     }
//   } else {
//     emit("moveToKnowledge", item);
//   }
//   emit("close");
// };

// KmsViewPage에 필요한 이벤트 핸들러들
const handleUpdateSummary = (summary) => {
  // if (selectedItem.value) {
  //   selectedItem.value.summary = summary;
  // }
  // KmsViewPage에서 emit된 이벤트 처리
  emit("updateSummary", selectedItem.value?.kms_know_key, summary);
};

const handleUpdateBody = (body) => {
  // if (selectedItem.value) {
  //   selectedItem.value.body = body;
  // }
  emit("updateBody", selectedItem.value?.kms_know_key, body);
};

const handleUpdateMustText = (mustText) => {
  // if (selectedItem.value) {
  //   selectedItem.value.must_text = mustText;
  // }
  emit("updateMustText", selectedItem.value?.kms_know_key, mustText);
};

const handleUpdateKeywordList = (keywordList) => {
  // if (selectedItem.value) {
  //   selectedItem.value.keyword_list = keywordList;
  // }
  emit("updateKeywordList", selectedItem.value?.kms_know_key, keywordList);
};

const handleOpenDeleteKnowPopup = () => {
  emit("openDeleteKnowPopup", selectedItem.value?.kms_know_key);
};
</script>

<!-- <template>
  <div v-if="show">
    <div class="backgroundLayout" @click="closePopup"></div>
    <div class="confimrKnowPopup">
      <div class="modal-header confirmPopHeaderWrap">
        <p style="font-weight: bold; font-size: 20px">검색 결과</p>
        <button class="close-btn" @click="closePopup">X</button>
      </div>

      <div class="search-info">
        <p>
          <span style="font-weight: bold">"{{ searchQuery }}"</span>
          검색 결과
          <span style="font-weight: bold; color: #51c2b8">
            {{ searchResults.length }} </span
          >건
        </p>
      </div>

      <div class="knowledge-list confimrKnowList">
        <div v-if="searchResults.length > 0">
          <div
            v-for="(item, index) in searchResults"
            :key="index"
            class="knowledge-box"
            @click="handleItemClick(item)"
          >
            <div class="knowWrap-header">
              <div class="search-path">>
                {{ item.know.folder_group_name || "" }} >
                {{ item.know.folder_name || "" }} >
                {{ item.know.doc_name || "" }}
              </div>
              <div class="action-buttons">
                <button class="move-to-btn" @click.stop="moveToKnowledge(item)">
                  <span>상세보기</span>
                </button>
              </div>
            </div>
            <div class="knowNavWrap">
              <div class="knowlist_title">
                <strong>{{ item.know.title }}</strong>
              </div>
            </div>
            <div class="keywords">
              <span
                v-for="(keyword, kidx) in item.know.keyword_list"
                :key="kidx"
                class="keyword"
              >
                # {{ keyword }}
              </span>
            </div>
          </div>
        </div>
        <div v-else class="no-results">
          <div class="no-results-container">
            <ChatWarning />
            <p class="no-results-message">검색어와 일치하는 내용이 없습니다.</p>
            <span>
              '입력한 검색어'에 대한 검색 결과가 없습니다.
              <span class="single-line"
                >다른 검색어를 입력하시거나 철자와 띄어쓰기를
                확인해보세요.</span
              >
            </span>
            <button class="retry-button" @click="closePopup">
              다시 검색하기
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <KmsViewPage
    v-if="showViewPopup && selectedItem"
    :knowViewItem="selectedItem"
    :folderAndGroupName="`${selectedItem?.folder_group_name} > ${selectedItem?.folder_name} > ${selectedItem?.doc_name}`"
    :show="showViewPopup"
    @close="closeViewPopup"
    @updateSummary="handleUpdateSummary"
    @updateBody="handleUpdateBody"
    @updateMustText="handleUpdateMustText"
    @updateKeywordList="handleUpdateKeywordList"
    @openDeleteKnowPopup="handleOpenDeleteKnowPopup"
  />
</template> -->

<template>
  <div v-if="show">
    <div class="backgroundLayout" @click="closePopup"></div>
    <div class="confimrKnowPopup">
      <div class="modal-header confirmPopHeaderWrap">
        <p style="font-weight: bold; font-size: 20px">검색 결과</p>
        <!-- <button class="close-btn" @click="closePopup">X</button> -->
        <div @click="closePopup" class="cursorP">
          <CloseIcon />
        </div>
      </div>

      <div class="search-info">
        <p>
          <span style="font-weight: bold">"{{ searchQuery }}"</span>
          검색 결과
          <span style="font-weight: bold; color: #51c2b8">
            {{
              isElasticSearch ? elasticResults.length : searchResults.length
            }} </span
          >건
          <span class="search-type"
            >({{
              props.searchOption === "elasticKnow"
                ? "Elastic 검색"
                : "KMS 검색"
            }})</span
          >
        </p>
      </div>

      <!-- 로딩 중일 때 표시 -->
      <div v-if="isLoading" class="loading-container">
        <!-- <div class="loading-container"> -->
        <div class="spinner"></div>
        <p>검색 중입니다...</p>
      </div>

      <div v-else class="knowledge-list confimrKnowList">
        <!-- Elastic 검색 결과 -->
        <div
          v-if="
            props.searchOption === 'elasticKnow' && elasticResults.length > 0
          "
        >
          <div
            v-for="(item, index) in elasticResults"
            :key="index"
            class="knowledge-box elastic-result"
            @click="handleItemClick({ know: { kms_know_key: item.know_key } })"
          >
            <div class="knowWrap-header">
              <div class="search-path">
                <span class="score-badge"
                  >정확도: {{ (item.score * 10).toFixed(1) }}%</span
                >
              </div>
              <div class="action-buttons">
                <!-- <button
                  class="move-to-btn"
                  @click.stop="
                    moveToKnowledge({ know: { kms_know_key: item.know_key } })
                  "
                >
                  <span>상세보기</span>
                </button> -->
              </div>
            </div>
            <div class="knowNavWrap">
              <div class="knowlist_title">
                <!-- <strong>{{ item.title || "제목 없음" }}</strong> -->
                <span class="know-key">지식 : {{ item.know_key }}</span>
                <!-- 독 업데이트 -->
                <!-- <span class="know-key">문서 : {{ item.doc_key }}</span> -->
              </div>
            </div>
            <div class="elastic-body">
              <p>{{ item.body }}</p>
            </div>
          </div>
        </div>

        <!-- 기존 KMS 검색 결과 -->
        <div v-else-if="searchResults.length > 0">
          <div
            v-for="(item, index) in searchResults"
            :key="index"
            class="knowledge-box"
            @click="handleItemClick(item)"
          >
            <div class="knowWrap-header">
              <!-- <div class="search-path">
                {{ item.know.folder_group_name || "" }} >
                {{ item.know.folder_name || "" }} >
                {{ item.know.doc_name || "" }}
              </div> -->
              <!-- <div class="action-buttons">
                <button class="move-to-btn" @click.stop="moveToKnowledge(item)">
                  <span>상세보기</span>
                </button>
              </div> -->
            </div>
            <div class="knowNavWrap">
              <div class="knowlist_title">
                <strong>{{ item.know.title }}</strong>
                <span class="know-key">{{ item.know.kms_know_key }}</span>
              </div>
            </div>
            <div class="summary" v-if="item.know.summary">
              <p>{{ item.know.summary }}</p>
            </div>
            <div class="summary" v-if="item.know.body">
              <p>{{ item.know.body }}</p>
            </div>
            <div
              class="keywords"
              v-if="item.know.keyword_list && item.know.keyword_list.length > 0"
            >
              <span
                v-for="(keyword, kidx) in item.know.keyword_list"
                :key="kidx"
                class="keyword"
              >
                # {{ keyword }}
              </span>
            </div>
            <div
              class="quest-list"
              v-if="item.know.quest_list && item.know.quest_list.length > 0"
            >
              <span
                v-for="(quest, kidx) in item.know.quest_list"
                :key="kidx"
                class="quest-item"
              >
                {{ quest }}
              </span>
            </div>
          </div>
        </div>

        <!-- 검색 결과 없을 경우 -->
        <div v-else class="no-results">
          <div class="no-results-container">
            <ChatWarning />
            <p class="no-results-message">검색어와 일치하는 내용이 없습니다.</p>
            <span>
              '{{ searchQuery }}'에 대한 검색 결과가 없습니다.
              <span class="single-line"
                >다른 검색어를 입력하시거나 철자와 띄어쓰기를
                확인해보세요.</span
              >
            </span>
            <button class="retry-button" @click="closePopup">
              다시 검색하기
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- 독 업데이트 -->
    <KmsSearchViewPage
      v-if="showViewPopup && selectedItem"
      :knowViewItem="selectedItem"
      :folderAndGroupName="`${selectedItem?.know?.folder_group_name || ''} > ${selectedItem?.know?.folder_name || ''} > ${selectedItem?.know?.doc_name || ''}`"
      :show="showViewPopup"
      @close="closeViewPopup"
      @updateSummary="handleUpdateSummary"
      @updateBody="handleUpdateBody"
      @updateMustText="handleUpdateMustText"
      @updateKeywordList="handleUpdateKeywordList"
      @openDeleteKnowPopup="handleOpenDeleteKnowPopup"
    />
  </div>
</template>

<style scoped>
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  text-align: center;
  width: 100%;
  margin-top: 200px;
  gap: 10px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #51c2b8;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.backgroundLayout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: black;
  opacity: 0.4;
}
/*
.confimrKnowPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50%;
  max-width: 600px;
  height: 90%;
  max-height: 800px;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 200;
  display: flex;
  flex-direction: column;
  gap: 20px;
} */

.confirmPopHeaderWrap {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  padding: 0px 0px 10px 0px;
}

.confirmPopHeaderWrap h5 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.search-info {
  /* padding: 10px 0; */
  /* font-weight: bold; */
  color: #333;
}

.confimrKnowList {
  min-height: 70%;
  height: 100%;
  overflow-y: auto;
}

.knowledge-box {
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  margin-bottom: 15px;
  cursor: pointer;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
}

.search-path {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 10px;
}

.knowlist_title {
  font-size: 1.1em;
  margin: 0px 0px 10px 0px;
}

.keywords {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.keyword {
  background-color: #f5f5f5;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  color: #666;
}

.no-results {
  text-align: center;
  padding: 40px 0;
  color: #666;
}

/* Quest 리스트 영역 */
.quest-list {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin: 8px 0 0px 0;
  max-height: 2.5em;
  overflow: hidden;
}

.quest-item {
  padding: 4px 8px;
  background: white; /* 다른 배경색 */
  border-radius: 13px;
  border: 0.5px solid #7c7c7c;
  font-size: 14px;
  color: #3a3a3a; /* 다른 텍스트 색상 */
}

.quest-item::before {
  content: "?";
  display: inline-block;
  font-weight: bold;
  margin-right: 6px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background-color: #9b9b9b;
  color: white;
  border-radius: 50%;
  font-size: 0.8em;
}

/* .close-btn {
  border: none;
  background: none;
  font-size: 18px;
  cursor: pointer;
  padding: 5px;
} */

.action-buttons {
  display: flex;
  justify-content: flex-end;
  /* margin-top: 10px; */
  /* padding-top: 10px; */
  /* border-top: 1px solid #eee; */
}

.move-to-btn {
  /* width: 20px; */
  padding: 0px 5px;
  border: 1px solid;
  border-color: rgb(204, 204, 204);
  background-color: white;
  color: rgb(72, 72, 72);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 10px !important;
  transition: all 0.2s ease;
}

.move-to-btn:hover {
  background-color: rgb(160, 160, 160);
  color: rgb(24, 24, 24);
}

.knowWrap-header {
  display: flex;
  justify-content: space-between;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 16px 0;
  border-bottom: 1px solid #ddd;
}

.no-results-container {
  text-align: center;
  padding: 20px;
  color: #333;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.no-results-container p {
  line-height: 2.5;
}

.no-results-message {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #333;
}

.no-results-tips {
  text-align: left;
  list-style-type: disc;
  margin: 0 auto 20px auto;
  padding: 0 20px;
  color: #666;
  font-size: 14px;
}

.no-results-tips li {
  margin-bottom: 8px;
}

.retry-button {
  padding: 10px 20px;
  margin-top: 14px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background-color: #51c2b8;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.retry-button:hover {
  background-color: #429f91;
}

.single-line {
  display: block; /* 텍스트를 새로운 줄로 내려줌 */
  /* margin-top: 4px; 원하는 간격을 추가 */
}

.search-type {
  font-size: 0.9em;
  color: #666;
  margin-left: 5px;
}

.score-badge {
  display: inline-block;
  padding: 2px 8px;
  background-color: #51c2b8;
  color: white;
  border-radius: 12px;
  font-size: 0.8em;
  margin-right: 8px;
}

.elastic-result {
  border-left: 4px solid #51c2b8;
}

.elastic-body {
  margin: 10px 0;
  color: #333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  max-height: 4.5em;
}

.know-key {
  font-size: 0.75em;
  color: #888;
  font-weight: normal;
  margin-left: 5px;
}

.backgroundLayout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: black;
  opacity: 0.4;
}

.confimrKnowPopup {
  position: fixed;
  top: 50%;
  /* left: 50%; */
  left: 20%;
  /* width: 50%; */
  width: 45%;
  max-width: 600px;
  height: 90%;
  max-height: 850px;
  /* transform: translate(-50%, -50%); */
  transform: translate(-20%, -50%);
  padding: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 200;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.confirmPopHeaderWrap {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  padding: 0px 0px 10px 0px;
}

.confirmPopHeaderWrap h5 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.search-info {
  color: #333;
}

.confimrKnowList {
  min-height: 70%;
  height: 100%;
  overflow-y: auto;
}

.knowledge-box {
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  margin-bottom: 15px;
  cursor: pointer;
}

.knowledge-box:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.search-path {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 10px;
}

.knowlist_title {
  font-size: 1.1em;
  margin: 0px 0px 10px 0px;
}

.summary {
  color: #353535;
  margin: 12px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  max-height: 4.5em;
}

.keywords {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.keyword {
  background-color: #f5f5f5;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  color: #666;
}

.no-results {
  text-align: center;
  padding: 40px 0;
  color: #666;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
}

.move-to-btn {
  padding: 0px 5px;
  border: 1px solid;
  border-color: rgb(204, 204, 204);
  background-color: white;
  color: rgb(72, 72, 72);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 10px !important;
  transition: all 0.2s ease;
}

.move-to-btn:hover {
  background-color: rgb(160, 160, 160);
  color: rgb(24, 24, 24);
}

.knowWrap-header {
  display: flex;
  justify-content: space-between;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 16px 0;
  border-bottom: 1px solid #ddd;
}

.no-results-container {
  text-align: center;
  padding: 20px;
  color: #333;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.no-results-container p {
  line-height: 2.5;
}

.no-results-message {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #333;
}

.retry-button {
  padding: 10px 20px;
  margin-top: 14px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background-color: #51c2b8;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.retry-button:hover {
  background-color: #429f91;
}

.single-line {
  display: block;
  margin-top: 4px;
}
</style>
