<script setup lang="ts">
import { computed } from "vue";
import MarkdownIt from "markdown-it";
import DOMPurify from "dompurify";

const props = defineProps<{
  source: string;
}>();

const md = new MarkdownIt();

const sanitizedHtml = computed(() => {
  const rawHtml = md.render(props.source);
  return DOMPurify.sanitize(rawHtml);
});
</script>

<template>
  <div v-html="sanitizedHtml"></div>
</template>
