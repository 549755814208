import Cookies from "js-cookie";

export type CookieAttributes = Parameters<typeof Cookies.set>[2];

export function setCookie<T extends object>(
  cookieValue: Partial<T>,
  options?: CookieAttributes
) {
  const mergedOptions: CookieAttributes = {
    path: "",
    domain: ".anytalk.com",
    sameSite: "None",
    secure: true,
    ...(options ?? {})
  };

  for (const [key, value] of Object.entries(cookieValue)) {
    Cookies.set(key, JSON.stringify(value), mergedOptions);
  }
}

export function getCookie<T extends object>(...cookieKeys: Array<keyof T>) {
  return cookieKeys.reduce(
    (result, key) => {
      const cookieValue = Cookies.get(key as string);
      if (cookieValue !== undefined) {
        result[key] = cookieValue;
      }
      return result;
    },
    {} as Partial<Record<keyof T, string>>
  );
}
