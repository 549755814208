<!-- userCardPop -->
<script setup>
import { ref, watch, defineProps, onMounted } from "vue";
import commonPopLayer from "../../../../popups/CommonPopLayer.vue";
import { useQuasar } from "quasar";
import { useStore } from "../../../../stores/store";
import { elapsedYear } from "../../../../utils/date";
import {
  joinChannel,
  getAuthCardList,
  updateChannelAuthUser
} from "../../../../api/backend";

const store = useStore();
const $q = useQuasar();

// ✅ props 정의
const props = defineProps({
  pSelectedUserInfo: {
    type: Object,
    default: () => ({}) // ✅ null 방지
  },
  pClosePop: Function,
  authCards: {
    type: Array,
    default: () => []
  },
  pPopTitle: {
    type: String,
    default: "멤버 카드 수정"
  },
  getChannelUserList: {
    type: Function,
    required: false,
    default: () => {}
  }
});

// ✅ 선택된 권한 값들 (초기값을 props에서 가져옴)
const selectedAuth = ref("");
const selectedAuthCard = ref("");
const selectedAuthCardName = ref("");

// ✅ 권한 카드 리스트 (초기값: props.authCards)
const authCards = ref([...props.authCards]);

// ✅ props 값이 변경될 때 선택된 값을 업데이트하는 함수
const updateSelectedValues = () => {
  selectedAuth.value = props.pSelectedUserInfo?.auth || "";
  selectedAuth.value = props.pSelectedUserInfo?.auth_type || "";
  selectedAuthCard.value = props.pSelectedUserInfo?.authcard_key || "";
  selectedAuthCardName.value = props.pSelectedUserInfo?.authcard_name || "";
};

const commonPopInfo = ref({
  title: props.pPopTitle
});

// ✅ `onMounted` 시 props의 초기 값 적용
onMounted(() => {
  updateSelectedValues();
});

// props.pPopTitle이 변경될 때 commonPopInfo.title 업데이트
watch(
  () => props.pPopTitle,
  (newTitle) => {
    commonPopInfo.value.title = newTitle;
  }
);

// ✅ `watch`를 활용하여 props가 변경될 때 반영
// watch(
//   () => props.pSelectedUserInfo,
//   () => {
//     updateSelectedValues();
//   },
//   { deep: true, immediate: true }
// );
watch(
  () => props.pSelectedUserInfo,
  (newUserInfo) => {
    if (newUserInfo) {
      selectedAuth.value = newUserInfo.auth_type || "";
      selectedAuthCard.value = newUserInfo.authcard_key || "";
      selectedAuthCardName.value = newUserInfo.authcard_name || "";
    }
  },
  { deep: true, immediate: true }
);

// ✅ 권한 카드 선택 시 `authcard_name`도 함께 설정
// watch(selectedAuthCard, (newAuthCardKey) => {
//   const selectedCard = authCards.value.find(
//     (card) => card.authcard_key === newAuthCardKey
//   );
//   selectedAuthCardName.value = selectedCard ? selectedCard.authcard_name : "";
// });

watch(
  () => props.authCards,
  (newAuthCards) => {
    authCards.value = [
      ...newAuthCards,
      ...authCards.value.filter(
        (existingCard) =>
          !newAuthCards.some(
            (newCard) => newCard.authcard_key === existingCard.authcard_key
          )
      )
    ];
    console.log(
      "🔄 props.authCards 변경 감지 → authCards 업데이트:",
      authCards.value
    );
  },
  { deep: true, immediate: true }
);

onMounted(async () => {
  const param = {
    subinfo_yn: 0,
    authcard_key: null,
    channel_key: store.currentChannel?.channel_key || 0,
    folder_key_list_str: ""
  };

  try {
    const authCardList = await getAuthCardList(param);
    console.log("🚀 API 응답 데이터:", authCardList);

    if (authCardList.length > 0) {
      const fetchedAuthCards = authCardList.map((item) => ({
        authcard_name: item.authcard.authcard_name,
        authcard_key: item.authcard.authcard_key,
        channel_key: item.authcard.channel_key,
        cre_date: item.authcard.cre_date
      }));

      // ✅ 기존 props.authCards + API에서 가져온 권한 카드 통합 (중복 제거)
      authCards.value = [
        ...props.authCards,
        ...fetchedAuthCards.filter(
          (newCard) =>
            !props.authCards.some(
              (existingCard) =>
                existingCard.authcard_key === newCard.authcard_key
            )
        )
      ];

      console.log("✅ 최종 authCards 리스트:", authCards.value);
    } else {
      console.log("📢 가져온 권한 카드가 없습니다.");
    }
  } catch (error) {
    console.error("카드 목록 가져오기 실패:", error);
  }
});

watch(selectedAuth, (newValue) => {
  if (props.pSelectedUserInfo) {
    props.pSelectedUserInfo.auth_type = newValue;
  }
});

watch(selectedAuthCard, (newAuthCardKey) => {
  if (props.pSelectedUserInfo) {
    props.pSelectedUserInfo.authcard_key = newAuthCardKey;
    const selectedCard = authCards.value.find(
      (card) => card.authcard_key === newAuthCardKey
    );
    selectedAuthCardName.value = selectedCard ? selectedCard.authcard_name : "";
    props.pSelectedUserInfo.authcard_name = selectedAuthCardName.value;
  }
});

// ✅ 권한 변경 API 호출
// const updateUserAuth = async () => {
//   try {
//     const payload = {
//       auth_user: {
//         user_key: props.pSelectedUserInfo?.user_key,
//         channel_key: store.currentChannel?.channel_key || 0,
//         auth_type: selectedAuth.value,
//         grant_user_key: 0,
//         grant_date: new Date().toISOString(),
//         authcard_key: selectedAuthCard.value ? selectedAuthCard.value : 0
//       },
//       secure_str4: store.currentChannel?.secure_str4 || ""
//     };

//     console.log("🚀 API 요청 payload:", JSON.stringify(payload, null, 2));

//     const response = await joinChannel(payload);

//     if (typeof response === "number" && response > 0) {
//       // alert(`권한이 변경되었습니다. (auth_user_key: ${response})`);

//       // ✅ 변경된 값 부모 컴포넌트에 즉시 반영
//       props.pSelectedUserInfo.auth = selectedAuth.value;
//       props.pSelectedUserInfo.authcard_key = selectedAuthCard.value
//         ? selectedAuthCard.value
//         : 0;
//       props.pSelectedUserInfo.authcard_name = selectedAuthCardName.value;

//       isEditMode.value = false;
//       $q.notify({
//         message: "수정 성공하였습니다.",
//         color: "positive"
//       });
//     } else {
//       $q.notify({
//         message: "수정 실패하였습니다.",
//         color: "negative"
//       });
//     }
//   } catch (error) {
//     console.error("권한 변경 중 오류 발생:", error);
//     $q.notify({
//       message: "수정 실패하였습니다.",
//       color: "negative"
//     });
//   }
// };
const updateAuthUser = async () => {
  try {
    const payload = {
      auth_user_key: props.pSelectedUserInfo?.auth_user_key,
      user_key: props.pSelectedUserInfo?.user_key,
      channel_key: store.currentChannel?.channel_key || 0,
      auth_type: selectedAuth.value,
      authcard_key: selectedAuthCard.value ? selectedAuthCard.value : 0
    };
    const response = await updateChannelAuthUser(payload);

    if (response == null) {
      $q.notify({
        message: "권한이 변경되었습니다.",
        color: "positive",
        timeout: 3000
      });
      // props.pSelectedUserInfo.auth = selectedAuth.value;
      // props.pSelectedUserInfo.authcard_key = selectedAuthCard.value;
      // props.pSelectedUserInfo.authcard_name = selectedAuthCardName.value;
    } else {
      $q.notify({
        message: "권한 변경에 실패하였습니다.",
        color: "negative",
        timeout: 3000
      });
    }
  } catch (error) {
    $q.notify({
      message: "권한 변경 중 오류가 발생했습니다.",
      color: "negative",
      timeout: 3000
    });
  }
  props.pClosePop();
  await props.getChannelUserList();
};

// ✅ 편집 모드 상태
const isEditMode = ref(false);

// ✅ 편집 모드 토글
const toggleEditMode = () => {
  isEditMode.value = !isEditMode.value;
};
</script>

<template>
  <commonPopLayer :pPopTitle="commonPopInfo.title" class="user-card-popup">
    <template #body>
      <div class="user-header">
        <div class="user-info">
          <h3 class="user-name">
            <!-- {{ props.pSelectedUserInfo?.name || "알 수 없음" }} -->
            {{ props.pSelectedUserInfo.nick || props.pSelectedUserInfo.name }}
            <span class="user-email"
              >(ID:{{
                props.pSelectedUserInfo?.user_id || "아이디 없음"
              }})</span
            >
          </h3>
        </div>
      </div>

      <div class="divider"></div>

      <div class="channelDetailTop flexAlign">
        <div class="channelDetailInfos">
          <div class="inputWrapBox">
            <div class="inputWrap">
              <p class="fontBold">학번</p>
              <span class="info-text">
                {{ props.pSelectedUserInfo?.student_id || "-" }}
              </span>
            </div>
            <div class="inputWrap">
              <p class="fontBold">이메일</p>
              <span class="info-text">
                {{ props.pSelectedUserInfo?.email || "-" }}
              </span>
            </div>
            <div class="inputWrap">
              <p class="fontBold">가입일</p>
              <span class="info-text">
                {{
                  props.pSelectedUserInfo?.joinDate
                    ? elapsedYear(props.pSelectedUserInfo?.joinDate)
                    : "-"
                }}
              </span>
            </div>
            <div class="inputWrap">
              <p class="fontBold">휴대폰번호</p>
              <span class="info-text">
                {{ props.pSelectedUserInfo?.phone }}
              </span>
            </div>
            <div class="inputWrap">
              <p class="fontBold">채널 권한</p>
              <select v-model="selectedAuth" class="inputSelect">
                <option value="">선택 없음</option>
                <option value="A">관리자</option>
                <option value="E">전문가</option>
                <option value="M">직원</option>
                <option value="S">구독자</option>
              </select>
            </div>
            <div class="inputWrap">
              <p class="fontBold">권한 카드</p>
              <select v-model="selectedAuthCard" class="inputSelect">
                <option value="">선택 없음</option>
                <option
                  v-for="card in authCards"
                  :key="card.authcard_key"
                  :value="card.authcard_key"
                >
                  {{ card.authcard_name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #actions>
      <div class="popup-footer">
        <button type="button" class="submit-btn" @click="updateAuthUser">
          저장
        </button>
        <button type="button" class="cancel-btn" @click="pClosePop">
          닫기
        </button>
      </div>
    </template>
  </commonPopLayer>
</template>

<style lang="scss" scoped>
.user-card-popup {
  :deep(.commonPopWrap) {
    max-width: 400px;
  }
}

.user-header {
  padding-left: 10px;
  margin-top: -15px;

  .user-info {
    .user-name {
      font-size: 1.25rem;
      font-weight: 600;
      margin: 0;
      display: flex;
      align-items: center;
      gap: 10px;

      .user-email {
        font-size: 0.875rem;
        font-weight: normal;
        color: #666;
      }
    }
  }
}

.divider {
  height: 1px;
  background-color: #eee;
  margin: 5px 0 10px 0;
}

.channelDetailTop {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.channelDetailInfos {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inputWrapBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inputWrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 12px;

  p {
    font-size: 0.875rem;
    font-weight: 500;
    color: #333;
  }

  .info-text {
    width: 60%;
    padding: 0 12px;
    font-size: 0.875rem;
    color: #1a202c;
  }
}

.inputSelect {
  margin-right: 20px;
  width: 55%;
  height: 36px;
  padding: 0 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background-color: #fff;
  font-size: 0.875rem;
  color: #1a202c;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
  }

  &:hover {
    border-color: #cbd5e0;
  }
}

.popup-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 12px;
}

.popup-footer button {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.submit-btn {
  background-color: #51c2b8;
  color: white;
  font-weight: 600;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.submit-btn:hover {
  background-color: #18a498;
}

.cancel-btn {
  background-color: #f5f5f5;
  font-weight: 600;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: #ddd;
}
</style>
