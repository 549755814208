// store.ts

import { defineStore } from "pinia";
import { Ref, computed, onMounted, ref, watch } from "vue";
import {
  getKnowList,
  getMsgList,
  getRoomList,
  type AuthChannel,
  type ResKnowCheck,
  type RoomInfo,
  deleteMsg,
  getMsgCheckList,
  getChannelList,
  getKnowCheckList,
  getKmsFolderGroupList,
  getKmsRepoImgFolderGroupList,
  getKmsRepoLinkFolderGroupList,
  getKmsFolderList,
  // getKmsDocList,
  createKmsFolder,
  deleteKmsFolder,
  // deleteKmsDoc,
  updateKmsFolder,
  createRepoFile,
  getKmsKnowConfirmList,
  // updateKmsDoc,
  getKmsKnowList2,
  ReqKmsChannelKnow,
  Credentials,
  loginIdPw
} from "../api/backend";
import type {
  Channel,
  CookieInfo,
  Know,
  KnowOrg,
  Msg,
  MsgCheck,
  User,
  KmsFolderGroup,
  KmsFolder,
  FolderTreeNode,
  // 독 업데이트
  // KmsDoc,
  KmsKnow,
  ResKmsKnow
} from "../api/types";
import { setCookie } from "../utils/cookie";
import { useTokenStore } from "./token";
import { createFakeKey } from "../utils/hooks";
import { createDate } from "../utils/date";
import { requestForToken } from "../configs/firebase";
// import { kmsKnows } from "../mokUpData/atKmsMockData";
import { useRoute, useRouter } from "vue-router";

export const useStore = defineStore("backend_store", () => {
  const tokenStore = useTokenStore();

  // == Channel ================================================================
  // const allChannelList
  // const ANYTALK_CHANNEL_KEY = 85;
  const ANYTALK_CHANNEL_KEY = 85; //남서울대학교
  const SAEUM_CHANNEL_KEY = 86;

  const forceSelectChannelMode = ref(false);
  const publicChannelKey = ref(ANYTALK_CHANNEL_KEY);
  const publicChannelList = ref<AuthChannel[]>([]);
  const allChannelList = ref<AuthChannel[]>([]);
  const myChannelList = ref<AuthChannel[]>([]);

  // 채널 추가 다이얼로그 표시 여부를 위한 상태 추가
  const showChannelAddDialog = ref(false);

  // 채널 추가 다이얼로그를 열고 닫는 함수
  const openChannelAddDialog = () => {
    showChannelAddDialog.value = true;
  };

  const closeChannelAddDialog = () => {
    showChannelAddDialog.value = false;
  };
  // ==============================================================================

  const isAuthenticated = ref(false); // 로그인 상태
  const userInfo = ref(null);
  const jwtToken = ref("");

  const autoLoginAndMoveToChannel = async (credentials: Credentials) => {
    try {
      console.log("🔹 자동 로그인 시작");

      const response = await loginIdPw(credentials);
      if (!response || !response.access_token) {
        throw new Error("❌ 로그인 실패!");
      }

      // 1️⃣ 로그인 정보 저장
      jwtToken.value = response.access_token;
      userInfo.value = response.user;
      isAuthenticated.value = true;

      // 2️⃣ 로컬 스토리지 저장
      localStorage.setItem("anytalk_user_info", JSON.stringify(response.user));
      localStorage.setItem("anytalk_jwt_token", response.access_token);

      console.log("✅ 자동 로그인 성공:", response.user);
      return response.user;
    } catch (error) {
      console.error("❌ 자동 로그인 실패:", error);
      return null;
    }
  };

  // ==============================================================================
  // ✅ 특정 채널에서 내 개인방이 있는지 확인하는 함수 추가
  const hasPrivateRoom = (channel_key: number, user_key: number): boolean => {
    const rooms = roomMap.value[channel_key] || [];

    // 내 user_key를 포함하는 개인방이 있는지 확인
    return rooms.some((room) => room.user_key === user_key);
  };

  const updateChannelAuth = (index: number) => {
    const authType = allChannelList.value[index].auth_type;

    if (authType === "") {
      allChannelList.value[index].auth_type = "S";
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      allChannelList.value[index].auth_type = [authType, "S"].join();
    }
    updateRoom(allChannelList.value[index].channel_key, myInfo.value?.user_key);
  };

  const deleteFollowChannelAuth = (index: number) => {
    const authType = allChannelList.value[index].auth_type;

    if (authType?.includes(", S")) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      allChannelList.value[index].auth_type = authType
        .replace(", S", "")
        .trim();
    } else if (authType?.includes("S")) {
      allChannelList.value[index].auth_type = "";
    }
    updateRoom(allChannelList.value[index].channel_key, myInfo.value?.user_key);
  };

  const TAB_LIST = {
    MY_CHANNEL: 0,
    ALL_CHANNEL: 1
  };
  // const channelTabIndex = ref(TAB_LIST.ALL_CHANNEL);
  const channelTabIndex = ref(TAB_LIST.MY_CHANNEL);
  const currentChannel = ref<AuthChannel>();
  const selectedChannel = ref<AuthChannel>();

  const allChannelSelected = computed(() =>
    allChannelList.value.findIndex(
      (channel) => channel.channel_key === currentChannel.value?.channel_key
    )
  );
  const channelSelected = computed(() =>
    allChannelList.value.findIndex(
      (channel) => channel.channel_key === selectedChannel.value?.channel_key
    )
  );
  const myChannelSelected = computed(() =>
    myChannelList.value.findIndex(
      (channel) => channel.channel_key === currentChannel.value?.channel_key
    )
  );

  const updateAllChannelTabSelected = (selected: string) => {
    const index = Number(selected);
    currentChannel.value = allChannelList.value[index];
  };
  const updateMyChannelTabSelected = (selected: string) => {
    const index = Number(selected);
    currentChannel.value = myChannelList.value[index];
  };
  const updateSelectedChannelBold = (selected: string) => {
    const index = Number(selected);
    selectedChannel.value = allChannelList.value[index];
  };
  const selectedChannelBold = computed({
    get: () => `${channelSelected.value}`,
    set: updateSelectedChannelBold
  });
  const allChannelTabSelected = computed({
    get: () => `${allChannelSelected.value}`,
    set: updateAllChannelTabSelected
  });
  // const myChannelTabSelected = computed({
  //   get: () => `${myChannelSelected.value}`,
  //   set: updateMyChannelTabSelected
  // });
  const myChannelTabSelected = computed({
    get: () => currentChannel.value?.channel_key || "",
    set: (channelKey) => {
      currentChannel.value = allChannelList.value.find(
        (channel) => `${channel.channel_key}` === channelKey
      );
    }
  });

  const channelQuestionMap = ref<Record<Channel["channel_key"], MsgCheck[]>>(
    {}
  );
  const channelQuestionList = computed(() => {
    if (currentChannel.value) {
      return channelQuestionMap.value[currentChannel.value.channel_key] ?? [];
    }
    return [];
  });
  const separatedQuestions = computed(() =>
    channelQuestionList.value.reduce(
      (acc, msgCheck) => {
        switch (msgCheck.status) {
          case "I":
            acc.active.push(msgCheck);
            break;
          case "O":
            acc.answered.push(msgCheck);
            break;
          case "R":
            acc.rejected.push(msgCheck);
        }
        return acc;
      },
      {
        active: [] as MsgCheck[],
        answered: [] as MsgCheck[],
        rejected: [] as MsgCheck[]
      }
    )
  );
  const activeQuestionList = computed(() => separatedQuestions.value.active);
  const answeredQuestionList = computed(
    () => separatedQuestions.value.answered
  );
  const rejectedQuestionList = computed(
    () => separatedQuestions.value.rejected
  );

  const updateAnsweredQuestion = (
    msgCheckKey: number,
    answer: string,
    status: KnowOrg["status"]
  ) => {
    if (currentChannel.value?.channel_key) {
      const index = channelQuestionMap.value[
        currentChannel.value.channel_key
      ].findIndex((msgCheck) => msgCheck.msg_check_key === msgCheckKey);

      if (index !== -1 && myInfo.value) {
        const msgCheck =
          channelQuestionMap.value[currentChannel.value.channel_key][index];
        msgCheck.status = status;
        msgCheck.msg_2 = {
          msg_key: createFakeKey(),
          room_key: msgCheck.question.room_key,
          parent_key: msgCheck.question.parent_key,
          body: answer,
          cre_user_key: myInfo.value.user_key,
          cre_date: new Date().toISOString(),
          delete_yn: 0
        };
        msgCheck.msg_2_user = myInfo.value;

        channelQuestionMap.value[currentChannel.value.channel_key].splice(
          index,
          1
        );
        channelQuestionMap.value[currentChannel.value.channel_key].unshift(
          msgCheck
        );
      }
    }
  };

  // == Knowledge ==============================================================
  const knowledgeMap = ref<Record<Channel["channel_key"], Know[]>>({});
  const knowledgeList = computed(() => {
    if (currentChannel.value !== undefined) {
      console.log("currentChannel", currentChannel.value);
      return (
        knowledgeMap.value[currentChannel.value.channel_key]?.sort(
          (know1, know2) => {
            return (
              new Date(know2.cre_date).getTime() -
              new Date(know1.cre_date).getTime()
            );
          }
        ) ?? []
      );
    }
    return [];
  });

  const knowCheckMap = ref<Record<Channel["channel_key"], ResKnowCheck[]>>({});
  const knowCheckList = computed(() => {
    if (currentChannel.value?.channel_key !== undefined) {
      return (
        knowCheckMap.value[currentChannel.value.channel_key]?.sort((a, b) => {
          return (
            new Date(b.cre_date).getTime() - new Date(a.cre_date).getTime()
          );
        }) ?? []
      );
    }
    return [];
  });

  const activeKnowCheckList = computed(() => {
    return knowCheckList.value.filter((item) => item.know?.confirm_yn === 0);
  });

  const answeredKnowCheckList = computed(() => {
    return knowCheckList.value.filter((item) => item.know?.confirm_yn === 1);
  });

  const rejectedKnowCheckList = computed(() => {
    return knowCheckList.value.filter((item) => item.know?.confirm_yn === 2);
  });

  const updateToKnowOrgList = (
    know_check_key: number,
    status: ResKnowCheck["status"]
  ) => {
    if (currentChannel.value?.channel_key) {
      const index = knowCheckList.value.findIndex(
        (knowOrg) => knowOrg.know_check_key === know_check_key
      );

      if (index !== -1) {
        const knowOrg = knowCheckList.value[index];
        knowOrg.status = status;

        knowCheckMap.value[currentChannel.value?.channel_key].splice(index, 1);
        knowCheckMap.value[currentChannel.value?.channel_key].unshift(knowOrg);

        return knowOrg;
      }
    }
  };
  const updatedToAnsweredKnowOrg = (know_check_key: number) => {
    const result = updateToKnowOrgList(know_check_key, "O");

    if (result !== undefined && currentChannel.value?.channel_key) {
      knowledgeMap.value[currentChannel.value?.channel_key].unshift({
        know_key: createFakeKey(),
        lc_know_id: result.know_check_key.toString(),
        know_body: result.body,
        channel_key: currentChannel.value.channel_key,
        know_check_key: result.know_check_key,
        link_key_list: null,
        keyword_key_list: null,
        cre_user_key: myInfo.value?.user_key ?? 0,
        cre_date: createDate(),
        delete_yn: 0,

        know_check: result,
        user: myInfo.value!
      });
    }
  };
  const updatedToRejectedKnowOrg = (know_check_key: number) => {
    updateToKnowOrgList(know_check_key, "R");
  };

  // == Msg 새롭게 변경된 ===================================================================
  // 질문, 답변 메시지 set
  interface SetMsg {
    req?: Msg; // ✅ 질문 (선택적)
    res?: Msg; // ✅ 답변 (선택적)
    ai_res_yn?: number; // AI 여부 (1: AI 답변, 0: 전문가 답변)
  }

  // 날짜별 메시지 목록
  interface DayMsg {
    day: string; // 날짜 (YYYY-MM-DD)
    s_msg_list?: SetMsg[];
  }

  // 새롭게 변경된 메시지 리스트
  const newMessageMap = ref<Record<number, DayMsg[]>>({}); // room_key별 메시지 저장

  // 새롭게 변경된 메시지 리스트 호출 함수
  const processChatMessages = async (
    roomKey: number,
    msgList: Msg[]
  ): Promise<void> => {
    const dMsgList: DayMsg[] = [];
    let prevDate: string | null = null;
    let prevM: Msg | null = null;
    let prevS: SetMsg | null = null;
    let s: SetMsg | null = null;
    let d: DayMsg | null = null;

    // ✅ 이미 받은 `msgList`를 사용 (roomKey를 직접 getMsgList로 호출하지 않음)
    const sortedMsgList = [...msgList].reverse(); // 최신 메시지를 먼저 가져와서 순서 변경

    for (const m of sortedMsgList) {
      if (!m || !m.cre_date) continue;

      const currentDate = new Date(m.cre_date).toISOString().split("T")[0]; // YYYY-MM-DD 형식 변환

      if (!prevDate || prevDate !== currentDate) {
        prevDate = currentDate;
        d = { day: currentDate, s_msg_list: [] };
        if (!dMsgList.some((dayMsg) => dayMsg.day === currentDate)) {
          dMsgList.push(d);
        }
      }

      if (!d) continue;
      if (!d.s_msg_list) d.s_msg_list = [];

      if (m.parent_key === 0) {
        // ✅ 질문
        if (prevS && !d.s_msg_list.includes(prevS)) {
          d.s_msg_list.push(prevS);
        }
        s = { req: m, res: undefined, ai_res_yn: 0 };
      } else {
        // ✅ 답변
        if (prevM && prevM.msg_key !== m.parent_key) {
          if (prevS && !d.s_msg_list.includes(prevS)) {
            d.s_msg_list.push(prevS);
            s = null;
          }
        }
        if (!s || !s.req) {
          // ✅ 부모 질문이 없는 경우 새로운 SetMsg 생성
          const parentMsg = sortedMsgList.find(
            (msg) => msg.msg_key === m.parent_key
          );
          s = {
            req: parentMsg || undefined,
            res: m,
            ai_res_yn: m.cre_user_key === 0 ? 1 : 0
          };
          d.s_msg_list.push(s);
          s = null;
        } else {
          // ✅ 부모 질문이 존재하면 같은 SetMsg에 답변 추가
          s.res = m;
          s.ai_res_yn = m.cre_user_key === 0 ? 1 : 0;
          d.s_msg_list.push(s);
          s = null;
        }
      }

      prevS = s;
      prevM = m;
    }

    // ✅ 마지막 남은 메시지 추가
    if (s) {
      d?.s_msg_list.push(s);
    }

    // ✅ newMessageMap에 roomKey 기준으로 저장
    newMessageMap.value[roomKey] = dMsgList;
  };

  // == Msg ======================================================================
  const messageMap = ref<Record<RoomInfo["room_key"], Msg[]>>({});
  // messageList를 계산하는 computed property
  const messageList = computed(() => {
    if (tokenStore.isLogin && currentRoom.value !== undefined) {
      return messageMap.value[currentRoom.value.room_key]?.slice() ?? [];
    }
    return [];
  });

  // arrangedMessageList를 계산하는 computed property
  const arrangedMessageList = computed(() => {
    const arr = messageList.value;
    console.log("🔵 arrangedMessageList 값:", arr);

    return arr;
  });

  // const appendMessage = (msg: Msg) => {
  //   if (
  //     tokenStore.isLogin &&
  //     currentRoom.value !== undefined &&
  //     currentRoom.value.room_key !== undefined &&
  //     msg.cre_date !== null // ✅ cre_date가 null이면 추가하지 않음
  //   ) {
  //     // messageMap.value[currentRoom.value.room_key].push(msg);
  //     messageMap.value[currentRoom.value.room_key].unshift(msg);
  //   }
  //   console.log("🟢 appendMessage 호출됨:", msg);
  // };

  // const setMessageBody = (msgKey: Msg["msg_key"], body: Msg["body"]) => {
  //   if (
  //     tokenStore.isLogin &&
  //     currentRoom.value !== undefined &&
  //     currentRoom.value.room_key !== undefined
  //   ) {
  //     const index = messageMap.value[currentRoom.value.room_key].findLastIndex(
  //       (msg) => msg.msg_key === msgKey
  //     );
  //     if (index !== -1) {
  //       // messageMap.value[currentRoom.value.room_key][index].body = body;
  //       messageMap.value[currentRoom.value.room_key][index].body = body;
  //     }
  //   }
  // };

  // const deleteMessage = async (msg_key: number, room_key: number) => {
  //   try {
  //     await deleteMsg(msg_key);

  //     // 메시지와 관련 응답을 messageMap에서 직접 제거
  //     if (messageMap.value[room_key]) {
  //       messageMap.value[room_key] = messageMap.value[room_key].filter(
  //         (msg) => msg.msg_key !== msg_key && msg.msg_key !== msg_key + 1
  //       );
  //     }
  //   } catch (error) {
  //     console.error("메시지 삭제 중 오류 발생:", error);
  //     throw error;
  //   }
  // };

  // ============================ 🟢 newMessageMap에 append
  const appendMessage = (msg: Msg, roomKey: number) => {
    if (!msg.cre_date) return;

    const dateKey = new Date(msg.cre_date).toISOString().split("T")[0]; // YYYY-MM-DD
    let dayMsg = newMessageMap.value[roomKey]?.find((d) => d.day === dateKey);

    if (!dayMsg) {
      // ✅ 해당 날짜가 없으면 새로 생성
      if (!newMessageMap.value[roomKey]) {
        newMessageMap.value[roomKey] = [];
      }
      dayMsg = { day: dateKey, s_msg_list: [] };
      newMessageMap.value[roomKey].push(dayMsg);
    }

    // ✅ 새로운 SetMsg 객체 생성
    let newSetMsg: SetMsg | undefined = undefined;

    if (msg.parent_key === 0) {
      // ✅ 질문 메시지 (req)
      newSetMsg = { req: msg, res: undefined, ai_res_yn: 0 };
      dayMsg.s_msg_list.push(newSetMsg);
    } else {
      // ✅ 답변 메시지 (res)
      const parentDayMsg = newMessageMap.value[roomKey].find((d) =>
        d.s_msg_list.some((s) => s.req?.msg_key === msg.parent_key)
      );

      if (parentDayMsg) {
        const parentSetMsg = parentDayMsg.s_msg_list.find(
          (s) => s.req?.msg_key === msg.parent_key
        );
        if (parentSetMsg) {
          // ✅ 부모 메시지를 찾았으면 res 추가
          parentSetMsg.res = msg;
          parentSetMsg.ai_res_yn = msg.cre_user_key === 0 ? 1 : 0;
        } else {
          // ✅ 부모 메시지가 없을 경우 새로운 SetMsg로 추가
          newSetMsg = {
            req: undefined,
            res: msg,
            ai_res_yn: msg.cre_user_key === 0 ? 1 : 0
          };
          dayMsg.s_msg_list.push(newSetMsg);
        }
      } else {
        // ✅ 해당 질문(req)이 없는 경우 새로운 SetMsg 생성 후 추가
        newSetMsg = {
          req: undefined,
          res: msg,
          ai_res_yn: msg.cre_user_key === 0 ? 1 : 0
        };
        dayMsg.s_msg_list.push(newSetMsg);
      }
    }
  };

  // ✅ 메시지 추가 함수 (질문 + 답변 모두 처리)
  // const appendMessage = (msg: Msg, roomKey: number) => {
  //   if (!msg.cre_date) return;

  //   const dateKey = new Date(msg.cre_date).toISOString().split("T")[0]; // YYYY-MM-DD
  //   let dayMsg = newMessageMap.value[roomKey]?.find((d) => d.day === dateKey);

  //   if (!dayMsg) {
  //     if (!newMessageMap.value[roomKey]) {
  //       newMessageMap.value[roomKey] = [];
  //     }
  //     dayMsg = { day: dateKey, s_msg_list: [] };
  //     newMessageMap.value[roomKey].push(dayMsg);
  //   }

  //   // ✅ 부모 질문(req) 찾기
  //   let parentSetMsg = null;

  //   if (msg.parent_key !== 0) {
  //     // parent_key가 있는 경우 (즉, 답변인 경우)
  //     for (const d of newMessageMap.value[roomKey]) {
  //       parentSetMsg = d.s_msg_list.find(
  //         (s) => s.req?.msg_key === msg.parent_key
  //       );
  //       if (parentSetMsg) break;
  //     }
  //   }
  //   if (msg.parent_key === 0) {
  //     // ✅ 질문(req) 추가
  //     dayMsg.s_msg_list.push({ req: msg, res: undefined, ai_res_yn: 0 });
  //   } else if (parentSetMsg) {
  //     // ✅ 기존 질문(req)이 있으면, 해당 SetMsg에 res 추가
  //     parentSetMsg.res = msg;
  //     parentSetMsg.ai_res_yn = msg.cre_user_key === 0 ? 1 : 0;
  //   } else {
  //     // ✅ 부모가 없는 경우, 따로 저장 (예외적으로)
  //     dayMsg.s_msg_list.push({
  //       req: undefined,
  //       res: msg,
  //       ai_res_yn: msg.cre_user_key === 0 ? 1 : 0
  //     });
  //   }
  // };

  // ============================ 🟢 newMessageMap에 setMessageBody
  const setMessageBody = (msgKey: Msg["msg_key"], body: Msg["body"]) => {
    if (
      tokenStore.isLogin &&
      currentRoom.value !== undefined &&
      currentRoom.value.room_key !== undefined
    ) {
      const roomKey = currentRoom.value.room_key;

      // ✅ newMessageMap에서 해당 메시지 찾기
      newMessageMap.value[roomKey]?.forEach((dayMsg) => {
        dayMsg.s_msg_list.forEach((sMsg) => {
          // ✅ 질문(req) 메시지 업데이트
          if (sMsg.req && sMsg.req.msg_key === msgKey) {
            sMsg.req.body = body;
          }
          // ✅ 답변(res) 메시지 업데이트
          if (sMsg.res && sMsg.res.msg_key === msgKey) {
            sMsg.res.body = body;
          }
        });
      });
    }
  };

  // ============================ 🟢 newMessageMap에 delete
  // const deleteMessage = async (msg_key: number, room_key: number) => {
  //   try {
  //     await deleteMsg(msg_key);

  //     // ✅ newMessageMap에서 메시지 삭제
  //     if (newMessageMap.value[room_key]) {
  //       newMessageMap.value[room_key].forEach((dayMsg) => {
  //         dayMsg.s_msg_list = dayMsg.s_msg_list.filter(
  //           (sMsg) =>
  //             sMsg.req &&
  //             sMsg.req.msg_key !== msg_key &&
  //             sMsg.res &&
  //             sMsg.res.msg_key !== msg_key
  //         );
  //       });

  //       // ✅ 빈 날짜 제거 (날짜 아래에 메시지가 하나도 없을 경우 삭제)
  //       newMessageMap.value[room_key] = newMessageMap.value[room_key].filter(
  //         (dayMsg) => dayMsg.s_msg_list.length > 0
  //       );
  //     }
  //   } catch (error) {
  //     console.error("메시지 삭제 중 오류 발생:", error);
  //     throw error;
  //   }
  // };
  const deleteMessage = async (msg_key: number, room_key: number) => {
    try {
      await deleteMsg(msg_key);

      if (newMessageMap.value[room_key]) {
        // ✅ 새로운 배열로 할당하여 반응형 시스템이 변경을 감지하게 함
        newMessageMap.value[room_key] = newMessageMap.value[room_key]
          .map((dayMsg) => ({
            ...dayMsg,
            s_msg_list: dayMsg.s_msg_list.filter(
              (sMsg) =>
                sMsg.req &&
                sMsg.req.msg_key !== msg_key &&
                sMsg.res &&
                sMsg.res.msg_key !== msg_key
            )
          }))
          .filter((dayMsg) => dayMsg.s_msg_list.length > 0);
      }
    } catch (error) {
      console.error("메시지 삭제 중 오류 발생:", error);
      throw error;
    }
  };

  // 메세지별 링크 정보를 저장할 Map
  const messageLinksMap = ref(new Map());

  // 링크 저장 메서드
  const setMessageLinks = (messageKey: number, links: any[]) => {
    messageLinksMap.value.set(messageKey, links);
  };

  // 링크 조회 메서드
  const getMessageLinks = (messageKey: number) => {
    return messageLinksMap.value.get(messageKey) || [];
  };

  // == Room =====================================================================
  const roomMap = ref<Record<Channel["channel_key"], RoomInfo[]>>({});
  const roomList = computed(() => {
    if (tokenStore.isLogin && currentChannel.value !== undefined) {
      return roomMap.value[currentChannel.value.channel_key] ?? [];
    }
    return [];
  });

  const selectedRoomMap = ref<Record<Channel["channel_key"], number>>({});
  const selectedRoomIndex = computed({
    get: () => {
      if (currentChannel.value) {
        return selectedRoomMap.value[currentChannel.value.channel_key];
      }
      return 1;
    },
    set: (value) => {
      if (currentChannel.value) {
        selectedRoomMap.value[currentChannel.value.channel_key] = value;
      }
    }
  });
  const toggleSelectedRoom = () => {
    if (selectedRoomIndex.value === 0) {
      selectedRoomIndex.value = 1;
    } else {
      selectedRoomIndex.value = 0;
    }
  };

  const currentRoom = computed(() => {
    if (roomList.value.length > 0) {
      selectedRoomIndex.value = 1;
      return roomList.value[selectedRoomIndex.value];
    }
    return undefined;
  });

  // == User ===================================================================
  const fcmToken = ref<string>();
  const myInfo = ref<User | null>(null);

  // 🔹 앱 로드 시 localStorage에서 사용자 정보 가져오기
  const loadUserFromLocalStorage = () => {
    const storedUser = localStorage.getItem("anytalk_user_info");
    if (storedUser) {
      try {
        myInfo.value = JSON.parse(storedUser);
        console.log("✅ 로컬스토리지에서 사용자 정보 로드:", myInfo.value);
      } catch (error) {
        console.error("❌ JSON 파싱 오류:", error);
        localStorage.removeItem("anytalk_user_info"); // 데이터 손상 시 삭제
      }
    }
  };

  // ** Update *****************************************************************

  // == API ====================================================================
  const updatePulblicChannelInfo = async () => {
    console.log("========updatePulblicChannelInfo");
    console.log("========myInfo", myInfo.value);
    const channelList = await getChannelList({
      channel_key: publicChannelKey.value,
      user_key: myInfo.value?.user_key
    });
    if (channelList) {
      publicChannelList.value = channelList;
      console.log("public channelList", publicChannelList.value);
    }
  };

  const updateNsuChannelList = async () => {
    console.log("========updateNsuChannelList");
    if (myInfo.value?.user_key) {
      const channelList = await getChannelList({
        auth_user_key: myInfo.value.user_key,
        user_key: myInfo.value?.user_key
      });
      if (channelList) {
        allChannelList.value = channelList.filter(
          (item) => item.channel_key === 250 || item.channel_key === 85
        );
        console.log("all channelList", allChannelList);
      }
    }
  };

  const updateAllChannelList = async () => {
    console.log("========updateAllChannelList");
    if (myInfo.value?.user_key) {
      const channelList = await getChannelList({
        auth_user_key: myInfo.value.user_key,
        user_key: myInfo.value?.user_key
      });
      console.log("==================== updateAllChanneList2222");
      if (channelList) {
        allChannelList.value = channelList;
        console.log("all channelList", allChannelList);
      }
    }
  };
  const updateMyChannelInfo = async () => {
    console.log("========updateMyChannelInfo", myInfo.value);
    console.log("========updateMyChannelInfo", myInfo.value);

    if (myInfo.value?.user_key) {
      const channelList = await getChannelList({
        user_key: myInfo.value?.user_key,
        own_user_key: myInfo.value.user_key,
        auth_user_key: myInfo.value.user_key
      });
      if (channelList) {
        myChannelList.value = channelList;
        console.log("my channelList", myChannelList.value);
      }
    }
  };

  const getChannelInfo = async (channel_key: number) => {
    if (myInfo.value?.user_key) {
      const channelList = await getChannelList({
        channel_key: channel_key
      });
      return channelList;
      // if (channelList) {
      //   myChannelList.value = channelList;
      //   console.log("my channelList", myChannelList.value);
      // }
    }
  };

  const updateChannelQuestionList = async (channel_key: number) => {
    const questionList = await getMsgCheckList(channel_key);
    channelQuestionMap.value[channel_key] = questionList ?? [];
  };

  const updateKnowledge = async (channel_key: number) => {
    // console.log("currentChannel lc_uuid", lc_uuid);
    console.log("currentChannel", currentChannel.value);
    // const result = await getKnowList(lc_uuid);
    const result = await getKnowList(channel_key);
    console.log("getKnowledgeList", result);
    knowledgeMap.value[channel_key] = result;
  };

  const updateKnowCheck = async (channel_key: number) => {
    const result = await getKnowCheckList(channel_key);
    console.log("getKnowCheckList", result);
    knowCheckMap.value[channel_key] = result ?? [];
  };

  const updateKnowExpertCheck = async (
    channel_key: number,
    confirm_yn: number
  ) => {
    if (!channel_key) return;

    const params: ReqKmsChannelKnow = {
      channel_key: channel_key,
      confirm_yn: confirm_yn,
      // limit: 1000
      limit: 5000
    };
    const result = await getKmsKnowList2(params);

    // result.data가 배열인지 확인
    if (Array.isArray(result)) {
      knowCheckMap.value = {
        ...knowCheckMap.value,
        [channel_key]: result
      };
    }

    // console.log("After update knowCheckMap:", knowCheckMap.value);
    // console.log("Specific channel data:", knowCheckMap.value[channel_key]);
  };

  const updateRoom = async (channel_key: number, user_key: number) => {
    const result = await getRoomList(channel_key, user_key);
    console.log("roomList", result);
    roomMap.value[channel_key] = result;

    // if (selectedRoomMap.value[channel_key] === undefined) {
    //   selectedRoomMap.value[channel_key] = 0;
    // }
    // ✅ 대표 채널(ANYTALK_CHANNEL_KEY)이 아닌 경우 무조건 개인방(1번 인덱스) 선택
    if (
      channel_key !== ANYTALK_CHANNEL_KEY ||
      selectedRoomMap.value[channel_key] !== undefined
    ) {
      selectedRoomMap.value[channel_key] = 0; // 대표 채널이면 0번 선택
    } else {
      selectedRoomMap.value[channel_key] = 1; // 개인방으로 설정
    }
  };

  const axios_ing_yn = ref(false);

  // const updateMessageList = async (room_key: number) => {
  //   const result = await getMsgList(room_key);
  //   console.log("msgList", result);
  //   messageMap.value[room_key] = result;
  //   newMessageMap.value[room_key] = result;
  //   if (result) {
  //     axios_ing_yn.value = true;
  //   }
  // };

  const updateMessageList = async (room_key: number) => {
    const result = await getMsgList(room_key); // ✅ FastAPI에서 메시지 리스트 가져오기
    console.log("msgList", result);

    // ✅ 기존 방식 유지 (Msg[] 저장)
    messageMap.value[room_key] = result;
    console.log("messageMap", messageMap.value);

    if (result.length > 0) {
      // ✅ result를 `processChatMessages`에 전달하여 `newMessageMap` 업데이트
      processChatMessages(room_key, result);
      axios_ing_yn.value = true;
    }
  };

  // const updateMessageKey = (oldKey: number, newKey: number) => {
  //   Object.keys(messageMap).forEach((roomKey) => {
  //     const messages = messageMap[roomKey];
  //     if (messages) {
  //       const index = messages.findIndex((msg) => msg.msg_key === oldKey);
  //       if (index !== -1) {
  //         console.log(`🛠 메시지 키 변경: ${oldKey} → ${newKey}`);
  //         messages[index].msg_key = newKey;
  //       }
  //     }
  //   });
  // };

  // ======================================== ✅ newMessageMap에 사용되는 updateMessageKey
  const updateMessageKey = (oldKey: number, newKey: number) => {
    Object.keys(newMessageMap.value).forEach((roomKey) => {
      newMessageMap.value[Number(roomKey)]?.forEach((dayMsg) => {
        dayMsg.s_msg_list.forEach((sMsg) => {
          // ✅ 질문(req) 메시지 키 변경
          if (sMsg.req && sMsg.req.msg_key === oldKey) {
            console.log(`🛠 질문 메시지 키 변경: ${oldKey} → ${newKey}`);
            sMsg.req.msg_key = newKey;
          }
          // ✅ 답변(res) 메시지 키 변경
          if (sMsg.res && sMsg.res.msg_key === oldKey) {
            console.log(`🛠 답변 메시지 키 변경: ${oldKey} → ${newKey}`);
            sMsg.res.msg_key = newKey;
          }
        });
      });
    });
  };

  const updateAllMessageKeys = (oldKeys: number[], newKey: number) => {
    Object.keys(newMessageMap.value).forEach((roomKey) => {
      newMessageMap.value[Number(roomKey)]?.forEach((dayMsg) => {
        dayMsg.s_msg_list.forEach((sMsg) => {
          oldKeys.forEach((oldKey) => {
            if (sMsg.req && sMsg.req.msg_key === oldKey) {
              console.log(`🛠 질문 메시지 키 변경: ${oldKey} → ${newKey}`);
              sMsg.req.msg_key = newKey;
            }
            if (sMsg.res && sMsg.res.msg_key === oldKey) {
              console.log(`🛠 답변 메시지 키 변경: ${oldKey} → ${newKey - 1}`);
              sMsg.res.msg_key = newKey;
            }
          });
        });
      });
    });
  };

  // const updateMessageKey = (oldKey: number, newKey: number) => {
  //   const roomKey = currentRoom?.value.room_key;

  //   // ✅ newMessageMap에서 해당 `msg_key` 변경
  //   newMessageMap.value[roomKey]?.forEach((dayMsg) => {
  //     dayMsg.s_msg_list?.forEach((setMsg) => {
  //       if (setMsg.res?.msg_key === oldKey) {
  //         setMsg.res.msg_key = newKey;
  //       }
  //     });
  //   });

  //   console.log(`🔄 메시지 키 업데이트: ${oldKey} → ${newKey}`);
  // };

  const setUserFromLoginResult = (loginResult: {
    access_token: string;
    user: User;
  }) => {
    const tokenStore = useTokenStore();

    if (!loginResult || !loginResult.access_token) {
      throw new Error("로그인 실패: access_token이 없습니다.");
    }

    // 🔹 받은 토큰 저장
    tokenStore.setToken(loginResult.access_token);

    // 🔹 `localStorage`에 JSON 형식으로 저장
    const userString = JSON.stringify(loginResult.user);
    localStorage.setItem("anytalk_user_info", userString);

    // 🔹 `myInfo.value`를 로컬 스토리지에서 다시 가져와 반응형 객체로 변환
    myInfo.value = JSON.parse(userString);

    console.log(
      "사용자 정보 업데이트 완료:",
      localStorage.getItem("anytalk_user_info")
    );
  };

  const updateMyInfo = async () => {
    console.log(
      "✅ 현재 저장된 값 확인:",
      localStorage.getItem("anytalk_user_info")
    );

    const storedUser = localStorage.getItem("anytalk_user_info");

    if (storedUser) {
      myInfo.value = JSON.parse(storedUser);
      console.log("✅ 기존 사용자 정보 유지:", myInfo.value);
      console.log(
        "✅ 현재 저장된 값 확인:",
        localStorage.getItem("anytalk_user_info")
      );

      // const updateMyInfo = async () => {
      //   const userInfo = await myInfo.value;
      //   myInfo.value = userInfo; // ✅ 기존 `updateMyInfo`의 구조 유지
      // };
      // const updateMyInfo = async () => {
      //   if (!myInfo.value) {
      //     console.log("❌ 사용자 정보가 없습니다. 업데이트 실행");
      //     return;
      //   }

      //   console.log("✅ 사용자 정보 유지됨:", myInfo.value);
      // };
      console.log("❌ 사용자 정보 없음, 업데이트 필요");
    }
  };

  const updateFirebaseToken = async () => {
    if (
      "Notification" in window &&
      "serviceWorker" in navigator &&
      "PushManager" in window
    ) {
      fcmToken.value = await requestForToken();
    } else {
      console.log("이 브라우저는 푸시 알림을 지원하지 않습니다.");
    }
  };

  // == Watch ==================================================================
  let isInitialLoad = true;

  onMounted(async () => {
    await loadUserFromLocalStorage();
    await updatePulblicChannelInfo();

    if (tokenStore.isLogin) {
      await updateMyInfo();
      console.log("로그인시 확인");
      await updateAllChannelList();

      const index = allChannelList.value.findIndex(
        (channel) =>
          channel.channel_key ===
          (tokenStore.savedInfo.last_channel_key ??
            currentChannel.value?.channel_key ??
            ANYTALK_CHANNEL_KEY)
      );
      updateAllChannelTabSelected(`${index === -1 ? 0 : index}`);
      // const channel_key = currentChannel.value?.channel_key;
      // if (channel_key) {
      //   updateRoom(channel_key);
      // }
      await updateMyChannelInfo();

      await updateFirebaseToken();
    } else {
      currentChannel.value = publicChannelList.value[0];
    }
    isInitialLoad = false; // 초기 로드 완료

    // ✅ 현재 채널이 없을 경우 기본 채널 설정
    if (!currentChannel.value) {
      currentChannel.value = allChannelList.value.find(
        (channel) => channel.channel_key === ANYTALK_CHANNEL_KEY
      ) ?? {
        channel_key: ANYTALK_CHANNEL_KEY
      };

      console.log("✅ 기본 채널 설정:", currentChannel.value);
    }
  });
  // watch([publicChannelKey], async () => {
  watch(
    () => publicChannelKey.value, // 단일 값 감시
    async () => {
      // if (isInitialLoad) return; // 초기 로드 중에는 실행하지 않음
      await updatePulblicChannelInfo();

      if (!tokenStore.isLogin || forceSelectChannelMode.value) {
        currentChannel.value = publicChannelList.value[0] ?? {
          channel_key: ANYTALK_CHANNEL_KEY
        };
        forceSelectChannelMode.value = false;
      }
      if (!tokenStore.isLogin || forceSelectChannelMode.value) {
        currentChannel.value = publicChannelList.value[0];
      }
    }
  );
  watch(
    () => tokenStore.getToken,
    async (token) => {
      console.log(token);
      if (tokenStore.isLogin) {
        await updateMyInfo();
        //남서울대 테스트계정 특별 처리
        if (myInfo.value?.user_key == 27) {
          console.log("남서울대만 업로드");
          // await updateAllChannelList();
          await updateNsuChannelList();
        } else {
          await updateAllChannelList();
          console.log("로그인시 전체 채널 불러오기");
        }
        if (currentChannel.value?.channel_key !== undefined) {
          const index = allChannelList.value.findIndex(
            (channel) =>
              channel.channel_key ===
              (tokenStore.savedInfo.last_channel_key ??
                currentChannel.value?.channel_key ??
                ANYTALK_CHANNEL_KEY)
          );
          updateAllChannelTabSelected(`${index}`);
        }
        updateMyChannelInfo();
        const channel_key = currentChannel.value?.channel_key;

        if (channel_key) {
          updateRoom(channel_key, myInfo.value?.user_key);
        }

        console.log(
          "========= if 대표 채널로 호출하기",
          currentChannel.value?.channel_key
        );
      } else {
        console.log(
          "========= else 대표 채널로 호출하기",
          currentChannel.value?.channel_key
        );
        await updatePulblicChannelInfo();
        currentChannel.value = publicChannelList.value[0];
        roomMap.value = {};
        selectedRoomMap.value = {};
      }
    }
  );
  watch([currentChannel], async ([channel]) => {
    if (channel && channel.channel_key !== undefined) {
      console.log("innnn");
      // updateKnowledge(channel.channel_key, channel.lc_uuid);

      if (tokenStore.isLogin) {
        tokenStore.savedInfo = {
          last_channel_key: channel.channel_key
        };
        setCookie<CookieInfo>({
          last_channel_key: channel.channel_key
        });
        updateRoom(channel.channel_key, myInfo.value?.user_key);
        // updateChannelQuestionList(channel.channel_key);
        // updateKnowCheck(channel.channel_key);
      }
    }
  });
  watch([currentRoom], async ([room]) => {
    if (room && tokenStore.isLogin) {
      tokenStore.savedInfo = {
        last_room_key: room.room_key
      };
      setCookie<CookieInfo>({
        last_room_key: room.room_key
      });
      console.log("현재 룸", room.name, room.room_key);
      updateMessageList(room.room_key);
    }
  });

  // == STT, TTS 테스트 ==================================================================
  const isSttTtsActive = ref(false);
  const isTtsActive = ref(false);
  const ttsOption: Ref<number> = ref(0);

  const toggleStt = () => {
    isSttTtsActive.value = !isSttTtsActive.value;
  };

  const toggleTts = () => {
    isTtsActive.value = !isTtsActive.value;
  };

  // == KMS ===================================================================
  // // 기본 데이터 저장소
  const folderGroups = ref<KmsFolderGroup[]>([]);
  const folders = ref<KmsFolder[]>([]);
  // 독 업데이트
  // const docs = ref<KmsDoc[]>([]);
  // const knows = ref<KmsKnow[]>([]);
  const knows = ref<ResKmsKnow[]>([]);
  const unconfirmedKnows = ref<ResKmsKnow[]>([]); // ✅ 미확인 지식 리스트

  const loadLinkFolderGroups = async (channelKey: number) => {
    try {
      const data = await getKmsRepoLinkFolderGroupList(channelKey);
      folderGroups.value = Array.isArray(data) ? data : [data];
      console.log("linkGroups: ", folderGroups.value);

      // 첫 번째 폴더 그룹 자동 선택
      if (folderGroups.value.length > 0) {
        selectedFolderGroupKey.value =
          folderGroups.value[0].kms_folder_group_key;
        // 선택된 그룹의 폴더 목록 로드
        await loadFolders(selectedFolderGroupKey.value);
      }
    } catch (error) {
      console.error("링크 폴더 그룹 로딩 실패:", error);
      throw error;
    }
  };

  // 독 업데이트
  // const loadDocFolderGroups = async (channelKey: number) => {
  //   try {
  //     const data = await getKmsRepoImgFolderGroupList(channelKey);
  //     folderGroups.value = Array.isArray(data) ? data : [data];
  //     console.log(
  //       "loadDocFolderGroups 함수 안 fileGroups: ",
  //       folderGroups.value
  //     );

  //     // 첫 번째 폴더 그룹 자동 선택
  //     if (folderGroups.value.length > 0) {
  //       selectedFolderGroupKey.value =
  //         folderGroups.value[0].kms_folder_group_key;
  //       // 선택된 그룹의 폴더 목록 로드
  //       await loadFolders(selectedFolderGroupKey.value);
  //     }
  //   } catch (error) {
  //     console.error("문서 폴더 그룹 로딩 실패:", error);
  //     throw error;
  //   }
  // };

  // 선택 상태 관리
  const selectedFolderGroupKey = ref(null);
  const selectedFolderKey = ref<number | null>(null);
  // 독 업데이트
  // const selectedDocKey = ref<number | null>(null);
  const selectedKnowKey = ref<number | null>(null);
  const expandedFolders = ref(new Set<number>());

  const route = useRoute();
  // const channelKey = ref<string | null>(route.params.channelkey);//-
  // const channelKey = ref<string | null>(
  //   route.params.channelkey ? String(route.params.channelkey) : null
  // ); //+
  const channelKey = ref<string | null>(
    currentChannel.value?.channel_key
      ? String(currentChannel.value?.channel_key)
      : null
  ); //+

  const folderTreeStructure = computed(() => {
    if (!selectedFolderGroupKey.value) return [];

    const groupFolders = folders.value.filter(
      (folder) => folder.kms_folder_group_key === selectedFolderGroupKey.value
    );

    return buildFolderTree(groupFolders);
  });

  const sortedFolders = computed(() => {
    return [...folders.value].sort((a, b) => {
      const keyA = Number(a.kms_folder_key);
      const keyB = Number(b.kms_folder_key);

      return keyA - keyB;
    });
  });

  // filteredFolderTrees에서 정렬된 폴더 사용
  const filteredFolderTrees = computed(() => {
    if (!selectedFolderGroupKey.value) return [];

    // 먼저 root 폴더들을 찾고 정렬
    const rootFolders = sortedFolders.value.filter(
      (f) =>
        f.parent_folder_key === 0 &&
        f.kms_folder_group_key === selectedFolderGroupKey.value
    );

    console.log(
      "Root folders:",
      rootFolders.map((f) => f.kms_folder_key)
    );

    const result = rootFolders.map((folder) => ({
      ...folder,
      children: getOrderedChildren(folder.kms_folder_key)
    }));
    // console.log("result", result);
    return result;
  });

  // 하위 폴더를 가져올 때도 정렬 적용
  const getOrderedChildren = (parentKey: number) => {
    const children = sortedFolders.value.filter(
      (f) => f.parent_folder_key === parentKey && f.delete_yn !== 1
    );
    // .map((folder) => ({
    //   ...folder,
    //   children: getOrderedChildren(folder.kms_folder_key)
    // }));

    // console.log(
    //   `Children for parent ${parentKey}:`,
    //   children.map((f) => f.kms_folder_key)
    // );
    // console.log("result", result);
    return children.map((folder) => ({
      ...folder,
      children: getOrderedChildren(folder.kms_folder_key)
    }));
  };

  const buildFolderTree = (
    folders: KmsFolder[],
    parentKey: number | null = null
  ): FolderTreeNode[] => {
    return folders
      .filter((folder) => folder.parent_folder_key === parentKey)
      .map((folder) => ({
        ...folder,
        children: buildFolderTree(folders, folder.kms_folder_key),
        level:
          parentKey === null
            ? 0
            : folders.find((f) => f.kms_folder_key === parentKey)?.level + 1 ||
              0
      }));
  };

  // children 폴더를 찾는 헬퍼 함수 추가
  const getChildFolders = (parentKey: number) => {
    return folders.value.filter(
      (folder) => folder.parent_folder_key === parentKey
    );
  };

  // 독 업데이트
  // 선택된 폴더의 문서 목록
  // const filteredDocs = computed(() => {
  //   if (!selectedFolderKey.value) return [];
  //   return docs.value.filter(
  //     (doc) =>
  //       doc.kms_folder_key === selectedFolderKey.value && doc.delete_yn === 0
  //   );
  // });

  // 독 업데이트
  // 선택된 문서의 지식
  // const selectedDocKnows = computed<KmsKnow[]>(() => {
  //   if (!selectedDocKey.value) return [];
  //   console.log("문서가 선택됨========================");

  //   // 현재 선택된 문서 정보 가져오기
  //   const doc = docs.value.find(
  //     (doc) => doc.kms_doc_key === selectedDocKey.value
  //   );
  //   console.log("선택된 문서들========================", doc);
  //   console.log("선택한 문서의 지식들===================", knows.value);

  //   return knows.value;
  //   // return doc ? doc.value : [];
  // });

  // 독 업데이트
  // 선택된 폴더의 지식
  const selectedFolderKnows = computed<KmsKnow[]>(() => {
    if (!selectedFolderKey.value) return [];
    console.log("문서가 선택됨========================");

    // 현재 선택된 문서 정보 가져오기
    const folder = folders.value.find(
      (folder) => folder.kms_folder_key === selectedFolderKey.value
    );
    console.log("선택된 폴더들========================", folder);
    console.log("선택한 폴더의 지식들===================", knows.value);

    return knows.value;
    // return doc ? doc.value : [];
  });

  // 현재 경로
  const currentPath = computed(() => {
    const parts = [];

    // 폴더 그룹 이름 추가
    const group = folderGroups.value.find(
      (g) => g.kms_folder_group_key === selectedFolderGroupKey.value
    );
    if (group) parts.push(group.folder_group_name);

    // 선택된 폴더의 전체 경로 찾기
    if (selectedFolderKey.value) {
      const folderPath = [];
      let currentFolder = folders.value.find(
        (f) => f.kms_folder_key === selectedFolderKey.value
      );

      while (currentFolder) {
        folderPath.unshift(currentFolder.folder_name);
        currentFolder = folders.value.find(
          (f) => f.kms_folder_key === currentFolder.parent_folder_key
        );
      }

      parts.push(...folderPath);
    }
    // 독 업데이트
    // 선택된 문서 이름 추가
    // if (selectedDocKey.value) {
    //   const selectedDoc = docs.value.find(
    //     (doc) => doc.kms_doc_key === selectedDocKey.value
    //   );
    //   if (selectedDoc) {
    //     parts.push(selectedDoc.doc_name);
    //   }
    // }

    return parts.join(" > ");
  });

  // 독 업데이트
  // 문서별 지식 개수 계산
  // const getKnowCountByDoc = (docKey: number) => {
  //   return knows.value.filter(
  //     (know) => know.kms_doc_key === docKey && know.delete_yn === 0
  //   ).length;
  // };
  const getKnowCountByFolder = (folderKey: number) => {
    return knows.value.filter(
      (know) => know.kms_folder_key === folderKey && know.delete_yn === 0
    ).length;
  };

  // 액션 메서드들
  const selectFolderGroup = (groupKey: number) => {
    selectedFolderGroupKey.value = groupKey;
    selectedFolderKey.value = null;
    // 독 업데이트
    // selectedDocKey.value = null;
  };

  // 폴더 선택 관리 (한번에 하나의 폴더 선택)
  const selectFolder = async (folderKey: number) => {
    console.log("========= 선택된 folderKey", folderKey);

    const foundFolder = folders.value.find(
      (folder) => folder.kms_folder_key === folderKey
    );

    // 이미 선택된 폴더를 다시 클릭하면 선택 해제
    if (selectedFolderKey.value === folderKey) {
      selectedFolderKey.value = null;
      selectedFolder.value = null;
    } else {
      selectedFolderKey.value = folderKey;
      selectedFolder.value = foundFolder || null;
    }

    try {
      if (selectedFolderKey.value) {
        // value로 접근
        // await loadDocs(selectedFolderKey.value);
        // 독 업데이트
        await loadKnows(Number(channelKey.value), selectedFolderKey.value, 1);
        console.log("1-2 kms_folder_key", selectedFolder.value?.kms_folder_key); // kms_folder_key 값만 가져오기
        console.log(
          "1-2 parent_folder_key",
          selectedFolder.value?.parent_folder_key
        );
      } else {
        // 선택 해제 시 문서 목록 초기화 등 필요한 처리
        // 독 업데이트
        // selectedDocKey.value = null;
        // filteredDocs.value = [];
        selectedFolderKey.value = null;
      }
    } catch (error) {
      console.error("문서 로드 실패:", error);
    }

    console.log("2", selectedFolder.value?.kms_folder_key);
    return selectedFolder.value?.kms_folder_key;
  };

  // 독 업데이트
  // const selectDoc = (docKey: number) => {
  //   selectedDocKey.value = docKey;
  // };
  const selectKnow = (knowKey: number) => {
    selectedKnowKey.value = knowKey;
  };

  // 폴더 열림/닫힘 상태 관리
  const toggleFolder = (folderKey: number) => {
    if (expandedFolders.value.has(folderKey)) {
      expandedFolders.value.delete(folderKey);
    } else {
      expandedFolders.value.add(folderKey);
    }
  };

  // 상세페이지 요약 수정
  const updateSummary = (knowKey: number, newSummary: string) => {
    console.log("updateSummary 호출됨");
    console.log("요약 수정 대상:", knowKey);
    console.log("새로운 요약 내용:", newSummary);

    const knowItem = kmsKnows.value.find(
      (know) => know.kms_know_key === knowKey
    );
    if (knowItem) {
      console.log("수정 전 요약 내용:", knowItem.summary);
      knowItem.summary = newSummary;
      console.log("수정 후 요약 내용:", knowItem.summary);
    } else {
      console.warn("해당 키를 가진 항목을 찾을 수 없습니다.");
    }
  };

  // 상세페이지 내용 수정
  const updateBody = (knowKey: number, newBody: string) => {
    console.log("updateBody 호출됨");
    console.log("내용 수정 대상:", knowKey);
    console.log("새로운 내용:", newBody);

    const knowItem = kmsKnows.value.find(
      (know) => know.kms_know_key === knowKey
    );
    if (knowItem) {
      console.log("수정 전 내용:", knowItem.body);
      knowItem.body = newBody;
      console.log("수정 후 내용:", knowItem.body);
    } else {
      console.warn("해당 키를 가진 항목을 찾을 수 없습니다.");
    }
  };

  // 상세페이지 필수 내용 수정
  const updateMustText = (knowKey: number, newMustText: string) => {
    const knowItem = kmsKnows.value.find(
      (know) => know.kms_know_key === knowKey
    );

    if (knowItem) {
      console.log("기존 필수내용:", knowItem.must_text);
      knowItem.must_text = newMustText; // 필수내용 업데이트
      console.log("새로운 필수내용:", knowItem.must_text);
    } else {
      console.warn("해당 키를 가진 항목을 찾을 수 없습니다.");
    }
  };

  // 상세페이지 키워드 리스트 수정
  const updateKeywordList = (knowKey: number, newKeywordList: string[]) => {
    console.log("updateKeywordList 호출됨");
    console.log("키워드 리스트 수정 대상:", knowKey);
    console.log("새로운 키워드 리스트:", newKeywordList);

    const knowItem = kmsKnows.value.find(
      (know) => know.kms_know_key === knowKey
    );

    if (knowItem) {
      console.log("기존 키워드 리스트:", knowItem.keyword_list);
      knowItem.keyword_list = newKeywordList; // 키워드 리스트 업데이트
      console.log("새로운 키워드 리스트:", knowItem.keyword_list);
    } else {
      console.warn("해당 키를 가진 항목을 찾을 수 없습니다.");
    }
  };

  // 상세페이지 키워드 리스트 수정
  const updateQuestList = (knowKey: number, newQuestdList: string[]) => {
    console.log("updateKeywordList 호출됨");
    console.log("키워드 리스트 수정 대상:", knowKey);
    console.log("새로운 키워드 리스트:", newQuestdList);

    const knowItem = kmsKnows.value.find(
      (know) => know.kms_know_key === knowKey
    );

    if (knowItem) {
      console.log("기존 키워드 리스트:", knowItem.quest_list);
      knowItem.quest_list = newQuestdList; // 키워드 리스트 업데이트
      console.log("새로운 키워드 리스트:", knowItem.quest_list);
    } else {
      console.warn("해당 키를 가진 항목을 찾을 수 없습니다.");
    }
  };

  const selectedFolder = computed(() => {
    return folders.value.find(
      (f) => f.kms_folder_key === selectedFolderKey.value
    );
  });

  const selectedFolderChildren = computed(() => {
    return folders.value.filter(
      // (f) => f.parent_folder_key === selectedFolderKey.value
      (f) => f.parent_folder_key === selectedFolder.value?.kms_folder_key
    );
    // return folders.value.find(
    //   (f) => f.kms_folder_key === selectedFolderKey.value
    // );
  });

  // 독 업데이트
  // const selectedDoc = computed(() =>
  //   docs.value.find((doc) => doc.kms_doc_key === selectedDocKey.value)
  // );

  // kms fodler 추가
  const addNewFolder = async (newFolder: KmsFolder) => {
    // API 호출을 위한 데이터 준비
    const folderData = {
      kms_folder_group_key: newFolder.kms_folder_group_key || null,
      parent_folder_key: newFolder.parent_folder_key || 0, // null일 경우 0으로 설정
      folder_name: newFolder.folder_name,
      cre_user_key: newFolder.cre_user_key,
      channel_key: newFolder.channel_key || null
    };

    // API 호출
    // const response = await createKmsFolder(folderData);
    await createKmsFolder(folderData);

    // 부모 폴더가 있는 경우 expandedFolders에 추가
    if (newFolder.parent_folder_key) {
      expandedFolders.value.add(newFolder.parent_folder_key);
    }

    // 현재 선택된 그룹의 폴더 목록 새로 로드
    await loadFolders(selectedFolderGroupKey.value);
  };

  const editFolderName = async (editFolder: KmsFolder) => {
    const folderData = {
      kms_folder_key: editFolder.kms_folder_key,
      kms_folder_group_key: editFolder.kms_folder_group_key,
      parent_folder_key: editFolder.parent_folder_key,
      folder_name: editFolder.folder_name
      // upd_user_key: editFolder.upd_user_key,
      // upd_date: editFolder.upd_date
    };

    const response = await updateKmsFolder(folderData);
    console.log("store.ts : ", response);

    await loadFolders(selectedFolderGroupKey.value);
  };

  const moveFolder = async (
    draggedFolderKey: number,
    targetFolderKey: number | null
  ) => {
    try {
      const folderToMove = folders.value.find(
        (f) => f.kms_folder_key === draggedFolderKey
      );
      if (!folderToMove) return;
      console.log("draggedFolderKey", draggedFolderKey);
      console.log("targetFolderKey", targetFolderKey);

      const updateData = {
        kms_folder_key: draggedFolderKey,
        kms_folder_group_key: folderToMove.kms_folder_group_key,
        parent_folder_key: targetFolderKey === null ? 0 : targetFolderKey,
        folder_name: folderToMove.folder_name
      };

      // API 호출 전 UI 즉시 업데이트
      const folderIndex = folders.value.findIndex(
        (f) => f.kms_folder_key === draggedFolderKey
      );
      if (folderIndex !== -1) {
        folders.value[folderIndex] = {
          ...folders.value[folderIndex],
          parent_folder_key: targetFolderKey === null ? 0 : targetFolderKey
        };
      }

      const response = await updateKmsFolder(updateData);
      if (!response) {
        await loadFolders(selectedFolderGroupKey.value);
      }
    } catch (error) {
      console.error("폴더 이동 실패:", error);
      throw error;
    }
  };

  // const moveFolder = async (
  //   draggedFolderKey: number,
  //   targetFolderKey: number | null,
  //   targetFolderGroupKey: number | null = null
  // ) => {
  //   try {
  //     // 이동할 폴더 찾기
  //     const folderToMove = folders.value.find(
  //       (f) => f.kms_folder_key === draggedFolderKey
  //     );

  //     if (!folderToMove) {
  //       console.warn("이동할 폴더를 찾을 수 없습니다.");
  //       return;
  //     }

  //     console.log("draggedFolderKey", draggedFolderKey);
  //     console.log("targetFolderKey", targetFolderKey);
  //     console.log("targetFolderGroupKey", targetFolderGroupKey);

  //     // 업데이트할 데이터 준비
  //     const updateData = {
  //       kms_folder_key: draggedFolderKey,
  //       // 폴더 그룹 키도 변경 가능하도록 수정
  //       kms_folder_group_key:
  //         targetFolderGroupKey || folderToMove.kms_folder_group_key,
  //       // 최상위 폴더로 이동 시 0, 그렇지 않으면 타겟 폴더 키
  //       parent_folder_key: targetFolderKey === null ? 0 : targetFolderKey,
  //       folder_name: folderToMove.folder_name
  //     };

  //     // 즉시 UI 업데이트
  //     const folderIndex = folders.value.findIndex(
  //       (f) => f.kms_folder_key === draggedFolderKey
  //     );

  //     if (folderIndex !== -1) {
  //       // 불변성을 유지하며 상태 업데이트
  //       folders.value = folders.value.map((folder, index) =>
  //         index === folderIndex
  //           ? {
  //               ...folder,
  //               parent_folder_key:
  //                 targetFolderKey === null ? 0 : targetFolderKey,
  //               kms_folder_group_key:
  //                 targetFolderGroupKey || folder.kms_folder_group_key
  //             }
  //           : folder
  //       );
  //     }

  //     // API 호출
  //     const response = await updateKmsFolder(updateData);

  //     // API 호출 실패 시 폴더 다시 로드
  //     if (!response) {
  //       // 폴더 그룹 키가 변경된 경우 해당 그룹의 폴더 로드
  //       if (targetFolderGroupKey) {
  //         await loadFolders(targetFolderGroupKey);
  //       } else {
  //         // 그렇지 않으면 현재 선택된 폴더 그룹의 폴더 로드
  //         await loadFolders(selectedFolderGroupKey.value);
  //       }
  //     }

  //     // 성공적으로 이동했음을 알리는 알림 추가 (선택사항)
  //     $q.notify({
  //       message: "폴더가 성공적으로 이동되었습니다.",
  //       color: "positive"
  //     });
  //   } catch (error) {
  //     console.error("폴더 이동 실패:", error);

  //     // 에러 발생 시 사용자에게 알림
  //     $q.notify({
  //       message: "폴더 이동 중 오류가 발생했습니다.",
  //       color: "negative"
  //     });

  //     // 에러 상황에서 원래 상태로 롤백
  //     await loadFolders(selectedFolderGroupKey.value);

  //     throw error;
  //   }
  // };

  const selectedFolders = ref(new Set<number>());

  const toggleFolderSelection = (folderKey: number) => {
    if (selectedFolders.value.has(folderKey)) {
      selectedFolders.value.delete(folderKey);
    } else {
      selectedFolders.value.add(folderKey);
    }
  };

  const deleteFolders = async (folderKey: number) => {
    try {
      await deleteKmsFolder({
        kms_folder_key: folderKey
      });
      await loadFolders(selectedFolderGroupKey.value); // 폴더 목록 새로고침
      // selectedFolders.value.clear();
    } catch (error) {
      console.error("폴더 삭제 실패:", error);
      throw error;
    }
  };

  //kms folder 삭제
  const deleteFolder = async (folderKey: number) => {
    try {
      const folderToDelete = new Set<number>();

      const findChildFolders = (parentKey: number) => {
        folderToDelete.add(parentKey);
        folders.value
          .filter((f) => f.parent_folder_key === parentKey)
          .forEach((child) => findChildFolders(child.kms_folder_key));
      };

      findChildFolders(folderKey);

      // 찾은 모든 폴더 삭제
      folders.value = folders.value.filter(
        (folder) => !folderToDelete.has(folder.kms_folder_key)
      );
    } catch (error) {
      console.error("폴더 삭제 중 오류 발생:", error);
      throw error;
    }
  };
  // 독 업데이트
  // const deleteDoc = async (kms_doc_key: number) => {
  //   try {
  //     // 서버에서 문서 삭제
  //     await deleteKmsDoc(kms_doc_key);

  //     // 로컬 상태 업데이트
  //     docs.value = docs.value.filter((doc) => doc.kms_doc_key !== kms_doc_key);
  //   } catch (error) {
  //     console.error("문서 삭제 중 오류 발생:", error);
  //     throw error;
  //   }
  // };

  // 독 업데이트
  // const updateDocName = async (docKey: number, newDocName: string) => {
  //   try {
  //     // API 호출하여 문서명 수정
  //     await updateKmsDoc({
  //       kms_doc_key: docKey,
  //       doc_name: newDocName
  //     });

  //     // 문서 목록 새로고침
  //     if (selectedFolderKey.value) {
  //       await loadDocs(selectedFolderKey.value);
  //     }
  //   } catch (error) {
  //     console.error("문서명 수정 실패:", error);
  //     throw error;
  //   }
  // };

  // == KMS REPO IMAGE ======================================================================
  const uploadQueue = ref<
    {
      file: File;
      imageUrl: string;
      progress: number;
      status: "uploading" | "completed" | "error";
    }[]
  >([]);

  const handleFileUpload = async (file: File, channelKey: number) => {
    if (!selectedFolderKey.value) {
      console.log("selectedFolderKey가 null입니다");
      throw new Error("폴더를 선택해주세요.");
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("kms_folder_key", selectedFolderKey.value.toString());
    formData.append("channel_key", channelKey.toString());
    formData.append("file_disp", file.name);

    try {
      const result = await createRepoFile(formData);
      // 파일 업로드 성공 후 파일 목록 새로고침
      // 독 업데이트
      // await loadDocs(selectedFolderKey.value);
      return result;
    } catch (error) {
      console.error("파일 업로드 실패:", error);
      throw error;
    }
  };

  const removeFromQueue = (index: number) => {
    uploadQueue.value.splice(index, 1);
  };

  // === 액션 (Actions) ======================================================================
  const loadFolderGroups = async (channelKey: number) => {
    try {
      if (!channelKey) {
        console.error("유효하지 않은 채널키:", channelKey);
        return;
      }

      const data = await getKmsFolderGroupList(channelKey);
      folderGroups.value = Array.isArray(data) ? data : [data];

      // 폴더 그룹이 있을 때만 처리
      if (folderGroups.value && folderGroups.value.length > 0) {
        selectedFolderGroupKey.value =
          folderGroups.value[0].kms_folder_group_key;
        await loadFolders(selectedFolderGroupKey.value);
      } else {
        // 폴더 그룹이 없는 경우도 처리
        console.log("해당 채널에 폴더 그룹이 없습니다:", channelKey);
        folderGroups.value = [];
        folders.value = [];
        selectedFolderGroupKey.value = null;
      }
    } catch (error) {
      console.error("폴더 그룹 로딩 실패:", error);
      throw error;
    }
  };

  const loadFolders = async (groupKey: number) => {
    try {
      const data = await getKmsFolderList(groupKey);
      const activeFolders = Array.isArray(data)
        ? data.filter((folder) => folder.delete_yn !== 1)
        : data.delete_yn !== 1
          ? [data]
          : [];
      // folders.value = Array.isArray(data) ? data : [data];
      folders.value = activeFolders;
    } catch (error) {
      console.error("폴더 로딩 실패:", error);
      throw error;
    }
  };
  // 독 업데이트
  // const loadDocs = async (folderKey: number) => {
  //   try {
  //     const data = await getKmsDocList(folderKey);
  //     docs.value = Array.isArray(data) ? data : [data];

  //     if (selectedDocKey.value) {
  //       const docExists = docs.value.some(
  //         (doc) => doc.kms_doc_key === selectedDocKey.value
  //       );
  //       if (!docExists) {
  //         selectedDocKey.value = null;
  //         knows.value = [];
  //       } else {
  //         // await loadKnows(channelKey.value, selectedDocKey.value, 1);
  //         await loadKnows(
  //           currentChannel.value?.channel_key ||
  //             Number(currentChannel.value?.channel_key),
  //           selectedDocKey.value,
  //           1
  //         );
  //       }
  //     }
  //   } catch (error) {
  //     console.error("문서 로딩 실패:", error);
  //     throw error;
  //   }
  // };

  const unconfirmedKnowCount = ref(0);

  const unconfirmedChannelKnowCount = ref(0);

  const loadKnows = async (
    channelKey: number,
    // 독 업데이트
    folderKey: number,
    // docKey: number,
    confirmYn: number
  ) => {
    try {
      const data: ResKmsKnow[] = await getKmsKnowConfirmList(
        channelKey,
        // docKey,
        // 독 업데이트
        folderKey,
        confirmYn
      );
      const unconfirmCount: ResKmsKnow[] = await getKmsKnowConfirmList(
        channelKey,
        0,
        0
      );

      unconfirmedChannelKnowCount.value = Array.isArray(unconfirmCount)
        ? unconfirmCount.length
        : 1;
      // knows 배열을 ResKmsKnow 형태로 저장
      knows.value = Array.isArray(data) ? data : [];

      if (confirmYn === 1) {
        const unconfirmedData: ResKmsKnow[] = await getKmsKnowConfirmList(
          channelKey,
          // docKey,
          // 독 업데이트
          folderKey,
          1
        );
        unconfirmedKnowCount.value = Array.isArray(unconfirmedData)
          ? unconfirmedData.length
          : 1;
      }
    } catch (error) {
      console.error("지식 로딩 실패:", error);
      throw error;
    }
  };

  const loadKnowDetail = async (
    channelKey: number,
    know_key_list_str: number,
    confirmYn: number = 1,
    subInfoYn: number = 1
  ) => {
    try {
      const params: ReqKmsChannelKnow = {
        channel_key: channelKey,
        know_key_list_str: [know_key_list_str],
        confirm_yn: confirmYn,
        sub_info_yn: subInfoYn,
        // limit: 1000
        limit: 5000
      };
      const data = await getKmsKnowList2(params);
      return data;
    } catch (error) {
      console.error("지식 상세 정보 로드 실패:", error);
      throw error;
    }
  };

  const loadChannelKnows = async (
    channelKey: number,
    confirmYn: number,
    limit: number
  ) => {
    try {
      const params: ReqKmsChannelKnow = {
        channel_key: channelKey,
        confirm_yn: confirmYn,
        // limit: 1000
        limit: 5000
      };

      const data: ResKmsKnow[] = await getKmsKnowList2(params);

      const unconfirmCount: ResKmsKnow[] = await getKmsKnowConfirmList(
        channelKey,
        0,
        0
      );
      unconfirmedChannelKnowCount.value = Array.isArray(unconfirmCount)
        ? unconfirmCount.length
        : 1;
      // knows 배열을 ResKmsKnow 형태로 저장
      // knows.value = Array.isArray(data) ? data : [];
      unconfirmedKnows.value = Array.isArray(data) ? data : [];

      // 검토 대기 중인 지식 수 업데이트
      // unconfirmedKnowCount.value = knows.value.filter(
      unconfirmedKnowCount.value = unconfirmedKnows.value.filter(
        (item) => item.know.confirm_yn === 0
      ).length;

      return data;
    } catch (error) {
      console.error("채널 지식 로딩 실패:", error);
      throw error;
    }
  };

  return {
    ANYTALK_CHANNEL_KEY,
    SAEUM_CHANNEL_KEY,
    forceSelectChannelMode,
    publicChannelKey,
    setMessageBody,

    allChannelList,
    updateAllChannelList,
    publicChannelList,
    myChannelList,
    updateMyChannelInfo,
    getChannelInfo,
    updateChannelAuth,
    deleteFollowChannelAuth,
    updateMessageList,

    showChannelAddDialog,
    openChannelAddDialog,
    closeChannelAddDialog,

    axios_ing_yn,

    TAB_LIST,
    channelTabIndex,
    allChannelTabSelected,
    selectedChannel,
    selectedChannelBold,
    allChannelSelected,
    myChannelTabSelected,
    myChannelSelected,
    currentChannel,
    selectedRoomIndex,
    currentRoom,

    activeQuestionList,
    repliedQuestionList: answeredQuestionList,
    rejectedQuestionList,
    updateAnsweredQuestion,

    knowledgeList,
    knowledgeMap,
    activeKnowCheckList,
    answeredKnowCheckList,
    rejectedKnowCheckList,
    updatedToAnsweredKnowOrg,
    updatedToRejectedKnowOrg,
    updateKnowledge,
    updateKnowCheck,
    updateKnowExpertCheck,
    updateChannelQuestionList,

    messageList,
    arrangedMessageList,
    // mergedList,
    // finalMessageList,
    appendMessage,
    roomList,
    toggleSelectedRoom,
    myInfo,
    ttsOption,
    isSttTtsActive,
    isTtsActive,
    toggleStt,
    toggleTts,
    deleteMessage,
    setMessageLinks,
    getMessageLinks,

    fcmToken,

    // 상태
    folderGroups,
    folders,
    // 독 업데이트
    // docs,
    knows,
    selectedFolderGroupKey,
    selectedFolderKey,
    // 독 업데이트
    // selectedDocKey,
    expandedFolders,
    selectedFolders,

    // 계산된 속성
    folderTreeStructure,
    filteredFolderTrees,
    getChildFolders,
    // 독 업데이트
    // filteredDocs,
    // 독 업데이트
    // selectedDocKnows,
    selectedFolderKnows,
    currentPath,
    toggleFolderSelection,
    sortedFolders,

    // 메서드
    // 독 업데이트
    // getKnowCountByDoc,
    getKnowCountByFolder,
    selectFolderGroup,
    selectFolder,
    // 독 업데이트
    // selectDoc,
    selectKnow,
    toggleFolder,
    deleteFolder,
    deleteFolders,

    // kms 상세페이지
    updateSummary,
    updateBody,
    updateMustText,
    updateKeywordList,
    updateQuestList,
    // loadKmsKnows,

    selectedFolder,
    // 독 업데이트
    // selectedDoc,
    addNewFolder,
    editFolderName,
    // 독 업데이트
    // updateDocName,

    // kms repo image
    uploadQueue,
    // 독 업데이트
    // loadDocFolderGroups,
    // handleFiles,
    // updateDocName,
    // saveImages,
    removeFromQueue,
    handleFileUpload,

    // kms repo link
    loadLinkFolderGroups,

    updateMyInfo,
    setUserFromLoginResult,
    // 액션
    loadFolderGroups,
    loadFolders,
    // loadDocs,
    loadKnows,
    loadKnowDetail,
    loadChannelKnows,
    unconfirmedKnowCount,
    unconfirmedChannelKnowCount,
    // getUnconfirmedKnowCount,

    autoLoginAndMoveToChannel,
    hasPrivateRoom,
    getOrderedChildren,
    selectedFolderChildren,

    updateMessageKey,
    updateAllMessageKeys,

    newMessageMap,
    // processMessages,
    processChatMessages,
    unconfirmedKnows
  };
});
