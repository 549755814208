<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";
import { Primitive, type PrimitiveProps } from "radix-vue";
import { inject, ref } from "vue";
import { sidebarKey } from "./SidebarRoot.vue";

interface Props extends PrimitiveProps {
  postion?: "left" | "right";
  disableOutsidePointerEvents?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  as: "div"
});
const isLeft = props.postion === "left" || props.postion === undefined;

const context = inject(sidebarKey);

const sidebarContentRef = ref<InstanceType<typeof Primitive>>();
onClickOutside(sidebarContentRef, () => {
  if (!props.disableOutsidePointerEvents && context?.enabled.value) {
    context?.toggleEnabled();
  }
});
</script>
<template>
  <Primitive
    ref="sidebarContentRef"
    class="sidebarContent"
    :class="{
      sidebarContent__open: context?.enabled.value ?? false,
      sidebarContent__pos_left: isLeft,
      sidebarContent__pos_right: !isLeft
    }"
    v-bind="props"
  >
    <slot></slot>
  </Primitive>
</template>
<style lang="scss">
.sidebarContent {
  --sidebar-width: min(500px, 100vw);

  position: fixed;
  width: var(--sidebar-width);
  height: 100vh;
  z-index: 100;
  /* z-index: 4; */
  top: 0;
  background: white;
  transition: transform ease-in 0.35s;

  &__pos_left {
    left: 0;
    transform: translateX(calc(-1 * var(--sidebar-width)));
  }
  &__pos_right {
    right: 0;
    transform: translateX(var(--sidebar-width));
  }

  &__open {
    transform: translateX(0px);
  }
}
</style>
