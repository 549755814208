<script setup>
import { inject, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { sidebarKey } from "../components/common/sidebar/SidebarRoot.vue";
import CommonPopLayer from "../popups/CommonPopLayer.vue";
import { useStore } from "../stores/store";

const sidebarContext = inject(sidebarKey);
const store = useStore();

const router = useRouter();
const route = useRoute();

import channelDetailCompo from "../components/root/channelManagement/ChannelDetailCompo.vue";

const popupTitle = "채널 상세";
// const popupInfos = ref({ mainColor: "skyblue", subColor: "gray" });

const props = defineProps({
  pSelectedChannelInfo: Object,
  pCloseDetailPop: Function,
  isPopYn: {
    type: Boolean,
    default: false
  }
});
const currentChannel = ref({});

const detailDesc = ref("안녕하세요, 환영합니다.");
const newDetailDesc = ref("");

const oldSelectedCategory = ref("");
oldSelectedCategory.value = props.pSelectedChannelInfo?.category
  ? props.pSelectedChannelInfo?.category
  : "기업";
const newSelectedCategory = ref("");
const changeCategory = (option) => {
  oldSelectedCategory.value = option;
};

const oldName = ref(props.pSelectedChannelInfo?.name);
const newName = ref("");

// 수정 <-> 저장
const detailPopMode = ref(false);
const changeModeToModi = () => {
  detailPopMode.value = true;
};

const changeModeToDetail = () => {
  detailPopMode.value = false;
  saveNewChannelInfos();
};

// 수정된 데이터 저장
const saveNewChannelInfos = () => {
  newDetailDesc.value = detailDesc;
  newSelectedCategory.value = oldSelectedCategory.value;
  newName.value = oldName.value;
};

const categories = ref([
  { name: "기업", value: "기업" },
  { name: "식당", value: "식당" },
  { name: "카페", value: "카페" },
  { name: "공공장소", value: "공공장소" },
  { name: "종교", value: "종교" }
]);

const movePage = () => {
  store.currentChannel = store.selectedChannel;
  console.log("props.pSelectedChannelInfo", props.pSelectedChannelInfo);
  router.push(`/manage/${props.pSelectedChannelInfo.channel_key}`);
  if (sidebarContext?.enabled.value === true) {
    sidebarContext?.toggleEnabled();
  }
  props.pCloseDetailPop();
};

const goToChatting = () => {
  if (sidebarContext?.enabled.value === true) {
    sidebarContext?.toggleEnabled();
  }
  store.currentChannel = props.pSelectedChannelInfo;
  router.push(`/`);
  props.pCloseDetailPop();
};

watch(
  () => store.currentChannel,
  (newValue) => {
    currentChannel.value = newValue;
  }
);

onMounted(() => {
  changeCategory(oldSelectedCategory.value);
  // setTimeout(() => {
  //   currentChannel.value = store.currentChannel;
  // }, 100);
});
</script>

<template>
  <CommonPopLayer :pPopTitle="popupTitle">
    <template #body>
      <channelDetailCompo :pIsPopYn="isPopYn" />
    </template>
    <template #actions>
      <button
        v-if="
          store.currentChannel &&
          store.currentChannel.auth_type &&
          store.currentChannel.auth_type.includes('A')
        "
        @click="movePage"
        class="fontSm cursorP"
      >
        채널 관리 이동
      </button>
      <button class="fontSm cursorP" @click="goToChatting">채팅창 이동</button>
    </template>
  </CommonPopLayer>
</template>

<style lang="scss" scoped></style>
