<script setup lang="ts">
import { elapsedDateAndTime, elapsedTime } from "../../../utils/date";
import {
  acceptKnowCheck,
  confirmKmsKnow2,
  rejectKnowCheck,
  type ResKnowCheck
} from "../../../api/backend";
import {
  getPureBody,
  getTitle,
  knowOrgStatusName,
  knowOrgTargetName
} from "./knowOrg";
import { computed, ref } from "vue";
import { useStore } from "../../../stores/store";
import { usePopupStore } from "../../../stores/popups";
import { DialogRoot } from "radix-vue";
import ConfirmAskPop from "../../../popups/ConfirmAskPop.vue";
import { useQuasar } from "quasar";
import { KmsKnow } from "../../../api/types";

interface Props {
  pKnows: ResKnowCheck[];
}
const props = defineProps<Props>();
const store = useStore();
const $q = useQuasar();
const popStore = usePopupStore();

// const emits = defineEmits(["openAnswerQuePop"]);
// const openAnswerQuePop = (ask) => {
//   console.log("z");
//   emits("openAnswerQuePop", ask);
// };
const emits = defineEmits(["openKnowDetailPop"]);

const openKnowDetailPop = (item: object, index: number) => {
  console.log("index", index);
  emits("openKnowDetailPop", item, index);
};

// 전체 선택
// 체크된 항목을 관리할 배열
const selectedItems = ref<ResKnowCheck[]>([]);

// 전체 선택 체크박스의 상태를 computed로 관리
const allSelected = computed({
  get: () => selectedItems.value.length === props.pKnows.length,
  set: (value: boolean) => {
    if (value) {
      selectedItems.value = [...props.pKnows]; // 전체 선택
    } else {
      selectedItems.value = []; // 전체 해제
    }
    selectedItems.value = value ? [...props.pKnows] : [];
  }
});

// 개별 체크박스 클릭 시 호출
const toggleItemSelection = (item: ResKnowCheck) => {
  // if (selectedItems.value.includes(item)) {
  //   selectedItems.value = selectedItems.value.filter((i) => i !== item); // 선택 해제
  // } else {
  //   selectedItems.value.push(item); // 선택
  // }
  const index = selectedItems.value.indexOf(item);
  if (index > -1) {
    selectedItems.value.splice(index, 1);
  } else {
    selectedItems.value.push(item);
  }
};

// 일괄 승인 함수
const batchApproveKnow = async () => {
  if (selectedItems.value.length === 0) {
    $q.notify({
      message: "선택된 지식이 없습니다.",
      color: "warning"
    });
    return;
  }

  // popStore.openConfirmAskPopLayer();
  console.log("dddd");
  popStore.confirmAskPopBody = `${selectedItems.value.length}개의 전문가 답변을 지식을 승인하시겠습니까?`;

  popStore.confirmAskOK = async () => {
    try {
      for (const item of selectedItems.value) {
        if (item.know) {
          const payload = {
            kms_know_key: item.know.kms_know_key,
            // 독 업데이트
            // kms_doc_key: item.know.kms_doc_key,
            kms_folder_key: item.know.kms_folder_key,
            channel_key: item.know.channel_key,
            confirm_yn: 1,
            title: item.know.title || "",
            summary: item.know.summary || "",
            body: item.know.body || "",
            must_text: item.know.must_text || "",
            delete_yn: 0,
            keyword_list: item.know.keyword_list || [],
            quest_list: item.know.quest_list || [],
            img_filekey_list: item.know.img_filekey_list || [],
            attach_filekey_list: item.know.attach_filekey_list || [],
            linkkey_list: item.know.linkkey_list || [],
            mapping_key_str: item.know.mapping_key_str || "",
            valid_yn: item.know.valid_yn || 1
          };
          console.log("payload", payload);
          await confirmKmsKnow2(payload);
        }
      }

      if (store.currentChannel) {
        await store.updateKnowExpertCheck(store.currentChannel.channel_key, 0);
      }
      selectedItems.value = []; // 작업 완료 후 선택 항목 초기화
      $q.notify({
        message: `지식이 승인되었습니다.`,
        color: "positive"
      });
    } catch (error) {
      console.error("승인 처리 중 오류 발생:", error);
      $q.notify({
        message: `지식 승인 중 오류가 발생했습니다.`,
        color: "negative"
      });
      throw error;
    }
  };
  popStore.openConfirmAskPopLayer();
  // popStore.confirmAskPopBody = `${selectedItems.value.length}개의 전문가 답변을 지식을 승인하시겠습니까?`;
  // popStore.confirmPopBody = `${selectedItems.value?.length}개의 지식이 승인되었습니다.`;
};

// 일괄 반려 함수
const batchRejectKnow = async () => {
  if (selectedItems.value.length === 0) {
    $q.notify({
      message: "선택된 지식이 없습니다.",
      color: "warning"
    });
    return;
  }

  popStore.openConfirmAskPopLayer();
  popStore.confirmAskPopBody = `${selectedItems.value.length}개의 전문가 답변을 지식을 반려하시겠습니까?`;
  popStore.confirmAskOK = async () => {
    try {
      for (const item of selectedItems.value) {
        if (item.know) {
          const payload = {
            kms_know_key: item.know.kms_know_key,
            // 독 업데이트
            // kms_doc_key: item.know.kms_doc_key,
            kms_folder_key: item.know.kms_folder_key,
            channel_key: item.know.channel_key,
            confirm_yn: 2,
            title: item.know.title || "",
            summary: item.know.summary || "",
            body: item.know.body || "",
            must_text: item.know.must_text || "",
            delete_yn: 0,
            keyword_list: item.know.keyword_list || [],
            quest_list: item.know.quest_list || [],
            img_filekey_list: item.know.img_filekey_list || [],
            attach_filekey_list: item.know.attach_filekey_list || [],
            linkkey_list: item.know.linkkey_list || [],
            mapping_key_str: item.know.mapping_key_str || "",
            valid_yn: item.know.valid_yn
          };
          console.log("payload", payload);
          await confirmKmsKnow2(payload);
        }
      }

      if (store.currentChannel) {
        await store.updateKnowExpertCheck(store.currentChannel.channel_key, 0);
      }
      selectedItems.value = []; // 작업 완료 후 선택 항목 초기화
      $q.notify({
        message: `지식이 반려되었습니다.`,
        color: "positive"
      });
    } catch (error) {
      console.error("반려 처리 중 오류 발생:", error);
      $q.notify({
        message: `지식 반려 중 오류가 발생했습니다.`,
        color: "negative"
      });
    }
  };
  // popStore.confirmPopBody = `${selectedItems.value?.length}개의 지식이 반려되었습니다.`;
};

// 일괄 승인 함수
// const batchApproveKnow = async () => {
//   popStore.openConfirmAskPopLayer();
//   popStore.confirmAskPopBody = `${selectedItems.value.length}개의 지식을 승인하시겠습니까?`;
//   popStore.confirmAskOK = async () => {
//     for (const item of selectedItems.value) {
//       if (item.know?.know_check_key && store.currentChannel) {
//         await acceptKnowCheck({ know_check_key: item.know?.know_check_key });
//       }
//     }
//     // await store.updateKnowCheck(store.currentChannel.channel_key);
//     // await store.updateKnowledge(
//     //   store.currentChannel.channel_key,
//     //   store.currentChannel.lc_uuid
//     // );
//     if (store.currentChannel) {
//       await store.updateKnowExpertCheck(store.currentChannel.channel_key, 0);
//     }
//     selectedItems.value = []; // 작업 완료 후 선택 항목 초기화
//   };
//   popStore.confirmPopBody = `${selectedItems.value.length}개의 지식이 승인되었습니다.`;
// };

// // 일괄 반려 함수
// const batchRejectKnow = async () => {
//   popStore.openConfirmAskPopLayer();
//   popStore.confirmAskPopBody = `${selectedItems.value.length}개의 지식을 반려하시겠습니까?`;
//   popStore.confirmAskOK = async () => {
//     for (const item of selectedItems.value) {
//       if (item.know?.know_check_key && store.currentChannel) {
//         await rejectKnowCheck({ know_check_key: item.know?.know_check_key });
//       }
//     }
//     // await store.updateKnowCheck(store.currentChannel.channel_key);
//     // await store.updateKnowledge(
//     //   store.currentChannel.channel_key
//     //   // store.currentChannel.lc_uuid
//     // );
//     if (store.currentChannel) {
//       await store.updateKnowExpertCheck(store.currentChannel.channel_key, 0);
//     }
//     selectedItems.value = []; // 작업 완료 후 선택 항목 초기화
//   };
//   popStore.confirmPopBody = `${selectedItems.value.length}개의 지식이 반려되었습니다.`;
// };

const isChecked = (item: ResKnowCheck) => selectedItems.value.includes(item);
</script>

<template>
  <!-- 전체 선택 체크박스 -->
  <div class="checkBoxWrap" v-if="props.pKnows.length > 0">
    <div class="select-all-container">
      <input
        type="checkbox"
        id="selectAll"
        class="allKnowCheckInput"
        v-model="allSelected"
      />
      <label for="selectAll">전체 선택</label>
    </div>
    <div class="confirmBoxWrap">
      <!-- 일괄 작업 버튼 -->
      <DialogRoot v-model:open="popStore.confirmAskPopYn">
        <button
          class="fontSm cursorP ok"
          @click="batchApproveKnow"
          :disabled="selectedItems.length === 0"
        >
          승인
        </button>
        <button
          class="fontSm cursorP cancel"
          @click="batchRejectKnow"
          :disabled="selectedItems.length === 0"
        >
          반려
        </button>
        <ConfirmAskPop />
      </DialogRoot>
    </div>
  </div>
  <ul class="wrap">
    <div
      v-for="(item, index) in props.pKnows"
      :key="item.know?.know_check_key"
      style="display: flex; align-items: center; gap: 10px"
    >
      <!-- 개별 체크박스 -->
      <input
        class="knowCheckInput"
        style="margin-top: 10px"
        type="checkbox"
        :checked="isChecked(item)"
        @change="toggleItemSelection(item)"
      />
      <!-- <li
        role="button"
        tabindex="0"
        @click="openKnowDetailPop(item, index)"
        @keydown.enter="openKnowDetailPop(item, index)"
        class="knowItem backShadow fontMd cursorP"
        :key="item.know_check_key"
      > -->
      <li
        role="button"
        tabindex="0"
        @click="openKnowDetailPop(item, index)"
        @keydown.enter="openKnowDetailPop(item, index)"
        class="knowItem backShadow fontMd cursorP"
      >
        <div class="top flexAlign" style="align-items: normal">
          <div class="infos">
            <span class="fontBold">{{ getTitle(item.know?.body) }}</span>
            <!-- <span>{{ elapsedTime(item.know?.cre_date) }}</span> -->
            <div class="miniInfoWrap flexAlign">
              <span class="btn status">
                <!-- {{ knowOrgStatusName[item.status] }} -->
                <!-- {{ knowOrgStatusName[item.know?.confirm_yn] }} -->
              </span>
              <!-- <span class="btn typeBtn">{{
                knowOrgTargetName[item.target_kind]
              }}</span> -->
            </div>
          </div>
          <span class="timestamp">{{
            elapsedDateAndTime(item.know?.cre_date)
          }}</span>
          <!-- <div
            class="creInfos flexAlign fontSm"
            style="justify-content: flex-end"
          > -->
          <div
            class="creInfos writer-info flexAlign fontSm"
            style="justify-content: flex-end"
          >
            <!-- <span>작성자: {{ item.know?.user?.nick }}</span> -->
            <!-- <span>{{ elapsedTime(item.know?.cre_date) }}</span> -->
          </div>
        </div>
        <div class="bottom fontMd">
          <p class="knowText">{{ getPureBody(item.know?.body) }}</p>
          <!-- <div>
          <span class="keywords fontSm">태그 : 강아지</span>
          <span class="urls fontSm">URL : 비숲 홈페이지</span>
        </div> -->
        </div>
      </li>
    </div>
  </ul>
</template>

<style lang="scss" scoped>
.allKnowCheckInput {
  flex-shrink: 0;
  width: 20px !important;
  height: 20px !important;
  border: 2px solid #ccc !important;
  border-radius: 4px !important;
  background-color: #f9f9f9 !important;
  transition: all 0.1s ease !important; /* 부드러운 전환 효과 */
  appearance: none !important;
  cursor: pointer !important;
}

.allKnowCheckInput:checked {
  background-color: #4caf50 !important;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"%3E%3Cpath d="M20.285 6.738l-11.082 11.195c-.369.369-.963.369-1.332 0l-4.156-4.156a.941.941 0 0 1 0-1.332l1.332-1.332c.369-.369.963-.369 1.332 0l2.824 2.823 9.75-9.75c.369-.369.963-.369 1.332 0l1.332 1.332c.369.369.369.963 0 1.332z"/%3E%3C/svg%3E') !important;
  border-color: #4caf50 !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 60% !important;
}

.allKnowCheckInput:hover {
  background-color: rgb(205, 240, 205) !important; /* 체크 시 녹색 배경 */
  color: black !important;
}

.knowCheckInput {
  width: 20px !important;
  height: 20px !important;
  border: 2px solid #ccc !important; /* 부드러운 테두리 */
  border-radius: 4px !important; /* 살짝 둥근 모서리 */
  background-color: #f9f9f9 !important; /* 밝은 배경 */
  transition: all 0.1s ease !important; /* 부드러운 전환 효과 */
  appearance: none !important; /* 기본 체크박스 제거 */
  cursor: pointer !important; /* 클릭 가능한 손 모양 커서 */
}

.knowCheckInput:checked {
  background-color: #4caf50 !important; /* 체크 시 녹색 배경 */
  border-color: #4caf50 !important; /* 체크 시 테두리 색 변경 */
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"%3E%3Cpath d="M20.285 6.738l-11.082 11.195c-.369.369-.963.369-1.332 0l-4.156-4.156a.941.941 0 0 1 0-1.332l1.332-1.332c.369-.369.963-.369 1.332 0l2.824 2.823 9.75-9.75c.369-.369.963-.369 1.332 0l1.332 1.332c.369.369.369.963 0 1.332z"/%3E%3C/svg%3E') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 60% !important; /* 체크 아이콘 크기 */
}

.knowCheckInput:hover {
  background-color: rgb(205, 240, 205) !important; /* 체크 시 녹색 배경 */

  color: black !important;
}

/* 스타일 추가 */
.select-all-container {
  /* margin-bottom: 10px; */
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
}

.checkBoxWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  vertical-align: middle;
}

.confirmBoxWrap {
  display: flex;
  gap: 10px;
}

.writer-info {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  color: #666;
  text-align: right;
  gap: 10px;
}

.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: space-between;

  & .knowItem {
    width: 100%;
    line-height: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 20px;
    gap: 10px;
    background-color: #fff;
    min-height: 150px;
    /* height: 190px; */
    transition:
      transform 0.2s,
      box-shadow 0.2s;
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
    }

    & .top {
      width: 100%;
      justify-content: space-between;
      display: flex;
      flex-direction: column;

      & .infos {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        align-items: baseline;

        & .miniInfoWrap {
          gap: 10px;
        }
      }

      & .btn {
        padding: 0 5px;
        font-size: 13px;
        cursor: pointer;
      }

      & .status {
        background-color: lightgray;
        border-radius: 15px;
        padding: 0 10px;
        line-height: 25px;
      }
      & .btn.typeBtn {
        font-weight: bold;
      }
    }

    & .creInfos {
      gap: 10px;
      display: flex;
      justify-content: space-between;
    }

    & .bottom {
      height: 100%;
      display: flex;
      align-items: start;
      flex-direction: column;
      justify-content: space-between;
      & .knowText {
        font-size: 14px;
        color: #353535;
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5em; /* 각 줄의 높이를 설정 */
        max-height: 4.5em; /* 3줄까지의 높이를 설정 (line-height * 3) */
      }

      & .keywords {
        margin-right: 15px;
      }
      & .urls {
      }
    }
  }
}

.top {
  position: relative; // 상대적 위치 설정

  & .timestamp {
    position: absolute; // 절대적 위치 설정
    right: 0; // 오른쪽 끝으로 정렬
    top: 0; // 상단에 위치
    font-size: 14px; // 글씨 크기 작게
    color: #666; // 회색으로 설정
  }

  & .infos {
    padding-right: 100px; // timestamp를 위한 공간 확보
  }
}

// 모바일 반응형 CSS
@media (max-width: 768px) {
  .wrap {
    /* padding: 0 10px; */

    & .knowItem {
      padding: 15px;
      height: auto; /* 높이를 자동으로 조정하여 콘텐츠에 맞게 확장되도록 설정 */

      & .top {
        flex-direction: column;
        gap: 10px; /* 각 요소 사이의 간격 조정 */
        align-items: normal;

        & .infos {
          gap: 5px;

          & .miniInfoWrap {
            gap: 5px;
            flex-direction: row;
            flex-wrap: wrap;
          }
        }

        & .btn {
          font-size: 12px; /* 모바일에서 폰트 크기 조정 */
          padding: 2px 5px; /* 버튼 패딩을 줄여서 공간 활용 */
        }

        & .status {
          font-size: 12px; /* 모바일에서 폰트 크기 조정 */
        }

        & .btn.typeBtn {
          font-weight: normal; /* 모바일에서 폰트 굵기 조정 */
        }
      }

      & .creInfos {
        font-size: 12px; /* 모바일에서 폰트 크기 조정 */
        gap: 5px; /* 작성자와 시간 간격 조정 */
      }

      & .bottom {
        gap: 5px; /* 모바일에서 각 요소 사이의 간격 조정 */

        & .knowText {
          -webkit-line-clamp: 2; /* 모바일에서 줄 수를 2줄로 조정 */
          max-height: 3em; /* 2줄까지의 높이를 설정 (line-height * 2) */
        }

        & .keywords,
        & .urls {
          font-size: 12px; /* 모바일에서 폰트 크기 조정 */
        }
      }
    }
  }
}
</style>
