<script setup lang="ts">
import { DialogRoot } from "radix-vue";
import { computed } from "vue";
import { acceptKnowCheck, rejectKnowCheck } from "../../../../api/backend";
import { usePopupStore } from "../../../../stores/popups";
import { useStore } from "../../../../stores/store";
import { elapsedTime } from "../../../../utils/date";
import {
  getPureBody,
  getTitle,
  knowOrgStatusName,
  knowOrgTargetName
} from "../knowOrg";
import type { KnowOrg } from "../../../../api/types";

/* 컴포넌트 */
import CommonPopLayer from "../../../../popups/CommonPopLayer.vue";

const store = useStore();
const popStore = usePopupStore();

const popTitle = "지식 상세";

interface Props {
  pClosePop: () => void;
  pSelectedKnow?: KnowOrg;
}
const props = defineProps<Props>();

const knowBody = computed(() => props.pSelectedKnow?.body);
const newTitle = computed(() => getTitle(knowBody.value ?? ""));
const newKnow = computed(() => getPureBody(knowBody.value ?? ""));

const approveKnow = () => {
  popStore.openConfirmAskPopLayer();
  popStore.confirmAskPopBody = "선택한 지식을 승인하시겠습니까?";
  popStore.confirmCloseParentPop = props.pClosePop;
  popStore.confirmAskOK = async () => {
    const know_check_key = props.pSelectedKnow?.know_check_key;
    if (know_check_key && store.currentChannel) {
      await acceptKnowCheck({ know_check_key });
      // await store.updateKnowCheck(store.currentChannel.channel_key);
      await store.updateKnowExpertCheck(store.currentChannel.channel_key, 1);
      await store.updateKnowledge(
        store.currentChannel.channel_key
        // store.currentChannel.lc_uuid
      );
    }
  };
  popStore.confirmPopBody = "선택한 지식이 승인되었습니다.";
};

const rejectKnow = () => {
  popStore.openConfirmAskPopLayer();
  popStore.confirmAskPopBody = "선택한 지식을 반려하시겠습니까?";
  popStore.confirmCloseParentPop = props.pClosePop;
  popStore.confirmAskOK = async () => {
    const know_check_key = props.pSelectedKnow?.know_check_key;
    if (know_check_key && store.currentChannel) {
      await rejectKnowCheck({ know_check_key });
      // await store.updateKnowCheck(store.currentChannel.channel_key);
      await store.updateKnowExpertCheck(store.currentChannel.channel_key, 2);
      await store.updateKnowledge(
        store.currentChannel.channel_key
        // store.currentChannel.lc_uuid
      );
    }
  };
  popStore.confirmPopBody = "선택한 지식이 반려되었습니다.";
};
</script>

<template>
  <CommonPopLayer :pPopTitle="popTitle">
    <template #body>
      <div class="knowDetailPopWrap">
        <div class="knowItem knowWrap">
          <div class="top flexAlign">
            <div class="infos flexAlign" style="">
              <span class="fontBold fontMd">
                {{ newTitle ? newTitle : "자식 키워드 제목" }}
              </span>
              <div class="btnWrap flexAlign">
                <span class="btn status">
                  {{ knowOrgStatusName[pSelectedKnow?.status ?? "I"] }}
                </span>
                <span class="btn typeBtn">
                  {{ knowOrgTargetName[pSelectedKnow?.target_kind ?? "O"] }}
                </span>
                <template v-if="false">
                  <span class="btn reportCnt">신고수 3</span>
                  <span class="btn exposeCnt">노출수 35</span>
                </template>
              </div>
            </div>
            <div class="creInfos fontSm flexAlign">
              <span>작성자: {{ pSelectedKnow?.user?.nick ?? "임재영" }}</span>
              <span>{{ elapsedTime(pSelectedKnow?.cre_date) }}</span>
            </div>
          </div>
          <div class="bottom fontMd">
            <p class="knowText">
              {{ newKnow }}
            </p>
          </div>
        </div>
      </div>
    </template>

    <template #actions>
      <DialogRoot v-model:open="popStore.confirmAskPopYn">
        <confirmAskPop />
      </DialogRoot>
      <template v-if="pSelectedKnow?.status === 'I'">
        <button class="fontSm cursorP ok" @click="approveKnow">승인</button>
        <button class="fontSm cursorP cancel" @click="rejectKnow">반려</button>
      </template>
    </template>
  </CommonPopLayer>
</template>

<style lang="scss" scoped>
@use "./knowModiPop.scss";
.knowWrap {
  max-height: 30rem;
  overflow-y: scroll;
}

/* .knowWrap::-webkit-scrollbar {
  display: none;
} */
</style>
