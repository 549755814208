<script setup lang="ts">
import { ref, computed, watch } from "vue";
import AddDetailKnowAuthPop from "./AddDetailKnowAuthPop.vue";
import SettingIcon from "@fluentui/svg-icons/icons/edit_settings_24_regular.svg";
import { createAuthCard } from "../../../../api/backend";
import { useStore } from "../../../../stores/store";

interface AuthCardData {
  authcard_name: string;
  channel_key: number;
  folder_key_list: number[];
  delete_yn: number;
}

interface AuthCardResponse {
  authcard_key: number;
  authcard_name: string;
  channel_key: number;
  cre_date: string;
  folder_key_list: number[];
}

const props = defineProps({
  open: {
    type: Boolean,
    required: true
  },
  pClosePop: {
    type: Function,
    required: true
  },
  authData: {
    type: Array as () => AuthCardResponse[],
    required: true
  }
});

const emits = defineEmits(["update:open", "save"]);

const kwdType = ref("");
const folderKeys = ref<number[]>([]); // ✅ 폴더 키 목록
const authorizedItems = ref<SavedFolder[]>([]);
const showDetailPopup = ref(false);
const showValidationError = ref(false);
const validationMessage = ref("");
const folderKeyList = computed(() =>
  authorizedItems.value.map((item) => item.kms_folder_key)
);

// ✅ `authData`가 변경되면 자동으로 반영
const computedAuthData = computed(() => props.authData);

// ✅ 유효성 검사
const isValid = computed(() => {
  if (!kwdType.value.trim()) {
    validationMessage.value = "권한명을 입력해주세요";
    return false;
  }
  return true;
});

// ✅ 팝업 닫기
const handleClose = () => {
  kwdType.value = "";
  folderKeys.value = [];
  authorizedItems.value = [];
  showValidationError.value = false;
  validationMessage.value = "";
  emits("update:open", false);
  if (props.pClosePop) props.pClosePop();
};

const store = useStore();

// ✅ 권한 카드 추가 API 요청
const handleSave = async () => {
  if (!isValid.value) {
    showValidationError.value = true;
    return;
  }

  const payload: AuthCardData = {
    authcard_name: kwdType.value,
    channel_key: store.currentChannel?.channel_key || 0,
    folder_key_list: folderKeyList.value.length ? folderKeyList.value : [0],
    delete_yn: 0
  };

  try {
    const response = await createAuthCard(payload);

    if (response && response.authcard_key) {
      // Add new auth card to existing list
      props.authData.push({
        authcard_name: response.authcard_name,
        authcard_key: response.authcard_key,
        channel_key: response.channel_key,
        cre_date: response.cre_date,
        folder_key_list: response.folder_key_list
      });

      emits("save", response);
      handleClose();
    } else {
      console.error("권한 카드 추가 실패:", response);
      // alert("권한 카드 추가 실패");
    }
  } catch (error) {
    console.error("권한 카드 추가 중 오류 발생:", error);
    // alert("권한 카드 추가 중 오류가 발생했습니다.");
  }
};

interface SavedFolder {
  folder_name: string;
  kms_folder_key: number;
}

// ✅ 폴더 선택 팝업
const handleDetailSave = (items: SavedFolder[]) => {
  authorizedItems.value = items;
};

const handleDetailClose = () => {
  showDetailPopup.value = false;
};

// ✅ 입력 필드 변경 시 유효성 검사 메시지 초기화
const handleInput = () => {
  showValidationError.value = false;
};
</script>

<template>
  <div v-if="open" class="popup-overlay">
    <div class="popup-content">
      <div class="popup-header">
        <div class="header-content">
          <h2>권한 카드 추가</h2>
        </div>
      </div>

      <div class="popup-body">
        <div class="input-column">
          <div class="input-group">
            <label>권한 카드명</label>
            <div class="input-wrapper">
              <input
                v-model="kwdType"
                type="text"
                class="auth-input"
                :class="{ error: showValidationError }"
                placeholder="권한명을 입력하세요"
                @input="handleInput"
              />
              <div v-if="showValidationError" class="error-message">
                {{ validationMessage }}
              </div>
            </div>
          </div>

          <!-- <div class="input-group">
            <label>폴더 키</label>
            <input
              v-model="folderKeys"
              type="text"
              class="auth-input"
              placeholder="쉼표(,)로 여러 개 입력 가능"
            />
          </div> -->

          <div class="knowledge-section-header">
            <h3>권한 지식</h3>
            <button class="setting-button" @click="showDetailPopup = true">
              <SettingIcon />
            </button>
          </div>

          <div class="knowledge-section">
            <div class="auth-list">
              <div
                v-for="item in authorizedItems"
                :key="item.kms_folder_key"
                class="folder-tag-container"
              >
                <div class="folder-tag">{{ item.folder_name }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="button-group">
          <button class="save-button" @click="handleSave">저장</button>
          <button class="cancel-button" @click="handleClose">닫기</button>
        </div>
      </div>
    </div>
  </div>

  <AddDetailKnowAuthPop
    v-model:open="showDetailPopup"
    :authorizedItems="authorizedItems"
    @save="handleDetailSave"
    @close="handleDetailClose"
  />
</template>

<!-- NewAddKnowAuthPop.vue -->
<!-- <script setup lang="ts">
import { ref, computed } from "vue";
import AddDetailKnowAuthPop from "./AddDetailKnowAuthPop.vue";
import SettingIcon from "@fluentui/svg-icons/icons/edit_settings_24_regular.svg";

const props = defineProps({
  open: {
    type: Boolean,
    required: true
  },
  pClosePop: {
    type: Function,
    required: true
  }
});

const emits = defineEmits(["update:open", "save"]);

const kwdType = ref("");
const authorizedItems = ref<string[]>([]);
const showDetailPopup = ref(false);
const showValidationError = ref(false);
const validationMessage = ref("");

// 유효성 검사를 위한 computed 속성
const isValid = computed(() => {
  if (!kwdType.value.trim()) {
    validationMessage.value = "권한명을 입력해주세요";
    return false;
  }
  return true;
});

const handleClose = () => {
  // 팝업 닫을 때 상태 초기화
  kwdType.value = "";
  showValidationError.value = false;
  validationMessage.value = "";
  emits("update:open", false);
  if (props.pClosePop) {
    props.pClosePop();
  }
};

const handleSave = () => {
  // 저장 전 유효성 검사
  if (!isValid.value) {
    showValidationError.value = true;
    return;
  }

  emits("save", {
    label: kwdType.value,
    value: kwdType.value.toLowerCase().replace(/\s+/g, "_"),
    permissions: authorizedItems.value
  });
  handleClose();
};

const handleDetailSave = (items: string[]) => {
  authorizedItems.value = items;
};

const handleDetailClose = () => {
  showDetailPopup.value = false;
};

// 입력 필드 변경 시 유효성 검사 메시지 초기화
const handleInput = () => {
  showValidationError.value = false;
};
</script>

<template>
  <div v-if="open" class="popup-overlay">
    <div class="popup-content">
      <div class="popup-header">
        <div class="header-content">
          <h2>권한 카드 추가</h2>
        </div>
      </div>

      <div class="popup-body">
        <div class="input-column">
          <div class="input-group">
            <label>권한 카드명</label>
            <div class="input-wrapper">
              <input
                v-model="kwdType"
                type="text"
                class="auth-input"
                :class="{ error: showValidationError }"
                placeholder="권한명을 입력하세요"
                @input="handleInput"
              />
              <div v-if="showValidationError" class="error-message">
                {{ validationMessage }}
              </div>
            </div>
          </div>

          <div class="knowledge-section-header">
            <h3>권한 지식</h3>
            <button class="setting-button" @click="showDetailPopup = true">
              <SettingIcon />
            </button>
          </div>

          <div class="knowledge-section">
            <div class="auth-list">
              <div
                v-for="item in authorizedItems"
                :key="item"
                class="folder-tag-container"
              >
                <div class="folder-tag">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="button-group">
          <button class="save-button" @click="handleSave">저장</button>
          <button class="cancel-button" @click="handleClose">닫기</button>
        </div>
      </div>
    </div>
  </div>

  <AddDetailKnowAuthPop
    v-model:open="showDetailPopup"
    :authorizedItems="authorizedItems"
    @save="handleDetailSave"
    @close="handleDetailClose"
  />
</template> -->

<style lang="scss" scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  border-radius: 8px;
  width: 400px;
  height: 480px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.popup-header {
  padding: 16px;
  border-bottom: 1px solid #eee;

  .header-content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    h2 {
      margin: 0;
      font-size: 18px;
      font-weight: 600;
    }

    .close-button {
      position: absolute;
      right: 0;
      background: none;
      border: none;
      font-size: 24px;
      cursor: pointer;
      padding: 0 8px;
      color: #666;
      box-shadow: none !important;

      &:hover {
        color: #333;
      }
    }
  }
}

.popup-body {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.input-column {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 20px;
}

.knowledge-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 30px !important;

  h3 {
    margin: 0;
    font-size: 14px;
    color: #666;
  }

  .setting-button {
    background: none !important;
    border: none !important;
    padding: 4px;
    cursor: pointer;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none !important;

    &:hover {
      color: #333;
    }
  }
}

.input-group {
  display: flex;
  align-items: center;
  gap: 8px;

  label {
    width: 70px;
    text-align: left;
  }

  .auth-input {
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 6px 12px;
    min-width: 0;
    height: 32px;

    &:focus {
      outline: none;
      border-color: #454545;
    }
  }
}

.knowledge-section {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 200px;
}

.auth-list {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  .folder-tag-container {
    padding: 4px;
  }

  .folder-tag {
    display: inline-flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 16px;
    padding: 4px 12px;
    font-size: 13px;
  }
}

.input-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.auth-input {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 6px 12px;
  min-width: 0;
  height: 32px;

  &:focus {
    outline: none;
    border-color: #454545;
  }

  &.error {
    border-color: #ff4d4f;
  }
}

.error-message {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 4px;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;

  button {
    padding: 8px 24px;
    border-radius: 4px;
    cursor: pointer;
    border: none;

    &.save-button {
      background-color: #51c2b8;
      color: white;
      font-weight: 600;
    }

    &.save-button:hover {
      background-color: #18a498;
    }

    &.cancel-button {
      background-color: #f5f5f5;
      font-weight: 600;
    }

    &.cancel-button:hover {
      background-color: #ddd;
    }
  }
}
</style>
