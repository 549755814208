import type { KnowOrg } from "../../../api/types";

export function splitBody(body?: string) {
  if (typeof body !== "string") {
    return ["제목없음", "비었습니다"] as const;
  }
  const colonIndex = body.indexOf(":");

  if (colonIndex === -1) {
    return ["제목없음", body] as const;
  }

  return [
    body.substring(0, colonIndex),
    body.substring(colonIndex + 1)
  ] as const;
}

export function getTitle(body: string) {
  return splitBody(body)[0];
}

export function getPureBody(body: string) {
  return splitBody(body)[1];
}

export const knowOrgTargetName: Record<KnowOrg["target_kind"], string> = {
  M: "메세지",
  O: "입력지식"
  // TODO: P - PDF
};
export const knowOrgStatusName: Record<KnowOrg["status"], string> = {
  I: "검토중",
  O: "검토완료",
  R: "거절됨"
};
