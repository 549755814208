<script setup lang="ts">
import { DialogClose, DialogRoot } from "radix-vue";

import { ref } from "vue";
import { usePopupStore } from "../../../../stores/popups";
import { useStore } from "../../../../stores/store";
import type { MsgCheck } from "../../../../api/types";
import SpeakYesIcon1 from "@fluentui/svg-icons/icons/speaker_2_20_regular.svg";
import HourGlassIcon from "@fluentui/svg-icons/icons/hourglass_20_regular.svg";

/* 컴포넌트 */
import CommonPopLayer from "../../../../popups/CommonPopLayer.vue";
import { elapsedTime } from "../../../../utils/date";
import {
  LANGUAGE,
  createExpertAnswer,
  deleteExpertAnswer
} from "../../../../api/backend";
import { useSpeechRecognition, useTTS } from "../../../main/sound";
import { SpeechRecognition } from "../../../../types/SpeechRecognition";
import { onMounted } from "vue";

const store = useStore();
const popStore = usePopupStore();

const popTitle = "답변 등록";
const selected = ref<"once" | "multi">("once");

// 지식 카드 수정-저장 시 로딩 상태
const isPageLoading = ref(false);

// 저장 시작 이벤트 처리
const handleSaveStart = () => {
  isPageLoading.value = true;
};

// 저장 완료 이벤트 처리
const handleSaveComplete = () => {
  isPageLoading.value = false;
};

interface Props {
  pClosePop: () => void;
  pSelectedQue?: MsgCheck;
}
const props = defineProps<Props>();

const showAIAnswerYn = ref(false);
const openAIAnswer = () => {
  showAIAnswerYn.value = !showAIAnswerYn.value;
};

const answer = ref("");
const answerToQue = (callback: () => void) => {
  //컨펌 애스크 팝, 컨펌 팝 참고
  popStore.openConfirmAskPopLayer();
  popStore.confirmAskPopBody = "전문가 답변을 등록하시겠습니까?";
  popStore.confirmCloseParentPop = props.pClosePop;
  popStore.confirmAskOK = callback;
  popStore.confirmPopBody = "전문가 답변이 등록되었습니다.";
};

const saveAnswer = async () => {
  console.log("answer", answer.value);
  handleSaveStart();
  if (props.pSelectedQue && store.currentChannel && store.currentRoom) {
    await createExpertAnswer({
      is_chunk: selected.value === "multi",
      msg_check_key: props.pSelectedQue.msg_check_key,
      room_key: props.pSelectedQue.question.room_key,
      parent_key: props.pSelectedQue.question.msg_key,
      user_key: store.myInfo?.user_key,
      question: props.pSelectedQue.question.body,
      answer: answer.value
    });

    await store.updateChannelQuestionList(store.currentChannel.channel_key);
    // await store.updateKnowCheck(store.currentChannel.channel_key);
    await store.updateMessageList(store.currentRoom.room_key);
    popStore.closeConfirmAskPopLayer();
    props.pClosePop();
    handleSaveComplete();
    // window.location.reload();
  }
};

const reejctToQue = (callback: () => void) => {
  popStore.openConfirmAskPopLayer();
  popStore.confirmAskPopBody = "답변을 거절하시겠습니까?";
  popStore.confirmCloseParentPop = props.pClosePop;
  popStore.confirmAskOK = callback;
  popStore.confirmPopBody = "답변이 거절되었습니다.";
};

const rejectAnswer = async () => {
  console.log("answer", answer.value);
  if (props.pSelectedQue && store.currentChannel) {
    await deleteExpertAnswer(props.pSelectedQue.msg_check_key);
    await store.updateChannelQuestionList(store.currentChannel.channel_key);
  }
};

// 음성인식으로 지식 추가하는 로직
const { initRecognition, startRecognition, stopRecognition } =
  useSpeechRecognition();
const { initTTS } = useTTS();
// const {initRecording, stopRecording,startSpeachRecording, startRecording } = useAudioRecorder()

const recognition: SpeechRecognition | null = null;
const selectedLanguage = ref(LANGUAGE.KOREAN.code);
const ttsOption = ref<number>(0);

// const startSpeechRecognition = async () => {
//   // await initRecording();
//   ttsOption.value = 1;
//   console.log("음성인식 시작");
//   await initRecognition(selectedLanguage.value);
//   await initTTS();
//   startRecognition({
//     onResult(result) {
//       answer.value = result;
//       console.log("음성인식 완료");
//       stopRecognition();
//       ttsOption.value = 0;
//     },
//     async onError(e) {
//       console.error("음성 인식 오류:", e.error);
//       ttsOption.value = 0;
//     }
//   });
//   // startRecording()
//   // startSpeachRecording();
// };
const startSpeechRecognition = async () => {
  ttsOption.value = 1;
  console.log("음성인식 시작");

  await initRecognition(selectedLanguage.value);
  await initTTS();

  startRecognition({
    onResult(result) {
      answer.value += (answer.value ? " " : "") + result; // 기존 값 뒤에 추가
      console.log("음성인식 완료");
      stopRecognition();
      ttsOption.value = 0;
    },
    async onError(e) {
      console.error("음성 인식 오류:", e.error);
      ttsOption.value = 0;
    }
  });
};

const stopSpeechRecognition = () => {
  stopRecognition();
  ttsOption.value = 0;
};

onMounted(() => {
  // popStore.confirmCloseParentPop = props.pClosePop;
  popStore.closeConfirmAskPopLayer();
});
</script>

<template>
  <CommonPopLayer :pPopTitle="popTitle" :pDisableOutsideClick="true">
    <template #body>
      <div class="answerQuestionWrap flexJustify">
        <div class="w100P">
          <div style="display: flex; justify-content: space-between">
            <p class="title fontBg fontBold">받은 질문</p>
            <div class="creDate fontSm">
              {{
                elapsedTime(
                  pSelectedQue?.question?.cre_date ?? new Date().toISOString()
                )
              }}
              (1일 지연됨)
            </div>
          </div>
          <div class="card w100P backShadow">
            <div class="sendInfo w100P">
              <div class="top flexAlign" style="padding: 10px">
                <div>
                  <img
                    class="profileImg"
                    alt="profile"
                    src="/src/assets/profile.png"
                  />
                  <span class="fontMd">{{
                    pSelectedQue?.question_user?.nick ?? ""
                  }}</span>
                </div>
                <!-- <div class="creDate fontSm">
                  {{
                    elapsedTime(
                      pSelectedQue?.question?.cre_date ??
                        new Date().toISOString()
                    )
                  }}
                  (1일 지연됨)
                </div> -->
              </div>
              <div class="body fontMd">
                {{ pSelectedQue?.question?.body ?? "" }}
              </div>
              <div
                style="text-align: right; padding: 5px 10px"
                class="fontSm cursorP"
                role="button"
                tabindex="0"
                @click="openAIAnswer"
                @keydown.enter="openAIAnswer"
              >
                AI답변 펼치기
              </div>
              <DialogRoot v-model:open="popStore.confirmAskPopYn">
                <confirmAskPop />
              </DialogRoot>
            </div>
            <div v-if="showAIAnswerYn" class="AIAnswerArea fontMd">
              <span>AI답변</span>
              <p>
                {{ pSelectedQue?.msg_1?.body ?? "" }}
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="addText flexJustify">
          <span class="fontBold fontBg title" style="margin-bottom: -15px"
            >지식 추가 옵션</span
          >
        </div>
        <label class="radio-container">
          <input type="radio" v-model="selected" value="once" />
          <span class="checkmark"></span>
          단일 지식 : 입력 지식에 한가지 주제의 지식이 들어가있습니다.
        </label>
        <label class="radio-container">
          <input type="radio" v-model="selected" value="multi" />
          <span class="checkmark"></span>
          다중 지식 : 입력 지식에 여러가지 주제의 지식이 들어가있습니다.
        </label> -->
        <div class="w100P">
          <p v-if="ttsOption === 0" class="title fontBg fontBold addKnowButton">
            전문가 답변<button @click="startSpeechRecognition">
              <SpeakYesIcon1 /> 음성입력
            </button>
          </p>
          <p
            v-else-if="ttsOption === 1"
            class="title fontBg fontBold addKnowButton"
          >
            전문가 답변<button
              class="recordingBtn"
              @click="stopSpeechRecognition"
            >
              <HourGlassIcon /> 인식 중
            </button>
          </p>
          <div class="answerArea fontMd">
            <textarea v-model="answer" class="fontMd" />
          </div>
        </div>
      </div>
    </template>

    <template #actions>
      <div class="button-group">
        <button class="save-button" @click="answerToQue(saveAnswer)">
          등록
        </button>
        <button
          class="close-button"
          aria-label="Reject"
          @click="reejctToQue(rejectAnswer)"
        >
          거절
        </button>
        <DialogClose class="close-button" aria-label="Close">
          취소
        </DialogClose>
      </div>
    </template>
  </CommonPopLayer>
  <div v-if="isPageLoading" class="spinner-overlay">
    <div class="spinner"></div>
    <p>저장 중입니다...</p>
  </div>
</template>

<style lang="scss" scoped>
.answerQuestionWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;

  & .title {
    padding-bottom: 10px;
    margin: 0;
  }

  & .card {
    position: relative;
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0 3px 4px 2px rgba(148, 148, 148, 0.3);

    &::before {
      content: "";
      position: absolute;
      left: -10px;
      top: 32px;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid #fff;
      transform: translateY(-50%);
      filter: drop-shadow(-1px 2px 1px rgba(0, 0, 0, 0.2));
    }
  }

  & .sendInfo {
    width: 100%;
    display: flex;
    flex-direction: column;

    & .top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      & .profileImg {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        object-fit: cover;
      }
    }

    & .body {
      width: 100%;
      line-height: 26px;
      padding: 10px;
    }
  }

  & .AIAnswerArea {
    background-color: #fff;
    border-top: 1px dashed #fff;
    padding: 10px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    & span {
      font-weight: bold;
      color: #fff;
      margin-right: 10px;
    }

    & p {
      margin: 0;
      color: #333;
    }
  }

  & .answerArea {
    position: relative;
    margin-top: 15px;
    background-color: #fff;
    border-radius: 15px;
    margin-left: 10px;
    box-shadow: 1px 3px 4px 2px rgba(148, 148, 148, 0.3);

    &::before {
      content: "";
      position: absolute;
      right: -15px;
      top: 20px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 15px solid #fff;
      transform: rotate(90deg);
      filter: drop-shadow(1px -2px 1px rgba(0, 0, 0, 0.2));
    }

    & textarea {
      width: 100%;
      min-height: 100px;
      border: none;
      padding: 20px;
      resize: vertical;
      background-color: #fff;
      color: #333;
      font-size: 0.95em;
      line-height: 1.6;
      transition: all 0.3s ease;

      &::placeholder {
        color: #8c8c8c;
      }

      // Custom scrollbar
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: #3eb697;
        border-radius: 18a498;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #18a498;
      }
    }
  }
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;

  button {
    padding: 8px 24px;
    border-radius: 4px;
    cursor: pointer;
    border: none;

    &.save-button {
      background-color: #51c2b8;
      color: white;
      font-weight: 600;
      &:hover {
        background-color: #18a498;
      }
    }

    &.close-button {
      background-color: #f5f5f5;
      font-weight: 600;
      &:hover {
        background-color: #ddd;
      }
    }
  }
}

// Recording animation
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.7;
  }
}

.recordingBtn {
  animation: pulse 1s infinite;
  background-color: #ff4d4d;
}

.btnWrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  & .cancel {
    margin-left: 10px;
  }
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #51c2b8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-overlay p {
  color: white;
  margin-top: 15px;
  font-size: 18px;
}
</style>
