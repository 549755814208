<!-- addAuthUSerPop -->
<script setup>
import { ref, defineProps, onMounted } from "vue";
import commonPopLayer from "../../../../popups/CommonPopLayer.vue";
import { useStore } from "../../../../stores/store";
import { elapsedYear } from "../../../../utils/date";
import {
  prepareAuthUser,
  getAuthCardList,
  joinChannel
} from "../../../../api/backend";
import { useQuasar } from "quasar";
import { watch } from "vue";

const store = useStore();
const $q = useQuasar();

const props = defineProps({
  pClosePop: Function,
  authCards: {
    type: Array,
    default: () => []
  },
  getChannelUserList: Function
});

const closePop = () => {
  userName.value = "";
  userId.value = "";
  studentId.value = "";
  academy.value = "";
  email.value = "";
  phoneEnc.value = "";
  phone4.value = "";
  selectedAuthCard.value = "";
  selectedAuth.value = "";
  props.pClosePop();
};

const userName = ref("");
const userId = ref("");
const studentId = ref("");
const academy = ref("");
const email = ref("");
const selectedAuth = ref("");
const selectedAuthCard = ref("");
const authCards = ref([...props.authCards]);
const phoneEnc = ref("");
const selectedAuthCardName = ref("");
const phone4 = ref("");

onMounted(async () => {
  userName.value = "";
  userId.value = "";
  studentId.value = "";
  academy.value = "";
  email.value = "";
  phoneEnc.value = "";
  phone4.value = "";
  selectedAuthCard.value = "";
  selectedAuth.value = "";

  selectedAuth.value = props.pSelectedUserInfo?.auth || "";
  selectedAuthCard.value = props.pSelectedUserInfo?.authcard_key || "";
  selectedAuthCardName.value = props.pSelectedUserInfo?.authcard_name || "";

  try {
    const param = {
      subinfo_yn: 1,
      authcard_key: null,
      channel_key: store.currentChannel?.channel_key || 0
    };
    const authCardList = await getAuthCardList(param);

    if (authCardList.length > 0) {
      const fetchedAuthCards = authCardList.map((item) => ({
        authcard_name: item.authcard.authcard_name,
        authcard_key: item.authcard.authcard_key,
        channel_key: item.authcard.channel_key,
        cre_date: item.authcard.cre_date
      }));

      authCards.value = [...props.authCards, ...fetchedAuthCards];
    }
  } catch (error) {
    console.error("카드 목록 가져오기 실패:", error);
  }
});

const updateUserAuth = async () => {
  // if (
  //   !userName.value ||
  //   !userId.value ||
  //   !studentId.value ||
  //   !email.value ||
  //   !phoneEnc.value ||
  //   !selectedAuth.value
  // ) {
  if (
    !userName.value ||
    !userId.value ||
    // !studentId.value ||
    // !email.value ||
    !phoneEnc.value ||
    !selectedAuth.value
  ) {
    $q.notify({
      message: "필수 입력란을 채워주세요.",
      color: "negative",
      timeout: 3000
    });
    return;
  }

  try {
    const queryParams = {
      channel_key: store.currentChannel?.channel_key || 0,
      auth_type: selectedAuth.value,
      user_id: userId.value,
      user_name: userName.value,
      // student_id: studentId.value, // 확인해봐야함.
      // email: email.value,
      phone_enc: phoneEnc.value,
      phone4: phone4.value,
      authcard_key: selectedAuthCard.value || 0
    };

    console.log("🚀 API 요청 queryParams:", queryParams);

    const response = await prepareAuthUser(queryParams);

    if (response == null) {
      $q.notify({
        message: "멤버가 추가되었습니다.",
        color: "positive",
        timeout: 3000
      });
      props.pClosePop();
    } else {
      $q.notify({
        message: "멤버 추가에 실패하였습니다.",
        color: "negative",
        timeout: 3000
      });
    }
  } catch (error) {
    $q.notify({
      message: "멤버 추가 중 오류가 발생했습니다.",
      color: "negative",
      timeout: 3000
    });
  }
  // const params = {
  //   auth_user: {
  //     user_key: response,
  //     channel_key: store.currentChannel?.channel_key || 0,
  //     auth_type: selectedAuth.value,
  //     grant_user_key: 0,
  //     grant_date: new Date().toISOString(),
  //     authcard_key: selectedAuthCard.value || 0
  //   },
  //   secure_str4: store.currentChannel?.secure_str4
  // };

  // await joinChannel(params);
  // props.pClosePop();
  await props.getChannelUserList();
  // closePop();
};

watch(phoneEnc, (newVal) => {
  if (newVal) {
    const cleaned = newVal.replace(/-/g, "");
    phoneEnc.value = cleaned;
    phone4.value = cleaned.slice(-4);
  }
});
</script>

<template>
  <div style="max-width: 450px !important">
    <commonPopLayer pPopTitle="멤버 추가">
      <template #body>
        <div class="channelDetailTop flexAlign">
          <div class="channelDetailInfos">
            <div class="channelName flexAlign fontMd">
              <div class="w100P flexAlign">
                <div class="fontMd inputWrapBox" style="width: 100%">
                  <div class="inputWrap">
                    <p class="fontBold">이름 *</p>
                    <input class="inputSelect" type="text" v-model="userName" />
                  </div>
                  <div class="inputWrap">
                    <p class="fontBold">ID *</p>
                    <input class="inputSelect" type="text" v-model="userId" />
                  </div>
                  <div class="inputWrap">
                    <p class="fontBold">사번(학번)</p>
                    <input
                      class="inputSelect"
                      type="text"
                      v-model="studentId"
                    />
                  </div>
                  <div class="inputWrap">
                    <p class="fontBold">이메일</p>
                    <input class="inputSelect" type="email" v-model="email" />
                  </div>
                  <div class="inputWrap">
                    <p class="fontBold">휴대폰 번호 *</p>
                    <input class="inputSelect" type="text" v-model="phoneEnc" />
                  </div>
                  <div class="inputWrap">
                    <p class="fontBold">채널 권한 *</p>
                    <select class="inputSelect" v-model="selectedAuth">
                      <option value="">선택 없음</option>
                      <option value="A">관리자</option>
                      <option value="E">전문가</option>
                      <option value="M">직원</option>
                      <option value="S">구독자</option>
                    </select>
                  </div>
                  <div class="inputWrap">
                    <p class="fontBold">권한 카드 *</p>
                    <select class="inputSelect" v-model="selectedAuthCard">
                      <option value="">선택 없음</option>
                      <option
                        v-for="card in authCards"
                        :key="card.authcard_key"
                        :value="card.authcard_key"
                      >
                        {{ card.authcard_name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #actions>
        <div class="popup-footer">
          <button type="button" class="submit-btn" @click="updateUserAuth">
            저장
          </button>
          <button type="button" class="cancel-btn" @click="closePop">
            닫기
          </button>
        </div>
      </template>
    </commonPopLayer>
  </div>
</template>

<style lang="scss" scoped>
.channelDetailTop {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.channelDetailInfos {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inputWrapBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inputWrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 0.875rem;
    font-weight: 500;
    color: #333;
  }
}

.inputSelect {
  width: 60%;
  height: 36px;
  padding: 0 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background-color: #fff;
  font-size: 0.875rem;
  color: #1a202c;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
  }

  &:hover {
    border-color: #cbd5e0;
  }
}

.popup-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 12px;
}

.popup-footer button {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.submit-btn {
  background-color: #51c2b8;
  color: white;
  font-weight: 600;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.submit-btn:hover {
  background-color: #18a498;
}

.cancel-btn {
  background-color: #f5f5f5;
  font-weight: 600;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: #ddd;
}
</style>
