<!-- ConfirmAskPop.vue -->
<script setup lang="ts">
import { useQuasar } from "quasar";
import { usePopupStore } from "../stores/popups";
import CommonPopLayer from "./CommonPopLayer.vue";
import { computed } from "vue";
import { watch } from "vue";

const $q = useQuasar();
const popStore = usePopupStore();

const popTitle = computed(() => {
  // confirmAskPopBody에 "승인"이라는 단어가 포함되어 있으면 "지식 승인"
  // "반려"라는 단어가 포함되어 있으면 "지식 반려"를 반환
  if (popStore.confirmAskPopBody.includes("승인")) {
    return "지식 승인";
  } else if (popStore.confirmAskPopBody.includes("반려")) {
    return "지식 반려";
  }
  return "확인";
});

// const popTitle = "확인";

const handleButtonClick = async (value: string) => {
  console.log("value", value);
  if (value === "confirm") {
    if (popStore.confirmAskOK) {
      await popStore.confirmAskOK();
      console.log("confirmAskOK 실행 완료");
    }
    popStore.confirm();
    $q.notify(popStore.confirmPopBody);
    // popStore.confirmCloseParentPop();
  } else if (value === "cancel") {
    popStore.cancel();
  }
};

watch(
  () => popStore.confirmAskPopYn,
  (newVal) => {
    console.log("confirmAskPopYn 변경됨:", newVal);
  }
);
</script>

<template>
  <template v-if="popStore.confirmAskPopYn">
    <!-- 배경 오버레이 -->
    <div class="backgroundLayout"></div>

    <!-- 팝업 컨텐츠 -->
    <div class="folderAddpopup">
      <div class="popup-content">
        <div class="popup-header">
          <p style="font-weight: bold; font-size: 20px">{{ popTitle }}</p>
          <button @click="popStore.closeConfirmAskPopLayer">X</button>
        </div>
        <div class="popup-body">
          <div class="textCenter fontBg">{{ popStore.confirmAskPopBody }}</div>
        </div>
        <div class="popup-footer">
          <button
            v-for="(btn, index) in popStore.confirmAskButtons"
            :key="btn.value"
            @click="handleButtonClick(btn.value)"
            :class="[index === 0 ? 'submit-btn' : 'cancel-btn']"
          >
            {{ btn.name }}
          </button>
        </div>
      </div>
    </div>
  </template>
</template>

<style lang="scss" scoped>
.backgroundLayout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.folderAddpopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 200;
}

.popup-content {
  display: flex;
  flex-direction: column;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 16px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;

  button {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
}

.popup-body {
  margin-bottom: 20px;

  .textCenter {
    text-align: center;
    color: #333;
    padding: 10px 0;
  }

  .fontBg {
    font-size: 16px;
  }
}

.popup-footer {
  display: flex;
  justify-content: center;
  gap: 12px;

  button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    padding: 10px 20px;
  }

  .submit-btn {
    background-color: #51c2b8;
    color: white;

    &:hover {
      background-color: #18a498;
    }
  }

  .cancel-btn {
    background-color: #f5f5f5;

    &:hover {
      background-color: #ddd;
    }
  }
}
</style>
