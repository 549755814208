<script setup lang="ts">
import { elapsedTime } from "../../../utils/date";
import type { MsgCheck } from "../../../api/types";
import { computed } from "vue";
import { ref } from "vue";
import { deleteExpertAnswer } from "../../../api/backend";
import { storeToRefs } from "pinia";
import { useStore } from "../../../stores/store";
import { usePopupStore } from "../../../stores/popups";
import { useQuasar } from "quasar";

const props = defineProps<{ pAsks: MsgCheck[] }>();

const emits = defineEmits(["openAnswerQuePop"]);
const openAnswerQuePop = (ask: MsgCheck) => {
  emits("openAnswerQuePop", ask);
};

const selectedItems = ref<MsgCheck[]>([]);

// 전체 선택 상태를 computed로 관리
const allSelected = computed({
  get: () => selectedItems.value.length === props.pAsks.length, // 전체가 선택되었는지 확인
  set: (value: boolean) => {
    if (value) {
      selectedItems.value = [...props.pAsks]; // 모든 항목을 선택
    } else {
      selectedItems.value = []; // 전체 해제
    }
  }
});

// 특정 항목이 선택되었는지 확인하는 함수
const isChecked = (item: MsgCheck) => selectedItems.value.includes(item);

// 개별 체크박스 클릭 시 호출
const toggleItemSelection = (item: MsgCheck) => {
  const index = selectedItems.value.findIndex(
    // (i) => i.question.msg_key === item.question.msg_key
    (i) => i.question.msg_key === item.question.msg_key
  );

  if (index > -1) {
    selectedItems.value.splice(index, 1); // 이미 선택된 항목이면 제거
  } else {
    selectedItems.value.push(item); // 선택되지 않은 항목이면 추가
  }
};

const store = useStore();
const $q = useQuasar();
const popStore = usePopupStore();

// ===== 전체 삭제 함수
// const reejctToQue = (callback: () => void) => {
//   popStore.openConfirmAskPopLayer();
//   popStore.confirmAskPopBody = "정말로 답변을 거절하시겠습니까?";
//   popStore.confirmAskOK = callback;
//   popStore.confirmPopBody = "답변이 거절되었습니다.";
// };

const rejectAllAnswer = async () => {
  if (selectedItems.value.length < 1) {
    $q.notify({
      message: "선택된 답변이 없습니다.",
      color: "negative"
    });
    return;
  }

  // selectedItems에서 msg_check_key 값만 추출하여 삭제
  for (const item of selectedItems.value) {
    if (item.msg_check_key) {
      await deleteExpertAnswer(item.msg_check_key);
    }
  }

  // 전체 목록 업데이트
  await store.updateChannelQuestionList(store.currentChannel?.channel_key);
  $q.notify({
    message: "답변이 거절되었습니다.",
    color: "positive"
  });

  selectedItems.value = [];
};
</script>

<template>
  <div v-if="props.pAsks.length > 0" class="checkAskArea">
    <div class="allCheckBoxWrap">
      <div style="display: flex; gap: 10px">
        <input
          class="allKnowCheckInput"
          type="checkbox"
          v-model="allSelected"
        />
        <label for="selectAll">전체 선택</label>
        <p>{{ selectedItems.length }}개의 지식이 선택되었습니다.</p>
      </div>
      <div style="display: flex; gap: 10px">
        <!-- <button @click="reejctToQue(rejectAllAnswer)">거절</button> -->
        <button class="rejectBtn" @click="rejectAllAnswer">거절</button>
      </div>
    </div>
    <ul>
      <div class="questionBoxWrap" v-for="(each, index) in props.pAsks">
        <!-- 개별 체크박스 -->
        <input
          class="knowCheckInput"
          type="checkbox"
          :checked="isChecked(each)"
          @change="toggleItemSelection(each)"
        />
        <li
          class="eachAsk fontMd flexAlign"
          :key="index"
          @click="openAnswerQuePop(each)"
        >
          <div class="eachBox">
            <div class="each">
              {{ each.question?.body ?? "" }}
            </div>
            <span class="eachDate fontSm">{{
              elapsedTime(each.question?.cre_date)
            }}</span>
          </div>
          <!-- <button class="answerBtn" type="button" @click="openAnswerQuePop(each)"> -->
          <button class="answerBtn" type="button">답변하기</button>
        </li>
      </div>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.allCheckBoxWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.allCheckBoxWrap label {
  font-weight: bold;
  font-size: 15px;
}

.allKnowCheckInput {
  flex-shrink: 0;
  width: 20px !important;
  height: 20px !important;
  border: 2px solid #ccc !important;
  border-radius: 4px !important;
  background-color: #f9f9f9 !important;
  transition: all 0.1s ease !important; /* 부드러운 전환 효과 */
  appearance: none !important;
  cursor: pointer !important;
}

.allKnowCheckInput:checked {
  background-color: #4caf50 !important;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"%3E%3Cpath d="M20.285 6.738l-11.082 11.195c-.369.369-.963.369-1.332 0l-4.156-4.156a.941.941 0 0 1 0-1.332l1.332-1.332c.369-.369.963-.369 1.332 0l2.824 2.823 9.75-9.75c.369-.369.963-.369 1.332 0l1.332 1.332c.369.369.369.963 0 1.332z"/%3E%3C/svg%3E') !important;
  border-color: #4caf50 !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 60% !important;
}

.allKnowCheckInput:hover {
  background-color: rgb(205, 240, 205) !important; /* 체크 시 녹색 배경 */
  color: black !important;
}

.knowCheckInput {
  width: 20px !important;
  height: 20px !important;
  border: 2px solid #ccc !important; /* 부드러운 테두리 */
  border-radius: 4px !important; /* 살짝 둥근 모서리 */
  background-color: #f9f9f9 !important; /* 밝은 배경 */
  transition: all 0.1s ease !important; /* 부드러운 전환 효과 */
  appearance: none !important; /* 기본 체크박스 제거 */
  cursor: pointer !important; /* 클릭 가능한 손 모양 커서 */
}

.knowCheckInput:checked {
  background-color: #4caf50 !important; /* 체크 시 녹색 배경 */
  border-color: #4caf50 !important; /* 체크 시 테두리 색 변경 */
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"%3E%3Cpath d="M20.285 6.738l-11.082 11.195c-.369.369-.963.369-1.332 0l-4.156-4.156a.941.941 0 0 1 0-1.332l1.332-1.332c.369-.369.963-.369 1.332 0l2.824 2.823 9.75-9.75c.369-.369.963-.369 1.332 0l1.332 1.332c.369.369.369.963 0 1.332z"/%3E%3C/svg%3E') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 60% !important; /* 체크 아이콘 크기 */
}

.knowCheckInput:hover {
  background-color: rgb(205, 240, 205) !important; /* 체크 시 녹색 배경 */

  color: black !important;
}

.rejectBtn {
  /* font-weight: bold; */
}

.questionBoxWrap {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.checkAskArea {
  width: 100%;
  /* padding: 15px; */

  & ul {
    margin: 0;
    display: grid;
    gap: 10px;

    & .eachAsk {
      flex: 1; /* li가 남은 공간을 차지하도록 설정 */
      align-items: center; /* 텍스트와 버튼을 같은 높이로 정렬 */
      margin: 5px 0px;
      display: flex;
      /* align-items: flex-start; */
      border-bottom: 1px solid #ddd; /* 구분선 색상 */
      padding: 15px 20px;
      background-color: #ffffff; /* 말풍선 배경 색상 */
      border-radius: 8px;
      border: 1px solid #e0e0e0;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      /* position: relative; */

      & .eachBox {
        display: flex;
        align-items: flex-start;
        flex: 1;
        gap: 15px;
        flex-direction: column;

        & .each {
          display: block;
          padding: 10px 15px;
          background-color: #ededed; /* 말풍선 색상 */
          border-radius: 15px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 그림자 */
          font-size: 16px;
          line-height: 1.5;
          position: relative;
          word-wrap: break-word;

          &::before {
            position: absolute;
            content: "";
            position: absolute;
            top: 50%;
            left: -20px; /* 꼬리 길이 조정 */
            width: 0;
            height: 0;
            border-width: 12px; /* 꼬리 크기 조정 */
            border-style: solid;
            border-color: transparent #ededed transparent transparent; /* 꼬리 색상 */
            transform: translateY(-50%);
          }
        }

        & .eachDate {
          color: #777;
          font-size: 14px;
          margin-top: 5px;
        }
      }

      & .answerBtn {
        min-width: 60px;
        padding: 6px 12px;
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        background-color: #747474; /* 버튼 색상 */
        border: none;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        transition:
          background-color 0.3s,
          transform 0.2s;
        text-align: center;
        margin-left: auto; /* 오른쪽 정렬 */
        align-self: flex-end;

        &:hover {
          background-color: #3f3f3f; /* 호버 시 색상 */
          transform: scale(1.01); /* 클릭 효과 */
          font-weight: bold;
        }

        &:focus {
          outline: none; /* 포커스 시 테두리 제거 */
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .checkAskArea {
    /* padding: 10px; */

    & ul {
      & .eachAsk {
        flex-direction: column;
        align-items: flex-start;
        padding: 15px 15px;

        & .answerBtn {
          width: auto;
          text-align: center;
          padding: 6px 10px;
          font-size: 14px;
          margin-left: 0; /* 모바일에서 버튼 위치 조정 */
          align-self: flex-end;
        }

        & .eachBox {
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;

          & .each {
            width: 100%;
            padding: 12px;
            font-size: 15px;
            position: unset;
            &::before {
              display: none;
            }
          }

          & .eachDate {
            font-size: 13px;
          }
        }

        & button {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
