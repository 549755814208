<script setup lang="ts">
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogTitle
} from "radix-vue";
import CloseIcon from "@fluentui/svg-icons/icons/dismiss_24_regular.svg";
// import { sidebarKey } from "../components/common/sidebar/SidebarRoot.vue";

import { ref, inject } from "vue";

// const sidebarContext = inject(sidebarKey);

const props = defineProps({
  pPopTitle: {
    type: String,
    default: "애니톡"
  },
  pPopupInfos: {
    type: Object,
    require: false
  },
  pSetDefault: Function
});

const commonPopInfo = ref({
  title: props.pPopTitle,
  mainBtnColor: props.pPopupInfos?.mainColor
    ? props.pPopupInfos.mainColor
    : "blue",
  subBtnColor: props.pPopupInfos?.subColor ? props.pPopupInfos.subColor : "gray"
});

const setDefault = () => {
  if (props.pSetDefault) {
    props.pSetDefault();
  }
};
</script>

<template>
  <DialogPortal>
    <DialogOverlay class="ReferDialogOverlay" />
    <DialogContent
      class="DialogContent ReferCommonPopWrap"
      :disableOutsidePointerEvents="false"
    >
      <DialogTitle class="DialogTitle commonPopHeader">
        <span>{{ commonPopInfo.title }}</span>
        <DialogClose as-child @click="setDefault">
          <CloseIcon class="cursorP" />
        </DialogClose>
      </DialogTitle>
      <div class="popBodyFooterWrap">
        <DialogDescription class="DialogDescription commonPopBody">
          <slot name="body"></slot>
        </DialogDescription>

        <div class="commonPopFooter">
          <slot name="actions">
            <button :style="commonPopInfo.mainBtnColor">확인</button>
            <button :style="commonPopInfo.subBtnColor">취소</button>
          </slot>
        </div>
      </div>
    </DialogContent>
  </DialogPortal>

  <!-- <div class="commonPopWrap">
      <div class="commonPopHeader">
        <span>{{ commonPopInfo.title }}</span>
        <span></span>
      </div>

      <div class="commonPopBody">
        <slot name="body"></slot>
      </div> -->
</template>

<style lang="scss" scoped>
/* 오버레이(배경) 스타일 */
.ReferDialogOverlay {
  width: 100vw !important;
  height: 100vh !important;
  position: fixed !important;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2) !important;
  z-index: 1000 !important;
}

/* 팝업 콘텐츠 스타일 */
:deep(.DialogContent),
.ReferCommonPopWrap {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 1001 !important;
  background-color: white !important;
  border-radius: 10px !important;
  padding: 20px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
  max-width: 900px !important;
  width: 90% !important;
}

/* 기본 팝업 스타일 오버라이드 - 모바일 환경 */
@media (max-width: 768px) {
  :deep(.DialogContent),
  :deep(.ReferCommonPopWrap) {
    max-width: 95vw !important;
    width: 95vw !important;
    height: auto !important;
    max-height: 90vh !important;
    padding: 15px !important;
    border-radius: 8px !important;
    overflow: hidden !important;
  }

  :deep(.commonPopLayer) {
    max-width: 100% !important;
    width: 100% !important;
    height: auto !important;
    max-height: 90vh !important;
  }

  :deep(.commonPopBody) {
    max-height: calc(90vh - 110px) !important;
    overflow-y: hidden !important;
    padding: 10px !important;
  }

  :deep(.commonPopHeader) {
    padding: 10px 10px 16px 10px !important;
  }

  :deep(.commonPopHeader h1) {
    font-size: 16px !important;
  }

  :deep(.popupFooter) {
    padding: 10px !important;
  }
}

/* 소형 모바일 화면 (480px 이하) */
@media (max-width: 480px) {
  :deep(.DialogContent),
  :deep(.ReferCommonPopWrap) {
    max-width: 98vw !important;
    width: 98vw !important;
    padding: 10px !important;
  }

  :deep(.commonPopHeader) {
    padding: 8px 8px 12px 8px !important;
  }

  :deep(.commonPopHeader h1) {
    font-size: 15px !important;
  }

  :deep(.commonPopBody) {
    padding: 8px !important;
  }

  :deep(.popupFooter) {
    padding: 8px !important;
  }

  :deep(.cancel) {
    padding: 6px 12px !important;
    font-size: 14px !important;
  }
}

/* 매우 작은 화면 (360px 이하) */
@media (max-width: 360px) {
  :deep(.DialogContent),
  :deep(.ReferCommonPopWrap) {
    padding: 8px !important;
  }

  :deep(.commonPopHeader) {
    padding: 6px 6px 10px 6px !important;
  }

  :deep(.commonPopHeader h1) {
    font-size: 14px !important;
  }

  :deep(.commonPopBody) {
    padding: 6px !important;
    max-height: calc(90vh - 100px) !important;
  }
}

/* 가로 모드(landscape) 스마트폰 */
@media (max-height: 480px) and (orientation: landscape) {
  :deep(.DialogContent),
  :deep(.ReferCommonPopWrap) {
    max-height: 96vh !important;
  }

  :deep(.commonPopBody) {
    max-height: calc(96vh - 100px) !important;
  }
}
</style>
