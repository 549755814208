<!-- AuthDetailPop.vue -->
<script setup lang="ts">
import { ref, watch } from "vue";
import AddDetailKnowAuthPop from "./AddDetailKnowAuthPop.vue";
import SettingIcon from "@fluentui/svg-icons/icons/edit_settings_24_regular.svg";
import { deleteAuthCard, updateAuthCard } from "../../../../api/backend";
import { useQuasar } from "quasar";
import DeleteConfirmPop from "./DeleteConfirmPop.vue";

interface AuthCardType {
  authcard_name: string;
  authcard_key: number;
  channel_key: number;
  folder_key_list?: number[];
  cre_date?: string;
  folder_list?: {
    folder_name: string;
    kms_folder_key: number;
    channel_key: number;
  }[];
}

interface SavedFolder {
  folder_name: string;
  kms_folder_key: number;
}

const props = defineProps({
  open: {
    type: Boolean,
    required: true
  },
  pClosePop: {
    type: Function,
    required: true
  },
  authData: {
    type: Object as () => AuthCardType,
    required: true
  }
});

const emits = defineEmits(["update:open", "save", "delete"]);

const showDetailPopup = ref(false);
const showDeleteConfirm = ref(false); // 삭제 확인 팝업 상태
const authCardName = ref("");
const authorizedItems = ref<SavedFolder[]>([]);
const showError = ref(false);
const errorMessage = ref("");
const $q = useQuasar();

// authData가 변경될 때마다 데이터 초기화
watch(
  () => props.authData,
  (newAuthData) => {
    if (newAuthData) {
      authCardName.value = newAuthData.authcard_name || "";
      // if (newAuthData.folder_key_list) {
      //   // authorizedItems.value = newAuthData.folder_key_list.map((key) =>
      //   //   key.toString()
      //   // );
      //   authorizedItems.value = newAuthData.folder_key_list
      //     .filter((key) => key !== null)
      //     .map((key) => ({
      //       folder_key: key,
      //       folder_name: key.toString() // 폴더 이름이 없는 경우 key를 이름으로 사용
      //     }));
      // }
      if (newAuthData.folder_list) {
        // folder_list가 있는 경우 그대로 사용
        authorizedItems.value = newAuthData.folder_list.map((folder) => ({
          folder_name: folder.folder_name,
          kms_folder_key: folder.kms_folder_key
        }));
      } else if (newAuthData.folder_key_list) {
        // folder_list가 없는 경우 기존 로직 유지
        authorizedItems.value = newAuthData.folder_key_list
          .filter((key) => key !== null)
          .map((key) => ({
            kms_folder_key: key,
            folder_name: key.toString()
          }));
      }
    }
  },
  { immediate: true }
);

const handleClose = () => {
  showError.value = false;
  emits("update:open", false);
  if (props.pClosePop) props.pClosePop();
};

const handleUpdate = async () => {
  if (!authCardName.value.trim()) {
    showError.value = true;
    errorMessage.value = "권한명을 입력해주세요.";
    $q.notify({
      message: "권한명을 입력해주세요",
      color: "negative"
    });
    return;
  }

  try {
    const updatedData = {
      authcard_key: props.authData.authcard_key,
      authcard_name: authCardName.value.trim(),
      channel_key: props.authData.channel_key,
      // folder_key_list: authorizedItems.value.map(Number),
      folder_key_list: authorizedItems.value.map((item) => item.kms_folder_key),
      delete_yn: 0
    };

    await updateAuthCard(updatedData);
    showError.value = false;
    emits("save", updatedData);
    handleClose();
    $q.notify({
      message: "권한 카드 수정에 성공하였습니다.",
      color: "positive"
    });
    // window.location.reload();
  } catch (error) {
    console.error("권한 카드 수정 실패:", error);
    showError.value = true;
    errorMessage.value = "권한 카드 수정에 실패했습니다.";
    $q.notify({
      message: "권한 카드 수정에 실패했습니다.",
      color: "negative"
    });
  }
};

const handleDelete = () => {
  showDeleteConfirm.value = true;
};

// 삭제 확인 시 실행
const confirmDelete = async () => {
  try {
    // API 호출로 권한 카드 삭제
    await deleteAuthCard(props.authData.authcard_key);

    // 팝업 닫기 및 삭제 이벤트 발생
    showDeleteConfirm.value = false;
    emits("delete", props.authData.authcard_key);
    handleClose();

    $q.notify({
      message: "권한 카드가 삭제되었습니다.",
      color: "positive"
    });
  } catch (error) {
    console.error("권한 카드 삭제 실패:", error);
    $q.notify({
      message: "권한 카드 삭제에 실패했습니다.",
      color: "negative"
    });
  }
};

// 삭제 취소 시 실행
const cancelDelete = () => {
  showDeleteConfirm.value = false;
};

const handleDetailSave = (items: SavedFolder[]) => {
  authorizedItems.value = items;
};

const handleDetailClose = () => {
  showDetailPopup.value = false;
};
</script>

<template>
  <div v-if="open" class="popup-overlay">
    <div class="popup-content">
      <div class="popup-header">
        <div class="header-content">
          <h2>권한 카드 수정</h2>
        </div>
      </div>

      <div class="popup-body">
        <div class="input-column">
          <div class="input-group">
            <label>권한 카드명</label>
            <div class="input-wrapper">
              <input
                v-model="authCardName"
                type="text"
                class="auth-input"
                placeholder="권한명을 입력하세요"
              />
              <!-- <div v-if="showError" class="error-message">
                {{ errorMessage }}
              </div> -->
            </div>
          </div>

          <div class="knowledge-section-header">
            <h3>권한 지식</h3>
            <button class="setting-button" @click="showDetailPopup = true">
              <SettingIcon />
            </button>
          </div>

          <div class="knowledge-section">
            <div class="auth-list">
              <div
                v-for="item in authorizedItems"
                :key="item.kms_folder_key"
                class="folder-tag-container"
              >
                <div class="folder-tag">
                  {{ item.folder_name }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="button-group">
          <button class="save-button" @click="handleUpdate">저장</button>
          <button class="delete-button" @click="handleDelete">삭제</button>
          <button class="close-button" @click="handleClose">닫기</button>
        </div>
      </div>
    </div>
  </div>

  <AddDetailKnowAuthPop
    v-model:open="showDetailPopup"
    :authorizedItems="authorizedItems"
    @save="handleDetailSave"
    @close="handleDetailClose"
  />

  <!-- 삭제 확인 팝업 -->
  <DeleteConfirmPop
    :open="showDeleteConfirm"
    @update:open="showDeleteConfirm = $event"
    :pClosePop="cancelDelete"
    @confirm="confirmDelete"
  />
</template>

<style lang="scss" scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  border-radius: 8px;
  width: 400px;
  height: 480px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.popup-header {
  padding: 16px;
  border-bottom: 1px solid #eee;

  .header-content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    h2 {
      margin: 0;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

.popup-body {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.input-column {
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex: 1;
  margin-bottom: 20px;
}

.knowledge-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 30px !important;
  h3 {
    margin: 0;
    font-size: 14px;
    color: #666;
  }
}

.setting-button {
  background: none !important;
  border: none !important;
  padding: 4px;
  cursor: pointer;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;

  &:hover {
    color: #333;
  }
}

.input-group {
  display: flex;
  align-items: center;
  gap: 8px;

  label {
    width: 70px;
    text-align: left;
  }

  .auth-input {
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 6px 12px;
    min-width: 0;
    height: 32px;

    &:focus {
      outline: none;
      border-color: #454545;
    }

    &.error {
      border-color: #ff4444;
    }
  }
}

.knowledge-section {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  max-height: 200px;
}

.auth-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-content: flex-start;
  overflow-y: auto; // 스크롤 추가
  height: 100%;

  .folder-tag-container {
    padding: 4px;
  }

  .folder-tag {
    display: inline-flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 4px 12px;
    font-size: 13px;
  }
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;

  button {
    padding: 8px 24px;
    border-radius: 4px;
    cursor: pointer;
    border: none;

    &.save-button {
      background-color: #51c2b8;
      color: white;
      font-weight: 600;
      &:hover {
        background-color: #18a498;
      }
    }

    &.delete-button {
      background-color: #f5f5f5;
      font-weight: 600;
      &:hover {
        background-color: #ddd;
      }
    }

    &.close-button {
      background-color: #f5f5f5;
      font-weight: 600;
      &:hover {
        background-color: #ddd;
      }
    }
  }
}

.error-message {
  color: #ff4444;
  font-size: 12px;
  margin-top: 4px;
}
</style>
