<script setup lang="ts">
import { ref, onMounted, computed, watch } from "vue";
import { useQuasar } from "quasar";
import router from "../../routers";

import { parse } from "best-effort-json-parser";
import { useSpeechRecognition, useTTS } from "../main/sound";
import {
  LANGUAGE,
  ReqResponseStream,
  createMsg,
  requestKmsAnswerStream
} from "../../api/backend";
import { useStore } from "../../stores/store";
import { useTokenStore } from "../../stores/token";

// import calmVideo4scsc from "../../assets/2C.mp4";
// import calmVideo4sc from "../../assets/4C.mp4";
import calmVideo5sc from "../../assets/calmVideo5sc.mp4";
import calmVideo10sc from "../../assets/calmVideo10sc.mp4";

import talkVideo5sc from "../../assets/talkVideo5sc.mp4";
import talkVideo4sc from "../../assets/talkVideo4sc.mp4";
import talkVideo3sc from "../../assets/talkVideo3sc.mp4";
import talkVideo2sc from "../../assets/talkVideo2sc.mp4";
import talkVideo1sc from "../../assets/talkVideo1sc.mp4";

import morderPop from "../../assets/answerPopImg.png";
import darkPop from "../../assets/darkPop.png";
import cartoonPop from "../../assets/cartoonPop.png";
import newPop from "../../assets/newPop.png";

import standImg from "../../assets/standImg.png";
import chastImg from "../../assets/chastImg.png";

// import museumImage from "../../assets/museum.png";
import gyeongbokgungImage from "../../assets/gyeongbokgung.jpeg";
// import hotelImage from "../../assets/hotel.jpg";
// import parkImage from "../../assets/park.jpg";

import hotelImage from "../../assets/hotel2.jpg";
// import gyeongbokgungImage from "../../assets/gyeungbokgung2.jpg";
// import museumImage from "../../assets/museum2.jpg";
import museumImage from "../../assets/museum3.jpg";
import parkImage from "../../assets/park2.jpg";

import OptionIcons from "@fluentui/svg-icons/icons/options_20_regular.svg";
import { onBeforeUnmount } from "vue";
import { onUnmounted } from "vue";
import { clearConfig } from "dompurify";
import { reload } from "firebase/auth";

const backgroundImages = [
  museumImage,
  gyeongbokgungImage,
  hotelImage,
  parkImage
];

const answerPopImages = [darkPop, morderPop, cartoonPop, newPop];

const placeImages = [standImg, chastImg];

const KNOW_SCORE = {
  high: 0.5,
  middle: 0.7
};

const store = useStore();
const tokenStore = useTokenStore();

const questionYn = ref(false);
const answerYn = ref(false);
const isBackgroundPopupVisible = ref(false);
const $q = useQuasar();
const isOptionMenuVisible = ref(false);
const chromaCanvas = ref<HTMLCanvasElement>();
const activePlayer = ref<HTMLVideoElement>();
const questionInput = ref(""); // STT 결과 저장
const resResult = ref(""); // 스트리밍 답변 결과 저장
// 반응형 변수
const isMobile = ref(false);
const selectedBackground = ref(backgroundImages[0]); // 기본 배경 설정
const multiQuery = ref(false);
const knowMode = ref<"accuracy" | "count">("count");
const knowScope = ref<ReqResponseStream["know_scope"]>("independent");

const sttLoading = ref(false);
const ttsLoading = ref(false);

const isAnswerPopupVisible = ref(false);
const selectedAnswerPop = ref(answerPopImages[0]); // 기본 배경 설정
const selectedPlacePop = ref(placeImages[0]); // 기본 배경 설정

// // 통합된 텍스트 계산
// const displayText = computed(() => {
//   if (introduceServiceYn.value && !introduceDocentYn.value) {
//     return introduceService.value; // 서비스 소개
//   } else if (!introduceServiceYn.value && introduceDocentYn.value) {
//     return introduceDocent.value; // AI 보람이 소개
//   } else {
//     return bodyText.value; // 기본 답변 텍스트
//   }
// });

interface MessageResponse {
  // link: [
  //   {
  //     channelKey: number;
  //     cre_date: string;
  //     cre_user_key: number;
  //     delete_yn: number;
  //     link_body: string;
  //     link_key: number;
  //     link_url: string;
  //     link_title: string;
  //   }
  // ];
  menu: [
    {
      channelKey: number;
      cre_date: string;
      cre_user_key: number;
      delete_yn: number;
      link_body: string;
      link_key: number;

      link_url: string;
      // link_title: string;
      link_name: string;
      repo_link_key: number;
    }
  ];

  call_info: [
    {
      cmd: string;
      sub_string: string;
    }
  ];

  attach_file_string: [
    {
      file_key: number;
      file_name: string;
      file_enc_path: string;
    }
  ];

  img_file_string: [
    {
      file_key: number;
      file_name: string;
      file_enc_path: string;
    }
  ];

  body_img_path_string: string[];

  must_text_string: string[];

  knowledge: [
    {
      know: string;
      metadata: {
        id: string;
        score: number;
        cre_date: string;
      };
    }
  ];
  body: string;
  keyword_string: string[];
  quest_string: string[];
  msg_key?: number;
  res_msg_key: number;
}

const recvMsgPattern = /^.*?(?=\{)|```$/s;

//받은 메시지에 JSON 데이터를 안전하게 파싱하는 유틸리티 함수
function recvMsgJson<T extends object>(content: string) {
  if (!content.includes("{")) {
    return {} as Partial<T>;
  }
  const jsonLikeText = content.replace(recvMsgPattern, "");
  return parse(jsonLikeText) as Partial<T>;
}

//답변
const recvMsgParsed = computed(() => {
  const parsedResult = recvMsgJson<MessageResponse>(resResult.value);
  // console.log("====================parsedResult", parsedResult);
  return parsedResult;
});

// 비디오 설정
// const calmQueue = [calmVideo4scsc, calmVideo4sc, calmVideo5sc, calmVideo10sc];
const calmQueue = [calmVideo5sc, calmVideo10sc];
// const talkQueue = [talkVideo4sc, talkVideo4sc2];
const talkQueue = [
  { src: talkVideo5sc, duration: 5 },
  { src: talkVideo4sc, duration: 4 },
  { src: talkVideo3sc, duration: 3 },
  { src: talkVideo2sc, duration: 2 },
  { src: talkVideo1sc, duration: 1 }

  // 필요 시 추가 영상 삽입
];
let calmIndex = 0;
let talkIndex = 0; // Talk 비디오 인덱스
let nextTalkIndex = 0; // 다음 Talk 비디오 인덱스

// 뷰포트 크기 감지 함수
const handleResize = () => {
  // 해상도와 화면 방향을 종합적으로 판단
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const isLandscape = screenWidth > screenHeight;

  // 가로 모드에서 모바일 크기 범위를 조정 (예: 1024px 미만)
  isMobile.value = screenWidth <= 768 || (isLandscape && screenWidth < 1024);
};

const goToBack = () => {
  stopTalk();
  // router.push("/talk");
  router.push("/talk").then(() => {
    location.reload(); // 페이지 리로드
  });
};

//준비중인 서비스 안내
const readyService = () => {
  $q.notify({
    message: "현재 서비스가 준비중입니다.",
    timeout: 3000
  });
};

//================================================ 배경 설정 관리

//배경 이미지 변경 팝업 상태관리
const toggleBackgroundPopup = () => {
  isOptionMenuVisible.value = false;
  isBackgroundPopupVisible.value = !isBackgroundPopupVisible.value;
};

// 배경 선택시 변경
const selectBackground = (image: string) => {
  console.log("선택 이미지", selectedBackground.value);
  selectedBackground.value = image;
  // toggleBackgroundPopup();
  console.log("선택된 배경:", image); // 디버깅용
  $q.notify({
    message: "배경이 변경되었습니다.",
    color: "positive",
    timeout: 3000
  });
};

// 배경 이름 변경
const getBackgroundName = computed(() => {
  const index = backgroundImages.indexOf(selectedBackground.value);
  return index === 0
    ? "전시관"
    : index === 1
      ? "경복궁"
      : index === 2
        ? "호텔"
        : index === 3
          ? "공원"
          : "";
});

//================================================ 말풍선 팝업 위치 조정
//팝업 이미지 변경 팝업 상태관리
const toggleAnswerPopup = () => {
  isOptionMenuVisible.value = false;
  isAnswerPopupVisible.value = !isAnswerPopupVisible.value;
};

// 팝업 선택시 변경
const selectAnswerPop = (image: string) => {
  console.log("선택 이미지", selectedAnswerPop.value);
  selectedAnswerPop.value = image;
  toggleAnswerPopup();
  console.log("선택된 말풍선:", image); // 디버깅용
  $q.notify({
    message: "말풍선이 변경되었습니다.",
    color: "positive",
    timeout: 3000
  });
};

const noPrepareEvent = () => {
  $q.notify({
    message: "서비스가 준비중입니다.",
    timeout: 3000
  });
};

// ✅ 특정 이미지일 때 다른 클래스 적용
const isCleanStyle = computed(
  () =>
    selectedAnswerPop.value === newPop ||
    selectedAnswerPop.value === morderPop ||
    selectedAnswerPop.value === darkPop
);
// ✅ 특정 이미지일 때 다른 클래스 적용
const isNormalStyle = computed(() => selectedAnswerPop.value === cartoonPop);

// 팝업 이름 변경
const getAnswerPopName = computed(() => {
  const index = answerPopImages.indexOf(selectedAnswerPop.value);
  return index === 0
    ? "심플"
    : index === 1
      ? "모던"
      : index === 2
        ? "카툰"
        : index === 3
          ? "깔끔"
          : "";
});

//================================================ 아바타 뷰 위치 조정
const isPlacePopupVisible = ref(false);

//위치 이미지 변경 팝업 상태관리
const togglePlacePopup = () => {
  isOptionMenuVisible.value = false;
  isPlacePopupVisible.value = !isPlacePopupVisible.value;
};

const textAnswerYn = ref(false);

const toggleQuestionPop = () => {
  questionYn.value = !questionYn.value;
  textAnswerYn.value = !textAnswerYn.value;
};

// 위치 선택시 변경
const selectPlace = (image: string) => {
  console.log("선택 이미지", selectedPlacePop.value);
  selectedPlacePop.value = image;
  togglePlacePopup();
  console.log("선택된 위치:", image); // 디버깅용
  $q.notify({
    message: "아바타 위치가 변경되었습니다.",
    color: "positive",
    timeout: 3000
  });
};

const questionRoot = computed(() => {
  const title = textAnswerYn.value ? "텍스트" : "음성";
  return title;
});

// 위치 이름 변경
const getPlaceName = computed(() => {
  const index = placeImages.indexOf(selectedPlacePop.value);
  return index === 0 ? "스탠딩 뷰" : index === 1 ? "바스트 뷰" : "";
});

const canvasStyle = computed(() => {
  const index = placeImages.indexOf(selectedPlacePop.value);
  return index === 0 ? "chroma-full-body" : "chroma-half-body";
});

const canvasDynamicStyle = computed(() => {
  const index = placeImages.indexOf(selectedPlacePop.value);
  return {
    // bottom: index === 0 ? "0%" : "-30%"
  };
});

//================================================ 모바일 옵션버튼 관리
const toggleOptionMenu = () => {
  isOptionMenuVisible.value = !isOptionMenuVisible.value;
};

//================================================ STT
const { startRecognition, stopRecognition, initRecognition } =
  useSpeechRecognition();

const stopStt = () => {
  stopRecognition();
  sttLoading.value = false;
};

// STT 실행
// const startSpeechRecognition = async () => {
//   await initRecognition(LANGUAGE.KOREAN.code);
//   await initTTS();
//   startRecognition({
//     onResult(resultText) {
//       questionYn.value = true;
//       questionInput.value = resultText; // STT 결과를 questionInput에 저장
//       sttLoading.value = false;
//       streamAnswer(); // STT가 끝나면 자동으로 질문 전송
//     }
//   });
// };
const startSpeechRecognition = async () => {
  try {
    await initRecognition(LANGUAGE.KOREAN.code);
    await initTTS();
    startRecognition({
      onResult(resultText) {
        console.log("STT 결과 수신:", resultText);
        questionYn.value = true;
        questionInput.value = resultText; // STT 결과를 questionInput에 저장
        sttLoading.value = false;
        streamAnswer(); // STT가 끝나면 자동으로 질문 전송
      },
      // onEnd() {
      //   console.log("음성 인식 종료 감지 - stopTalk 호출");
      //   $q.notify({
      //     message: "음성을 인지하지 못했습니다.",
      //     color: "negative",
      //     timeout: 3000
      //   });
      //   stopTalk(); // 종료 시 stopTalk 호출
      // },
      // onSoundEnd() {
      //   console.log("소리 감지 종료 - stopTalk 호출");
      //   $q.notify({
      //     message: "음성을 인지하지 못했습니다.",
      //     color: "negative",
      //     timeout: 3000
      //   });
      //   stopTalk(); // 소리 종료 시 stopTalk 호출
      // }
      onError(event) {
        console.error("음성 인식 오류:", event);
        $q.notify({
          message: "음성인식에 실패하였습니다.",
          color: "negative",
          timeout: 3000
        });
        stopTalk(); // 오류 시에도 stopTalk 호출
      }
    });
  } catch (error) {
    console.error("음성 인식 시작 중 오류:", error);
  }
};
//================================================ TTS

// STT & TTS
const { runTTS, initTTS, stopTTS } = useTTS();

// const stopIntroduce = () => {
//   // stopIntroduceServiceAudio();
//   // stopIntroduceDocentAudio();
//   stopTalk();
//   questionYn.value = false;
//   answerYn.value = false;
//   ttsLoading.value = false;
//   introduceServiceYn.value = false;
//   introduceDocentYn.value = false;
//   stopTTS();
//   stopRecognition();
// };

//================================================ Canvas 크로마키
let chromaContext: CanvasRenderingContext2D | null = null;

// 크로마키 렌더링
const renderChromaKey = () => {
  if (!chromaContext || !activePlayer.value) return;

  chromaContext.drawImage(
    activePlayer.value,
    0,
    0,
    chromaCanvas.value.width,
    chromaCanvas.value.height
  );

  const frame = chromaContext.getImageData(
    0,
    0,
    chromaCanvas.value.width,
    chromaCanvas.value.height
  );
  const data = frame.data;

  // 이미지의 폭과 높이 계산
  const width = chromaCanvas.value.width;
  const height = chromaCanvas.value.height;

  // 주변 픽셀까지 포함하여 초록색 투명화
  const kernelSize = 1; // 한 칸씩 더 따기 (커널 반지름)
  const isGreenPixel = (r: number, g: number, b: number) =>
    g > 100 && r < 90 && b < 90;

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const index = (y * width + x) * 4;

      // const r = data[index];
      // const g = data[index + 1];
      // const b = data[index + 2];

      // 커널 내의 픽셀 확인
      let makeTransparent = false;
      for (let ky = -kernelSize; ky <= kernelSize; ky++) {
        for (let kx = -kernelSize; kx <= kernelSize; kx++) {
          const nx = x + kx;
          const ny = y + ky;

          // 캔버스 범위를 벗어나지 않도록 확인
          if (nx >= 0 && ny >= 0 && nx < width && ny < height) {
            const neighborIndex = (ny * width + nx) * 4;
            const nr = data[neighborIndex];
            const ng = data[neighborIndex + 1];
            const nb = data[neighborIndex + 2];

            if (isGreenPixel(nr, ng, nb)) {
              makeTransparent = true;
              break;
            }
          }
        }
        if (makeTransparent) break;
      }

      if (makeTransparent) {
        data[index + 3] = 0; // 투명화
      }
    }
  }

  chromaContext.putImageData(frame, 0, 0);

  // 다음 Calm 비디오를 미리 로드 (중복 방지)
  const currentIndex = calmQueue.indexOf(activePlayer.value.src);
  const nextIndex = (currentIndex + 1) % calmQueue.length;
  preloadVideo(calmQueue[nextIndex]);

  requestAnimationFrame(renderChromaKey);
};

//================================================ 가만히 있는 영상

const playCalmVideo = () => {
  if (!activePlayer.value) return;
  // answerYn.value = false;

  const currentVideo = calmQueue[calmIndex];
  const nextIndex = (calmIndex + 1) % calmQueue.length; // 다음 비디오 인덱스

  activePlayer.value.src = currentVideo;
  activePlayer.value.load();
  activePlayer.value.play();

  activePlayer.value.onended = () => {
    // 다음 Calm 비디오를 미리 로드
    preloadVideo(calmQueue[nextIndex]);

    calmIndex = nextIndex; // Calm 비디오 인덱스 업데이트
    playCalmVideo(); // 다음 Calm 비디오 재생
  };
};

//================================================ 소개 영상

// 서비스 소개
const introduceServiceYn = ref(false);
const introduceServiceAudio = new Audio("src/assets/introduceservice.mp3"); // 서비스 소개 음성 파일 경로
const introduceServiceDuration = 14; // 서비스 소개 음성 길이 (초)
const introduceService = ref(
  "AI 도슨트 서비스는 전시관, 행사장, 관광지 등의 방문객들에게 맞춤형 정보를 제공하는 스마트 안내 서비스입니다. 음성 대화와 실시간 응답을 통해 더욱 풍부한 관람 경험을 선사합니다!"
);

const startIntroduceService = async () => {
  // stopIntroduceServiceAudio()
  stopTalk();
  introduceServiceYn.value = true;
  answerYn.value = true;
  isTTSPlaying.value = true;
  // ttsLoading.value = true;

  console.log("서비스 소개 시작");

  introduceServiceAudio.currentTime = 0;

  // 오디오 재생 시작
  introduceServiceAudio
    .play()
    .then(() => {
      console.log("서비스 소개 음성 재생 시작");
    })
    .catch((error) => {
      console.error("오디오 재생 오류:", error);
    });

  // 오디오 종료 이벤트 처리
  introduceServiceAudio.onended = () => {
    introduceServiceYn.value = false;
    console.log("서비스 소개 종료");
    answerYn.value = false;
    isStreamingYn.value = false;
    // answerYn.value = false;
  };

  // 영상 재생 (음성 재생 시간 동안)
  await playTalkVideosForTTS(introduceServiceDuration);
  introduceServiceYn.value = false;
  // answerYn.value = false; // 말풍선 숨기기
  // ttsLoading.value = false;
};

// 오디오 정지 함수
const stopIntroduceServiceAudio = () => {
  if (!introduceServiceAudio.paused) {
    introduceServiceYn.value = false;
    introduceServiceAudio.pause(); // 재생 중인 오디오 정지
    introduceServiceAudio.currentTime = 0; // 재생 위치를 처음으로 초기화
    console.log("서비스 소개 음성 정지");
  }
};

// AI 보람이 소개
const introduceDocentYn = ref(false);
const introduceDocentAudio = new Audio("src/assets/introducedocent.mp3"); // AI 보람이 소개 음성 파일 경로
const introduceDocentDuration = 16.5; // AI 보람이 소개 음성 길이 (초)
const introduceDocent = ref(
  "안녕하세요! 저는 서울시 시민행복 전시관의 AI 도슨트, AI 보람이입니다. 우리 서울시 시민행복 전시관에 대해 궁금한게 있다면 무엇이든 물어보세요! 작품 소개, 위치 안내, 기타 정보에 대해 친절히 답변드리겠습니다."
);

// const startIntroduceDocent = async (introduceDocent: string) => {
//   introduceDocentYn.value = true;
//   await initRecognition(LANGUAGE.KOREAN.code);
//   await initTTS();
//   console.log("===================== 소개 텍스트", introduceDocent);
//   await playTalkVideoForDuration(introduceDocent);
//   introduceDocentYn.value = false;
// };

const startIntroduceDocent = async () => {
  stopTalk();
  // stopIntroduceServiceAudio();
  introduceDocentYn.value = true;
  answerYn.value = true;
  isTTSPlaying.value = true;
  // ttsLoading.value = true;

  console.log("AI 보람이 소개 시작");

  introduceDocentAudio.currentTime = 0;

  introduceDocentAudio
    .play()
    .then(() => {
      console.log("보람이 소개 음성 재생 시작");
    })
    .catch((error) => {
      console.error("오디오 재생 오류:", error);
    });

  introduceDocentAudio.onended = () => {
    introduceDocentYn.value = false; // 말풍선 숨기기
    console.log("보람이 소개 종료");
    answerYn.value = false;
    isStreamingYn.value = false;
  };

  // 말하는 영상을 음성 길이에 맞춰 재생
  await playTalkVideosForTTS(introduceDocentDuration);
  introduceDocentYn.value = false; // 말풍선 숨기기
  // answerYn.value = false;
  // ttsLoading.value = false;
};

// 오디오 정지 함수
const stopIntroduceDocentAudio = () => {
  if (!introduceDocentAudio.paused) {
    introduceDocentAudio.pause(); // 재생 중인 오디오 정지
    introduceDocentYn.value = false; // 말풍선 숨기기
    introduceDocentAudio.currentTime = 0; // 재생 위치를 처음으로 초기화
    console.log("보람이 소개 음성 정지");
  }
};

//================================================ 말하는 영상
const isTTSPlaying = ref(false); // TTS 재생 상태 플래그

// TTS 실행 및 영상 재생
// const playTalkVideoForDuration = async (text: string) => {
//   try {
//     console.log("TTS 시작");
//     answerYn.value = true; // 말풍선 표시
//     ttsLoading.value = true;
//     isTTSPlaying.value = true;

//     // TTS 실행 및 재생 시간 반환
//     const ttsDuration = await runTTS(text, () => {
//       console.log("TTS 완료");
//       isTTSPlaying.value = false; // TTS가 완료되었음을 표시
//     });

//     console.log(`=============== TTS 재생 시간: ${ttsDuration}초`);

//     // TTS 재생 시간에 따라 영상 재생
//     await playTalkVideosForTTS(ttsDuration);

//     // TTS가 끝날 때까지 대기
//     while (isTTSPlaying.value) {
//       await new Promise((resolve) => setTimeout(resolve, 100)); // 100ms 대기
//     }

//     // TTS 종료 시 Calm 비디오로 전환
//     stopTalk();
//     console.log("=============== TTS 동작 및 영상 종료");
//     answerYn.value = false;
//     ttsLoading.value = false;
//   } catch (error) {
//     console.error("영상 재생 오류:", error);
//   }
// };

// TTS 실행 및 영상 재생
const playTalkVideoForDuration = async (text: string) => {
  try {
    console.log("TTS 시작");
    answerYn.value = true; // 말풍선 표시
    ttsLoading.value = true;
    // isTTSPlaying.value = true;

    // 🔥 개행 정리: \n\n → \n
    const cleanedText = text.replace(/\n\n/g, "\n");

    // TTS 실행 및 재생 시간 반환
    const ttsDuration = await runTTS(cleanedText, () => {
      // const ttsDuration = await runTTS(responseMsgText.value, () => {
      console.log("TTS 완료");
      isTTSPlaying.value = false; // TTS가 완료되었음을 표시
    });

    console.log(`=============== TTS 재생 시간: ${ttsDuration}초`);

    // TTS 재생 시간에 따라 영상 재생
    await playTalkVideosForTTS(ttsDuration);

    // TTS가 끝날 때까지 대기
    while (isTTSPlaying.value) {
      await new Promise((resolve) => setTimeout(resolve, 100)); // 100ms 대기
    }

    // TTS 종료 시 Calm 비디오로 전환
    stopTalk();

    console.log("=============== TTS 동작 및 영상 종료");
    answerYn.value = false;
    isStreamingYn.value = false;
    ttsLoading.value = false;
  } catch (error) {
    console.error("영상 재생 오류:", error);
  }
};

// TTS와 영상 조합 실행 함수
const playTalkVideosForTTS = async (ttsDuration: number) => {
  let remainingTime = Math.floor(ttsDuration); // 소수점 버림
  console.log(`TTS 전체 길이: ${ttsDuration}초, 반내림: ${remainingTime}초`);
  // debugger;

  const playCount: { [src: string]: number } = {}; // 각 영상의 재생 횟수 추적
  talkQueue.forEach((video) => {
    playCount[video.src] = 0; // 초기화
  });

  while (remainingTime > 1) {
    // 남은 시간보다 짧거나 같은 영상 중 duration이 1이 아닌 영상 필터링
    const availableVideos = talkQueue.filter(
      (video) => video.duration <= remainingTime && video.duration > 1
    );

    if (availableVideos.length === 0) {
      console.warn("남은 시간에 맞는 영상이 없습니다. 종료.");
      break;
    }

    // 재생 횟수가 가장 적은 영상 우선
    const minPlayCount = Math.min(
      ...availableVideos.map((video) => playCount[video.src])
    );
    const leastPlayedVideos = availableVideos.filter(
      (video) => playCount[video.src] === minPlayCount
    );

    // 최소 재생 횟수를 가진 영상 중 랜덤 선택
    const randomIndex = Math.floor(Math.random() * leastPlayedVideos.length);
    const selectedVideo = leastPlayedVideos[randomIndex];

    console.log(
      `랜덤 영상 재생 시작: ${selectedVideo.src}, 길이: ${selectedVideo.duration}초, 남은 시간: ${remainingTime}초`
    );
    if (isTTSPlaying.value) {
      await playVideo(selectedVideo.src, selectedVideo.duration);
      playCount[selectedVideo.src] += 1; // 재생 횟수 증가
      remainingTime -= selectedVideo.duration;
      console.log(
        `영상 재생 후 남은 시간: ${remainingTime}초, 재생 횟수:`,
        playCount
      );
    } else {
      console.log("TTS 재생이 완료되어서 영상 재생을 중단합니다.");
      break;
    }
  }

  // 마지막 1초를 처리
  if (remainingTime === 1) {
    const lastVideo = talkQueue.find((video) => video.duration === 1);
    if (lastVideo) {
      console.log(
        `마지막 1초 영상 재생 시작: ${lastVideo.src}, 길이: ${lastVideo.duration}초`
      );
      await playVideo(lastVideo.src, lastVideo.duration);
      remainingTime -= lastVideo.duration;
      console.log(`영상 재생 후 남은 시간: ${remainingTime}초`);
    }
  }

  console.log("TTS에 맞는 영상 재생 완료.");
};

const playVideo = (videoSrc, duration) => {
  return new Promise((resolve) => {
    if (!activePlayer.value) {
      console.error("activePlayer가 정의되지 않았습니다.");
      resolve(false);
      return;
    }

    console.log(`영상 로드: ${videoSrc}, 예상 재생 길이: ${duration}초`);

    // 기존 재생 중단 및 이벤트 초기화
    activePlayer.value.pause();
    activePlayer.value.removeAttribute("src");
    activePlayer.value.load();

    const onEndedHandler = () => {
      activePlayer.value.removeEventListener("ended", onEndedHandler);
      console.log(`재생 완료: ${videoSrc}`);
      // answerYn.value = false;
      resolve(true);
    };

    activePlayer.value.addEventListener("ended", onEndedHandler);

    // 영상 로드 및 재생
    activePlayer.value.src = videoSrc;
    activePlayer.value.load();
    activePlayer.value
      .play()
      .then(() => {
        console.log(`영상 재생 시작: ${videoSrc}`);
      })
      .catch((e) => {
        console.error("비디오 재생 오류:", e);
        resolve(false);
      });
  });
};

const preloadSet = new Set<string>();

const preloadVideo = (videoSrc: string) => {
  if (preloadSet.has(videoSrc)) {
    // console.log(`이미 로드된 비디오: ${videoSrc}`);
    return; // 중복 로드 방지
  }

  const video = document.createElement("video");
  video.src = videoSrc;
  video.preload = "auto"; // 미리 로드
  video.load();

  preloadSet.add(videoSrc); // 로드 완료한 비디오 기록
  console.log(`미리 로드 완료: ${videoSrc}`);
};

// 말하는 영상 Que 정렬
const playTalkVideoQueue = (ttsDuration: number) => {
  if (!activePlayer.value) return;

  const currentVideo = talkQueue[talkIndex];
  nextTalkIndex = (talkIndex + 1) % talkQueue.length; // 다음 Talk 비디오 순환

  activePlayer.value.src = currentVideo.src;
  activePlayer.value.load();
  activePlayer.value.play();

  activePlayer.value.onended = () => {
    // 다음 Talk 비디오를 미리 로드
    preloadVideo(talkQueue[nextTalkIndex].src);

    talkIndex = nextTalkIndex; // Talk 비디오 인덱스 업데이트
    if (ttsDuration > currentVideo.duration) {
      playTalkVideoQueue(ttsDuration - currentVideo.duration);
    }
  };
};

// const initializeVideo = (videoSrc: string) => {
//   if (!activePlayer.value) return;

//   activePlayer.value.src = videoSrc;
//   activePlayer.value.load();
//   activePlayer.value.play().catch((e) => {
//     console.error("비디오 재생 오류:", e);
//   });
// };

//================================================ 질문/답변 로직
// const knowScoreComputed = computed(() =>
//   knowMode.value === "accuracy" ? KNOW_SCORE.high : 1
// );
const isStreamingYn = ref(false);

// 스트리밍 답변 처리
const streamAnswer = async () => {
  try {
    resResult.value = ""; // 기존 결과 초기화

    const defaultBody = {
      multi_query: multiQuery.value,
      question: questionInput.value ?? "",
      channel_key: 332,
      know_scope: knowScope.value
    };

    const reader = await requestKmsAnswerStream(
      tokenStore.isLogin
        ? {
            ...defaultBody,
            user_key: store.myInfo?.user_key,
            // room_key: 1374
            room_key: store.currentRoom?.room_key
          }
        : defaultBody
    ); // 서버에 질문 전송

    if (reader) {
      isStreamingYn.value = true;
      isTTSPlaying.value = true;
      let firstValueSkipped = false;

      while (true) {
        const { value, done } = await reader.read();

        if (done) {
          // isStreamingYn.value = false;
          answerYn.value = false;
          isStreamingYn.value = false;
          console.log("스트리밍 완료!");

          // 최종적으로 받은 응답에서 body만 추출하여 처리
          try {
            const parsedResult = JSON.parse(resResult.value);
            const bodyText = parsedResult.body || "결과를 가져오지 못했습니다.";
            await playTalkVideoForDuration(bodyText); // TTS 시간에 맞게 영상 재생
            stopTalk();
            questionYn.value = false;
            questionInput.value = ""; // 질문 초기화
          } catch (e) {
            console.error("JSON 파싱 오류:", e);
          }

          break;
        }

        if (value) {
          resResult.value += value; // ✅ 받은 JSON 데이터를 누적하여 `displayText`에 실시간 반영

          if (!firstValueSkipped) {
            firstValueSkipped = true;
            continue;
          }
        }
      }
    }
  } catch (error) {
    console.error("스트리밍 오류", error);
  }
};

// 안전하게 JSON 파싱을 시도하는 함수
const safeParseJSON = (jsonString: string): any => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return null; // 파싱 실패 시 null 반환
  }
};

// body 필드만 추출하는 computed 속성
// const bodyText = computed(() => {
//   const parsedResult = safeParseJSON(result.value);
//   if (parsedResult && parsedResult.body) {
//     return parsedResult.body; // body 필드만 반환
//   }
// });
const bodyText = computed(() => {
  const parsedResult = safeParseJSON(resResult.value);

  if (parsedResult && parsedResult.body) {
    // 🔥 개행 정리: \n\n → \n
    return parsedResult.body.replace(/\n\n/g, "\n");
  }

  return ""; // 기본값 반환
});

// const displayText = computed(() => {
//   if (isStreamingYn.value) {
//     console.log("✅======================== 스트리밍 답변들", responseMsgText);
//     // return result.value || "응답중..."; // ✅ 스트리밍 데이터를 실시간 반영
//     // return resResult.value; // ✅ 스트리밍 데이터를 실시간 반영
//     return responseMsgText;
//   } else if (introduceServiceYn.value && !introduceDocentYn.value) {
//     return introduceService.value; // 서비스 소개
//   } else if (!introduceServiceYn.value && introduceDocentYn.value) {
//     return introduceDocent.value; // AI 보람이 소개
//   } else {
//     return bodyText.value; // 기본 답변 텍스트
//   }
// });

// const formatTextForDisplay = (text: string) => {
//   if (!text) return "";
//   return text.replace(/\n/g, "<br>"); // 🔥 개행 문자를 <br> 태그로 변환
// };

const displayText = computed(() => {
  if (isStreamingYn.value) {
    return formatTextForDisplay(responseMsgText.value); // 🔥 responseMsgText를 개행 변환
  } else if (introduceServiceYn.value && !introduceDocentYn.value) {
    return formatTextForDisplay(introduceService.value); // 서비스 소개
  } else if (!introduceServiceYn.value && introduceDocentYn.value) {
    return formatTextForDisplay(introduceDocent.value); // AI 보람이 소개
  } else {
    // return formatTextForDisplay(bodyText.value); // 기본 답변 텍스트
    return formatTextForDisplay(responseMsgText.value); // 🔥 responseMsgText를 개행 변환
  }
});

// 🔥 개행(\n)을 <br> 태그로 변환하는 함수
const formatTextForDisplay = (text: string) => {
  if (!text) return "";
  return text.replace(/\n/g, "<br>"); // 개행을 HTML <br>로 변환
};

// 특수기호, 특수 문자, 특수 기호 설정
// const responseMsgText = computed(() => {
//   if (recvMsgParsed.value?.body) {
//     answerYn.value = true;
//     console.log("받은 메시지값의 body", recvMsgParsed.value.body);
//     console.log("받은 메시지값 전체", recvMsgParsed.value);

//     const cleanBody = recvMsgParsed.value.body
//       .replace(/[^\p{L}\p{N}\s.,|\\\-:;"'(){}~/%@#$^&*\[\]!]/gu, "") // ✅ 허용할 문자만 남기기
//       .replace(/^###\s*/gm, "") // ✅ ### 제목 제거
//       .replace(/\n\n/g, "\n"); // ✅ 개행 정리 추가

//     return cleanBody.trim(); // 앞뒤 공백 제거
//   }
//   return "";
// });
const responseMsgText = computed(() => {
  if (recvMsgParsed.value?.body) {
    answerYn.value = true;
    console.log("받은 메시지값의 body", recvMsgParsed.value.body);
    console.log("받은 메시지값 전체", recvMsgParsed.value);

    const cleanBody = recvMsgParsed.value.body
      .replace(/[^\p{L}\p{N}\s.,|\\\-:;"'(){}~/%@#$^&*\[\]!?]/gu, "") // ✅ 허용할 문자만 남기기
      .replace(/^###\s*/gm, ""); // ✅ ### 제목 제거

    return formatTextForDisplay(cleanBody); // 🔥 개행을 <br>로 변환하여 리턴
  }
  return "";
});

//================================================ 음성대화 버튼 관리
// // 말하기 시작
// const startTalk = async () => {
//   questionInput.value = "";
//   sttLoading.value = true;
//   await startSpeechRecognition();
// };

// // 말하기 정지
// const stopTalk = () => {
//   stopTTS();
//   stopRecognition();
//   playCalmVideo();
// };

const startTalk = async () => {
  stopTalk();
  isRecording.value = true; // 🔥 녹음 시작 시 true로 설정
  try {
    // questionInput.value = "";
    // sttLoading.value = true;
    await startSpeechRecognition(); // 음성 인식 시작
  } catch (error) {
    console.error("음성 인식 오류:", error);
    isRecording.value = false; // 오류 발생 시 false로 변경
  }
};

// const stopTalk = () => {
//   isRecording.value = false; // 🔥 녹음 중단 시 false로 설정
//   stopTTS();
//   stopRecognition();
//   playCalmVideo();
// };
const stopTalk = () => {
  answerYn.value = false;
  isStreamingYn.value = false;
  isRecording.value = false;
  isTTSPlaying.value = false; // 🔥 TTS 재생 중단
  stopTTS();
  stopRecognition();
  playCalmVideo();
  stopIntroduceServiceAudio();
  stopIntroduceDocentAudio();
  // answerYn.value = false;
};

//================================================ onNMount, watch 관리
onMounted(() => {
  handleResize(); // 초기 뷰포트 크기 확인

  // 뷰포트 크기 변경 감지 이벤트 추가
  window.addEventListener("resize", handleResize);

  // Calm 비디오 미리 로드
  calmQueue.forEach((videoSrc) => preloadVideo(videoSrc));

  // Talk 비디오 미리 로드
  talkQueue.forEach((video) => preloadVideo(video.src));

  // Canvas 및 Calm 비디오 초기화
  chromaContext = chromaCanvas.value?.getContext("2d", { alpha: true }) || null;
  chromaCanvas.value!.width = 640;
  chromaCanvas.value!.height = 360;

  playCalmVideo();
  renderChromaKey();
});

onBeforeUnmount(() => {
  // 뷰포트 크기 변경 감지 이벤트 제거
  window.removeEventListener("resize", handleResize);
});

watch(isMobile, (newVal) => {
  console.log("모바일 상태:", newVal);
});

const toggleTalk = async () => {
  if (sttLoading.value) {
    console.log("인식 중 -> 강제 중단");
    questionInput.value = "";
    stopTalk();
    // stopIntroduce();
    return;
  }

  if (isTTSPlaying.value) {
    console.log("TTS 재생 중 -> 강제 중단");
    answerYn.value = false;
    isStreamingYn.value = false;

    questionInput.value = "";
    stopTalk();
    playCalmVideo();
    // stopIntroduce();
    return;
  }

  if (isRecording.value) {
    console.log("녹음 중 -> 중단");
    stopTalk();
    playCalmVideo();
    questionInput.value = "";
    answerYn.value = false;
    isStreamingYn.value = false;
  } else {
    console.log("녹음 시작");
    isRecording.value = true; // 🔥 녹음 시작 시 true로 변경
    await startTalk();
  }
};

// =========================================================
const isRecording = ref(false); // 음성 인식 중인지 상태 관리
// const buttonLabel = computed(() => {
//   if (sttLoading.value) return "인식 중..."; // STT 로딩 중
//   return isRecording.value ? "음성 중단" : "음성 대화"; // 상태에 따라 변경
// });
const buttonLabel = computed(() => {
  if (sttLoading.value) return "인식 중..."; // STT 로딩 중
  if (isTTSPlaying.value) return "답변 중..."; // TTS 재생 중
  return isRecording.value ? "음성 중단" : "음성 대화"; // 기본 상태
});

const textStreamAnswer = async () => {
  await stopTalk();
  await startTextRecognition();
};

// STT 실행
const startTextRecognition = async () => {
  await initRecognition(LANGUAGE.KOREAN.code);
  await initTTS();
  streamAnswer(); // STT가 끝나면 자동으로 질문 전송
  setTimeout(() => {
    questionInput.value = "";
  }, 7000);
};

// ===========================================================================================================================================================================
</script>

<template>
  <div class="backgroundContainerWrap">
    <!-- <p class="answerText">답변이 들어오는 곳</p> -->
  </div>

  <!-- 제목 -->
  <div class="channel-title">서울시 시민행복 전시관</div>

  <!-- 나가기 -->
  <div class="exitBtn">
    <div>AI Docent</div>
    <img src="../../assets/anytalkwhite.png" alt="anytalkLogo" />
    <div @click="goToBack">X</div>
  </div>

  <!-- 배경 -->
  <div class="video-wrapper">
    <div
      class="background-container"
      :style="{
        backgroundImage: `linear-gradient(
      rgba(0, 205, 0, 0.1),
      rgba(200, 200, 200, 0.2)
    ), url('${selectedBackground}')`
      }"
    ></div>
    <div class="backgroundGradient"></div>

    <!-- 아바타와 캔버스 -->
    <div class="parentChroma">
      <canvas ref="chromaCanvas" class="chroma-canvas"></canvas>
    </div>
    <video ref="activePlayer" muted playsinline class="hidden"></video>
  </div>

  <!-- <div
    v-if="answerYn && !introduceServiceYn && introduceDocentYn"
    class="answerPopWrap"
  >
    <div class="answerText">
      {{ introduceDocent }}
    </div>
  </div>
  <div
    v-if="answerYn && introduceServiceYn && !introduceDocentYn"
    class="answerPopWrap"
  >
    <div class="answerText">
      {{ introduceService }}
    </div>
  </div> -->

  <!-- 답변 말풍선  -->
  <div v-if="isStreamingYn || answerYn" class="answerPopWrap">
    <!-- <div class="answerPopWrap"> -->
    <img
      src="../../assets/answerPop_modern.png"
      alt="말풍선"
      class="answerBubble"
    />
    <!-- <div class="answerText">
      {{ displayText }}
    </div> -->
    <!-- <div class="answerText" v-html="responseMsgText"></div> -->
    <div class="answerText" v-html="displayText"></div>
  </div>
  <!-- <div v-if="answerYn" class="answerPopWrap"> -->
  <!-- <div class="answerPopWrap"> -->
  <!-- <img
      src="../../assets/answerPop_modern.png"
      alt="말풍선"
      class="answerBubble"
    /> -->
  <!-- <div class="answerText"> -->
  <!-- {{ isStreamingYn ? "응답중 ..." : displayText }} -->
  <!-- {{ displayText }} -->
  <!-- </div> -->
  <!-- <div class="answerText" v-html="responseMsgText"></div> -->
  <!-- </div> -->

  <!-- 소개 버튼 -->
  <div class="introBtnWrap">
    <button @click="startIntroduceService">서비스 소개</button>
    <button @click="startIntroduceDocent">보람이 소개</button>
  </div>

  <!-- 질문 입력창 -->
  <!-- <transition name="fade-slide"> -->
  <div class="questionPop">
    <textarea v-model="questionInput"></textarea>
    <button @click="textStreamAnswer">전송</button>
  </div>
  <!-- </transition> -->

  <div v-if="!answerYn" class="isMobileOptionBtn">
    <button class="isMobileOptionIcon" @click="toggleOptionMenu">
      <OptionIcons />
    </button>
    <div v-if="isOptionMenuVisible" class="optionPopup">
      <button @click="toggleBackgroundPopup">
        배경 | {{ getBackgroundName }}
      </button>
      <!-- <button @click="readyService">아바타 | 보람이</button>
        <button @click="readyService">말풍선 | 카툰</button>
        <button @click="readyService">말투 | 큐레이터</button> -->
      <button @click="readyService">말풍선 | {{ getAnswerPopName }}</button>
      <button @click="readyService">아바타 뷰 | {{ getPlaceName }}</button>
      <button @click="readyService">질문방식 | {{ questionRoot }}</button>
    </div>
  </div>

  <!-- 배경 선택 팝업 -->
  <div v-if="isBackgroundPopupVisible" class="backgroundPopup">
    <div class="popupContent">
      <div class="headerWrap">
        <button class="closePopup" @click="toggleBackgroundPopup">X</button>
        <h5>배경을 선택해 주세요</h5>
      </div>
      <div class="backgroundOptions">
        <button
          v-for="(image, index) in backgroundImages"
          :key="index"
          @click="selectBackground(image)"
        >
          <span>{{
            index === 0
              ? "전시관"
              : index === 1
                ? "경복궁"
                : index === 2
                  ? "호텔"
                  : index === 3
                    ? "공원"
                    : ""
          }}</span>
        </button>
      </div>
    </div>
  </div>

  <!-- 음성인식 버튼 -->
  <div class="btnWrap">
    <div
      @click="toggleTalk"
      class="audioBtn"
      :class="{
        listening: sttLoading,
        speaking: isTTSPlaying,
        blink: isRecording
      }"
    >
      <img src="../../assets/anytalklogowhite.png" alt="음성 대화" />
      <div>{{ buttonLabel }}</div>
    </div>
  </div>
</template>

<style scoped>
html,
body {
  min-height: 100dvh;
  overflow: hidden;

  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }
}

.video-wrapper {
  position: relative;
  width: 100vw;
  height: 100dvh;
}

.background-container {
  position: fixed;
  z-index: -10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100dvh;
  background-size: cover;
  background-position: center;
}
.backgroundGradient {
  z-index: 10;
  position: fixed;
  width: 100vw;
  height: 120px;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.4) 30%,
    rgba(0, 0, 0, 0) 80%
  );
}

.parentChroma {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: flex-end; /* 아래쪽 정렬 */
}

/* 아바타와 캔버스 */
.chroma-canvas {
  position: absolute;
  bottom: 0;
  left: 30%;
  height: 60vh;
  max-height: 70vh; /* 최대 높이 제한 */
  aspect-ratio: 2.5/4 !important;
  transform: translateX(-50%);
}

.hidden {
  display: none;
}

.channel-title {
  position: fixed;
  top: 5%;
  left: 8%;
  color: white;
  font-weight: bold;
  font-size: clamp(19px, 4vw, 34px); /* 최소 14px, 기본 3vw, 최대 24px */
  padding: 10px 15px;
  border-radius: 8px;
  z-index: 1000; /* 다른 요소 위에 표시 */
}

.exitBtn {
  display: flex;
  gap: 15px;
  position: fixed;
  top: 5%;
  right: 8%;
  color: white;
  font-weight: bold;
  font-size: clamp(10px, 4vw, 20px); /* 최소 14px, 기본 3vw, 최대 24px */
  padding: 10px 15px;
  border-radius: 8px;
  z-index: 1000; /* 다른 요소 위에 표시 */
}

/* 이미지 크기를 AI Docent 텍스트와 동일하게 유지 */
.exitBtn img {
  height: 1.5em; /* 텍스트 크기와 동일한 높이 */
  width: auto; /* 비율 유지 */
  margin-right: 25px;
  padding-bottom: 4px;
  opacity: 0.5;
}

/* 화면이 작아서 겹칠 경우 AI Docent & img 숨기기 */
@media (max-width: 650px) {
  .exitBtn div:first-child, /* AI Docent */
  .exitBtn img {
    display: none;
  }
}

/* .answerWrap {
  width: 100vw;
  height: 100vh;
} */

/* .answerPopWrap {
  position: fixed;
  top: 15%;
  left: 33%;
  width: 40%;
  min-height: 160px;
  min-width: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 30px 20px;
  box-sizing: border-box;
  flex-direction: column;
} */

/* .danswerPopWrap {
  position: fixed;
  top: 15%;
  left: 50%;
  width: clamp(30%, 40vw, 80%);
  min-height: 160px;
  max-height: 33.33%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 30px 20px;
  box-sizing: border-box;
  flex-direction: column;
  transform: translateX(-50%);
  opacity: 0.8;
} */

.answerPopWrap {
  position: fixed;
  top: 15%;
  left: 50%;
  width: clamp(20%, 50vw, 90%); /* 최소 30%, 기본 50vw, 최대 100% */
  min-height: 160px;
  /* max-height: 30vh; */
  max-height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 30px 20px;
  box-sizing: border-box;
  flex-direction: column;
  transform: translateX(-50%); /* 가운데 정렬 */
}

/* 말풍선 이미지 */
.answerBubble {
  width: 100%;
  height: 100%; /* 부모 크기에 맞게 자동 증가 */
  object-fit: fill; /* 가득 채우면서 비율 유지 */
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5;
}

/* 텍스트 스타일 */
.answerText {
  width: 90%;
  height: 90%;
  font-size: clamp(14px, 1vw, 14px);
  font-weight: 800;
  text-align: left;
  word-wrap: break-word;
  white-space: pre-wrap;
  line-height: 1.5;
  padding: 2%;
  overflow-y: auto; /* 높이가 넘칠 경우 스크롤 */
  word-break: break-word; /* 텍스트가 넘칠 경우 자동 줄바꿈 */

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: thin; /* Firefox */

  &::-webkit-scrollbar {
    /* Chrome, Safari, Opera */
    display: none;
  }
}

.introBtnWrap {
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: fixed;
  bottom: 5%;
  left: 8%;
  color: white;
  font-weight: bold;
  font-size: clamp(10px, 4vw, 20px); /* 최소 14px, 기본 3vw, 최대 24px */
  padding: 10px 15px;
  border-radius: 8px;
  z-index: 1000; /* 다른 요소 위에 표시 */
}

.introBtnWrap button {
  background-color: rgba(22, 22, 22, 0.7);
  color: white;
  font-size: 13px !important;
  width: 120px;
  font-weight: 600;
  border-radius: 10px;
  opacity: 0.6;
}

.btnWrap {
  width: 100vw;
  height: 100vh;
}

.audioBtn {
  /* background-color: rgb(82, 82, 82); */
  background-color: black;
  opacity: 0.5;
  box-shadow:
    0 4px 6px -1px rgb(0 0 0 / 0.1),
    0 2px 4px -2px rgb(0 0 0 / 0.1);
  color: white;
  text-align: center;
  display: flex; /* 정렬을 위한 flex 사용 */
  justify-content: center; /* 가로 정렬 */
  align-items: center; /* 세로 정렬 */
  position: absolute;
  border-radius: 100%;
  bottom: 7%;
  right: 5%;
  height: 12%;
  cursor: pointer;
  font-size: clamp(12px, 2.5vw, 18px);
  font-weight: 700;
  aspect-ratio: 1/1;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
}
.audioBtn img {
  width: 30%;
}
/* STT 로딩 중일 때 버튼 깜빡임 */
@keyframes blink {
  0% {
    background-color: rgba(255, 255, 255, 0.8);
  }
  50% {
    background-color: rgba(255, 255, 255, 0.4);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.audioBtn.blink {
  animation: blink 1s infinite !important; /* 🔥 우선순위 강제 적용 */
  /* background-color: rgba(255, 255, 255, 0.8) !important; 
  color: black !important; */
}

.audioBtn.isRecording .audioBtn.listening {
  animation: blink 1s infinite !important; /* 🔥 우선순위 강제 적용 */
  background-color: rgba(255, 255, 255, 0.8) !important; /* 🔥 덮어씌우기 */
  color: black !important;
}

/* TTS 재생 중 버튼 스타일 */
.audioBtn.speaking {
  background-color: rgb(201, 62, 62) !important; /* 빨간색 강조 */
  color: white;
}

@media (max-width: 590px) {
  .questionPop {
    bottom: 7% !important;
    min-width: 82% !important;
  }

  .audioBtn {
    height: 7%;
    bottom: 17%;
    right: 1%;
  }

  .introBtnWrap {
    bottom: 16%;
    gap: 10px;
  }

  .introBtnWrap button {
    font-size: 12px !important;
    width: 110px;
    font-weight: 600;
    border-radius: 5px;
  }

  .answerPopWrap {
    width: clamp(50%, 90vw, 90%);
  }
}

.isMobileOptionBtn {
  opacity: 0.7;
  position: absolute !important;
  right: 9% !important;
  top: 20% !important;
  width: 40px !important;
  height: 40px !important;
  align-items: center;
  display: flex;
}

.isMobileOptionIcon {
  width: 100% !important;
  height: 100% !important;
  border-radius: 100px;
  padding: 0px;
}

.optionPopup {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  top: 60px;
  /* left: -30px; */
  left: -90px;
}
.optionPopup button {
  text-align: center;
}

/* 배경 팝업 */
.backgroundPopup {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: white;
  padding: 12px;
  border-radius: 5px;
  z-index: 1000;
  opacity: 0.8;
  position: absolute;
  top: 30%;
  left: 72%;
  right: 220px;
  transform: translate(-50%, -50%);
  width: 200px;
}

.popupContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  height: 100%;
}
.popupContent h5 {
  /* margin: 10px 0px; */
  font-weight: 500;
  width: 100%;
  margin: 0;
  font-size: 15px;
  text-align: center;
}

.questionPop {
  position: absolute;
  bottom: 7%;
  left: 50%;
  transform: translateX(-50%);
  /* width: 35%; */
  background-size: 100% 100%;
  border-radius: 10px;
  /* padding: 10px; */
  gap: 5px;
  min-width: 25%;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* overflow: visible; */
  height: auto;
  /* z-index: 1000; */
}

.questionPop textarea {
  text-align: right;
  opacity: 0.4;
  align-content: center;
  color: white;
  width: 80%;
  height: 50px;
  border: none;
  outline: none;
  font-size: clamp(14px, 1vw, 18px);
  font-weight: 800;
  resize: none;
  background-color: black;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 0 auto;
}
.questionPop button {
  /* margin-left: -30px; */
}

.fade-slide-enter-active {
  animation: fadeSlideIn 0.5s ease-out forwards;
}

/* ✅ 사라질 때 (페이드 아웃 + 위에서 아래로) */
.fade-slide-leave-active {
  animation: fadeSlideOut 0.5s ease-in forwards;
}

.headerWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 5px;
}
.backgroundOptions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  width: 100%;
  padding: 0 5px;
}

.backgroundOptions button {
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  padding: 0;
  border: 2px solid transparent;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
}

.backgroundOptions button:hover {
  border-color: #2264a6;
}

.backgroundOptions button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease;
}

.backgroundOptions button:nth-child(1)::before {
  background-image: url("../../assets/museum3.jpg");
}
.backgroundOptions button:nth-child(2)::before {
  background-image: url("../../assets/gyeongbokgung.jpeg");
}
.backgroundOptions button:nth-child(3)::before {
  background-image: url("../../assets/hotel2.jpg");
}
.backgroundOptions button:nth-child(4)::before {
  background-image: url("../../assets/park2.jpg");
}

.backgroundOptions button:hover::before {
  transform: scale(1.1);
}

.backgroundOptions button span {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 10px;
  text-align: center;
}

.backgroundPopOptions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  width: 100%;
  padding: 0 5px;
}

.backgroundPopOptions button {
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  padding: 0;
  border: 2px solid transparent;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
}

.backgroundPopOptions button:hover {
  border-color: #2264a6;
}

.backgroundPopOptions button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  transition: transform 0.3s ease;
}

.backgroundPopOptions button:nth-child(1)::before {
  background-image: url("../../assets/darkPop.png");
}
.backgroundPopOptions button:nth-child(2)::before {
  background-image: url("../../assets/answerPopImg.png");
}
.backgroundPopOptions button:nth-child(3)::before {
  background-image: url("../../assets/cartoonPop.png");
}
.backgroundPopOptions button:nth-child(4)::before {
  background-image: url("../../assets/newPop.png");
}

.backgroundPopOptions button:hover::before {
  transform: scale(1.1);
}

.backgroundPopOptions button span {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 10px;
  text-align: center;
}

/* ======================================================= */
.backgroundPlaceOptions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  width: 100%;
  padding: 0 5px;
}

.backgroundPlaceOptions button {
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  padding: 0;
  border: 2px solid transparent;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
}

.backgroundPlaceOptions button:hover {
  border-color: #2264a6;
}

.backgroundPlaceOptions button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  transition: transform 0.3s ease;
}

.backgroundPlaceOptions button:nth-child(1)::before {
  background-image: url("../../assets/standImg.png");
}
.backgroundPlaceOptions button:nth-child(2)::before {
  background-image: url("../../assets/chastImg.png");
}

.backgroundPlaceOptions button:hover::before {
  transform: scale(1.1);
}

.backgroundPlaceOptions button span {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 10px;
  text-align: center;
}

/* .closePopup {
  margin-left: auto;
  padding: 5px 15px;
  border: none;
  cursor: pointer;
} */

.closePopup {
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 2px 6px;
  font-size: 12px;
  border: none;
  background: none;
  cursor: pointer;
  color: #666;
}

.closePopup:hover {
  background-color: #c32f39;
}

.pcOptionPopupBtn {
  font-size: 1rem;
  padding: 10px;
  background-color: white;
  color: #434343;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pcOptionPopupBtn:hover {
  background-color: #aaaaaa;
  opacity: 0.7;
}

.pcOptionContainer {
  position: relative;
  z-index: 1000;
}

.pcOptionToggleBtn {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0.7;
}

.pcOptionPopup {
  position: absolute;
  top: 50px;
  right: 0;
  width: 200px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.pcOptionPopup button {
  text-align: left;
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.pcOptionPopup button:hover {
  background-color: #f0f0f0;
}
</style>
