import { useLocalStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { ref, computed, watch } from "vue";
import { jwtDecode } from "jwt-decode";
import type { CookieInfo } from "../api/types";
import { getChannelList } from "../api/backend";

export const useTokenStore = defineStore("jwt_token", () => {
  const accessToken = useLocalStorage("anytalk_jwt_token", "");

  const getToken = computed(() => accessToken.value);
  const setToken = (newToken: string) => {
    accessToken.value = newToken;
  };
  const isLogin = computed(() => {
    console.log("isLogin여부", accessToken.value);
    return accessToken.value !== "";
  });
  // const logOut = async () => {
  //   accessToken.value = "";
  //   await getChannelList({ channel_key: 85 });
  // };
  const logOut = () => {
    accessToken.value = "";
  };

  const userToken = computed(() => {
    if (accessToken.value === "") {
      return undefined;
    }
    const token = jwtDecode(accessToken.value);
    return token.sub;
  });

  function getLocalStorage(key?: string) {
    if (key) {
      const info = window.localStorage.getItem(key);
      if (info !== null) {
        return info;
      }
    }
    return "{}";
  }

  const _savedInfo = ref(getLocalStorage(userToken.value));
  const savedInfo = computed({
    get: () => JSON.parse(_savedInfo.value) as Partial<CookieInfo>,
    set: (newValue) => {
      if (userToken.value === undefined) {
        return;
      }
      _savedInfo.value = JSON.stringify({
        ...savedInfo.value,
        ...newValue
      });
      window.localStorage.setItem(userToken.value, _savedInfo.value);
    }
  });

  watch(
    userToken,
    (newToken) => {
      _savedInfo.value = getLocalStorage(newToken);
    },
    { immediate: true }
  );

  const createHeader = () =>
    isLogin.value
      ? {
          Authorization: `Bearer ${accessToken.value}`
        }
      : {};
  return { getToken, setToken, isLogin, logOut, createHeader, savedInfo };
});
