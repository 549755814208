<script setup lang="ts">
interface Props {
  portal?: string;
}
const props = defineProps<Props>();
</script>

<template>
  <Teleport :to="props.portal ?? 'body'">
    <slot></slot>
  </Teleport>
</template>
