<!-- KmsViewPage.vue -->

<script setup lang="ts">
import { defineProps, onMounted, ref } from "vue";
import {
  updateKmsKnow,
  deleteKmsKnow,
  createPreAfunTotalSummary,
  createPreAfunTitle,
  getFileList,
  createFileList,
  createRepoLinkList
} from "../../../api/backend";
import CloseIcon from "@fluentui/svg-icons/icons/dismiss_24_regular.svg";
import KmsKnow from "../../../../src/mokUpData/atKmsMockData";
import { useRouter } from "vue-router";

import {
  TagsInputInput,
  TagsInputItem,
  TagsInputItemDelete,
  TagsInputItemText,
  TagsInputRoot
} from "radix-vue";
import { Icon } from "@iconify/vue";

const router = useRouter();
const $q = useQuasar();
const store = useStore();

const navigateToRepoFile = () => {
  router.push("/talk/kmsrepofile");
};

const navigateToRepoLink = () => {
  router.push("/talk/kmsrepolink");
};

const navigateToRepoImage = () => {
  router.push("/talk/kmsrepoimage");
};

import Delete from "@fluentui/svg-icons/icons/presence_offline_20_regular.svg";
// import Add from "@fluentui/svg-icons/icons/add_circle_20_regular.svg";
import KnowList from "@fluentui/svg-icons/icons/document_bullet_list_20_regular.svg";
import SummaryIcon from "@fluentui/svg-icons/icons/document_checkmark_20_regular.svg";
import ContentIcon from "@fluentui/svg-icons/icons/slide_text_20_regular.svg";

import ImageIcon from "@fluentui/svg-icons/icons/image_20_regular.svg";
import AttachIcon from "@fluentui/svg-icons/icons/attach_20_regular.svg";
import KeywordIcon from "@fluentui/svg-icons/icons/key_20_regular.svg";
import MustTextIcon from "@fluentui/svg-icons/icons/checkbox_warning_20_regular.svg";
import LinkIcon from "@fluentui/svg-icons/icons/link_multiple_20_regular.svg";

import { useQuasar } from "quasar";
import KmsCompareAfun from "./KmsCompareAfun.vue";
import KmsKnowDetailPop from "./KmsKnowDetailPop.vue";
import { watch } from "vue";
import { RepoLink } from "../../../api/types";
import { watchEffect } from "vue";
import { useStore } from "../../../stores/store";

// mock data
const mockCreatePreAfun = async (
  requestData: PreAfunRequest
): Promise<PreAfunResponse> => {
  await new Promise((resolve) => setTimeout(resolve, 1000));

  const mockResponse: PreAfunResponse = {
    title: "남서울대학교 학사경고 및 자퇴・제적 처리에 관한 규정",
    summary:
      "본 규정은 남서울대학교의 학사경고, 자퇴 및 제적 처리에 관한 세부사항을 규정합니다. 학사경고는 해당 학기 평점평균이 1.5 미만인 경우 부과되며, 연속 3회 학사경고 시 제적 처리됩니다. 또한 미등록, 미복학, 재학연한 초과 등의 사유로도 제적될 수 있으며, 자퇴는 보증인 연서로 자퇴원을 제출하여 총장의 허가를 받아야 합니다. 본 규정은 학생들의 학업 성취도 향상과 중도탈락 방지를 위한 기준을 제시합니다.",
    keywords: [
      "학사경고",
      "자퇴처리",
      "제적기준",
      "성적평가",
      "학업성취",
      "중도탈락",
      "학사관리",
      "학칙",
      "대학규정",
      "학사제도"
    ]
  };

  // 더 상세한 비교를 위한 대체 응답도 준비
  const alternativeMockResponse: PreAfunResponse = {
    title: "학업 성적 평가 및 학적 변동에 관한 시행세칙",
    summary:
      "이 시행세칙은 남서울대학교 학칙에 의거하여 재학생의 학업 성적 평가와 학적 변동에 관한 세부 사항을 정합니다. 학기당 평점평균 1.5 미만 취득 시 학사경고 조치되며, 학사경고 3회 누적 시 제적됩니다. 자퇴는 보증인 동의하에 신청 가능하며, 등록금 반환은 별도 규정을 따릅니다. 미등록, 미복학, 재학연한 초과 등으로 인한 제적 시 재입학이 제한될 수 있습니다. 본 세칙은 학생들의 학업 지속과 성적 향상을 지원하기 위한 기준을 제시합니다.",
    keywords: [
      "성적평가",
      "학적변동",
      "제적기준",
      "재입학",
      "등록금",
      "학사경고",
      "자퇴신청",
      "시행세칙",
      "학사제도",
      "학생지원"
    ]
  };

  // 랜덤하게 두 응답 중 하나를 반환하여 테스트의 다양성 확보
  return Math.random() > 0.5 ? mockResponse : alternativeMockResponse;
};

// Props 정의
const props = defineProps<{
  knowViewItem: KmsKnow; // 이름 변경
  folderAndGroupName: string;
  show: boolean;
}>();

// Emits 정의
const emit = defineEmits([
  "close",
  "updateSummary",
  "updateBody",
  "updateMustText",
  "updateKeywordList",
  "openDeleteKnowPopup",
  "knowDeleted",
  "saveStart",
  "saveComplete"
]);

onMounted(() => {
  // 기존 로직 유지
  newAttachFiles.value = []; // 첨부파일 초기화
  newImageFiles.value = []; // 이미지 초기화
  newLinkList.value = []; // 링크 초기화

  // localStorage 초기화
  localStorage.removeItem("tempAttachFiles");
  localStorage.removeItem("tempImageFiles");
  localStorage.removeItem("tempLinkList");
});

// 컴포넌트가 마운트되었을 때 knowViewItem 확인
onMounted(() => {
  if (props.knowViewItem) {
    console.log("전달받은 folderAndGroupName:", props.folderAndGroupName); // 정상 로그 출력
    console.log("전달받은 show:", props.show); // 정상 로그 출력
  } else {
    console.warn("KmsViewPage - knowViewItem이 전달되지 않았습니다.");
  }
});

watch(
  () => props.knowViewItem,
  () => {
    // 지식 항목 변경 시 모든 로컬 상태 초기화
    newAttachFiles.value = [];
    newImageFiles.value = [];
    newLinkList.value = [];

    // localStorage 완전 초기화
    localStorage.removeItem("tempAttachFiles");
    localStorage.removeItem("tempImageFiles");
    localStorage.removeItem("tempLinkList");

    // 상세 정보 초기화
    imageDetails.value = [];
    attachFileDetails.value = [];
    linkDetails.value = [];
  },
  { deep: true }
);

// 지식 수정 상태
const isEditing = ref(false);

const isGenerating = ref(false);

// 로컬 상태 관리
const localTitle = ref<string>("");
const localSummary = ref<string>(""); // 지식
const localBody = ref<string>(""); // 내용
const localMustText = ref<string>(""); // 필수표시
const localKeywordList = ref<string[]>([]); //키워드
const localQuestList = ref<string[]>([""]); //추천 질문
const editingKeywordIndex = ref<number | null>(null);
const editedKeywordInput = ref("");
const afunYn = ref(false);
const showComparePopup = ref(false);

const imageDetails = ref<any[]>([]);
const attachFileDetails = ref<any[]>([]);
const linkDetails = ref<any[]>([]);

// 로컬 상태에 새로 추가된 파일/이미지 관리를 위한 ref 추가
const newAttachFiles = ref<File[]>([]);
const newImageFiles = ref<File[]>([]);
const newLinkList = ref<{ name: string; url: string }[]>([]);

const imageFileInput = ref<HTMLInputElement | null>(null);
const attachFileInput = ref<HTMLInputElement | null>(null);

// 링크 관련 상태 추가
const newLinkName = ref("");
const newLinkUrl = ref("");
const isAddingLink = ref(false);

export interface preAfunReq {
  // channel_key: number;
  // kms_doc_key: number;
  // kms_know_key: number;
  source_text: string;
  // requestType: string;
}

export interface preAfunRes {
  title: string;
  summary: string;
  keywords: string[];
}

// 수정 모드 전환 함수
const toggleEditMode = () => {
  console.log("toggleEditMode 호출됨");
  console.log("수정 전 isEditing 값:", isEditing.value);
  console.log("수정 전 데이터:", props.knowViewItem); // 데이터 구조 확인을 위한 로그

  localTitle.value = props.knowViewItem.know.title || "";
  isEditing.value = true; // 수정 모드로 전환
  localSummary.value = props.knowViewItem.know.summary || ""; // 기존 지식 복사
  localBody.value = props.knowViewItem.know.body || ""; // 기존 내용 복사
  localMustText.value = props.knowViewItem.know.must_text || ""; // 기존 필수내용 복사
  // localKeywordList.value = [...props.knowViewItem?.keyword_list]; // 키워드 리스트 복사
  localKeywordList.value = props.knowViewItem.know.keyword_list
    ? [...props.knowViewItem.know.keyword_list]
    : [];
  localQuestList.value = props.knowViewItem.know.quest_list
    ? [...props.knowViewItem.know.quest_list]
    : [];

  console.log("수정 후 isEditing 값:", isEditing.value);
  console.log("localSummary 값:", localSummary.value);
  console.log("localBody 값:", localBody.value);
  console.log("localMustText 값:", localMustText.value);
  console.log("localKeywordList 값:", localKeywordList.value);
};

// const newKeywordInput = ref(""); // 새 키워드 입력 필드 값

// const handleEnter = (event: KeyboardEvent) => {
//   event.preventDefault(); // 기본 동작 완전히 차단
//   const trimmedKeyword = newKeywordInput.value.trim();

//   if (trimmedKeyword && !localKeywordList.value.includes(trimmedKeyword)) {
//     // 입력값 뒤에 공백 추가 후 리스트에 저장
//     localKeywordList.value.push(`${trimmedKeyword} `);
//     newKeywordInput.value = ""; // 입력 필드 초기화
//   } else if (!trimmedKeyword) {
//     console.warn("빈 키워드 입력 시도");
//   } else {
//     console.warn("중복된 키워드 입력 시도");
//   }
// };

// const removeKeyword = (item: string) => {
//   // 키워드 삭제 로직
//   localKeywordList.value = localKeywordList.value.filter(
//     (keyword) => keyword !== item
//   );
// };

const uploadFiles = async (files: File[], fileType: "attach" | "image") => {
  try {
    // FormData 생성
    const formData = new FormData();
    const channelKey =
      props.knowViewItem?.know?.channel_key || props.knowViewItem?.channel_key;
    if (!channelKey) {
      throw new Error("Channel key is required for file upload");
    }

    // 채널 키 추가
    formData.append("channel_key", channelKey.toString());
    // formData.append("channel_key", props.channelKey.toString());

    // 파일들 추가
    files.forEach((file) => {
      formData.append("file_list", file);
    });

    // API 호출
    const response = await createFileList(formData);

    // 파일 키 추가
    if (response && Array.isArray(response)) {
      const fileKeys = response.map((item) => item.file_key);

      if (fileType === "attach") {
        attachFileKeys.value = fileKeys;
      } else {
        imgFileKeys.value = fileKeys;
      }

      return fileKeys;
    }

    return [];
  } catch (error) {
    console.error(`파일 업로드 중 에러:`, error);
    $q.notify({
      message: `${fileType === "attach" ? "첨부 파일" : "이미지"} 업로드 중 오류가 발생했습니다.`,
      color: "negative"
    });
    return [];
  }
};

const fetchAdditionalDetails = async () => {
  try {
    // 이미지 상세 정보 초기화
    imageDetails.value = [];

    // 첨부 파일 상세 정보 초기화
    attachFileDetails.value = [];

    // 이미지 파일 상세 정보 조회
    // if (props.knowViewItem?.know?.img_filekey_list?.length) {
    //   const imagePromises = props.knowViewItem?.know?.img_filekey_list.map(
    //     (fileKey) => getFileList(fileKey)
    //   );
    //   imageDetails.value = await Promise.all(imagePromises);
    // }

    // // 첨부 파일 상세 정보 조회
    // if (props.knowViewItem?.know?.attach_filekey_list?.length) {
    //   const attachPromises = props.knowViewItem?.know?.attach_filekey_list.map(
    //     (fileKey) => getFileList(fileKey)
    //   );
    //   attachFileDetails.value = await Promise.all(attachPromises);
    // }

    // 이미지 파일 상세 정보 조회
    if (props.knowViewItem?.know?.img_filekey_list?.length) {
      const imgFileKeys = props.knowViewItem.know.img_filekey_list;
      const imageResult = await getFileList(imgFileKeys);
      imageDetails.value = imageResult; // API가 리스트를 반환하므로 바로 할당
    }

    // 첨부 파일 상세 정보 조회
    if (props.knowViewItem?.know?.attach_filekey_list?.length) {
      const attachFileKeys = props.knowViewItem.know.attach_filekey_list;
      const attachResult = await getFileList(attachFileKeys);
      attachFileDetails.value = attachResult;
    }

    // // 링크 상세 정보 조회
    // if (props.knowViewItem.linkkey_list?.length) {
    //   const linkPromises = props.knowViewItem.linkkey_list.map(linkKey => getLinkInfo(linkKey));
    //   linkDetails.value = await Promise.all(linkPromises);
    // }
  } catch (error) {
    console.error("추가 정보 로드 중 오류:", error);
    // 오류 발생 시 상세 정보 초기화
    imageDetails.value = [];
    attachFileDetails.value = [];
  }
};

// 컴포넌트 마운트 시 또는 knowViewItem 변경 시 정보 로드
onMounted(fetchAdditionalDetails);
watch(() => props.knowViewItem, fetchAdditionalDetails);

// 첨부 파일 추가 핸들러
const handleNewAttachFileUpload = (event: Event) => {
  const files = (event.target as HTMLInputElement).files;
  if (files) {
    Array.from(files).forEach((file) => {
      // 중복 파일 제거
      if (!newAttachFiles.value.some((f) => f.name === file.name)) {
        newAttachFiles.value.push(file);
        // localStorage.setItem(
        //   "tempAttachFiles",
        //   JSON.stringify(newAttachFiles.value)
        // );
      }
    });
  }
};

// 이미지 파일 추가 핸들러
const handleNewImageUpload = (event: Event) => {
  const files = (event.target as HTMLInputElement).files;
  if (files) {
    Array.from(files).forEach((file) => {
      // 중복 이미지 제거
      // if (!newImageFiles.value.some((f) => f.name === file.name)) {
      if (
        !newImageFiles.value.some((f) => f.name === file.name) &&
        !imageDetails.value.some((img) => img.file_name === file.name)
      ) {
        newImageFiles.value.push(file);

        const fileURL = URL.createObjectURL(file);

        // 미리보기용 객체 추가 - 기존 이미지 상세 정보와 비슷한 구조로 만듦
        imageDetails.value.push({
          file_key: null, // 아직 서버에 저장되지 않았으므로 키는 없음
          file_name: file.name,
          file_enc_path: fileURL, // 로컬 URL
          isLocal: true // 로컬 파일 표시를 위한 플래그
        });

        // localStorage.setItem(
        //   "tempImageFiles",
        //   JSON.stringify(newImageFiles.value)
        // );
      }
    });
  }
};

// 첨부 파일 삭제 핸들러
const removeNewAttachFile = (index: number) => {
  newAttachFiles.value.splice(index, 1);
};

// 이미지 파일 삭제 핸들러
const removeNewImageFile = (index: number) => {
  newImageFiles.value.splice(index, 1);
};

// 지식 수정 저장 함수
const saveKmsKnow = async () => {
  if (!localSummary.value.trim()) {
    $q.notify({
      message: "지식을 제대로 입력해주세요.",
      color: "negative"
    });
    return;
  }

  // 로딩 시작
  emit("saveStart");

  try {
    const channelKey =
      props.knowViewItem?.know?.channel_key || props.knowViewItem?.channel_key;

    if (!channelKey) {
      throw new Error("Channel key is required for file upload");
    }

    // 첨부 파일 업로드 (있는 경우)
    let attachFileKeys: number[] = [];
    if (newAttachFiles.value.length > 0) {
      const formData = new FormData();

      // formData.append("channel_key", props.channelKey.toString());
      // formData.append(
      //   "channel_key",
      //   channelKey
      //     ? channelKey.toString()
      //     : props.knowViewItem.channel_key.toString()
      // );
      formData.append(
        "channel_key",
        props.knowViewItem.know.channel_key.toString()
      );
      newAttachFiles.value.forEach((file) => {
        formData.append("file_list", file);
      });

      const response = await createFileList(formData);
      attachFileKeys = response.map((item) => item.file_key);
    }

    // 이미지 파일 업로드 (있는 경우)
    let imgFileKeys: number[] = [];
    if (newImageFiles.value.length > 0) {
      const formData = new FormData();

      // formData.append("channel_key", props.channelKey.toString());
      formData.append(
        "channel_key",
        channelKey
          ? channelKey.toString()
          : props.knowViewItem.channel_key.toString()
      );
      newImageFiles.value.forEach((file) => {
        formData.append("file_list", file);
      });

      const response = await createFileList(formData);
      imgFileKeys = response.map((item) => item.file_key);
    }

    // 파일 업로드 Promise 배열
    // const uploadPromises = [];

    // if (newAttachFiles.value.length > 0) {
    // const attachFormData = new FormData();
    // attachFormData.append("channel_key", channel_key);
    // newAttachFiles.value.forEach(file => attachFormData.append("file_list", file));
    // uploadPromises.push(createFileList(attachFormData));
    // }

    // if (newImageFiles.value.length > 0) {
    // const imageFormData = new FormData();
    // imageFormData.append("channel_key", channel_key);
    // newImageFiles.value.forEach(file => imageFormData.append("file_list", file));
    // uploadPromises.push(createFileList(imageFormData));
    // }

    // // 모든 파일 업로드 동시 처리
    // const responses = await Promise.all(uploadPromises);

    // // file key 추출
    // const attachFileKeys = responses[0]?.map(item => item.file_key) || [];
    // const imgFileKeys = responses[1]?.map(item => item.file_key) || [];

    // 변경 사항 확인
    const hasChanges =
      localTitle.value !== props.knowViewItem.title ||
      localSummary.value.trim() !== props.knowViewItem.summary ||
      localBody.value !== props.knowViewItem.body ||
      localMustText.value !== props.knowViewItem.must_text ||
      JSON.stringify(localKeywordList.value) !==
        JSON.stringify(props.knowViewItem.keyword_list);
    JSON.stringify(localQuestList.value) !==
      JSON.stringify(props.knowViewItem.quest_list);
    localValidYn.value !== props.knowViewItem.know.valid_yn; // valid_yn 변경 확인 추가

    if (!hasChanges) {
      // 변경된 값이 없으면 저장하지 않고 수정 모드 종료
      $q.notify({
        message: "변경된 내용이 없습니다."
      });
      isEditing.value = false;
      return;
    }

    // 링크 저장 로직
    const linkPromises = newLinkList.value.map((link) =>
      createRepoLinkList({
        channel_key: props.knowViewItem.know.channel_key,
        link_name: link.name,
        link_url: link.url
      })
    );

    const linkResponses = await Promise.all(linkPromises);
    const newLinkKeys = linkResponses.map((response) => response.repo_link_key);

    // const updatedAttachFileKeys = [
    //   ...(props.knowViewItem?.know?.attach_filekey_list || []),
    //   ...attachFileKeys
    // ];
    // const updatedImgFileKeys = [
    //   ...(props.knowViewItem?.know?.img_filekey_list || []),
    //   ...imgFileKeys
    // ];

    // imageDetails와 attachFileDetails에서 file_key만 추출
    const currentImgFileKeys = imageDetails.value
      .map((img) => img.file_key)
      .filter((key) => key !== null && key !== undefined);
    const currentAttachFileKeys = attachFileDetails.value.map(
      (file) => file.file_key
    );

    // 새로 업로드한 파일의 키를 합침
    const updatedImgFileKeys = [...currentImgFileKeys, ...imgFileKeys].filter(
      (key) => key !== null && key !== undefined
    );
    const updatedAttachFileKeys = [...currentAttachFileKeys, ...attachFileKeys];

    const updatedData = {
      kms_know_key: props.knowViewItem.know.kms_know_key,
      // 독 업데이트
      // kms_doc_key: props.knowViewItem.know.kms_doc_key,
      kms_folder_key: props.knowViewItem.know.kms_folder_key,
      channel_key: props.knowViewItem.know.channel_key,
      lc_know_id: props.knowViewItem.know.lc_know_id,
      confirm_yn: props.knowViewItem.know.confirm_yn,
      title: localTitle.value,
      summary: localSummary.value.trim(), // 수정된 지식
      body: localBody.value,
      must_text: localMustText.value,
      keyword_list: localKeywordList.value,
      quest_list: localQuestList.value,
      // img_filekey_list: props.knowViewItem.img_filekey_list,
      // attach_filekey_list: props.knowViewItem.attach_filekey_list,

      // 새로 추가된 파일 처리
      // attach_filekey_list: [
      //   ...(props.knowViewItem?.know?.attach_filekey_list || []),
      //   ...attachFileKeys
      // ],
      // img_filekey_list: [
      //   ...(props.knowViewItem?.know?.img_filekey_list || []),
      //   ...imgFileKeys
      // ],

      attach_filekey_list: updatedAttachFileKeys,
      img_filekey_list: updatedImgFileKeys,

      // link_str_list: props.knowViewItem.know.link_str_list,
      // link_url_list: props.knowViewItem.know.link_url_list,
      // linkkey_list: props.knowViewItem.know.linkkey_list,
      linkkey_list: props.knowViewItem.know.linkkey_list,
      delete_yn: props.knowViewItem.know.delete_yn,
      valid_yn: localValidYn.value, // 🚀 valid_yn 추가
      refine_yn: 1
    };

    // 디버깅용 로그 추가
    console.log("API 호출 데이터:", updatedData);

    const result = await updateKmsKnow(updatedData);

    // 독 업데이트
    // if (store.selectedDocKey) {
    //   await store.loadKnows(
    //     store.currentChannel?.channel_key,
    //     store.selectedDocKey,
    //     1
    //   );
    // }
    if (store.selectedFolderKey) {
      await store.loadKnows(
        store.currentChannel?.channel_key,
        store.selectedFolderKey,
        1
      );
    }

    if (result) {
      $q.notify({
        message: "지식이 성공적으로 수정되었습니다.",
        color: "positive"
      });
      isEditing.value = false;

      // DB 업데이트 후 로컬 데이터 동기화
      props.knowViewItem.know.title = localTitle.value;
      props.knowViewItem.know.summary = localSummary.value;
      props.knowViewItem.know.body = localBody.value;
      props.knowViewItem.know.keyword_list = localKeywordList.value;
      props.knowViewItem.know.quest_list = localQuestList.value;
      props.knowViewItem.know.must_text = localMustText.value;
      // props.knowViewItem.know.attach_filekey_list =
      //   updatedData.attach_filekey_list;
      // props.knowViewItem.know.img_filekey_list = updatedData.img_filekey_list;
      props.knowViewItem.know.attach_filekey_list = updatedAttachFileKeys;
      props.knowViewItem.know.img_filekey_list = updatedImgFileKeys;
      props.knowViewItem.know.valid_yn = localValidYn.value; // 🚀 valid_yn 업데이트

      await fetchAdditionalDetails();

      localStorage.removeItem("tempAttachFiles");
      localStorage.removeItem("tempImageFiles");

      // 새로 추가된 파일 초기화
      newAttachFiles.value = [];
      newImageFiles.value = [];
      isEditing.value = false;
    } else {
      throw new Error("DB 업데이트에 실패했습니다.");
    }
  } catch (error) {
    console.error("내용 수정 중 오류 발생:", error);
    $q.notify({
      message: "수정 중 문제가 발생했습니다.",
      color: "negative"
    });
  } finally {
    // 로딩 완료
    emit("saveComplete");
    closePop();
  }
};

// const openDeletePopup = () => {
//   showDeletePopup.value = true;
// };

// const closeDeletePopup = () => {
//   showDeletePopup.value = false;
// };

// const deleteKmsKnowItem = async (kmsKey: string) => {
//   try {
//     await deleteKmsKnow(kmsKey);
//     alert("지식이 삭제되었습니다.");
//     emit("delete", kmsKey); // 삭제된 항목의 키를 상위로 전달
//     emit("close"); // 모달 닫기
//   } catch (error) {
//     console.error("삭제 중 오류 발생:", error);
//     alert("삭제 중 문제가 발생했습니다. 다시 시도해주세요.");
//   }
// };

// 이미지 삭제 함수
const removeImage = (index: number) => {
  // props.knowViewItem.img_filekey_list.splice(index, 1);

  // 삭제할 이미지 정보
  const imageToRemove = imageDetails.value[index];

  // 로컬 파일이면 newImageFiles에서도 제거
  if (imageToRemove.isLocal) {
    const fileIndex = newImageFiles.value.findIndex(
      (f) => f.name === imageToRemove.file_name
    );
    if (fileIndex !== -1) {
      newImageFiles.value.splice(fileIndex, 1);
    }

    // URL.revokeObjectURL로 메모리 해제
    URL.revokeObjectURL(imageToRemove.file_enc_path);
  }

  imageDetails.value.splice(index, 1);
};

// 첨부파일 삭제 함수
const removeAttachFile = (index: number) => {
  attachFileDetails.value.splice(index, 1);
};
// 파일 입력 참조
const fileInput = ref<HTMLInputElement | null>(null);

// 파일 업로드 트리거
const triggerFileUpload = () => {
  fileInput.value?.click();
};

// 파일 업로드 처리 함수
const handleFileUpload = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const file = target.files?.[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = () => {
      const result = reader.result as string;

      // 이미지 추가
      props.knowViewItem.img_filekey_list.push(result); // Base64 URL로 추가
    };
    reader.readAsDataURL(file);
  }
};

// 전체 삭제 함수
const removeAllImages = () => {
  props.knowViewItem.img_filekey_list = []; // 이미지 리스트 초기화
};

// 선택된 이미지 인덱스를 저장할 배열
const selectedImages = ref<number[]>([]);

// 선택 삭제 상태 관리 변수
const isSelecting = ref(false); // 선택 삭제 모드 여부

// 선택 모드 토글 함수
const toggleSelectingMode = () => {
  isSelecting.value = !isSelecting.value; // 선택 모드 전환
  if (!isSelecting.value) {
    selectedImages.value = []; // 선택 초기화
  }
};

// 이미지 선택 토글 함수
const toggleImageSelection = (index: number) => {
  if (selectedImages.value.includes(index)) {
    selectedImages.value = selectedImages.value.filter((i) => i !== index);
  } else {
    selectedImages.value.push(index);
  }

  console.log("선택된 이미지 인덱스:", selectedImages.value);
};

// 선택 삭제 함수
const removeSelectedImages = () => {
  if (selectedImages.value?.length === 0) {
    alert("선택된 이미지가 없습니다.");
    return;
  }

  // 선택된 이미지를 제외한 새로운 리스트 생성
  props.knowViewItem.img_filekey_list =
    props.knowViewItem.img_filekey_list.filter(
      (_, index) => !selectedImages.value.includes(index)
    );

  // 선택 초기화 및 선택 모드 종료
  selectedImages.value = [];
  isSelecting.value = false;

  console.log(
    "선택된 이미지가 삭제되었습니다. 현재 리스트:",
    props.knowViewItem.img_filekey_list
  );
};

// closePop 메서드 정의
const closePop = () => {
  // 현재 값과 로컬 값을 비교하여 변경 사항이 있는지 확인
  // const hasUnsavedChanges =
  //   localSummary.value !== props.knowViewItem.summary ||
  //   localBody.value !== props.knowViewItem.body ||
  //   JSON.stringify(localKeywordList.value) !==
  //     JSON.stringify(props.knowViewItem.keyword_list) ||
  //   JSON.stringify(localQuestList.value) !==
  //     JSON.stringify(props.knowViewItem.quest_list) ||
  //   localMustText.value !== props.knowViewItem.must_text;

  localStorage.removeItem("tempAttachFiles");
  localStorage.removeItem("tempImageFiles");
  localStorage.removeItem("tempLinkList");

  newAttachFiles.value = [];
  newImageFiles.value = [];
  newLinkList.value = [];

  // if (hasUnsavedChanges) {
  //   // 변경 사항이 있으면 사용자 확인 알림 표시
  //   const confirmClose = window.confirm(
  //     "수정 중인 내용이 저장되지 않았습니다. 정말 닫으시겠습니까?"
  //   );
  //   if (!confirmClose) {
  //     return; // 닫기 취소
  //   }
  // }

  // 편집 상태 초기화 및 close 이벤트 발생
  // isEditing.value = false;

  if (isEditing.value) {
    // 편집 모드 종료
    isEditing.value = false;

    // 원본 데이터로 다시 로드하여 변경사항 폐기
    fetchAdditionalDetails();
  }

  emit("close");
};

const compareData = ref({
  title: { original: "", generated: "" },
  summary: { original: "", generated: "" },
  keywords: { original: [], generated: [] },
  quests: { original: [], generated: [] }
});

const createPreAfunBtn = async () => {
  try {
    isGenerating.value = true;

    const sourceText = localBody.value;

    if (!sourceText || sourceText.trim()?.length === 0) {
      $q.notify({
        message: "내용을 입력해주세요.",
        color: "negative"
      });
      return;
    }

    const requestData = {
      source_text: sourceText.trim(),
      channel_key: store.currentChannel?.channel_key.toString()
    };

    console.log("전송 데이터:", requestData);

    const result = await createPreAfunTotalSummary(requestData);
    console.log("result", result);

    if (result) {
      localBody.value = sourceText;

      compareData.value = {
        title: {
          original: localTitle.value || props.knowViewItem.title,
          generated: result?.result?.chunk_list[0]?.title || ""
        },
        summary: {
          original: localSummary.value || props.knowViewItem.summary,
          generated: result?.result?.chunk_list[0]?.summary || ""
        },
        keywords: {
          original: [
            ...(localKeywordList.value?.length > 0
              ? localKeywordList.value
              : Array.isArray(props.knowViewItem?.know?.keyword_list)
                ? props.knowViewItem?.know?.keyword_list
                : [])
          ],
          generated: [
            ...(Array.isArray(result?.result?.chunk_list[0]?.keyword_list)
              ? result?.result?.chunk_list[0]?.keyword_list
              : [])
          ]
        },
        quests: {
          original: [
            ...(localQuestList.value?.length > 0
              ? localQuestList.value
              : Array.isArray(props.knowViewItem?.know?.quest_list)
                ? props.knowViewItem?.know?.quest_list
                : [])
          ],
          generated: [
            ...(Array.isArray(result?.result?.chunk_list[0]?.quest_list)
              ? result?.result?.chunk_list[0]?.quest_list
              : [])
          ]
        }
      };

      $q.notify({
        message: "AI가 제목, 지식, 키워드를 생성했습니다.",
        color: "positive"
      });

      showComparePopup.value = true;
    }
  } catch (error) {
    console.log("AFUN 처리 중 에러가 발생 ", error);
    $q.notify({
      message: "AI 자동 생성 중 오류가 발생했습니다.",
      color: "negative"
    });
  } finally {
    isGenerating.value = false;
  }
};

// const createPreAfunBtn = async () => {
//   try {
//     // 생성 시작 시 상태 변경
//     isGenerating.value = true;

//     // const sourceText = localBody.value || props.knowViewItem.body;
//     const sourceText = localBody.value;

//     if (!sourceText || sourceText.trim().length === 0) {
//       $q.notify({
//         message: "요약할 내용을 입력해주세요.",
//         color: "negative"
//       });
//       return;
//     }

//     const requestData = {
//       source_text: sourceText.trim(),
//       channel_key: store.currentChannel?.channel_key.toString()
//     };

//     console.log("전송 데이터:", requestData);

//     const result = await createPreAfunTotalSummary(requestData);
//     console.log("result", result);

//     if (result) {
//       // 내용은 항상 현재 값 유지
//       localBody.value = sourceText;

//       // 비교 데이터 설정을 원본 데이터로 초기화
//       compareData.value = {
//         title: {
//           original: localTitle.value || props.knowViewItem.title,
//           generated: result?.result?.chunk_list[0]?.title || ""
//         },
//         summary: {
//           original: localSummary.value || props.knowViewItem.summary,
//           generated: result?.result?.chunk_list[0]?.summary || ""
//         },
//         keywords: {
//           original: [
//             ...(localKeywordList.value.length > 0
//               ? localKeywordList.value
//               : props.knowViewItem?.know?.keyword_list)
//           ],
//           generated: [...(result?.result?.chunk_list[0]?.keyword_list || [])]
//         },
//         // quests: {
//         //   original: [
//         //     ...(localQuestList.value.length > 0
//         //       ? localQuestList.value
//         //       : props.knowViewItem?.know?.quest_list)
//         //   ],
//         //   generated: [...(result?.result?.chunk_list[0]?.quest_list || [])]
//         // }
//         quests: {
//           original: [
//             ...(localQuestList.value.length > 0
//               ? localQuestList.value
//               : props.knowViewItem?.know?.quest_list || [])
//           ],
//           generated: [...(result?.result?.chunk_list[0]?.quest_list || [])]
//         }
//       };

//       $q.notify({
//         message: "AI가 제목, 요약, 키워드를 생성했습니다.",
//         color: "positive"
//       });

//       showComparePopup.value = true;
//     }
//   } catch (error) {
//     console.log("AFUN 처리 중 에러가 발생 ", error);
//     $q.notify({
//       message: "AI 자동 생성 중 오류가 발생했습니다.",
//       color: "negative"
//     });
//   } finally {
//     // 생성 완료 시 상태 복원
//     isGenerating.value = false;
//   }
// };

const saveKnowDetail = (newBody: string) => {
  props.knowViewItem.know.body = newBody; // ✅ 실제 데이터에 반영
  // localBody.value = newBody; // 수정된 값 반영
};

const applyCompareSelection = (selectedData) => {
  // 선택되지 않은 항목은 현재 값을 유지
  if (selectedData.title) {
    localTitle.value =
      selectedData.title === "original"
        ? props.knowViewItem.title
        : compareData.value.title.generated;
  } else {
    // 선택되지 않은 경우 현재 편집 중인 값이나 원본 값 유지
    localTitle.value = localTitle.value || props.knowViewItem.title;
  }

  if (selectedData.summary) {
    localSummary.value =
      selectedData.summary === "original"
        ? props.knowViewItem.summary
        : compareData.value.summary.generated;
  } else {
    localSummary.value = localSummary.value || props.knowViewItem.summary;
  }

  if (selectedData.keywords) {
    localKeywordList.value =
      selectedData.keywords === "original"
        ? [...props.knowViewItem.keyword_list]
        : [...compareData.value.keywords.generated];
  } else {
    localKeywordList.value = [
      ...(localKeywordList.value?.length > 0
        ? localKeywordList.value
        : props.knowViewItem.keyword_list)
    ];
  }

  if (selectedData.quests) {
    localQuestList.value =
      selectedData.quests === "original"
        ? [...props.knowViewItem.quest_list]
        : [...compareData.value.quests.generated];
  } else {
    localQuestList.value = [
      ...(localQuestList.value?.length > 0
        ? localQuestList.value
        : props.knowViewItem.quest_list)
    ];
  }

  // 내용은 항상 현재 편집 중인 내용 또는 원본 내용 유지
  localBody.value = localBody.value || props.knowViewItem.body;

  // 수정 모드가 아니라면 수정 모드로 전환
  if (!isEditing.value) {
    isEditing.value = true;
  }

  $q.notify({
    message: "선택한 내용이 적용되었습니다.",
    color: "positive"
  });
};

// 삭제 버튼 클릭 시
const handleDelete = async () => {
  try {
    await deleteKmsKnow(props.knowViewItem.know.kms_know_key);
    emit("openDeleteKnowPopup", props.knowViewItem.know.kms_know_key); // 상위 컴포넌트에 삭제 알림
    // $q.notify({
    //   message: "지식이 삭제되었습니다.",
    //   color: "positive"
    // });
    closePop();
  } catch (error) {
    console.error("삭제 중 오류 발생:", error);
    $q.notify({
      message: "삭제 중 문제가 발생했습니다.",
      color: "negative"
    });
  }
};

// 링크 추가 토글
const toggleAddLink = () => {
  isAddingLink.value = !isAddingLink.value;
  if (!isAddingLink.value) {
    // 추가 취소 시 입력값 초기화
    newLinkName.value = "";
    newLinkUrl.value = "";
  }
};

// 새 링크 추가
const addNewLink = async () => {
  if (!newLinkName.value.trim() || !newLinkUrl.value.trim()) {
    $q.notify({
      message: "링크 이름과 URL을 모두 입력해주세요.",
      color: "negative"
    });
    return;
  }
  try {
    newLinkList.value.push({
      name: newLinkName.value.trim(),
      url: newLinkUrl.value.trim()
    });

    // 📌 **1. 새 링크 DB 저장**
    const linkData = {
      channel_key: props.knowViewItem.know.channel_key,
      link_name: newLinkName.value.trim(),
      link_url: newLinkUrl.value.trim()
    };

    const response = await createRepoLinkList(linkData);
    console.log("response", response);
    if (!response || !response.repo_link_key) {
      throw new Error("링크 저장 실패");
    }

    // 📌 **2. 기존 링크 리스트에 추가**
    const updatedLinkKeys = [
      ...(props.knowViewItem.know.linkkey_list || []),
      response.repo_link_key
    ];

    // 📌 **3. `updateKmsKnow` API 호출**
    const updatedData = {
      ...props.knowViewItem.know,
      linkkey_list: updatedLinkKeys,
      refine_yn: 1
    };

    const updateResponse = await updateKmsKnow(updatedData);
    if (updateResponse) {
      props.knowViewItem.know.linkkey_list = updatedLinkKeys;

      $q.notify({
        message: "링크가 추가되었습니다.",
        color: "positive"
      });

      newLinkName.value = "";
      newLinkUrl.value = "";
      isAddingLink.value = false;
    } else {
      throw new Error("지식 업데이트 실패");
    }
  } catch (error) {
    console.error("링크 추가 중 오류 발생:", error);
    $q.notify({
      message: "링크 추가 중 오류가 발생했습니다.",
      color: "negative"
    });
  }
};

// 링크 삭제
const removeLink = (index: number) => {
  props.knowViewItem.know.linkkey_list.splice(index, 1);
};

const keywordInput = ref(""); // 사용자 입력값
// 입력값을 처리하여 쉼표(,) 또는 공백을 기준으로 키워드를 자동 분리
const processKeywordInput = () => {
  if (!keywordInput.value) return;

  // 쉼표(`,`)와 공백(` `)을 기준으로 분리
  const keywords = keywordInput.value
    .split(/,|\s+/) // 쉼표 또는 공백이 있으면 분리
    .map((word) => word.trim()) // 앞뒤 공백 제거
    .filter(Boolean); // 빈 값 제거

  if (keywords?.length) {
    localKeywordList.value.push(...keywords);
  }

  keywordInput.value = ""; // 입력 필드 초기화
};

const questInput = ref(""); // 사용자 입력값
// 입력값을 처리하여 쉼표(,) 또는 공백을 기준으로 키워드를 자동 분리
const processQuestInput = () => {
  if (!questInput.value) return;

  // 쉼표(`,`)와 공백(` `)을 기준으로 분리
  const quests = questInput.value
    .split(/,|\s+/) // 쉼표 또는 공백이 있으면 분리
    .map((word) => word.trim()) // 앞뒤 공백 제거
    .filter(Boolean); // 빈 값 제거

  if (quests?.length) {
    localQuestList.value.push(...quests);
  }

  questInput.value = ""; // 입력 필드 초기화
};

// 붙여넣기 이벤트 처리
const handlePaste = (event: ClipboardEvent) => {
  event.preventDefault(); // 기본 붙여넣기 동작 방지
  const pastedText = event.clipboardData?.getData("text") || "";

  // 쉼표(`,`) 또는 공백(` `) 기준으로 즉시 분리하여 추가
  const keywords = pastedText
    .split(/,|\s+/)
    .map((word) => word.trim())
    .filter(Boolean);

  if (keywords?.length) {
    localKeywordList.value.push(...keywords);
  }
};

const localValidYn = ref(props.knowViewItem?.know.valid_yn ?? 1); // 기본 값 없을 때 '중'으로 설정

watch(
  () => props.knowViewItem?.know.valid_yn,
  (newVal) => {
    if (newVal !== undefined && newVal !== null) {
      localValidYn.value = newVal;
    }
  }
);

const getPriorityText = (validYn) => {
  switch (validYn) {
    case 9:
      return "상";
    case 1:
      return "중";
    case 0:
      return "하";
    default:
      return "-";
  }
};
const detailKnowPopYn = ref(false);

const openDetailKnow = () => {
  detailKnowPopYn.value = true;
};
</script>

<template>
  <!-- 모달 배경 -->
  <!-- <div v-if="props.show" class="modal-overlay" @click="closePop"></div> -->
  <div v-if="props.show" class="modal-overlay"></div>
  <!-- <div v-if="props.show" class="modal-overlay" @click="$emit('close')"></div> -->
  <!-- 팝업 콘텐츠 -->
  <div v-if="props.show && props.knowViewItem" class="view-popup">
    <div class="popup-content">
      <div class="bottom-line">
        <div class="popup-header">
          <span>지식 카드</span>
          <!-- <button class="close-button" @click="closePop">X</button> -->
          <div @click="closePop" class="cursorP">
            <CloseIcon />
          </div>
          <!-- <button class="close-button" @click="$emit('close')">X</button> -->
        </div>
      </div>
      <!-- AFUN 처리 -->
      <div class="popup-body">
        <div class="form-group afunWrap">
          <div>
            <label>AI 자동 생성</label>
            <p class="afunBody">* AFUN - AI가 자동으로 생성합니다</p>
          </div>
          <button
            v-if="isEditing"
            @click="createPreAfunBtn"
            type="button"
            :disabled="isGenerating"
            class="ai-button"
          >
            {{ isGenerating ? "생성 중..." : "생성 시작" }}
          </button>
          <div v-if="isGenerating" class="spinner-overlay">
            <div class="spinner"></div>
            <p>생성 중입니다...</p>
          </div>
        </div>
        <div class="current-path">
          <!-- <p>중</p> -->

          <p>{{ props.folderAndGroupName }}</p>
        </div>

        <div class="scroll">
          <div class="knowFileNamep-wrap">
            <div v-if="!isEditing" class="fileNameWrap">
              <div class="textWrapBox">
                <h6><KnowList /> 제목</h6>
                <p
                  class="priority-badge"
                  :class="'priority-' + props.knowViewItem.know.valid_yn"
                >
                  {{ getPriorityText(props.knowViewItem.know.valid_yn) }}
                </p>
                <div class="know-key">
                  {{ props.knowViewItem.know.kms_know_key }}
                </div>
              </div>
              <div>
                <p>{{ props.knowViewItem.know.title }}</p>
              </div>
              <!-- <p><KnowList /> {{ props.knowViewItem.know.title }}</p> -->
            </div>
            <div v-else class="fileNameWrap">
              <div class="textWrapBox">
                <h6><KnowList /> 제목</h6>
                <p>
                  <select v-model="localValidYn" class="search-select">
                    <option :value="9">상</option>
                    <option :value="1">중</option>
                    <option :value="0">하</option>
                  </select>
                </p>
              </div>
              <input type="text" v-model="localTitle" />
            </div>

            <!-- currentPath 표시 -->
            <div class="knowNavWrapRight">
              <!-- <div class="current-path">
                <p>{{ props.folderAndGroupName }}</p>
              </div> -->
              <div>
                <!-- 파일 원본 형식 -->
                <!-- <button class="edit-button">
                      {{ props.knowViewItem.doc_type }}
                    </button> -->
                <!-- 하드코딩 -->
                <!-- <button class="file-button">PDF</button> -->
              </div>
            </div>
          </div>

          <!-- 지식 박스 -->
          <div class="content-box">
            <div class="content-body-wrap">
              <h6><SummaryIcon /> 내용</h6>
              <button @click="openDetailKnow"><ContentIcon /> 원본 보기</button>
            </div>
            <div v-if="isEditing">
              <!-- 수정 모드 -->
              <textarea
                v-model="localSummary"
                class="edit-textarea summaryText"
                placeholder="지식을 입력하세요"
              ></textarea>
              <!-- <button @click="saveSummary">저장</button>
            <button @click="isEditing = false">취소</button> -->
            </div>
            <div v-else class="summaryText">
              <!-- 읽기 모드 -->
              <p>{{ props.knowViewItem.know.summary }}</p>
              <!-- <button @click="isEditing = true">수정</button> -->
            </div>
          </div>
          <KmsKnowDetailPop
            v-if="detailKnowPopYn"
            :show="detailKnowPopYn"
            :knowBody="props.knowViewItem.know.body"
            @close="detailKnowPopYn = false"
            @save="saveKnowDetail"
            @toggle="toggleEditMode"
          />

          <!-- 내용 박스 -->
          <!-- <div class="content-box">
            <h6><ContentIcon /> 원본 내용</h6>
            <div v-if="isEditing">
              <textarea
                v-model="localBody"
                class="edit-textarea bodyTextArea"
                placeholder="원본 내용을 입력하세요"
              ></textarea>
            </div>
            <div v-else>
              <p>{{ props.knowViewItem.know.body }}</p>
            </div>
          </div> -->

          <!-- 필수 표시 박스 -->
          <div class="content-box">
            <h6><MustTextIcon /> 필수 표시</h6>
            <div v-if="isEditing">
              <!-- 수정 모드 -->
              <textarea
                v-model="localMustText"
                class="edit-textarea"
                placeholder="필수 표시 내용을 입력하세요"
              ></textarea>
            </div>
            <div v-else>
              <!-- 읽기 모드 -->
              <p>{{ props.knowViewItem.know.must_text }}</p>
            </div>
          </div>

          <!-- 이미지 박스 -->
          <!-- <div class="content-box">
          <div class="menuWrap">
            <div>
              <h6>
                이미지 ({{ props.knowViewItem.img_filekey_list?.length }})
              </h6>
            </div>
            <div>
              <button class="add-button" @click="removeAllImages">
                전체 삭제
              </button>
              <button
                class="add-button"
                @click="
                  isSelecting ? removeSelectedImages() : toggleSelectingMode()
                "
              >
                {{ isSelecting ? "선택 삭제" : "선택 모드" }}
              </button>

              <button class="add-button" @click="triggerFileUpload">
                <Add />
              </button>
              <input
                type="file"
                accept="image/*"
                ref="fileInput"
                @change="handleFileUpload"
                style="display: none"
              />
            </div>
          </div>
          <div v-if="props.knowViewItem.img_filekey_list?.length > 0">
            <div class="image-container">
              <div
                v-for="(image, index) in props.knowViewItem.img_filekey_list"
                :key="index"
                class="image-item"
              >
                <img :src="image" alt="첨부 이미지" class="viewImg" />
                <button class="delete-button" @click="removeImage(index)">
                  <Delete />
                </button>
                <input
                  v-if="isSelecting"
                  type="checkbox"
                  :checked="selectedImages.includes(index)"
                  @change="toggleImageSelection(index)"
                />
              </div>
            </div>
          </div>
          <p v-else>이미지가 없습니다.</p>
          </div> -->

          <div class="content-box">
            <div class="menuWrap">
              <!-- <div>
                <h6>
                  <ImageIcon /> 이미지 ({{
                    props.knowViewItem.img_filekey_list?.length
                  }})
                </h6>
              </div> -->
              <div>
                <h6>
                  <ImageIcon /> 이미지 ({{
                    imageDetails?.length + newImageFiles?.length
                  }})
                </h6>
              </div>
              <div v-if="isEditing">
                <input
                  type="file"
                  ref="imageFileInput"
                  style="display: none"
                  @change="handleNewImageUpload"
                  accept="image/*"
                  multiple
                />
                <button class="add-button" @click="imageFileInput.click()">
                  <Add /> <span>추가</span>
                </button>
              </div>
              <!-- 남서울대 내부 테스트를 위해 우선 주석 -->
              <!-- <div v-if="isEditing">
                <button class="add-button" @click="removeAllImages">
                  전체 삭제
                </button>
                <button
                  class="add-button"
                  @click="
                    isSelecting ? removeSelectedImages() : toggleSelectingMode()
                  "
                >
                  {{ isSelecting ? "선택 삭제" : "선택 모드" }}
                </button>

                <button class="add-button" @click="navigateToRepoImage">
                  <Add />
                  <span>관리</span>
                </button>
                <input
                  type="file"
                  accept="image/*"
                  ref="fileInput"
                  @change="handleFileUpload"
                  style="display: none"
                />
              </div> -->
            </div>
            <!-- <div v-if="props.knowViewItem.img_filekey_list?.length > 0">
              <div class="image-container">
                <div
                  v-for="(image, index) in props.knowViewItem.img_filekey_list"
                  :key="index"
                  class="image-item"
                >
                  <img :src="image" alt="첨부 이미지" class="viewImg" />
                  <button class="delete-button" @click="removeImage(index)">
                    <Delete />
                  </button>
                  <input
                    v-if="isSelecting"
                    type="checkbox"
                    :checked="selectedImages.includes(index)"
                    @change="toggleImageSelection(index)"
                  />
                </div>
              </div>
            </div> -->
            <div v-if="imageDetails?.length > 0" class="image-container">
              <div
                v-for="(image, index) in imageDetails"
                :key="index"
                class="image-item"
              >
                <!-- <img
                  :src="image?.file_enc_path"
                  :alt="image?.file_name"
                  class="viewImg"
                /> -->
                <!-- <img
                  :src="
                    image?.file_enc_path
                      ? `https://anytalk.com:28081/uploads/${image.file_enc_path.replaceAll('\\', '/')}`
                      : ''
                  "
                  :alt="image?.file_name"
                  class="viewImg"
                /> -->
                <img
                  :src="
                    image.isLocal
                      ? image.file_enc_path
                      : `https://anytalk.com/uploads/${image.file_enc_path.replaceAll('\\', '/')}`
                  "
                  :alt="image?.file_name"
                  class="viewImg"
                />

                <button
                  v-if="isEditing"
                  class="delete-button"
                  @click="removeImage(index)"
                >
                  x
                </button>
                <div class="file-name">{{ image?.file_name }}</div>
              </div>
            </div>
            <!-- 새로 추가된 이미지 목록 -->
            <!-- <div v-if="newImageFiles?.length > 0" class="image-container">
              <div
                v-for="(file, index) in newImageFiles"
                :key="file.name"
                class="image-item"
              >
                <img
                  :src="
                    file && window?.URL ? window?.URL.createObjectURL(file) : ''
                  "
                  :alt="file ? file.name : ''"
                  class="viewImg"
                />
                <button
                  v-if="isEditing"
                  class="delete-button"
                  @click="removeNewImageFile(index)"
                >
                  x
                </button>
              </div>
            </div> -->

            <p v-if="imageDetails?.length === 0 && newImageFiles?.length === 0">
              이미지가 없습니다.
            </p>
            <!-- <p v-else>이미지가 없습니다.</p> -->
          </div>

          <!-- 키워드 박스 기존 -->
          <!-- <div class="content-box">
          <h6>키워드 ({{ props.knowViewItem.keyword_list.length }})</h6>
          <div v-if="isEditing">
            <div class="keyword-edit-container">
              <div
                v-for="(keyword, index) in localKeywordList"
                :key="index"
                class="keyword-chip"
              >
                {{ keyword }}
                <button
                  @click="removeKeyword(index)"
                  class="remove-keyword-btn"
                >
                  ×
                </button>
              </div>
              <button @click="addKeyword" class="add-keyword-btn">
                + 키워드 추가
              </button>
            </div>
          </div>
          <div v-else>
            <p>{{ props.knowViewItem.keyword_list.join(", ") }}</p>
          </div>
          </div> -->
          <!-- 키워드 박스 수정 -->
          <div class="content-box">
            <h6>
              <KeywordIcon /> 키워드 ({{
                props.knowViewItem?.know.keyword_list?.length
              }})
            </h6>

            <TagsInputRoot
              v-if="isEditing"
              v-model="localKeywordList"
              class="tags-input-root"
            >
              <TagsInputItem
                v-for="item in localKeywordList"
                :key="item"
                :value="item"
                class="tags-input-item"
              >
                <!-- <TagsInputItemText class="tags-input-item-text" /> -->
                <TagsInputItemText class="tags-input-item-text">{{
                  item
                }}</TagsInputItemText>
                <TagsInputItemDelete class="tags-delete-button">
                  <!-- <Icon icon="lucide:x" /> -->
                  x
                </TagsInputItemDelete>
              </TagsInputItem>
              <TagsInputInput
                v-model="keywordInput"
                placeholder="키워드 입력"
                class="tags-input-input"
                @input="processKeywordInput"
                @paste="handlePaste"
              />
            </TagsInputRoot>
            <div v-else class="repo-button">
              <!-- <p>{{ props.knowViewItem?.know.keyword_list?.join(", ") }}</p> -->
              <p>{{ props.knowViewItem?.know.keyword_list?.join(", ") }}</p>
            </div>

            <!-- <div v-if="isEditing">
            <div class="keyword-edit-container">
              <div
                v-for="(keyword, index) in localKeywordListlocalKeywordList"
                :key="index"
                class="keyword-chip"
              >
                <template v-if="editingKeywordIndex === index">
                  <input
                    v-model="editedKeywordInput"
                    type="text"
                    class="edit-keyword-input"
                    placeholder="키워드 수정"
                    @keyup.enter="saveEditedKeyword"
                    @keyup.esc="cancelEditKeyword"
                  />
                  <div class="keyword-input-actions">
                    <button @click="saveEditedKeyword" class="save-keyword-btn">
                      ✓
                    </button>
                    <button
                      @click="cancelEditKeyword"
                      class="cancel-keyword-btn"
                    >
                      ✗
                    </button>
                  </div>
                </template>
                <template v-else>
                  {{ keyword }}
                  <button @click="editKeyword(index)" class="edit-keyword-btn">
                    ✎
                  </button>
                  <button
                    @click="removeKeyword(index)"
                    class="remove-keyword-btn"
                  >
                    ×
                  </button>
                </template>
              </div>

              <div v-if="isAddingKeyword" class="new-keyword-input-container">
                <input
                  v-model="newKeywordInput"
                  @keyup.enter="saveNewKeyword"
                  @keyup.esc="cancelAddKeyword"
                  type="text"
                  class="new-keyword-input"
                  placeholder="새 키워드 입력"
                  autofocus
                />
                <div class="keyword-input-actions">
                  <button @click="saveNewKeyword" class="save-keyword-btn">
                    ✓
                  </button>
                  <button @click="cancelAddKeyword" class="cancel-keyword-btn">
                    ✗
                  </button>
                </div>
              </div>

              <button v-else @click="addKeyword" class="add-keyword-btn">
                + 키워드 추가
              </button>
            </div>
          </div> -->
            <!-- <div v-else>
            <p>{{ props.knowViewItem.keyword_list.join(", ") }}</p>
          </div> -->
          </div>

          <!-- 추천 질문 박스 수정 -->
          <div class="content-box">
            <h6>
              <KeywordIcon /> 추천 질문 ({{
                props.knowViewItem?.know.quest_list?.length || 0
              }})
            </h6>

            <TagsInputRoot
              v-if="isEditing"
              v-model="localQuestList"
              class="tags-input-root"
            >
              <TagsInputItem
                v-for="item in localQuestList"
                :key="item"
                :value="item"
                class="tags-input-item"
              >
                <!-- <TagsInputItemText class="tags-input-item-text" /> -->
                <TagsInputItemText class="tags-input-item-text">{{
                  item
                }}</TagsInputItemText>
                <TagsInputItemDelete class="tags-delete-button">
                  <!-- <Icon icon="lucide:x" /> -->
                  x
                </TagsInputItemDelete>
              </TagsInputItem>
              <TagsInputInput
                v-model="questInput"
                placeholder="추천질문 입력"
                class="tags-input-input"
                @input="processQuestInput"
                @paste="handlePaste"
              />
            </TagsInputRoot>
            <div v-else class="repo-button">
              <!-- <p>{{ props.knowViewItem?.know.keyword_list?.join(", ") }}</p> -->
              <p>{{ props.knowViewItem?.know.quest_list?.join(", ") }}</p>
            </div>

            <!-- <div v-if="isEditing">
            <div class="keyword-edit-container">
              <div
                v-for="(keyword, index) in localKeywordList"
                :key="index"
                class="keyword-chip"
              >
                <template v-if="editingKeywordIndex === index">
                  <input
                    v-model="editedKeywordInput"
                    type="text"
                    class="edit-keyword-input"
                    placeholder="키워드 수정"
                    @keyup.enter="saveEditedKeyword"
                    @keyup.esc="cancelEditKeyword"
                  />
                  <div class="keyword-input-actions">
                    <button @click="saveEditedKeyword" class="save-keyword-btn">
                      ✓
                    </button>
                    <button
                      @click="cancelEditKeyword"
                      class="cancel-keyword-btn"
                    >
                      ✗
                    </button>
                  </div>
                </template>
                <template v-else>
                  {{ keyword }}
                  <button @click="editKeyword(index)" class="edit-keyword-btn">
                    ✎
                  </button>
                  <button
                    @click="removeKeyword(index)"
                    class="remove-keyword-btn"
                  >
                    ×
                  </button>
                </template>
              </div>

              <div v-if="isAddingKeyword" class="new-keyword-input-container">
                <input
                  v-model="newKeywordInput"
                  @keyup.enter="saveNewKeyword"
                  @keyup.esc="cancelAddKeyword"
                  type="text"
                  class="new-keyword-input"
                  placeholder="새 키워드 입력"
                  autofocus
                />
                <div class="keyword-input-actions">
                  <button @click="saveNewKeyword" class="save-keyword-btn">
                    ✓
                  </button>
                  <button @click="cancelAddKeyword" class="cancel-keyword-btn">
                    ✗
                  </button>
                </div>
              </div>

              <button v-else @click="addKeyword" class="add-keyword-btn">
                + 키워드 추가
              </button>
            </div>
          </div> -->
            <!-- <div v-else>
            <p>{{ props.knowViewItem.keyword_list.join(", ") }}</p>
          </div> -->
          </div>

          <!-- 첨부파일 박스 -->
          <div class="content-box">
            <div class="menuWrap">
              <div>
                <h6>
                  <!-- <AttachIcon /> 첨부파일 ({{ attachFileDetails.length }}) -->
                  <AttachIcon /> 첨부파일 ({{
                    attachFileDetails?.length + newAttachFiles?.length
                  }})
                </h6>
              </div>
              <div v-if="isEditing">
                <input
                  type="file"
                  ref="attachFileInput"
                  style="display: none"
                  @change="handleNewAttachFileUpload"
                  multiple
                />
                <button class="add-button" @click="attachFileInput.click()">
                  <Add /> <span>추가</span>
                </button>
              </div>
              <!-- repo file 우선 주석 -->
              <!-- <div>
                <button class="add-button" @click="navigateToRepoFile">
                  <Add />
                  <span>관리</span>
                </button>
              </div> -->
            </div>
            <div v-if="attachFileDetails?.length > 0">
              <button
                v-for="(file, index) in attachFileDetails"
                :key="file.file_key"
                class="refo-button"
              >
                <button
                  v-if="isEditing"
                  class="delete-button"
                  @click="removeAttachFile(index)"
                >
                  x
                </button>
                {{ file?.file_name }}
              </button>
            </div>
            <!-- 새로 추가된 파일 목록 -->
            <div v-if="newAttachFiles?.length > 0">
              <button
                v-for="(file, index) in newAttachFiles"
                :key="index"
                class="refo-button"
              >
                {{ file?.file_name }}
                <button
                  v-if="isEditing"
                  class="delete-button"
                  @click="removeNewAttachFile(index)"
                >
                  x
                </button>
                <div class="file-name">{{ file.name }}</div>
              </button>
            </div>

            <p
              v-if="
                attachFileDetails?.length === 0 && newAttachFiles?.length === 0
              "
            >
              첨부 파일이 없습니다.
            </p>
            <!-- <p v-else>첨부 파일이 없습니다.</p> -->
          </div>
          <!-- <div class="content-box">
            <div class="menuWrap">
              <div>
                <h6>
                  <AttachIcon /> 첨부파일 ({{
                    props.knowViewItem.attach_filekey_list?.length
                  }})
                </h6>
              </div>
              <div>
                <button class="add-button" @click="navigateToRepoFile">
                  <Add />
                  <span>관리</span>
                </button>
              </div>
            </div>
            <button class="refo-button">2024년 성적 장학금.pdf</button>
          </div> -->

          <!-- 바로가기 링크 박스 -->
          <div class="content-box">
            <div class="menuWrap">
              <div>
                <h6>
                  <LinkIcon /> 바로가기 링크({{
                    props.knowViewItem.know.linkkey_list?.length
                  }})
                </h6>
              </div>
              <div v-if="isEditing">
                <button class="add-button" @click="toggleAddLink">
                  <Add /> <span>추가</span>
                </button>
              </div>
              <!-- 남서울대 내부 테스트를 위해 우선 주석 -->
              <!-- <div>
              <button class="add-button" @click="navigateToRepoLink">
                <Add />
                <span>관리</span>
              </button>
            </div> -->
            </div>

            <!-- <p v-if="localLinks?.length === 0">바로가기 링크가 없습니다.</p> -->

            <!-- 링크 추가 폼 -->
            <div v-if="isEditing && isAddingLink" class="link-form">
              <input
                v-model="newLinkName"
                type="text"
                class="link-input"
                placeholder="링크 텍스트를 입력하세요"
              />
              <input
                v-model="newLinkUrl"
                type="text"
                class="link-input"
                placeholder="URL을 입력하세요"
              />
              <div class="link-buttons">
                <button @click="addNewLink" class="submit-btn">추가</button>
                <button @click="toggleAddLink" class="cancel-btn">취소</button>
              </div>
            </div>

            <div
              v-if="props.knowViewItem?.link_list?.length"
              class="linkListWrap"
            >
              <div
                v-for="(link, index) in props.knowViewItem?.link_list"
                :key="index"
                class="link-item"
              >
                <a :href="link.link_url" target="_blank">{{
                  link.link_name
                }}</a>
                <button
                  v-if="isEditing"
                  class="delete-button"
                  @click="removeLink(index)"
                >
                  x
                </button>
              </div>
            </div>
            <!-- <p v-if="props.knowViewItem.know.link_list?.length === 0">
              링크가 없습니다.
            </p> -->
            <p v-else>바로가기 링크가 없습니다.</p>

            <!-- <div class="linkListWrap">
              <div
                v-for="(link, index) in props.knowViewItem?.know?.link_list"
                :key="index"
                class="link-item"
              >
                <a :href="link.link_url" target="_blank" class="link-text">
                  {{ link.link_name }}
                </a>
                <button
                  v-if="isEditing"
                  class="delete-link-btn"
                  @click="removeLink(index)"
                >
                  <Delete />
                </button>
              </div>
            </div> -->
            <!-- <ul>
              <div class="linkListWrap">
                <li
                  v-for="(link, index) in props.knowViewItem.link_list"
                  :key="index"
                  class="link-item"
                >
                  <a
                    :href="`${link.link_url}`"
                    target="_blank"
                    class="link-text"
                  >
                    {{ link.link_name }}
                  </a>
                </li>
              </div>
            </ul> -->
          </div>
        </div>
      </div>

      <!-- 하단 버튼 -->
      <div class="popup-actions">
        <button
          class="edit-button"
          @click="isEditing ? saveKmsKnow() : toggleEditMode()"
        >
          {{ isEditing ? "저장" : "수정" }}
        </button>
        <!-- <button class="close-button" @click="$emit('openDeleteKnowPopup')">
          삭제
        </button> -->
        <!-- <button class="close-button" @click="handleDelete">삭제</button> -->
        <button class="close-button" @click="$emit('openDeleteKnowPopup')">
          삭제
        </button>
        <button class="close-button" @click="closePop">닫기</button>
        <!-- <button class="close-button" @click="$emit('close')">닫기</button> -->
      </div>
    </div>
  </div>

  <KmsCompareAfun
    v-if="showComparePopup"
    :show="showComparePopup"
    :compare-data="compareData"
    @close="showComparePopup = false"
    @apply="applyCompareSelection"
  />
</template>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
}

/* .view-popup {
  padding: 20px;
  width: 40%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
} */

/* .view-popup {
  padding: 20px;
  width: 600px;
  max-width: 90vw;
  height: auto;
  min-height: 400px;
  max-height: 90vh;
  overflow-y: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
} */
.view-popup {
  padding: 20px;
  width: 600px;
  max-width: 90vw;
  height: auto; /* 높이를 auto로 두되 */
  max-height: 90vh; /* 화면 높이의 90%로 제한 */
  overflow: hidden; /* 내부에서만 스크롤 */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.popup-content {
  /* padding: 0 20px; */
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 16px 0;
}

.popup-header h5 {
  margin: 0;
}

.popup-header span {
  font-size: 20px;
  font-weight: 800;
}

.know-nav-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* popup-body 스크롤 설정 */
.popup-body {
  overflow-y: scroll;
  scrollbar-width: thin; /* 
  max-height: calc(90vh - 150px); /* 상하단 여백 포함한 높이 */
  padding: 15px 0;
  /* height: 680px; */
  max-height: calc(90vh - 150px); /* 상하단 여백 포함한 최대 높이 */
}
/* .popup-body {
  margin: 20px 0;
} */

/* 공통 스타일 */
.content-box {
  margin: 20px 0;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.content-box ul {
  gap: 10px;
  list-style: none;
}

.content-box h6 {
  margin: 5px 0;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}
.content-body-wrap {
  display: flex;
  justify-content: space-between;
}

.content-body-wrap button {
  /* min-width: 10px; */
  font-weight: 500;
  background: none !important;
  box-shadow: none !important;
}

.content-box p {
  margin: 0;
  font-size: 16px;
  color: #555;
}

.edit-textarea {
  width: 100%;
  height: 80px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
}
.summaryText {
  min-height: 150px;
}

.bodyTextArea {
  min-height: 200px;
}

.edit-button,
.save-button,
.basic-button,
.file-button {
  /* margin-top: 10px; */
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-button {
  background-color: #51c2b8;
  color: white;
  font-weight: bold;
}

/* .file-button {
  display: inline-block;
  background-color: #f2f2f2;
  color: rgb(115, 115, 115);
  border: 1px solid #a3a3a3;
  font-size: 14px;
  margin-right: 12px;
  text-decoration: none;
  cursor: pointer;
} */

.file-button {
  padding: 2px 2px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8em !important;
  font-weight: 600;
  background-color: #545454;
  color: white;
}

.refo-button {
  padding: 3px 3px;
  border: none;
  border-radius: 4px;
  /* border: 1px solid #959595; */
  cursor: pointer;
  font-size: 1em !important;
  background-color: #ffffff;
  color: #545454;
  text-decoration: none;
  margin-right: 10px;
  margin-bottom: 5px;
}

.save-button {
  background-color: #28a745;
  color: white;
}

.close-button {
  min-width: 40px;
  width: auto;
  text-align: center;
  background-color: rgb(236, 236, 236);
  box-shadow: 0 2px 2px 1px rgba(184, 184, 184, 0.3);
  font-size: 15px !important;
  font-weight: 600;
}

.edit-button:hover {
  background-color: #1e9187;
}

.save-button:hover {
  background-color: #218838;
}
.sbutton {
  /* margin-top: 5px; */
  padding: 2px 2px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8em !important;
  background-color: #6c6c6c;
  color: white;
}

.sbutton:hover {
  background-color: #1e9187;
}

.popup-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 30px 0 5px 0;

  button {
    padding: 8px 24px;
  }
}

.keyword-edit-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.keyword-chip {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 16px;
  padding: 4px 10px;
  font-size: 12px;
}

.remove-keyword-btn {
  background: none;
  border: none;
  color: red;
  margin-left: 5px;
  cursor: pointer;
}

.add-keyword-btn {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  cursor: pointer;
}

.new-keyword-input-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.new-keyword-input {
  flex-grow: 1;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
}

.keyword-input-actions {
  display: flex;
  gap: 5px;
}

.save-keyword-btn,
.cancel-keyword-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.save-keyword-btn {
  color: green;
}

.cancel-keyword-btn {
  color: red;
}

.edit-keyword-input {
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  width: 100px;
}

.edit-keyword-btn {
  background: none;
  border: none;
  color: #007bff;
  margin-left: 5px;
  cursor: pointer;
  font-size: 12px;
}

.viewImg {
  width: 220px;
  height: 140px;
  border-radius: 4px;
  background-color: #ffffff;
  color: #545454;
  box-shadow: 0 2px 2px 1px rgba(184, 184, 184, 0.3);
  /* text-decoration: none; */
  /* margin-right: 10px; */
  /* border: 1px solid black; */
}

.img-wrap {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.menuWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 5px; */
}

/* 이미지 삭제 */
.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-item {
  position: relative;
}

/* .content-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
} */

/* 이미지, 첨부파일, 키워드, 링크 삭제 버튼 통일 */
.delete-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: #888;
  cursor: pointer;
  padding: 2px 6px;
  min-width: 20px;
  min-height: 15px;
  /* border-radius: 50%; 원형으로 변경 */
  transition:
    background-color 0.2s ease,
    color 0.2s ease;
}

.delete-button:hover {
  color: #333;
  background-color: rgba(0, 0, 0, 0.1);
}

/* 첨부파일, 이미지 삭제 버튼 위치 조정 */
.image-item .delete-button,
.file-name .delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
}

/* .link-item .remove-btn {
  margin-left: 8px;
} */

/* .delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
} */

/* .add-button {
  padding: 4px 2px;
  background-color: white;
  border: 1px solid #a5a5a5;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1em !important;
  color: #4e4e4e;
} */

/* .delete-button {
  padding: 4px 4px;
  background-color: white;
  border: 1px solid #a5a5a5;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1em !important;
  color: #4e4e4e;
} */

.fileNameWrap {
  padding: 15px;
  border-radius: 3px;
  font-size: 1.1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  font-weight: 700;
  gap: 2px;
  width: 100%;
  background-color: #e9ecef;
  align-items: flex-start;
}

.textWrapBox {
  display: flex;
  gap: 10px;
  align-items: center;
}

.fileNameWrap h6 {
  margin: 2px 0;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.fileNameWrap p {
  font-weight: 600;
}

.fileNameWrap input {
  width: 100%;
  border: 1px solid #ddd;
}

.bottom-line {
  /* margin-bottom: 12px; */
  border-bottom: 1px solid #cccccc;
}

.current-path {
  display: flex;
  /* margin: 1rem 0; */
  padding: 0px 0px 0.6rem 0px;
  /* background-color: #f8f9fa; */
  border-radius: 4px;
  font-size: 0.9em;
  color: #666;
  justify-content: flex-end;
}

.knowFileNamep-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin: 4px 0;
  /* padding-left: 10px; */
  background-color: #e9ecef;
}

.knowNavWrapRight {
  display: flex;
  align-items: center;
}

/* 키워드 영역 css */
.tags-input-root {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border: 1px solid rgba(190, 190, 190, 0.7);
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 480px;
  flex-wrap: wrap;
  background-color: white;
}

.tags-input-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: #dcdcdc; /* 연한 회색으로 변경 */
  color: #000 !important; /* 어두운 텍스트 색상 */
  border-radius: 0.6rem;
  padding: 0.05rem 0.1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  /* color: white; */
}

.tags-delete-button {
  min-height: 5px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: #888;
  cursor: pointer;
  padding: 0px 1px;
  min-width: 15px;
  /* height: 100%; */
  /* border-radius: 50%; 원형으로 변경 */
  font-size: 13px !important;
  transition:
    background-color 0.2s ease,
    color 0.2s ease;
}

.tags-delete-button:hover {
  color: #333;
  background-color: rgba(0, 0, 0, 0.1);
}

.tags-input-item[aria-current="true"] {
  background-color: #383838;
}

.tags-input-item-text {
  font-size: 0.875rem;
  padding-left: 0.25rem;
}

.tags-input-item-delete {
  padding: 0.125rem;
  border-radius: 0.25rem;
  background-color: transparent;
  /* color: white; */
  color: #666; /* 삭제 버튼 색상 변경 */
  transition: background-color 0.2s ease;
  /* margin-left: 4px; */
}

.tags-input-item-delete:hover {
  background-color: rgba(255, 250, 250, 0.25);
  color: #333;
}

.tags-input-input {
  font-size: 0.875rem;
  flex: 1;
  border: none;
  outline: none;
  border-radius: 0.25rem;
  background-color: transparent;
  color: gray;
  padding-left: 0.25rem;
  placeholder-color: #a0a0a0;
}

.form-group {
  margin: 2px 0 15px 0;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.afunWrap {
  display: flex;
  justify-content: space-between;
  background-color: #eefffb;
  /* border: 4px solid #b2f4e3; */
  border-radius: 5px;
  padding: 10px;
}

.afunWrap button {
  background-color: #b2f4e3;
  /* font-weight: 700; */
  height: 30px;
  place-self: center;
}

.afunWrap button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
  position: relative;
}

.generation-button-container {
  position: relative;
  display: flex;
  align-items: center;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #51c2b8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.afunBody {
  color: #666;
}
.ai-button {
  font-size: clamp(0.8em, 2vw, 1em) !important;
  font-weight: bold !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 90px;
  padding: 0 8px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-overlay p {
  color: white;
  margin-top: 15px;
  font-size: 18px;
}

.scroll {
  /* overflow-y: auto; */
  /* max-height: calc(100vh - 150px); */
  /* height: 590px; */
  /* height: 550px; */
  /* height: 55vh; */
}

/* .linkListWrap {
  display: flex;
  gap: 10px;
} */

.linkListWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}

.linkListWrap a {
  background-color: white;
  color: black;
  font-weight: 600;
  border-radius: 5px;
  padding: 5px 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.linkListWrap a:hover {
  transform: scale(1.02);
  background-color: rgb(165, 165, 165);
  transition: all 0.2s ease; /* 부드러운 전환 효과 */
  /* transition: transform 0.5s; */
  color: #000; /* 텍스트 색상 진하게 */
}

.link-form {
  margin-top: 15px;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.link-input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.link-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.submit-btn {
  background-color: #51c2b8;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.cancel-btn {
  background-color: #f5f5f5;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.link-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.save-link-btn,
.cancel-link-btn {
  padding: 0.25rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
}

.save-link-btn {
  background-color: #51c2b8;
  color: white;
}

.cancel-link-btn {
  background-color: #e2e2e2;
  color: #666;
}

/* .link-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
} */

.link-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.delete-link-btn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #666;
}

.delete-link-btn:hover {
  color: #ff4444;
}

.linkListWrap a {
  background-color: rgb(245, 245, 245);
  font-weight: 600;
  border-radius: 5px;
  padding: 5px 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: #333;
}

.remove-btn {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 2px 6px;
  min-width: 30px;
}

.remove-btn:hover {
  color: #ff4444;
  background-color: rgb(219, 219, 219);
  border-radius: 4px;
}

.no-links {
  color: #666;
  text-align: center;
  margin-top: 10px;
}

.add-button {
  padding: 2px 2px;
  background-color: white;
  /* border: 1px solid #a5a5a5; */
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  color: #4e4e4e;
}

.image-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.file-name {
  font-size: 0.875rem;
  color: #666;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  margin-top: 5px;
}

.know-key {
  font-size: 0.75em;
  color: #888;
  font-weight: normal;
}

.priority-badge {
  border: 0.5px solid #d7d7d7;
  font-size: 1em;
  padding: 2px 8px;
  border-radius: 8px;
  margin-left: 6px;
  font-weight: 600;
  align-content: center;
  height: 25px;
}

.priority-9 {
  background-color: #ffebee;
  color: #d32f2f;
}

.priority-1 {
  background-color: #e8f5e9;
  color: #388e3c;
}

.priority-0 {
  background-color: #e3f2fd;
  color: #1976d2;
}

.search-select {
  width: 70px;
  height: 25px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
}
</style>
