function formatDate(date) {
  return date.toISOString().split("T")[0];
}

function getPreviousWeekDates() {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const lastWednesday = new Date(today);
  lastWednesday.setDate(today.getDate() - ((dayOfWeek + 4) % 7) - 7);

  const dates = [];
  for (let i = 0; i < 7; i++) {
    const date = new Date(lastWednesday);
    date.setDate(lastWednesday.getDate() + i);
    dates.push(formatDate(date));
  }
  return dates;
}

function getLastFiveWeeks() {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const weeks = [];
  for (let i = 4; i >= 0; i--) {
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - dayOfWeek - i * 7);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    weeks.push(`${formatDate(startOfWeek)} ~ ${formatDate(endOfWeek)}`);
  }
  return weeks;
}

function getLastSixMonths() {
  const today = new Date();
  const months = [];
  for (let i = 5; i >= 0; i--) {
    const monthDate = new Date(today);
    monthDate.setMonth(today.getMonth() - i);
    months.push(`${monthDate.getFullYear()}년 ${monthDate.getMonth() + 1}월`);
  }
  return months;
}

export default {
  install(app) {
    app.config.globalProperties.$datePlugin = {
      getPreviousWeekDates,
      getLastFiveWeeks,
      getLastSixMonths
    };
  }
};
