import { defineStore } from "pinia";
import { ref } from "vue";

export const useContentsStore = defineStore("contents", {
  state: () => ({
    searchText: ref(""),
    arrayOption: ref(""),
    manageSearchText: ref(""),
    memberOption: ref(""),
    knowSearchText: ref(""),
    myMsgSearchText: ref("")
  }),
  actions: {
    getSearchText(search: string) {
      // this.searchText = search;
      this.searchText = search.toLowerCase(); // ✅ 대소문자 구분 없이 저장
      console.log("검색어는", this.searchText);
    },
    getArrayText(option: string) {
      this.arrayOption = option;
      console.log("옵션은", this.arrayOption);
    },
    getSearchManageText(search: string) {
      this.manageSearchText = search;
      console.log("멤버 검색어는", this.manageSearchText);
    },
    getMemberFilter(option: string) {
      this.memberOption = option;
    },
    getSearchKnowText(search: string) {
      this.knowSearchText = search;
      console.log("지식 검색어는", this.manageSearchText);
    },
    getMyMsgText(search: string) {
      this.myMsgSearchText = search;
      console.log("메세지 검색어는", this.myMsgSearchText);
    }
  }
});
