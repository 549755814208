<script setup lang="ts">
import { ref, computed, onMounted, inject } from "vue";
import { useStore } from "../../../stores/store";
import { useContentsStore } from "../../../stores/content";
import { sidebarKey } from "../../common/sidebar/SidebarRoot.vue";
import { useRouter } from "vue-router";
import { useQuasar } from "quasar";
import { getSavedMessages } from "../../../api/backend";

// Icons
import FolderIcon from "@fluentui/svg-icons/icons/folder_20_regular.svg";
import TrashIcon from "@fluentui/svg-icons/icons/delete_20_regular.svg";
import DocumentIcon from "@fluentui/svg-icons/icons/document_20_regular.svg";

// Store와 컨텍스트
const store = useStore();
const contentStore = useContentsStore();
const sidebarContext = inject(sidebarKey);
const router = useRouter();
const $q = useQuasar();

// 상태 관리
const savedMessages = ref([]);
const folders = ref([{ folder_key: 6514, name: "기본 폴더", count: 0 }]);
const selectedFolder = ref(folders.value[0]?.folder_key || null);
const isLoading = ref(false);
const isTrashSelected = ref(false);
const trashItems = ref([]);

// 보관함 메시지 가져오기
const fetchSavedMessages = async (folderKey) => {
  isLoading.value = true;
  try {
    // backend.ts에 정의한 API 함수 사용
    const messages = await getSavedMessages({
      user_key: store.myInfo?.user_key,
      folder_key: folderKey,
      delete_yn: 0 // 삭제되지 않은 메시지
    });

    savedMessages.value = messages || [];

    // 폴더별 메시지 수 업데이트
    const folderIndex = folders.value.findIndex(
      (f) => f.folder_key === folderKey
    );
    if (folderIndex !== -1) {
      folders.value[folderIndex].count = savedMessages.value.length;
    }
  } catch (error) {
    console.error("보관 메시지 로드 실패:", error);
    $q.notify({
      message: "저장된 메시지를 불러오는데 실패했습니다",
      color: "negative"
    });
  } finally {
    isLoading.value = false;
  }
};

// 삭제된 메시지 가져오기 (휴지통)
const fetchTrashMessages = async () => {
  isLoading.value = true;
  try {
    // backend.ts에 정의한 API 함수 사용
    const messages = await getSavedMessages({
      user_key: store.myInfo?.user_key,
      delete_yn: 1 // 삭제된 메시지
    });

    trashItems.value = messages || [];
  } catch (error) {
    console.error("삭제된 메시지 로드 실패:", error);
    $q.notify({
      message: "삭제된 메시지를 불러오는데 실패했습니다",
      color: "negative"
    });
  } finally {
    isLoading.value = false;
  }
};

// 폴더 선택 핸들러
const selectFolder = (folderKey) => {
  selectedFolder.value = folderKey;
  isTrashSelected.value = false;
  fetchSavedMessages(folderKey);
};

// 휴지통 선택 핸들러
const selectTrash = () => {
  selectedFolder.value = null;
  isTrashSelected.value = true;
  fetchTrashMessages();
};

// 날짜 포맷팅 유틸리티
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return (
    date.toLocaleDateString() +
    " " +
    date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
  );
};

// 메시지 채널로 이동 (원본 메시지 보기)
const goToOriginalMessage = (channelKey, msgKey) => {
  router.push(`/talk/channel?chKey=${channelKey}&msgKey=${msgKey}`);
  sidebarContext?.toggleEnabled();
};

// 필터링된 메시지 목록
const filteredMessages = computed(() => {
  if (!contentStore.myMsgSearchText)
    return isTrashSelected.value ? trashItems.value : savedMessages.value;

  const searchText = contentStore.myMsgSearchText.toLowerCase().trim();
  const messages = isTrashSelected.value
    ? trashItems.value
    : savedMessages.value;

  return messages.filter(
    (msg) =>
      msg.ask_str.toLowerCase().includes(searchText) ||
      msg.res_str.toLowerCase().includes(searchText)
  );
});

// 질문 텍스트 자르기
const truncateText = (text, maxLength = 50) => {
  if (!text) return "";
  text = text.replace(/\n/g, " "); // 줄바꿈을 공백으로 변환
  return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};

// 컴포넌트 초기화
onMounted(async () => {
  if (selectedFolder.value) {
    fetchSavedMessages(selectedFolder.value);
  }
});
</script>

<template>
  <div class="message-save-list">
    <!-- 폴더 섹션 -->
    <div class="folder-section">
      <!-- 폴더 목록 -->
      <ul class="folder-list">
        <li
          v-for="folder in folders"
          :key="folder.folder_key"
          class="folder-item"
          :class="{ active: selectedFolder === folder.folder_key }"
          @click="selectFolder(folder.folder_key)"
        >
          <FolderIcon />
          <span>{{ folder.name }}</span>
          <span class="count">({{ folder.count }})</span>
        </li>

        <!-- 휴지통 -->
        <li
          class="trash-item"
          :class="{ active: isTrashSelected }"
          @click="selectTrash"
        >
          <TrashIcon />
          <span>휴지통</span>
          <span class="count">({{ trashItems.length }})</span>
        </li>
      </ul>
    </div>

    <!-- 메시지 섹션 -->
    <div class="message-section">
      <div v-if="isLoading" class="loading">
        <div class="spinner"></div>
        불러오는 중...
      </div>

      <div v-else-if="filteredMessages.length === 0" class="empty-state">
        {{
          isTrashSelected ? "휴지통이 비어있습니다" : "저장된 메시지가 없습니다"
        }}
      </div>

      <!-- 메시지 목록 -->
      <ul class="message-list">
        <li
          v-for="message in filteredMessages"
          :key="message.save_key"
          class="message-item"
        >
          <div class="message-header">
            <DocumentIcon />
            <span
              class="message-title"
              @click="
                goToOriginalMessage(message.channel_key, message.res_msg_key)
              "
            >
              {{ truncateText(message.ask_str) }}
            </span>
          </div>

          <div class="message-content">
            {{ truncateText(message.res_str, 100) }}
          </div>

          <div class="message-footer">
            <span class="message-date">{{ formatDate(message.cre_date) }}</span>

            <div class="message-actions">
              <!-- 채널 이름 표시 -->
              <span class="channel-key"> 채널 {{ message.channel_key }} </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.message-save-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .folder-section {
    // padding: 15px;
    border-bottom: 1px solid #e0e0e0;

    .folder-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      h3 {
        font-size: 1rem;
        font-weight: 500;
        margin: 0;
      }
    }

    .folder-list {
      list-style: none;
      padding: 0;
      margin: 0;

      .folder-item {
        height: 38px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 8px 10px;
        border-radius: 4px;
        cursor: pointer;
        margin-bottom: 2px;
        font-size: 0.95rem;

        svg {
          margin-right: 8px;
          color: #666;
        }

        .count {
          margin-left: 5px;
          font-size: 0.8rem;
          color: #999;
        }

        &:hover {
          background-color: #f5f5f5;
        }

        &.active {
          background-color: #e0f2f1;
          color: #03ceae;

          svg {
            color: #03ceae;
          }
        }

        &.trash-item {
          // margin-top: 5px;
          border-top: 1px solid #eee;
          padding-top: 12px;
          height: 38px;

          svg {
            color: #ef5350;
          }

          &.active {
            background-color: #f5e0e0;
            color: #ef5350;

            svg {
              color: #ef5350;
            }
          }
        }
      }
    }
  }

  .message-section {
    flex: 1;
    padding: 15px;
    overflow-y: auto;

    .loading,
    .empty-state {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      color: #999;
      font-size: 0.9rem;
    }

    .loading {
      .spinner {
        width: 20px;
        height: 20px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-top-color: #03ceae;
        border-radius: 50%;
        animation: spin 1s linear infinite;
        margin-right: 10px;
      }

      @keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }
    }

    .message-list {
      list-style: none;
      padding: 0;
      margin: 0;

      .message-item {
        margin-bottom: 12px;
        padding: 12px;
        border: 1px solid #e0e0e0;
        border-radius: 6px;
        transition: box-shadow 0.2s;

        &:hover {
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
        }

        .message-header {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          svg {
            color: #03ceae;
            margin-right: 8px;
          }

          .message-title {
            font-weight: 500;
            cursor: pointer;
            color: #444;

            &:hover {
              color: #03ceae;
              text-decoration: underline;
            }
          }
        }

        .message-content {
          font-size: 0.9rem;
          color: #666;
          margin-bottom: 10px;
          line-height: 1.5;
        }

        .message-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 0.8rem;

          .message-date {
            color: #888;
          }

          .channel-name,
          .channel-key {
            font-size: 0.75rem;
            color: #03ceae;
            margin-right: 8px;
            background-color: #e0f2f1;
            padding: 2px 6px;
            border-radius: 4px;
          }

          .message-actions {
            display: flex;
            gap: 8px;
            align-items: center;
          }
        }
      }
    }
  }
}

// 모바일 대응
@media (max-width: 480px) {
  .message-save-list {
    .folder-section {
      padding: 10px;

      .folder-list li {
        padding: 6px 8px;
      }
    }

    .message-section {
      padding: 10px;

      .message-list .message-item {
        padding: 10px;

        .message-footer {
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;

          .message-date {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
</style>
