<script setup lang="ts">
import upSwapIcon from "@fluentui/svg-icons/icons/triangle_12_filled.svg";
import SwapIcon from "@fluentui/svg-icons/icons/triangle_down_12_filled.svg";
import { useQuasar } from "quasar";
import {
  DialogDescription,
  RadioGroupIndicator,
  RadioGroupItem,
  RadioGroupRoot
} from "radix-vue";
import { computed, onMounted, ref } from "vue";
import {
  baseUrl,
  initBaseUrl,
  localUrl,
  loginIdPw,
  loginSso
} from "../../api/backend";
import LoginOptionPop from "../../popups/LoginOptionPop.vue";
import { useLocalStorage } from "@vueuse/core";
import { useStore } from "../../stores/store";
import { GoogleAuthAPI } from "../../configs/googleLogin";
import { useTokenStore } from "../../stores/token";
import { reactive } from "vue";
import { Token } from "../../api/types";

const $q = useQuasar();

const emit = defineEmits(["submit-login"]);

const useSelectedUser = () => {
  // saveApi();
  if (selectedUser.value) {
    const userInfo = props.userInfoList.find(
      (user) => user.username === selectedUser.value
    );
    if (userInfo) {
      props.fillInfo(userInfo);
      emit("submit-login", userInfo); // 부모로 사용자 정보 전달
    }
  }
};

const localUrlBtn = () => {
  localUrl();
  anytalkApi.value = baseUrl.value;
};

const initBaseUrlBtn = () => {
  console.log("URL 값은??", baseUrl.value);
  console.log("=====222 URL 값은??", import.meta.env.VITE_BASE_URL);

  // initBaseUrl 호출
  initBaseUrl();

  anytalkApi.value = baseUrl.value;
};

// Props and data
const props = defineProps<{
  userInfoList: { username: string }[];
  fillInfo: (userInfo: { username: string }) => void;
  closePop: () => void;
  handleGoogleSubmit: Function;
}>();

const popupTitle = "로그인 옵션";
const selectedUser = ref<string | null>(null); // 라디오 버튼을 통해 선택된 사용자

// 버튼 활성화 여부를 결정하는 computed 속성
const isButtonEnabled = computed(() => {
  return selectedUser.value !== null || anytalkApi.value.trim() !== "";
});

onMounted(() => {
  console.log("유저 정보", props.userInfoList);
  console.log("팝업에 들어옴");
});

// const useSelectedUser = () => {
//   saveApi();
//   if (selectedUser.value) {
//     const userInfo = props.userInfoList.find(
//       (user) => user.username === selectedUser.value
//     );
//     if (userInfo) {
//       props.fillInfo(userInfo);
//     }
//   }
// };

const customStyle = ref({ "--dialog-width": "20%" });

if (window.innerWidth <= 576) {
  customStyle.value["--dialog-width"] = "80%";
}

window.addEventListener("resize", () => {
  customStyle.value["--dialog-width"] =
    window.innerWidth <= 576 ? "80%" : "20%";
});
// const showApiUrl = ref(false);
const showApiUrl = ref(true);

const openApiUIrl = () => {
  showApiUrl.value = !showApiUrl.value;
};
// https://192.168.0.30:8000

// const saveApi = () => {
//   baseUrl.value = anytalkApi.value;
//   console.log("anytalkApi.value변경", anytalkApi.value);
//   console.log("base_url변경", baseUrl.value);
//   $q.notify({
//     message: "저장이 완료되었습니다.",
//     color: "positive"
//   });
// };

// const saveApi = () => {
//   baseUrl.value = anytalkApi.value;
//   console.log("API URL 저장됨:", anytalkApi.value);

//   $q.notify({
//     message: "저장이 완료되었습니다.",
//     color: "positive",
//     timeout: 3000
//   });
//   // props.closePop();
// };

const saveApi = () => {
  anytalkApi.value = anytalkApi.value.trimEnd(); // 마지막 공백 제거
  baseUrl.value = anytalkApi.value;

  console.log("API URL 저장됨:", anytalkApi.value);

  $q.notify({
    message: "저장이 완료되었습니다.",
    color: "positive",
    timeout: 3000
  });
};

// const anytalkApi = ref("https://api.anytalk.kr:8082");
// const anytalkApi = ref("https://anytalk.com:8000");
const anytalkApi = useLocalStorage(
  "anytalk_api_base_url",
  "https://anytalk.com:8443"
  // "https://chatweb.nsu.ac.kr"
  // http://220.68.189.120:8443
);

const store = useStore();

const googleAuth = new GoogleAuthAPI();

const handleGoogleSubmit = async () => {
  const user = await googleAuth.signIn();
  const result = await loginSso({
    uid: user.uid,
    email: user.email ?? null,
    nick: user.displayName ?? null,
    photo_url: user.photoURL ?? null,
    provider_id: user.providerId
  });

  if (result) {
    await store.setUserFromLoginResult(result); // 로그인 후 사용자 정보 업데이트
    loginWithToken(result.access_token); //토큰 전달
    props.closePop();
  }
};
const tokenStore = useTokenStore();

export interface Credentials {
  username: string;
  password: string;
}

const credentials = reactive<Credentials>({
  username: "",
  password: ""
});

const fillInfo = (info: Credentials) => {
  $q.notify({
    message: `${info.username}님이 선택되었습니다.`,
    color: "positive",
    timeout: 3000
  });
  credentials.username = info.username;
  credentials.password = info.password;
};

const loginWithToken = (token?: Token | string) => {
  console.log(token);
  if (token && typeof token === "object") {
    // status.value = true;
    tokenStore.setToken((token as Token).access_token);
    fillInfo({ username: "", password: "" });
    // isLogin.value = false;
    $q.notify({
      message: "로그인에 성공했습니다.",
      color: "positive",
      timeout: 3000
    });
  } else if (typeof token === "string") {
    // Handle the case when only access_token is passed
    // status.value = true;
    tokenStore.setToken(token);
    fillInfo({ username: "", password: "" });
    // isLogin.value = false;
    $q.notify({
      message: "로그인에 성공했습니다.",
      color: "positive",
      timeout: 3000
    });
  } else {
    $q.notify({
      message: "아이디 또는 비밀번호를 다시 확인해주세요",
      color: "negative",
      timeout: 3000
    });
    // status.value = false;
  }
};
</script>

<template>
  <LoginOptionPop :pPopTitle="popupTitle">
    <template #body>
      <div :style="customStyle">
        <DialogDescription
          :style="customStyle"
          :disableOutsidePointerEvents="false"
        >
          <div class="optionWrap">
            <!-- api주소 -->
            <div class="apiWrap">
              <label class="DialogDescription__Item" for="apiAddress">
                <div
                  @click="openApiUIrl"
                  style="
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    cursor: pointer;
                  "
                >
                  <div class="sectionTitle">API 설정</div>
                  <div v-if="showApiUrl">
                    <!-- <span
                      class="iconWrap roomToggle flexJustify"
                      @click="openApiUIrl"
                    > -->
                    <span class="iconWrap roomToggle flexJustify">
                      <upSwapIcon />
                    </span>
                  </div>
                  <div v-else style="padding-bottom: 10px">
                    <span class="roomToggle flexJustify">
                      <SwapIcon />
                    </span>
                  </div>
                </div>
                <div class="apiWrap" v-if="showApiUrl">
                  <input
                    class="apiInput"
                    type="text"
                    v-model="anytalkApi"
                    placeholder="URL을 입력하세요"
                  />
                  <div class="btnWrap">
                    <div>
                      <button @click="initBaseUrlBtn" class="resetBtn">
                        <!-- 초기화 -->
                        온라인
                      </button>
                      <button @click="localUrlBtn" class="resetBtn">
                        <!-- 로컬 -->
                        개발용
                      </button>
                    </div>
                    <button @click="saveApi" class="okBtn">저장</button>
                  </div>
                </div>
                <!-- <EditableRoot
                  v-slot="{ isEditing }"
                  v-model="baseUrl"
                  activationMode="none"
                  submitMode="none"
                  placeholder="URL을 입력하세요"
                >
                  <EditableArea>
                    <EditableInput
                      id="apiAddress"
                      class="DialogDescription__Input"
                      required
                    />
                  </EditableArea>
                  <EditableEditTrigger class="DialogDescription__EditButton">
                    편집
                  </EditableEditTrigger>
                  <div class="DialogDescription__SubmitButtonContainer">
                    <button @click="initBaseUrl">초기화</button>
                    <EditableSubmitTrigger>저장</EditableSubmitTrigger>
                    <div>
                      <EditableCancelTrigger>취소</EditableCancelTrigger>
                    </div>
                  </div>
                </EditableRoot> -->
              </label>
            </div>

            <div class="DialogDescription__Item">
              <!-- 아이디 설정 -->
              <div class="googleLoginWrap">
                <!-- <span
                  class="googleLoginBtn"
                  type="button"
                  @click="handleGoogleSubmit"
                > -->
                <span
                  class="googleLoginBtn"
                  type="button"
                  @click="props.handleGoogleSubmit"
                >
                  <img
                    class="googleLogo"
                    src="/src/assets/google.png"
                    alt="anytalk logo"
                  />
                  <p>구글 로그인</p>
                </span>
              </div>
              <p class="udnerLine"></p>
              <!-- <div class="sectionTitle">아이디</div> -->
              <RadioGroupRoot v-model="selectedUser" aria-label="User Info">
                <div
                  v-for="userInfo in props.userInfoList"
                  :key="userInfo.username"
                  class="loginInfoItem"
                  @click="selectedUser = userInfo.username"
                >
                  <RadioGroupItem :value="userInfo.username" class="radio-item">
                    <RadioGroupIndicator class="radio-indicator" />
                  </RadioGroupItem>
                  <label class="textStyle" :for="userInfo.username">
                    {{ userInfo.username }}
                  </label>
                </div>
              </RadioGroupRoot>
              <!-- <button
                @click="useSelectedUser"
                :disabled="!selectedUser"
                class="okBtn"
                style="float: right"
              > -->
              <!-- <button
                @click="useSelectedUser"
                :disabled="!isButtonEnabled"
                class="okBtn"
                style="float: right"
              >
                사용하기
              </button> -->

              <button
                @click="useSelectedUser"
                :disabled="!isButtonEnabled"
                class="loginBtn"
              >
                로그인
              </button>
            </div>
          </div>
        </DialogDescription>
      </div>
    </template>
  </LoginOptionPop>
</template>

<style lang="scss" scoped>
.optionWrap {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  flex-wrap: wrap;
}

.DialogDescription__Item {
  width: 100%;
}

.sectionTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #333;
  display: flex;
}
.iconWrap {
  padding: 0 0 10px 0;
}

.loginInfoItem {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 5px;
  padding: 8px 20px 8px 0px;
  /* border: 0.5px solid #e0e0e0; */
  box-shadow: 0px 0px 1px 1px rgba(181, 181, 181, 0.3);
  border-radius: 3px;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
  cursor: pointer;
}

.loginInfoItem:hover {
  background-color: #ebebeb;
  /* border: 0.5px solid #bebebe; */
  box-shadow: 0px 0px 1px 2px rgba(176, 176, 176, 0.3);
}

.radio-item {
  margin: 0px 10px;
  padding: 0px;
  min-width: 25px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: white;
  border: 0.5px solid #9d9d9d;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-indicator {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #03ceae;
}

.textStyle {
  font-weight: 500;
  font-size: 16px;
  color: #555;
}
.okBtn {
  margin-top: 10px;
  /* padding: 10px 20px; */
  background-color: #03ceae;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.okBtn:disabled {
  background-color: #b2b2b2;
  cursor: not-allowed;
}

.okBtn:not(:disabled):hover {
  background-color: #02b399;
}

.apiWrap {
  flex: 1;
}

.DialogDescription__Input {
  width: 100%;
  padding: 8px 10px;
  font-size: 16px;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  margin-top: 8px;
}

.DialogDescription__EditButton {
  margin-top: 10px;
  cursor: pointer;
  color: #03ceae;
}

.DialogDescription__SubmitButtonContainer {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.actionBtn {
  /* padding: 10px 20px; */
  background-color: #03ceae;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.actionBtn.cancel {
  background-color: #e0e0e0;
}

.actionBtn:not(.cancel):hover {
  background-color: #02b399;
}

.actionBtn.cancel:hover {
  background-color: #d0d0d0;
}

.apiWrap {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
}
.apiInput {
  width: 100%;
  border: 0.5px solid #d0d0d0;
}
.btnWrap {
  display: flex;
  justify-content: space-between;
}
.resetBtn {
  margin: 10px 10px 0px 0px;
  background-color: #ececec;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.loginBtn {
  height: 2.3rem;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  background-color: #03ceae;
  color: white;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  align-content: center;
  margin: 20px 0 13px 0;
}

.googleLoginWrap {
  margin-bottom: 20px;
  :hover {
    background-color: #d7d7d7;
    color: #393939;
  }
}
.googleLoginBtn {
  height: 2.3rem;
  font-size: 16px;
  width: 100%;
  /* background-color: white; */
  background-color: rgb(236, 236, 236);
  border: 0.5px solid #949494;
  color: #696969;
  /* color: white; */
  /* font-weight: bold; */
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  align-content: center;
  display: flex;
  justify-content: center;
  & p {
    align-content: center;
    font-weight: 700;
  }
}

.googleLogo {
  object-fit: contain;
  align-items: center;
  padding: 6px;
}

.udnerLine {
  width: 100%;
  /* border: 0.5px dotted #b2b2b2; */
  border: 0.2px dashed #d0d0d0;
  margin-bottom: 30px !important;
}

@media (max-width: 576px) {
  .textStyle {
    font-size: 14px;
  }

  .apiWrap {
    width: 100%;
  }

  .loginInfoItem {
    gap: 8px;
  }
}

@media (max-width: 480px) {
  .optionWrap {
    flex-direction: column;
    gap: 15px;
  }

  .textStyle {
    font-size: 14px;
  }
}
</style>
