<script setup lang="ts">
import {
  DialogContent,
  DialogOverlay,
  DialogRoot,
  DialogTrigger
} from "radix-vue";
import { computed, ref, inject, Teleport } from "vue";
import { useStore } from "../../src/stores/store";
import ChannelCreatePop from "../../src/popups/ChannelCreatePop.vue";
import AddIcon from "@fluentui/svg-icons/icons/add_24_regular.svg";
import SearchIcon from "@fluentui/svg-icons/icons/search_24_regular.svg";
import CloseIcon from "@fluentui/svg-icons/icons/dismiss_24_regular.svg";
import AdminIcon from "@fluentui/svg-icons/icons/crown_20_filled.svg";
import LockIcon from "@fluentui/svg-icons/icons/lock_closed_key_20_filled.svg";
import { useRouter } from "vue-router";
import { useQuasar } from "quasar";
import { joinChannel, unjoinChannel } from "../api/backend";
import { sidebarKey } from "../../src/components/common/sidebar/SidebarRoot.vue";
import PrivateChannelDialog from "../components/root/PrivateChannelDialog.vue";

const store = useStore();
const sidebarContext = inject(sidebarKey);
const $q = useQuasar();
const router = useRouter();
const channelCreatePopOpen = ref(false);
const privateChannelDialog = ref(false);
const password = ref("");
const pTitleValue = ref("비공개 채팅");
const kindOf = ref("구독");

const emit = defineEmits(["close"]);

// 닫기 함수 수정
const closeDialog = () => {
  store.closeChannelAddDialog(); // 전역 상태 업데이트
  emit("close"); // 부모 컴포넌트에 이벤트 전달
};

// 검색 기능
const searchQuery = ref("");
const tabs = ref("all"); // 'my' or 'all'

// 내 구독 채널 목록 (필터링)
const mySubscribedChannels = computed(() => {
  return store.allChannelList.filter(
    (item) =>
      ["S", "A", "E", "M"].some((type) =>
        (item.auth_type || "").split(",").includes(type)
      ) && item.name.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

// 전체 채널 목록 (필터링)
const allAvailableChannels = computed(() => {
  return store.allChannelList.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

const openChannelCreatePop = () => {
  channelCreatePopOpen.value = true;
};

const closeChannelCreatePop = () => {
  channelCreatePopOpen.value = false;
  closeDialog();
};

const openDetailPop = (channel) => {
  store.selectedChannel = channel;
  // 세부 정보 팝업 열기 로직
};

// 비공개 채널 다이얼로그 관련 함수
const closePrivateDialog = () => {
  privateYn.value = false;
  privateChannelDialog.value = false;
};

const openPrivateDialog = (channel) => {
  store.selectedChannel = channel;
  privateYn.value = true;
  privateChannelDialog.value = true;
};

// 채널 구독 관련 함수
const followThisChannelParam = (channel: Channel) => {
  const auth_user: Omit<AuthUser, "auth_user_key" | "grant_date"> = {
    user_key: store.myInfo?.user_key,
    channel_key: channel.channel_key,
    auth_type: "S",
    grant_user_key: 0
  };

  const secure_str4 = password.value; // secure_str4 값

  // ReqChannelAnswerWithSecure 객체 반환
  return { auth_user, secure_str4 };
};

const getChannelIndex = (channel: Channel) => {
  const currentChannelKey = channel.channel_key;
  const chanIndex = store.allChannelList.findIndex(
    (item) => item.channel_key === channel.channel_key
  );
  return chanIndex;
};

const followThisChannel = async (channel: Channel) => {
  const index = getChannelIndex(channel);
  const params = followThisChannelParam(channel);
  const response = await joinChannel(params);

  if (response) {
    await store.updateChannelAuth(index);
    $q.notify({
      message: "구독이 완료되었습니다.",
      color: "positive"
    });

    store.closeChannelAddDialog();
    goToChatting(channel);
    password.value = ""; // 비밀번호 초기화
    privateChannelDialog.value = false;
    privateYn.value = false;
    store.closeChannelAddDialog(); // 채널 추가 다이얼로그 닫기
  } else {
    $q.notify({
      message: "비밀번호가 틀렸습니다.",
      color: "negative"
    });
  }
};

const unFollowThisChannelParam = (channel: Channel) => {
  const param = {
    user_key: store.myInfo?.user_key,
    channel_key: channel.channel_key
  };

  return param;
};

const unFollowThisChannel = async (channel, event) => {
  if (event) {
    event.stopPropagation();
  }

  try {
    await unjoinChannel(unFollowThisChannelParam(channel));
    $q.notify({
      message: "구독이 취소되었습니다.",
      color: "negative"
    });
    await store.deleteFollowChannelAuth(getChannelIndex(channel));
  } catch (error) {
    $q.notify({
      message: "구독 취소 중 오류가 발생했습니다.",
      color: "negative",
      timeout: 3000
    });
  }
};

const goToChatting = (channel: object) => {
  store.currentChannel = [];
  store.currentChannel = channel;
  sidebarContext?.toggleEnabled();
  store.closeChannelAddDialog(); // 채널 추가 다이얼로그 닫기
  router.push("/talk");
};

// 채널 클릭 시 처리 함수
const handleChannelClick = async (channel: Channel) => {
  // 이미 구독 중인지 확인
  // const isSubscribed =
  //   channel.auth_type?.includes("S") ||
  //   channel.auth_type?.includes("A") ||
  //   channel.auth_type?.includes("E") ||
  //   channel.auth_type?.includes("M");
  const isSubscribed = isChannelSubscribed(channel);

  if (isSubscribed || store.myInfo?.observer_yn === 1) {
    // 이미 구독 중이거나 관리자인 경우 바로 채팅으로 이동
    goToChatting(channel);
  } else {
    // 비밀번호가 필요한 채널인 경우
    if (channel.secure_yn) {
      openPrivateDialog(channel);
    } else {
      // 비밀번호가 필요 없는 채널인 경우 바로 구독
      // const index = getChannelIndex(channel);
      await followThisChannel(channel);
    }
  }
};

// 구독 버튼 클릭 핸들러
const handleSubscribeClick = async (event, channel: Channel) => {
  event.stopPropagation(); // 이벤트 전파 방지

  if (channel.secure_yn) {
    console.log("비밀번호 채널 오픈");
    openPrivateDialog(channel);
  } else {
    await followThisChannel(channel);
  }
};

// 구독 여부 확인
const isSubscribed = (channel) => {
  return ["S", "A", "E", "M"].some((type) =>
    (channel.auth_type || "").split(",").includes(type)
  );
};

// 채널이 구독되었는지 확인하는 함수
const isChannelSubscribed = (channel) => {
  return ["S", "A", "E", "M"].some((type) =>
    (channel.auth_type || "").split(",").includes(type)
  );
};

// 채널이 관리자 권한을 가지고 있는지 확인
const isChannelAdmin = (channel) => {
  return channel && channel.auth_type && channel.auth_type.includes("A");
};

const privateYn = ref(false);
</script>

<template>
  <!-- 모달 오버레이 -->
  <DialogOverlay v-if="!privateChannelDialog" class="dialog-overlay" />

  <!-- 모달 컨텐츠 -->
  <DialogContent class="dialog-content">
    <div v-if="!privateChannelDialog" class="channel-dialog">
      <div class="dialog-header">
        <h2>채널 추가</h2>
        <div>
          <button @click="openChannelCreatePop" class="create-channel-btn">
            + 새 채널
          </button>
          <button class="cursorP" @click="closeDialog"><CloseIcon /></button>
        </div>
      </div>

      <!-- 검색 영역 -->
      <div class="search-area">
        <div class="search-input-wrapper">
          <input
            type="text"
            v-model="searchQuery"
            placeholder="채널 검색..."
            class="search-input"
          />
          <SearchIcon class="search-icon" />
        </div>
      </div>

      <!-- 탭 메뉴 -->
      <div class="channel-tabs">
        <button
          @click="tabs = 'my'"
          :class="['tab-button', { active: tabs === 'my' }]"
        >
          내 구독 채널
        </button>
        <button
          @click="tabs = 'all'"
          :class="['tab-button', { active: tabs === 'all' }]"
        >
          전체 채널
        </button>
      </div>

      <!-- 채널 목록 -->
      <div class="channel-list-container">
        <div v-if="tabs === 'my'" class="channel-list">
          <div v-if="mySubscribedChannels.length === 0" class="no-channels">
            구독 중인 채널이 없습니다.
          </div>
          <div
            v-for="channel in mySubscribedChannels"
            :key="channel.channel_key"
            class="channel-item"
            @click="handleChannelClick(channel)"
          >
            <div class="channel-info">
              <div class="channel-name">
                {{ channel.name }}
                <AdminIcon v-if="isChannelAdmin(channel)" />
                <LockIcon v-if="channel.secure_yn" />
                <p>{{ channel.channel_key }}</p>
              </div>
              <div class="channel-description" v-if="channel.description">
                {{ channel.description }}
              </div>
            </div>
            <button
              class="subscribe-cancel-btn"
              @click="unFollowThisChannel(channel, $event)"
              v-if="!isChannelAdmin(channel)"
            >
              구독취소
            </button>
          </div>
        </div>

        <div v-else class="channel-list">
          <div v-if="allAvailableChannels.length === 0" class="no-channels">
            검색 결과가 없습니다.
          </div>
          <div
            v-for="channel in allAvailableChannels"
            :key="channel.channel_key"
            class="channel-item"
          >
            <div class="channel-info">
              <div class="channel-name">
                {{ channel.name }}
                <AdminIcon v-if="isChannelAdmin(channel)" />
                <LockIcon v-if="channel.secure_yn" />
                <p>{{ channel.channel_key }}</p>
              </div>
              <div class="channel-description" v-if="channel.description">
                {{ channel.description }}
              </div>
            </div>
            <!-- <button
              v-if="!channel.auth_type || !channel.auth_type.includes('S')"
              @click.stop="handleSubscribeClick($event, channel)"
              class="subscribe-btn"
            > -->
            <!-- 구독 상태에 따라 버튼 변경 -->
            <button
              v-if="!isSubscribed(channel)"
              @click.stop="handleSubscribeClick($event, channel)"
              class="subscribe-btn"
            >
              구독
            </button>
            <span v-else class="subscribed-label">
              <button class="subscribe-ing-btn">구독중</button>
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- 채널 생성 팝업 -->
    <DialogRoot v-model:open="channelCreatePopOpen">
      <ChannelCreatePop :pClosePop="closeChannelCreatePop" />
    </DialogRoot>

    <DialogRoot v-model:open="privateChannelDialog">
      <PrivateChannelDialog
        v-if="privateChannelDialog && store.selectedChannel && privateYn"
        :pClosePop="closePrivateDialog"
        :channel="store.selectedChannel"
        v-model:password="password"
        :followThisChannel="followThisChannel"
        :kindOf="kindOf"
        :pTitle="pTitleValue"
      />
    </DialogRoot>
  </DialogContent>

  <!-- 비공개 채널 다이얼로그 -->
  <!-- <Teleport to="body">
    <DialogRoot v-model:open="privateChannelDialog">
      <PrivateChannelDialog
        v-if="privateChannelDialog && store.selectedChannel && privateYn"
        :pClosePop="closePrivateDialog"
        :channel="store.selectedChannel"
        v-model:password="password"
        :followThisChannel="followThisChannel"
        :kindOf="kindOf"
        :pTitle="pTitleValue"
      />
    </DialogRoot>
  </Teleport> -->
</template>

<style scoped lang="scss">
.cursorP {
  padding: 4px 0px 4px 10px;
  background-color: white;
  box-shadow: none;
}
.dialog-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.2);
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 999;
}

.dialog-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 90%;
  max-width: 700px;
  max-height: 85vh;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.channel-dialog {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 10px 10px 10px;
  // min-height: 800px;
  // max-height: 800px;
  height: 835px;
  // max-height: 85vh;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #eee;

  h2 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
  }
}

.create-channel-btn {
  background-color: #03ceae;
  color: white;
  border: none;
  padding: 8px 15px;
  margin: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background-color: darken(#03ceae, 5%);
  }
}

.search-area {
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
}

.search-input-wrapper {
  position: relative;
  width: 100%;
}

.search-input {
  width: 100%;
  padding: 10px 15px 10px 35px;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: 1rem;
  outline: none;

  &:focus {
    border-color: #03ceae;
  }
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
}

.channel-tabs {
  display: flex;
  border-bottom: 1px solid #eee;
}

.tab-button {
  flex: 1;
  padding: 10px;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;

  &.active {
    border-bottom-color: #03ceae;
    font-weight: bold;
  }

  &:hover {
    background-color: #f5f5f5;
  }
}

.channel-list-container {
  flex: 1;
  overflow-y: scroll;
  padding: 0;
  max-height: calc(85vh - 180px);
}

.channel-list {
  display: flex;
  flex-direction: column;
}

.channel-item {
  max-height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  margin: 2px 0px;

  &:hover {
    background-color: #f9f9f9;
  }
}

.channel-info {
  // display: block;
  width: 500px !important;
}

.channel-name {
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;
}

.channel-name p {
  font-size: 10px;
  color: gray;
}

.channel-description {
  font-size: 0.85rem;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 1.4;
}

.subscribe-btn {
  background-color: #03ceae;
  color: white;
  font-weight: bold;
  // background-color: #f0f0f0;
  border: none;
  // padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  width: 60px;

  &:hover {
    background-color: #00bda1;
  }
}

.subscribe-cancel-btn {
  background-color: #545454;
  // background-color: white;
  // box-shadow: #888;
  color: white;
  // color: #545454;
  font-weight: 500;
  border: none;
  padding: 5px 3px;
  border-radius: 4px;
  cursor: pointer;
  width: 60px;

  &:hover {
    // background-color: #dbdbdb;
    background-color: #343434;
  }
}

.subscribe-ing-btn {
  background-color: #f0f0f0;
  font-weight: 400;
  border: none;
  // padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  width: 60px;

  &:hover {
    // background-color: #e0e0e0;
  }
}

.no-channels {
  padding: 30px;
  text-align: center;
  color: #888;
}

:deep(.popLayer),
:deep(.DialogContent) {
  z-index: 1100 !important;
}

.dialog-overlay {
  z-index: 999;
}

@media (max-width: 480px) {
  .channel-dialog {
    max-height: 85vh;
  }
  .dialog-header {
    padding: 10px;
  }
  .create-channel-btn {
    margin: 5px;
    padding: 5px;
    width: 80px;
  }
  .cursorP {
    padding-right: 0;
  }
  .search-input-wrapper {
    // height: 40px;
  }
  .search-input {
    height: 30px;
  }
  .tab-button {
    padding: 7px;
  }
  .channel-info {
    // display: block;
    width: 200px !important;
  }
  .channel-name {
    max-width: 170px;
  }

  .channel-description {
    max-width: 180px;
  }
}
</style>
