<!-- DeleteKnowAuthPop.vue -->
<script setup lang="ts">
import CloseIcon from "@fluentui/svg-icons/icons/dismiss_24_regular.svg";

const props = defineProps({
  open: {
    type: Boolean,
    required: true
  },
  pClosePop: {
    type: Function,
    required: true
  }
});

const emits = defineEmits(["confirm", "update:open"]);

const handleConfirm = () => {
  emits("confirm");
  emits("update:open", false);
};

const handleCancel = () => {
  emits("update:open", false);
  props.pClosePop();
};
</script>

<template>
  <Teleport to="body">
    <div v-if="open" class="popup-overlay">
      <div class="popup-container">
        <div class="popup-content">
          <div class="popup-header">
            <p style="font-weight: bold; font-size: 20px">권한 카드 삭제</p>
            <div @click="handleCancel" class="cursorP close-icon">
              <CloseIcon />
            </div>
          </div>
          <div class="popup-body">
            <p style="color: black">정말로 이 지식을 삭제하시겠습니까?</p>
          </div>
          <div class="popup-footer">
            <button type="button" class="submit-btn" @click="handleConfirm">
              확인
            </button>
            <button type="button" class="cancel-btn" @click="handleCancel">
              취소
            </button>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-container {
  background-color: white;
  border-radius: 8px;
  padding: 20px 20px 20px 20px;
  width: 90%;
  max-width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.popup-content {
  display: flex;
  flex-direction: column;
  background-color: white !important;
}

.popup-content input {
  outline: none;
  border-color: #51c2b8;
  box-shadow: 0 0 0 2px rgba(81, 194, 184, 0.1);
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 16px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.popup-header h5 {
  margin: 0;
  font-size: 16px;
}

.popup-header .close-button {
  /* background: none !important; */
  background-color: white !important;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.popup-message {
  margin-bottom: 20px;
  color: #666;
}

// .popup-buttons {
//   display: flex;
//   justify-content: center;
//   padding-top: 20px;
//   gap: 10px;

//   button {
//     padding: 8px 20px;
//     border-radius: 4px;
//     font-weight: 500;
//     cursor: pointer;
//     border: none;
//     transition: background-color 0.2s;
//   }

//   .cancel-btn {
//     background-color: #e0e0e0;
//     color: #333;

//     &:hover {
//       background-color: #d0d0d0;
//     }
//   }

//   .confirm-btn {
//     background-color: #454545;
//     color: white;

//     &:hover {
//       background-color: #333;
//     }
//   }
// }

.popup-body label {
  margin-bottom: 8px;
  font-size: 14px;
}

.popup-body input {
  width: 100%;
  padding: 8px;
  margin: 0px !important;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.popup-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 12px;
}

.popup-footer button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn {
  background-color: #51c2b8;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.submit-btn:hover {
  background-color: #18a498;
}

.cancel-btn {
  background-color: #f5f5f5;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.cancel-btn:hover {
  background-color: #ddd;
}
</style>
