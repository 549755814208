# ReferKnowPop.vue
<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { DialogClose } from "radix-vue";
import { usePopupStore } from "../../../../stores/popups";
import { useQuasar } from "quasar";
import ReferCommonPopLayer from "../../../../popups/ReferCommonPopLayer.vue";
import { getKmsKnowReason } from "../../../../api/backend";
import docCopy from "@fluentui/svg-icons/icons/document_copy_20_regular.svg";

const popStore = usePopupStore();
const $q = useQuasar();

const props = defineProps<{
  msgKey: number;
}>();

const emit = defineEmits(["closeReferKnowBtn"]);

const knowData = ref<any>(null);
const loading = ref(true);
const error = ref("");

// body 콘텐츠만 추출하는 computed 속성
const bodyContent = computed(() => {
  if (!knowData.value?.reason_json) return "";

  try {
    // JSON 문자열을 객체로 파싱
    let jsonData = knowData.value.reason_json;

    // 이미 문자열인 경우 파싱 시도
    if (typeof jsonData === "string") {
      // "body {"로 시작하는 문자열인 경우 처리
      if (jsonData.startsWith("body {")) {
        // "body " 부분 제거하고 JSON 파싱
        jsonData = jsonData.substring(5);
      }
      try {
        jsonData = JSON.parse(jsonData);
      } catch (parseError) {
        console.error("내부 JSON 파싱 오류:", parseError);
        // 파싱 실패 시 원본 문자열 사용
      }
    }

    // 객체에 body 프로퍼티가 있는 경우
    if (typeof jsonData === "object" && jsonData !== null) {
      if (jsonData.body) {
        // body 값이 객체인 경우 문자열로 변환
        if (typeof jsonData.body === "object") {
          return JSON.stringify(jsonData.body, null, 2);
        }
        return jsonData.body;
      } else if (Array.isArray(jsonData.last_hubo_know_list)) {
        // 선택된 지식 항목들을 결합하여 표시
        return jsonData.last_hubo_know_list
          .map((item) => `# ${item.title}\n${item.body}`)
          .join("\n\n");
      }
    }

    // 위 조건에 해당하지 않으면 원본 반환
    return typeof jsonData === "string"
      ? jsonData
      : JSON.stringify(jsonData, null, 2);
  } catch (e) {
    console.error("JSON 파싱 오류:", e);
    // 파싱에 실패하면 원본 텍스트 반환
    return knowData.value.reason_json;
  }
});

watch(
  () => props.msgKey,
  async (newKey) => {
    if (!newKey) {
      error.value = "msg_key가 유효하지 않습니다.";
      return;
    }

    try {
      loading.value = true;
      const response = await getKmsKnowReason(newKey);
      knowData.value = response;
    } catch (err) {
      console.error("지식 데이터 로딩 실패:", err);
      error.value = "데이터를 불러오는데 실패했습니다.";
    } finally {
      loading.value = false;
    }
  },
  { immediate: true }
);

// onMounted(async () => {
//   if (!props.msgKey) {
//     error.value = "msg_key가 유효하지 않습니다.";
//     loading.value = false;
//     return;
//   }

//   try {
//     loading.value = true;
//     const response = await getKmsKnowReason(props.msgKey);
//     knowData.value = response;
//   } catch (err) {
//     console.error("지식 데이터 로딩 실패:", err);
//     error.value = "데이터를 불러오는데 실패했습니다.";
//   } finally {
//     loading.value = false;
//   }
// });

const closePop = () => {
  emit("closeReferKnowBtn");
};

const popTitle = "지식 원본";
// const popTitle = "Tracking";

const formatText = (text: string) => {
  if (!text) return "";

  // 문자열의 시작과 끝의 공백을 제거
  text = text.trim();

  return (
    text
      // 'step - ' 시작하는 줄은 강조하고 여백 추가
      .replace(
        /^(step \d+\.[^\n]*)/gm,
        '<span class="highlighted-step">$1</span>'
      )
      // 일반 줄바꿈 처리
      .replace(/\n/g, "<br>")
      // 공백 문자 보존
      .replace(/ /g, "&nbsp;")
      // 탭 문자를 4개의 공백으로 변환
      .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;")
  );
};

const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    $q.notify({
      message: "원본 내용이 클립보드에 복사되었습니다.",
      color: "positive",
      timeout: 3000
    });
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
};
</script>

<template>
  <ReferCommonPopLayer :pPopTitle="popTitle">
    <template #body>
      <div class="editTextWrap">
        <div v-if="loading" class="loading-state">
          <div class="loading-spinner"></div>
          <p>데이터를 불러오는 중...</p>
        </div>

        <div v-else-if="error" class="error-state">
          {{ error }}
        </div>

        <!-- <div
          v-else-if="knowData"
          v-html="formatText(knowData?.reason_str || '')"
          class="know-content"
        ></div> -->

        <!-- <div
          v-else-if="knowData"
          v-html="formatText(knowData?.reason_json || '')"
          class="know-content"
        ></div> -->

        <!-- body 필드만 표시하는 부분 -->
        <div
          v-else-if="knowData"
          v-html="formatText(bodyContent)"
          class="know-content"
        ></div>

        <div v-else class="empty-state">지식 데이터가 없습니다.</div>
      </div>
      <!-- <div
        class="copyIcon"
        v-if="knowData"
        @click="copyToClipboard(knowData.reason_json || '')"
      >
        <docCopy /> 복사하기
      </div> -->
      <div
        class="copyIcon"
        v-if="knowData"
        @click="copyToClipboard(bodyContent)"
      >
        <docCopy /> 복사하기
      </div>
    </template>
    <template #actions>
      <DialogClose
        @click="closePop"
        class="IconButton fontSm cursorP cancel"
        aria-label="Close"
      >
        닫기
      </DialogClose>
    </template>
  </ReferCommonPopLayer>
</template>

<style scoped>
.editTextWrap {
  width: 100%;
  padding: 10px;
  max-height: 450px;
  overflow: auto;
  line-height: 1.5;
  word-break: break-word;
}

.loading-state {
  text-align: center;
  padding: 20px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3eb697;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 10px;
}

.error-state {
  color: #dc3545;
  text-align: center;
  padding: 20px;
}

.empty-state {
  text-align: center;
  color: #666;
  padding: 20px;
}

.know-content {
  white-space: pre-wrap;
}

.cancel {
  background-color: #ccc;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cancel:hover {
  background-color: #b3b3b3;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

:deep(.know-content) {
  white-space: pre-wrap;
  line-height: 1.6;
  font-family: monospace; /* 고정폭 폰트 사용 */
}

:deep(.highlighted-step) {
  display: block;
  margin: 15px 0;
  padding: 8px 12px;
  background-color: #f0f4f8;
  font-size: 1.2em;
  font-weight: bold !important;
  color: #1a1a1a;
  border-left: 5px solid #3eb697;
  text-indent: 5px;
  border-radius: 3px;
}
.copyIcon {
  cursor: pointer;
  margin-top: 10px;
  display: inline-block; /* transform을 적용하기 위해 inline-block 지정 */
  transform-origin: center center; /* 중앙에서 확대 */
}

.copyIcon:hover {
  transition: transform 0.5s ease;
  transform: scale(1.02);
}
</style>
