<script setup lang="ts">
import { elapsedTime } from "../../../utils/date";
import type { MsgCheck } from "../../../api/types";

const props = defineProps<{ pAsks: MsgCheck[] }>();
</script>

<template>
  <div class="checkAnswersArea">
    <div
      class="eachAnswer backShadow fontMd"
      v-for="(each, index) in props.pAsks"
      :key="index"
    >
      <p>{{ each.question.body }}</p>
      <p>삭제 일시 : {{ elapsedTime(each.cre_date) }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.checkAnswersArea {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;

  & .eachAnswer {
    padding: 20px;
    background-color: #fff;
    color: gray;
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 10px;

    & p {
      margin: 0;
    }

    & .line {
      width: 100%;
      border-bottom: 1px dashed gray;
    }

    & .answer {
      width: 100%;
      display: flex;
      align-items: start;
      justify-content: space-between;

      & .body {
        width: calc(100% - 200px);
      }

      & .info {
        width: 180px;
        white-space: nowrap;
        color: gray;
        text-align: right;
      }
    }
  }
}
</style>
