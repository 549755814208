<!-- MainBox -->
<script setup lang="ts">
import { ref, onMounted, inject, watch, onUnmounted } from "vue";
import { useQuasar } from "quasar";
import QRCode from "qrcode";
import router from "../../routers";
import buttonHomepage from "../../assets/nsu_homepage.png";
import { useStore } from "../../stores/store";
import { useTokenStore } from "../../stores/token";
import MarkdownRenderer from "../common/MarkdownRenderer.vue";
import { sidebarKey } from "../common/sidebar/SidebarRoot.vue"


// Fluent UI Icons
import SubscribeIcon from "@fluentui/svg-icons/icons/checkmark_circle_20_regular.svg";
import QrCodeIcon from "@fluentui/svg-icons/icons/qr_code_24_regular.svg";
import LinkCopyIcon from "@fluentui/svg-icons/icons/link_multiple_24_regular.svg";
import DocentIcon from "@fluentui/svg-icons/icons/person_20_regular.svg";
import ChevronDownIcon from "@fluentui/svg-icons/icons/chevron_down_24_regular.svg";
import ChevronUpIcon from "@fluentui/svg-icons/icons/chevron_up_24_regular.svg";
import DismissIcon from "@fluentui/svg-icons/icons/dismiss_24_regular.svg";
import { getFileList, unjoinChannel } from "../../api/backend";
import { Channel } from "../../api/types";

// Emit
const emits = defineEmits(['update:isVisible']);

// Props
const props = defineProps<{
  questionInput?: string;
  response?: string;
  answerYn?: boolean;
}>();

const store = useStore();
const tokenStore = useTokenStore();
const $q = useQuasar();

// State Variables
const isPopupVisible = ref(false);
const qrCodeUrl = ref<string | undefined>(undefined);
const isMainBoxVisible = ref(false);
const context = inject(sidebarKey);

// Methods
const openQRCodePopup = async () => {
  await generateQRCode(
    `https://anytalk.com/talk/channel?chKey=${store.currentChannel?.channel_key}`
  );
  isPopupVisible.value = true;
};

const closeQRCodePopup = () => {
  isPopupVisible.value = false;
};

const generateQRCode = async (text: string) => {
  try {
    const url = await QRCode.toDataURL(text);
    qrCodeUrl.value = url;
  } catch (err) {
    console.error("QR 코드 생성 오류:", err);
  }
};

onMounted(() => {
  window.addEventListener('toggle-mainbox', ((event: CustomEvent) => {
    isMainBoxVisible.value = event.detail.isVisible;
    emits('update:isVisible', isMainBoxVisible.value);
  }) as EventListener);
});

const downloadQRCode = () => {
  if (qrCodeUrl.value) {
    const link = document.createElement("a");
    link.href = qrCodeUrl.value;
    link.download = "qrcode.png";
    closeQRCodePopup();
    link.click();
    $q.notify({
      message: "다운로드가 완료되었습니다.",
      color: "positive",
      timeout: 3000
    });
  } else {
    $q.notify({
      message: "QR 코드를 다운로드하지 못했습니다.",
      color: "negative",
      timeout: 3000
    });
  }
};

const copyLink = () => {
  const url = `https://anytalk.com/talk/channel?chKey=${store.currentChannel?.channel_key}&loginYn=1`;
  navigator.clipboard
    .writeText(url)
    .then(() =>
      $q.notify({
        message: "링크가 클립보드에 복사되었습니다.",
        color: "positive",
        timeout: 3000
      })
    )
    .catch((err) => console.error("링크 복사 오류:", err));
};

const goToHomepage = () => {
  router.push("/nsu.ac.kr");
};

const goToAiDocent = () => {
  router.push("/aidocent");
};

const goToAiOrcha = () => {
  router.push("/aiOrch");
};

// Toggle MainBox visibility
// const toggleMainBox = () => {
//   isMainBoxVisible.value = !isMainBoxVisible.value;
//   emits('update:isVisible', isMainBoxVisible.value);
// };

// 이미지 URL을 저장할 상태 변수
const channelLogoUrl = ref("");
// const baseImageUrl = "https://anytalk.com:28081/uploads/"; // 실제 이미지 서버 URL로 변경하세요
const baseImageUrl = "https://anytalk.com/uploads/"; // 실제 이미지 서버 URL로 변경하세요

// 채널 로고 이미지 로드 함수
const loadChannelLogo = async () => {
  if (store.currentChannel?.logo_img_filekey) {
    try {
      // 파일 키를 배열로 전달
      const fileList = await getFileList([store.currentChannel?.logo_img_filekey]);
      if (fileList && fileList.length > 0) {
        // 파일 경로 조합
        channelLogoUrl.value = `${baseImageUrl}${fileList[0].file_enc_path.replaceAll('\\', '/')}`;
      }
    } catch (error) {
      console.error("채널 로고 로드 중 오류:", error);
      channelLogoUrl.value = ""; // 오류 시 이미지 URL 초기화
    }
  } else {
    channelLogoUrl.value = ""; // 로고 없는 경우 초기화
  }
};

// 채널 변경 시 로고 로드
watch(
  () => store.currentChannel?.logo_img_filekey,
  (newVal) => {
    if (newVal) {
      loadChannelLogo();
    } else {
      channelLogoUrl.value = "";
    }
  },
  { immediate: true }
);

// 컴포넌트 마운트 시 초기 로드
onMounted(() => {
  if (store.currentChannel?.logo_img_filekey) {
    loadChannelLogo();
  }
});

// Add this to your script setup section
const isMobile = ref(false);

// Function to detect mobile devices
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 480;
};

// Add event listener for resizing
onMounted(() => {
  checkMobile(); // Check initially
  window.addEventListener('resize', checkMobile);
});

// Clean up event listener
onUnmounted(() => {
  window.removeEventListener('resize', checkMobile);
});

const unFollowThisChannel = async (channel: Channel) => {
  await unjoinChannel(unFollowThisChannelParam(channel));
  store.toggleSelectedRoom();
  $q.notify({ message: "구독이 취소되었습니다.", color: "negative" });
  store.deleteFollowChannelAuth(getChannelIndex(channel));
  // router.push("/talk/channel?chKey=85");
    router.push("/talk/channel?chKey=85");
    $q.notify({ message: "메인 채널로 이동합니다." });
};
const unFollowThisChannelParam = (channel: object) => {
  // privateChannelDialog.value = false;
  const param = new Object();

  param.user_key = store.myInfo?.user_key;
  param.channel_key = channel.channel_key;

  return param;
};
const getChannelIndex = (channel: Channel) => {
  const currentChannelKey = channel.channel_key;
  const chanIndex = store.allChannelList.findIndex(
    (item) => item.channel_key === channel.channel_key
  );
  console.log("chanIndex", chanIndex);
  return chanIndex;
};

const truncateText = (text: string, maxLength: number) => {
  if (!text) return '';
  return text.length > maxLength 
    ? text.substring(0, maxLength) + '...' 
    : text;
};
</script>

<template>
  <!-- 토글 버튼 - 항상 표시 -->
  <!-- <div 
    class="toggle-button"
    @click="toggleMainBox"
    tabindex="0"
  >
    <component :is="isMainBoxVisible ? ChevronUpIcon : ChevronDownIcon" />
    <span class="toggle-label">채널 정보</span>
  </div> -->

  <!-- MainBox 컨텐츠 -->
  <!-- <div v-show="tokenStore.isLogin && isMainBoxVisible" class="detailWrap" :class="{ 'slide-up': !isMainBoxVisible,  'overlay-active': context?.enabled.value
 }"> -->
  <div v-show=" isMainBoxVisible" class="detailWrap" :class="{ 'slide-up': !isMainBoxVisible,  'overlay-active': context?.enabled.value
 }" @click.stop>
    <!-- 로그인 후 UI -->
    
    <!-- <div v-if="tokenStore.isLogin" class="detailWrap"> -->
    <div  class="detailWrap">
      <div class="descFaqWrap">
        <div>
          <div class="qrLinkWrap">
            
            <button class="codeLinkBtn" @click="openQRCodePopup">
              <QrCodeIcon /> QR 코드
            </button>
            <button class="codeLinkBtn" @click="copyLink">
              <LinkCopyIcon /> 링크 복사
            </button>
            <!-- <button class="codeLinkBtn" @click="copyLink">
              <LinkCopyIcon /> 구독취소
            </button> -->
            <button
              v-if="store.currentChannel?.name === '남서울대학교 홈페이지'"
              class="codeLinkBtn"
              @click="goToHomepage"
            >
              <img :src="buttonHomepage" alt="홈페이지" class="button-image" />
            </button>
            <button
              v-if="store.currentChannel?.channel_key === 332"
              class="codeLinkBtn"
              @click="goToAiDocent"
            >
              <DocentIcon /> AI 도슨트
            </button>
            <button
              v-if="store.currentChannel?.channel_key === 264"
              class="codeLinkBtn"
              @click="goToAiOrcha"
            >
              <DocentIcon /> AI 오케스트라
            </button>
            <!-- <button
            v-if="(store.currentChannel && store.currentChannel.auth_type !== '' )&& (store.myInfo?.observer_yn != 1)"
              class="codeLinkBtn"
              @click="unFollowThisChannel(store.currentChannel)"
            > -->
            <button
            v-if="store.currentChannel && store.currentChannel.auth_type !== '' "
              class="codeLinkBtn"
              @click="unFollowThisChannel(store.currentChannel)"
            >
              <SubscribeIcon /> 구독 취소
            </button>
          </div>

        <!-- QR Code Popup -->
        <div
          v-if="isPopupVisible"
          class="popup-overlay"
          @click="closeQRCodePopup"
        >
          <div class="popup" @click.stop>
            <div class="popup-content">
              <img :src="qrCodeUrl" alt="QR Code" />
              <button class="qrBtn ok" @click="downloadQRCode">
                QR 코드 다운로드
              </button>
              <button class="qrBtn close" @click="closeQRCodePopup">
                닫기
              </button>
            </div>
          </div>
        </div>
      </div>

        <!-- 채널 정보 (로그인 후) -->
        <!-- 로고디자인2 -->
        <!-- <div class="descWrap channel-wrap">
          <div
            v-if="store.currentChannel?.channel_key === 225"
            class="channel-banner"
          >
            <div class="channel-banner-content">
              <p class="channel-title">
                <strong>LEAD THE WORLD!</strong>
              </p>
              <p class="channel-subtitle">{{ store.currentChannel?.description }}</p>
            </div>
          </div>
          <div v-if="channelLogoUrl" class="channel-logo">
            <img :src="channelLogoUrl" alt="채널 로고" />
          </div>
        </div> -->
        <!-- 로고디자인1 -->
        <!-- <div class="descWrap channel-wrap">
          <div v-if="channelLogoUrl" class="channel-image-container">
            <img :src="channelLogoUrl" alt="채널 이미지" class="channel-image" />
          </div>
          
          <div class="channel-description-card">
            <p>{{ store.currentChannel?.description }}</p>
          </div>
        </div> -->

        <div 
          class="channel-banner" 
          :style="{ 
            backgroundImage: channelLogoUrl ? `url(${channelLogoUrl})` : 'url(/src/assets/sky-background.jpg)'  
          }"
          :class="{ 'no-image': !channelLogoUrl }"      
        >
          <!-- <h2 class="banner-title">LEAD THE WORLD!</h2> -->
          <!-- Desktop view (no truncation) -->
          <h2 class="banner-title" v-if="!isMobile">{{ store.currentChannel?.name || '' }}</h2>
          <p class="banner-description" v-if="!isMobile">{{ store.currentChannel?.description || '' }}</p>
          
          <!-- Mobile view (with truncation) -->
          <h2 class="banner-title" v-if="isMobile">{{ truncateText(store.currentChannel?.name || '', 10) }}</h2>
          <p class="banner-description" v-if="isMobile">{{ truncateText(store.currentChannel?.description || '', 38) }}</p>
          <!-- <h2 class="banner-title">{{ store.currentChannel?.name }}</h2> -->
          <!-- <p class="banner-description">{{ store.currentChannel?.description }}</p> -->
        </div>
      </div>
    </div>

      <!-- 답변 영역 (비로그인 상태) -->
      <div v-if="!tokenStore.isLogin && answerYn" class="noLoginAnswerWrap">
        <div class="outputContainer outputContainer__reply">
          <li class="msgWrap answerChat">
            <div class="msgBox">
              <div>
                <div class="message fontMd">
                  <p v-if="questionInput" class="queInAnswer">
                    {{ questionInput }}
                  </p>
                  <p v-else class="noLoginAsk">
                    <h2>안녕하세요 무엇을 도와드릴까요?</h2>
                  </p>
                  <MarkdownRenderer class="answer" :source="response" />
                </div>
              </div>
            </div>
          </li>
        </div>
      </div>
    </div>
</template>

<style scoped lang="scss">
.detailWrap {
  transition: all 1.2s cubic-bezier(0.16, 1, 0.3, 1);
  /* border-bottom: 0.1px solid rgb(189, 189, 189); */
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.1);
  position: fixed; // 닫기 버튼의 절대 위치 기준점
  /* top: 4.5%; */
  top: 23px;
  left: 50%;       // 중앙 정렬을 위해
  transform: translateX(-50%);
  margin: 0 auto;
  width: 100%;
  height: auto;
  background-color: white;
  padding: 0 40px 0;
  z-index: 1;

  &.overlay-active {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none !important; 
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;

  svg {
    width: 20px;
    height: 20px;
    color: #666;
  }
}

.descFaqWrap {
  margin: 0 auto !important;
  width: 100%;
  height: auto;
  background-color: white;
  padding: 0 40px 0;
  border-radius: 20px;
}

.descWrap {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 18px;
  padding: 15px;
  min-height: 60px;
  margin-bottom: 5px;
  box-shadow: none;
  transition: background-color 0.3s;
}

.pWrap {
  border-top: 0.5px solid #c0c0c0;
  padding-bottom: 10px;
}

.noLoginAnswerWrap {
  margin: 0 auto;
  display: flex;
  width: 80%;
  padding: 10px;
  line-height: 24px;
  box-shadow: 0 3px 4px 2px rgba(148, 148, 148, 0.3);
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  position: relative;
  text-align: left;
  min-width: 40px;
  margin-top: 30px;
}

.noLoginAsk {
  width: 100%;
  margin: 0 auto;
  padding-top: 15%;
  text-align: center;
}

.popup-overlay {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 150;
}

.popup {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 40px 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 151;
  width: 350px;
  border-radius: 20px;
}

.popup-content {
  border-radius: 20px;
  text-align: center;
  display: grid;
  border-radius: 50px;
}

.popup-content img {
  width: 100%;
}

.qrBtn {
  margin-top: 10px;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 4px;

  &.ok {
    background-color: #03ceae;

    &:hover {
    background-color: #35a587;
  }
  }

}

.qrLinkWrap {
  display: flex;
  gap: 5px;
  justify-content: center;
  margin: 10px 0px 0px 0px;
}

.codeLinkBtn {
  background-color: white;
  box-shadow: 0 0 0 white;

  .button-image {
    width: 50px;
    height: auto;
    border-radius: 5px;
  }
}

// 로고디자인2
// .channel-banner {
//   width: 100%;
//   height: 160px;
//   overflow: hidden;
//   background-image: url("https://anytalk.com:28081/uploads/");
//   background-size: cover;
//   background-position: center center;
//   background-repeat: no-repeat;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: white;
//   text-align: center;
// }

// .channel-banner-content {
//   background: rgba(0, 0, 0, 0.2);
//   padding: 5px 15px;
//   border-radius: 5px;
// }

// .channel-wrap {
//   width: 100%;
//   margin: 10px 0;
// }

// .channel-image-container {
//   width: 100%;
//   border-radius: 8px;
//   overflow: hidden;
//   margin-bottom: 15px;
//   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
// }

// .channel-image {
//   width: 100%;
//   display: block;
//   object-fit: cover;
// }

// .channel-description-card {
//   background-color: #f8f9fa;
//   border-left: 4px solid #03ceae; /* 브랜드 컬러로 강조 */
//   border-radius: 4px;
//   padding: 12px 15px;
//   margin-top: 5px;
// }

// .channel-description-card p {
//   font-size: 15px;
//   line-height: 1.6;
//   color: #333;
//   margin: 0;
// }

// @media (max-width: 480px) {
//   .channel-description-card {
//     padding: 10px;
//   }
  
//   .channel-description-card p {
//     font-size: 14px;
//   }
// }

// 로고디자인1 (이랑씨 로고 디자인)
// .channel-wrap {
//   display: flex;
//   flex-direction: column;
// }

// .channel-logo {
//   margin-bottom: 15px;
//   text-align: center;
// }

// .channel-logo img {
//   max-width: 100%;
//   max-height: 150px;
//   border-radius: 8px;
//   object-fit: contain;
// }

// .channel-title {
//   font-size: 1.5em;
// }

// .channel-subtitle {
//   font-size: 1.2em;
// }

.channel-container {
  width: 100%;
  margin-bottom: 15px;
}

.qrLinkWrap {
  display: flex;
  gap: 5px;
  justify-content: center;
  margin: 10px 0;
}

.codeLinkBtn {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: white;
  border: none;
  cursor: pointer;
  padding: 8px 12px;
  font-size: 14px;
  color: #333;
}

.codeLinkBtn:hover {
  background-color: #f5f5f5;
  border-radius: 4px;
}

// .channel-banner.no-image {
//   // background: linear-gradient(135deg, #3cceb8 0%, #b5d7d4 100%) !important;
//   background: linear-gradient(135deg, #67d8c9 0%, #c3fffa 100%) !important;
//   display: flex !important;
//   flex-direction: column !important;
//   justify-content: center !important;
//   align-items: center !important;
//   text-align: center !important;
//   padding: 40px 20px !important;
//   border-radius: 8px !important;
// }

// .channel-banner.no-image::before {
//   content: none !important;
// }

// .channel-banner.no-image .banner-title {
//   color: white;
//   font-size: 28px;
//   margin-bottom: 15px;
//   text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
// }

// .channel-banner.no-image .banner-description {
//   color: rgba(255, 255, 255, 0.9);
//   font-size: 16px;
//   max-width: 70%;
//   line-height: 1.6;
//   text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
// }

.channel-banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 260px;
  max-width: 860px;
  margin: 0 auto;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.channel-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.3); /* 이미지 위 약간의 오버레이 */
  z-index: 1;
}

.banner-title, 
.banner-description {
  position: relative;
  z-index: 2;
  color: white;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  white-space: normal;
  overflow: visible;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

// .channel-banner {
//   width: 100%;
//   height: 260px;
//   // background-image: url('/src/assets/campus_image.jpg'); /* 적절한 배경 이미지 경로로 변경 */
//   background-size: cover;
//   background-position: center;
//   position: relative;
//   border-radius: 8px;
//   overflow: hidden;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   text-align: center;
//   margin-top: 10px;
// }

// .channel-banner::before {
//   content: '';
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(108, 108, 108, 0.32);
// }

.banner-title {
  color: white;
  font-size: 36px;
  font-weight: bold;
  margin: 0 0 20px 0;
  position: relative;
  z-index: 1;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.banner-description {
  color: white;
  font-size: 18px;
  line-height: 1.5;
  max-width: 80%;
  position: relative;
  z-index: 1;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

@media (max-width: 480px) {
  .channel-banner {
    height: 200px;
    width: 100%;
  }
  
  .banner-title {
    max-height: 50px !important;
    font-size: 24px;
    margin-bottom: 10px;
  }

  .descFaqWrap {
    margin: 0 auto;
    width: 100%;
    height: auto;
    background-color: white;
    padding: 0 0;
    border-radius: 20px;
  }

  .banner-description {
    color: white;
    font-size: 18px;
    line-height: 1.5;
    max-width: 80%;
    position: relative;
    z-index: 1;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
}

.msgWrap {
  list-style-type: none;
}

.msgBox {
  margin: 0 0 0 10px;
  width: 85%;
  display: flex;
  justify-content: start;
  gap: 13px;

  & div {
    width: 100%;
  }
}

.message {
  display: inline-block;
  width: auto;
  padding: 10px;
  line-height: 24px;
  box-shadow: 0 3px 4px 2px rgba(148, 148, 148, 0.3);
  border-radius: 10px;
  position: relative;
  text-align: left;
  min-width: 40px;
  background-color: #fff;
  color: #222;
}

.queInAnswer {
  background-color: rgb(84 84 84);
  color: #fff;
  margin: 0;
  border-radius: 5px;
  padding: 10px 15px;
  line-height: 25px;
  font-weight: bold;
}

.answer {
  margin: 25px 0 !important;
  font-size: 17px;
  padding: 0 15px;
}

.toggle-button {
  position: fixed;
  top: 16px;
  /* right: 200px; */
  right: 310px;
  transform: none;

  &:hover {
    background-color: #f5f5f5;
  }

  .toggle-label {
    font-size: 14px;
    color: #666;
  }
}

.slide-up {
  transform: translateY(-100%);
  transition: all 1.2s cubic-bezier(0.16, 1, 0.3, 1);
  opacity: 0;
}



// 슬라이드 애니메이션 클래스
.slide-enter-active {
  animation: slideDown 1.2s cubic-bezier(0.16, 1, 0.3, 1);
}

.slide-leave-active {
  animation: slideUp 1.2s cubic-bezier(0.16, 1, 0.3, 1);
}

// @media (max-width: 480px) {
//   .detailWrap {
//     padding: 0px;
//   }

//   .close-button {
//     top: 5px;
//     right: 5px;
//     padding: 6px;

//     svg {
//       width: 16px;
//       height: 16px;
//     }
//   }

//   .descFaqWrap {
//     padding: 5px 15px;
//     margin: 0 !important;
//     width: 100%;
//     min-width: 10px;
//   }

//   .descWrap div {
//     display: -webkit-box;
//     -webkit-line-clamp: 2;
//     -webkit-box-orient: vertical;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     font-size: 14px
//   }

//   .channel-banner {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 100% !important;
//     height: 120px !important;
//     overflow: hidden !important;
//     background-size: cover !important;
//     background-position: center !important;
//   }

//   .channel-banner-content {
//     display: flex !important;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     width: 80% !important;
//     height: 100% !important;
//     padding: 20px 10px;
//     text-align: center;
//     margin: auto;
//   }

//   .channel-title {
//     font-size: 1.1em;
//   }

//   .channel-subtitle {
//     font-size: 0.8em;
//   }

//   .noLoginAnswerWrap {
//     width: 90%;
//     padding: 15px;
//   }

//   .msgBox {
//     width: 100% !important;
//     margin: 0;
//   }

//   .message {
//     width: 100%;
//   }

//   .answer {
//     font-size: 15px;
//     padding: 0 10px;
//   }

//   .queInAnswer {
//     padding: 8px 12px;
//     font-size: 14px;
//   }

//   .popup {
//     width: 90%;
//     max-width: 350px;
//     padding: 20px 15px;
//   }

//   .qrLinkWrap {
//     flex-wrap: wrap;
//   }

//   .codeLinkBtn {
//     font-size: 14px;
//     padding: 4px 8px;
//   }

//   .toggle-button {
//     top: 10px;
//     right: 10px;
//     padding: 6px 12px;

//     .toggle-label {
//       font-size: 12px;
//     }
//   }
// }

@media (max-width: 480px) {
  .channel-banner.mobile-compact {
    height: 120px;  /* 높이 줄임 */
    border-radius: 0;  /* 모서리 제거 */
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
  }

  .channel-banner.mobile-compact::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom, 
      rgba(0,0,0,0.6), 
      rgba(0,0,0,0.3)
    );
    z-index: 1;
  }

  .channel-banner.mobile-compact .banner-content {
    position: relative;
    z-index: 2;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  // .channel-banner.mobile-compact .banner-title {
  //   font-size: 18px;
  //   color: white;
  //   margin-bottom: 5px;
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   max-width: 100%;
  //   max-height: 50px !important;
  // }

  .channel-banner.mobile-compact .banner-description {
    font-size: 14px;
    color: rgba(255,255,255,0.8);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

/* 애니메이션 효과 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.slide-enter-active {
  animation: slideDown 0.3s ease-out;
}

.slide-leave-active {
  animation: slideUp 0.3s ease-out;
}

/* 폰트 스타일 */
.fontMd {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
}
</style>