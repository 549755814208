<!-- ManageUsersAuthCompo.vue -->
<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useStore } from "../../../stores/store";
import { mockUserList } from "../../../mokUpData/mockData";
import {
  getUserList,
  getAuthCardList,
  joinChannel,
  unjoinChannel
} from "../../../../src/api/backend";
// import eachMember from "./EachMemberCompo.vue";
import userCardPop from "../myPage/popups/userCardPop.vue";
import { DialogClose, DialogRoot } from "radix-vue";
import { useTokenStore } from "../../../stores/token";
import { elapsedYear } from "../../../utils/date";
import addAuthUserPop from "./popups/addAuthUserPop.vue";
import { useQuasar } from "quasar";
import { usePopupStore } from "../../../stores/popups";
import SubTabsCompo from "./SubTabsCompo.vue";

const store = useStore();
// const users = mockUserList;
const users = ref<User[]>([]); // ✅ API 응답을 저장할 users

const $q = useQuasar();

// 검색 옵션
const searchOptions = ref([
  { name: "이름", value: "name" },
  { name: "채널 권환", value: "auth" },
  { name: "이메일", value: "email" },
  { name: "휴대폰 4자리", value: "phone" }
]);

const selectedSearchOption = ref("name");
const searchQuery = ref("");

interface ApiUser {
  user_key: number;
  name: string;
  nick: string;
  email: string | null;
  phone4: string | null;
  aa_cre_date: string;
  status: string;
  observer_yn: string;
  last_room_key: number | null;
  last_channel_key: number | null;
  student_id: string | null;
}

// 전체 API 응답 타입 (user + auth 정보 포함)
interface ApiUserResponse {
  user: ApiUser;
  auth_user_key: number;
  auth_type: string;
  authcard_key: number;
  authcard_name: string;
}

// UI에서 사용할 데이터 타입
interface User {
  user_key: number;
  name: string;
  nick: string;
  email: string | null;
  phone: string | null;
  joinDate: string;
  status: string;
  observer_yn: string;
  last_room_key: number | null;
  last_channel_key: number | null;
  auth_user_key: number; // ✅ 추가
  auth_type: string; // ✅ 추가
  authcard_key: number; // ✅ 추가
  authcard_name: string; // ✅ 추가
  student_id: string | null; // ✅ 추가
  user_id: string | null; // ✅ 추가
}

// const users = ref<User[]>([]);
export interface ReqGetUserList {
  channel_key: number | null;
  auth_type: string | null;
  user_key: number | null;
  user_id: string | null;
  phone4: string | null;
  user_name: string | null;
  user_nick: string | null;
}

// 사용자 관리 상태
const selectedUsers = ref<User["user_key"][]>([]);

const selectedUserInfo = ref<User | null>(null); // ✅ 선택된 사용자 정보 저장
const userCardPopYn = ref(false); // ✅ 팝업 상태 (true = 열림, false = 닫힘)
const userAuthUserPopYn = ref(false); // ✅ 팝업 상태 (true = 열림, false = 닫힘)

// 검색 핸들러
const handleSearch = () => {
  searchQuery.value = searchQuery.value.trim();
  if (!searchQuery.value) {
    $q.notify({
      message: "검색어를 입력해주세요.",
      color: "warning",
      timeout: 2000
    });
    return;
  }
  const resultCount = filteredUsers.value.length;
  $q.notify({
    message: `검색결과: ${resultCount}건`,
    color: resultCount > 0 ? "positive" : "negative",
    timeout: 2000
  });
};

// 사용자 선택 핸들러
const toggleUserSelection = (userKey: number) => {
  const position = selectedUsers.value.indexOf(userKey);
  if (position === -1) {
    selectedUsers.value.push(userKey);
  } else {
    selectedUsers.value.splice(position, 1);
  }
};

const toggleAllUsers = (event: Event) => {
  const checkbox = event.target as HTMLInputElement;
  if (checkbox.checked) {
    selectedUsers.value = users.value.map((user) => user.user_key);
  } else {
    selectedUsers.value = [];
  }
};

// // 사용자가 테이블 행 클릭 시, `eachMember` 컴포넌트 열기
// const selectMember = (user: User) => {
//   selectedMember.value = user;
// };

// 사용자가 테이블 행 클릭 시, `userCardPop` 팝업 열기
const openUserCardPop = (user: User) => {
  selectedUserInfo.value = user;
  userCardPopYn.value = true;
};

// `userCardPop` 닫기 함수
const closeUserCardPop = () => {
  userCardPopYn.value = false;
  selectedUserInfo.value = null;
};

// 사용자가 테이블 행 클릭 시, `userCardPop` 팝업 열기
// const openAuthUserPop = (user: User) => {
const openAuthUserPop = () => {
  userAuthUserPopYn.value = true;
  console.log("AddAuthUserPop.value", userAuthUserPopYn.value);
  // selectedUserInfo.value = user;
};

// `userCardPop` 닫기 함수
const closeAuthUserPop = () => {
  userAuthUserPopYn.value = false;
  // selectedUserInfo.value = null;
};

// // 필터링된 사용자 목록
// const filteredUsers = computed<User[]>(() => {
//   if (!searchQuery.value) return users.value;

//   return users.value.filter((user) => {
//     const searchTerm = searchQuery.value.toLowerCase();
//     switch (selectedSearchOption.value) {
//       case "name":
//         return user.name.toLowerCase().includes(searchTerm);
//       case "nick":
//         return user.nick.toLowerCase().includes(searchTerm);
//       case "auth":
//         return user.auth.toLowerCase().includes(searchTerm);
//       case "email":
//         return user.email?.toLowerCase().includes(searchTerm) ?? false;
//       case "phone":
//         return user.phone.includes(searchTerm);
//       default:
//         return true;
//     }
//   });
// });

const filteredUsers = computed<User[]>(() => {
  if (!searchQuery.value) {
    // 가입일 기준 내림차순 정렬 (최신 가입자가 위로)
    return [...users.value].sort(
      (a, b) => new Date(b.joinDate).getTime() - new Date(a.joinDate).getTime()
    );
  }

  return [...users.value]
    .filter((user) => {
      const searchTerm = searchQuery.value.toLowerCase();
      switch (selectedSearchOption.value) {
        case "name":
          return user.name.toLowerCase().includes(searchTerm);
        case "nick":
          return user.nick.toLowerCase().includes(searchTerm);
        case "auth":
          return user.auth.toLowerCase().includes(searchTerm);
        case "email":
          return user.email?.toLowerCase().includes(searchTerm) ?? false;
        case "phone":
          return user.phone.includes(searchTerm);
        default:
          return true;
      }
    })
    .sort(
      (a, b) => new Date(b.joinDate).getTime() - new Date(a.joinDate).getTime()
    ); // ✅ 가입일 기준 내림차순 정렬
});

const popStore = usePopupStore();

const deleteUserAuth = async (user_key: number) => {
  // ✅ 기존 confirmAskOK, confirmAskNO 초기화 (중복 실행 방지)
  popStore.confirmAskOK = null;
  popStore.confirmAskNO = null;

  // ✅ 삭제 확인 팝업 열기
  popStore.openConfirmAskPopLayer();
  popStore.confirmAskPopBody = "정말로 삭제하시겠습니까?";

  // ✅ 확인 버튼 클릭 시 실행할 함수 지정
  popStore.confirmAskOK = async () => {
    try {
      const payload = {
        user_key: user_key,
        channel_key: store.currentChannel?.channel_key || 0
      };

      console.log("🚀 API 요청 payload:", JSON.stringify(payload, null, 2));

      const response = await unjoinChannel(payload);

      if (response) {
        $q.notify({
          message: "회원이 삭제되었습니다.",
          color: "positive",
          timeout: 3000
        });
      } else {
        $q.notify({ message: "삭제 실패", color: "negative", timeout: 3000 });
      }

      // ✅ 채널 사용자 목록 갱신
      await getChannelUserList();
    } catch (error) {
      console.error("❌ 회원 삭제 중 오류 발생:", error);
      $q.notify({
        message: "삭제 중 오류가 발생했습니다.",
        color: "negative",
        timeout: 3000
      });
    }

    // ✅ 팝업 닫기 및 핸들러 초기화
    popStore.closeConfirmAskPopLayer();
  };

  // ✅ 취소 버튼 클릭 시 실행할 함수 지정
  popStore.confirmAskNO = () => {
    console.log("❌ 삭제 취소됨");
    popStore.closeConfirmAskPopLayer(); // 팝업 닫기
  };

  // ✅ 성공 메시지 설정
  popStore.confirmPopBody = "회원이 삭제되었습니다.";
};

const getChannelUserList = async () => {
  const param = {
    channel_key: store.currentChannel?.channel_key || 0,
    auth_type: "" || null,
    user_key: 0 || null,
    user_id: "" || null,
    phone4: "" || null,
    user_name: "" || null,
    user_nick: "" || null,
    student_id: "" || null
  };

  try {
    const userList: ApiUserResponse[] = await getUserList(param);
    if (userList) {
      users.value = userList.map((item) => ({
        user_key: item.user.user_key,
        name: item.user.name,
        nick: item.user.nick,
        email: item.user.email,
        phone: item.user.phone4,
        joinDate: item.user.aa_cre_date,
        status: item.user.status,
        observer_yn: item.user.observer_yn,
        last_room_key: item.user.last_room_key,
        last_channel_key: item.user.last_channel_key,
        auth_user_key: item.auth_user_key, // ✅ 추가
        auth_type: item.auth_type, // ✅ 추가
        authcard_key: item.authcard_key, // ✅ 추가
        authcard_name: item.authcard_name, // ✅ 추가
        student_id: item.user.student_id, // ✅ 추가
        user_id: item.user.user_id // ✅ 추가
      }));
    }
  } catch (error) {
    console.error("유저 리스트 불러오기 실패:", error);
  }
};

onMounted(async () => {
  getChannelUserList();
});

const authTypeMap: Record<string, string> = {
  A: "관리자",
  E: "전문가",
  M: "직원",
  S: "구독자"
};

// 권한을 한글로 변환하는 computed 속성
const getAuthLabel = (authType: string) => {
  return authTypeMap[authType] || "알 수 없음";
};

const authCard = ref([]);

export interface ReqGetAuthCardList {
  subinfo_yn: number;
  authcard_key: number | null;
  channel_key: number;
  folder_key_list_str: string;
}

const openKnowCardPop = async () => {
  const param: ReqGetAuthCardList = {
    subinfo_yn: 1,
    authcard_key: null,
    channel_key: store.currentChannel?.channel_key || 0
    // folder_key_list_str: ""
  };

  try {
    const authCardList = await getAuthCardList(param); // ✅ API 호출

    console.log("🚀 API 응답 데이터:", authCardList); // ✅ 응답 데이터 확인

    if (authCardList.length > 0) {
      authCard.value = authCardList.map((item) => ({
        authcard_name: item.authcard.authcard_name, // ✅ 카드 이름
        authcard_key: item.authcard.authcard_key, // ✅ 카드 키
        channel_key: item.authcard.channel_key, // ✅ 채널 키
        cre_date: item.authcard.cre_date // ✅ 생성 날짜
      }));
    } else {
      console.log("📢 가져온 권한 카드가 없습니다.");
      authCard.value = []; // ✅ 가져온 데이터가 없으면 빈 배열로 초기화
    }
  } catch (error) {
    console.error("카드 목록 가져오기 실패:", error);
  }
};
</script>

<template>
  <div class="checkWrap">
    <div class="subTabWrap flexAlign">
      <div class="subTab flexAlign">
        <p class="total-count">멤버 카드 ({{ filteredUsers.length }})</p>
        <div
          @click="changsubTab(index)"
          :class="{ selected: checkNowTab == index }"
          class="eachTab cursorP fontMd"
          v-for="(tab, index) in pTabs"
          :key="index"
          style="font-size: 16px"
        >
          {{ tab }}
        </div>

        <!-- 검색 섹션 -->
        <div class="search-section flexAlign">
          <div class="info-section flexAlign">
            <button class="add-button cursorP" @click="openAuthUserPop">
              멤버 추가
            </button>
          </div>
          <select v-model="selectedSearchOption" class="search-select">
            <option
              v-for="option in searchOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.name }}
            </option>
          </select>
          <input
            type="text"
            v-model="searchQuery"
            :placeholder="'검색어를 입력하세요'"
            class="search-input"
          />
          <button @click="handleSearch" class="search-button">검색</button>
        </div>
      </div>
    </div>
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th>No</th>
            <th>이름</th>
            <!-- <th>학번</th> -->
            <th>아이디</th>
            <th>이메일</th>
            <th>휴대폰 4자리</th>
            <th>가입일</th>
            <th>채널 권한</th>
            <th>권한 카드</th>
            <th>탈퇴여부</th>
            <th>삭제</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(user, index) in filteredUsers"
            :key="user.user_key"
            @click="openUserCardPop(user)"
          >
            <td>{{ index + 1 }}</td>
            <td>
              {{
                user.name == "" || user.name == null
                  ? user.nick
                  : user.name || "이름 없음"
              }}
            </td>
            <!-- <td>{{ user.student_id || "없음" }}</td> -->
            <td>{{ user.user_id || "없음" }}</td>
            <!-- ✅ auth_type 사용 -->
            <td>{{ user.email || "없음" }}</td>
            <td>{{ user.phone || "없음" }}</td>
            <td>{{ elapsedYear(user.joinDate) }}</td>
            <td>{{ getAuthLabel(user.auth_type) }} ({{ user.auth_type }})</td>
            <td>
              {{ user.authcard_name || "없음" }} / {{ user.authcard_key || 0 }}
            </td>
            <td>{{ user.delete_yn === 1 ? "O" : "X" }}</td>
            <td>
              <button
                class="deleteBtn"
                delete-button-absolute
                @click.stop="deleteUserAuth(user.user_key)"
              >
                삭제
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <DialogRoot v-model:open="userCardPopYn">
        <userCardPop
          :pSelectedUserInfo="selectedUserInfo"
          :pClosePop="closeUserCardPop"
          :authCards="authCard"
          :getChannelUserList="getChannelUserList"
          v-if="selectedUserInfo"
        />
      </DialogRoot>

      <DialogRoot v-model:open="userAuthUserPopYn">
        <addAuthUserPop
          :pSelectedUserInfo="selectedUserInfo"
          :pClosePop="closeAuthUserPop"
          :getChannelUserList="getChannelUserList"
        />
      </DialogRoot>

      <DialogRoot v-model:open="popStore.confirmAskPopYn">
        <confirmAskPop />
      </DialogRoot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.checkWrap {
  width: 100%;
  margin-bottom: 20px;
}

.subTabWrap {
  min-height: 50px;
  width: 100%;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid gray;
  padding: 0 6px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 80px;
    height: 3px;
    background-color: gray;
  }
}

.flexAlign {
  display: flex;
  align-items: center;
}

.subTab {
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: space-between;

  .tab-group {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .eachTab {
    padding: 14px 10px 10px;
    white-space: nowrap;

    &.selected {
      border-bottom: 2px solid gray;
      font-weight: bold;
    }
  }
}

.header-container {
  padding: 0 5px;
  margin-bottom: 20px;
}

.search-section {
  gap: 12px;

  .search-select {
    width: 100px;
    height: 35px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    background-color: white;
  }

  .search-input {
    flex: 1;
    height: 35px;
    padding: 0 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }

  .search-button {
    height: 35px;
    padding: 0 20px;
    background-color: #4a4a4a;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
}

.info-section {
  gap: 16px;
  white-space: nowrap;

  .add-button {
    height: 35px;
    padding: 0 16px;
    background: rgb(74, 74, 74);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;

    &:hover {
      // background-color: #c3c3c3;
    }
  }
}

.total-count {
  margin: 0;
  font-size: 16px;
  font-weight: bold !important;
  padding: 14px 4px 10px;
  min-width: 100px;
}

.table-container {
  margin-top: 20px;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      height: 36px;
      padding: 4px 12px;
      text-align: center;
      font-size: 14px;
      color: #333;
      border-left: none;
    }

    th {
      background-color: #f6f7f9;
      font-weight: 500;
      color: #333;
      border-top: 1px solid #ddd;
      border-bottom: 2px solid #bcbbbb;
    }

    th:first-child,
    td:first-child {
      width: 50px;
      min-width: 50px;
    }

    th:nth-child(3),
    td:nth-child(3) {
      width: 20%;
      min-width: 050px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    th:nth-child(4),
    td:nth-child(4) {
      width: 15%;
      min-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    th:nth-child(5),
    td:nth-child(5) {
      width: 10%;
      min-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    th:not(:first-child):not(:nth-child(4)):not(:nth-child(5)),
    td:not(:first-child):not(:nth-child(4)):not(:nth-child(5)) {
      min-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    th:nth-child(9),
    td:nth-child(9) {
      width: 80px;
      min-width: 80px;
    }

    th:nth-child(10),
    td:nth-child(10) {
      width: 70px;
      min-width: 80px;
      border-right: none;
    }

    tbody tr {
      &:hover {
        background-color: #f6f7f9;
      }
    }

    input[type="checkbox"] {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
}

.delete-button-absolute {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  z-index: 100;
}

.addBtn {
  cursor: pointer;
  background: #d5d5d5;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
}

.deleteBtn {
  min-width: 40px !important;
  width: 20px !important;
  padding: 0px 3px !important;
}
</style>
